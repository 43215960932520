// SEWING
export const GET_SEWINGS = "GET_SEWINGS";
export const GET_SEWINGS_SUCCESS = "GET_SEWINGS_SUCCESS";
export const GET_SEWINGS_ERROR = "GET_SEWINGS_ERROR";

export const GET_SEWING_BY_ID = "GET_SEWING_BY_ID";
export const GET_SEWING_BY_ID_SUCCESS = "GET_SEWING_BY_ID_SUCCESS";

export const ADD_NEW_SEWING = "ADD_NEW_SEWING";
export const ADD_NEW_SEWING_SUCCESS = "ADD_NEW_SEWING_SUCCESS";
export const ADD_NEW_SEWING_ERROR = "ADD_NEW_SEWING_ERROR";

export const UPDATE_SEWING = "UPDATE_SEWING";
export const UPDATE_SEWING_SUCCESS = "UPDATE_SEWING_SUCCESS";
export const UPDATE_SEWING_ERROR = "UPDATE_SEWING_ERROR";

export const DELETE_SEWING = "DELETE_SEWING";
export const DELETE_SEWING_SUCCESS = "DELETE_SEWING_SUCCESS";

export const SEWING_CLEAR_ERROR = "SEWING_CLEAR_ERROR";

export const GET_SEWING_ENTRY_FORM = "GET_SEWING_ENTRY_FORM";
export const GET_SEWING_ENTRY_FORM_SUCCESS = "GET_SEWING_ENTRY_FORM_SUCCESS";

export const SAVE_SEWING_ENTRY_FORM = "SAVE_SEWING_ENTRY_FORM";
export const SAVE_SEWING_ENTRY_FORM_SUCCESS = "SAVE_SEWING_ENTRY_FORM_SUCCESS";

export const GET_PAGING_SEWING_OUTSTANDING_REPORT = "GET_PAGING_SEWING_OUTSTANDING_REPORT";
export const GET_PAGING_SEWING_OUTSTANDING_REPORT_SUCCESS = "GET_PAGING_SEWING_OUTSTANDING_REPORT_SUCCESS";
