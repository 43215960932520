import {
  GET_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENT_SUCCESS,
  GET_INVOICE_PAYMENT_FAIL,
  GET_INVOICE_PAYMENT_BY_ID,
  GET_INVOICE_PAYMENT_BY_ID_SUCCESS,
  ADD_NEW_INVOICE_PAYMENT,
  UPDATE_INVOICE_PAYMENT,
  DELETE_INVOICE_PAYMENT,
  INVOICE_PAYMENT_CLEAR_ERROR,
  INVOICE_PAYMENT_CLEAR_NOTIFY,
  ADD_NEW_INVOICE_PAYMENT_SUCCESS,
  UPDATE_INVOICE_PAYMENT_SUCCESS,
  DELETE_INVOICE_PAYMENT_SUCCESS,
} from "./actionTypes";

export const getInvoicePayment = id => ({
  type: GET_INVOICE_PAYMENT,
  payload: id,
});

export const getInvoicePaymentSuccess = payload => ({
  type: GET_INVOICE_PAYMENT_SUCCESS,
  payload,
});

export const getInvoicePaymentFail = error => ({
  type: GET_INVOICE_PAYMENT_FAIL,
  payload: error,
});

export const getInvoicePaymentById = data => ({
  type: GET_INVOICE_PAYMENT_BY_ID,
  payload: data,
});

export const getInvoicePaymentByIdSuccess = payload => ({
  type: GET_INVOICE_PAYMENT_BY_ID_SUCCESS,
  payload,
});

export const getInvoicePaymentByIdFail = error => ({
  type: GET_INVOICE_PAYMENT_BY_ID_FAIL,
  payload: error,
});

export const addNewInvoicePayment = payload => ({
  type: ADD_NEW_INVOICE_PAYMENT,
  payload,
});

export const addNewInvoicePaymentSuccess = payload => ({
  type: ADD_NEW_INVOICE_PAYMENT_SUCCESS,
  payload,
});

export const updateInvoicePayment = payload => ({
  type: UPDATE_INVOICE_PAYMENT,
  payload,
});

export const updateInvoicePaymentSuccess = payload => ({
  type: UPDATE_INVOICE_PAYMENT_SUCCESS,
  payload,
});

export const deleteInvoicePayment = payload => ({
  type: DELETE_INVOICE_PAYMENT,
  payload,
});

export const deleteInvoicePaymentSuccess = payload => ({
  type: DELETE_INVOICE_PAYMENT_SUCCESS,
  payload,
});

export const clearInvoicePaymentNotify = () => ({
  type: INVOICE_PAYMENT_CLEAR_NOTIFY,
});
