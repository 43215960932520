import React, { useEffect, useState } from "react";
import { NIL } from "uuid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getProductImages,
  updateProductImages,
  deleteProductImage,
} from "store/actions";

import { Button, Card, CardHeader, Col, Row } from "reactstrap";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ProductImageTable from "../../containers/product-image/ProductImageTable"
import ProductUploadModalForm from "../../containers/product-image/ProductUploadModalForm"

const ProductImageTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [modalUploadForm, setModalUploadForm] = useState(false);
  const [modalRemoveImage, setModalRemoveImage] = useState(false);

  const {
    getProductImages,
    deleteProductImage,
    updateProductImages,
    items,
    item,
    message,
    itemCount,
    pageCount,
    loading,
    productId,
  } = props;

  useEffect(() => {
    if (productId) {
      getProductImages({ productId, page, pageSize });
    }
  }, [productId, page, pageSize]);

  const handleSubmitUpload = items => {
    updateProductImages({ productId, attachments: items });
    setModalUploadForm(false);
  };

  const handleSubmitRemoveImage = () => {
    deleteProductImage({ id: selectedId, productId });
    setModalRemoveImage(false);
  };

  return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}></Col>
              <Col md={10}>
                <div className="text-sm-end">
                  <Button
                      color="primary"
                      onClick={() => setModalUploadForm(true)}
                  >
                    <i className="fas fa-cloud-upload-alt" /> Upload files
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {items && (
            <ProductImageTable
                items={items}
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                loading={loading}
                defaultPageSize={pageSize}
                onConfirmDelete={id => {
                  setSelectedId(id);
                  setModalRemoveImage(true);
                }}
                onChangePage={page => setPage(page)}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
            />
        )}

        <Row>
          <ProductUploadModalForm
              title={"Upload Attachments"}
              isOpen={modalUploadForm}
              toggle={() => setModalUploadForm(!modalUploadForm)}
              onSubmit={handleSubmitUpload}
          />
          <ConfirmDeleteModal
              title="Confirm Delete"
              isOpen={modalRemoveImage}
              toggle={() => setModalRemoveImage(!modalRemoveImage)}
              onSubmit={handleSubmitRemoveImage}
          />
        </Row>
      </>
  );
};

ProductImageTab.propTypes = {
  productId: PropTypes.string,
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,

  getProductImages: PropTypes.func,
  updateProductImages: PropTypes.func,
  deleteProductImage: PropTypes.func,
};

const mapStateToProps = ({ productImage }) => {
  return productImage;
};

export default withRouter(
    connect(mapStateToProps, {
      getProductImages,
      updateProductImages,
      deleteProductImage,
      // updateOrder,
      // getOrderById,
      // updateItemAttachment,
      // deleteItemAttachment,
    })(ProductImageTab)
);
