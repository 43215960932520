import {
  GET_ORDER_ITEMS,
  GET_ORDER_ITEMS_SUCCESS,
  GET_ORDER_ITEMS_FAIL,
  GET_ORDER_ITEM_BY_ID_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const Order = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_ITEMS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ORDER_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_ORDER_ITEMS_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default Order;
