import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
  getCompany,
  addNewCompany,
  updateCompany,
  getCompanyById,
  updateSupplier,
  deleteCompany,
  clearCompanyNotify,
} from "store/actions";
import CompanyTable from "./CompanyTable";
import ModalForm from "./ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";

const CompanyList = props => {
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);

  const { getCompany, message, items, item, loading, itemCount, pageCount } =
    props;

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearCompanyNotify;
    }
  }, [message]);

  const toggleModalEdit = () => {
    setModalEditIsOpen(!modalEditIsOpen);
  };

  const toggleModalAdd = () => {
    setModalAddIsOpen(!modalAddIsOpen);
  };

  const toggleModalConfirmDelete = () => {
    setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen);
  };

  const handleOnEdit = id => {
    const { getCompanyById } = props;
    getCompanyById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = supplier => {
    const { updateCompany } = props;
    updateCompany(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = supplier => {
    const { addNewCompany } = props;
    addNewCompany(supplier);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    const { deleteCompany } = props;
    deleteCompany(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnPageChange = page => {
    const { getCompany } = props;
    getCompany({ page, pageSize });
    setCurrentPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    const { getCompany } = props;
    getCompany({ page: 1, pageSize });
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleOnSearch = e => {
    const { value } = e.target;
    const { getCompany } = props;
    getCompany({ term: value });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Company" breadcrumbItem="Company List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={handleOnSearch}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <CompanyTable
                  items={items}
                  itemCount={itemCount}
                  currentPage={currentPage}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onEdit={handleOnEdit}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={handleOnPageChange}
                  onPageSizeChange={handleOnPageSizeChange}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Company"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={toggleModalEdit}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Company"}
                isOpen={modalAddIsOpen}
                toggle={toggleModalAdd}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={toggleModalConfirmDelete}
                onSubmit={handleDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CompanyList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getCompany: PropTypes.func,
  addNewCompany: PropTypes.func,
  updateCompany: PropTypes.func,
  getCompanyById: PropTypes.func,
  deleteCompany: PropTypes.func,
  clearCompanyNotify: PropTypes.func,
};

const mapStateToProps = ({ company }) => {
  return company;
};

export default withRouter(
  connect(mapStateToProps, {
    getCompany,
    addNewCompany,
    updateCompany,
    getCompanyById,
    deleteCompany,
    updateSupplier,
    clearCompanyNotify,
  })(CompanyList)
);
