export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";

export const GET_PERMISSION_BY_ID = "GET_PERMISSION_BY_ID";
export const GET_PERMISSION_BY_ID_SUCCESS = "GET_PERMISSION_BY_ID_SUCCESS";

export const ADD_NEW_PERMISSION = "ADD_NEW_PERMISSION";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

export const DELETE_PERMISSION = "DELETE_PERMISSION";

export const PERMISSION_CLEAR_ERROR = "PERMISSION_CLEAR_ERROR";
