import {
  GET_INVOICE_REPORT,
  GET_INVOICE_REPORT_SUCCESS,
  GET_INVOICE_REPORT_FAIL,
  INVOICE_REPORT_CLEAR_NOTIFY,
} from "./actionTypes";

// InvoiceReport
export const getInvoiceReport = id => ({
  type: GET_INVOICE_REPORT,
  payload: id,
});

export const getInvoiceReportSuccess = payload => ({
  type: GET_INVOICE_REPORT_SUCCESS,
  payload,
});

export const getInvoiceReportFail = error => ({
  type: GET_INVOICE_REPORT_FAIL,
  payload: error,
});

export const clearInvoiceReportNotify = () => ({
  type: INVOICE_REPORT_CLEAR_NOTIFY,
});
