import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import {
  getPurchaseOrderById,
  getItemUnitOfMeasurements,
  getCuttings,
  getSewings,
  getPackagings,
  getFinishedGoodss,
  getPurchaseOrderMaterial,
  getPurchaseOrderAttachments,
  getItemUsageLimitations,
  updatePurchaseOrder,
} from "store/actions"
import CuttingTab from "./CuttingTab"
import SewingTab from "./SewingTab"
import PackagingTab from "./PackagingTab"
import FinishedGoodsTab from "./FinishedGoodsTab"
import DesignFormTab from "./DesignFormTab"
import MaterialListTab from "./MaterialListTab"
import PurchaseOrderAttachmentTab from "./PurchaseOrderAttachmentTab"
import ItemUsageLimitationTab from "./ItemUsageLimitationTab"
import PurchaseOrderForm from "../../containers/purchase-order/PurchaseOrderForm"

const PurchaseOrderDetail = props => {
  const { match, history } = props

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null)

  const {
    getItemUsageLimitations,
    getPurchaseOrderAttachments,
    getPurchaseOrderById,
    getCuttings,
    getSewings,
    getPackagings,
    getFinishedGoodss,
    getPurchaseOrderMaterial,
    updatePurchaseOrder,
    purchaseOrder
  } = props
  const { item, loading } = purchaseOrder

  useEffect(() => {
    const { id } = match.params
    getPurchaseOrderById(id)
    setId(id)
  }, [])

  const handleOnSubmit=(item)=>{
    updatePurchaseOrder(item);
    history.push(`/purchase-order`)
  }
  
  const handleOnCancel=()=>{
    history.push(`/purchase-order`)
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Purcahse Order detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            { loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <>
                  { item ? (
                      <>
                        <Breadcrumbs
                            title="Home"
                            backLink={ "/purchase-order" }
                            breadcrumbItem={ `${ item.code } | Purchase Order Detail` }
                        />
                        <Row>
                          <Col md={ 12 }>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "1",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("1")
                                      //   getItemUnitOfMeasurements({ itemId: id });
                                    } }
                                >
                                  Info
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "2",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("2")
                                      //   getItemUnitOfMeasurements({ itemId: id });
                                    } }
                                >
                                  Design
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "3",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("3")
                                      getCuttings({ purchaseOrderId: id, pageSize: 10000 })
                                    } }
                                >
                                  Cutting
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "4",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("4")
                                      getSewings({ purchaseOrderId: id, pageSize: 10000 })
                                    } }
                                >
                                  Sewing
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "5",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("5")
                                      getPackagings({ purchaseOrderId: id, pageSize: 10000 })
                                    } }
                                >
                                  Packaging
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "6",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("6")
                                      getFinishedGoodss({ purchaseOrderId: id, pageSize: 10000 })
                                    } }
                                >
                                  Finished Goods
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "7",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("7")
                                      getPurchaseOrderMaterial(id)
                                    } }
                                >
                                  Material List
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "8",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("8")
                                      getPurchaseOrderAttachments({ purchaseOrderId: id })
                                    } }
                                >
                                  Attachments
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                    style={ { cursor: "pointer" } }
                                    className={ classNames({
                                      active: activeTab === "9",
                                    }) }
                                    onClick={ () => {
                                      setActiveTab("9")
                                        getItemUsageLimitations({ purchaseOrderId: id, pageSize: 1000 })
                                    } }
                                >
                                  Accessory Usage Limitation
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent activeTab={ activeTab }>
                              <TabPane tabId="1">
                                <PurchaseOrderForm 
                                    purchaseOrder = {item}
                                    onSubmit={ handleOnSubmit } 
                                    onCancel={ handleOnCancel }/>
                              </TabPane>
                              <TabPane tabId="2">
                                { id ? <DesignFormTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                              <TabPane tabId="3">
                                { id ? <CuttingTab purchaseOrderId={ id }/> : null }
                              </TabPane>

                              <TabPane tabId="4">
                                { id ? <SewingTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                              <TabPane tabId="5">
                                { id ? <PackagingTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                              <TabPane tabId="6">
                                { id ? <FinishedGoodsTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                              <TabPane tabId="7">
                                { id ? <MaterialListTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                              <TabPane tabId="8">
                                { id ? <PurchaseOrderAttachmentTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                              <TabPane tabId="9">
                                { id ? <ItemUsageLimitationTab purchaseOrderId={ id }/> : null }
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                      </>
                  ) : null }
                </>
            ) }
          </Container>
        </div>
      </React.Fragment>
  )
}

PurchaseOrderDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  purchaseOrder: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getPurchaseOrderById: PropTypes.func,
  getCuttings: PropTypes.func,
  getSewings: PropTypes.func,
  getPackagings: PropTypes.func,
  getFinishedGoodss: PropTypes.func,
  getPurchaseOrderMaterial: PropTypes.func,
  getPurchaseOrderAttachments: PropTypes.func,
  getItemUsageLimitations: PropTypes.func,
  updatePurchaseOrder: PropTypes.func,
}

const mapStateToProps = ({ purchaseOrder }) => {
  return {
    purchaseOrder,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPurchaseOrderById,
      getItemUnitOfMeasurements,
      getCuttings,
      getSewings,
      getPackagings,
      getFinishedGoodss,
      getPurchaseOrderMaterial,
      getPurchaseOrderAttachments,
      getItemUsageLimitations,
      updatePurchaseOrder
    })(PurchaseOrderDetail)
)
