import {
  GET_PRODUCT_STOCK_OUT_PRODUCTS,
  GET_PRODUCT_STOCK_OUT_PRODUCTS_SUCCESS,
  GET_PRODUCT_STOCK_OUT_PRODUCTS_ERROR,
  GET_PRODUCT_STOCK_OUT_PRODUCT_BY_ID,
  GET_PRODUCT_STOCK_OUT_PRODUCT_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT,
  ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_STOCK_OUT_PRODUCT,
  UPDATE_PRODUCT_STOCK_OUT_PRODUCT_SUCCESS,
  DELETE_PRODUCT_STOCK_OUT_PRODUCT,
  DELETE_PRODUCT_STOCK_OUT_PRODUCT_SUCCESS,
  PRODUCT_STOCK_OUT_PRODUCT_CLEAR_ERROR,
  ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT_ERROR,
} from "./actionTypes";

// ProductStockOutProduct
export const getProductStockOutProducts = query => ({
  type: GET_PRODUCT_STOCK_OUT_PRODUCTS,
  payload: query,
});

export const getProductStockOutProductsSuccess = payload => ({
  type: GET_PRODUCT_STOCK_OUT_PRODUCTS_SUCCESS,
  payload,
});

export const getProductStockOutProductsError = error => ({
  type: GET_PRODUCT_STOCK_OUT_PRODUCTS_ERROR,
  payload: error,
});

export const getProductStockOutProductById = id => ({
  type: GET_PRODUCT_STOCK_OUT_PRODUCT_BY_ID,
  payload: id,
});

export const getProductStockOutProductByIdSuccess = payload => ({
  type: GET_PRODUCT_STOCK_OUT_PRODUCT_BY_ID_SUCCESS,
  payload,
});

export const addNewProductStockOutProduct = item => ({
  type: ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT,
  payload: item,
});

export const addNewProductStockOutProductSuccess = item => ({
  type: ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT_SUCCESS,
  payload: item,
});

export const addNewProductStockOutProductError = error => ({
  type: ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT_ERROR,
  payload: error,
});

export const updateProductStockOutProduct = item => ({
  type: UPDATE_PRODUCT_STOCK_OUT_PRODUCT,
  payload: item,
});

export const updateProductStockOutProductSuccess = item => ({
  type: UPDATE_PRODUCT_STOCK_OUT_PRODUCT_SUCCESS,
  payload: item,
});

export const deleteProductStockOutProduct = item => ({
  type: DELETE_PRODUCT_STOCK_OUT_PRODUCT,
  payload: item,
});

export const deleteProductStockOutProductSuccess = item => ({
  type: DELETE_PRODUCT_STOCK_OUT_PRODUCT_SUCCESS,
  payload: item,
});

export const clearProductStockOutProductError = () => ({
  type: PRODUCT_STOCK_OUT_PRODUCT_CLEAR_ERROR,
});
