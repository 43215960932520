import {
  GET_UNITS,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  GET_UNIT_BY_ID,
  GET_UNIT_BY_ID_SUCCESS,
  ADD_NEW_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT,
  UNIT_CLEAR_ERROR,
} from "./actionTypes";


export const getUnits = query => ({
  type: GET_UNITS,
  payload: query,
});

export const getUnitsSuccess = units => ({
  type: GET_UNITS_SUCCESS,
  payload: units,
});

export const getUnitsFail = error => ({
  type: GET_UNITS_FAIL,
  payload: error,
});

export const getUnitById = id => ({
  type: GET_UNIT_BY_ID,
  payload: id,
});

export const getUnitByIdSuccess = unit => ({
  type: GET_UNIT_BY_ID_SUCCESS,
  payload: unit,
});

export const getUnitByIdFail = error => ({
  type: GET_UNIT_BY_ID_FAIL,
  payload: error,
});

export const addNewUnit = unit => ({
  type: ADD_NEW_UNIT,
  payload: unit,
});

export const updateUnit = unit => ({
  type: UPDATE_UNIT,
  payload: unit,
});

export const deleteUnit = unit => ({
  type: DELETE_UNIT,
  payload: unit,
});

export const clearUnitError = () => ({
  type: UNIT_CLEAR_ERROR,
});
