import {
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_BY_ID,
  GET_COMPANY_BY_ID_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_NEW_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  COMPANY_CLEAR_NOTIFY,
} from "./actionTypes";

// Company
export const getCompany = id => ({
  type: GET_COMPANY,
  payload: id,
});

export const getCompanySuccess = items => ({
  type: GET_COMPANY_SUCCESS,
  payload: items,
});

export const getCompanyFail = error => ({
  type: GET_COMPANY_FAIL,
  payload: error,
});

export const getCompanyById = data => ({
  type: GET_COMPANY_BY_ID,
  payload: data,
});

export const getCompanyByIdSuccess = item => ({
  type: GET_COMPANY_BY_ID_SUCCESS,
  payload: item,
});

export const getCompanyByIdFail = error => ({
  type: GET_COMPANY_BY_ID_FAIL,
  payload: error,
});

export const addNewCompany = payload => ({
  type: ADD_NEW_COMPANY,
  payload,
});

export const addNewCompanySuccess = payload => ({
  type: ADD_NEW_COMPANY_SUCCESS,
  payload,
});

export const updateCompany = payload => ({
  type: UPDATE_COMPANY,
  payload,
});

export const updateCompanySuccess = payload => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload,
});

export const deleteCompany = payload => ({
  type: DELETE_COMPANY,
  payload,
});

export const deleteCompanySuccess = payload => ({
  type: DELETE_COMPANY_SUCCESS,
  payload,
});

export const clearCompanyNotify = () => ({
  type: COMPANY_CLEAR_NOTIFY,
});
