import { call, put, takeEvery } from "redux-saga/effects";
import {
GET_ORDER_NOTIFICATION_HISTORY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderNotificationHistorySuccess,
  getOrderNotificationHistoryFail,
} from "./actions";

const getOrderNotificationHistoryRequest = query =>
  get(`/api/OrderPushNotification/paging`, { params: query });

  function* getOrderNotificationHistories({ payload }) {
    try {
      const response = yield call(getOrderNotificationHistoryRequest, payload);
  
      yield put(getOrderNotificationHistorySuccess(response));
    } catch (err) {
      const error = err?.response?.data?.message ?? "Something has gone wrong.";
      yield put(getOrderNotificationHistoryFail(error));
    }
  }

function* orderNotificationHistorySaga() {
  yield takeEvery(GET_ORDER_NOTIFICATION_HISTORY, getOrderNotificationHistories);
}

export default orderNotificationHistorySaga;