import {
  GET_ITEM_UNIT_OF_MEASUREMENTS,
  GET_ITEM_UNIT_OF_MEASUREMENTS_SUCCESS,
  GET_ITEM_UNIT_OF_MEASUREMENTS_ERROR,
  GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID,
  GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS,
  ADD_NEW_ITEM_UNIT_OF_MEASUREMENT,
  ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_SUCCESS,
  UPDATE_ITEM_UNIT_OF_MEASUREMENT,
  UPDATE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS,
  DELETE_ITEM_UNIT_OF_MEASUREMENT,
  DELETE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS,
  ITEM_UNIT_OF_MEASUREMENT_CLEAR_ERROR,
  ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_ERROR,
} from "./actionTypes";
export const getItemUnitOfMeasurements = query => ({
  type: GET_ITEM_UNIT_OF_MEASUREMENTS,
  payload: query,
});

export const getItemUnitOfMeasurementsSuccess = payload => ({
  type: GET_ITEM_UNIT_OF_MEASUREMENTS_SUCCESS,
  payload,
});

export const getItemUnitOfMeasurementsError = error => ({
  type: GET_ITEM_UNIT_OF_MEASUREMENTS_ERROR,
  payload: error,
});

export const getItemUnitOfMeasurementById = id => ({
  type: GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID,
  payload: id,
});

export const getItemUnitOfMeasurementByIdSuccess = payload => ({
  type: GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS,
  payload,
});

export const addNewItemUnitOfMeasurement = item => ({
  type: ADD_NEW_ITEM_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const addNewItemUnitOfMeasurementSuccess = item => ({
  type: ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const addNewItemUnitOfMeasurementError = error => ({
  type: ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_ERROR,
  payload: error,
});

export const updateItemUnitOfMeasurement = item => ({
  type: UPDATE_ITEM_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const updateItemUnitOfMeasurementSuccess = item => ({
  type: UPDATE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const deleteItemUnitOfMeasurement = item => ({
  type: DELETE_ITEM_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const deleteItemUnitOfMeasurementSuccess = item => ({
  type: DELETE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const clearItemUnitOfMeasurementError = () => ({
  type: ITEM_UNIT_OF_MEASUREMENT_CLEAR_ERROR,
});
