import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { NIL } from "uuid";
import Select from "react-select";

const ModalForm = ({
  isOpen,
  toggle,
  title,
  item,
  companyOptions,
  onSubmit,
}) => {
  const [id, setId] = useState(NIL);
  const [name, setName] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = e => {
    const { value } = e.target;
    setName(value);
  };

  const onOpened = () => {
    if (!isEmpty(item)) {
      const { id, name, companyId, companyName } = item;
      setId(id);
      setName(name);
      setCompanyId({ key: companyId, value: companyId, label: companyName });
    } else {
      setId(NIL);
      setName("");
      setCompanyId(null);
      setSubmitted(false);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const data = {
      id,
      name,
      companyId: companyId?.value,
    };

    if (data.name && data.companyId) {
      onSubmit(data);
    }
  };

  const handleSelectChange = (valueType, actionMeta) => {
    setCompanyId(valueType);
  };

  return (
    <Modal
      size="md"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col md={12}>
            <FormGroup className="mb-3">
              <Label htmlFor="">Name</Label>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                className={submitted && !name ? "is-invalid" : ""}
                value={name}
                onChange={handleChange}
              />
              {submitted && !name && (
                <div className="invalid-feedback-custom">Name is required.</div>
              )}
            </FormGroup>
            <FormGroup
              className={
                "select2-container" +
                (submitted && !companyId ? " is-invalid" : "")
              }
            >
              <Label>Company</Label>
              <Select
                name="companyId"
                value={companyId}
                onChange={handleSelectChange}
                options={companyOptions}
                classNamePrefix="select2-selection"
                isClearable
              />
            </FormGroup>
            {submitted && !companyId && (
              <div className="invalid-feedback-custom">
                Company is required.
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit} type="submit">
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  companyOptions: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default ModalForm;
