import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import NumberFormat from "react-number-format";
import { NIL } from "uuid";

export class OrderItemRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      displayOrder: 0,
      orderId: NIL,
      title: "",
      quantity: "",
      length: "",
      width: "",
      height: "",
      volume: "",
    };
  }

  componentDidMount() {
    const { item } = this.props;
    if (item) {
      this.setState({
        ...item,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { item } = props;

    if (item.id !== state.id) {
      return {
        ...item,
      };
    }
    return null;
  }

  handleChange = e => {
    const { name, value } = e.target;
    let title, quantity, length, width, height, volume;
    this.setState({ [name]: value });

    switch (name) {
      case "title":
        title = value;
        quantity = this.state.quantity;
        length = this.state.length;
        width = this.state.width;
        height = this.state.height;
        volume = this.state.volume;
        break;
      case "quantity":
        title = this.state.title;
        quantity = value;
        length = this.state.length;
        width = this.state.width;
        height = this.state.height;
        volume =
          Number(value ?? 0) *
          Number(this.state.length ?? 0) *
          Number(this.state.width ?? 0) *
          Number(this.state.height ?? 0);
        break;
    }

    this.setState({
      volume: Number(volume).toFixed(4),
    });

    const item = {
      id: this.state.id,
      displayOrder: this.state.displayOrder,
      orderId: this.state.orderId,
      title,
      quantity: Number(quantity),
      length: Number(length),
      width: Number(width),
      height: Number(height),
      volume:
        Number(quantity) * Number(length) * Number(width) * Number(height),
    };

    this.props.onChange(item);
  };

  handleOnChange = (name, value) => {
    let title, quantity, length, width, height, volume;
    this.setState({ [name]: value });
    switch (name) {
      case "length":
        title = this.state.title;
        quantity = this.state.quantity;
        length = value;
        width = this.state.width;
        height = this.state.height;
        volume =
          Number(value ?? 0) *
          Number(this.state.quantity ?? 0) *
          Number(this.state.width ?? 0) *
          Number(this.state.height ?? 0);
        break;
      case "width":
        title = this.state.title;
        quantity = this.state.quantity;
        length = this.state.length;
        width = value;
        height = this.state.height;
        volume =
          Number(value ?? 0) *
          Number(this.state.length ?? 0) *
          Number(this.state.quantity ?? 0) *
          Number(this.state.height ?? 0);
        break;
      case "height":
        title = this.state.title;
        quantity = this.state.quantity;
        length = this.state.length;
        width = this.state.width;
        height = value;
        volume = Number(
          Number(value ?? 0) *
            Number(this.state.length ?? 0) *
            Number(this.state.width ?? 0) *
            Number(this.state.quantity ?? 0)
        ).toFixed(4);

        break;
    }

    this.setState({
      volume: Number(volume).toFixed(4),
    });

    const item = {
      id: this.state.id,
      displayOrder: this.state.displayOrder,
      orderId: this.state.orderId,
      title,
      quantity: Number(quantity),
      length: Number(length),
      width: Number(width),
      height: Number(height),
      volume:
        Number(quantity) * Number(length) * Number(width) * Number(height),
    };

    this.props.onChange(item);
  };

  render() {
    const { onRemove, isFirst } = this.props;
    return (
      <Row style={{ paddingRight: "20px", paddingTop: "5px" }}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              right: "-27px",
              top: isFirst ? "27px" : "0",
            }}
          >
            <Button
              style={{
                background: "transparent",
                color: "#495057",
                border: "none",
              }}
              outline
              onClick={() => onRemove(this.state.displayOrder)}
            >
              <i className="fas fa-trash" />
            </Button>
          </div>
        </div>

        <Col md={3}>
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            {isFirst && <Label>Title</Label>}
            <Input
              type="text"
              name="title"
              maxLength={255}
              placeholder="Title"
              value={this.state.title}
              onChange={this.handleChange}
            />
          </div>
        </Col>
        <Col md={9}>
          <div className="d-flex">
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {isFirst && <Label>Quantity</Label>}
              <Input
                type="number"
                name="quantity"
                placeholder="Quantity"
                className="text-end"
                value={this.state.quantity}
                onChange={this.handleChange}
              />
            </div>
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {isFirst && <Label>Length (m)</Label>}
              <NumberFormat
                value={this.state.length}
                className="form-control text-end"
                placeholder="Length"
                thousandSeparator={true}
                fixedDecimalScale={false}
                onValueChange={obj => {
                  const { value } = obj;
                  this.handleOnChange("length", value);
                }}
              />
            </div>
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {isFirst && <Label>Width (m)</Label>}
              <NumberFormat
                value={this.state.width}
                className="form-control text-end"
                placeholder="Width"
                thousandSeparator={true}
                fixedDecimalScale={false}
                onValueChange={obj => {
                  const { value } = obj;
                  this.handleOnChange("width", value);
                }}
              />
            </div>
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {isFirst && <Label>Height (m)</Label>}
              <NumberFormat
                value={this.state.height}
                placeholder="Height"
                className="form-control text-end"
                thousandSeparator={true}
                fixedDecimalScale={false}
                onValueChange={obj => {
                  const { value } = obj;
                  this.handleOnChange("height", value);
                }}
              />
            </div>
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {isFirst && <Label>Volume (m3)</Label>}
              <NumberFormat
                name="volume"
                value={this.state.volume}
                placeholder="Volume"
                className="form-control text-end"
                thousandSeparator={true}
                fixedDecimalScale={false}
                readOnly
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

OrderItemRow.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default OrderItemRow;
