import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_PURCHASE_ORDER_ATTACHMENT,
  GET_PURCHASE_ORDER_ATTACHMENT_BY_ID,
  UPDATE_PURCHASE_ORDER_ATTACHMENT,
  DELETE_PURCHASE_ORDER_ATTACHMENT,
  GET_PURCHASE_ORDER_ATTACHMENTS,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPurchaseOrderAttachmentByIdSuccess,
  getPurchaseOrderAttachmentsError,
  getPurchaseOrderAttachmentsSuccess,
} from "./actions"

const getPurchaseOrderAttachmentsRequest = query =>
  get("/api/PurchaseOrderAttachment/paging", { params: query });
const addNewPurchaseOrderAttachmentRequest = item =>
  post("/api/PurchaseOrderAttachment", item);
const getPurchaseOrderAttachmentByIdRequest = id =>
  get(`/api/PurchaseOrderAttachment/${id}`);
const updatePurchaseOrderAttachmentRequest = item =>
  update("/api/PurchaseOrderAttachment", item);
const deletePurchaseOrderAttachmentRequest = id =>
  del(`/api/PurchaseOrderAttachment/${id}`);

const savePurchaseOrderAttachmentsRequest = item =>
    post("/api/PurchaseOrderAttachment/bulk", item);


function* getPurchaseOrderAttachments({ payload }) {
  try {
    const response = yield call(getPurchaseOrderAttachmentsRequest, payload);
    yield put(getPurchaseOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderAttachmentsError(error));
  }
}

function* addNewPurchaseOrderAttachment({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(addNewPurchaseOrderAttachmentRequest, payload);
    const response = yield call(getPurchaseOrderAttachmentsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderAttachmentsError(error));
  }
}

function* updatePurchaseOrderAttachment({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(updatePurchaseOrderAttachmentRequest, payload);
    const response = yield call(getPurchaseOrderAttachmentsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderAttachmentsError(error));
  }
}

function* getPurchaseOrderAttachmentById({ payload }) {
  try {
    let response = yield call(getPurchaseOrderAttachmentByIdRequest, payload);
    yield put(getPurchaseOrderAttachmentByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderAttachmentsError(error));
  }
}

function* deletePurchaseOrderAttachment({ payload }) {
  try {
    const { purchaseOrderId, id } = payload;
    yield call(deletePurchaseOrderAttachmentRequest, id);
    const response = yield call(getPurchaseOrderAttachmentsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderAttachmentsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderAttachmentsError(err));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PURCHASE_ORDER_ATTACHMENTS, getPurchaseOrderAttachments);
  yield takeEvery(ADD_NEW_PURCHASE_ORDER_ATTACHMENT, addNewPurchaseOrderAttachment);
  yield takeEvery(GET_PURCHASE_ORDER_ATTACHMENT_BY_ID, getPurchaseOrderAttachmentById);
  yield takeEvery(UPDATE_PURCHASE_ORDER_ATTACHMENT, updatePurchaseOrderAttachment);
  yield takeEvery(DELETE_PURCHASE_ORDER_ATTACHMENT, deletePurchaseOrderAttachment);
}

export default itemSaga;
