// PRODUCT_STOCK_OUT
export const GET_PRODUCT_STOCK_OUTS = "GET_PRODUCT_STOCK_OUTS";
export const GET_PRODUCT_STOCK_OUTS_SUCCESS = "GET_PRODUCT_STOCK_OUTS_SUCCESS";
export const GET_PRODUCT_STOCK_OUTS_ERROR = "GET_PRODUCT_STOCK_OUTS_ERROR";

export const GET_PRODUCT_STOCK_OUT_BY_ID = "GET_PRODUCT_STOCK_OUT_BY_ID";
export const GET_PRODUCT_STOCK_OUT_BY_ID_SUCCESS =
  "GET_PRODUCT_STOCK_OUT_BY_ID_SUCCESS";

export const ADD_NEW_PRODUCT_STOCK_OUT = "ADD_NEW_PRODUCT_STOCK_OUT";
export const ADD_NEW_PRODUCT_STOCK_OUT_SUCCESS =
  "ADD_NEW_PRODUCT_STOCK_OUT_SUCCESS";
export const ADD_NEW_PRODUCT_STOCK_OUT_ERROR =
  "ADD_NEW_PRODUCT_STOCK_OUT_ERROR";

export const UPDATE_PRODUCT_STOCK_OUT = "UPDATE_PRODUCT_STOCK_OUT";
export const UPDATE_PRODUCT_STOCK_OUT_SUCCESS =
  "UPDATE_PRODUCT_STOCK_OUT_SUCCESS";
export const UPDATE_PRODUCT_STOCK_OUT_ERROR = "UPDATE_PRODUCT_STOCK_OUT_ERROR";

export const DELETE_PRODUCT_STOCK_OUT = "DELETE_PRODUCT_STOCK_OUT";
export const DELETE_PRODUCT_STOCK_OUT_SUCCESS =
  "DELETE_PRODUCT_STOCK_OUT_SUCCESS";

export const GET_CONFIRM_PRODUCT_STOCK_OUT = "GET_CONFIRM_PRODUCT_STOCK_OUT";
export const GET_CONFIRM_PRODUCT_STOCK_OUT_SUCCESS =
  "GET_CONFIRM_PRODUCT_STOCK_OUT_SUCCESS";

export const PRODUCT_STOCK_OUT_CLEAR_ERROR = "PRODUCT_STOCK_OUT_CLEAR_ERROR";
