import { SEVERITY } from "helpers/utils.js";
import {
  GET_FINISHED_GOODSS,
  GET_FINISHED_GOODSS_SUCCESS,
  GET_FINISHED_GOODS_BY_ID_SUCCESS,
  GET_FINISHED_GOODSS_ERROR,
  FINISHED_GOODS_CLEAR_ERROR,
  GET_FINISHED_GOODS_BY_ID,
  ADD_NEW_FINISHED_GOODS,
  UPDATE_FINISHED_GOODS,
  DELETE_FINISHED_GOODS,
  GET_FINISHED_GOODS_ENTRY_FORM_SUCCESS,
  SAVE_FINISHED_GOODS_ENTRY_FORM_SUCCESS,
  GET_FINISHED_GOODS_ENTRY_FORM, SAVE_FINISHED_GOODS_ENTRY_FORM, CHANGE_STATUS_FINISHED_GOODS,
} from "./actionTypes.js"


const initState = {
  items: [],
  item: null,
  summary: null,
  clothingSizes: [],
  purchaseOrderItems: [],
  hasProductStockIn: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const sewing = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FINISHED_GOODS_ENTRY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case SAVE_FINISHED_GOODS_ENTRY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Finished Goods Form",
          text: "Finished Goods Entries have been saved successfully",
          severity: SEVERITY.SUCCESS,
        },
      }

    case GET_FINISHED_GOODS_ENTRY_FORM:
    case SAVE_FINISHED_GOODS_ENTRY_FORM:
    case DELETE_FINISHED_GOODS:
    case ADD_NEW_FINISHED_GOODS:
    case UPDATE_FINISHED_GOODS:
    case GET_FINISHED_GOODSS:
    // case CHANGE_STATUS_FINISHED_GOODS:
      return {
        ...state,
        loading: true,
        // item: null,
      };
    case GET_FINISHED_GOODSS_SUCCESS:
      const { itemCount, pageCount, data, clothingSizes, purchaseOrderItems, hasProductStockIn, summary } =
        payload;
      return {
        ...state,
        itemCount,
        pageCount,
        clothingSizes,
        purchaseOrderItems,
        hasProductStockIn,
        summary,
        items: data,
        // item: null,
        loading: false,
      };
    case GET_FINISHED_GOODS_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_FINISHED_GOODS_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_FINISHED_GOODSS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case FINISHED_GOODS_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default sewing;
