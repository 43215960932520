import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";

export class PermissionRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 0,
      permissionGroup: null,
      permissions: [],
      name: "",
      enable: false,
      enableOnMobile: false,
      export: false,
      read: false,
      write: false,
      delete: false,
    };
  }

  handleCheckbox = e => {
    const { name, checked } = e.target;
    let { permissions } = this.state;

    this.setState({ [e.target.name]: e.target.checked });

    switch (name) {
      case "enable":
        permissions = this.getValueUpdate(permissions, checked, "Enable");
        break;
      case "enableOnMobile":
        permissions = this.getValueUpdate(
          permissions,
          checked,
          "EnableOnMobile"
        );
        break;
      case "export":
        permissions = this.getValueUpdate(permissions, checked, "Export");
        break;
      case "read":
        permissions = this.getValueUpdate(permissions, checked, "Read");
        break;
      case "write":
        permissions = this.getValueUpdate(permissions, checked, "Write");
        break;
      case "delete":
        permissions = this.getValueUpdate(permissions, checked, "Delete");
        break;
      default:
        break;
    }

    const item = {
      name: this.state.name,
      permissions,
    };

    this.setState({
      permissions,
    });

    this.props.onChange(item);
  };

  getValueUpdate = (values, checked, name) => {
    var obj = values.find(v => v.systemName.endsWith(name));
    let newObj = {
      category: obj.category,
      enable: checked,
      id: obj.id,
      name: obj.name,
      systemName: obj.systemName,
    };

    return values.map(obj => {
      return obj.id === newObj.id ? newObj : obj;
    });
  };

  componentDidMount() {
    const { permissionGroup, index } = this.props;
    const { name, permissions } = permissionGroup;
    let enableObj = permissions.find(v => v.systemName.endsWith("Enable"));
    let enableOnMobileObj = permissions.find(v =>
      v.systemName.endsWith("EnableOnMobile")
    );
    let exportObj = permissions.find(v => v.systemName.endsWith("Export"));
    let readObj = permissions.find(v => v.systemName.endsWith("Read"));
    let writeObj = permissions.find(v => v.systemName.endsWith("Write"));
    let deleteObj = permissions.find(v => v.systemName.endsWith("Delete"));

    this.setState({
      key: index,
      permissionGroup: permissionGroup,
      name: name,
      permissions,
      enable: enableObj ? enableObj.enable : undefined,
      enableOnMobile: enableOnMobileObj ? enableOnMobileObj.enable : undefined,
      export: exportObj ? exportObj.enable : undefined,
      read: readObj ? readObj.enable : undefined,
      write: writeObj ? writeObj.enable : undefined,
      delete: deleteObj ? deleteObj.enable : undefined,
    });
  }

  render() {
    const { permissionGroup } = this.props;
    const { name } = permissionGroup;

    return (
      <Card className="border mb-1">
        <CardBody className="pt-3 pb-3">
          <Row>
            <Col md={3}>
              <h6 className="fw-bold">{name}</h6>
            </Col>
            <Col md={9} className="float-end">
              <Row>
                <Col md={2}>
                  {this.state.enableOnMobile !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="enableOnMobile"
                        className="form-check-input"
                        checked={this.state.enableOnMobile}
                        onChange={this.handleCheckbox}
                      />
                      <label className="form-check-label">
                        Enable On Mobile
                      </label>
                    </div>
                  )}
                </Col>
                <Col md={2}>
                  {this.state.enable !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="enable"
                        className="form-check-input"
                        checked={this.state.enable}
                        onChange={this.handleCheckbox}
                      />
                      <label className="form-check-label">Is Enable?</label>
                    </div>
                  )}
                </Col>
                <Col md={2}>
                  {this.state.read !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="read"
                        className="form-check-input"
                        checked={this.state.read}
                        onChange={this.handleCheckbox}
                      />
                      <label className="form-check-label">Read</label>
                    </div>
                  )}
                </Col>
                <Col md={2}>
                  {this.state.write !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="write"
                        className="form-check-input"
                        checked={this.state.write}
                        onChange={this.handleCheckbox}
                      />
                      <label className="form-check-label">Write</label>
                    </div>
                  )}
                </Col>
                <Col md={2}>
                  {this.state.delete !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="delete"
                        className="form-check-input"
                        checked={this.state.delete}
                        onChange={this.handleCheckbox}
                      />
                      <label className="form-check-label">Delete</label>
                    </div>
                  )}
                </Col>
                <Col md={2}>
                  {this.state.export !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="export"
                        className="form-check-input"
                        checked={this.state.export}
                        onChange={this.handleCheckbox}
                      />
                      <label className="form-check-label">Export</label>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

PermissionRow.propTypes = {
  index: PropTypes.number,
  permissionGroup: PropTypes.object,
  onChange: PropTypes.func,
};

export default PermissionRow;
