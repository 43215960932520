import {
  GET_INVOICE_PAYMENT_HISTORY,
  GET_INVOICE_PAYMENT_HISTORY_SUCCESS,
  GET_INVOICE_PAYMENT_HISTORY_FAIL,
} from "./actionTypes";

const initState = {
  draftIds: [],
  pendingIds: [],
  confirmedIds: [],
  rejectedIds: [],
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const InvoicePaymentHistotry = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOICE_PAYMENT_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICE_PAYMENT_HISTORY_SUCCESS:
      const {
        draftIds,
        pendingIds,
        confirmedIds,
        rejectedIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        draftIds,
        pendingIds,
        confirmedIds,
        rejectedIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_INVOICE_PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default InvoicePaymentHistotry;
