import { call, put, takeEvery } from "redux-saga/effects";
import {
GET_ORDER_MESSAGE_HISTORY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderMessageHistorySuccess,
  getOrderMessageHistoryFail,
} from "./actions";

const getOrderMessageHistoryRequest = query =>
  get(`/api/OrderSendMessage/paging`, { params: query });

  function* getOrderMessageHistories({ payload }) {
    try {
      const response = yield call(getOrderMessageHistoryRequest, payload);
  
      yield put(getOrderMessageHistorySuccess(response));
    } catch (err) {
      const error = err?.response?.data?.message ?? "Something has gone wrong.";
      yield put(getOrderMessageHistoryFail(error));
    }
  }

function* orderMessageHistorySaga() {
  yield takeEvery(GET_ORDER_MESSAGE_HISTORY, getOrderMessageHistories);
}

export default orderMessageHistorySaga;