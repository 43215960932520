import {
  GET_TRANSPORT_NOTE,
  GET_TRANSPORT_NOTE_SUCCESS,
  GET_TRANSPORT_NOTE_FAIL,
  GET_TRANSPORT_NOTE_BY_ID,
  GET_TRANSPORT_NOTE_BY_ID_SUCCESS,
  ADD_NEW_TRANSPORT_NOTE,
  UPDATE_TRANSPORT_NOTE,
  DELETE_TRANSPORT_NOTE,
  TRANSPORT_NOTE_CLEAR_ERROR,
} from "./actionTypes";


export const getTransportNote = id => ({
  type: GET_TRANSPORT_NOTE,
  payload: id,
});

export const getTransportNoteSuccess = transportnote => ({
  type: GET_TRANSPORT_NOTE_SUCCESS,
  payload: transportnote,
});

export const getTransportNoteFail = error => ({
  type: GET_TRANSPORT_NOTE_FAIL,
  payload: error,
});

export const getTransportNoteById = data => ({
  type: GET_TRANSPORT_NOTE_BY_ID,
  payload: data,
});

export const getTransportNoteByIdSuccess = transportnote => ({
  type: GET_TRANSPORT_NOTE_BY_ID_SUCCESS,
  payload: transportnote,
});

export const getTransportNoteByIdFail = error => ({
  type: GET_TRANSPORT_NOTE_BY_ID_FAIL,
  payload: error,
});

export const addNewTransportNote= transportnote => ({
  type: ADD_NEW_TRANSPORT_NOTE,
  payload: transportnote,
});

export const updateTransportNote = transportnote => ({
  type: UPDATE_TRANSPORT_NOTE,
  payload: transportnote,
});

export const deleteTransportNote = transportnote => ({
  type: DELETE_TRANSPORT_NOTE,
  payload: transportnote,
});

export const clearTransportNoteError = () => ({
  type: TRANSPORT_NOTE_CLEAR_ERROR,
});
