import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { NIL } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDeliveryAgenciesRequest } from "store/delivery-agency/saga";
import {
  addNewDelivererRequest,
  getDelivererByIdRequest,
  getDeliverersRequest,
} from "store/deliverer/saga";
import ModalForm from "containers/deliverer/ModalForm";

const statusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In-Progress" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Rejected" },
  { key: 4, value: 4, label: "Closed" },
];

export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",
      deliveryAgencyId: null,
      delivererId: null,
      description: "",
      date: null,
      status: { key: 0, value: 0, label: "Draft" },
      submitted: false,

      deliveryAgencyOptions: [],
      delivererOptions: [],
      delivererName: "",
      modalAddDelivererIsOpen: false,
    };
  }

  toggleModalAddDelivererIsOpen = () => {
    this.setState(prevState => ({
      modalAddDelivererIsOpen: !prevState.modalAddDelivererIsOpen,
    }));
  };

  componentDidMount = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
        deliveryAgencyId: item.deliveryAgencyId
          ? {
              key: item.deliveryAgencyId,
              value: item.deliveryAgencyId,
              label: item.deliveryAgency,
            }
          : [],
        delivererId: item.delivererId
          ? {
              key: item.delivererId,
              value: item.delivererId,
              label: item.deliverer?.name ?? "Unkown",
            }
          : null,
        date: item.date ? new Date(item.date) : null,
        status: {
          key: item.status,
          value: item.status,
          label: item.statusName,
        },
      });
    }

    getDeliveryAgenciesRequest().then(res => {
      this.setState({
        deliveryAgencyOptions: res.map(a => {
          return {
            key: a.index,
            label: a.name,
            value: a.id,
          };
        }),
      });
    });

    getDeliverersRequest().then(res => {
      this.setState({
        delivererOptions: res.map(a => {
          return {
            key: a.id,
            value: a.id,
            label: a.name,
          };
        }),
      });
    });
  };

  static getDerivedStateFromProps(props, state) {
    const { item } = props;
    if (item) {
      if (item.id !== state.id) {
        return {
          ...item,
          deliveryAgencyId: item.deliveryAgencyId
            ? {
                key: item.deliveryAgencyId,
                value: item.deliveryAgencyId,
                label: item.deliveryAgency,
              }
            : [],
          delivererId: item.delivererId
            ? {
                key: item.delivererId,
                value: item.delivererId,
                label: item.deliverer?.name ?? "Unkown",
              }
            : null,
          date: item.date ? new Date(item.date) : null,
          status: {
            key: item.status,
            value: item.status,
            label: item.statusName,
          },
        };
      }
    }
    return null;
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleSubmit = () => {
    const { id, title, date, delivererId, deliveryAgencyId, status } =
      this.state;
    const { onSubmit } = this.props;

    this.setState({
      submitted: true,
    });

    let isValid = title && delivererId && deliveryAgencyId;

    if (isValid) {
      const data = {
        id,
        title,
        date,
        deliveryAgencyId: deliveryAgencyId?.value ?? null,
        delivererId: delivererId?.value ?? null,
        status: status.value,
      };

      onSubmit(data);
    }
  };

  handleCategorySelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      this.setState({
        delivererName: newValue.value,
        modalAddDelivererIsOpen: true,
      });
    } else {
      this.setState({
        delivererId: newValue,
      });
    }
  };

  handleDelivererSubmit = item => {
    addNewDelivererRequest(item).then(id => {
      if (id) {
        getDelivererByIdRequest(id).then(data => {
          this.setState({
            delivererId: {
              key: data.id,
              value: data.id,
              label: data.name,
            },
            modalAddDelivererIsOpen: false,
          });
        });
      }
    });
  };

  render() {
    const {
      submitted,
      title,
      delivererId,
      deliveryAgencyId,

      deliveryAgencyOptions,
      delivererOptions,
      status,
    } = this.state;

    return (
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md={6}>
                  <FormGroup
                    className={submitted && !title ? " is-invalid" : ""}
                  >
                    <Label htmlFor="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      className={submitted && !title ? " is-invalid" : ""}
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {submitted && !title && (
                    <div className="invalid-feedback-custom">
                      Title Type is required.
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={
                      submitted && !this.state.date ? " is-invalid" : ""
                    }
                  >
                    <Label>Date</Label>
                    <DatePicker
                      id="date"
                      name="date"
                      className={
                        "form-control" +
                        (submitted && !this.state.date ? " is-invalid" : "")
                      }
                      selected={this.state.date}
                      onChange={date => this.handleDateChange(date, "date")}
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Date"
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !this.state.date && (
                    <div className="invalid-feedback-custom">
                      Date is required.
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={
                      submitted && !deliveryAgencyId ? " is-invalid" : ""
                    }
                  >
                    <Label>Delivery Agency</Label>
                    <Select
                      name="deliveryAgencyId"
                      value={this.state.deliveryAgencyId}
                      onChange={this.handleSelectChange}
                      options={deliveryAgencyOptions}
                      classNamePrefix="select2-selection"
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !deliveryAgencyId && (
                    <div className="invalid-feedback-custom">
                      Delivery Agency
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={submitted && !delivererId ? " is-invalid" : ""}
                  >
                    <Label>Deliverer</Label>
                    <CreatableSelect
                      name="delivererId"
                      value={delivererId}
                      onChange={this.handleCategorySelectChange}
                      options={delivererOptions}
                      classNamePrefix="select2-selection"
                    />
                    {/* <Select
                      name="delivererId"
                      value={delivererId}
                      onChange={this.handleSelectChange}
                      options={delivererOptions}
                      classNamePrefix="select2-selection"
                      isClearable
                    /> */}
                  </FormGroup>
                  {submitted && !delivererId && (
                    <div className="invalid-feedback-custom">
                      Deliverer is required.
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={submitted && !status ? " is-invalid" : ""}
                  >
                    <Label>Status</Label>
                    <Select
                      name="status"
                      value={this.state.status}
                      onChange={this.handleSelectChange}
                      options={statusOptions}
                      classNamePrefix="select2-selection"
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !status && (
                    <div className="invalid-feedback-custom">
                      Status is required.
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="title">Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      rows={3}
                      placeholder="Description"
                      value={this.state.description}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" onClick={this.handleSubmit}>
                  Submit{" "}
                </Button>{" "}
              </div>
            </CardBody>
          </Card>
          {this.state.delivererName && (
            <ModalForm
              title="Add New Deliverer"
              delivererName={this.state.delivererName}
              isOpen={this.state.modalAddDelivererIsOpen}
              toggle={this.toggleModalAddDelivererIsOpen}
              onSubmit={this.handleDelivererSubmit}
            />
          )}
        </Col>
      </Row>
    );
  }
}

Form.propTypes = {
  isReceiver: PropTypes.bool,
  item: PropTypes.object,
  showStatus: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Form;
