import FileSaver from "file-saver";
import {
  get,
  getFile,
  postFile,
  post,
  del,
  put as update,
} from "helpers/api_helper";

const getLabelPrintRequest = async id => {
  return await get(`/api/Order/${id}/labelPrint`);
};

const getVerifiedOrderTrackingRequest = async query => {
  return await post("/api/OrderVerifiedHistory/paging", query);
};

const bulkVerifyOrderTrackingRequest = async items =>
  post("/api/OrderVerifiedHistory/bulkVerify", items);

const exportToExcelRequest = async (query, fileName) => {
  return await postFile(`/api/Order/exportToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const downloadLabelRequest = async (id, fileName) => {
  return await getFile(`/api/Order/${id}/downloadLabel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const orderService = {
  getLabelPrintRequest,
  exportToExcelRequest,
  downloadLabelRequest,
  getVerifiedOrderTrackingRequest,
  bulkVerifyOrderTrackingRequest,
};
