import {
  GET_PAGING_CODE_COLOR,
  GET_PAGING_CODE_COLOR_SUCCESS,
  GET_PAGING_CODE_COLOR_ERROR,
  GET_CODE_COLOR_BY_ID,
  GET_CODE_COLOR_BY_ID_SUCCESS,
  ADD_NEW_CODE_COLOR,
  ADD_NEW_CODE_COLOR_SUCCESS,
  UPDATE_CODE_COLOR,
  UPDATE_CODE_COLOR_SUCCESS,
  DELETE_CODE_COLOR,
  DELETE_CODE_COLOR_SUCCESS,
  CODE_COLOR_CLEAR_ERROR,
  ADD_NEW_CODE_COLOR_ERROR,
} from "./actionTypes";
// CodeColor
export const getPagingCodeColor = query => ({
  type: GET_PAGING_CODE_COLOR,
  payload: query,
});

export const getPagingCodeColorSuccess = payload => ({
  type: GET_PAGING_CODE_COLOR_SUCCESS,
  payload,
});

export const getPagingCodeColorError = error => ({
  type: GET_PAGING_CODE_COLOR_ERROR,
  payload: error,
});

export const getCodeColorById = id => ({
  type: GET_CODE_COLOR_BY_ID,
  payload: id,
});

export const getCodeColorByIdSuccess = payload => ({
  type: GET_CODE_COLOR_BY_ID_SUCCESS,
  payload,
});

export const addNewCodeColor = item => ({
  type: ADD_NEW_CODE_COLOR,
  payload: item,
});

export const addNewCodeColorSuccess = item => ({
  type: ADD_NEW_CODE_COLOR_SUCCESS,
  payload: item,
});

export const addNewCodeColorError = error => ({
  type: ADD_NEW_CODE_COLOR_ERROR,
  payload: error,
});

export const updateCodeColor = item => ({
  type: UPDATE_CODE_COLOR,
  payload: item,
});

export const updateCodeColorSuccess = item => ({
  type: UPDATE_CODE_COLOR_SUCCESS,
  payload: item,
});

export const deleteCodeColor = item => ({
  type: DELETE_CODE_COLOR,
  payload: item,
});

export const deleteCodeColorSuccess = item => ({
  type: DELETE_CODE_COLOR_SUCCESS,
  payload: item,
});

export const clearCodeColorError = () => ({
  type: CODE_COLOR_CLEAR_ERROR,
});
