import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { NIL } from "uuid";
import moment from "moment";

const ModalForm = ({ isOpen, toggle, title, item, onSubmit }) => {
  const [id, setId] = useState(NIL);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(0);
  const [date, setDate] = useState(null);

  const [submitted, setSubmitted] = useState(false);

  const onOpened = () => {
    if (!isEmpty(item)) {
      const { id, code, name, date, status } = item;
      setId(id);
      setCode(code);
      setDate(moment(date).toDate());
      setName(name);
      setStatus(status);
    } else {
      setId(NIL);
      setCode("");
      setName("");
      setDate(new Date());
      setStatus(0);
      setSubmitted(false);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    let isValid = name && date;
    if (isValid) {
      const data = {
        id,
        code,
        name,
        date,
        status,
      };
      onSubmit(data);
    }
  };

  return (
    <Modal
      size="md"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col md={12}>
            <FormGroup className="mb-3">
              <Label htmlFor="">Code</Label>
              <Input
                type="text"
                name="code"
                placeholder="Auto Generate"
                value={code}
                readOnly
                onChange={e => {
                  setCode(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label htmlFor="">Name</Label>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                className={submitted && !name ? "is-invalid" : ""}
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
              />
              {submitted && !name && (
                <div className="invalid-feedback-custom">Name is required.</div>
              )}
            </FormGroup>
            <FormGroup
              className={
                "select2-container" + (submitted && !date ? " is-invalid" : "")
              }
            >
              <Label>Stock In Date</Label>
              <DatePicker
                className="form-control"
                selectsStart
                name="date"
                selected={date}
                onChange={obj => setDate(obj)}
                dateFormat="dd-MMM-yyyy"
                placeholderText="Stock In Date"
                isClearable
              />
            </FormGroup>
            {submitted && !date && (
              <div className="invalid-feedback-custom">
                Stock In Date is required.
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit} type="submit">
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
