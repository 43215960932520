import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ROLES,
  ADD_NEW_ROLE,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
  DELETE_ROLE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getRolesSuccess,
  getRoleByIdSuccess,
  getRolesFail,
  updateRoleSuccess,
  updatePermissionSuccess,
} from "./actions";

const getRolesRequest = query => get("/api/role/paging", { params: query });
const addNewRoleRequest = role => post("/api/role", role);
const getRoleByIdRequest = id => get(`/api/role/${id}`);
const updateRoleRequest = role => update("/api/role", role);
const deleteRoleRequest = id => del(`/api/role/${id}`);

function* fetchRoles({ payload }) {
  try {
    const response = yield call(getRolesRequest, payload);
    yield put(getRolesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* addNewRole({ payload }) {
  try {
    yield call(addNewRoleRequest, payload);
    const response = yield call(getRolesRequest, {});
    yield put(getRolesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* updateRole({ payload }) {
  try {
    const { data, permissionTab } = payload;
    yield call(updateRoleRequest, data);

    if (permissionTab) {
      var response = yield call(getRoleByIdRequest, data.id);
      yield put(getRoleByIdSuccess(response));
      yield put(updatePermissionSuccess(response));
    } else {
      let response = yield call(getRolesRequest, {});
      yield put(getRolesSuccess(response));
    }
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* getRoleById({ payload }) {
  try {
    var response = yield call(getRoleByIdRequest, payload);
    console.log(response);
    yield put(getRoleByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* deleteRole({ payload }) {
  try {
    yield call(deleteRoleRequest, payload);
    const response = yield call(getRolesRequest, {});
    yield put(getRolesSuccess(response));
  } catch (error) {
    const err = error?.response?.data?.title ?? "Something has gone wrong.";
    console.log(err);
    yield put(getRolesFail(err));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles);
  yield takeEvery(ADD_NEW_ROLE, addNewRole);
  yield takeEvery(GET_ROLE_BY_ID, getRoleById);
  yield takeEvery(UPDATE_ROLE, updateRole);
  yield takeEvery(DELETE_ROLE, deleteRole);
}

export default roleSaga;
