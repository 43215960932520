//INVOICE
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL";

export const GET_INVOICE_BY_ID = "GET_INVOICE_BY_ID";
export const GET_INVOICE_BY_ID_SUCCESS = "GET_INVOICE_BY_ID_SUCCESS";

export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";

export const ADD_NEW_INVOICE = "ADD_NEW_INVOICE";
export const ADD_NEW_INVOICE_SUCCESS = "ADD_NEW_INVOICE_SUCCESS";

export const UPDATE_INVOICE = "UPDATE_INVOICE";

export const DELETE_INVOICE = "DELETE_INVOICE";

export const INVOICE_CLEAR_ERROR = "INVOICE_CLEAR_ERROR";

export const INVOICE_CLEAR_NOTIFY = "INVOICE_CLEAR_NOTIFY";
