import { get, post, del, put as update } from "helpers/api_helper";

const getRequest = async () => {
  return await get("/api/Currency");
};

const getByQueryRequest = async query => {
  return await get("/api/Currency/getByQuery", { params: query });
};

const getDefaultRequest = async () => {
  return await get("/api/Currency/default");
};

export const currencyService = {
  getRequest,
  getDefaultRequest,
  getByQueryRequest,
};
