import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_CLOTHING_SIZE,
  GET_CLOTHING_SIZE_BY_ID,
  UPDATE_CLOTHING_SIZE,
  DELETE_CLOTHING_SIZE,
  GET_CLOTHING_SIZES,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getClothingSizeByIdSuccess,
  getClothingSizesError,
  getClothingSizesSuccess,
} from "./actions";

const getClothingSizesRequest = query =>
  get("/api/ClothingSize/paging", { params: query });
const getAllClothingSizesRequest = () => get("/api/ClothingSize");
const addNewClothingSizeRequest = item => post("/api/ClothingSize", item);
const getClothingSizeByIdRequest = id => get(`/api/ClothingSize/${id}`);
const updateClothingSizeRequest = item => update("/api/ClothingSize", item);
const deleteClothingSizeRequest = id => del(`/api/ClothingSize/${id}`);

function* getClothingSizes({ payload }) {
  try {
    const response = yield call(getClothingSizesRequest, payload);
    yield put(getClothingSizesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getClothingSizesError(error));
  }
}

function* addNewClothingSize({ payload }) {
  try {
    yield call(addNewClothingSizeRequest, payload);
    const response = yield call(getClothingSizesRequest, {});
    yield put(getClothingSizesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getClothingSizesError(error));
  }
}

function* updateClothingSize({ payload }) {
  try {
    yield call(updateClothingSizeRequest, payload);
    const response = yield call(getClothingSizesRequest, {});
    yield put(getClothingSizesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getClothingSizesError(error));
  }
}

function* getClothingSizeById({ payload }) {
  try {
    var response = yield call(getClothingSizeByIdRequest, payload);
    yield put(getClothingSizeByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getClothingSizesError(error));
  }
}

function* deleteClothingSize({ payload }) {
  try {
    yield call(deleteClothingSizeRequest, payload);
    const response = yield call(getClothingSizesRequest, {});
    yield put(getClothingSizesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getClothingSizesError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_CLOTHING_SIZES, getClothingSizes);
  yield takeEvery(ADD_NEW_CLOTHING_SIZE, addNewClothingSize);
  yield takeEvery(GET_CLOTHING_SIZE_BY_ID, getClothingSizeById);
  yield takeEvery(UPDATE_CLOTHING_SIZE, updateClothingSize);
  yield takeEvery(DELETE_CLOTHING_SIZE, deleteClothingSize);
}

export default itemSaga;

export { getAllClothingSizesRequest };
