import {
    GET_INVOICE_PAYMENT_HISTORY,
    GET_INVOICE_PAYMENT_HISTORY_SUCCESS,
    GET_INVOICE_PAYMENT_HISTORY_FAIL
  } from "./actionTypes";

  export const getInvoicePaymentHistory = query => ({
    type: GET_INVOICE_PAYMENT_HISTORY,
    payload: query,
  });
  
  export const getInvoicePaymentHistorySuccess = items => ({
    type: GET_INVOICE_PAYMENT_HISTORY_SUCCESS,
    payload: items,
  });
  
  export const getInvoicePaymentHistoryFail = error => ({
    type: GET_INVOICE_PAYMENT_HISTORY_FAIL,
    payload: error,
  });