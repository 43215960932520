import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  Input,
  Button,
  FormGroup,
  CardHeader,
  Collapse,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getInvoices,
  deleteInvoice,
  clearInvoiceNotify,
} from "../../store/actions";
import InvoiceTable from "../../containers/invoice/InvoiceTable";
import { timestampFileName, toastMessage } from "helpers/utils";

import { invoiceService } from "services/invoice-service";
import InvoiceStatusSummary from "containers/invoice/InvoiceStatusSummary";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

const paymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

class InvoiceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
      paymentStatus: null,

      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      isOpen: false,
    };
  }

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getInvoices({ page: 1, pageSize: 10, term: "" });
  }

  handleStatusFilter = ({ paymentStatus }) => {
    this.props.getInvoices({ paymentStatus });
  };

  handleOnEdit = id => {
    const { history } = this.props;
    history.push(`/invoice/${id}`);
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    const { history } = this.props;
    history.push("/transport/add-new");
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getInvoices({ term: value ?? "" });
  };

  handleSearch = () => {
    const { getInvoices } = this.props;
    const { term, startDate, endDate, status } = this.state;

    getInvoices({
      term: term ?? "",
      // startDate: startDate
      //   ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
      //   : "",
      // endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
      paymentStatus:
        status &&
        status.map(a => {
          return a.value;
        }),
    });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getInvoices({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getInvoices({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleExportToPdf = id => {
    if (id) {
      invoiceService.exportToPdf(id, `${timestampFileName()}_invoice.pdf`);
    }
  };

  componentDidUpdate() {
    const { invoice, clearInvoiceNotify } = this.props;
    const { message } = invoice;
    if (message) {
      toastMessage(message);
      clearInvoiceNotify();
    }
  }

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteInvoice(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  handleExportToExcel = () => {
    const { term, startDate, endDate } = this.state;
    invoiceService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      },
      `${timestampFileName()}_Invoice_List.xlsx`
    );
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  render() {
    const { pageSize, currentPage } = this.state;
    const { invoice } = this.props;
    const { items, itemCount, pageCount, loading } = invoice;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Invoice List | JLS Express</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Invoice List" />
            <Row>
              <Col md={12}>
                <Card className="mb-1 rounded-2">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          className="d-lg-none w-100"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>

                        <Button
                          type="button"
                          outline
                          color="primary"
                          className="d-none d-lg-block"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={11}>
                            <div className="text-sm-end">
                              <Button
                                type="button"
                                color="primary"
                                outline
                                size="md"
                                onClick={this.handleExportToExcel}
                              >
                                <i className="fas fa-file-excel" /> Export to
                                Excel
                              </Button>
                            </div>
                          </Col>
                          <Col md={1}>
                            <div onClick={this.toggleBody}>
                              <span className="font-size-24 text-primary me-2">
                                <i
                                  className={
                                    this.state.isOpen
                                      ? "fas fa-angle-up"
                                      : "fas fa-angle-down"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.isOpen}>
                    <CardBody className="pb-1">
                      <Row>
                        <Col md={3}>
                          <FormGroup>
                            <Select
                              name="status"
                              value={this.state.status}
                              onChange={this.handleSelectChange}
                              options={paymentStatusOptions}
                              classNamePrefix="select2-selection"
                              placeholder="Payment Status"
                              isClearable
                              isMulti
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <>
                    <InvoiceStatusSummary
                      item={invoice}
                      onStatusFilter={this.handleStatusFilter}
                    />

                    <InvoiceTable
                      items={items}
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={pageCount}
                      loading={loading}
                      defaultPageSize={pageSize}
                      onEdit={this.handleOnEdit}
                      onExportToPdf={this.handleExportToPdf}
                      onConfirmDelete={this.handleConfirmDelete}
                      onChangePage={this.handleOnPageChange}
                      onPageSizeChange={this.handleOnPageSizeChange}
                    />

                    <ConfirmDeleteModal
                      title="Confirm Delete"
                      isOpen={this.state.modalConfirmDeleteIsOpen}
                      toggle={this.toggleModalConfirmDelete}
                      onSubmit={this.handleDelete}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

InvoiceList.propTypes = {
  message: PropTypes.object,
  invoice: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoices: PropTypes.func,
  deleteInvoice: PropTypes.func,
  history: PropTypes.object,
  clearInvoiceNotify: PropTypes.func,
};

const mapStateToProps = ({ invoice }) => {
  return { invoice };
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoices,
    deleteInvoice,
    clearInvoiceNotify,
  })(InvoiceList)
);
