import React, { Component } from "react";
import { NIL } from "uuid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Button, CardHeader } from "reactstrap";

import {
  getOrderAttachments,
  updateOrderAttachments,
  deleteOrderAttachment,
} from "store/actions";

import OrderUploadModalForm from "containers/order/OrderUploadModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import _ from "lodash";
import OrderAttachmentTable from "containers/order/OrderAttachmentTable";

export class OrderAttachmentTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: NIL,
      selectedId: null,
      modalUploadForm: false,
      modalRemoveImage: false,
      currentPage: 1,
      pageSize: 12,
      isGallery: false,
      photoIndex: 0,
    };
  }

  componentDidMount = () => {
    const { orderId } = this.props;

    if (orderId) {
      this.setState({
        orderId,
      });
    }
  };

  toggleOrderUpload = () => {
    this.setState(prevState => ({
      modalUploadForm: !prevState.modalUploadForm,
    }));
  };

  toggleRemoveImage = () => {
    this.setState(prevState => ({
      modalRemoveImage: !prevState.modalRemoveImage,
    }));
  };

  handleUpload = () => {
    this.setState({
      modalUploadForm: true,
    });
  };

  handleSubmitUpload = items => {
    const { updateOrderAttachments } = this.props;
    const { orderId } = this.state;
    this.setState({
      modalUploadForm: false,
    });
    updateOrderAttachments({ orderId, attachments: items });
  };

  handleRemoveImage = id => {
    this.setState({
      selectedId: id,
      modalRemoveImage: true,
    });
  };

  handleSubmitRemoveImage = () => {
    const { deleteOrderAttachment } = this.props;
    const { selectedId, orderId } = this.state;

    this.setState({
      modalRemoveImage: false,
    });

    deleteOrderAttachment({ id: selectedId, orderId });
  };

  handleOnChangePage = page => {
    const { orderId, pageSize } = this.state;
    this.props.getOrderAttachments({ orderId, page, pageSize });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = size => {
    const { orderId, getOrderAttachments } = this.props;
    getOrderAttachments({ orderId, page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };

  render() {
    const { items, itemCount, pageCount, loading } = this.props;
    const { currentPage, pageSize } = this.state;
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}></Col>
              <Col md={10}>
                <div className="text-sm-end">
                  <Button color="primary" onClick={this.handleUpload}>
                    <i className="fas fa-cloud-upload-alt" /> Upload files
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {items && (
          <OrderAttachmentTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onConfirmDelete={this.handleRemoveImage}
            onChangePage={this.handleOnChangePage}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}

        <Row>
          <OrderUploadModalForm
            title={"Upload Attachments"}
            isOpen={this.state.modalUploadForm}
            toggle={this.toggleOrderUpload}
            onSubmit={this.handleSubmitUpload}
          />
          <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={this.state.modalRemoveImage}
            toggle={this.toggleRemoveImage}
            onSubmit={this.handleSubmitRemoveImage}
          />
        </Row>
      </>
    );
  }
}

OrderAttachmentTab.propTypes = {
  orderId: PropTypes.string,
  items: PropTypes.array,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  loading: PropTypes.bool,
  getOrderAttachments: PropTypes.func,
  updateOrderAttachments: PropTypes.func,
  deleteOrderAttachment: PropTypes.func,
};

const mapStateToProps = ({ orderAttachment }) => {
  return orderAttachment;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderAttachments,
    updateOrderAttachments,
    deleteOrderAttachment,
    // updateOrder,
    // getOrderById,
    // updateOrderAttachment,
    // deleteOrderAttachment,
  })(OrderAttachmentTab)
);
