import {
  GET_ITEM_USAGE_LIMITATIONS,
  GET_ITEM_USAGE_LIMITATIONS_SUCCESS,
  GET_ITEM_USAGE_LIMITATIONS_ERROR,
  GET_ITEM_USAGE_LIMITATION_BY_ID,
  GET_ITEM_USAGE_LIMITATION_BY_ID_SUCCESS,
  ADD_NEW_ITEM_USAGE_LIMITATION,
  ADD_NEW_ITEM_USAGE_LIMITATION_SUCCESS,
  UPDATE_ITEM_USAGE_LIMITATION,
  UPDATE_ITEM_USAGE_LIMITATION_SUCCESS,
  DELETE_ITEM_USAGE_LIMITATION,
  DELETE_ITEM_USAGE_LIMITATION_SUCCESS,
  ITEM_USAGE_LIMITATION_CLEAR_ERROR,
  ADD_NEW_ITEM_USAGE_LIMITATION_ERROR,
  SAVE_BULK_ITEM_USAGE_LIMITATION, SAVE_BULK_ITEM_USAGE_LIMITATION_SUCCESS,
} from "./actionTypes"

// ItemUsageLimitation
export const getItemUsageLimitations = query => ({
  type: GET_ITEM_USAGE_LIMITATIONS,
  payload: query,
});

export const getItemUsageLimitationsSuccess = payload => ({
  type: GET_ITEM_USAGE_LIMITATIONS_SUCCESS,
  payload,
});

export const getItemUsageLimitationsError = error => ({
  type: GET_ITEM_USAGE_LIMITATIONS_ERROR,
  payload: error,
});

export const getItemUsageLimitationById = id => ({
  type: GET_ITEM_USAGE_LIMITATION_BY_ID,
  payload: id,
});

export const getItemUsageLimitationByIdSuccess = payload => ({
  type: GET_ITEM_USAGE_LIMITATION_BY_ID_SUCCESS,
  payload,
});

export const addNewItemUsageLimitation = item => ({
  type: ADD_NEW_ITEM_USAGE_LIMITATION,
  payload: item,
});


export const addNewItemUsageLimitationSuccess = item => ({
  type: ADD_NEW_ITEM_USAGE_LIMITATION_SUCCESS,
  payload: item,
});

export const addNewItemUsageLimitationError = error => ({
  type: ADD_NEW_ITEM_USAGE_LIMITATION_ERROR,
  payload: error,
});

export const saveBulkItemUsageLimitation = items => ({
  type: SAVE_BULK_ITEM_USAGE_LIMITATION,
  payload: items,
});

export const saveBulkItemUsageLimitationSuccess = items => ({
  type: SAVE_BULK_ITEM_USAGE_LIMITATION_SUCCESS,
  payload: items,
});


export const updateItemUsageLimitation = item => ({
  type: UPDATE_ITEM_USAGE_LIMITATION,
  payload: item,
});

export const updateItemUsageLimitationSuccess = item => ({
  type: UPDATE_ITEM_USAGE_LIMITATION_SUCCESS,
  payload: item,
});

export const deleteItemUsageLimitation = item => ({
  type: DELETE_ITEM_USAGE_LIMITATION,
  payload: item,
});

export const deleteItemUsageLimitationSuccess = item => ({
  type: DELETE_ITEM_USAGE_LIMITATION_SUCCESS,
  payload: item,
});

export const clearItemUsageLimitationError = () => ({
  type: ITEM_USAGE_LIMITATION_CLEAR_ERROR,
});
