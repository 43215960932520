import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tbody, Thead, Tr } from "react-super-responsive-table";
import {
  getProductUnitOfMeasurements,
  addNewProductUnitOfMeasurement,
  getProductUnitOfMeasurementById,
  updateProductUnitOfMeasurement,
  deleteProductUnitOfMeasurement,
  clearProductUnitOfMeasurementError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ModalForm from "containers/item-unit-of-measurement/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";

const ProductUnitOfMeasurementTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);

  const {
    getProductUnitOfMeasurements,
    addNewProductUnitOfMeasurement,
    getProductUnitOfMeasurementById,
    updateProductUnitOfMeasurement,
    deleteProductUnitOfMeasurement,
    clearProductUnitOfMeasurementError,
    items,
    item,
    message,
    itemCount,
    pageCount,
    loading,
    productId,
  } = props;

  useEffect(() => {
    console.log(productId);
    getProductUnitOfMeasurements({ productId });
  }, []);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductUnitOfMeasurementError();
    }
  }, [message]);

  const handleSubmit = data => {
    const dataSubmit = {
      ...data,
      productId,
    };

    addNewProductUnitOfMeasurement(dataSubmit);
    setModalAddIsOpen(false);
  };

  const handleUpdate = data => {
    const dataSubmit = {
      ...data,
      productId,
    };
    updateProductUnitOfMeasurement(dataSubmit);
    setModalEditIsOpen(false);
  };

  const handleDelete = () => {
    deleteProductUnitOfMeasurement({ productId, id: selectedId });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
    <div>
      <Card className="mb-1">
        <CardHeader className="bg-transparent border-bottom">
          <Row className="g-1">
            <Col md={2}>
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    placeholder="Quick Search ..."
                    onChange={e => {
                      const { value } = e.target;
                      //   debouncedQuickSearch(value);
                    }}
                  ></Input>
                  <i className="fas fa-search search-icon" />
                </div>
              </div>
            </Col>
            <Col md={10}>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="primary"
                  className="me-1"
                  onClick={() => setModalAddIsOpen(true)}
                >
                  <i className="fas fa-plus" /> Add New
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap" 
                >
                  <thead>
                    <tr>
                      <th style={{ width: "80px" }}>#</th>
                      <th className="text-center">UoM</th>
                      <th className="text-center">Conversion Rate</th>
                      <th className="text-center">Display Order</th>
                      <th>Note</th>
                      <th style={{ width: "120px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num = (page - 1) * pageSize + (index + 1);
                      return (
                        <tr key={index}>
                          <td style={{ textAlign: "right" }}>{num}</td>
                          <td>{item.unitOfMeasurementName}</td>
                          <td className="text-end">
                            {item.unitOfMeasurementConversionRate}
                          </td>
                          <td className="text-end">{item.displayOrder}</td>
                          <td>{item.note}</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-primary" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    getProductUnitOfMeasurementById(item.id);
                                    setModalEditIsOpen(true);
                                  }}
                                ></i>
                              </Link>

                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setSelectedId(item.id);
                                    setModalConfirmDeleteIsOpen(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={page}
            totalPage={pageCount}
            defaultPageSize={pageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            // onChangePage={i => onChangePage(i)}
            // onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
      <ModalForm
        title="Add Unit of Measurement"
        isOpen={modalAddIsOpen}
        toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
        onSubmit={handleSubmit}
      />
      {item ? (
        <ModalForm
          title="Edit Unit of Measurement"
          item={item}
          isOpen={modalEditIsOpen}
          toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
          onSubmit={handleUpdate}
        />
      ) : null}

      <ConfirmDeleteModal
        title="Confirm Delete"
        isOpen={modalConfirmDeleteIsOpen}
        toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
        onSubmit={handleDelete}
      />
    </div>
  );
};

ProductUnitOfMeasurementTab.propTypes = {
  productId: PropTypes.string,
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getProductUnitOfMeasurements: PropTypes.func,
  addNewProductUnitOfMeasurement: PropTypes.func,
  getProductUnitOfMeasurementById: PropTypes.func,
  updateProductUnitOfMeasurement: PropTypes.func,
  deleteProductUnitOfMeasurement: PropTypes.func,
  clearProductUnitOfMeasurementError: PropTypes.func,
};

const mapStateToProps = ({ productUnitOfMeasurement }) => {
  return productUnitOfMeasurement;
};

export default withRouter(
  connect(mapStateToProps, {
    getProductUnitOfMeasurements,
    addNewProductUnitOfMeasurement,
    getProductUnitOfMeasurementById,
    updateProductUnitOfMeasurement,
    deleteProductUnitOfMeasurement,
    clearProductUnitOfMeasurementError,
  })(ProductUnitOfMeasurementTab)
);
