import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from "reactstrap"
import StockRequestDetailToPrint from "./StockRequestDetailToPrint"
import { useReactToPrint } from "react-to-print"
import { getStockRequestByIdRequest } from "../../store/stock-request/saga"

const PrintPreviewModal = ({ isOpen, toggle, stockRequestId }) => {
  const [ stockRequest, setStockRequest ] = useState(null)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      console.log("onBeforeGetContent print")

    },
    onAfterPrint: () => {
      console.log("After print")
      componentRef.current = null
      // setIsPrint(false);
    }
  })


  const onOpened = () => {
    getStockRequestByIdRequest(stockRequestId).then(data => {
      console.log(data)
      setStockRequest(data)
    })
  }
  return (
      <Modal
          size="xl"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Print Preview
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div style={ { height: "350px", overflow: "auto", background: "gray", padding: "10px" } }>
            {
              stockRequest ?
                  <StockRequestDetailToPrint ref={ componentRef } stockRequest={ stockRequest }/>
                  : <div className="loading"></div>
            }
          </div>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handlePrint }>
            <i className="mdi mdi-printer"/> Print Now
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

PrintPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  stockRequestId: PropTypes.string,
}

export default PrintPreviewModal