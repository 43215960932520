import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_SHIPPING_CONTACT,
  ADD_NEW_SHIPPING_CONTACT,
  GET_SHIPPING_CONTACT_BY_ID,
  UPDATE_SHIPPING_CONTACT,
  DELETE_SHIPPING_CONTACT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getShippingContactSuccess,
  getShippingContactByIdSuccess,
  getShippingContactFail,
} from "./actions";

const getShippingContactRequest = query =>
  get(`/api/ShippingContact/paging`, {
    params: query,
  });

const getDefaultShippingContactRequest = query =>
  get(`/api/ShippingContact/default`, {
    params: query,
  });
const addNewShippingContactRequest = shipping =>
  post(`/api/ShippingContact`, shipping);
const getShippingContactByIdRequest = id => get(`/api/ShippingContact/${id}`);
const updateShippingContactRequest = shipping =>
  update(`/api/ShippingContact`, shipping);
const deleteShippingContactRequest = id => del(`/api/ShippingContact/${id}`);

function* fetchShippings({ payload }) {
  try {
    const response = yield call(getShippingContactRequest, payload);

    yield put(getShippingContactSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getShippingContactFail(error));
  }
}

function* addNewShippingContact({ payload }) {
  try {
    const { userId, data } = payload;
    yield call(addNewShippingContactRequest, data);
    const response = yield call(getShippingContactRequest, { userId });
    yield put(getShippingContactSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getShippingContactFail(error));
  }
}

function* updateShippingContact({ payload }) {
  try {
    const { userId, data } = payload;
    yield call(updateShippingContactRequest, data);
    const response = yield call(getShippingContactRequest, { userId });
    yield put(getShippingContactSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getShippingContactFail(error));
  }
}

function* getShippingContactById({ payload }) {
  try {
    var response = yield call(getShippingContactByIdRequest, payload);
    yield put(getShippingContactByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getShippingContactFail(error));
  }
}

function* deleteShippingContact({ payload }) {
  try {
    const { userId, id } = payload;
    console.log(payload);
    yield call(deleteShippingContactRequest, id);

    const response = yield call(getShippingContactRequest, { userId });
    yield put(getShippingContactSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getShippingContactFail(error));
  }
}

function* ShippingContactSaga() {
  yield takeEvery(GET_SHIPPING_CONTACT, fetchShippings);
  yield takeEvery(ADD_NEW_SHIPPING_CONTACT, addNewShippingContact);
  yield takeEvery(GET_SHIPPING_CONTACT_BY_ID, getShippingContactById);
  yield takeEvery(UPDATE_SHIPPING_CONTACT, updateShippingContact);
  yield takeEvery(DELETE_SHIPPING_CONTACT, deleteShippingContact);
}
export {
  getShippingContactRequest,
  addNewShippingContactRequest,
  updateShippingContactRequest,
  getShippingContactByIdRequest,
  getDefaultShippingContactRequest,
};

export default ShippingContactSaga;
