// STOCK_IN_ITEM
export const GET_STOCK_IN_ITEMS = "GET_STOCK_IN_ITEMS";
export const GET_STOCK_IN_ITEMS_SUCCESS = "GET_STOCK_IN_ITEMS_SUCCESS";
export const GET_STOCK_IN_ITEMS_ERROR = "GET_STOCK_IN_ITEMS_ERROR";

export const GET_STOCK_IN_ITEM_BY_ID = "GET_STOCK_IN_ITEM_BY_ID";
export const GET_STOCK_IN_ITEM_BY_ID_SUCCESS =
  "GET_STOCK_IN_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_IN_ITEM = "ADD_NEW_STOCK_IN_ITEM";
export const ADD_NEW_STOCK_IN_ITEM_SUCCESS = "ADD_NEW_STOCK_IN_ITEM_SUCCESS";
export const ADD_NEW_STOCK_IN_ITEM_ERROR = "ADD_NEW_STOCK_IN_ITEM_ERROR";

export const UPDATE_STOCK_IN_ITEM = "UPDATE_STOCK_IN_ITEM";
export const UPDATE_STOCK_IN_ITEM_SUCCESS = "UPDATE_STOCK_IN_ITEM_SUCCESS";
export const UPDATE_STOCK_IN_ITEM_ERROR = "UPDATE_STOCK_IN_ITEM_ERROR";

export const DELETE_STOCK_IN_ITEM = "DELETE_STOCK_IN_ITEM";
export const DELETE_STOCK_IN_ITEM_SUCCESS = "DELETE_STOCK_IN_ITEM_SUCCESS";

export const STOCK_IN_ITEM_CLEAR_ERROR = "STOCK_IN_ITEM_CLEAR_ERROR";
