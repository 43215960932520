import {
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDERS_SUCCESS,
  GET_PURCHASE_ORDERS_ERROR,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_BY_ID_SUCCESS,
  ADD_NEW_PURCHASE_ORDER,
  ADD_NEW_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_SUCCESS,
  PURCHASE_ORDER_CLEAR_ERROR,
  ADD_NEW_PURCHASE_ORDER_ERROR,
  GET_CONFIRM_PURCHASE_ORDER,
  GET_CONFIRM_PURCHASE_ORDER_SUCCESS,
  PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN,
  PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN_SUCCESS,
  GET_PURCHASE_ORDER_MATERIAL,
  GET_PURCHASE_ORDER_MATERIAL_SUCCESS,
  GET_PURCHASE_ORDER_SUMMARY, GET_PURCHASE_ORDER_SUMMARY_SUCCESS,
} from "./actionTypes"
// PurchaseOrder
export const getPurchaseOrders = query => ({
  type: GET_PURCHASE_ORDERS,
  payload: query,
});

export const getPurchaseOrdersSuccess = payload => ({
  type: GET_PURCHASE_ORDERS_SUCCESS,
  payload,
});

export const getPurchaseOrdersError = error => ({
  type: GET_PURCHASE_ORDERS_ERROR,
  payload: error,
});

export const getPurchaseOrderById = id => ({
  type: GET_PURCHASE_ORDER_BY_ID,
  payload: id,
});

export const getPurchaseOrderByIdSuccess = payload => ({
  type: GET_PURCHASE_ORDER_BY_ID_SUCCESS,
  payload,
});

export const getPurchaseOrderMaterial = id => ({
  type: GET_PURCHASE_ORDER_MATERIAL,
  payload: id,
});

export const getPurchaseOrderMaterialSuccess = payload => ({
  type: GET_PURCHASE_ORDER_MATERIAL_SUCCESS,
  payload,
});

export const addNewPurchaseOrder = item => ({
  type: ADD_NEW_PURCHASE_ORDER,
  payload: item,
});

export const addNewPurchaseOrderSuccess = item => ({
  type: ADD_NEW_PURCHASE_ORDER_SUCCESS,
  payload: item,
});

export const addNewPurchaseOrderError = error => ({
  type: ADD_NEW_PURCHASE_ORDER_ERROR,
  payload: error,
});

export const updatePurchaseOrder = item => ({
  type: UPDATE_PURCHASE_ORDER,
  payload: item,
});

export const updatePurchaseOrderSuccess = item => ({
  type: UPDATE_PURCHASE_ORDER_SUCCESS,
  payload: item,
});

export const deletePurchaseOrder = item => ({
  type: DELETE_PURCHASE_ORDER,
  payload: item,
});

export const deletePurchaseOrderSuccess = item => ({
  type: DELETE_PURCHASE_ORDER_SUCCESS,
  payload: item,
});

export const clearPurchaseOrderError = () => ({
  type: PURCHASE_ORDER_CLEAR_ERROR,
});

export const getConfirmPurchaseOrder = payload => ({
  type: GET_CONFIRM_PURCHASE_ORDER,
  payload,
});

export const getConfirmPurchaseOrderSuccess = () => ({
  type: GET_CONFIRM_PURCHASE_ORDER_SUCCESS,
});

export const generatePurchaseOrderProductStockIn = id => ({
  type: PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN,
  payload: id,
});

export const generatePurchaseOrderProductStockInSuccess = item => ({
  type: PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN_SUCCESS,
  payload: item,
});

export const getPurchaseOrderSummary = query => ({
  type: GET_PURCHASE_ORDER_SUMMARY,
  payload: query,
});

export const getPurchaseOrderSummarySuccess = payload => ({
  type: GET_PURCHASE_ORDER_SUMMARY_SUCCESS,
  payload,
});
