import {
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAIL,
  GET_FEEDBACK_BY_ID,
  GET_FEEDBACK_BY_ID_SUCCESS,
  ADD_NEW_FEEDBACK,
  ADD_NEW_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK,
  UPDATE_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK,
  DELETE_FEEDBACK_SUCCESS,
  FEEDBACK_CLEAR_NOTIFY,
} from "./actionTypes";
// Feedback
export const getFeedback = id => ({
  type: GET_FEEDBACK,
  payload: id,
});

export const getFeedbackSuccess = items => ({
  type: GET_FEEDBACK_SUCCESS,
  payload: items,
});

export const getFeedbackFail = error => ({
  type: GET_FEEDBACK_FAIL,
  payload: error,
});

export const getFeedbackById = data => ({
  type: GET_FEEDBACK_BY_ID,
  payload: data,
});

export const getFeedbackByIdSuccess = item => ({
  type: GET_FEEDBACK_BY_ID_SUCCESS,
  payload: item,
});

export const getFeedbackByIdFail = error => ({
  type: GET_FEEDBACK_BY_ID_FAIL,
  payload: error,
});

export const addNewFeedback = payload => ({
  type: ADD_NEW_FEEDBACK,
  payload,
});

export const addNewFeedbackSuccess = payload => ({
  type: ADD_NEW_FEEDBACK_SUCCESS,
  payload,
});

export const updateFeedback = payload => ({
  type: UPDATE_FEEDBACK,
  payload,
});

export const updateFeedbackSuccess = payload => ({
  type: UPDATE_FEEDBACK_SUCCESS,
  payload,
});

export const deleteFeedback = payload => ({
  type: DELETE_FEEDBACK,
  payload,
});

export const deleteFeedbackSuccess = payload => ({
  type: DELETE_FEEDBACK_SUCCESS,
  payload,
});

export const clearFeedbackNotify = () => ({
  type: FEEDBACK_CLEAR_NOTIFY,
});
