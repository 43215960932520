import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class NotificationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
    };
  }

  render() {
    const { isOpen, toggle, item } = this.props;

    const { notification } = item;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {notification.title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <FormGroup>
            <Input
              type="textarea"
              rows="6"
              value={notification.content}
              readOnly
            ></Input>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

NotificationModal.propTypes = {
  order: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  item: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default NotificationModal;
