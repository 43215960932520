import {
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAIL,
  GET_WAREHOUSE_BY_ID_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const warehouse = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_WAREHOUSES:
      return {
        ...state,
        loading: true,
      };
    case GET_WAREHOUSES_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_WAREHOUSE_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_WAREHOUSES_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default warehouse;
