import {
  GET_ORDER_NOTIFICATION_HISTORY,
  GET_ORDER_NOTIFICATION_HISTORY_SUCCESS,
  GET_ORDER_NOTIFICATION_HISTORY_FAIL,
} from "./actionTypes";

export const getOrderNotificationHistory = id => ({
  type: GET_ORDER_NOTIFICATION_HISTORY,
  payload: id,
});

export const getOrderNotificationHistorySuccess = payload => ({
  type: GET_ORDER_NOTIFICATION_HISTORY_SUCCESS,
  payload
});

export const getOrderNotificationHistoryFail = error => ({
  type: GET_ORDER_NOTIFICATION_HISTORY_FAIL,
  payload: error,
});