import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { NIL } from "uuid";
import PropTypes from "prop-types";
import Select from "react-select";
import { roleService } from "services/role-service";
import avatar from "../../assets/images/avatar.png";
import ReactInputMask from "react-input-mask";
const countries = [
  { key: 0, value: "+855", label: "Cambodia (+855)", flag: "🇰🇭" },
  { key: 1, value: "+82", label: "South Korea (+82)", flag: "🇰🇷" },
];

export class UserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      avatar: "",
      roles: [],
      location: null,
      locationId: null,
      roleOptions: [],
      submitted: false,
      isKoreanNumber: false,
      selectedCountry: {
        key: 1,
        value: "+82",
        label: "South Korea (+82)",
        flag: "🇰🇷",
      },
    };
  }

  componentDidMount() {
    const { item } = this.props;
    const { phoneNumber } = item;
    if (item) {
      this.setState({
        ...item,
        email: item?.email ?? "",
        // phoneNumber: phoneNumber ?? "",
        // address: item.address ?? "",
        selectedCountry:
          phoneNumber && phoneNumber.startsWith("+82")
            ? countries.find(e => e.value === "+82")
            : countries.find(e => e.value === "+855"),
      });
    } else {
      this.setState({
        id: NIL,
        firstName: "",
        lastName: "",
        // uniqueId: "",
        username: "",
        email: "",
        // phoneNumber: "",
        // address: "",
        avatar: null,
        roles: [],
        location: null,
        locationId: null,

        submitted: false,
        isKoreanNumber: false,
      });
    }

    roleService.getRoleRequest().then(data => {
      if (data) {
        this.setState({
          roleOptions: data.map(item => {
            return {
              key: item.id,
              value: item.id,
              label: item.name,
            };
          }),
        });
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { item } = props;

    if (item) {
      if (item.id !== state.id) {
        const { phoneNumber } = item;
        return {
          ...item,
          email: item?.email ?? "",
          phoneNumber: phoneNumber ?? "",
          address: item.address ?? "",
          selectedCountry:
            phoneNumber && phoneNumber.startsWith("+82")
              ? countries.find(e => e.value === "+82")
              : countries.find(e => e.value === "+855"),
        };
      }
    }

    return {
      ...state,
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleSubmit = () => {
    const {
      id,
      firstName,
      lastName,
      // uniqueId,
      username,
      email,
      // phoneNumber,
      // address,
      // avatar,
      roles,
      // location,
      // locationId,
    } = this.state;

    const data = {
      id,
      firstName,
      lastName,
      // uniqueId,
      username,
      email,
      // phoneNumber,
      // address,
      // avatar,
      roles,
      // location,
      // locationId,
    };

    var isValid = data.firstName && data.lastName;

    if (isValid) {
      this.props.onSubmit(data);
    }

    this.setState({
      submitted: true,
    });
  };

  handleSelectCountryChange = country => {
    this.setState({
      selectedCountry: country,
    });
  };

  render() {
    const { submitted, selectedCountry } = this.state;
    var localAvatar = this.state.avatar ?? avatar;

    return (
      <>
        <Card className="mb-2">
          <CardBody>
            <Row>
              <Col md={4} className="text-center">
                <Card className="border">
                  <CardHeader className="border">
                    <CardTitle>Profile</CardTitle>
                  </CardHeader>
                  <CardBody className="d-flex justify-content-center">
                    <div className="w-50">
                      <img
                        src={localAvatar}
                        className="img-fluid card-img-top rounded-circle"
                        alt=""
                      />
                    </div>
                  </CardBody>
                  {/* <CardFooter>
                    <Button outline size="sm" color="primary">
                      Change Profile
                    </Button>
                  </CardFooter> */}
                </Card>
              </Col>
              <Col md={4}>
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={this.state.username}
                    readOnly
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className={
                      submitted && !this.state.firstName ? "is-invalid" : ""
                    }
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                  {submitted && !this.state.firstName && (
                    <div className="invalid-feedback-custom">
                      First Name is required.
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className={
                      submitted && !this.state.lastName ? "is-invalid" : ""
                    }
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                  {submitted && !this.state.lastName && (
                    <div className="invalid-feedback-custom">
                      Last Name is required.
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label htmlFor="Email">Email</Label>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <label>Roles</label>
                  <Select
                    name="roles"
                    placeholder="Select Role..."
                    value={this.state.roles}
                    options={this.state.roleOptions}
                    onChange={this.handleSelectChange}
                    isMulti
                  />
                </div>
                {/* <div className="mb-3">
                  <Label>Address</Label>
                  <Input
                    type="textarea"
                    id="address"
                    name="address"
                    maxLength="225"
                    rows="4"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </div> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="float-end">
              <Button color="primary" onClick={this.handleSubmit}>
                Submit{" "}
              </Button>{" "}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

UserForm.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default UserForm;
