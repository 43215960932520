import {
  GET_CUTTINGS,
  GET_CUTTINGS_SUCCESS,
  GET_CUTTINGS_ERROR,
  GET_CUTTING_BY_ID,
  GET_CUTTING_BY_ID_SUCCESS,
  ADD_NEW_CUTTING,
  ADD_NEW_CUTTING_SUCCESS,
  UPDATE_CUTTING,
  UPDATE_CUTTING_SUCCESS,
  DELETE_CUTTING,
  DELETE_CUTTING_SUCCESS,
  CUTTING_CLEAR_ERROR,
  ADD_NEW_CUTTING_ERROR,
  GET_CUTTING_ENTRY_FORM,
  GET_CUTTING_ENTRY_FORM_SUCCESS,
  SAVE_CUTTING_ENTRY_FORM,
  SAVE_CUTTING_ENTRY_FORM_SUCCESS,
} from "./actionTypes"

// Cutting
export const getCuttings = query => ({
  type: GET_CUTTINGS,
  payload: query,
});

export const getCuttingsSuccess = payload => ({
  type: GET_CUTTINGS_SUCCESS,
  payload,
});

export const getCuttingEntryForm = query => ({
  type: GET_CUTTING_ENTRY_FORM,
  payload: query,
});

export const getCuttingEntryFormSuccess = payload => ({
  type: GET_CUTTING_ENTRY_FORM_SUCCESS,
  payload,
});

export const saveCuttingEntryForm = query => ({
  type: SAVE_CUTTING_ENTRY_FORM,
  payload: query,
});

export const saveCuttingEntryFormSuccess = payload => ({
  type: SAVE_CUTTING_ENTRY_FORM_SUCCESS,
  payload,
});

export const getCuttingsError = error => ({
  type: GET_CUTTINGS_ERROR,
  payload: error,
});

export const getCuttingById = id => ({
  type: GET_CUTTING_BY_ID,
  payload: id,
});

export const getCuttingByIdSuccess = payload => ({
  type: GET_CUTTING_BY_ID_SUCCESS,
  payload,
});

export const addNewCutting = item => ({
  type: ADD_NEW_CUTTING,
  payload: item,
});

export const addNewCuttingSuccess = item => ({
  type: ADD_NEW_CUTTING_SUCCESS,
  payload: item,
});

export const addNewCuttingError = error => ({
  type: ADD_NEW_CUTTING_ERROR,
  payload: error,
});

export const updateCutting = item => ({
  type: UPDATE_CUTTING,
  payload: item,
});

export const updateCuttingSuccess = item => ({
  type: UPDATE_CUTTING_SUCCESS,
  payload: item,
});

export const deleteCutting = item => ({
  type: DELETE_CUTTING,
  payload: item,
});

export const deleteCuttingSuccess = item => ({
  type: DELETE_CUTTING_SUCCESS,
  payload: item,
});

export const clearCuttingError = () => ({
  type: CUTTING_CLEAR_ERROR,
});
