import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { currencyService } from "services/currency-service";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      itemShippingFeeId: null,
      from: 0,
      to: 0,
      value: 0,
      type: "",
      currencyId: null,

      submitted: false,
      currencyOptions: [],
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { itemShippingFeeId } = this.props;
    const { id, from, to, value, currencyId } = this.state;
    this.setState({
      submitted: true,
    });

    const item = {
      id,
      itemShippingFeeId,
      from,
      to,
      value,
      type: "",
      currencyId: currencyId?.value,
    };

    var isValid = item.from && item.to && item.value && item.currencyId;

    if (isValid) {
      this.props.onSubmit(item);
    }
  };

  componentDidMount() {
    const { itemShippingFeeId } = this.props;

    this.setState({
      itemShippingFeeId,
    });

    currencyService.getRequest().then(data => {
      if (data) {
        this.setState({
          currencyOptions: data
            .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.symbol,
              };
            }),
        });
      }
    });

    currencyService.getDefaultRequest().then(data => {
      if (data) {
        this.setState({
          currencyId: {
            key: data.id,
            value: data.id,
            label: data.symbol,
          },
        });
      }
    });
  }

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
      });
    } else {
      this.setState({
        id: NIL,
        title: "",
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  onOpened = () => {
    const { item } = this.props;
    if (item) {
      this.setState({
        ...item,
        value: item.value,
        currencyId: {
          key: item.currencyId,
          value: item.currencyId,
          label: item.currency,
        },
      });
    } else {
      this.setState({
        from: 0,
        to: 0,
        value: 0,
        type: "",
        submitted: false,
      });
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted, currencyOptions } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Form</Label>
                <NumberFormat
                  name="from"
                  value={this.state.from}
                  className={
                    "form-control text-end " +
                    (submitted && !this.state.from ? "is-invalid" : "")
                  }
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  //   prefix={`${currencyLabel} `}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.setState({ from: value });
                  }}
                />
                {submitted && !this.state.from && (
                  <div className="invalid-feedback-custom">
                    From is required.
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">To</Label>
                <NumberFormat
                  name="to"
                  value={this.state.to}
                  className={
                    "form-control text-end " +
                    (submitted && !this.state.to ? "is-invalid" : "")
                  }
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  //   prefix={`${currencyLabel} `}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.setState({ to: value });
                  }}
                />
                {submitted && !this.state.price && (
                  <div className="invalid-feedback-custom">To is required.</div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Value</Label>
                <NumberFormat
                  name="value"
                  value={this.state.value}
                  className={
                    "form-control text-end " +
                    (submitted && !this.state.value ? "is-invalid" : "")
                  }
                  thousandSeparator={true}
                  fixedDecimalScale={false}
                  //   prefix={`${currencyLabel} `}
                  onValueChange={obj => {
                    const { value } = obj;
                    this.setState({ value });
                  }}
                />
                {submitted && !this.state.price && (
                  <div className="invalid-feedback-custom">
                    Value is required.
                  </div>
                )}
              </FormGroup>
              <div className="mb-3">
                <div
                  className={
                    "select2-container" +
                    (submitted && !this.state.currencyId ? " is-invalid" : "")
                  }
                >
                  <Label>Currency</Label>
                  <Select
                    name="currencyId"
                    value={this.state.currencyId}
                    onChange={this.handleSelectChange}
                    options={currencyOptions}
                    classNamePrefix="test select2-selection"
                    isClearable
                  />
                </div>
                {submitted && !this.state.currencyId && (
                  <div className="invalid-feedback-custom">
                    Currency is required.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
  itemShippingFeeId: PropTypes.string,
};

export default ModalForm;
