import React, { Component } from "react";
import { Button, Col, FormGroup, Modal, Label, Row } from "reactstrap";
import QRCode from "qrcode";
import Barcode from "react-barcode";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import { orderService } from "services/order-service";
import moment from "moment";
const tdStyle = {
  borderWidth: "1px",
  borderColor: "black",
  borderStyle: "solid",
  padding: "5px",
  verticalAlign: "top",
  fontSize: "16px",
  fontFamily: "Arial, Helvetica, sans-serif",
  fontWeight: "bolder",
};
const tableStyle = {
  borderWidth: "1px",
  borderColor: "black",
  borderStyle: "solid",
  padding: "5px",
  width: "100%",
  borderCollapse: "collapse",
};

export class PrintPreviewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      orderQRCode: "",
      base64Pdf: null,
      trackingQRCode: "",
    };
  }

  handleSubmit = () => {
    var divContents = document.getElementById("content-data").innerHTML;
    var a = window.open("", "_blank", "popup=yes");
    a.document.write("<html>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
    this.props.onSubmit();
  };

  onOpened = () => {
    const { orderId } = this.props;

    if (orderId && orderId !== NIL) {
      orderService.getLabelPrintRequest(orderId).then(data => {
        if (data) {
          this.setState({ base64Pdf: data });
        }
      });
    }
  };

  render() {
    const { item } = this.state;
    const { isOpen, toggle, title } = this.props;
    return (
      <Modal
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div id="content-data" className="modal-body">
          {this.state.base64Pdf && (
            <embed
              type="application/pdf"
              width="100%"
              height="500"
              src={`data:application/pdf;base64,${this.state.base64Pdf}`}
            />
          )}
        </div>
        <div className="modal-footer">
          {/* <Button color="primary" onClick={this.handleSubmit} type="submit">
            Print
          </Button> */}
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

PrintPreviewModal.propTypes = {
  orderId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default PrintPreviewModal;
