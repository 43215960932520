export const GET_TRANSPORT_NOTE = "GET_TRANSPORT_NOTE";
export const GET_TRANSPORT_NOTE_SUCCESS = "GET_TRANSPORT_NOTE_SUCCESS";
export const GET_TRANSPORT_NOTE_FAIL = "GET_TRANSPORT_NOTE_FAIL";

export const GET_TRANSPORT_NOTE_BY_ID = "GET_TRANSPORT_NOTE_BY_ID";
export const GET_TRANSPORT_NOTE_BY_ID_SUCCESS = "GET_TRANSPORT_NOTE_BY_ID_SUCCESS";

export const ADD_NEW_TRANSPORT_NOTE = "ADD_NEW_TRANSPORT_NOTE";

export const UPDATE_TRANSPORT_NOTE = "UPDATE_TRANSPORT_NOTE";

export const DELETE_TRANSPORT_NOTE = "DELETE_TRANSPORT_NOTE";

export const TRANSPORT_NOTE_CLEAR_ERROR = "TRANSPORT_NOTE_CLEAR_ERROR";
