import React, { useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { NIL } from "uuid"
import Select from "react-select"
import { validateFinishedGoodsRequest } from "../../store/finished-goods/saga"

const statusOptions = [
  { key: 1, value: 0, label: 'Draft' },
  { key: 2, value: 1, label: 'Pending' }
]
// purchaseOrderId
const ModalForm = ({
                     isOpen,
                     toggle,
                     title,
                     purchaseOrderId,
                     item,
                     onSubmit,
                   }) => {
  const [ id, setId ] = useState(NIL)
  const [ itemId, setItemId ] = useState(null)
  const [ status, setStatus ] = useState(null)
  const [ clothingSizeId, setClothingSizeId ] = useState(null)
  const [ quantity, setQuantity ] = useState(0)
  const [ valid, setValid ] = useState(false)
  const [ exceed, setExceed ] = useState(0)

  const [ term, setTerm ] = useState("")
  const [ submitted, setSubmitted ] = useState(false)

  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])

  const onOpened = () => {
    if (item) {
      setId(item.id)
      setItemId({
        id: item.itemId,
        value: item.itemId,
        label: `${ item.itemCode } - ${ item.itemName }`,
      })
      setStatus({
        key: item.status,
        value: item.status,
        label: item.statusName
      })
      setQuantity(item.quantity)
      setClothingSizeId({
        key: item.clothingSizeId,
        value: item.clothingSizeId,
        label: item.clothingSizeName,
      })
    } else {
      setId(NIL)
      setItemId(null)
      setClothingSizeId(null)
      setQuantity(0)
      setSubmitted(false)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)

    if (itemId) {
      const item = {
        id,
        purchaseOrderId,
        itemId: itemId?.value,
        status: status?.value ?? 0,
        clothingSizeId: clothingSizeId?.value,
        quantity,
      }

      validateFinishedGoodsRequest(item).then(data => {
        if (data.valid) {
          onSubmit(data)
        } else {
          setValid(false)
          setExceed(data.exceed)
        }
      })

      setItemId(null)
    }
  }


  return (
      <>
        <Modal
            size="md"
            onOpened={ onOpened }
            isOpen={ isOpen }
            toggle={ toggle }
            backdrop="static"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { title }
            </h5>
            <button
                type="button"
                onClick={ toggle }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col md={ 12 }>
                <div className="d-flex">
                  <FormGroup className="flex-grow-1">
                    <Label>Item</Label>
                    <div className="d-flex">
                      <Input
                          type="text"
                          value={ itemId?.label || "" }
                          className={ `form-control me-1` }
                          readOnly={ true }
                      />
                    </div>
                  </FormGroup>
                </div>
                <FormGroup>
                  <Label>Size</Label>
                  <Select
                      isDisabled
                      name="clothingSizeId"
                      value={ clothingSizeId }
                      onChange={ option => {
                        setClothingSizeId(option)
                      } }
                      options={ clothingSizeOptions }
                      classNamePrefix="select2-selection"
                      placeholder="Select Size"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Status</Label>
                  <Select
                      name="status"
                      value={ status }
                      onChange={ option => {
                        setStatus(option)
                      } }
                      options={ statusOptions }
                      classNamePrefix="select2-selection"
                      placeholder="Select Size"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Quantity</Label>
                  <Input
                      type="number"
                      name="quantity"
                      placeholder="Quantity"
                      className={
                          "form-control " +
                          (((submitted && !valid)) ? "is-invalid" : "")
                      }
                      value={ quantity }
                      onChange={ e => {
                        setQuantity(e.target.value)
                      } }
                  />
                  { submitted && !valid && (
                      <div className="invalid-feedback-custom">
                        {exceed} items exceed packaging quantity
                      </div>
                  ) }
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="primary" onClick={ handleSubmit } type="submit">
              Submit
            </Button>
            <button
                type="button"
                onClick={ toggle }
                className="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </>
  )
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  purchaseOrderId: PropTypes.string,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  clothingSizes: PropTypes.array,
}

export default ModalForm
