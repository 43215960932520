import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import ReactInputMask from "react-input-mask";
import { SEVERITY, toastMessage } from "helpers/utils";
import { settingService } from "services/setting-service";

export class SendMessageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: "",
      body: "",

      submitted: false,
    };
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSendMessage = () => {
    const { phoneNumber, body, submitted } = this.state;

    this.setState({ submitted: true });

    let isValid = phoneNumber && body;

    if (isValid) {
      settingService
        .sendSMSRequest({ phoneNumber, body })
        .then(() => {
          toastMessage({
            title: "Send Message",
            text: `Message has been sent to ${phoneNumber}`,
            severity: SEVERITY.SUCCESS,
          });
        })
        .catch(err => {
          const error =
            err?.response?.data?.message ?? "Something has gone wrong.";
          toastMessage({
            title: "Send Message",
            text: `${error}`,
            severity: SEVERITY.DANGER,
          });
        });
    }
  };

  render() {
    const { submitted, phoneNumber, body } = this.state;
    return (
      <Card>
        <CardHeader className="bg-transparent border pt-3">
          <CardTitle>Send Message</CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label>Phone Nubmer (with country code)</Label>
            {/* <ReactInputMask
              mask={"099-999-9999"}
              name="phoneNumber"
              alwaysShowMask={false}
              value={phoneNumber}
              className={"form-control me-4"}
              maskChar=""
              placeholder="Phone Number"
              onChange={this.handleOnChange}
            /> */}
            <Input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              className={submitted && !phoneNumber ? "is-invalid" : ""}
              value={this.state.phoneNumber}
              onChange={this.handleOnChange}
            ></Input>
            {submitted && !phoneNumber && (
              <div className="invalid-feedback-custom">
                Phone number is required.
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Body</Label>
            <Input
              type="textarea"
              rows={3}
              name="body"
              placeholder="Message Body"
              className={submitted && !body ? "is-invalid" : ""}
              value={this.state.body}
              onChange={this.handleOnChange}
            ></Input>
            {submitted && !body && (
              <div className="invalid-feedback-custom">
                Message Body is required.
              </div>
            )}
          </FormGroup>
        </CardBody>
        <CardFooter className="bg-transparent border">
          <div className="text-end">
            <Button
              color="primary"
              onClick={this.handleSendMessage}
              type="submit"
            >
              Send Message
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default SendMessageForm;
