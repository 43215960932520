import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  getProductStockInById,
  getItemUnitOfMeasurements,
  updateProductStockIn
} from "store/actions";

import ProductStockInForm from "../../containers/product-stock-in/ProductStockInForm"

const ProductStockInDetail = props => {
  const { match, history } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [id, setId] = useState(null);

  const { getProductStockInById, updateProductStockIn, productStockIn } = props;
  const { item, loading } = productStockIn;

  useEffect(() => {
    const { id } = match.params;
    getProductStockInById(id);
    setId(id);
  }, []);

  const handleOnSubmit = (data) => {
    updateProductStockIn(data)
    history.push(`/product-stock-in`)
  }

  const handleOnCancel = () =>{
    history.push(`/product-stock-in`);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Stock In Detail | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {item ? (
                <>
                  <Breadcrumbs
                    title="Home"
                    backLink={"/product-stock-in"}
                    breadcrumbItem={`${item.code} | Stock In Detail`}
                  />
                  <ProductStockInForm item={item} onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
                </>
              ) : null}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

ProductStockInDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  productStockIn: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getProductStockInById: PropTypes.func,
  updateProductStockIn: PropTypes.func,
};

const mapStateToProps = ({ productStockIn }) => {
  return {
    productStockIn,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getProductStockInById,
    getItemUnitOfMeasurements,
    updateProductStockIn
  })(ProductStockInDetail)
);
