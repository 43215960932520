import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Modal, Table } from "reactstrap"
import { Tbody, Thead, Tr } from "react-super-responsive-table"
import { hasAccess } from "../../helpers/utils"
import { permission } from "../../constants/permission"

const StockRequestValidationModal = props => {
  const { isOpen, toggle, stockRequestItems, title, isStockValidation} = props;
  return (
      <Modal
          size="lg"
          // onOpened={onOpened}
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            { title }
          </h5>
          <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Table
              id="tech-companies-1"
              className="table table-striped table-bordered table-nowrap"
          >
            <thead>
              <tr>
                <th style={{ width: "80px" }}>#</th>
                <th>Item</th>
                <th>Quantity</th>
                <th>UOM</th>
                <th>Exceed</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {stockRequestItems.map((item,index) =>{
                return <tr key={index}>
                  <td className={"text-end"}>{index + 1}</td>
                  <td>{item.itemCode} - {item.itemName}</td>
                  <td className={"text-end"}>{item.quantity}</td>
                  <td>{item.itemUnitOfMeasurementTitle}</td>
                  <td>{item.exceed}</td>
                  <td>
                    {
                      !item.valid ? <Badge color={"danger"} className={"px-2"}>{isStockValidation ? 'Exceed stock balance': 'Exceed usage limit balance'} </Badge>:<></>
                    }
                  </td>
                </tr>
              })}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
              type="button"
              onClick={toggle}
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

StockRequestValidationModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  stockRequestItems: PropTypes.array,
  isStockValidation: PropTypes.bool
}

export default StockRequestValidationModal