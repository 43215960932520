import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactInputMask from "react-input-mask";

const phoneTypeOptions = [
  { key: 0, value: "Primary", label: "Primary" },
  { key: 0, value: "Alternative", label: "Alternative" },
];

export class PhoneRow extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSelectChange = (valueType, actionMeta) => {
    const { phone } = this.props;
    const { id, phoneNumber, index } = phone;

    const data = {
      id,
      type: valueType ?? null,
      phoneNumber,
      index,
    };

    this.props.onChange(data);
  };

  handleChange = e => {
    const { value } = e.target;
    const { phone } = this.props;
    const { id, type, index } = phone;

    const data = {
      id,
      type,
      phoneNumber: value,
      index,
    };

    this.props.onChange(data);
  };

  render() {
    const { phone, isPrimary, onAdd, onRemove } = this.props;
    const { type, phoneNumber } = phone;
    return (
      <FormGroup>
        <div className="d-flex">
          <div style={{ flexBasis: "280px" }}>
            <Select
              name="type"
              value={type}
              onChange={this.handleSelectChange}
              options={phoneTypeOptions}
              // classNamePrefix="select2-selection"
              isClearable
            />
          </div>
          <ReactInputMask
            mask={"099-999-9999"}
            name="phoneNumber"
            alwaysShowMask={false}
            value={phoneNumber}
            className={"form-control me-4"}
            maskChar=""
            placeholder="Phone Number"
            onChange={this.handleChange}
          />

          <div style={{ position: "relative" }}>
            <div
              style={{ position: "absolute", right: "0", top: "5px" }}
              onClick={isPrimary ? () => onAdd() : () => onRemove(phone)}
            >
              <span
                className="font-size-16 text-primary ps-2"
                style={{ cursor: "pointer" }}
              >
                <i
                  className={isPrimary ? "fas fa-plus" : "fas fa-trash-alt"}
                ></i>
              </span>
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

PhoneRow.propTypes = {
  phone: PropTypes.object,
  isPrimary: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default PhoneRow;
