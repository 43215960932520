import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERER,
  ADD_NEW_DELIVERER,
  GET_DELIVERER_BY_ID,
  UPDATE_DELIVERER,
  DELETE_DELIVERER,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDelivererSuccess,
  getDelivererByIdSuccess,
  getDelivererFail,
  addNewDelivererSuccess,
  updateDelivererSuccess,
  deleteDelivererSuccess,
} from "./actions";

const getDeliverersRequest = query => get(`/api/Deliverer`, { params: query });

const getDelivererRequest = query =>
  get(`/api/Deliverer/paging`, { params: query });
const addNewDelivererRequest = item => post(`/api/Deliverer`, item);
const getDelivererByIdRequest = id => get(`/api/Deliverer/${id}`);
const updateDelivererRequest = item => update(`/api/Deliverer`, item);
const deleteDelivererRequest = id => del(`/api/Deliverer/${id}`);

function* fetchDeliverers({ payload }) {
  try {
    const response = yield call(getDelivererRequest, payload);

    yield put(getDelivererSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDelivererFail(error));
  }
}

function* addNewDeliverer({ payload }) {
  try {
    yield call(addNewDelivererRequest, payload);
    const response = yield call(getDelivererRequest);
    yield put(getDelivererSuccess(response));
    yield put(addNewDelivererSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDelivererFail(error));
  }
}

function* updateDeliverer({ payload }) {
  try {
    yield call(updateDelivererRequest, payload);
    const response = yield call(getDelivererRequest);
    yield put(getDelivererSuccess(response));
    yield put(updateDelivererSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDelivererFail(error));
  }
}

function* getDelivererById({ payload }) {
  try {
    var response = yield call(getDelivererByIdRequest, payload);
    yield put(getDelivererByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDelivererFail(error));
  }
}

function* deleteDeliverer({ payload }) {
  try {
    yield call(deleteDelivererRequest, payload);
    const response = yield call(getDelivererRequest);
    yield put(getDelivererSuccess(response));
    yield put(deleteDelivererSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDelivererFail(error));
  }
}

function* DelivererSaga() {
  yield takeEvery(GET_DELIVERER, fetchDeliverers);
  yield takeEvery(ADD_NEW_DELIVERER, addNewDeliverer);
  yield takeEvery(GET_DELIVERER_BY_ID, getDelivererById);
  yield takeEvery(UPDATE_DELIVERER, updateDeliverer);
  yield takeEvery(DELETE_DELIVERER, deleteDeliverer);
}

export default DelivererSaga;

export {
  getDeliverersRequest,
  addNewDelivererRequest,
  getDelivererByIdRequest,
};
