import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_NEW_STOCK_REQUEST,
  GET_STOCK_REQUEST_BY_ID,
  UPDATE_STOCK_REQUEST,
  DELETE_STOCK_REQUEST,
  GET_STOCK_REQUESTS,
  GET_CONFIRM_STOCK_REQUEST,
  SUBMIT_WITH_STATUS_STOCK_REQUEST,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper"
import {
  getConfirmStockRequestSuccess,
  getStockRequestByIdSuccess,
  getStockRequestsError,
  getStockRequestsSuccess, updateStockRequestError,
} from "./actions"

const getStockRequestsRequest = query =>
    post("/api/StockRequest/paging", query)
const submitWithStatusStockRequestsRequest = query =>
    get("/api/StockRequest/submitWithStatus", { params: query })
const addNewStockRequestRequest = item =>
    post("/api/StockRequest", item)
const getStockRequestByIdRequest = id => get(`/api/StockRequest/${ id }`)
const getConfirmStockRequestReqeust = id =>
    get(`/api/StockRequest/${ id }/confirm`)
const updateStockRequestRequest = item =>
    update("/api/StockRequest", item)
const deleteStockRequestRequest = id => del(`/api/StockRequest/${ id }`)

const validateStockRequest = id => get(`/api/StockRequest/validate/${ id }`)
const validateStockItemLimitationRequest = id => get(`/api/StockRequest/validateItemLimitation/${ id }`)

function* submitWithStatusStockRequests({ payload }) {
  try {
    const {
      history,
      data,
      stockRequestTypeValue,
      stockRequestTypeName
    } = payload
    const { id, status } = data

    yield call(submitWithStatusStockRequestsRequest, {
      id,
      status,
    })

    if (history) {
      history.push(`/stock-request/${ stockRequestTypeName }`)
    } else {
      const response = yield call(getStockRequestsRequest, { stockRequestTypeValue })
      yield put(getStockRequestsSuccess(response))
    }

    // const { id, status, type } = payload;
    //
    // yield call(submitWithStatusStockRequestsRequest, {
    //   id,
    //   status,
    // });
    // if (type === "Tab") {
    //   const response = yield call(getStockRequestByIdRequest, id);
    //   yield put(getStockRequestByIdSuccess(response));
    // } else if (type === "List") {
    // let response = yield call(getStockRequestsRequest, {});
    //   yield put(getStockRequestsSuccess(response));
    // }

  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* getStockRequests({ payload }) {
  try {
    const response = yield call(getStockRequestsRequest, payload)
    yield put(getStockRequestsSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* addNewStockRequest({ payload }) {
  try {
    const { history, data, stockRequestType } = payload
    let response = yield call(addNewStockRequestRequest, data)
    history.push(`/stock-request/${ stockRequestType }`)
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* updateStockRequest({ payload }) {
  try {
    const { history, data, stockRequestType } = payload
    let response = yield call(updateStockRequestRequest, data)

    const { isValid, data: result, validationType } = response

    if (isValid) {
      history.push(`/stock-request/${ stockRequestType }`)
    }else{
      yield put(updateStockRequestError({result, validationType}));
    }
    // const response = yield call(getStockRequestsRequest, { stockRequestType: type});
    // yield put(getStockRequestsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* getStockRequestById({ payload }) {
  try {
    const response = yield call(getStockRequestByIdRequest, payload)
    yield put(getStockRequestByIdSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* getConfirmStockRequest({ payload }) {
  try {
    const { stockRequestId, type } = payload

    let response = yield call(getConfirmStockRequestReqeust, stockRequestId)
    if (type === "List") {
      response = yield call(getStockRequestsRequest, {})
      yield put(getStockRequestsSuccess(response))
    }
    yield put(getConfirmStockRequestSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* deleteStockRequest({ payload }) {
  try {
    const { stockRequestType, id } = payload
    yield call(deleteStockRequestRequest, id)
    const response = yield call(getStockRequestsRequest, { stockRequestType })
    yield put(getStockRequestsSuccess(response))
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getStockRequestsError(error))
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_REQUESTS, getStockRequests)
  yield takeEvery(ADD_NEW_STOCK_REQUEST, addNewStockRequest)
  yield takeEvery(GET_STOCK_REQUEST_BY_ID, getStockRequestById)
  yield takeEvery(UPDATE_STOCK_REQUEST, updateStockRequest)
  yield takeEvery(DELETE_STOCK_REQUEST, deleteStockRequest)
  yield takeEvery(GET_CONFIRM_STOCK_REQUEST, getConfirmStockRequest)
  yield takeEvery(
      SUBMIT_WITH_STATUS_STOCK_REQUEST,
      submitWithStatusStockRequests
  )
}

export default itemSaga

export {
  getStockRequestByIdRequest,
  validateStockRequest,
  validateStockItemLimitationRequest
}
