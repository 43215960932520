import {
    GET_ORDER_MESSAGE_HISTORY,
    GET_ORDER_MESSAGE_HISTORY_SUCCESS,
    GET_ORDER_MESSAGE_HISTORY_FAIL,
  } from "./actionTypes";
  
  export const getOrderMessageHistory = id => ({
    type: GET_ORDER_MESSAGE_HISTORY,
    payload: id,
  });
  
  export const getOrderMessageHistorySuccess = payload => ({
    type: GET_ORDER_MESSAGE_HISTORY_SUCCESS,
    payload
  });
  
  export const getOrderMessageHistoryFail = error => ({
    type: GET_ORDER_MESSAGE_HISTORY_FAIL,
    payload: error,
  });