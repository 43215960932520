import {
  GET_STOCK_LOCATIONS,
  GET_STOCK_LOCATIONS_SUCCESS,
  GET_STOCK_LOCATIONS_ERROR,
  GET_STOCK_LOCATION_BY_ID,
  GET_STOCK_LOCATION_BY_ID_SUCCESS,
  ADD_NEW_STOCK_LOCATION,
  ADD_NEW_STOCK_LOCATION_SUCCESS,
  UPDATE_STOCK_LOCATION,
  UPDATE_STOCK_LOCATION_SUCCESS,
  DELETE_STOCK_LOCATION,
  DELETE_STOCK_LOCATION_SUCCESS,
  STOCK_LOCATION_CLEAR_ERROR,
  ADD_NEW_STOCK_LOCATION_ERROR,
} from "./actionTypes"

// StockLocation
export const getStockLocations = query => ({
  type: GET_STOCK_LOCATIONS,
  payload: query,
});

export const getStockLocationsSuccess = payload => ({
  type: GET_STOCK_LOCATIONS_SUCCESS,
  payload,
});

export const getStockLocationsError = error => ({
  type: GET_STOCK_LOCATIONS_ERROR,
  payload: error,
});

export const getStockLocationById = id => ({
  type: GET_STOCK_LOCATION_BY_ID,
  payload: id,
});

export const getStockLocationByIdSuccess = payload => ({
  type: GET_STOCK_LOCATION_BY_ID_SUCCESS,
  payload,
});

export const addNewStockLocation = item => ({
  type: ADD_NEW_STOCK_LOCATION,
  payload: item,
});


export const addNewStockLocationSuccess = item => ({
  type: ADD_NEW_STOCK_LOCATION_SUCCESS,
  payload: item,
});

export const addNewStockLocationError = error => ({
  type: ADD_NEW_STOCK_LOCATION_ERROR,
  payload: error,
});


export const updateStockLocation = item => ({
  type: UPDATE_STOCK_LOCATION,
  payload: item,
});

export const updateStockLocationSuccess = item => ({
  type: UPDATE_STOCK_LOCATION_SUCCESS,
  payload: item,
});

export const deleteStockLocation = item => ({
  type: DELETE_STOCK_LOCATION,
  payload: item,
});

export const deleteStockLocationSuccess = item => ({
  type: DELETE_STOCK_LOCATION_SUCCESS,
  payload: item,
});

export const clearStockLocationError = () => ({
  type: STOCK_LOCATION_CLEAR_ERROR,
});
