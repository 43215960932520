import {
  GET_ORDER_NOTE,
  GET_ORDER_NOTE_SUCCESS,
  GET_ORDER_NOTE_FAIL,

  GET_ORDER_NOTE_BY_ID,
  GET_ORDER_NOTE_BY_ID_SUCCESS,

  ADD_NEW_ORDER_NOTE,
  ADD_NEW_ORDER_NOTE_SUCCESS,

  UPDATE_ORDER_NOTE,
  UPDATE_ORDER_NOTE_SUCCESS,


  DELETE_ORDER_NOTE,
  DELETE_ORDER_NOTE_SUCCESS,

  ORDER_NOTE_CLEAR_NOTIFY
} from "./actionTypes";


export const getOrderNote = id => ({
  type: GET_ORDER_NOTE,
  payload: id,
});

export const getOrderNoteSuccess = ordernotes => ({
  type: GET_ORDER_NOTE_SUCCESS,
  payload: ordernotes,
});

export const getOrderNoteFail = error => ({
  type: GET_ORDER_NOTE_FAIL,
  payload: error,
});

export const getOrderNoteById = data => ({
  type: GET_ORDER_NOTE_BY_ID,
  payload: data,
});

export const getOrderNoteByIdSuccess = ordernote => ({
  type: GET_ORDER_NOTE_BY_ID_SUCCESS,
  payload: ordernote,
});

export const getOrderNoteByIdFail = error => ({
  type: GET_ORDER_NOTE_BY_ID_FAIL,
  payload: error,
});

export const addNewOrderNote= payload => ({
  type: ADD_NEW_ORDER_NOTE,
  payload
});

export const addNewOrderNoteSuccess = payload => ({
  type: ADD_NEW_ORDER_NOTE_SUCCESS,
  payload,
});

export const updateOrderNote = payload => ({
  type: UPDATE_ORDER_NOTE,
  payload,
});

export const updateOrderNoteSuccess = payload => ({
  type: UPDATE_ORDER_NOTE_SUCCESS,
  payload,
});

export const deleteOrderNote = payload => ({
  type: DELETE_ORDER_NOTE,
  payload,
});
export const deleteOrderNoteSuccess = payload => ({
  type: DELETE_ORDER_NOTE_SUCCESS,
  payload,
});

export const clearOrderNoteNotify = () => ({
  type: ORDER_NOTE_CLEAR_NOTIFY,
});
