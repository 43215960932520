import {
  GET_PRODUCT_UNIT_OF_MEASUREMENTS,
  GET_PRODUCT_UNIT_OF_MEASUREMENTS_SUCCESS,
  GET_PRODUCT_UNIT_OF_MEASUREMENTS_ERROR,
  GET_PRODUCT_UNIT_OF_MEASUREMENT_BY_ID,
  GET_PRODUCT_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT,
  ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT_SUCCESS,
  UPDATE_PRODUCT_UNIT_OF_MEASUREMENT,
  UPDATE_PRODUCT_UNIT_OF_MEASUREMENT_SUCCESS,
  DELETE_PRODUCT_UNIT_OF_MEASUREMENT,
  DELETE_PRODUCT_UNIT_OF_MEASUREMENT_SUCCESS,
  PRODUCT_UNIT_OF_MEASUREMENT_CLEAR_ERROR,
  ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT_ERROR,
} from "./actionTypes";

// ProductUnitOfMeasurement
export const getProductUnitOfMeasurements = query => ({
  type: GET_PRODUCT_UNIT_OF_MEASUREMENTS,
  payload: query,
});

export const getProductUnitOfMeasurementsSuccess = payload => ({
  type: GET_PRODUCT_UNIT_OF_MEASUREMENTS_SUCCESS,
  payload,
});

export const getProductUnitOfMeasurementsError = error => ({
  type: GET_PRODUCT_UNIT_OF_MEASUREMENTS_ERROR,
  payload: error,
});

export const getProductUnitOfMeasurementById = id => ({
  type: GET_PRODUCT_UNIT_OF_MEASUREMENT_BY_ID,
  payload: id,
});

export const getProductUnitOfMeasurementByIdSuccess = payload => ({
  type: GET_PRODUCT_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS,
  payload,
});

export const addNewProductUnitOfMeasurement = item => ({
  type: ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const addNewProductUnitOfMeasurementSuccess = item => ({
  type: ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const addNewProductUnitOfMeasurementError = error => ({
  type: ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT_ERROR,
  payload: error,
});

export const updateProductUnitOfMeasurement = item => ({
  type: UPDATE_PRODUCT_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const updateProductUnitOfMeasurementSuccess = item => ({
  type: UPDATE_PRODUCT_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const deleteProductUnitOfMeasurement = item => ({
  type: DELETE_PRODUCT_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const deleteProductUnitOfMeasurementSuccess = item => ({
  type: DELETE_PRODUCT_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const clearProductUnitOfMeasurementError = () => ({
  type: PRODUCT_UNIT_OF_MEASUREMENT_CLEAR_ERROR,
});
