import {
  GET_DELIVERY_ORDER_ITEMS,
  GET_DELIVERY_ORDER_ITEMS_SUCCESS,
  GET_DELIVERY_ORDER_ITEMS_FAIL,
  GET_DELIVERY_ORDER_ITEM_BY_ID,
  GET_DELIVERY_ORDER_ITEM_BY_ID_SUCCESS,
  ADD_NEW_DELIVERY_ORDER_ITEM,
  ADD_NEW_DELIVERY_ORDER_ITEMS,
  UPDATE_DELIVERY_ORDER_ITEM,
  DELETE_DELIVERY_ORDER_ITEM,
  DELETE_DELIVERY_ORDER_ITEMS,
  DELIVERY_ORDER_ITEM_CLEAR_ERROR,
} from "./actionTypes";

// DeliveryOrderItem
export const getDeliveryOrderItems = query => ({
  type: GET_DELIVERY_ORDER_ITEMS,
  payload: query,
});

export const getDeliveryOrderItemsSuccess = items => ({
  type: GET_DELIVERY_ORDER_ITEMS_SUCCESS,
  payload: items,
});

export const getDeliveryOrderItemsFail = error => ({
  type: GET_DELIVERY_ORDER_ITEMS_FAIL,
  payload: error,
});

export const getDeliveryOrderItemById = id => ({
  type: GET_DELIVERY_ORDER_ITEM_BY_ID,
  payload: id,
});

export const getDeliveryOrderItemByIdSuccess = item => ({
  type: GET_DELIVERY_ORDER_ITEM_BY_ID_SUCCESS,
  payload: item,
});

export const getDeliveryOrderItemByIdFail = error => ({
  type: GET_DELIVERY_ORDER_ITEM_BY_ID_FAIL,
  payload: error,
});

export const addNewDeliveryOrderItem = item => ({
  type: ADD_NEW_DELIVERY_ORDER_ITEM,
  payload: item,
});

export const addNewDeliveryOrderItems = payload => ({
  type: ADD_NEW_DELIVERY_ORDER_ITEMS,
  payload,
});

export const updateDeliveryOrderItem = item => ({
  type: UPDATE_DELIVERY_ORDER_ITEM,
  payload: item,
});

export const deleteDeliveryOrderItem = item => ({
  type: DELETE_DELIVERY_ORDER_ITEM,
  payload: item,
});

export const deleteDeliveryOrderItems = payload => ({
  type: DELETE_DELIVERY_ORDER_ITEMS,
  payload,
});

export const clearDeliveryOrderItemError = () => ({
  type: DELIVERY_ORDER_ITEM_CLEAR_ERROR,
});
