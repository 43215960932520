import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from "reactstrap"
import { useReactToPrint } from "react-to-print"
import StockRequestDetailToPrint from "../StockRequest/StockRequestDetailToPrint"
import ProductListToPrint from "./ProductListToPrint"
import { getAllProductRequest } from "../../store/product/saga"

const ProductPrintPreviewModel = ({ isOpen, toggle, term, purchaseOrderIds, clothingSizeIds, locationIds }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      console.log("onBeforeGetContent print")
    },
    onAfterPrint: () => {
      console.log("After print")
      componentRef.current = null
      // setIsPrint(false);
    }
  })
  
  const onOpened=()=>{
    setLoading(true);
    getAllProductRequest({
      term,
      purchaseOrderIds,
      clothingSizeIds,
      locationIds
    }).then(res =>{
      setProducts(res);
      setLoading(false);
    })
  }
  
  return (
      <Modal
          size="xl"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Print Preview
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div style={ { height: "350px", overflow: "auto", background: "gray", padding: "10px" } }>
            {loading ?
                <div className="loading"></div>:
            <>
              {
                products.length > 0 &&
                <ProductListToPrint ref={ componentRef } products={products}/>
              }
            </>
            }
          </div>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handlePrint }>
            <i className="mdi mdi-printer"/> Print Now
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

ProductPrintPreviewModel.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  term: PropTypes.string,
  purchaseOrderIds: PropTypes.array,
  clothingSizeIds: PropTypes.array,
  locationIds: PropTypes.array
}

export default ProductPrintPreviewModel