import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import PropTypes, { string } from "prop-types";
import Select from "react-select";
import { getItemsRequest } from "store/item/saga";
import CustomPagination from "components/Common/CustomPagination";
import { debounce } from "lodash";
import { getItemTypeOptions } from "helpers/utils";
import NumberFormat from "react-number-format";

const ItemSelectionModal = ({
  isOpen,
  toggle,
  title,
  selectedTypeOption,
  selectedPurchaseOrderId,
  onSubmit,
}) => {
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [itemCount, setItemCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [purchaseOrderId, setPurchaseOrderId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [type, setType] = useState("");

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 500),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    getItemsRequest({
      page,
      pageSize,
      term,
      type: type?.value ?? "",
      purchaseOrderId,
    }).then(res => {
      const { data, itemCount, pageCount } = res;
      setSelectedItem(null);
      setItemCount(itemCount);
      setPageCount(pageCount);
      setItems(data);
      setIsLoading(false);
    });
  }, [page, pageSize, term, type]);

  const onOpened = () => {
    if (selectedTypeOption) {
      setType(selectedTypeOption);
    }

    if (selectedPurchaseOrderId) {
      setPurchaseOrderId(selectedPurchaseOrderId);
    }
    setSelectedItem(null);
  };

  const handleSelect = () => {
    onSubmit(selectedItem);
  };

  return (
    <Modal
      size="xl"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      scrollable
      backdrop="static"
    >
      <div className="modal-header py-3">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ModalBody className="pb-2 border-bottom">
        <Row>
          <Col md={8}>
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative">
                <Input
                  type="text"
                  placeholder="Quick Search ..."
                  onChange={e => {
                    const { value } = e.target;
                    debouncedQuickSearch(value);
                  }}
                ></Input>
                <i className="fas fa-search search-icon" />
              </div>
            </div>
            <div
              className="me-2 mb-2 d-inline-block"
              style={{ width: "200px" }}
            >
              <Select
                name="type"
                value={type}
                onChange={option => {
                  setType(option);
                }}
                options={getItemTypeOptions()}
                classNamePrefix="select2-selection"
                placeholder="Select Type"
                isClearable
              />
            </div>
          </Col>
          <Col md={4}></Col>
        </Row>
      </ModalBody>
      <div className="modal-body">
        <Row>
          <Col md={12} className="px-3">
            <Row>
              {isLoading ? (
                <Col md={12}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </Col>
              ) : (
                <>
                  {items.map((item, index) => {
                    const {itemAttachments} = item;
                    let itemAttachment = itemAttachments.length >0 ? itemAttachments[0]: null;
                    return (
                      <Col md={4} key={index} className="px-1">
                        <Card
                          className={
                            "mb-2 flex-row border" +
                            (selectedItem && selectedItem.id === item.id
                              ? " border-primary"
                              : "")
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedItem(item)}
                        >
                          <div
                            style={{
                              width: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {itemAttachment?
                                <img style={{width:"70px", height:"70px", objectFit:"contain"}} src={itemAttachment.url} alt=""/>
                                :
                                <div style={{width:"80px", height:"80px"}} className={"avatar-order"}>
                                  <i style={{fontSize:"40px"}} className="fas fa-socks text-primary"></i>
                                </div>
                            }
                            
                          </div>
                          <CardBody className="px-0 py-0">
                            <CardHeader className="bg-transparent m-0 py-2 px-0">
                              <CardTitle className="mb-0">
                                Code: {item.code} - {item.name}
                              </CardTitle>
                            </CardHeader>
                            <div className="d-flex justify-content-between pe-4">
                              <span className="text-muted">Type:</span>
                              <span>{item.type}</span>
                            </div>
                            <div className="d-flex justify-content-between pe-4">
                              <span className="text-muted">On Hand:</span>
                              <span>
                                <NumberFormat
                                  value={item.onHandQuantity}
                                  displayType="text"
                                  thousandSeparator
                                  fixedDecimalScale={false}
                                />
                              </span>
                            </div>
                            <div className="d-flex justify-content-between pe-4">
                              <span className="text-muted">Out Going:</span>
                              <span>
                                <NumberFormat
                                  value={item.outgoingQuantity ?? 0}
                                  displayType="text"
                                  thousandSeparator
                                  fixedDecimalScale={false}
                                />
                              </span>
                            </div>
                            <div className="d-flex justify-content-between pe-4">
                              <span className="text-muted">Min:</span>
                              <span>
                                <NumberFormat
                                  value={item.minQuantity ?? 0}
                                  displayType="text"
                                  thousandSeparator
                                  fixedDecimalScale={false}
                                />
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          </Col>
          <Col md={12}>
            <CustomPagination
              itemCount={itemCount}
              currentPage={page}
              totalPage={pageCount}
              defaultPageSize={pageSize}
              pageSizeOptions={[6, 12, 24, 30, 40, 50, 100]}
              onChangePage={i => setPage(i)}
              onPageSizeChange={size => {
                setPageSize(size);
                setPage(1);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          onClick={handleSelect}
          disabled={!selectedItem}
          type="submit"
        >
          Select
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

ItemSelectionModal.propTypes = {
  item: PropTypes.object,
  selectedTypeOption: PropTypes.object,
  selectedPurchaseOrderId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ItemSelectionModal;
