// TRANSPORT_ORDER
export const GET_TRANSPORT_ORDERS = "GET_TRANSPORT_ORDERS";
export const GET_TRANSPORT_ORDERS_SUCCESS = "GET_TRANSPORT_ORDERS_SUCCESS";
export const GET_TRANSPORT_ORDERS_FAIL = "GET_TRANSPORT_ORDERS_FAIL";

export const GET_TRANSPORT_ORDER_BY_ID = "GET_TRANSPORT_ORDER_BY_ID";
export const GET_TRANSPORT_ORDER_BY_ID_SUCCESS =
  "GET_TRANSPORT_ORDER_BY_ID_SUCCESS";

export const ADD_NEW_TRANSPORT_ORDER = "ADD_NEW_TRANSPORT_ORDER";
export const ADD_NEW_TRANSPORT_ORDERS = "ADD_NEW_TRANSPORT_ORDERS";

export const UPDATE_TRANSPORT_ORDER = "UPDATE_TRANSPORT_ORDER";

export const DELETE_TRANSPORT_ORDER = "DELETE_TRANSPORT_ORDER";
export const DELETE_TRANSPORT_ORDERS = "DELETE_TRANSPORT_ORDERS";

export const TRANSPORT_ORDER_CLEAR_ERROR = "TRANSPORT_ORDER_CLEAR_ERROR";
