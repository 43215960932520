import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_STOCK_OUT_ITEM,
  GET_STOCK_OUT_ITEM_BY_ID,
  UPDATE_STOCK_OUT_ITEM,
  DELETE_STOCK_OUT_ITEM,
  GET_STOCK_OUT_ITEMS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getStockOutItemByIdSuccess,
  getStockOutItemsError,
  getStockOutItemsSuccess,
} from "./actions";

const getStockOutItemsRequest = query =>
  get("/api/StockOutItem/paging", { params: query });
const addNewStockOutItemRequest = item => post("/api/StockOutItem", item);
const getStockOutItemByIdRequest = id => get(`/api/StockOutItem/${id}`);
const updateStockOutItemRequest = item => update("/api/StockOutItem", item);
const deleteStockOutItemRequest = id => del(`/api/StockOutItem/${id}`);

function* getStockOutItems({ payload }) {
  try {
    const response = yield call(getStockOutItemsRequest, payload);
    yield put(getStockOutItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutItemsError(error));
  }
}

function* addNewStockOutItem({ payload }) {
  try {
    const { stockOutId } = payload;
    yield call(addNewStockOutItemRequest, payload);
    const response = yield call(getStockOutItemsRequest, { stockOutId });
    yield put(getStockOutItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutItemsError(error));
  }
}

function* updateStockOutItem({ payload }) {
  try {
    const { stockOutId } = payload;
    yield call(updateStockOutItemRequest, payload);
    const response = yield call(getStockOutItemsRequest, { stockOutId });
    yield put(getStockOutItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutItemsError(error));
  }
}

function* getStockOutItemById({ payload }) {
  try {
    var response = yield call(getStockOutItemByIdRequest, payload);
    yield put(getStockOutItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutItemsError(error));
  }
}

function* deleteStockOutItem({ payload }) {
  try {
    const { stockOutId, id } = payload;
    yield call(deleteStockOutItemRequest, id);
    const response = yield call(getStockOutItemsRequest, { stockOutId });
    yield put(getStockOutItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutItemsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_OUT_ITEMS, getStockOutItems);
  yield takeEvery(ADD_NEW_STOCK_OUT_ITEM, addNewStockOutItem);
  yield takeEvery(GET_STOCK_OUT_ITEM_BY_ID, getStockOutItemById);
  yield takeEvery(UPDATE_STOCK_OUT_ITEM, updateStockOutItem);
  yield takeEvery(DELETE_STOCK_OUT_ITEM, deleteStockOutItem);
}

export default itemSaga;
