import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getTransportOrders,
  addNewTransportOrders,
  deleteTransportOrders,
  clearTransportOrderError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SelectionListModal from "containers/order/SelectionListModal";
import TransportOrderTable from "containers/transport-order/TransportOrderTable";
import VerifyOrderTrackingForm from "./VerifyOrderTrackingForm";
import { exportService } from "services/export-service";
import { timestampFileName } from "helpers/utils";

export class ReceiveOrderTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: null,
      transportId: null,
      selectedIds: [],
      currentPage: 1,
      pageSize: 10,

      modalAddOrder: false,
      modalConfirmDeleteIsOpen: false,
      modalVerifyOrderIsOpen: false,
    };
  }

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  toggleVerifyOrderModal = () => {
    this.setState(prevState => ({
      modalVerifyOrderIsOpen: !prevState.modalVerifyOrderIsOpen,
    }));
  };

  componentDidMount = () => {
    const { transportId } = this.props;
    this.setState({
      transportId,
    });
  };

  static getDerivedStateFromProps(props, state) {
    const { transportId } = props;
    if (transportId !== state.transportId) {
      getTransportOrders({ transportId });
      return transportId;
    }

    return null;
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");
      this.props.clearTransportOrderError();
    }
  }

  toggleAddOrder = () => {
    this.setState(prevState => ({
      modalAddOrder: !prevState.modalAddOrder,
    }));
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddOrder: true,
    });
  };

  handleOnSubmit = ids => {
    const { addNewTransportOrders } = this.props;
    const { transportId } = this.state;

    if (transportId) {
      addNewTransportOrders({ transportId, orderIds: ids });
      this.setState({
        modalAddOrder: false,
      });
    }
  };

  handleOnRemove = ids => {
    this.setState({
      selectedIds: ids,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    const { transportId, selectedIds } = this.state;
    const { deleteTransportOrders } = this.props;
    deleteTransportOrders({ transportId, orderIds: selectedIds });
    this.setState({
      modalConfirmDeleteIsOpen: false,
    });
  };

  handleOnPageChange = page => {
    const { transportId, pageSize } = this.state;
    this.props.getTransportOrders({
      transportId,
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { transportId } = this.state;
    this.props.getTransportOrders({
      transportId,
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnVerifyOrder = id => {
    this.setState({
      orderId: id,
      modalVerifyOrderIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.props.getTransportOrders({
      term: value ?? "",
      transportId: this.state.transportId,
    });
  };

  handleExportToExcel = () => {
    const { transport } = this.props;
    const { transportId } = this.state;
    const { item } = transport;
    exportService.exportTransportOrdersToExcel(
      { transportId },
      `${timestampFileName()}_${item.title}_Order_List.xlsx`
    );
  };

  render() {
    const { transport, transportOrder } = this.props;
    const { items, itemCount, pageCount, loading } = transportOrder;
    const { pageSize, currentPage } = this.state;

    return (
      <Row>
        <Col md={12}>
          <TransportOrderTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            defaultPageSize={pageSize}
            totalPage={pageCount}
            transportStatus={transport?.item?.status}
            loading={loading}
            onAddNew={this.handleOnAddNew}
            onVerifyOrder={this.handleOnVerifyOrder}
            onRemove={this.handleOnRemove}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
            onSearch={this.handleOnSearch}
            onExportToExcel={this.handleExportToExcel}
            isReceive={true}
          />
        </Col>

        <SelectionListModal
          title={"Order Selection"}
          toggle={this.toggleAddOrder}
          isOpen={this.state.modalAddOrder}
          onSubmit={this.handleOnSubmit}
          orderParams={{ deliveryStatus: [1] }} // In Korea Warehouse
        />

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />

        {this.state.orderId && (
          <VerifyOrderTrackingForm
            title="Verify Order Tracking Number"
            orderId={this.state.orderId}
            isOpen={this.state.modalVerifyOrderIsOpen}
            toggle={this.toggleVerifyOrderModal}
            //   onSubmit={this.handleDelete}
          />
        )}
      </Row>
    );
  }
}

ReceiveOrderTab.propTypes = {
  transport: PropTypes.object,
  transportOrder: PropTypes.object,
  transportId: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  addNewTransportOrders: PropTypes.func,
  getTransportOrders: PropTypes.func,

  transportOrder: PropTypes.object,
  error: PropTypes.string,
  clearTransportOrderError: PropTypes.func,
  deleteTransportOrders: PropTypes.func,

  history: PropTypes.object,
};

const mapStateToProps = ({ transport, transportOrder }) => {
  return {
    transport,
    transportOrder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTransportOrders,
    addNewTransportOrders,
    clearTransportOrderError,
    deleteTransportOrders,
  })(ReceiveOrderTab)
);
