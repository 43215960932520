import { SEVERITY } from "helpers/utils.js";
import {
  GET_PRODUCT_STOCK_IN_PRODUCTS,
  GET_PRODUCT_STOCK_IN_PRODUCTS_SUCCESS,
  GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_STOCK_IN_PRODUCTS_ERROR,
  PRODUCT_STOCK_IN_PRODUCT_CLEAR_ERROR,
  GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID,
  ADD_NEW_PRODUCT_STOCK_IN_PRODUCT,
  UPDATE_PRODUCT_STOCK_IN_PRODUCT,
  DELETE_PRODUCT_STOCK_IN_PRODUCT,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const productStockInProduct = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_PRODUCT_STOCK_IN_PRODUCT:
    case ADD_NEW_PRODUCT_STOCK_IN_PRODUCT:
    case UPDATE_PRODUCT_STOCK_IN_PRODUCT:
    case GET_PRODUCT_STOCK_IN_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_STOCK_IN_PRODUCTS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        item: null,
        loading: false,
      };
    case GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_PRODUCT_STOCK_IN_PRODUCTS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case PRODUCT_STOCK_IN_PRODUCT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default productStockInProduct;
