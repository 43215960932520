import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/prints.css'
import NumberFormat from "react-number-format"
import moment from "moment"

const StockRequestDetailToPrint = React.forwardRef((props, ref) => {
  const { stockRequestId,isPrint, items, stockRequest } = props
  return (<div ref={ ref }>
    <div className="print-container">
        <div className="print-content">
          <div className="header-section">
              <h2 className="header-title">STOCK REQUEST</h2>
              <div className="header-section1">
                <div>
                  <p>Purchase Order: <strong>#{stockRequest.purchaseOrderCode}</strong></p>
                  <p>Reference Number: <strong>#{stockRequest.code}</strong></p>
                </div>
                <div>
                  <p>Request From : <strong>{stockRequest.typeName}</strong></p>
                  <p>Request Date: <strong>{moment(stockRequest.dateRequest)
                      .local()
                      .format("DD-MMM-YYYY")}</strong></p>
                </div>
              </div>
          </div>
          <table className="table-print">
            <thead>
            <tr>
              <th>#</th>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>UoM</th>
              <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {
              stockRequest && stockRequest.stockRequestItems.map((item, index) => {
                  let totalQuantity =
                      item.quantity * item.unitOfMeasurementConversionRate;
                return <tr key={index}>
                  <td className={ "text-right" }>{index + 1}</td>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td className={ "text-right" }>
                    <NumberFormat
                        value={item.quantity}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                    />
                  </td>
                  <td>{item.unitOfMeasurementName}</td>
                  <td className={ "text-right" }>
                    <NumberFormat
                        value={totalQuantity ?? 0}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                    />
                  </td>
                </tr>
              })
            }
            </tbody>
          </table>
          <div className="footer-section">
            <div>
              <p>Requested By: </p>
              <div className="box-signature"></div>
              <p><strong>{stockRequest.createdByUser}</strong></p>
            </div>
            <div>
              <p>Approved By: </p>
              <div className="box-signature"></div>
              <p><strong>{stockRequest.approvedByUser}</strong></p>
            </div>
          </div>
      </div>
    </div>
  </div>)
})

StockRequestDetailToPrint.displayName = 'StockRequestDetailToPrint'

StockRequestDetailToPrint.propTypes = {
  stockRequestId: PropTypes.string,
  stockRequest: PropTypes.object,
  items: PropTypes.array,
  isPrint: PropTypes.bool,
}

export default StockRequestDetailToPrint