import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import NumberFormat from "react-number-format";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImg,
  Carousel,
  CarouselControl,
  CarouselItem,
  Table,
} from "reactstrap";
import { orderService } from "services/order-service";
import CustomPagination from "components/Common/CustomPagination";

// const items = [
//   {
//     src: "http://skote-v-light.react.themesbrand.com/static/media/img-4.d54eed02ef5a376c9b2b.jpg",
//     altText: "Slide 1",
//     caption: "Slide 1",
//   },
//   {
//     src: "https://jlsexpresskorea.blob.core.windows.net/dev-item-attachment-test/239b2428-3452-4a31-96ef-162dfdd98f00.jpg",
//     altText: "Slide 2",
//     caption: "Slide 2",
//   },
// ];

export class VerifyOrderTrackingModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      invoiceId: null,
      note: "",
      submitted: false,
      verifiedQuantity: 0,
      activeIndex: 0,
      orderId: null,
      items: [],
      page: 1,
      pageSize: 10,
      pageCount: 0,
      itemCount: 0,
      currentPage: 1,
      loading: false,
    };
  }

  onOpened = () => {
    const { orderId } = this.props;
    this.setState({
      loading: true,
      orderId,
    });
    this.populateVerifiedOrderTracking({
      orderId,
      page: "",
      pageSize: "",
    });
  };

  componentDidMount = () => {};

  populateVerifiedOrderTracking = ({ orderId, page, pageSize }) => {
    orderService
      .getVerifiedOrderTrackingRequest({ orderId, page, pageSize })
      .then(res => {
        const { itemCount, pageCount, data } = res;

        this.setState({
          itemCount,
          pageCount,
          items: data,
          loading: false,
        });
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { items } = this.state;
    this.setState({ loading: true });

    orderService.bulkVerifyOrderTrackingRequest(items).then(() => {
      this.setState({ loading: false });
    });
  };

  handleAmountChange = values => {
    const { value } = values;
    this.setState({
      verifiedQuantity: value,
    });
  };

  handleOnChangePage = page => {
    const { orderId, pageSize } = this.state;
    this.populateVerifiedOrderTracking({ orderId, page, pageSize });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = size => {
    const { orderId } = this.props;
    this.populateVerifiedOrderTracking({ orderId, page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };

  handleHeaderCheck = e => {
    const { checked } = e.target;
    this.setState(prevState => ({
      isHeaderCheck: checked,
      verified: checked,
      items: prevState.items.map(a => {
        return {
          ...a,
          verified: checked,
        };
      }),
    }));
  };

  handleRowCheck = (id, e) => {
    const { checked } = e.target;
    const { items } = this.state;
    let newItems = items.map(a => {
      if (a.id === id && checked) {
        return {
          ...a,
          verified: true,
        };
      } else if (a.id === id && !checked) {
        return {
          ...a,
          verified: false,
        };
      } else {
        return a;
      }
    });

    this.setState({
      isSelected: newItems.some(a => a.verified),
      items: newItems,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { itemCount, pageCount, items, currentPage, pageSize, loading } =
      this.state;

    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {loading ? (
            <div className="loading"></div>
          ) : (
            <>
              <div>
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap align-middle"
                >
                  <thead>
                    <tr>
                      <th>
                        <Input
                          type="checkbox"
                          checked={this.state.isHeaderCheck}
                          onClick={this.handleHeaderCheck}
                          readOnly
                        />
                      </th>

                      <th>Tracking Number</th>
                      <th>Verified Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Input
                              name={`order_verified_${item.id}`}
                              onClick={e => this.handleRowCheck(item.id, e)}
                              checked={item.verified}
                              type="checkbox"
                              readOnly
                            />
                          </td>
                          <td>{item.trackingNumber}</td>
                          <td>{item.verifiedTypeName}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CustomPagination
                itemCount={itemCount}
                currentPage={currentPage}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 15, 20, 25, 30]}
                onChangePage={i => this.handleOnChangePage(i)}
                onPageSizeChange={size => this.handleOnPageSizeChange(size)}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Verify
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

VerifyOrderTrackingModalForm.propTypes = {
  orderId: PropTypes.string,
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default VerifyOrderTrackingModalForm;
