import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row, Table } from "reactstrap"
import { NIL } from "uuid"
import Select from "react-select"
import { getAllClothingSizesRequest } from "../../store/clothing-size/saga"
import { customSingleValue, formatItemOptionLabel, getMaxValue } from "../../helpers/utils"
import { debounce } from "lodash"
import { getItemsRequest } from "../../store/item/saga"
import { getItemUnitOfMeasurementsRequest } from "../../store/item-unit-of-measurement/saga"
import PurchaseOrderItemRow from "./PurchaseOrderItemRow"
import { validatePurchaseOrderStockRequest } from "../../store/purchase-order/saga"

const PurchaseOrderForm = props => {
  const { onSubmit } = props

  const [ purchaseOrder, setPurchaseOrder ] = useState({
    id: NIL,
    code: '',
    name: '',
    referenceNumber: '',
    description: '',
    status: 0,
    clothingSizes: [],
    purchaseOrderItems: []
  })

  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ term, setTerm ] = useState('')
  const [ submitted, setSubmitted ] = useState(false)
  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])
  const [ itemOptions, setItemOptions ] = useState([])
  const [ loading, setLoading ] = useState(false);
  const [ invalid, setInvalid ] = useState(false )

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  useEffect(() => {
    getAllClothingSizesRequest().then(data => {
      setClothingSizeOptions(data.map(a => {
        return {
          key: a.id,
          value: a.id,
          label: a.name
        }
      }))
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    getItemsRequest({ term }).then(({ data}) => {
      if (data) {
        setLoading(false)
        setItemOptions(data.map((a, index) => {
          return {
            key: a.id,
            value: a.id,
            type: a.type,
            itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
            quantity: a.onHandQuantity,
            label: `${ a.code } - ${ a.name }`
          }
        }))
      }
    })

  }, [ term ])

  useEffect(() => {
    if(props.purchaseOrder){
      const { purchaseOrder } = props;
      
      setPurchaseOrder({
        ...purchaseOrder,
        clothingSizes: purchaseOrder.clothingSizes.map(a =>{
          return {
            key: a.id,
            value: a.id,
            label: a.name
          }
        }),
        purchaseOrderItems: purchaseOrder.purchaseOrderItems.map((a,index) =>{
          return {
            ...a,
            index,
            id: a.id,
            itemId: {
              value: a.itemId,
            },
            label: `${ a.itemCode } - ${ a.itemName }`,
            unitOfMeasurementId: {
              key: a.unitOfMeasurementId,
              id: a.itemUnitOfMeasurementId,
              value: a.unitOfMeasurementId,
              label: a.itemUnitOfMeasurementTitle,
            }
          }
        })
      })
    }
  }, [props?.purchaseOrder])

  const handleChange = e => {
    const { name, value } = e.target
    setPurchaseOrder({
      ...purchaseOrder,
      [name]: value
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    switch (name){
      case 'itemId':
        if (valueType) {
          const { value, label } = valueType
          getItemUnitOfMeasurementsRequest({ itemId: value }).then(res => {
            const { data } = res
            let uomOptions = data?.map(a => {
              return {
                id: a.id,
                value: a.unitOfMeasurementId,
                label: a.title
              }
            }) ?? []

            let max = 0
            if (purchaseOrder.purchaseOrderItems.length > 0) {
              max = getMaxValue(
                  purchaseOrder.purchaseOrderItems.map(a => {
                    return a.index
                  })
              )
              max += 1
            } else {
              max = max + 1
            }

            let newPurchaseOrderItem = {
              index: max,
              id: NIL,
              itemId: valueType,
              label: label,
              quantity: 0,
              note: '',
              itemUnitOfMeasurementId: null,
              unitOfMeasurementId: uomOptions.length > 0 ? uomOptions[0] : null,
              uomOptions,
              isSelected: false
            }

            setPurchaseOrder({ ...purchaseOrder, purchaseOrderItems: [ ...purchaseOrder.purchaseOrderItems, newPurchaseOrderItem ] })
          })
        }
        break;
      default:
        setPurchaseOrder({
          ...purchaseOrder,
          [name]: valueType
        })
        break;
    }
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setPurchaseOrder({
      ...purchaseOrder,
      purchaseOrderItems: purchaseOrder.purchaseOrderItems.map(item => ({ ...purchaseOrder, isSelected: checked }))
    })
  }

  const handleDeleteSelected = () => {
    setPurchaseOrder({ ...purchaseOrder, purchaseOrderItems: purchaseOrder.purchaseOrderItems.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleStockRequestItemChange = item => {
    
    setPurchaseOrder({
      ...purchaseOrder,
      purchaseOrderItems: purchaseOrder.purchaseOrderItems.map(a => a.index === item.index ? item : a)
    })
  }

  const handleDeleteStockRequestItem = (item) => {
    setPurchaseOrder({
      ...purchaseOrder,
      purchaseOrderItems: purchaseOrder.purchaseOrderItems.filter(e => e.index !== item.index)
    })
  }

  const handleSubmit = () => {
    setSubmitted(true)

    let isValid = purchaseOrder.code && purchaseOrder.name

    if (isValid) {
      let data = {
        ...purchaseOrder,
        purchaseOrderItems: purchaseOrder.purchaseOrderItems.map(a => {
          return {
            ...a,
            itemId: a.itemId?.value,
            itemUnitOfMeasurementId: a.unitOfMeasurementId?.id,
            unitOfMeasurementId: a.unitOfMeasurementId?.value
          }
        }),
        clothingSizes: purchaseOrder.clothingSizes.map(a => {
          return {
            id: a.value,
            name: a.label
          }
        })
      }
      
      validatePurchaseOrderStockRequest(data).then(res =>{
        const { valid } = res
        if(valid){
          setInvalid(false);
          onSubmit(data);
        }else{
          setInvalid(true);
          setPurchaseOrder({
            ...res,
            purchaseOrderItems: res.purchaseOrderItems.map((a,index) => {
              return {
                ...a,
                index,
                label: `${ a.itemCode } - ${ a.itemName }`,
                itemUnitOfMeasurementId: a.unitOfMeasurementId?.id,
                unitOfMeasurementId: {
                  id: a.itemUnitOfMeasurementId,
                  key: a.unitOfMeasurementId,
                  value: a.unitOfMeasurementId,
                  label: a.itemUnitOfMeasurementTitle
                }
              }
            }),
            clothingSizes: res.clothingSizes.map(a => {
              return {
                key: a.id,
                value: a.id,
                label: a.name,
              }
            })
          })
        }
      })
    }
  }

  return (
      <>
        <Card className={ "mb-2" }>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      className={ submitted && !purchaseOrder.code ? "is-invalid" : "" }
                      placeholder="Purchase Code"
                      value={ purchaseOrder.code }
                      onChange={ handleChange }
                  />
                  { submitted && !purchaseOrder.code && (
                      <div className="invalid-feedback-custom">Code is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Name</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={ submitted && !purchaseOrder.name ? "is-invalid" : "" }
                      value={ purchaseOrder.name }
                      onChange={ handleChange }
                  />
                  { submitted && !purchaseOrder.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Reference Number</Label>
                  <Input
                      type="text"
                      name="referenceNumber"
                      placeholder="Reference Number"
                      value={ purchaseOrder.referenceNumber }
                      onChange={ handleChange }
                  />
                </FormGroup>

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <div
                      className={
                          "select2-container" +
                          (submitted && !purchaseOrder.clothingSizes ? " is-invalid" : "")
                      }
                  >
                    <Label>Clothing Size</Label>
                    <Select
                        name="clothingSizes"
                        value={ purchaseOrder.clothingSizes }
                        onChange={handleSelectChange}
                        options={ clothingSizeOptions }
                        classNamePrefix="select2-selection"
                        isMulti
                        isClearable
                    />
                  </div>
                  { submitted && !purchaseOrder.clothingSizes && (
                      <div className="invalid-feedback-custom">Clothing Sizes is required.</div>
                  ) }
                </div>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Description</Label>
                  <Input
                      type="textarea"
                      rows={ 3 }
                      name="description"
                      placeholder="Description"
                      value={ purchaseOrder.description }
                      onChange={ handleChange }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>

            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <Select
                    name="itemId"
                    value={ null }
                    placeholder={ "Find by Code, Name, ..." }
                    onChange={ handleSelectChange }
                    options={ itemOptions }
                    components={ {
                      SingleValue: customSingleValue
                    } }
                    onInputChange={ handleInputChange }
                    formatOptionLabel={ formatItemOptionLabel }
                    classNamePrefix="select2-selection"
                    isLoading={ loading }
                    isClearable
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline 
                          disabled={ !purchaseOrder.purchaseOrderItems.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Note</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>
              {
                purchaseOrder.purchaseOrderItems
                    // .sort((a, b) => a.index > b.index ? -1 : 1)
                    .map((item, index) => {
                      return <PurchaseOrderItemRow
                          key={ index }
                          item={ item }
                          isSubmitted={ submitted }
                          invalid={ invalid }
                          onChange={ handleStockRequestItemChange }
                          onDelete={ handleDeleteStockRequestItem }
                      />
                    })
              }
              </tbody>
            </Table>
            <div className={ (submitted && purchaseOrder.purchaseOrderItems.length === 0 ? " is-invalid" : "") }>
            </div>
            { submitted && purchaseOrder.purchaseOrderItems.length === 0 && (
                <div className="invalid-feedback-custom">
                  Purchase Order items are required.
                </div>
            ) }
            
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardBody>
            <Button color="primary"
                    className={ "me-1" }
                    onClick={ handleSubmit }
                    type="submit">
              Submit
            </Button>
            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>
      </>
  )
}

PurchaseOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  purchaseOrder: PropTypes.object
}

export default PurchaseOrderForm