import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CuttingFormTableRow from "./CuttingFormTableRow"
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Table } from "reactstrap"

const CuttingForm = props => {
  const { entryForm, onSubmit } = props

  const [ items, setItems ] = useState([])
  const [ clothingSizes, setClothingSizes ] = useState([])

  useEffect(() => {
    if (entryForm) {
      setItems(entryForm.items.map((item, index) => {
        return {
          ...item,
          index
        }
      }))
      setClothingSizes(entryForm.clothingSizes)
    }
  }, [])

  const handleOnChange = () => {
    setItems(items.map((item, index) => {
      if (item.index === index) {
        return {
          ...item,
        }
      }
      return item
    }))
  }
  
  const handleSubmit=()=>{
    onSubmit(items);
  }

  return (
      <Card>
        <CardHeader className={ "bg-transparent border-bottom" }>
          <CardTitle>Item Entries Form</CardTitle>
        </CardHeader>
        <CardBody>
          <Table
              // id="tech-companies-1"
              className="table-editable table table-striped table-bordered table-nowrap"
          >
            <thead>
            <tr>
              <th className={ "text-center" } style={ { width: "80px" } }>
                #
              </th>
              <th className="text-center" style={ { width: "350px" } }>Item</th>
              {
                clothingSizes.map(size => {
                  return <th key={ size.id } className={ "text-center" }>{ size.name }</th>
                })
              }
            </tr>
            </thead>
            <tbody>
            {
              items.map((item, index) => {
                return <CuttingFormTableRow
                    key={ index }
                    item={ item }
                    num={index + 1}
                    clothingSizes={ clothingSizes }
                    onChange={ handleOnChange }
                />
              })
            }
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className={ "bg-transparent border-top text-end" }>
          <Button color="primary" onClick={ handleSubmit } type="submit">
           Submit
          </Button>
        </CardFooter>
      </Card>
  )
}

CuttingForm.propTypes = {
  entryForm: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default CuttingForm