// SHIPPING_CONTACT
export const GET_SHIPPING_CONTACT = "GET_SHIPPING_CONTACT";
export const GET_SHIPPING_CONTACT_SUCCESS = "GET_SHIPPING_CONTACT_SUCCESS";
export const GET_SHIPPING_CONTACT_FAIL = "GET_SHIPPING_CONTACT_FAIL";

export const GET_SHIPPING_CONTACT_BY_ID = "GET_SHIPPING_CONTACT_BY_ID";
export const GET_SHIPPING_CONTACT_BY_ID_SUCCESS =
  "GET_SHIPPING_CONTACT_BY_ID_SUCCESS";

export const ADD_NEW_SHIPPING_CONTACT = "ADD_NEW_SHIPPING_CONTACT";

export const UPDATE_SHIPPING_CONTACT = "UPDATE_SHIPPING_CONTACT";

export const DELETE_SHIPPING_CONTACT = "DELETE_SHIPPING_CONTACT";

export const SHIPPING_CONTACT_CLEAR_ERROR = "SHIPPING_CONTACT_CLEAR_ERROR";
