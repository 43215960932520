import {
  GET_STOCK_ADJUSTMENTS,
  GET_STOCK_ADJUSTMENTS_SUCCESS,
  GET_STOCK_ADJUSTMENTS_ERROR,
  GET_STOCK_ADJUSTMENT_BY_ID,
  GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS,
  ADD_NEW_STOCK_ADJUSTMENT,
  ADD_NEW_STOCK_ADJUSTMENT_SUCCESS,
  UPDATE_STOCK_ADJUSTMENT,
  UPDATE_STOCK_ADJUSTMENT_SUCCESS,
  DELETE_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT_SUCCESS,
  STOCK_ADJUSTMENT_CLEAR_ERROR,
  ADD_NEW_STOCK_ADJUSTMENT_ERROR,
  GET_CONFIRM_STOCK_ADJUSTMENT,
  GET_CONFIRM_STOCK_ADJUSTMENT_SUCCESS,
} from "./actionTypes";
// StockAdjustment
export const getStockAdjustments = query => ({
  type: GET_STOCK_ADJUSTMENTS,
  payload: query,
});

export const getStockAdjustmentsSuccess = payload => ({
  type: GET_STOCK_ADJUSTMENTS_SUCCESS,
  payload,
});

export const getStockAdjustmentsError = error => ({
  type: GET_STOCK_ADJUSTMENTS_ERROR,
  payload: error,
});

export const getStockAdjustmentById = id => ({
  type: GET_STOCK_ADJUSTMENT_BY_ID,
  payload: id,
});

export const getStockAdjustmentByIdSuccess = payload => ({
  type: GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS,
  payload,
});

export const addNewStockAdjustment = item => ({
  type: ADD_NEW_STOCK_ADJUSTMENT,
  payload: item,
});

export const addNewStockAdjustmentSuccess = item => ({
  type: ADD_NEW_STOCK_ADJUSTMENT_SUCCESS,
  payload: item,
});

export const addNewStockAdjustmentError = error => ({
  type: ADD_NEW_STOCK_ADJUSTMENT_ERROR,
  payload: error,
});

export const updateStockAdjustment = item => ({
  type: UPDATE_STOCK_ADJUSTMENT,
  payload: item,
});

export const updateStockAdjustmentSuccess = item => ({
  type: UPDATE_STOCK_ADJUSTMENT_SUCCESS,
  payload: item,
});

export const deleteStockAdjustment = item => ({
  type: DELETE_STOCK_ADJUSTMENT,
  payload: item,
});

export const deleteStockAdjustmentSuccess = item => ({
  type: DELETE_STOCK_ADJUSTMENT_SUCCESS,
  payload: item,
});

export const clearStockAdjustmentError = () => ({
  type: STOCK_ADJUSTMENT_CLEAR_ERROR,
});

export const getConfirmStockAdjustment = payload => ({
  type: GET_CONFIRM_STOCK_ADJUSTMENT,
  payload,
});

export const getConfirmStockAdjustmentSuccess = payload => ({
  type: GET_CONFIRM_STOCK_ADJUSTMENT_SUCCESS,
  payload,
});
