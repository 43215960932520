import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_STOCK_IN_ITEM,
  GET_STOCK_IN_ITEM_BY_ID,
  UPDATE_STOCK_IN_ITEM,
  DELETE_STOCK_IN_ITEM,
  GET_STOCK_IN_ITEMS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getStockInItemByIdSuccess,
  getStockInItemsError,
  getStockInItemsSuccess,
} from "./actions";

const getStockInItemsRequest = query =>
  get("/api/StockInItem/paging", { params: query });
const addNewStockInItemRequest = item => post("/api/StockInItem", item);
const getStockInItemByIdRequest = id => get(`/api/StockInItem/${id}`);
const updateStockInItemRequest = item => update("/api/StockInItem", item);
const deleteStockInItemRequest = id => del(`/api/StockInItem/${id}`);

function* getStockInItems({ payload }) {
  try {
    const response = yield call(getStockInItemsRequest, payload);
    yield put(getStockInItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInItemsError(error));
  }
}

function* addNewStockInItem({ payload }) {
  try {
    const { stockInId } = payload;
    yield call(addNewStockInItemRequest, payload);
    const response = yield call(getStockInItemsRequest, { stockInId });
    yield put(getStockInItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInItemsError(error));
  }
}

function* updateStockInItem({ payload }) {
  try {
    const { stockInId } = payload;
    yield call(updateStockInItemRequest, payload);
    const response = yield call(getStockInItemsRequest, { stockInId });
    yield put(getStockInItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInItemsError(error));
  }
}

function* getStockInItemById({ payload }) {
  try {
    var response = yield call(getStockInItemByIdRequest, payload);
    yield put(getStockInItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInItemsError(error));
  }
}

function* deleteStockInItem({ payload }) {
  try {
    const { stockInId, id } = payload;
    yield call(deleteStockInItemRequest, id);
    const response = yield call(getStockInItemsRequest, { stockInId });
    yield put(getStockInItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInItemsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_IN_ITEMS, getStockInItems);
  yield takeEvery(ADD_NEW_STOCK_IN_ITEM, addNewStockInItem);
  yield takeEvery(GET_STOCK_IN_ITEM_BY_ID, getStockInItemById);
  yield takeEvery(UPDATE_STOCK_IN_ITEM, updateStockInItem);
  yield takeEvery(DELETE_STOCK_IN_ITEM, deleteStockInItem);
}

export default itemSaga;
