import {
  GET_ORDER_ATTACHMENTS,
  GET_ORDER_ATTACHMENTS_SUCCESS,
  GET_ORDER_ATTACHMENTS_FAIL,
  GET_ORDER_ATTACHMENT_BY_ID,
  GET_ORDER_ATTACHMENT_BY_ID_SUCCESS,
  ADD_NEW_ORDER_ATTACHMENT,
  UPDATE_ORDER_ATTACHMENT,
  DELETE_ORDER_ATTACHMENT,
  ORDER_ATTACHMENT_CLEAR_ERROR,
  UPDATE_ORDER_ATTACHMENTS,
} from "./actionTypes";

// OrderAttachment
export const getOrderAttachments = query => ({
  type: GET_ORDER_ATTACHMENTS,
  payload: query,
});

export const getOrderAttachmentsSuccess = items => ({
  type: GET_ORDER_ATTACHMENTS_SUCCESS,
  payload: items,
});

export const getOrderAttachmentsFail = error => ({
  type: GET_ORDER_ATTACHMENTS_FAIL,
  payload: error,
});

export const getOrderAttachmentById = id => ({
  type: GET_ORDER_ATTACHMENT_BY_ID,
  payload: id,
});

export const getOrderAttachmentByIdSuccess = item => ({
  type: GET_ORDER_ATTACHMENT_BY_ID_SUCCESS,
  payload: item,
});

export const getOrderAttachmentByIdFail = error => ({
  type: GET_ORDER_ATTACHMENT_BY_ID_FAIL,
  payload: error,
});

export const addNewOrderAttachment = item => ({
  type: ADD_NEW_ORDER_ATTACHMENT,
  payload: item,
});

export const updateOrderAttachment = item => ({
  type: UPDATE_ORDER_ATTACHMENT,
  payload: item,
});

export const updateOrderAttachments = payload => ({
  type: UPDATE_ORDER_ATTACHMENTS,
  payload,
});

export const deleteOrderAttachment = item => ({
  type: DELETE_ORDER_ATTACHMENT,
  payload: item,
});

export const clearOrderAttachmentError = () => ({
  type: ORDER_ATTACHMENT_CLEAR_ERROR,
});
