// CUTTING
export const GET_CUTTINGS = "GET_CUTTINGS";
export const GET_CUTTINGS_SUCCESS = "GET_CUTTINGS_SUCCESS";
export const GET_CUTTINGS_ERROR = "GET_CUTTINGS_ERROR";

export const GET_CUTTING_BY_ID = "GET_CUTTING_BY_ID";
export const GET_CUTTING_BY_ID_SUCCESS = "GET_CUTTING_BY_ID_SUCCESS";

export const ADD_NEW_CUTTING = "ADD_NEW_CUTTING";
export const ADD_NEW_CUTTING_SUCCESS = "ADD_NEW_CUTTING_SUCCESS";
export const ADD_NEW_CUTTING_ERROR = "ADD_NEW_CUTTING_ERROR";

export const UPDATE_CUTTING = "UPDATE_CUTTING";
export const UPDATE_CUTTING_SUCCESS = "UPDATE_CUTTING_SUCCESS";
export const UPDATE_CUTTING_ERROR = "UPDATE_CUTTING_ERROR";

export const DELETE_CUTTING = "DELETE_CUTTING";
export const DELETE_CUTTING_SUCCESS = "DELETE_CUTTING_SUCCESS";

export const GET_CUTTING_ENTRY_FORM = "GET_CUTTING_ENTRY_FORM";
export const GET_CUTTING_ENTRY_FORM_SUCCESS = "GET_CUTTING_ENTRY_FORM_SUCCESS";

export const SAVE_CUTTING_ENTRY_FORM = "SAVE_CUTTING_ENTRY_FORM";
export const SAVE_CUTTING_ENTRY_FORM_SUCCESS = "SAVE_CUTTING_ENTRY_FORM_SUCCESS";

export const CUTTING_CLEAR_ERROR = "CUTTING_CLEAR_ERROR";
