import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ITEM_SHIPPING_FEE_ENTRYS,
  ADD_NEW_ITEM_SHIPPING_FEE_ENTRY,
  GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID,
  UPDATE_ITEM_SHIPPING_FEE_ENTRY,
  DELETE_ITEM_SHIPPING_FEE_ENTRY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getItemShippingFeeEntrysSuccess,
  getItemShippingFeeEntryByIdSuccess,
  getItemShippingFeeEntrysFail,
} from "./actions";
// ItemShippingFeeEntry
const getItemShippingFeeEntrysRequest = query =>
  get("/api/ItemShippingFeeEntry/paging", { params: query });
const addNewItemShippingFeeEntryRequest = item =>
  post("/api/ItemShippingFeeEntry", item);
const getItemShippingFeeEntryByIdRequest = id =>
  get(`/api/ItemShippingFeeEntry/${id}`);
const updateItemShippingFeeEntryRequest = item =>
  update("/api/ItemShippingFeeEntry", item);
const deleteItemShippingFeeEntryRequest = id =>
  del(`/api/ItemShippingFeeEntry/${id}`);

function* fetchItemShippingFeeEntrys({ payload }) {
  try {
    const response = yield call(getItemShippingFeeEntrysRequest, payload);
    yield put(getItemShippingFeeEntrysSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeeEntrysFail(error));
  }
}

function* addNewItemShippingFeeEntry({ payload }) {
  try {
    yield call(addNewItemShippingFeeEntryRequest, payload);
    const { itemShippingFeeId } = payload;
    const response = yield call(getItemShippingFeeEntrysRequest, {
      itemShippingFeeId,
    });
    yield put(getItemShippingFeeEntrysSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeeEntrysFail(error));
  }
}

function* updateItemShippingFeeEntry({ payload }) {
  try {
    yield call(updateItemShippingFeeEntryRequest, payload);
    const { itemShippingFeeId } = payload;
    const response = yield call(getItemShippingFeeEntrysRequest, {
      itemShippingFeeId,
    });
    yield put(getItemShippingFeeEntrysSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeeEntrysFail(error));
  }
}

function* getItemShippingFeeEntryById({ payload }) {
  try {
    var response = yield call(getItemShippingFeeEntryByIdRequest, payload);
    yield put(getItemShippingFeeEntryByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeeEntrysFail(error));
  }
}

function* deleteItemShippingFeeEntry({ payload }) {
  try {
    const { id, itemShippingFeeId } = payload;
    yield call(deleteItemShippingFeeEntryRequest, id);
    const response = yield call(getItemShippingFeeEntrysRequest, {
      itemShippingFeeId,
    });
    yield put(getItemShippingFeeEntrysSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeeEntrysFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ITEM_SHIPPING_FEE_ENTRYS, fetchItemShippingFeeEntrys);
  yield takeEvery(ADD_NEW_ITEM_SHIPPING_FEE_ENTRY, addNewItemShippingFeeEntry);
  yield takeEvery(
    GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID,
    getItemShippingFeeEntryById
  );
  yield takeEvery(UPDATE_ITEM_SHIPPING_FEE_ENTRY, updateItemShippingFeeEntry);
  yield takeEvery(DELETE_ITEM_SHIPPING_FEE_ENTRY, deleteItemShippingFeeEntry);
}

export default roleSaga;
