// STOCK_LOCATION
export const GET_STOCK_LOCATIONS = "GET_STOCK_LOCATIONS";
export const GET_STOCK_LOCATIONS_SUCCESS =
  "GET_STOCK_LOCATIONS_SUCCESS";
export const GET_STOCK_LOCATIONS_ERROR = "GET_STOCK_LOCATIONS_ERROR";

export const GET_STOCK_LOCATION_BY_ID = "GET_STOCK_LOCATION_BY_ID";
export const GET_STOCK_LOCATION_BY_ID_SUCCESS =
  "GET_STOCK_LOCATION_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_LOCATION = "ADD_NEW_STOCK_LOCATION";

export const ADD_NEW_STOCK_LOCATION_SUCCESS =
  "ADD_NEW_STOCK_LOCATION_SUCCESS";
export const ADD_NEW_STOCK_LOCATION_ERROR =
  "ADD_NEW_STOCK_LOCATION_ERROR";


export const UPDATE_STOCK_LOCATION = "UPDATE_STOCK_LOCATION";
export const UPDATE_STOCK_LOCATION_SUCCESS =
  "UPDATE_STOCK_LOCATION_SUCCESS";
export const UPDATE_STOCK_LOCATION_ERROR =
  "UPDATE_STOCK_LOCATION_ERROR";

export const DELETE_STOCK_LOCATION = "DELETE_STOCK_LOCATION";
export const DELETE_STOCK_LOCATION_SUCCESS =
  "DELETE_STOCK_LOCATION_SUCCESS";

export const STOCK_LOCATION_CLEAR_ERROR = "STOCK_LOCATION_CLEAR_ERROR";
