import {
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAIL,
  GET_WAREHOUSE_BY_ID,
  GET_WAREHOUSE_BY_ID_SUCCESS,
  ADD_NEW_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
  WAREHOUSE_CLEAR_ERROR,
} from "./actionTypes";


export const getWarehouses = query => ({
  type: GET_WAREHOUSES,
  payload: query,
});

export const getWarehousesSuccess = warehouses => ({
  type: GET_WAREHOUSES_SUCCESS,
  payload: warehouses,
});

export const getWarehousesFail = error => ({
  type: GET_WAREHOUSES_FAIL,
  payload: error,
});

export const getWarehouseById = id => ({
  type: GET_WAREHOUSE_BY_ID,
  payload: id,
});

export const getWarehouseByIdSuccess = warehouse => ({
  type: GET_WAREHOUSE_BY_ID_SUCCESS,
  payload: warehouse,
});

export const getWarehouseByIdFail = error => ({
  type: GET_Warehouse_BY_ID_FAIL,
  payload: error,
});

export const addNewWarehouse = warehouse => ({
  type: ADD_NEW_WAREHOUSE,
  payload: warehouse,
});

export const updateWarehouse = warehouse => ({
  type: UPDATE_WAREHOUSE,
  payload: warehouse,
});

export const deleteWarehouse = warehouse => ({
  type: DELETE_WAREHOUSE,
  payload: warehouse,
});

export const clearWarehouseError = () => ({
  type: WAREHOUSE_CLEAR_ERROR,
});
