import {
  GET_TRANSPORT_ORDERS,
  GET_TRANSPORT_ORDERS_SUCCESS,
  GET_TRANSPORT_ORDERS_FAIL,
  GET_TRANSPORT_ORDER_BY_ID_SUCCESS,
  TRANSPORT_ORDER_CLEAR_ERROR,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const transportOrder = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TRANSPORT_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSPORT_ORDERS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_TRANSPORT_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case TRANSPORT_ORDER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case GET_TRANSPORT_ORDERS_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default transportOrder;
