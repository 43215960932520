import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

class BannerTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onConfirmDelete,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
                >
                  <Thead>
                    <Tr>
                      <Th>Image</Th>
                      <Th>Title</Th>
                      <Th>Sub Title</Th>
                      <Th>Type</Th>
                      <Th>Display Order</Th>
                      <Th>Link</Th>
                      <Th style={{ width: "120px" }}>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.map((item, index) => {
                      return (
                        <Tr key={index}>
                          <Td>
                            <img
                              style={{
                                width: "200px",
                                height: "90px",
                                objectFit: "cover",
                              }}
                              src={item.url}
                              alt="bitbucket"
                            />
                          </Td>
                          <Td>{item.title}</Td>
                          <Td>{item.subTitle}</Td>
                          <Td>{item.type == 0 ? "Slider" : "Landing"}</Td>
                          <Td>{item.displayOrder}</Td>
                          <Td>{item.link}</Td>

                          <Td>
                            <div className="d-flex gap-3">
                              {hasAccess(permission.banner.read) && (
                                <Link className="text-primary" to="#">
                                  <i
                                    className="mdi mdi-pencil font-size-18"
                                    id="edittooltip"
                                    onClick={() => onEdit(item.id)}
                                  ></i>
                                </Link>
                              )}
                              {hasAccess(permission.banner.delete) && (
                                <Link className="text-danger" to="#">
                                  <i
                                    className="mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                    onClick={() => onConfirmDelete(item.id)}
                                  ></i>
                                </Link>
                              )}
                            </div>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

BannerTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default BannerTable;
