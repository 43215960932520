// PURCHASE_ORDER_ITEM
export const GET_PURCHASE_ORDER_ITEMS = "GET_PURCHASE_ORDER_ITEMS";
export const GET_PURCHASE_ORDER_ITEMS_SUCCESS =
  "GET_PURCHASE_ORDER_ITEMS_SUCCESS";
export const GET_PURCHASE_ORDER_ITEMS_ERROR = "GET_PURCHASE_ORDER_ITEMS_ERROR";

export const GET_PURCHASE_ORDER_ITEM_BY_ID = "GET_PURCHASE_ORDER_ITEM_BY_ID";
export const GET_PURCHASE_ORDER_ITEM_BY_ID_SUCCESS =
  "GET_PURCHASE_ORDER_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_PURCHASE_ORDER_ITEM = "ADD_NEW_PURCHASE_ORDER_ITEM";
export const ADD_NEW_PURCHASE_ORDER_ITEM_SUCCESS =
  "ADD_NEW_PURCHASE_ORDER_ITEM_SUCCESS";
export const ADD_NEW_PURCHASE_ORDER_ITEM_ERROR =
  "ADD_NEW_PURCHASE_ORDER_ITEM_ERROR";

export const ADD_NEW_BULK_PURCHASE_ORDER_ITEM = "ADD_NEW_BULK_PURCHASE_ORDER_ITEM";
export const ADD_NEW_BULK_PURCHASE_ORDER_ITEM_SUCCESS =
    "ADD_NEW_BULK_PURCHASE_ORDER_ITEM_SUCCESS";
export const ADD_NEW_BULK_PURCHASE_ORDER_ITEM_ERROR =
    "ADD_NEW_BULK_PURCHASE_ORDER_ITEM_ERROR";

export const UPDATE_PURCHASE_ORDER_ITEM = "UPDATE_PURCHASE_ORDER_ITEM";
export const UPDATE_PURCHASE_ORDER_ITEM_SUCCESS =
  "UPDATE_PURCHASE_ORDER_ITEM_SUCCESS";
export const UPDATE_PURCHASE_ORDER_ITEM_ERROR =
  "UPDATE_PURCHASE_ORDER_ITEM_ERROR";

export const SAVE_PURCHASE_ORDER_ITEMS = "SAVE_PURCHASE_ORDER_ITEMS";
export const SAVE_PURCHASE_ORDER_ITEMS_SUCCESS = "SAVE_PURCHASE_ORDER_ITEMS_SUCCESS";
export const SAVE_PURCHASE_ORDER_ITEMS_ERROR = "SAVE_PURCHASE_ORDER_ITEMS_ERROR";

export const GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID = "GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID";
export const GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_SUCCESS = "GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_SUCCESS";
export const GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_ERROR = "GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_ERROR";

export const DELETE_PURCHASE_ORDER_ITEM = "DELETE_PURCHASE_ORDER_ITEM";
export const DELETE_PURCHASE_ORDER_ITEM_SUCCESS =
  "DELETE_PURCHASE_ORDER_ITEM_SUCCESS";

export const PURCHASE_ORDER_ITEM_CLEAR_ERROR =
  "PURCHASE_ORDER_ITEM_CLEAR_ERROR";
