import { SEVERITY } from "helpers/utils.js";
import {
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAIL,
  GET_FEEDBACK_BY_ID_SUCCESS,
  ADD_NEW_FEEDBACK,
  ADD_NEW_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK,
  UPDATE_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK,
  DELETE_FEEDBACK_SUCCESS,
  FEEDBACK_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const Feedback = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_FEEDBACK:
    case UPDATE_FEEDBACK:
    case DELETE_FEEDBACK:
    case GET_FEEDBACK:
      return {
        ...state,
        loading: true,
      };
    case GET_FEEDBACK_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_FEEDBACK_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case ADD_NEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Note",
          text: "Invoice Note has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Note",
          text: "Invoice Note has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Note",
          text: "Invoice Note has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_FEEDBACK_FAIL:
      return {
        ...state,
        error: payload,
      };
    case FEEDBACK_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default Feedback;
