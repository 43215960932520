import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_PACKAGING,
  GET_PACKAGING_BY_ID,
  UPDATE_PACKAGING,
  DELETE_PACKAGING,
  GET_PACKAGINGS, GET_PACKAGING_ENTRY_FORM, SAVE_PACKAGING_ENTRY_FORM,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPackagingByIdSuccess, getPackagingEntryFormSuccess,
  getPackagingsError,
  getPackagingsSuccess, savePackagingEntryFormSuccess,
} from "./actions"

const getPackagingsRequest = query => get("/api/Packaging/paging", { params: query });
const addNewPackagingRequest = item => post("/api/Packaging", item);
const getPackagingByIdRequest = id => get(`/api/Packaging/${id}`);
const updatePackagingRequest = item => update("/api/Packaging", item);
const deletePackagingRequest = id => del(`/api/Packaging/${id}`);

const getPackagingEntryFormRequest = id => get(`/api/Packaging/entryForm/${id}`);
const savePackagingEntryFormRequest = item => post("/api/Packaging/entryForm", item);
const validatePackagingEntryFormRequest = item => post("/api/Packaging/validateEntryForm", item);
const validatePackagingRequest = item => post("/api/Packaging/validateEntry", item);

function* getPackagings({ payload }) {
  try {
    const response = yield call(getPackagingsRequest, payload);
    yield put(getPackagingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* addNewPackaging({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(addNewPackagingRequest, payload);
    const response = yield call(getPackagingsRequest, {
      purchaseOrderId,
    });
    yield put(getPackagingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* updatePackaging({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(updatePackagingRequest, payload);
    const response = yield call(getPackagingsRequest, {
      purchaseOrderId,
    });
    yield put(getPackagingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* getPackagingById({ payload }) {
  try {
    var response = yield call(getPackagingByIdRequest, payload);
    yield put(getPackagingByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* deletePackaging({ payload }) {
  try {
    const { purchaseOrderId, id } = payload;
    yield call(deletePackagingRequest, id);
    const response = yield call(getPackagingsRequest, {
      purchaseOrderId,
    });
    yield put(getPackagingsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* getPackagingEntryForm({ payload }) {
  try {
    const response = yield call(getPackagingEntryFormRequest, payload);
    yield put(getPackagingEntryFormSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* savePackagingEntryForm({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(savePackagingEntryFormRequest, payload);
    const response = yield call(getPackagingsRequest, {
      purchaseOrderId,
    });
    yield put(getPackagingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPackagingsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PACKAGINGS, getPackagings);
  yield takeEvery(ADD_NEW_PACKAGING, addNewPackaging);
  yield takeEvery(GET_PACKAGING_BY_ID, getPackagingById);
  yield takeEvery(UPDATE_PACKAGING, updatePackaging);
  yield takeEvery(DELETE_PACKAGING, deletePackaging);
  yield takeEvery(GET_PACKAGING_ENTRY_FORM, getPackagingEntryForm);
  yield takeEvery(SAVE_PACKAGING_ENTRY_FORM, savePackagingEntryForm);
}

export default itemSaga;

export {
  validatePackagingEntryFormRequest,
  validatePackagingRequest
}
