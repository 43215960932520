// STOCK_ADJUSTMENT_ITEM
export const GET_STOCK_ADJUSTMENT_ITEMS = "GET_STOCK_ADJUSTMENT_ITEMS";
export const GET_STOCK_ADJUSTMENT_ITEMS_SUCCESS =
  "GET_STOCK_ADJUSTMENT_ITEMS_SUCCESS";
export const GET_STOCK_ADJUSTMENT_ITEMS_ERROR =
  "GET_STOCK_ADJUSTMENT_ITEMS_ERROR";

export const GET_STOCK_ADJUSTMENT_ITEM_BY_ID =
  "GET_STOCK_ADJUSTMENT_ITEM_BY_ID";
export const GET_STOCK_ADJUSTMENT_ITEM_BY_ID_SUCCESS =
  "GET_STOCK_ADJUSTMENT_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_ADJUSTMENT_ITEM = "ADD_NEW_STOCK_ADJUSTMENT_ITEM";
export const ADD_NEW_STOCK_ADJUSTMENT_ITEM_SUCCESS =
  "ADD_NEW_STOCK_ADJUSTMENT_ITEM_SUCCESS";
export const ADD_NEW_STOCK_ADJUSTMENT_ITEM_ERROR =
  "ADD_NEW_STOCK_ADJUSTMENT_ITEM_ERROR";

export const UPDATE_STOCK_ADJUSTMENT_ITEM = "UPDATE_STOCK_ADJUSTMENT_ITEM";
export const UPDATE_STOCK_ADJUSTMENT_ITEM_SUCCESS =
  "UPDATE_STOCK_ADJUSTMENT_ITEM_SUCCESS";
export const UPDATE_STOCK_ADJUSTMENT_ITEM_ERROR =
  "UPDATE_STOCK_ADJUSTMENT_ITEM_ERROR";

export const DELETE_STOCK_ADJUSTMENT_ITEM = "DELETE_STOCK_ADJUSTMENT_ITEM";
export const DELETE_STOCK_ADJUSTMENT_ITEM_SUCCESS =
  "DELETE_STOCK_ADJUSTMENT_ITEM_SUCCESS";

export const STOCK_ADJUSTMENT_ITEM_CLEAR_ERROR =
  "STOCK_ADJUSTMENT_ITEM_CLEAR_ERROR";
