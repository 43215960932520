import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MetaTags from "react-meta-tags"
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col, Collapse,
  Container, DropdownItem, DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  timestampFileName
} from "../../helpers/utils"

import { exportProductStockOutToExcel } from "../../store/product-stock-out-product/saga"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getPagingSewingOutstandingReport } from "../../store/sewing/actions"
import { debounce } from "lodash"
import { Tbody } from "react-super-responsive-table"
import NumberFormat from "react-number-format"
import CustomPagination from "../../components/Common/CustomPagination"
import { exportSewingOutstanding } from "../../store/sewing/saga"

const SewingOutstandingReport = props => {
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ term, setTerm ] = useState("")
  const [ collapseIsOpen, setCollapseIsOpen ] = useState(false)
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false)

  const {
    getPagingSewingOutstandingReport,
    sewing,
  } = props

  const { items, item, message, itemCount, pageCount, loading } =
      sewing

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  const handleExportToExcel = () => {
    exportSewingOutstanding({
      term,
    }, `${ timestampFileName() }_Sewing_Outstanding.xlsx`).then(() => {
      console.log('ok')
    })
  }

  useEffect(() => {
    getPagingSewingOutstandingReport(
        {
          term,
          page,
          pageSize,
        })
  }, [ page, pageSize, term ])

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sewing Outstanding Report | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Report" breadcrumbItem="Sewing Outstanding Report"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border-bottom pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => {
                                  const { value } = e.target
                                  debouncedQuickSearch(value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }>

                      </Col>
                      <Col md={ 2 }>

                      </Col>
                      <Col md={ 2 }>

                      </Col>
                      <Col md={ 4 }>

                        <div className="text-sm-end">
                          <Button
                              type="button"
                              color="primary"
                              outline
                              className="me-1"
                              onClick={handleExportToExcel}
                          >
                            <i className="fas fa-file-export me-1" /> Export
                            to Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-rep-plugin">
                      { loading ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary m-1" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                      ) : (
                          <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                          >
                            <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered table-nowrap"
                            >
                              <thead>
                              <tr>
                                <th style={ { width: "80px" } }>#</th>
                                <th className="text-center">Purchase Order</th>
                                <th className="text-center">Cutting</th>
                                <th className="text-center">Sewing</th>
                                <th className="text-center">Outstanding</th>
                              </tr>
                              </thead>
                              <Tbody>
                                { items.map((item, index) => {
                                  let num = (page - 1) * pageSize + (index + 1)
                                  let outstanding = item.cuttingQuantity - item.sewingQuantity
                                  return (
                                      <tr key={ index }>
                                        <td style={ { textAlign: "right" } }>{ num }</td>
                                        <td>{ item.code }</td>
                                        <td className="text-end">
                                          <NumberFormat
                                              value={ item.cuttingQuantity }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </td>
                                        <td className="text-end">
                                          <NumberFormat
                                              value={ item.sewingQuantity }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </td>

                                        <td className="text-end">
                                          <NumberFormat
                                              value={ outstanding }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </td>
                                      </tr>
                                  )
                                }) }
                              </Tbody>
                            </Table>
                          </div>
                      ) }
                    </div>
                    <CustomPagination
                        itemCount={ itemCount }
                        currentPage={ page }
                        totalPage={ pageCount }
                        defaultPageSize={ pageSize }
                        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                        onChangePage={ i => setPage(i) }
                        onPageSizeChange={ size => {
                          setPage(1)
                          setPageSize(size)
                        } }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

SewingOutstandingReport.propTypes = {
  sewing: PropTypes.object,
  getPagingSewingOutstandingReport: PropTypes.func
}

const mapStateToProps = ({ sewing }) => {
  return {
    sewing
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPagingSewingOutstandingReport,
    })(SewingOutstandingReport)
)
