import FileSaver from "file-saver";
import {
  get,
  getFile,
  post,
  del,
  put as update,
  postFile,
} from "helpers/api_helper";

const exportToPdf = async (id, fileName) => {
  return await getFile(`/api/Invoice/${id}/exportToPDF`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const exportToExcelRequest = async (query, fileName) => {
  return await postFile(`/api/Invoice/exportToExcel`, query, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const invoiceService = {
  exportToPdf,
  exportToExcelRequest,
};
