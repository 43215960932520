import React from 'react'
import PropTypes from "prop-types"
import moment from "moment/moment"
import NumberFormat from "react-number-format"

const PurchaseOrderToPrint  = React.forwardRef((props, ref) => {
    const { purchaseOrder } = props;
    
  return (
      <div ref={ ref }>
        <div className="print-container">
          <div className="print-content">
            <div className="header-section">
              <h2 className="header-title">Purchase Order</h2>
              <div className="header-section1">
                <div>
                  <p>Purchase Order: <strong>#{purchaseOrder?.code}</strong></p>
                  {/*<p>Reference Number: <strong>#{stockRequest.code}</strong></p>*/}
                </div>
                <div>
                  <p>Date: <strong>{moment(purchaseOrder?.dateRequest)
                      .local()
                      .format("DD-MMM-YYYY")}</strong></p>
                </div>
              </div>
            </div>
            <table className="table-print">
              <thead>
              <tr>
                <th>#</th>
                <th>Item Code</th>
                <th>Sample</th>
                <th>Roll</th>
                <th>Total Metre</th>
              </tr>
              </thead>
              <tbody>
              {
                  purchaseOrder && purchaseOrder.purchaseOrderItems.map((item, index) => {
                    const { itemCode, itemName, quantity, itemUnitOfMeasurementConversion, itemUnitOfMeasurementTitle, itemAttachments } = item
                    let itemAttachment = itemAttachments.length > 0 ? itemAttachments[0] : null
                
                    let totalQuantity = quantity * itemUnitOfMeasurementConversion;
                    
                    return <tr key={index}>
                      <td className={ "text-right" }>{index + 1}</td>
                      <td>{itemCode} - {itemName}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          {
                            itemAttachment ?
                                <img style={ { width: "80px", height: "50px", objectFit: "cover" } }
                                     src={ itemAttachment.url } alt=""/> : <>
                                  <div className="avatar-order">
                                    <i className="fas fa-socks text-primary"></i>
                                  </div>
                                </>
                          }
                        </div>
                      </td>
                      <td>
                        <NumberFormat
                            value={quantity}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                        />
                        <span className={"text-right ps-2"}>{itemUnitOfMeasurementTitle}</span>
                      </td>
                      <td className={ "text-right" }>
                        <NumberFormat
                            value={totalQuantity ?? 0}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                        />
                      </td>
                    </tr>
                  })
              }
              </tbody>
            </table>
            <div className="footer-section">
              <div>
                <p>Warehouse: </p>
                <div className="box-signature"></div>
                {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/}
              </div>
              <div>
                <p>Checked By: </p>
                <div className="box-signature"></div>
                {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/}
              </div>
              <div>
                <p>Approved By: </p>
                <div className="box-signature"></div>
                {/*<p><strong>{stockRequest.approvedByUser}</strong></p>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
});


PurchaseOrderToPrint.propTypes = {
  purchaseOrder: PropTypes.object
}

PurchaseOrderToPrint.displayName="PurchaseOrderToPrint";

export default PurchaseOrderToPrint