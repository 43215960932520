import {
  GET_ITEM_SHIPPING_FEE_ENTRYS,
  GET_ITEM_SHIPPING_FEE_ENTRYS_SUCCESS,
  GET_ITEM_SHIPPING_FEE_ENTRYS_FAIL,
  GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_SUCCESS,
  UPDATE_ITEM_SHIPPING_FEE_ENTRY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
};

const itemShippingFee = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ITEM_SHIPPING_FEE_ENTRY:
      return {
        ...state,
        loading: true,
      };

    case GET_ITEM_SHIPPING_FEE_ENTRYS:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEM_SHIPPING_FEE_ENTRYS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case GET_ITEM_SHIPPING_FEE_ENTRYS_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default itemShippingFee;
