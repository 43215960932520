import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import Select from "react-select";

const transportationTypeOptions = [
  { key: 1, value: "Sea", label: "Sea" },
  { key: 2, value: "Air", label: "Air" },
];

const typeOptions = [
  { key: 1, value: 0, label: "Weight" },
  { key: 2, value: 1, label: "CBM Rate" },
];

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",
      transportationType: null,
      isDefault: false,
      type: null,
      submitted: false,
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, title, type, transportationType, isDefault } = this.state;
    this.setState({
      submitted: true,
    });

    const item = {
      id,
      title,
      transportationType: transportationType?.value,
      isDefault,
      type: type?.value ?? 0,
    };

    var isValid = item.title;

    if (isValid) {
      this.props.onSubmit(item);
    }
  };

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
        transportationType: transportationTypeOptions.find(
          e => e.value === item.transportationType
        ),
        type: typeOptions.find(e => e.value === item.type) ?? null,
      });
    } else {
      this.setState({
        id: NIL,
        title: "",
        type: null,
        transportationType: null,
        isDefault: false,
        submitted: false,
      });
    }
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted, transportationType } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Title</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={submitted && !this.state.title ? "is-invalid" : ""}
                  value={this.state.title}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.title && (
                  <div className="invalid-feedback-custom">
                    Title is required
                  </div>
                )}
              </FormGroup>
              <FormGroup
                className={
                  "select2-container" +
                  (submitted && !transportationType ? " is-invalid" : "")
                }
              >
                <Label>Transportation Type</Label>
                <Select
                  name="transportationType"
                  value={this.state.transportationType}
                  onChange={this.handleSelectChange}
                  options={transportationTypeOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
              {submitted && !transportationType && (
                <div className="invalid-feedback-custom">
                  Transportation Type is required.
                </div>
              )}
              <div className="mb-3">
                <div
                  className={
                    "select2-container" +
                    (submitted && !this.state.type ? " is-invalid" : "")
                  }
                >
                  <Label>Type</Label>
                  <Select
                    name="type"
                    value={this.state.type}
                    onChange={this.handleSelectChange}
                    options={typeOptions}
                    classNamePrefix="test select2-selection"
                    isClearable
                  />
                </div>
                {submitted && !this.state.type && (
                  <div className="invalid-feedback-custom">
                    Type is required.
                  </div>
                )}
              </div>
              <div className="form-check form-check-primary mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheckcolor1"
                  checked={this.state.isDefault}
                  onChange={e => {
                    this.setState({
                      isDefault: e.target.checked,
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="customCheckcolor1">
                  Is Default
                </label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default ModalForm;
