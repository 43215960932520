import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getItemById, getItemUnitOfMeasurements, getPagingItemOutHistory } from "store/actions";
import ItemUnitOfMeasurementTab from "./ItemUnitOfMeasurementTab";
import ItemAttachmentTab from "./ItemAttachmentTab";
import ItemOutHistoryTab from "./ItemOutHistoryTab"

const ItemDetail = props => {
  const { getPagingItemOutHistory, match, history } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [id, setId] = useState(null);

  const { getItemById, getItemUnitOfMeasurements } = props;
  const { item, loading } = props.item;

  useEffect(() => {
    const { id } = match.params;
    getItemById(id);
    setId(id);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Item Detail | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          {item ? (
            <Breadcrumbs
              title="Home"
              backLink={"/item"}
              breadcrumbItem={`${item.code}-${item.name} | Item Detail`}
            />
          ) : null}

          <Row>
            <Col md={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      setActiveTab("1");
                      getItemUnitOfMeasurements({ itemId: id });
                    }}
                  >
                    Unit of Measurment
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    Attachments
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        setActiveTab("3");
                        getPagingItemOutHistory({itemId: id})
                      }}
                  >
                    Out History
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {id ? <ItemUnitOfMeasurementTab itemId={id} /> : null}
                </TabPane>
                <TabPane tabId="2">
                  {id ? <ItemAttachmentTab itemId={id} /> : null}
                </TabPane>

                <TabPane tabId="3">
                  {id ? <ItemOutHistoryTab itemId={id} /> : null}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ItemDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getItemById: PropTypes.func,
  getPagingItemOutHistory: PropTypes.func,
};

const mapStateToProps = ({ item }) => {
  return {
    item,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getItemById,
    getItemUnitOfMeasurements,
    getPagingItemOutHistory,
  })(ItemDetail)
);
