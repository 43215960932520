import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL as NIL_UUID } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Select from "react-select";
import { userService } from "services/user-service";

export class UserSelectionModalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemCount: 0,
      pageCount: 0,

      selectedUser: null,
      users: null,
    };
  }

  componentDidMount() {}

  handleSelect = user => {
    this.setState(prevState => ({
      selectedUser: user,
      users: prevState.users.map(a => {
        return a.id === user.id
          ? { ...a, isSelect: true }
          : { ...a, isSelect: false };
      }),
    }));
  };

  handleFilter = e => {
    const { value } = e.target;
    userService.getUserPagingRequest({ term: value ?? "" }).then(res => {
      const { itemCount, pageCount, data } = res;
      this.setState({
        users: data.map(user => {
          return {
            ...user,
            isSelect: false,
          };
        }),
        itemCount,
        pageCount,
      });
    });
  };

  onOpened = () => {
    const { notUserIds } = this.props;

    userService
      .getUserPagingRequest({ term: "", notUserIds: notUserIds })
      .then(res => {
        const { itemCount, pageCount, data } = res;
        this.setState({
          selectedUser: null,
          users: data.map(user => {
            return {
              ...user,
              isSelect: false,
            };
          }),
          itemCount,
          pageCount,
        });
      });
  };

  handleSubmit = () => {
    const { selectedUser } = this.state;
    if (selectedUser) {
      this.props.onSubmit(selectedUser);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { users, selectedUser } = this.state;

    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Input
                  type="text"
                  name="search"
                  placeholder="Search: Id, Name, PhoneNumber ..."
                  onKeyUp={e => this.handleFilter(e)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered table-nowrap"
              >
                <Thead>
                  <Tr>
                    <Th>Id</Th>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th style={{ width: "120px" }}>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users &&
                    users.map((user, index) => {
                      const name = `${user.firstName} ${user.lastName}`;
                      return (
                        <Tr key={index}>
                          <Td>{user.uniqueId}</Td>
                          <Td>{name}</Td>
                          <Td>{user.phoneNumber}</Td>
                          <Td>
                            <Button
                              size="sm"
                              outline={!user.isSelect}
                              color="primary"
                              onClick={() => this.handleSelect(user)}
                            >
                              {user.isSelect ? "Selected" : "Select"}
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            disabled={selectedUser === null}
            onClick={this.handleSubmit}
            type="submit"
          >
            Select
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

UserSelectionModalList.propTypes = {
  item: PropTypes.object,
  notUserIds: PropTypes.array,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default UserSelectionModalList;
