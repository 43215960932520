import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap"
import AttachmentUploadModalForm from "../../containers/purchase-order-attachment/AttachmentUploadModalForm"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  getPurchaseOrderAttachments,
  addNewPurchaseOrderAttachment,
  deletePurchaseOrderAttachment,
  getPurchaseOrderAttachmentById,
  updatePurchaseOrderAttachment
} from "../../store/purchase-order-attachment/actions"
import { debounce } from "lodash"
import ConfirmDeleteModal from "../../components/Common/ConfirmDeleteModal"
import moment from "moment"

const PurchaseOrderAttachmentTab = props => {
  const {
    getPurchaseOrderAttachments,
    addNewPurchaseOrderAttachment,
    deletePurchaseOrderAttachment,
    updatePurchaseOrderAttachment,
    getPurchaseOrderAttachmentById,
    purchaseOrderAttachment,
    purchaseOrderId
  } = props

  const { items, item, loading } = purchaseOrderAttachment
  const [ modalUploadForm, setModalUploadForm ] = useState(false)
  const [ modalEditForm, setModalEditForm ] = useState(false)
  const [ modalConfirmDelete, setModalConfirmDelete ] = useState(false)
  const [ term, setTerm ] = useState()
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ selectedId, setSelectedId ] = useState(null)


  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  useEffect(() => {
    getPurchaseOrderAttachments({ purchaseOrderId, term, page, pageSize })
  }, [ term, page, pageSize ])

  const handleSubmit = data => {
    addNewPurchaseOrderAttachment(data)
    setModalUploadForm(false)
  }

  const handleUpdate = (data) => {
    updatePurchaseOrderAttachment(data)
    setModalEditForm(false)
  }

  const handleDelete = () => {
    deletePurchaseOrderAttachment({ id: selectedId, purchaseOrderId })
    setModalConfirmDelete(false)
  }

  return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target
                          debouncedQuickSearch(value)
                        } }
                    />
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      color="primary"
                      onClick={ () => setModalUploadForm(true) }
                  >
                    <i className="fas fa-cloud-upload-alt"/> Upload files
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {
              loading ? <div className={ "loading" }></div> :
                  <>
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th className={"text-center"} style={ { width: "80px" } }>#</th>
                        <th className={"text-center"}>Title</th>
                        <th className={"text-center"}>File Name</th>
                        <th className={"text-center"}>Created Date</th>
                        <th className={"text-center"} style={ { width: "120px" } }>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        items.map((item, index) => {
                          let num = (page - 1) * pageSize + (index + 1)
                          return <tr key={ index }>
                            <td className={"text-end"}>{ num }</td>
                            <td>{ item.title }</td>
                            <td>{ item.fileName }</td>
                            <td className="text-end">
                              { moment
                                  .utc(item.createdDate)
                                  .local()
                                  .format("DD-MMM-yyyy h:mm:ss a") }
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <div className="d-flex gap-3">
                                  <Link className="text-primary" to="#">
                                    <i
                                        className="mdi mdi-download font-size-18"
                                        id="deletetooltip"
                                        onClick={ () => {
                                          // setSelectedId(item.id)
                                          window.open(item.url, '_blank');
                                        } }
                                    ></i>
                                  </Link>
                                  <Link className="text-primary" to="#">
                                    <i
                                        className="mdi mdi-pencil font-size-18"
                                        id="edittooltip"
                                        onClick={ () => {
                                          getPurchaseOrderAttachmentById(item.id);
                                          setModalEditForm(true)
                                          // setModalUploadForm(true);
                                        } }
                                    ></i>
                                  </Link>

                                  <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={ () => {
                                          setSelectedId(item.id)
                                          setModalConfirmDelete(true)
                                        } }
                                    ></i>
                                  </Link>
                                 
                                </div>
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </Table>

                    <AttachmentUploadModalForm
                        isOpen={ modalUploadForm }
                        toggle={ () => setModalUploadForm(!modalUploadForm) }
                        purchaseOrderId={ purchaseOrderId }
                        title={ "Upload Attachment" }
                        onSubmit={ handleSubmit }
                    />


                    <ConfirmDeleteModal
                        title="Confirm Delete"
                        isOpen={ modalConfirmDelete }
                        toggle={ () => setModalConfirmDelete(!modalConfirmDelete) }
                        onSubmit={ handleDelete }
                    />
                        <AttachmentUploadModalForm
                            isOpen={ modalEditForm }
                            toggle={ () => setModalEditForm(!modalEditForm) }
                            purchaseOrderId={ purchaseOrderId }
                            title={ "Edit Attachment" }
                            item={ item }
                            onSubmit={ handleUpdate }
                        />
                    
                  </>
            }
          </CardBody>
        </Card>

       
      </>
  )
}

PurchaseOrderAttachmentTab.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  addNewPurchaseOrderAttachment: PropTypes.func,
  purchaseOrderAttachment: PropTypes.object,
  getPurchaseOrderAttachments: PropTypes.func,
  deletePurchaseOrderAttachment: PropTypes.func,
  updatePurchaseOrderAttachment: PropTypes.func,
  getPurchaseOrderAttachmentById: PropTypes.func,
}

const mapStateToProps = ({ purchaseOrderAttachment }) => {
  return {
    purchaseOrderAttachment
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPurchaseOrderAttachments,
      addNewPurchaseOrderAttachment,
      deletePurchaseOrderAttachment,
      updatePurchaseOrderAttachment,
      getPurchaseOrderAttachmentById
      // deleteItemAttachment,
      // updateOrder,
      // getOrderById,
      // updateItemAttachment,
      // deleteItemAttachment,
    })(PurchaseOrderAttachmentTab)
)
