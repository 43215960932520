import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import PropTypes from "prop-types";
import { debounce, isEmpty } from "lodash";
import { NIL } from "uuid";
import Select from "react-select";
import { unitOfMeasurmentService } from "services/unit-of-measurement-service";
import { getItemsRequest } from "store/item/saga";
import { setOptions } from "leaflet";
import { getItemUnitOfMeasurements } from "store/actions";
import { getItemUnitOfMeasurementsRequest } from "store/item-unit-of-measurement/saga";
import ItemSelectionModal from "containers/item/ItemSelectionModal";

const ModalForm = ({ isOpen, toggle, title, stockInId, item, onSubmit }) => {
  const [id, setId] = useState(NIL);
  const [itemId, setItemId] = useState(null);
  const [term, setTerm] = useState("");
  const [unitOfMeasurementId, setUnitOfMeasurementId] = useState(null);
  const [quantity, setQuantity] = useState(0);

  const [submitted, setSubmitted] = useState(false);
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [isLoadingItem, setIsLoadingItem] = useState(false);
  const [modalSelectItemIsOpen, setModalSelectItemIsOpen] = useState(false);

  const debouncedFetchItems = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );

  useEffect(() => {
    setIsLoadingItem(true);
    getItemsRequest({ term }).then(res => {
      const { data } = res;
      setItemOptions(
        data.map(a => {
          return {
            id: a.id,
            value: a.id,
            label: `${a.code} - ${a.name}`,
          };
        })
      );
      setIsLoadingItem(false);
    });
  }, [term]);

  const onOpened = () => {
    if (item) {
      const { id } = item;
      setId(id);
      setItemId({
        id: item.itemId,
        value: item.itemId,
        label: `${item.itemCode} - ${item.itemName}`,
      });
      setUnitOfMeasurementId({
        id: item.unitOfMeasurementId,
        value: item.unitOfMeasurementId,
        label: item.unitOfMeasurementName,
      });
      setQuantity(item.quantity);
      handlePopulateUnitOfMeasurementOption(item.itemId);
    } else {
      setId(NIL);
      setItemId(null);
      setUnitOfMeasurementId(null);
      setQuantity(0);

      setSubmitted(false);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    let isValid = itemId && unitOfMeasurementId && quantity;

    if (isValid) {
      const data = {
        id,
        stockInId,
        itemId: itemId?.value,
        unitOfMeasurementId: unitOfMeasurementId.value,
        quantity,
      };

      onSubmit(data);
    }
  };

  const handlePopulateUnitOfMeasurementOption = itemId => {
    getItemUnitOfMeasurementsRequest({ itemId: itemId }).then(res => {
      const { data } = res;
      setUnitOfMeasurementOptions(
        data.map(a => {
          return {
            key: "",
            value: a.unitOfMeasurementId,
            label: a.unitOfMeasurementName,
          };
        })
      );
    });
  };

  const handleSelectedItem = item => {
    setItemId({
      id: item.id,
      value: item.id,
      label: `${item.code} - ${item.name}`,
    });
    handlePopulateUnitOfMeasurementOption(item.id);
    setModalSelectItemIsOpen(false);
  };

  return (
    <>
      <Modal
        size="md"
        onOpened={onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <div className="d-flex">
                <FormGroup className="flex-grow-1">
                  <Label>Item</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      value={itemId?.label || ""}
                      className={`form-control me-1`}
                      readOnly={true}
                    />
                    <Button
                      size="sm"
                      color="primary"
                      outline
                      onClick={() => setModalSelectItemIsOpen(true)}
                    >
                      Select
                    </Button>
                  </div>
                </FormGroup>
              </div>
              <FormGroup className="mb-3">
                <Label htmlFor="">Quantity</Label>
                <Input
                  type="number"
                  name="quantity"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={e => {
                    setQuantity(e.target.value);
                  }}
                />
                {submitted && quantity == 0 && (
                  <div className="invalid-feedback-custom">
                    Quantity is required.
                  </div>
                )}
              </FormGroup>
              <div className="mb-3">
                <div
                  className={
                    "select2-container" +
                    (submitted && !unitOfMeasurementId ? " is-invalid" : "")
                  }
                >
                  <Label>Unit of Measurement</Label>
                  <Select
                    name="unitOfMeasurementId"
                    value={unitOfMeasurementId}
                    onChange={option => {
                      setUnitOfMeasurementId(option);
                    }}
                    options={unitOfMeasurementOptions}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                </div>
                {submitted && !unitOfMeasurementId && (
                  <div className="invalid-feedback-custom">
                    Unit of Measurement is required.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <ItemSelectionModal
        title="Item Selection"
        isOpen={modalSelectItemIsOpen}
        toggle={() => setModalSelectItemIsOpen(!modalSelectItemIsOpen)}
        onSubmit={handleSelectedItem}
      />
    </>
  );
};

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  stockInId: PropTypes.string,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
