import React, { Component } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { NIL } from "uuid";
import Select from "react-select";
import { currencyService } from "services/currency-service";

export class OrderAdditionalEntryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      orderId: null,
      currencyId: null,
      title: "",
      amount: 0,

      currencyOptions: [],
    };
  }

  handleSelectChange = (valueType, actionMeta) => {
    const { item } = this.props;
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
    switch (name) {
      case "currencyId":
        item.currencyId = valueType;
        break;

      default:
        break;
    }

    this.props.onChange(item);
  };

  componentDidMount = () => {
    currencyService.getRequest().then(data => {
      if (data) {
        this.setState({
          currencyOptions: data
            .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            .map(item => {
              return {
                name: item.label,
                key: item.id,
                value: item.id,
                label: item.symbol,
              };
            }),
        });
      }
    });
  };

  handleChange = e => {
    const { value, name } = e.target;
    const { item } = this.props;

    switch (name) {
      case "title":
        item.title = value;
        break;
    }

    this.props.onChange(item);
  };

  handleOnAmountChange = value => {
    const { item } = this.props;
    item.amount = value;
    this.props.onChange(item);
  };

  render() {
    const { item, isPrimary, onAdd, onRemove } = this.props;
    const { title, currencyId, amount } = item;
    const { currencyOptions } = this.state;
    return (
      <div>
        <Row>
          <Col md={6}>
            <div className="mb-2">
              <Input
                type="text"
                name="title"
                placeholder="Description"
                value={title}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-2">
              <NumberFormat
                name="amount"
                className="form-control text-end"
                placeholder="Amount"
                value={amount}
                thousandSeparator={true}
                // fixedDecimalScale={true}
                onValueChange={obj => {
                  const { value } = obj;
                  this.handleOnAmountChange(value);
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-2 me-3">
              <Select
                name="currencyId"
                value={currencyId}
                onChange={this.handleSelectChange}
                options={currencyOptions}
                classNamePrefix="select2-selection"
                isClearable
              />
            </div>
            <div
              style={{ position: "absolute", right: "0", top: "7px" }}
              onClick={isPrimary ? () => onAdd() : () => onRemove(item)}
            >
              <span
                className="font-size-16 text-primary p-2"
                style={{ cursor: "pointer" }}
              >
                <i
                  className={isPrimary ? "fas fa-plus" : "fas fa-trash-alt"}
                ></i>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

OrderAdditionalEntryForm.propTypes = {
  item: PropTypes.object,
  isPrimary: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default OrderAdditionalEntryForm;
