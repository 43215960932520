import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import moment from "moment";
import { join, sumBy } from "lodash";
import NumberFormat from "react-number-format";
import { timestampFileName } from "helpers/utils";
import { invoiceService } from "services/invoice-service";

export class InvoiceDetailInfoTab extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleExportToPdf = () => {
    const { item } = this.props;
    if (item) {
      const { id } = item;
      invoiceService.exportToPdf(id, `${timestampFileName()}_invoice.pdf`);
    }
  };

  render() {
    const { item } = this.props;
    const { order } = item;

    const {
      sender,
      senderUniqueId,
      senderAddress,
      shippingContactObject,
      totalAmount,
      isPickup,
      isDelivery,
      pickupFee,
      deliveryFee,
      orderItems,
      weight,
      weightExtra,
      weightComment,
      weightFeeAmount,
      discountAmount,
      discountPercentage,
      unitFee,
      unitFeeAmount,
      createdDate,
      cbmExchangeRate,
      exchangeRate,
      orderAdditionalEntries,
      cbmRate,
    } = order;

    let totalQuantity = sumBy(orderItems, orderItem => {
      return orderItem.quantity;
    });

    let totalVolumn = sumBy(orderItems, orderItem => {
      const { quantity, length, width, height } = orderItem;
      return quantity * length * width * height;
    });

    let shippingFeeUSD = (totalVolumn ?? 0) * cbmRate;
    let shippingFeeKRW = (shippingFeeUSD ?? 0) * cbmExchangeRate;
    let totalPayment = sumBy(
      item.invoicePayments.filter(e => e.paymentStatus === 2), // 2 is Confirmed
      invoicePayment => {
        return invoicePayment.amount;
      }
    );

    let balanceDue = totalAmount - totalPayment;

    const { contact, phones, address } = shippingContactObject;
    let phoneArr = phones
      ? phones.map(a => {
          return a.phoneNumber;
        })
      : [];

    let totalOrderAdditionalEntries = 0;

    return (
      <Card>
        <CardBody className="border-bottom">
          <Row>
            <Col md={6}></Col>
            <Col md={6} className="text-sm-end">
              <h4 className="float-end font-size-16">
                Invoice No #{item.invoiceNo}
              </h4>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <address>
                <strong>Billed To:</strong>
                <br />
                <span>
                  {sender} | {senderUniqueId}
                </span>
                <br />
                <span>{senderAddress}</span>
                <br />
              </address>
            </Col>
            <Col md={4}>
              <address className="mt-2 mt-sm-0">
                <strong>Shipped To:</strong>
                <br />
                <span>{contact}</span>
                <br />
                <span>{join(phoneArr, ", ")}</span>
                <br />
                <span>{address}</span>
                <br />
              </address>
            </Col>
            <Col md={4} className="text-sm-end">
              <address className="mt-2 mt-sm-0">
                <strong>Invoice Date:</strong>
                <br />
                <span>{moment(item.date).format("DD-MMM-YYYY")}</span>
                <br />
              </address>

              {item.dueDate && (
                <address className="mt-2 mt-sm-0">
                  <strong>Due Date</strong>
                  <br />
                  <span>{moment(item.dueDate).format("DD-MMM-YYYY")}</span>
                  <br />
                </address>
              )}

              <address className="mt-2 mt-sm-0">
                <strong>Order Date</strong>
                <br />
                <span>{moment(createdDate).format("DD-MMM-YYYY")}</span>
                <br />
              </address>
              <address className="mt-2 mt-sm-0">
                <strong>Tracking No</strong>
                <br />
                <span>#{order.trackingNumber}</span>
                <br />
              </address>
            </Col>
          </Row>
          <Row>
            <Col md={6}></Col>
            <Col md={6} className="text-sm-end"></Col>
          </Row>
        </CardBody>
        <CardBody>
          <Row>
            <Col md={12}>
              <h5>Order summary</h5>
            </Col>
            {orderItems && orderItems.length > 0 ? (
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>No</th>
                    <th>Title</th>
                    <th>Quantity</th>
                    <th>L</th>
                    <th>W</th>
                    <th>H</th>
                    <th style={{ width: "10%" }}>Volume</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems &&
                    orderItems.map((orderItem, index) => {
                      const { title, quantity, width, height, length } =
                        orderItem;
                      let volume = quantity * length * width * height;
                      return (
                        <tr key={index}>
                          <td className="text-end">{index + 1}</td>
                          <td>{title}</td>
                          <td className="text-end">{quantity}</td>
                          <td className="text-end">
                            {Number(length).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(width).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(height).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(volume).toFixed(4)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <th>Total</th>
                    <th className="text-end">{totalQuantity}</th>
                    <th colSpan={3} className="text-end">
                      Total Volume
                    </th>

                    <th className="text-end">
                      {Number(totalVolumn).toFixed(4)}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      CBM Rate (USD)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Shipping Fee (USD)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(shippingFeeUSD).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Exchange Rate
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(cbmExchangeRate).toFixed(2)}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="text-end">
                      Shipping Fee (KRW)
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(shippingFeeKRW).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </th>
                  </tr>
                </tfoot>
              </table>
            ) : null}
            {weight ? (
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th style={{ width: "10%" }}>Quantity</th>
                    <th style={{ width: "10%" }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{order.description}</p>
                      <p>Weight: {weight}</p>
                      <p>Weight Extra: {weightExtra}</p>
                      <p>
                        Weight Component:{" "}
                        <span className="text-muted">{weightComment}</span>{" "}
                      </p>
                    </td>
                    <td className="text-end">{order.quantity}</td>
                    <td className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={weightFeeAmount}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
            {unitFee ? (
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th style={{ width: "10%" }}>Quantity</th>
                    <th style={{ width: "10%" }}>Unit Price</th>
                    <th style={{ width: "10%" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{order.description}</p>
                    </td>
                    <td className="text-end">{order.quantity}</td>
                    <td className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(unitFee).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </td>
                    <td className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(unitFeeAmount).toFixed()}
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}

            {orderAdditionalEntries && orderAdditionalEntries.length > 0 && (
              <table className="table-nowrap table table-bordered">
                <thead>
                  <tr>
                    <th>Additional Services</th>
                    <th style={{ width: "10%" }}>Amount</th>
                    <th style={{ width: "10%" }}>Currency</th>
                    <th style={{ width: "10%" }}>Rate</th>
                    <th style={{ width: "10%" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderAdditionalEntries.map((item, index) => {
                    const { amount, currencyLabel } = item;
                    let displayExchangeRate =
                      currencyLabel === "USD" ? exchangeRate : 1;
                    let totalOrderAdditionalEntry =
                      amount * displayExchangeRate;
                    totalOrderAdditionalEntries += totalOrderAdditionalEntry;
                    return (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={amount}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                        <td>{item.currencyLabel}</td>
                        <td className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={displayExchangeRate}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                        <td className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={Number(totalOrderAdditionalEntry).toFixed(0)}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={4} className="text-end">
                      Total
                    </th>
                    <th className="text-end">
                      <NumberFormat
                        displayType="text"
                        value={Number(totalOrderAdditionalEntries).toFixed(0)}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        readOnly
                      />
                    </th>
                  </tr>
                </tfoot>
              </table>
            )}

            {isPickup ||
              isDelivery ||
              (discountAmount > 0 && (
                <table className="table-nowrap table table-bordered">
                  <tbody>
                    {isPickup && (
                      <tr>
                        <td className="border-0 text-end">
                          <strong>Pickup</strong>
                        </td>
                        <td className="text-end w-10">
                          <NumberFormat
                            displayType="text"
                            value={pickupFee}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                      </tr>
                    )}

                    {isDelivery && (
                      <tr>
                        <td className="border-0 text-end">
                          <strong>Delivery</strong>
                        </td>
                        <td className="text-end w-10">
                          <NumberFormat
                            displayType="text"
                            value={deliveryFee}
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </td>
                      </tr>
                    )}

                    {discountAmount > 0 && (
                      <tr>
                        <td className="border-0 text-end">
                          <strong>Discount {discountPercentage} %</strong>
                        </td>
                        <td style={{ width: "10%" }} className="text-end">
                          <NumberFormat
                            displayType="text"
                            value={Number(discountAmount).toFixed()}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            readOnly
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ))}

            <Col md={12}>
              <Row>
                <Col md={4}>
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p>Bank Name :</p>
                          <p>Acc Name :</p>
                          <p>Acc No :</p>
                        </td>
                        <td>
                          <p>KB (국민은행)</p>
                          <p>김현희(주은)</p>
                          <p>868601-01-618931</p>
                        </td>
                        <td>
                          <p>ABA BANK</p>
                          <p>LIM LEANGKRUY</p>
                          <p>001 168 202</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md={8}>
                  <table className="table table-borderless">
                    <tbody>
                      <tr style={{ borderStyle: "solid" }}>
                        <td className="border-0 text-end">
                          <strong>TOTAL (KRW)</strong>
                        </td>
                        <td
                          className="border-0 text-end"
                          style={{ width: "100px" }}
                        >
                          <h4 className="m-0">
                            <NumberFormat
                              displayType="text"
                              value={Number(totalAmount).toFixed()}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              readOnly
                            />
                          </h4>
                        </td>
                      </tr>
                      <tr style={{ borderStyle: "solid" }}>
                        <td className="border-0 text-end">
                          <strong>EX.RATE</strong>
                        </td>
                        <td
                          className="border-0 text-end"
                          style={{ width: "100px" }}
                        >
                          <h4 className="m-0">
                            <NumberFormat
                              displayType="text"
                              value={Number(exchangeRate).toFixed(2)}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              readOnly
                            />
                          </h4>
                        </td>
                      </tr>
                      <tr style={{ borderStyle: "solid" }}>
                        <td className="border-0 text-end">
                          <strong>TOTAL (USD)</strong>
                        </td>
                        <td
                          className="border-0 text-end"
                          style={{ width: "100px" }}
                        >
                          <h4 className="m-0">
                            <NumberFormat
                              displayType="text"
                              value={Number(totalAmount / exchangeRate).toFixed(
                                2
                              )}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              readOnly
                            />
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 text-end">
                          <strong>PAID (KRW)</strong>
                        </td>
                        <td className="border-0 text-end">
                          <h4 className="m-0">
                            <NumberFormat
                              displayType="text"
                              value={`-${Number(totalPayment).toFixed()}`}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              readOnly
                            />
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 text-end">
                          <strong>BALANCE DUE (KRW)</strong>
                        </td>
                        <td className="border-0 text-end">
                          <h4 className="m-0">
                            <NumberFormat
                              displayType="text"
                              value={Number(balanceDue).toFixed()}
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                              readOnly
                            />
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="float-end">
            <Button
              outline
              color="primary"
              onClick={this.handleExportToPdf}
              className="me-1"
            >
              <i className="fas fa-file-pdf"></i> Export to PDF
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

InvoiceDetailInfoTab.propTypes = {
  item: PropTypes.object,
};

export default InvoiceDetailInfoTab;
