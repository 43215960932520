import { SEVERITY } from "helpers/utils.js";
import {
  GET_STOCK_REQUESTS,
  GET_STOCK_REQUESTS_SUCCESS,
  GET_STOCK_REQUEST_BY_ID_SUCCESS,
  GET_STOCK_REQUESTS_ERROR,
  STOCK_REQUEST_CLEAR_ERROR,
  GET_CONFIRM_STOCK_REQUEST,
  GET_CONFIRM_STOCK_REQUEST_SUCCESS,
  SUBMIT_WITH_STATUS_STOCK_REQUEST, UPDATE_STOCK_REQUEST_ERROR,
} from "./actionTypes.js"
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
  invalid: false,
  validationType: null,
};

const stockRequest = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_WITH_STATUS_STOCK_REQUEST:
    case GET_CONFIRM_STOCK_REQUEST:
    case GET_STOCK_REQUESTS:
      return {
        ...state,
        loading: true,
      };
    case GET_CONFIRM_STOCK_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Stock In",
          text: "Stock In has been confirmed successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };
    case GET_STOCK_REQUESTS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        invalid: false,
        validationType: null,
        loading: false,
      };
    case GET_STOCK_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        invalid: false,
        validationType: null,
        item: payload,
      };
    case UPDATE_STOCK_REQUEST_ERROR:
      const { result, validationType } = payload;
      return {
        ...state,
        loading: false,
        invalid: true,
        validationType,
        item: result
      }
      
    case GET_STOCK_REQUESTS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Code");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Item is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case STOCK_REQUEST_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default stockRequest;
