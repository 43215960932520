import {
  GET_STOCK_INS,
  GET_STOCK_INS_SUCCESS,
  GET_STOCK_INS_ERROR,
  GET_STOCK_IN_BY_ID,
  GET_STOCK_IN_BY_ID_SUCCESS,
  ADD_NEW_STOCK_IN,
  ADD_NEW_STOCK_IN_SUCCESS,
  UPDATE_STOCK_IN,
  UPDATE_STOCK_IN_SUCCESS,
  DELETE_STOCK_IN,
  DELETE_STOCK_IN_SUCCESS,
  STOCK_IN_CLEAR_ERROR,
  ADD_NEW_STOCK_IN_ERROR,
  GET_CONFIRM_STOCK_IN,
  GET_CONFIRM_STOCK_IN_SUCCESS,
} from "./actionTypes";
// StockIn
export const getStockIns = query => ({
  type: GET_STOCK_INS,
  payload: query,
});

export const getStockInsSuccess = payload => ({
  type: GET_STOCK_INS_SUCCESS,
  payload,
});

export const getStockInsError = error => ({
  type: GET_STOCK_INS_ERROR,
  payload: error,
});

export const getStockInById = id => ({
  type: GET_STOCK_IN_BY_ID,
  payload: id,
});

export const getStockInByIdSuccess = payload => ({
  type: GET_STOCK_IN_BY_ID_SUCCESS,
  payload,
});

export const addNewStockIn = item => ({
  type: ADD_NEW_STOCK_IN,
  payload: item,
});

export const addNewStockInSuccess = item => ({
  type: ADD_NEW_STOCK_IN_SUCCESS,
  payload: item,
});

export const addNewStockInError = error => ({
  type: ADD_NEW_STOCK_IN_ERROR,
  payload: error,
});

export const updateStockIn = item => ({
  type: UPDATE_STOCK_IN,
  payload: item,
});

export const updateStockInSuccess = item => ({
  type: UPDATE_STOCK_IN_SUCCESS,
  payload: item,
});

export const deleteStockIn = item => ({
  type: DELETE_STOCK_IN,
  payload: item,
});

export const deleteStockInSuccess = item => ({
  type: DELETE_STOCK_IN_SUCCESS,
  payload: item,
});

export const clearStockInError = () => ({
  type: STOCK_IN_CLEAR_ERROR,
});

export const getConfirmStockIn = payload => ({
  type: GET_CONFIRM_STOCK_IN,
  payload,
});

export const getConfirmStockInSuccess = payload => ({
  type: GET_CONFIRM_STOCK_IN_SUCCESS,
  payload,
});
