import {
  GET_DEVICE_INFO_NOTIFICATION_HISTORY,
  GET_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  GET_DEVICE_INFO_NOTIFICATION_HISTORY_FAIL,
  GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID,
  GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID_SUCCESS,
  ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY,
  ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY,
  UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  DELETE_DEVICE_INFO_NOTIFICATION_HISTORY,
  DELETE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  DEVICE_INFO_NOTIFICATION_HISTORY_CLEAR_NOTIFY,
} from "./actionTypes";

export const getDeviceInfoNotificationHistory = id => ({
  type: GET_DEVICE_INFO_NOTIFICATION_HISTORY,
  payload: id,
});

export const getDeviceInfoNotificationHistorySuccess = items => ({
  type: GET_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  payload: items,
});

export const getDeviceInfoNotificationHistoryFail = error => ({
  type: GET_DEVICE_INFO_NOTIFICATION_HISTORY_FAIL,
  payload: error,
});

export const getDeviceInfoNotificationHistoryById = data => ({
  type: GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID,
  payload: data,
});

export const getDeviceInfoNotificationHistoryByIdSuccess = item => ({
  type: GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID_SUCCESS,
  payload: item,
});

export const getDeviceInfoNotificationHistoryByIdFail = error => ({
  type: GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID_FAIL,
  payload: error,
});

export const addNewDeviceInfoNotificationHistory = payload => ({
  type: ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY,
  payload,
});

export const addNewDeviceInfoNotificationHistorySuccess = payload => ({
  type: ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const updateDeviceInfoNotificationHistory = payload => ({
  type: UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY,
  payload,
});

export const updateDeviceInfoNotificationHistorySuccess = payload => ({
  type: UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const deleteDeviceInfoNotificationHistory = payload => ({
  type: DELETE_DEVICE_INFO_NOTIFICATION_HISTORY,
  payload,
});

export const deleteDeviceInfoNotificationHistorySuccess = payload => ({
  type: DELETE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const clearDeviceInfoNotificationHistoryNotify = () => ({
  type: DEVICE_INFO_NOTIFICATION_HISTORY_CLEAR_NOTIFY,
});
