import {
  GET_ORDER_VERIFY_TRACKING,
  GET_ORDER_VERIFY_TRACKING_SUCCESS,
  GET_ORDER_VERIFY_TRACKING_FAIL,
  GET_ORDER_VERIFY_TRACKING_BY_ID,
  GET_ORDER_VERIFY_TRACKING_BY_ID_SUCCESS,
  ADD_NEW_ORDER_VERIFY_TRACKING,
  ADD_NEW_ORDER_VERIFY_TRACKING_SUCCESS,
  UPDATE_ORDER_VERIFY_TRACKING,
  UPDATE_ORDER_VERIFY_TRACKING_SUCCESS,
  DELETE_ORDER_VERIFY_TRACKING,
  DELETE_ORDER_VERIFY_TRACKING_SUCCESS,
  ORDER_VERIFY_TRACKING_CLEAR_NOTIFY,
  BULK_ORDER_VERIFY_TRACKING,
  BULK_ORDER_VERIFY_TRACKING_SUCCESS,
  BULK_ORDER_VERIFY_TRACKING_ERROR,
} from "./actionTypes";

export const getOrderVerifyTracking = id => ({
  type: GET_ORDER_VERIFY_TRACKING,
  payload: id,
});

export const getOrderVerifyTrackingSuccess = items => ({
  type: GET_ORDER_VERIFY_TRACKING_SUCCESS,
  payload: items,
});

export const getOrderVerifyTrackingFail = error => ({
  type: GET_ORDER_VERIFY_TRACKING_FAIL,
  payload: error,
});

export const getOrderVerifyTrackingById = data => ({
  type: GET_ORDER_VERIFY_TRACKING_BY_ID,
  payload: data,
});

export const getOrderVerifyTrackingByIdSuccess = item => ({
  type: GET_ORDER_VERIFY_TRACKING_BY_ID_SUCCESS,
  payload: item,
});

export const getOrderVerifyTrackingByIdFail = error => ({
  type: GET_ORDER_VERIFY_TRACKING_BY_ID_FAIL,
  payload: error,
});

export const addNewOrderVerifyTracking = payload => ({
  type: ADD_NEW_ORDER_VERIFY_TRACKING,
  payload,
});

export const addNewOrderVerifyTrackingSuccess = payload => ({
  type: ADD_NEW_ORDER_VERIFY_TRACKING_SUCCESS,
  payload,
});

export const updateOrderVerifyTracking = payload => ({
  type: UPDATE_ORDER_VERIFY_TRACKING,
  payload,
});

export const updateOrderVerifyTrackingSuccess = payload => ({
  type: UPDATE_ORDER_VERIFY_TRACKING_SUCCESS,
  payload,
});

export const deleteOrderVerifyTracking = payload => ({
  type: DELETE_ORDER_VERIFY_TRACKING,
  payload,
});

export const deleteOrderVerifyTrackingSuccess = payload => ({
  type: DELETE_ORDER_VERIFY_TRACKING_SUCCESS,
  payload,
});

export const verifyBulkOrderVerifyTracking = payload => ({
  type: BULK_ORDER_VERIFY_TRACKING,
  payload,
});

export const verifyBulkOrderVerifyTrackingSuccess = payload => ({
  type: BULK_ORDER_VERIFY_TRACKING_SUCCESS,
  payload,
});

export const verifyBulkOrderVerifyTrackingError = payload => ({
  type: BULK_ORDER_VERIFY_TRACKING_ERROR,
  payload,
});

export const clearOrderVerifyTrackingNotify = () => ({
  type: ORDER_VERIFY_TRACKING_CLEAR_NOTIFY,
});
