import {
  GET_CLOTHING_SIZES,
  GET_CLOTHING_SIZES_SUCCESS,
  GET_CLOTHING_SIZES_ERROR,
  GET_CLOTHING_SIZE_BY_ID,
  GET_CLOTHING_SIZE_BY_ID_SUCCESS,
  ADD_NEW_CLOTHING_SIZE,
  ADD_NEW_CLOTHING_SIZE_SUCCESS,
  UPDATE_CLOTHING_SIZE,
  UPDATE_CLOTHING_SIZE_SUCCESS,
  DELETE_CLOTHING_SIZE,
  DELETE_CLOTHING_SIZE_SUCCESS,
  CLOTHING_SIZE_CLEAR_ERROR,
  ADD_NEW_CLOTHING_SIZE_ERROR,
} from "./actionTypes";
// ClothingSize
export const getClothingSizes = query => ({
  type: GET_CLOTHING_SIZES,
  payload: query,
});

export const getClothingSizesSuccess = payload => ({
  type: GET_CLOTHING_SIZES_SUCCESS,
  payload,
});

export const getClothingSizesError = error => ({
  type: GET_CLOTHING_SIZES_ERROR,
  payload: error,
});

export const getClothingSizeById = id => ({
  type: GET_CLOTHING_SIZE_BY_ID,
  payload: id,
});

export const getClothingSizeByIdSuccess = payload => ({
  type: GET_CLOTHING_SIZE_BY_ID_SUCCESS,
  payload,
});

export const addNewClothingSize = item => ({
  type: ADD_NEW_CLOTHING_SIZE,
  payload: item,
});

export const addNewClothingSizeSuccess = item => ({
  type: ADD_NEW_CLOTHING_SIZE_SUCCESS,
  payload: item,
});

export const addNewClothingSizeError = error => ({
  type: ADD_NEW_CLOTHING_SIZE_ERROR,
  payload: error,
});

export const updateClothingSize = item => ({
  type: UPDATE_CLOTHING_SIZE,
  payload: item,
});

export const updateClothingSizeSuccess = item => ({
  type: UPDATE_CLOTHING_SIZE_SUCCESS,
  payload: item,
});

export const deleteClothingSize = item => ({
  type: DELETE_CLOTHING_SIZE,
  payload: item,
});

export const deleteClothingSizeSuccess = item => ({
  type: DELETE_CLOTHING_SIZE_SUCCESS,
  payload: item,
});

export const clearClothingSizeError = () => ({
  type: CLOTHING_SIZE_CLEAR_ERROR,
});
