import { get, post, del, put as update } from "helpers/api_helper";

const createRequest = async data => {
  return await post("/api/Location", data);
};
const getRequest = async () => {
  return await get("/api/Location");
};
const getByIdRequest = async id => {
  return await get(`/api/Location/${id}`);
};

export const locationService = {
  createRequest,
  getRequest,
  getByIdRequest,
};
