import { SEVERITY } from "helpers/utils.js";
import {
  GET_SETTING_SUCCESS,
  GET_SETTING_FAIL,
  GET_SETTING_BY_ID_SUCCESS,
  GET_SETTING_DEFAULT,
  GET_SETTING_DEFAULT_SUCCESS,
  ADD_NEW_SETTING,
  UPDATE_SETTING,
  UPDATE_SETTING_SUCCESS,
  ADD_NEW_SETTING_SUCCESS,
  SETTING_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const setting = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SETTING_DEFAULT:
    case ADD_NEW_SETTING:
    case UPDATE_SETTING:
      return {
        ...state,
        item: null,
        loading: true,
      };
    case ADD_NEW_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Setting",
          text: "Setting has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Setting",
          text: "Setting has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_SETTING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };

    case GET_SETTING_DEFAULT_SUCCESS:
    case GET_SETTING_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case GET_SETTING_FAIL:
      return {
        ...state,
        error: payload,
      };
    case SETTING_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default setting;
