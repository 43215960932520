import { SEVERITY } from "helpers/utils.js";
import {
  GET_INVOICE_REPORT,
  GET_INVOICE_REPORT_SUCCESS,
  GET_INVOICE_REPORT_FAIL,
  INVOICE_REPORT_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  itemCount: 0,
  pageCount: 0,
  totalAmount: 0,
  totalOutstanding: 0,
  totalPayment: 0,
  loading: false,
  message: null,
};

const InvoiceReport = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOICE_REPORT:
      return {
        ...state,
        loading: true,
      };

    case GET_INVOICE_REPORT_SUCCESS:
      const {
        itemCount,
        pageCount,
        totalAmount,
        totalOutstanding,
        totalPayment,
        data,
      } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        totalAmount,
        totalOutstanding,
        totalPayment,
        items: data,
        loading: false,
      };

    case INVOICE_REPORT_CLEAR_NOTIFY: {
      return {
        ...state,
        message: null,
      };
    }

    case GET_INVOICE_REPORT_FAIL:
      return {
        ...state,
        error: payload,
        message: {
          title: "Invoice Payment",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    default:
      return state;
  }
};

export default InvoiceReport;
