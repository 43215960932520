import React from 'react'
import PropTypes from 'prop-types'
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PurchaseOrderForm from "../../containers/purchase-order/PurchaseOrderForm"
import {
  addNewPurchaseOrder
} from "store/actions"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

const AddNewPurchaseOrder = props => {
  const { addNewPurchaseOrder, history } = props

  const handleOnSubmit = (data) => {
    addNewPurchaseOrder(data)
    history.push(`/purchase-order`)
  }

  const handleOnCancel = () => {
    history.push(`/purchase-order`);
  }


  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Purchase Order | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ `/purchase-order` }
                breadcrumbItem={ `Add New Purchase Order` }
            />
            <Row>
              <Col md={ 12 }>
                <PurchaseOrderForm onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

AddNewPurchaseOrder.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  addNewPurchaseOrder: PropTypes.func
}

const mapStateToProps = ({ purchaseOrder }) => {
  return purchaseOrder;
};

export default withRouter(
    connect(mapStateToProps, {
      addNewPurchaseOrder,
    })(AddNewPurchaseOrder)
);