import {
  GET_ITEM_SHIPPING_FEES,
  GET_ITEM_SHIPPING_FEES_SUCCESS,
  GET_ITEM_SHIPPING_FEES_FAIL,
  GET_ITEM_SHIPPING_FEE_BY_ID,
  GET_ITEM_SHIPPING_FEE_BY_ID_SUCCESS,
  ADD_NEW_ITEM_SHIPPING_FEE,
  UPDATE_ITEM_SHIPPING_FEE,
  DELETE_ITEM_SHIPPING_FEE,
  ITEM_SHIPPING_FEE_CLEAR_ERROR,
} from "./actionTypes";

// ItemShippingFee
export const getItemShippingFees = query => ({
  type: GET_ITEM_SHIPPING_FEES,
  payload: query,
});

export const getItemShippingFeesSuccess = items => ({
  type: GET_ITEM_SHIPPING_FEES_SUCCESS,
  payload: items,
});

export const getItemShippingFeesFail = error => ({
  type: GET_ITEM_SHIPPING_FEES_FAIL,
  payload: error,
});

export const getItemShippingFeeById = id => ({
  type: GET_ITEM_SHIPPING_FEE_BY_ID,
  payload: id,
});

export const getItemShippingFeeByIdSuccess = item => ({
  type: GET_ITEM_SHIPPING_FEE_BY_ID_SUCCESS,
  payload: item,
});

export const getItemShippingFeeByIdFail = error => ({
  type: GET_ITEM_SHIPPING_FEE_BY_ID_FAIL,
  payload: error,
});

export const addNewItemShippingFee = item => ({
  type: ADD_NEW_ITEM_SHIPPING_FEE,
  payload: item,
});

export const updateItemShippingFee = item => ({
  type: UPDATE_ITEM_SHIPPING_FEE,
  payload: item,
});

export const deleteItemShippingFee = item => ({
  type: DELETE_ITEM_SHIPPING_FEE,
  payload: item,
});

export const clearItemShippingFeeError = () => ({
  type: ITEM_SHIPPING_FEE_CLEAR_ERROR,
});
