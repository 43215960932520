import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from "reactstrap"
import { useReactToPrint } from "react-to-print"
import PurchaseOrderToPrint from "./PurchaseOrderToPrint"
import { getPurchaseOrderByIdRequest } from "../../store/purchase-order/saga"

const PurchaseOrderPrintPreviewModal = props => {
  const {
    title,
    isOpen,
    toggle,
    purchaseOrderId
  } = props

  const [ purchaseOrder, setPurchaseOrder ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      console.log("onBeforeGetContent print")
    },
    onAfterPrint: () => {
      console.log("After print")
      componentRef.current = null
      // setIsPrint(false);
    }
  })

  const onOpened = () => {
    setLoading(true)
    getPurchaseOrderByIdRequest(purchaseOrderId).then((data) => {
      console.log(data)
      setLoading(false);
      setPurchaseOrder(data);
    })
    // getAllItemsRequest({
    //   term,
    //   type: type?.value ?? null,
    //   locationIds: locations.map(a => {
    //     return a.value
    //   }),
    //   categoryIds: categories?.map(a => {
    //     return a.value
    //   }),
    //   sortBy: sortField,
    //   sortAsc: toggleSort,
    // }).then(res => {
    //   setItems(res)
    //   setLoading(false)
    // })
  }

  return (
      <Modal
          size="xl"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Print Preview
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {
            loading ?
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div> :
                <div style={ { height: "350px", overflow: "auto", background: "gray", padding: "10px" } }>
                  {
                    purchaseOrder &&
                    <PurchaseOrderToPrint ref={ componentRef } purchaseOrder={purchaseOrder}/>
                  }
                </div>
          }
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handlePrint }>
            <i className="mdi mdi-printer"/> Print Now
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

PurchaseOrderPrintPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.func,
  purchaseOrderId: PropTypes.string.isRequired
}

export default PurchaseOrderPrintPreviewModal