// STOCK_REQUEST
export const GET_STOCK_REQUESTS = "GET_STOCK_REQUESTS";
export const GET_STOCK_REQUESTS_SUCCESS = "GET_STOCK_REQUESTS_SUCCESS";
export const GET_STOCK_REQUESTS_ERROR = "GET_STOCK_REQUESTS_ERROR";

export const GET_STOCK_REQUEST_BY_ID = "GET_STOCK_REQUEST_BY_ID";
export const GET_STOCK_REQUEST_BY_ID_SUCCESS =
  "GET_STOCK_REQUEST_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_REQUEST = "ADD_NEW_STOCK_REQUEST";
export const ADD_NEW_STOCK_REQUEST_SUCCESS = "ADD_NEW_STOCK_REQUEST_SUCCESS";
export const ADD_NEW_STOCK_REQUEST_ERROR = "ADD_NEW_STOCK_REQUEST_ERROR";

export const UPDATE_STOCK_REQUEST = "UPDATE_STOCK_REQUEST";
export const UPDATE_STOCK_REQUEST_SUCCESS = "UPDATE_STOCK_REQUEST_SUCCESS";
export const UPDATE_STOCK_REQUEST_ERROR = "UPDATE_STOCK_REQUEST_ERROR";

export const DELETE_STOCK_REQUEST = "DELETE_STOCK_REQUEST";
export const DELETE_STOCK_REQUEST_SUCCESS = "DELETE_STOCK_REQUEST_SUCCESS";

export const SUBMIT_WITH_STATUS_STOCK_REQUEST =
  "SUBMIT_WITH_STATUS_STOCK_REQUEST";
export const SUBMIT_WITH_STATUS_STOCK_REQUEST_SUCCESS =
  "SUBMIT_WITH_STATUS_STOCK_REQUEST_SUCCESS";

export const GET_CONFIRM_STOCK_REQUEST = "GET_CONFIRM_STOCK_REQUEST";
export const GET_CONFIRM_STOCK_REQUEST_SUCCESS =
  "GET_CONFIRM_STOCK_REQUEST_SUCCESS";

export const STOCK_REQUEST_CLEAR_ERROR = "STOCK_REQUEST_CLEAR_ERROR";
