import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

export class InvoiceSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { item, onStatusFilter } = this.props;
    const { totalAmount, totalOutstanding, totalPayment } = item;

    return (
      <Card className="mb-2">
        <CardHeader className="bg-transparent border">
          <CardTitle>Invoice Summary</CardTitle>
          <Row>
            <Col md={4}>
              <Card
                color="primary"
                className="border border-primary mini-stats-wid mb-2"
              >
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-white fw-medium">Total Amount</p>
                      <h4 className="mb-0 text-white">
                        <NumberFormat
                          value={totalAmount}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          displayType="text"
                          prefix="₩ "
                        />
                      </h4>
                    </div>
                    <div
                      className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                      style={{
                        borderColor: "white",
                        borderStyle: "solid",
                      }}
                    >
                      <span className="avatar-title">
                        <i className={"fas fa-won-sign font-size-24"} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                color="success"
                className="border border-success mini-stats-wid mb-2"
              >
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-white fw-medium">Total Payment</p>
                      <h4 className="mb-0 text-white">
                        <NumberFormat
                          value={totalPayment}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          displayType="text"
                          prefix="₩ "
                        />
                      </h4>
                    </div>
                    <div
                      className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                      style={{
                        borderColor: "white",
                        borderStyle: "solid",
                      }}
                    >
                      <span className="avatar-title bg-success">
                        <i className={"fas fa-check font-size-24"} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                color="warning"
                className="border border-warning mini-stats-wid mb-2"
              >
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-white fw-medium">Balance Due</p>
                      <h4 className="mb-0 text-white">
                        <NumberFormat
                          value={totalOutstanding}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          displayType="text"
                          prefix="₩ "
                        />
                      </h4>
                    </div>
                    <div
                      className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                      style={{
                        borderColor: "white",
                        borderStyle: "solid",
                      }}
                    >
                      <span className="avatar-title bg-warning">
                        <i className={"bx bx-list-ol font-size-24"} />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardHeader>
      </Card>

      // <Card className="mb-2">
      //   <CardHeader className="bg-transparent border">
      //     <CardTitle>Invoice Summary</CardTitle>

      //     <Row>
      //       <Col md={12}>
      //         <div className="d-flex flex-row">
      //           <Card
      //             color="primary"
      //             className="mini-stats-wid mt-1 mb-2 border me-1 w-100 clickable"
      //             onClick={() =>
      //               onStatusFilter({
      //                 invoiceIds: all,
      //                 paymentStatus: [1, 2, 3],
      //               })
      //             }
      //           >
      //             <CardBody className="p-2">
      //               <div className="d-flex">
      //                 <div className="flex-grow-1">
      //                   <p className="text-white font-size-12 fw-bold">All</p>
      //                   <h4 className="mb-0 text-white text-center">
      //                     <NumberFormat
      //                       value={all.length}
      //                       displayType="text"
      //                       thousandSeparator={true}
      //                       fixedDecimalScale={false}
      //                     />
      //                   </h4>
      //                 </div>
      //                 <div
      //                   className="mini-stat-icon avatar-sm rounded-circle align-self-center"
      //                   style={{
      //                     borderColor: "white",
      //                     borderStyle: "solid",
      //                   }}
      //                 >
      //                   <span className="avatar-title bg-transparent">
      //                     <i className={"fas fa-cubes font-size-24"} />
      //                   </span>
      //                 </div>
      //               </div>
      //             </CardBody>
      //           </Card>

      //           <Card
      //             color="info"
      //             onClick={() =>
      //               onStatusFilter({
      //                 invoiceIds: invoicedInvoiceIds,
      //                 paymentStatus: [1],
      //               })
      //             }
      //             className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
      //           >
      //             <CardBody className="p-2">
      //               <div className="d-flex">
      //                 <div className="flex-grow-1">
      //                   <p className="text-white font-size-12 fw-bold">
      //                     Invoiced
      //                   </p>
      //                   <h4 className="mb-0 text-white text-center">
      //                     <NumberFormat
      //                       value={invoicedInvoiceIds.length}
      //                       displayType="text"
      //                       thousandSeparator={true}
      //                       fixedDecimalScale={false}
      //                     />
      //                   </h4>
      //                 </div>
      //                 <div
      //                   className="mini-stat-icon avatar-sm rounded-circle align-self-center"
      //                   style={{
      //                     borderColor: "white",
      //                     borderStyle: "solid",
      //                   }}
      //                 >
      //                   <span className="avatar-title bg-transparent">
      //                     <i className={"fas fa-list font-size-24"} />
      //                   </span>
      //                 </div>
      //               </div>
      //             </CardBody>
      //           </Card>
      //           <Card
      //             color="success"
      //             onClick={() =>
      //               onStatusFilter({
      //                 invoiceIds: paidInvoiceIds,
      //                 paymentStatus: [2],
      //               })
      //             }
      //             className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
      //           >
      //             <CardBody className="p-2">
      //               <div className="d-flex">
      //                 <div className="flex-grow-1">
      //                   <p className="text-white font-size-12 fw-bold">Paid</p>
      //                   <h4 className="mb-0 text-white text-center">
      //                     <NumberFormat
      //                       value={paidInvoiceIds.length}
      //                       displayType="text"
      //                       thousandSeparator={true}
      //                       fixedDecimalScale={false}
      //                     />
      //                   </h4>
      //                 </div>
      //                 <div
      //                   className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
      //                   style={{
      //                     borderColor: "white",
      //                     borderStyle: "solid",
      //                   }}
      //                 >
      //                   <span className="avatar-title bg-transparent">
      //                     <i className={"fas fa-check font-size-24"} />
      //                   </span>
      //                 </div>
      //               </div>
      //             </CardBody>
      //           </Card>
      //           <Card
      //             color="danger"
      //             onClick={() =>
      //               onStatusFilter({
      //                 invoiceIds: unpaidInvoiceIds,
      //                 paymentStatus: [3],
      //               })
      //             }
      //             className="mini-stats-wid mt-1 mb-2 border ms-1 w-100 clickable"
      //           >
      //             <CardBody className="p-2">
      //               <div className="d-flex">
      //                 <div className="flex-grow-1">
      //                   <p className="text-white font-size-12 fw-bold">
      //                     Unpaid
      //                   </p>
      //                   <h4 className="mb-0 text-white text-center">
      //                     <NumberFormat
      //                       value={unpaidInvoiceIds.length}
      //                       displayType="text"
      //                       thousandSeparator={true}
      //                       fixedDecimalScale={false}
      //                     />
      //                   </h4>
      //                 </div>
      //                 <div
      //                   className="mini-stat-icon avatar-sm rounded-circle align-self-center"
      //                   style={{
      //                     borderColor: "white",
      //                     borderStyle: "solid",
      //                   }}
      //                 >
      //                   <span className="avatar-title bg-transparent">
      //                     <i className={"mdi mdi-close-thick font-size-24"} />
      //                   </span>
      //                 </div>
      //               </div>
      //             </CardBody>
      //           </Card>
      //         </div>
      //       </Col>
      //     </Row>
      //   </CardHeader>
      // </Card>
    );
  }
}

InvoiceSummary.propTypes = {
  item: PropTypes.object,
  onStatusFilter: PropTypes.func,
};

export default InvoiceSummary;
