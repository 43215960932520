import React, { Component } from "react";
import MetaTags from "react-meta-tags";
// Core viewer
// import { Viewer } from "@react-pdf-viewer/core";

// // Plugins
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// // Import styles
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Create new plugin instance

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartSeries: null,
      orderSummary: null,
      invoiceSummary: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | JLS Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
