import {
  GET_SHIPPING_CONTACT,
  GET_SHIPPING_CONTACT_SUCCESS,
  GET_SHIPPING_CONTACT_FAIL,
  GET_SHIPPING_CONTACT_BY_ID,
  GET_SHIPPING_CONTACT_BY_ID_SUCCESS,
  ADD_NEW_SHIPPING_CONTACT,
  UPDATE_SHIPPING_CONTACT,
  DELETE_SHIPPING_CONTACT,
  SHIPPING_CONTACT_CLEAR_ERROR,
} from "./actionTypes";

// ShippingContact
export const getShippingContact = id => ({
  type: GET_SHIPPING_CONTACT,
  payload: id,
});

export const getShippingContactSuccess = shippings => ({
  type: GET_SHIPPING_CONTACT_SUCCESS,
  payload: shippings,
});

export const getShippingContactFail = error => ({
  type: GET_SHIPPING_CONTACT_FAIL,
  payload: error,
});

export const getShippingContactById = data => ({
  type: GET_SHIPPING_CONTACT_BY_ID,
  payload: data,
});

export const getShippingContactByIdSuccess = shipping => ({
  type: GET_SHIPPING_CONTACT_BY_ID_SUCCESS,
  payload: shipping,
});

export const getShippingContactByIdFail = error => ({
  type: GET_SHIPPING_CONTACT_BY_ID_FAIL,
  payload: error,
});

export const addNewShippingContact = shipping => ({
  type: ADD_NEW_SHIPPING_CONTACT,
  payload: shipping,
});

export const updateShippingContact = shipping => ({
  type: UPDATE_SHIPPING_CONTACT,
  payload: shipping,
});

export const deleteShippingContact = shipping => ({
  type: DELETE_SHIPPING_CONTACT,
  payload: shipping,
});

export const clearShippingContactError = () => ({
  type: SHIPPING_CONTACT_CLEAR_ERROR,
});
