import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import roleSaga from "./role/saga";
import unitSaga from "./unit/saga";
import warehouseSaga from "./warehouse/saga";
import orderSaga from "./order/saga";
import categorySaga from "./category/saga";
import attributeSaga from "./attribute/saga";
import userSaga from "./user/saga";
import orderItemSaga from "./order-item/saga";
import currencySaga from "./currency/saga";
import bannerSaga from "./banner/saga";
import itemShippingFeeSaga from "./item-shipping-fee/saga";
import itemShippingFeeEntrySaga from "./item-shipping-fee-entry/saga";
import userItemShippingFeeSaga from "./user-item-shipping-fee/saga";
import shippingAddressSaga from "./shipping-contact/saga";
import permissionSaga from "./permission/saga";
import orderAttachmentSaga from "./order-attachment/saga";
import orderNoteSaga from "./order-note/saga";
import transportSaga from "./transport/saga";
import transportOrderSaga from "./transport-order/saga";
import transportNoteSaga from "./transport-note/saga";
import orderTrackingSaga from "./order-tracking/saga";
import invoiceSaga from "./invoice/saga";
import invoicePaymentSaga from "./invoice-payment/saga";
import invoiceNoteSaga from "./invoice-note/saga";
import invoicePaymentHistorySaga from "./invoice-payment-history/saga";
import invoiceReportSaga from "./invoice-report/saga";
import settingSaga from "./settings/saga";
import deliveryAgencySaga from "./delivery-agency/saga";
import delivererSaga from "./deliverer/saga";
import deliveryOrderSaga from "./delivery-order/saga";
import deliveryOrderItemSaga from "./delivery-order-item/saga";
import orderVerifyTrackingSaga from "./order-verify-tracking/saga";
import orderNotificationHistorySaga from "./order-notification-history/saga";
import orderMessageHistorySaga from "./order-message-history/saga";
import deviceInfoSaga from "./device-info/saga";
import deviceInfoNotificationSaga from "./device-info-notification-history/saga";
import feedbackSaga from "./feedback/saga";
import supplierSaga from "./supplier/saga";
import companySaga from "./company/saga";
import storeSaga from "./store/saga";
import productSaga from "./product/saga";
import productImageSaga from "./product-image/saga";
import itemSaga from "./item/saga";
import itemAttachmentSaga from "./item-attachment/saga";
import unitOfMeasurementSaga from "./unit-of-measurement/saga";
import itemUnitOfMeasurementSaga from "./item-unit-of-measurement/saga";
import stockInSaga from "./stock-in/saga";
import stockInItemSaga from "./stock-in-item/saga";
import stockAdjustmentSaga from "./stock-adjustment/saga";
import stockAdjustmentItemSaga from "./stock-adjustment-item/saga";
import purchaseOrderSaga from "./purchase-order/saga";
import purchaseOrderItemSaga from "./purchase-order-item/saga";
import stockOutSaga from "./stock-out/saga";
import stockOutItemSaga from "./stock-out-item/saga";
import productStockInSaga from "./product-stock-in/saga";
import productStockInProductSaga from "./product-stock-in-product/saga";
import productUnitOfMeasurementSaga from "./product-unit-of-measurement/saga";
import productStockOutSaga from "./product-stock-out/saga";
import productStockOutProductSaga from "./product-stock-out-product/saga";
import clothingSizeSaga from "./clothing-size/saga";
import cuttingSaga from "./cutting/saga";
import stockRequestSaga from "./stock-request/saga";
import stockRequestItemSaga from "./stock-request-item/saga";
import sewingSaga from "./sewing/saga";
import packagingSaga from "./packaging/saga";
import finishedGoodsSaga from "./finished-goods/saga";
import purchaseOrderAttachmentSaga from "./purchase-order-attachment/saga";
import itemUsageLimitationSaga from "./item-usage-limitation/saga";
import stockLocationSaga from "./stock-location/saga";
import transferLocationSaga from "./transfer-location/saga";
import codeColorSaga from "./code-color/saga";
import locationSaga from "./location/saga";

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(roleSaga),
    fork(unitSaga),
    fork(warehouseSaga),
    fork(orderSaga),
    fork(categorySaga),
    fork(attributeSaga),
    fork(userSaga),
    fork(orderItemSaga),
    fork(currencySaga),
    fork(bannerSaga),
    fork(itemShippingFeeSaga),
    fork(itemShippingFeeEntrySaga),
    fork(userItemShippingFeeSaga),
    fork(shippingAddressSaga),
    fork(permissionSaga),
    fork(orderAttachmentSaga),
    fork(orderNoteSaga),
    fork(transportSaga),
    fork(transportOrderSaga),
    fork(transportNoteSaga),
    fork(orderTrackingSaga),
    fork(invoiceSaga),
    fork(invoiceNoteSaga),
    fork(invoicePaymentSaga),
    fork(invoicePaymentHistorySaga),
    fork(invoiceReportSaga),
    fork(settingSaga),
    fork(deliveryAgencySaga),
    fork(delivererSaga),
    fork(deliveryOrderSaga),
    fork(deliveryOrderItemSaga),
    fork(orderVerifyTrackingSaga),
    fork(orderNotificationHistorySaga),
    fork(orderMessageHistorySaga),
    fork(deviceInfoSaga),
    fork(deviceInfoNotificationSaga),
    fork(feedbackSaga),
    fork(supplierSaga),
    fork(companySaga),
    fork(storeSaga),
    fork(productSaga),
    fork(productImageSaga),
    fork(itemSaga),
    fork(itemAttachmentSaga),
    fork(unitOfMeasurementSaga),
    fork(itemUnitOfMeasurementSaga),
    fork(stockInSaga),
    fork(stockInItemSaga),
    fork(stockAdjustmentSaga),
    fork(stockAdjustmentItemSaga),
    fork(purchaseOrderSaga),
    fork(purchaseOrderItemSaga),
    fork(stockOutSaga),
    fork(stockOutItemSaga),
    fork(productStockInSaga),
    fork(productStockInProductSaga),
    fork(productUnitOfMeasurementSaga),
    fork(productStockOutSaga),
    fork(productStockOutProductSaga),
    fork(clothingSizeSaga),
    fork(cuttingSaga),
    fork(stockRequestSaga),
    fork(stockRequestItemSaga),
    fork(sewingSaga),
    fork(packagingSaga),
    fork(finishedGoodsSaga),
    fork(purchaseOrderAttachmentSaga),
    fork(itemUsageLimitationSaga),
    fork(stockLocationSaga),
    fork(transferLocationSaga),
    fork(codeColorSaga),
    fork(locationSaga),
  ]);
}
