// ITEM_ITEM_UNIT_OF_MEASUREMENT
export const GET_ITEM_UNIT_OF_MEASUREMENTS = "GET_ITEM_UNIT_OF_MEASUREMENTS";
export const GET_ITEM_UNIT_OF_MEASUREMENTS_SUCCESS =
  "GET_ITEM_UNIT_OF_MEASUREMENTS_SUCCESS";
export const GET_ITEM_UNIT_OF_MEASUREMENTS_ERROR =
  "GET_ITEM_UNIT_OF_MEASUREMENTS_ERROR";

export const GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID =
  "GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID";
export const GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS =
  "GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS";

export const ADD_NEW_ITEM_UNIT_OF_MEASUREMENT =
  "ADD_NEW_ITEM_UNIT_OF_MEASUREMENT";
export const ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_SUCCESS =
  "ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_SUCCESS";
export const ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_ERROR =
  "ADD_NEW_ITEM_UNIT_OF_MEASUREMENT_ERROR";

export const UPDATE_ITEM_UNIT_OF_MEASUREMENT =
  "UPDATE_ITEM_UNIT_OF_MEASUREMENT";
export const UPDATE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS =
  "UPDATE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS";
export const UPDATE_ITEM_UNIT_OF_MEASUREMENT_ERROR =
  "UPDATE_ITEM_UNIT_OF_MEASUREMENT_ERROR";

export const DELETE_ITEM_UNIT_OF_MEASUREMENT =
  "DELETE_ITEM_UNIT_OF_MEASUREMENT";
export const DELETE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS =
  "DELETE_ITEM_UNIT_OF_MEASUREMENT_SUCCESS";

export const ITEM_UNIT_OF_MEASUREMENT_CLEAR_ERROR =
  "ITEM_UNIT_OF_MEASUREMENT_CLEAR_ERROR";
