import { SEVERITY } from "helpers/utils.js";
import {
  GET_SEWINGS,
  GET_SEWINGS_SUCCESS,
  GET_SEWING_BY_ID_SUCCESS,
  GET_SEWINGS_ERROR,
  SEWING_CLEAR_ERROR,
  GET_SEWING_BY_ID,
  ADD_NEW_SEWING,
  UPDATE_SEWING,
  DELETE_SEWING,
  GET_SEWING_ENTRY_FORM_SUCCESS,
  SAVE_SEWING_ENTRY_FORM_SUCCESS,
  GET_PAGING_SEWING_OUTSTANDING_REPORT_SUCCESS, GET_PAGING_SEWING_OUTSTANDING_REPORT,
} from "./actionTypes.js"
import { GET_CUTTING_ENTRY_FORM_SUCCESS, SAVE_CUTTING_ENTRY_FORM_SUCCESS } from "../cutting/actionTypes"
const initState = {
  items: [],
  item: null,
  summary: null,
  clothingSizes: [],
  purchaseOrderItems: [],
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const sewing = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SEWING_ENTRY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case SAVE_SEWING_ENTRY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Saving Form",
          text: "Saving Entries have been saved successfully",
          severity: SEVERITY.SUCCESS,
        },
      }
      
    case DELETE_SEWING:
    case UPDATE_SEWING:
    case GET_SEWINGS:
    case GET_PAGING_SEWING_OUTSTANDING_REPORT:
      return {
        ...state,
        loading: true,
        // item: null,
      };
      
    case GET_PAGING_SEWING_OUTSTANDING_REPORT_SUCCESS:
      return  {
        ...state,
        itemCount: payload.itemCount,
        pageCount: payload.pageCount,
        items: payload.data,
        loading: false,
      }
    case GET_SEWINGS_SUCCESS:
      const { itemCount, pageCount, data, clothingSizes, purchaseOrderItems, summary } =
        payload;
      return {
        ...state,
        itemCount,
        pageCount,
        clothingSizes,
        purchaseOrderItems,
        summary,
        items: data,
        // item: null,
        loading: false,
      };
    case ADD_NEW_SEWING:
      return {
        ...state,
        item: null,
      };
    case GET_SEWING_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_SEWING_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_SEWINGS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case SEWING_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default sewing;
