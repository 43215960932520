import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_PURCHASE_ORDER_ITEM,
  GET_PURCHASE_ORDER_ITEM_BY_ID,
  UPDATE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM,
  GET_PURCHASE_ORDER_ITEMS,
  ADD_NEW_BULK_PURCHASE_ORDER_ITEM,
  SAVE_PURCHASE_ORDER_ITEMS,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPurchaseOrderItemByIdSuccess, getPurchaseOrderItemsByPurchaseOrderIdSuccess,
  getPurchaseOrderItemsError,
  getPurchaseOrderItemsSuccess, savePurchaseOrderItemsSuccess,
} from "./actions"

const getPurchaseOrderItemsRequest = query =>
  get("/api/PurchaseOrderItem/paging", { params: query });
const addNewPurchaseOrderItemRequest = item =>
  post("/api/PurchaseOrderItem", item);
const getPurchaseOrderItemByIdRequest = id =>
  get(`/api/PurchaseOrderItem/${id}`);
const updatePurchaseOrderItemRequest = item =>
  update("/api/PurchaseOrderItem", item);
const deletePurchaseOrderItemRequest = id =>
  del(`/api/PurchaseOrderItem/${id}`);

const addNewBulkPurchaseOrderItemRequest = item =>
    post("/api/PurchaseOrderItem/bulk", item);

const savePurchaseOrderItemsRequest = item =>
    post("/api/PurchaseOrderItem/bulk", item);

const getPurchaseOrderItemsByPurchaseOrderIdRequest = id =>
    get(`/api/PurchaseOrderItem/design/${id}`);

function* getPurchaseOrderItems({ payload }) {
  try {
    const response = yield call(getPurchaseOrderItemsRequest, payload);
    yield put(getPurchaseOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* addNewPurchaseOrderItem({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(addNewPurchaseOrderItemRequest, payload);
    const response = yield call(getPurchaseOrderItemsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* addNewBulkPurchaseOrderItem({ payload }) {
  try {
    const { orderItems, purchaseOrderId } = payload;
    yield call(addNewBulkPurchaseOrderItemRequest, orderItems);
    const response = yield call(getPurchaseOrderItemsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* savePurchaseOrderItems({ payload }) {
  try {
    const { purchaseOrderId } = payload; 
    yield call(savePurchaseOrderItemsRequest, payload);
    let response = yield call(getPurchaseOrderItemsByPurchaseOrderIdRequest, purchaseOrderId);
    yield put(savePurchaseOrderItemsSuccess())
    yield put(getPurchaseOrderItemsByPurchaseOrderIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* updatePurchaseOrderItem({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(updatePurchaseOrderItemRequest, payload);
    const response = yield call(getPurchaseOrderItemsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* getPurchaseOrderItemById({ payload }) {
  try {
    let response = yield call(getPurchaseOrderItemByIdRequest, payload);
    yield put(getPurchaseOrderItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* getPurchaseOrderItemsByPurchaseOrderId({ payload }) {
  try {
    let response = yield call(getPurchaseOrderItemsByPurchaseOrderIdRequest, payload);
    yield put(getPurchaseOrderItemsByPurchaseOrderIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(error));
  }
}

function* deletePurchaseOrderItem({ payload }) {
  try {
    const { purchaseOrderId, id } = payload;
    yield call(deletePurchaseOrderItemRequest, id);
    const response = yield call(getPurchaseOrderItemsRequest, {
      purchaseOrderId,
    });
    yield put(getPurchaseOrderItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPurchaseOrderItemsError(err));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PURCHASE_ORDER_ITEMS, getPurchaseOrderItems);
  yield takeEvery(ADD_NEW_PURCHASE_ORDER_ITEM, addNewPurchaseOrderItem);
  yield takeEvery(ADD_NEW_BULK_PURCHASE_ORDER_ITEM, addNewBulkPurchaseOrderItem);
  yield takeEvery(GET_PURCHASE_ORDER_ITEM_BY_ID, getPurchaseOrderItemById);
  yield takeEvery(GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID, getPurchaseOrderItemsByPurchaseOrderId);
  yield takeEvery(SAVE_PURCHASE_ORDER_ITEMS, savePurchaseOrderItems);
  yield takeEvery(UPDATE_PURCHASE_ORDER_ITEM, updatePurchaseOrderItem);
  yield takeEvery(DELETE_PURCHASE_ORDER_ITEM, deletePurchaseOrderItem);
}

export default itemSaga;
