import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Table } from "reactstrap"
import SewingFormTableRow from "./SewingFormTableRow"
import { isNumber, size } from "lodash"
import { NIL } from "uuid"
import { validateSewingEntryFormRequest } from "../../store/sewing/saga"

const SewingModalForm = props => {
  const {
    isOpen,
    toggle,
    title,
    clothingSizes,
    purchaseOrderItems,
    purchaseOrderId,
    onSubmit
  } = props

  const [ items, setItems ] = useState([])
  const [ submitted, setSubmitted ] = useState(false)

  useEffect(() => {
    if (purchaseOrderItems && purchaseOrderItems.length > 0) {
      setItems(purchaseOrderItems.map((a, index) => {
        return {
          id: NIL,
          itemCode: a.itemCode,
          itemName: a.itemName,
          index,
          itemId: a.itemId,
          entries: clothingSizes.map(size => ({
            id: size.id,
            clothingSizeId: size.id,
            quantity: '',
            isValid: false,
          }))
        }
      }))
    }
  }, [ purchaseOrderItems ])

  const handleOnChange = () => {
    setItems(items.map((item, index) => {
      if (item.index === index) {
        return {
          ...item,
        }
      }
      return item
    }))
  }


  const handleSubmit = () => {
    setSubmitted(true)
    let data = items.map(item => {
      return {
        ...item,
        entries: item.entries.map(entry => ({
          ...entry,
          quantity: isNumber(entry.quantity) ? entry.quantity : 0,
        }))
      }
    })

    validateSewingEntryFormRequest({
      purchaseOrderId,
      items: data,
    }).then(res => {
      if (res) {
        const { isValid } = res

        if (isValid) {
          onSubmit(data)
        } else {
          let { items } = res.data
          let results = items.map(a => {
            return {
              ...a,
              entries: a.entries.map((e, index1) => {
                return {
                  ...e,
                  index: index1,
                }
              })
            }
          })

          setItems(results)
        }
      }
    })
  }

  const onOpened = () => {
    setSubmitted(false)
  }

  return (
      <>
        <Modal
            size="xl"
            onOpened={ onOpened }
            isOpen={ isOpen }
            toggle={ toggle }
            backdrop="static"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { title }
            </h5>
            <button
                type="button"
                onClick={ toggle }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Table
                // id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  #
                </th>
                <th className="text-center" style={ { width: "350px" } }>Item</th>
                {
                  clothingSizes.map(size => {
                    return <th key={ size.id } className={ "text-center" }>{ size.name }</th>
                  })
                }
              </tr>
              </thead>
              <tbody>
              {
                items.map((item, index) => {
                  return <SewingFormTableRow
                      key={ index }
                      item={ item }
                      num={ index + 1 }
                      submitted={ submitted }
                      clothingSizes={ clothingSizes }
                      onChange={ handleOnChange }
                  />
                })
              }
              </tbody>
            </Table>
          </div>
          <div className="modal-footer">
            <Button color="primary" onClick={ handleSubmit } type="submit">
              Submit
            </Button>
            <button
                type="button"
                onClick={ toggle }
                className="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </>
  )
}

SewingModalForm.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  clothingSizes: PropTypes.array,
  purchaseOrderItems: PropTypes.array,
  purchaseOrderId: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default SewingModalForm