import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ItemUsageLimitationForm from "../../containers/item-usage-limitation/ItemUsageLimitationForm"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getItemUsageLimitations, saveBulkItemUsageLimitation, clearItemUsageLimitationError } from "../../store/item-usage-limitation/actions"
import { toastMessage } from "../../helpers/utils"

const ItemUsageLimitationTab = props => {
  const { 
    clearItemUsageLimitationError, 
    saveBulkItemUsageLimitation, 
    getItemUsageLimitations, 
    purchaseOrderId, 
    itemUsageLimitation
  } = props;
  
  const { loading, item, items, message } = itemUsageLimitation;
  
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [term, setTerm] = useState("");
  
  useEffect(() => {
    getItemUsageLimitations({
      purchaseOrderId,
      page,
      pageSize,
      term
    })
  }, [purchaseOrderId, page, pageSize, term])

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearItemUsageLimitationError();
    }
  }, [message]);
  
  
  
  const handleOnSubmit = (data) => {
    saveBulkItemUsageLimitation({
      data,
      purchaseOrderId
    })
  }
  
  const handleOnCancel=()=>{
    
  }
  
  return (
      <>
        {
          loading ? <div className={"loading"}></div>: 
              <ItemUsageLimitationForm
              purchaseOrderId={purchaseOrderId}
              items={items}
              onSubmit={ handleOnSubmit }
              onCancel={ handleOnCancel }/>
        }
      </>
  )
}

ItemUsageLimitationTab.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  itemUsageLimitation: PropTypes.object,
  getItemUsageLimitations: PropTypes.func,
  saveBulkItemUsageLimitation: PropTypes.func,
  clearItemUsageLimitationError: PropTypes.func,
  
}

const mapStateToProps = ({ itemUsageLimitation }) => {
  return {
    itemUsageLimitation
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getItemUsageLimitations,
      saveBulkItemUsageLimitation,
      clearItemUsageLimitationError
    })(ItemUsageLimitationTab)
)
