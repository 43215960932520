import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_WAREHOUSES,
  ADD_NEW_WAREHOUSE,
  GET_WAREHOUSE_BY_ID,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getWarehousesSuccess,
  getWarehouseByIdSuccess,
  getWarehousesFail,
} from "./actions";

const getWarehousesRequest = query =>
  get("/api/warehouse/paging", { params: query });
const addNewWarehouseRequest = warehouse => post("/api/warehouse", warehouse);
const getWarehouseByIdRequest = id => get(`/api/warehouse/${id}`);
const updateWarehouseRequest = warehouse => update("/api/warehouse", warehouse);
const deleteWarehouseRequest = id => del(`/api/warehouse/${id}`);

function* fetchWarehouses({ payload }) {
  try {
    const response = yield call(getWarehousesRequest, payload);
    yield put(getWarehousesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWarehousesFail(error));
  }
}

function* addNewWarehouse({ payload }) {
  try {
    yield call(addNewWarehouseRequest, payload);
    const response = yield call(getWarehousesRequest, {});
    yield put(getWarehousesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWarehousesFail(error));
  }
}

function* updateWarehouse({ payload }) {
  try {
    yield call(updateWarehouseRequest, payload);
    const response = yield call(getWarehousesRequest, {});
    yield put(getWarehousesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWarehousesFail(error));
  }
}

function* getWarehouseById({ payload }) {
  try {
    var response = yield call(getWarehouseByIdRequest, payload);
    yield put(getWarehouseByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWarehousesFail(error));
  }
}

function* deleteWarehouse({ payload }) {
  try {
    yield call(deleteWarehouseRequest, payload);
    const response = yield call(getWarehousesRequest, {});
    yield put(getWarehousesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWarehousesFail(error));
  }
}

function* warehouseSaga() {
  yield takeEvery(GET_WAREHOUSES, fetchWarehouses);
  yield takeEvery(ADD_NEW_WAREHOUSE, addNewWarehouse);
  yield takeEvery(GET_WAREHOUSE_BY_ID, getWarehouseById);
  yield takeEvery(UPDATE_WAREHOUSE, updateWarehouse);
  yield takeEvery(DELETE_WAREHOUSE, deleteWarehouse);
}

export default warehouseSaga;
