import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useCallback, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row, Table, UncontrolledDropdown,
} from "reactstrap"
import {
  getStockRequests,
  deleteStockRequest,
  clearStockRequestError,
  submitWithStatusStockRequest,
} from "store/actions"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { getStockRequestTypeValue, hasAccess, toastMessage } from "helpers/utils"

import { debounce } from "lodash"
import { permission } from "../../constants/permission"
import moment from "moment/moment"
import CustomPagination from "../../components/Common/CustomPagination"
import ConfirmationModal from "../../components/Common/ConfirmationModal"
import { validateStockItemLimitationRequest, validateStockRequest } from "../../store/stock-request/saga"
import StockRequestValidationModal from "../../containers/stock-request/StockRequestValidationModal"

const StockRequestList = props => {
  const [ id, setId ] = useState(null)
  const [ term, setTerm ] = useState("")
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ stockRequestTypeName, setStockRequestTypeName ] = useState('')
  const [ stockRequestTypeValue, setStockRequestTypeValue ] = useState(null)
  const [ modalConfirmation, setModalConfirmation ] = useState(false)
  const [ confirmationType, setConfirmationType ] = useState('');
  const [ modalValidation, setModalValidation ] = useState(false)
  const [ modalValidationItemLimitation, setModalValidationItemLimitation ] = useState(false)
  const [ stockRequestItems, setStockRequestItems ] = useState([])

  const SUBMIT = "SUBMIT"
  const APPROVE = "APPROVE"
  const CONFIRMED = "CONFIRMED"
  const REJECT = "REJECT"
  const CANCEL = "CANCEL"

  const {
    submitWithStatusStockRequest,
    getStockRequests,
    deleteStockRequest,
    clearStockRequestError,
    message,
    items,
    loading,
    itemCount,
    pageCount,
    history,
    match
  } = props

  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term)
      }, 1000),
      []
  )

  useEffect(() => {
    const { stockRequestType } = match.params
    setStockRequestTypeName(stockRequestType)
    let stockRequestTypeParam = getStockRequestTypeValue(stockRequestType)
    setStockRequestTypeValue(stockRequestTypeParam)
    let statuses = []
    if (stockRequestTypeParam) {
      statuses
    } else {
      statuses = [ 1, 2, 3, 6 ]
      // Submitted = 1,
      //     Approved = 2,
      //     Rejected = 3,
      //     Confirmed = 3,
    }
    getStockRequests({ page, pageSize, stockRequestType: stockRequestTypeParam, statuses, term })
  }, [ page, pageSize, term, match.params.stockRequestType ])


  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearStockRequestError()
    }
  }, [ message ])

  const handleOnEdit = id => {
    history.push(`/stock-request/${ stockRequestTypeName ?? 'default' }/${ id }`)
  }

  const handleOnAddNew = () => {
    history.push(`/stock-request/${ stockRequestTypeName }/add-new`)
  }

  const handleConfirmDelete = id => {
    setId(id)
    setModalConfirmDeleteIsOpen(true)
  }

  const handleDelete = () => {
    const payload = {
      id,
      stockRequestType: stockRequestTypeValue
    }
    deleteStockRequest(payload)
    setModalConfirmDeleteIsOpen(false)
  }

  const validateAndConsumeStock = (status, stockRequestId) => {
    validateStockItemLimitationRequest(stockRequestId).then(res => {
      const { isValid, data } = res

      if (isValid) {
        // Send Validate Stock
        validateStockRequest(stockRequestId).then(res => {
          if (res.isValid) {
            submitWithStatusStockRequest({
              data: {
                id,
                status
              },
              stockRequestTypeValue,
              stockRequestTypeName
            })

            setModalConfirmation(false)
          } else {
            setModalValidation(true)
            setStockRequestItems(res.data.stockRequestItems)
          }
        })

      } else {
        setModalValidationItemLimitation(true)
        const { stockRequestItems } = data
        setStockRequestItems(stockRequestItems)
      }
    })
  }

  const handleOnSubmitWithStatus = () => {

    let status = 1
    switch (confirmationType) {
      case SUBMIT:
        status = 1
        break
      case APPROVE:
        status = 2
        break
      case REJECT:
        status = 3
        break
      case CANCEL:
        status = 5
        break
      case CONFIRMED:
        status = 6
        break
    }

    if (status === 1) {
      validateAndConsumeStock(status, id)
    } else if (status === 2) {
      validateAndConsumeStock(status, id)
    } else if (status === 6) {
      validateAndConsumeStock(status, id)
    } else {
      submitWithStatusStockRequest({
        data: {
          id,
          status
        },
        stockRequestTypeValue,
        stockRequestTypeName
      })
      setModalConfirmation(false)
    }
  }

  const displayStatus = item => {
    let color = ""
    const { status, statusName } = item
    switch (status) {
      case 0:
        color = "info"
        break
      case 1:
        color = "primary"
        break
      case 2:
        color = "primary"
        break
      case 6:
        color = "success"
        break
      case 3:
        color = "danger"
        break
      case 4:
        color = "dark"
        break
      case 5:
        color = "dark"
        break
    }
    return (
        <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
          <span>{ statusName }</span>
        </Badge>
    )
  }

  const getConfirmationText = (confirmationType) => {
    switch (confirmationType) {
      case SUBMIT:
        return 'Are you sure wanna submit for approval this?'
      case APPROVE:
        return 'Are you sure wanna approve this?'
      case CONFIRMED:
        return 'Are you sure wanna confirmed this?'
      case REJECT:
        return 'Are you sure wanna reject this?'
      case CANCEL:
        return 'Are you sure wanna cancel this?'
    }
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock Request | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Stock Request"
                breadcrumbItem="Stock Request List"
            />
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 4 }>
                        {
                          stockRequestTypeValue ?
                              <div className="text-sm-end">

                                { hasAccess(permission.stockRequest.write) &&
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="me-1"
                                        onClick={ handleOnAddNew }
                                    >
                                      <i className="fas fa-plus"/> Add New
                                    </Button>
                                }
                              </div> : null
                        }
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                <Card>
                  <CardBody>
                    <div className="table-rep-plugin">
                      { loading ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary m-1" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                      ) : (<>

                        <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                        >
                          <Table
                              id="tech-companies-1"
                              className="table table-striped table-bordered table-nowrap"
                          >
                            <thead>
                            <tr>
                              <th style={ { width: "80px" } }>#</th>
                              <th style={ { width: "120px" } }>Action</th>
                              <th className="text-center">Reference Number</th>
                              <th className="text-center">Invoice No</th>
                              <th className="text-center">Purchase Order</th>
                              <th className="text-center">Stock Request From</th>
                              <th className="text-center">Date Requested</th>
                              <th className="text-center">Item Count</th>
                              <th className="text-center">Status</th>
                              <th className="text-center">Created By</th>
                            </tr>
                            </thead>
                            <tbody>
                            { items.map((item, index) => {
                              let num = (page - 1) * pageSize + (index + 1)
                              const { status } = item
                              return (
                                  <tr key={ index } onDoubleClick={ () => handleOnEdit(item.id) }
                                      style={ { cursor: 'pointer' } }>
                                    <td style={ { textAlign: "right" } }>{ num }</td>
                                    <td>
                                      <div className="text-center">
                                        {
                                          <UncontrolledDropdown>
                                            <DropdownToggle className="card-drop" tag="a">
                                              <i className="mdi mdi-dots-horizontal font-size-18"/>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-start">
                                              {
                                                  [ 0, 3, 5 ].includes(item.status) && // Draft, Rejected and Cancel
                                                  <DropdownItem
                                                      onClick={ () => {
                                                        setId(item.id)
                                                        setModalConfirmation(true)
                                                        setConfirmationType(SUBMIT)
                                                      } }
                                                  >
                                                    <i className="mdi mdi-note-multiple text-primary me-2"></i>
                                                    Submit
                                                  </DropdownItem>
                                              }
                                              {
                                                  [ 2 ].includes(item.status) && stockRequestTypeValue === null && // Approved
                                                  <>
                                                    <DropdownItem
                                                        onClick={ () => {
                                                          setId(item.id)
                                                          setModalConfirmation(true)
                                                          setConfirmationType(CONFIRMED)
                                                        } }
                                                    >
                                                      <i className="mdi mdi-check text-primary me-2"></i>
                                                      Confirm
                                                    </DropdownItem>
                                                  </>
                                              }
                                              {
                                                  [ 1 ].includes(item.status) && stockRequestTypeValue === null && //  Submitted
                                                  <DropdownItem
                                                      onClick={ () => {
                                                        setId(item.id)
                                                        setModalConfirmation(true)
                                                        setConfirmationType(APPROVE)
                                                      } }
                                                  >
                                                    <i className="mdi mdi-check text-primary me-2"></i>
                                                    Approve
                                                  </DropdownItem>
                                              }
                                              {
                                                  [ 1, 2 ].includes(item.status) && stockRequestTypeValue === null && //  Submitted and Approve
                                                  <DropdownItem
                                                      onClick={ () => {
                                                        setId(item.id)
                                                        setModalConfirmation(true)
                                                        setConfirmationType(REJECT)
                                                      } }
                                                  >
                                                    <i className="mdi mdi-cancel text-danger me-2"></i>
                                                    Reject
                                                  </DropdownItem>
                                              }
                                              {
                                                  [ 1, 2 ].includes(item.status) && stockRequestTypeValue === null && //  Submitted and Approve
                                                  <>

                                                    <DropdownItem
                                                        onClick={ () => {
                                                          setId(item.id)
                                                          setModalConfirmation(true)
                                                          setConfirmationType(CANCEL)
                                                        } }
                                                    >
                                                      <i className="mdi mdi-cancel text-warning me-2"></i>
                                                      Cancel
                                                    </DropdownItem>
                                                  </>
                                              }
                                              {

                                                  [ 0 ].includes(item.status) && // Draft
                                                  <DropdownItem
                                                      onClick={ () => handleConfirmDelete(item.id) }
                                                  >
                                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-2"/>
                                                    Delete
                                                  </DropdownItem>
                                              }

                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        }

                                      </div>
                                    </td>
                                    <td>{ item.code }</td>
                                    <td className={ "text-end" }>{ item.invoiceNo }</td>
                                    <td>{ item.purchaseOrderCode }</td>
                                    <td>{ item.typeName }</td>
                                    <td className="text-end">
                                      { moment(item.dateRequest)
                                          .local()
                                          .format("DD-MMM-YYYY") }
                                    </td>
                                    <td className="text-end">{ item.itemCount }</td>
                                    <td className="text-center">{ displayStatus(item) }</td>
                                    <td>{ item.createdByUser }</td>

                                  </tr>
                              )
                            }) }
                            </tbody>
                          </Table>
                        </div>
                        <CustomPagination
                            itemCount={ itemCount }
                            currentPage={ page }
                            totalPage={ pageCount }
                            defaultPageSize={ pageSize }
                            pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                            onChangePage={ i => setPage(i) }
                            onPageSizeChange={ size => {
                              setPage(1)
                              setPageSize(size)
                            } }
                        />
                      </>)
                      }
                    </div>
                  </CardBody>
                </Card>

                { items && (
                    <></>
                    // <StockRequestTable
                    //   items={items}
                    //   itemCount={itemCount}
                    //   page={page}
                    //   totalPage={pageCount}
                    //   loading={loading}
                    //   defaultPageSize={pageSize}
                    //   onEdit={handleOnEdit}
                    //   onConfirm={handleConfirmStock}
                    //   onConfirmDelete={handleConfirmDelete}
                    //   onChangePage={page => setPage(page)}
                    //   onPageSizeChange={pageSize => {
                    //     setPage(1);
                    //     setPageSize(pageSize);
                    //   }}
                    // />
                ) }

                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () =>
                        setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                    }
                    onSubmit={ handleDelete }
                />

                <ConfirmationModal
                    title="Confirmation"
                    isOpen={ modalConfirmation }
                    text={ getConfirmationText(confirmationType) }
                    toggle={ () => setModalConfirmation(!modalConfirmation) }
                    onSubmit={ handleOnSubmitWithStatus }
                />

                <StockRequestValidationModal
                    title={ "Stock Request Validation Result" }
                    isStockValidation={ true }
                    isOpen={ modalValidation }
                    toggle={ () => setModalValidation(false) }
                    stockRequestItems={ stockRequestItems }/>

                <StockRequestValidationModal
                    title={ "Stock Request Validation Accessory Limited Result" }
                    isOpen={ modalValidationItemLimitation }
                    isStockValidation={ false }
                    toggle={ () => {
                      setModalValidationItemLimitation(false)
                      setStockRequestItems([])
                    } }
                    stockRequestItems={ stockRequestItems }/>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

StockRequestList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,
  match: PropTypes.object,
  getStockRequests: PropTypes.func,
  deleteStockRequest: PropTypes.func,
  clearStockRequestError: PropTypes.func,
  submitWithStatusStockRequest: PropTypes.func,
}

const mapStateToProps = ({ stockRequest }) => {
  return stockRequest
}

export default withRouter(
    connect(mapStateToProps, {
      getStockRequests,
      deleteStockRequest,
      clearStockRequestError,
      submitWithStatusStockRequest
    })(StockRequestList)
)
