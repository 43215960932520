import {
  GET_SEWINGS,
  GET_SEWINGS_SUCCESS,
  GET_SEWINGS_ERROR,
  GET_SEWING_BY_ID,
  GET_SEWING_BY_ID_SUCCESS,
  ADD_NEW_SEWING,
  ADD_NEW_SEWING_SUCCESS,
  UPDATE_SEWING,
  UPDATE_SEWING_SUCCESS,
  DELETE_SEWING,
  DELETE_SEWING_SUCCESS,
  SEWING_CLEAR_ERROR,
  ADD_NEW_SEWING_ERROR,
  GET_SEWING_ENTRY_FORM,
  GET_SEWING_ENTRY_FORM_SUCCESS,
  SAVE_SEWING_ENTRY_FORM,
  SAVE_SEWING_ENTRY_FORM_SUCCESS, GET_PAGING_SEWING_OUTSTANDING_REPORT, GET_PAGING_SEWING_OUTSTANDING_REPORT_SUCCESS,
} from "./actionTypes"
import {
  GET_CUTTING_ENTRY_FORM,
  GET_CUTTING_ENTRY_FORM_SUCCESS,
  SAVE_CUTTING_ENTRY_FORM,
  SAVE_CUTTING_ENTRY_FORM_SUCCESS
} from "../cutting/actionTypes"

// Sewing
export const getSewings = query => ({
  type: GET_SEWINGS,
  payload: query,
});

export const getSewingsSuccess = payload => ({
  type: GET_SEWINGS_SUCCESS,
  payload,
});

export const getSewingsError = error => ({
  type: GET_SEWINGS_ERROR,
  payload: error,
});

export const getSewingById = id => ({
  type: GET_SEWING_BY_ID,
  payload: id,
});

export const getSewingByIdSuccess = payload => ({
  type: GET_SEWING_BY_ID_SUCCESS,
  payload,
});

export const addNewSewing = item => ({
  type: ADD_NEW_SEWING,
  payload: item,
});

export const addNewSewingSuccess = item => ({
  type: ADD_NEW_SEWING_SUCCESS,
  payload: item,
});

export const addNewSewingError = error => ({
  type: ADD_NEW_SEWING_ERROR,
  payload: error,
});

export const updateSewing = item => ({
  type: UPDATE_SEWING,
  payload: item,
});

export const updateSewingSuccess = item => ({
  type: UPDATE_SEWING_SUCCESS,
  payload: item,
});

export const deleteSewing = item => ({
  type: DELETE_SEWING,
  payload: item,
});

export const deleteSewingSuccess = item => ({
  type: DELETE_SEWING_SUCCESS,
  payload: item,
});

export const clearSewingError = () => ({
  type: SEWING_CLEAR_ERROR,
});

export const getSewingEntryForm = query => ({
  type: GET_SEWING_ENTRY_FORM,
  payload: query,
});

export const getSewingEntryFormSuccess = payload => ({
  type: GET_SEWING_ENTRY_FORM_SUCCESS,
  payload,
});

export const saveSewingEntryForm = query => ({
  type: SAVE_SEWING_ENTRY_FORM,
  payload: query,
});

export const saveSewingEntryFormSuccess = payload => ({
  type: SAVE_SEWING_ENTRY_FORM_SUCCESS,
  payload,
});

export const getPagingSewingOutstandingReport = query => ({
  type: GET_PAGING_SEWING_OUTSTANDING_REPORT,
  payload: query,
});

export const getPagingSewingOutstandingReportSuccess = query => ({
  type: GET_PAGING_SEWING_OUTSTANDING_REPORT_SUCCESS,
  payload: query,
});

