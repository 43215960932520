import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALLCATEGORIES,
  ADD_NEW_CATEGORY,
  GET_CATEGORY_BY_ID,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCategoriesSuccess,
  getCategoryByIdSuccess,
  getCategoriesFail,
} from "./actions";

const getCategoriesRequest = query =>
  get("/api/category/paging", { params: query });
const addNewCategoryRequest = category => post("/api/category", category);
const getCategoryByIdRequest = id => get(`/api/category/${id}`);
const updateCategoryRequest = category => update("/api/category", category);
const deleteCategoryRequest = id => del(`/api/category/${id}`);

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getCategoriesRequest, payload);
    yield put(getCategoriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCategoriesFail(error));
  }
}

function* addNewCategory({ payload }) {
  try {
    yield call(addNewCategoryRequest, payload);
    const response = yield call(getCategoriesRequest, {});
    yield put(getCategoriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCategoriesFail(error));
  }
}

function* updateCategory({ payload }) {
  try {
    yield call(updateCategoryRequest, payload);
    const response = yield call(getCategoriesRequest, {});
    yield put(getCategoriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCategoriesFail(error));
  }
}

function* getCategoryById({ payload }) {
  try {
    var response = yield call(getCategoryByIdRequest, payload);
    yield put(getCategoryByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCategoriesFail(error));
  }
}

function* deleteCategory({ payload }) {
  try {
    yield call(deleteCategoryRequest, payload);
    const response = yield call(getCategoriesRequest, {});
    yield put(getCategoriesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCategoriesFail(error));
  }
}

function* categorySaga() {
  yield takeEvery(GET_ALLCATEGORIES, fetchCategories);
  yield takeEvery(ADD_NEW_CATEGORY, addNewCategory);
  yield takeEvery(GET_CATEGORY_BY_ID, getCategoryById);
  yield takeEvery(UPDATE_CATEGORY, updateCategory);
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
}

export default categorySaga;
