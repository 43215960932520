import { SEVERITY } from "helpers/utils.js";
import {
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT,
  ADD_NEW_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  PRODUCT_CLEAR_NOTIFY,
  GET_PRODUCT_BY_ID,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  totalOnHand: 0,
  totalOutgoing: 0,
  totalIn: 0,
  loading: false,
  message: null,
};

const product = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_PRODUCT:
    case UPDATE_PRODUCT:
    case DELETE_PRODUCT:
    case GET_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_SUCCESS:
      const { itemCount, pageCount, totalOnHand, totalOutgoing, totalIn , data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        totalOnHand,
        totalOutgoing,
        totalIn,
        items: data,
        loading: false,
      };

    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case GET_PRODUCT_FAIL:
      let hasUniqueCode = payload.includes("UNIQUE_Product_Code");
      return {
        ...state,
        loading: false,
        message: {
          title: "Product",
          text: hasUniqueCode ? "Product is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        item: null,
      };

    case ADD_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        item: null,
        loading: false,
        message: {
          title: "Product",
          text: "Product has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        item: null,
        loading: false,
        message: {
          title: "Product",
          text: "Product has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Product",
          text: "Product has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case PRODUCT_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default product;
