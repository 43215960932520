import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useCallback, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap"
//CodeColor
import {
  getPagingCodeColor,
  addNewCodeColor,
  updateCodeColor,
  getCodeColorById,
  deleteCodeColor,
  clearCodeColorError,
} from "store/actions"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { toastMessage } from "helpers/utils"

import { debounce } from "lodash"
import ModalForm from "containers/code-color/ModalForm"
import CodeColorTable from "containers/code-color/CodeColorTable"

const CodeColorList = (props) => {
  const [ id, setId ] = useState(null)
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false)
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)

  const {
    getPagingCodeColor,
    addNewCodeColor,
    deleteCodeColor,
    updateCodeColor,
    clearCodeColorError,
    getCodeColorById,
    codeColor,
  } = props

  const {
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = codeColor;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getPagingCodeColor({ page, pageSize, term })
  }, [ page, pageSize, term ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearCodeColorError()
    }
  }, [ message ])

  const handleOnEdit = id => {
    getCodeColorById(id)
    setModalEditIsOpen(true)
  }

  const handleUpdate = supplier => {
    updateCodeColor(supplier)
    setModalEditIsOpen(false)
  }

  const handleOnAddNew = () => {
    setModalAddIsOpen(true)
  }

  const handleSubmit = item => {
    addNewCodeColor(item)
    setModalAddIsOpen(false)
  }

  const handleConfirmDelete = id => {
    setId(id)
    setModalConfirmDeleteIsOpen(true)
  }

  const handleDelete = () => {
    deleteCodeColor(id)
    setModalConfirmDeleteIsOpen(false)
  }


  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Code Color | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Code Color" breadcrumbItem="Code Color List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 4 }>
                        <div className="text-sm-end">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                { items && (
                    <CodeColorTable
                        items={ items }
                        itemCount={ itemCount }
                        page={ page }
                        totalPage={ pageCount }
                        loading={ loading }
                        defaultPageSize={ pageSize }
                        onEdit={ handleOnEdit }
                        onConfirmDelete={ handleConfirmDelete }
                        onChangePage={ (page) => setPage(page) }
                        onPageSizeChange={ (pageSize) => {
                          setPage(1)
                          setPageSize(pageSize)
                        } }
                    />
                ) }
                { item && (
                    <ModalForm
                        title={ "Edit Code Color" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add Code Color" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                    onSubmit={ handleDelete }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

CodeColorList.propTypes = {
  codeColor: PropTypes.object,

  getPagingCodeColor: PropTypes.func,
  addNewCodeColor: PropTypes.func,
  updateCodeColor: PropTypes.func,
  getCodeColorById: PropTypes.func,
  deleteCodeColor: PropTypes.func,
  clearCodeColorError: PropTypes.func,
}

const mapStateToProps = ({ codeColor }) => {
  return {
    codeColor
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPagingCodeColor,
      addNewCodeColor,
      updateCodeColor,
      getCodeColorById,
      deleteCodeColor,
      clearCodeColorError,
    })(CodeColorList)
)
