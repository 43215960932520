// DELIVERY_ORDER_ITEM
export const GET_DELIVERY_ORDER_ITEMS = "GET_DELIVERY_ORDER_ITEMS";
export const GET_DELIVERY_ORDER_ITEMS_SUCCESS =
  "GET_DELIVERY_ORDER_ITEMS_SUCCESS";
export const GET_DELIVERY_ORDER_ITEMS_FAIL = "GET_DELIVERY_ORDER_ITEMS_FAIL";

export const GET_DELIVERY_ORDER_ITEM_BY_ID = "GET_DELIVERY_ORDER_ITEM_BY_ID";
export const GET_DELIVERY_ORDER_ITEM_BY_ID_SUCCESS =
  "GET_DELIVERY_ORDER_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERY_ORDER_ITEM = "ADD_NEW_DELIVERY_ORDER_ITEM";
export const ADD_NEW_DELIVERY_ORDER_ITEMS = "ADD_NEW_DELIVERY_ORDER_ITEMS";

export const UPDATE_DELIVERY_ORDER_ITEM = "UPDATE_DELIVERY_ORDER_ITEM";

export const DELETE_DELIVERY_ORDER_ITEM = "DELETE_DELIVERY_ORDER_ITEM";
export const DELETE_DELIVERY_ORDER_ITEMS = "DELETE_DELIVERY_ORDER_ITEMS";

export const DELIVERY_ORDER_ITEM_CLEAR_ERROR =
  "DELIVERY_ORDER_ITEM_CLEAR_ERROR";
