import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_BY_USER,
  USER_CLEAR_NOTIFY,
  RESET_USER_PASSWORD,
  UPDATE_BY_USER_SUCCESS,
  UPDATE_USER_PROFILE,
  REGISTER_USER_PORTAL,
  REGISTER_USER_PORTAL_SUCCESS,
  REGISTER_USER_PORTAL_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  DELETE_USER_BY_ID,
  DELETE_USER_BY_ID_SUCCESS,
  DELETE_USER_BY_ID_ERROR,
} from "./actionTypes";

// User
export const getUsers = query => ({
  type: GET_ALL_USERS,
  payload: query,
});

export const getUsersSuccess = users => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: users,
});

export const getUsersFail = error => ({
  type: GET_ALL_USERS_FAIL,
  payload: error,
});

export const getUserById = id => ({
  type: GET_USER_BY_ID,
  payload: id,
});

export const getUserByIdSuccess = user => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
});

export const getUserByIdFail = error => ({
  type: GET_USER_BY_ID_FAIL,
  payload: error,
});

export const updateUser = user => ({
  type: UPDATE_BY_USER,
  payload: user,
});

export const registerUserPortal = user => ({
  type: REGISTER_USER_PORTAL,
  payload: user,
});

export const registerUserPortalSuccess = payload => ({
  type: REGISTER_USER_PORTAL_SUCCESS,
  payload,
});

export const registerUserPortalError = payload => ({
  type: REGISTER_USER_PORTAL_ERROR,
  payload,
});

export const updateUserProfile = user => ({
  type: UPDATE_USER_PROFILE,
  payload: user,
});

export const updateUserProfileSuccess = payload => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserProfileError = payload => ({
  type: UPDATE_USER_PROFILE_ERROR,
  payload,
});

export const updateUserSuccess = user => ({
  type: UPDATE_BY_USER_SUCCESS,
  payload: user,
});

export const resetUserPassword = payload => ({
  type: RESET_USER_PASSWORD,
  payload,
});

export const deleteUserById = id => ({
  type: DELETE_USER_BY_ID,
  payload: id,
});

export const deleteUserByIdSuccess = id => ({
  type: DELETE_USER_BY_ID_SUCCESS,
  payload: id,
});

export const deleteUserByIdError = err => ({
  type: DELETE_USER_BY_ID_ERROR,
  payload: err,
});

export const clearUserNotify = () => ({
  type: USER_CLEAR_NOTIFY,
});
