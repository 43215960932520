import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NIL } from "uuid";
import { Button, Modal } from "reactstrap";
import {
  getOrderVerifyTracking,
  verifyBulkOrderVerifyTracking,
  clearOrderVerifyTrackingNotify,
} from "store/actions";
import OrderVerifyTrackingTable from "containers/order-verify-tracking/OrderVerifyTrackingTable";
import { toastMessage } from "helpers/utils";

export class VerifyOrderTrackingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      invoiceId: null,
      note: "",
      submitted: false,
      verifiedQuantity: 0,
      activeIndex: 0,
      orderId: null,
      items: [],
      page: 1,

      loading: false,

      currentPage: 1,
      pageSize: 10,
    };
  }

  onOpened = () => {
    const { orderId } = this.props;
    this.props.getOrderVerifyTracking({ orderId });
  };

  componentDidUpdate() {
    const { message, clearOrderVerifyTrackingNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearOrderVerifyTrackingNotify();
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { items } = this.state;
    const { orderId } = this.props;
    this.props.verifyBulkOrderVerifyTracking({ orderId, data: items });
  };

  handleOnChangePage = page => {
    const { pageSize } = this.state;
    const { orderId } = this.props;
    this.props.getOrderVerifyTracking({ orderId, page, pageSize });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = size => {
    const { orderId, getOrderVerifyTracking } = this.props;
    getOrderVerifyTracking({ orderId, page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };

  handleOrderVerifyTrackingOnChange = items => {
    this.setState({
      items,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { items, itemCount, pageCount, loading } = this.props;
    const { currentPage, pageSize } = this.state;

    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {loading ? (
            <div className="loading"></div>
          ) : (
            <>
              <OrderVerifyTrackingTable
                items={items}
                itemCount={itemCount}
                currentPage={currentPage}
                totalPage={pageCount}
                loading={loading}
                defaultPageSize={pageSize}
                onViewDetail={this.handleOnViewDetail}
                onConfirmDelete={this.handleConfirmDelete}
                showCheckbox={true}
                onChangePage={this.handleOnChangePage}
                onPageSizeChange={this.handleOnPageSizeChange}
                onChange={this.handleOrderVerifyTrackingOnChange}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            <i className="fas fa-check me-1"></i>
            Verify
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

VerifyOrderTrackingForm.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,

  orderId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrderVerifyTracking: PropTypes.func,
  verifyBulkOrderVerifyTracking: PropTypes.func,
  clearOrderVerifyTrackingNotify: PropTypes.func,
};

const mapStateToProps = ({ orderVerifyTracking }) => {
  return orderVerifyTracking;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderVerifyTracking,
    verifyBulkOrderVerifyTracking,
    clearOrderVerifyTrackingNotify,
  })(VerifyOrderTrackingForm)
);
