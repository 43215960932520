import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";

import { getFeedbackById } from "store/actions";
import moment from "moment";
import Rating from "react-rating";

export class FeedbackDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
    };
  }

  componentDidMount() {
    const { match, getFeedbackById } = this.props;
    const { id } = match.params;
    if (id !== NIL) {
      this.setState({
        id,
      });
      getFeedbackById(id);
    }
  }

  render() {
    const { feedback } = this.props;
    const { item, loading } = feedback;
    // const { attachments } = item;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Feedback Detail | JLS Express</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Home"
              backLink={"/feedback"}
              breadcrumbItem="Feedback Detail"
            />

            <Row>
              <Col lg={12}>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {item && (
                      <Card>
                        <CardHeader className="bg-transparent border">
                          <CardTitle>Info</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={12}>
                              <CardTitle>Comment</CardTitle>
                              <p className="text-muted">{item.comment}</p>
                            </Col>
                            <Col md={12}>
                              <CardTitle>User</CardTitle>
                              <p className="text-muted">{item.user}</p>
                            </Col>
                            <Col md={12}>
                              <CardTitle>Rating</CardTitle>
                              <Rating
                                max={5}
                                initialRating={item.rating}
                                emptySymbol="mdi mdi-star-outline text-danger fa-1x"
                                fullSymbol="mdi mdi-star text-danger fa-1x"
                                fractions={2}
                              />
                            </Col>
                            <Col md={12}>
                              <CardTitle>Date</CardTitle>
                              <p className="text-muted">
                                {moment
                                  .utc(item.createdDate)
                                  .local()
                                  .format("DD-MMM-yyyy h:mm:ss a")}
                              </p>
                            </Col>
                            {item.attachments && item.attachments.length > 0 && (
                              <Col md={12}>
                                <CardTitle>Attachments</CardTitle>
                                <Row>
                                  {item.attachments.map((attachment, index) => {
                                    const { url, fileName } = attachment;
                                    return (
                                      <Col md={4} key={index}>
                                        <CardImg
                                          top
                                          className="img-fluid img-rounded"
                                          src={url}
                                          alt={fileName}
                                          onClick={() =>
                                            this.setState({
                                              isGallery: true,
                                              photoIndex: index,
                                            })
                                          }
                                        />
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

FeedbackDetail.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  feedback: PropTypes.object,

  getFeedbackById: PropTypes.func,
};

const mapStateToProps = ({ feedback }) => {
  return {
    feedback,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getFeedbackById,
  })(FeedbackDetail)
);
