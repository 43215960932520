import { call, put, takeEvery } from "redux-saga/effects";
import FileSaver from "file-saver";
import {
  ADD_NEW_PRODUCT_STOCK_IN,
  GET_PRODUCT_STOCK_IN_BY_ID,
  UPDATE_PRODUCT_STOCK_IN,
  DELETE_PRODUCT_STOCK_IN,
  GET_PRODUCT_STOCK_INS,
  GET_CONFIRM_PRODUCT_STOCK_IN,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import {
  getConfirmProductStockInSuccess,
  getProductStockInByIdSuccess,
  getProductStockInsError,
  getProductStockInsSuccess,
} from "./actions";

const getProductStockInsRequest = query =>
  get("/api/ProductStockIn/paging", { params: query });
const addNewProductStockInRequest = item => post("/api/ProductStockIn", item);
const getProductStockInByIdRequest = id => get(`/api/ProductStockIn/${id}`);
const getConfirmProductStockInReqeust = id =>
  get(`/api/ProductStockIn/${id}/confirm`);
const updateProductStockInRequest = item => update("/api/ProductStockIn", item);
const deleteProductStockInRequest = id => del(`/api/ProductStockIn/${id}`);

const getProductStockInUploadTemplate = async fileName => {
  return await getFile(`/api/ProductStockIn/uploadTemplate`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const getProductStockInProductToExcel = async (id, fileName) => {
  return await getFile(`/api/ProductStockIn/${id}/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* getProductStockIns({ payload }) {
  try {
    console.log("Okss");
    console.log(payload);
    const response = yield call(getProductStockInsRequest, payload);
    yield put(getProductStockInsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInsError(error));
  }
}

function* addNewProductStockIn({ payload }) {
  try {
    yield call(addNewProductStockInRequest, payload);
    const response = yield call(getProductStockInsRequest, {});
    yield put(getProductStockInsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInsError(error));
  }
}

function* updateProductStockIn({ payload }) {
  try {
    yield call(updateProductStockInRequest, payload);
    const response = yield call(getProductStockInsRequest, {});
    yield put(getProductStockInsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInsError(error));
  }
}

function* getProductStockInById({ payload }) {
  try {
    var response = yield call(getProductStockInByIdRequest, payload);
    yield put(getProductStockInByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInsError(error));
  }
}

function* getConfirmProductStockIn({ payload }) {
  try {
    const { productStockInId, type } = payload;

    let response = yield call(
      getConfirmProductStockInReqeust,
      productStockInId
    );
    if (type === "List") {
      response = yield call(getProductStockInsRequest, {});
      yield put(getProductStockInsSuccess(response));
    }
    yield put(getConfirmProductStockInSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInsError(error));
  }
}

function* deleteProductStockIn({ payload }) {
  try {
    yield call(deleteProductStockInRequest, payload);
    const response = yield call(getProductStockInsRequest, {});
    yield put(getProductStockInsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PRODUCT_STOCK_INS, getProductStockIns);
  yield takeEvery(ADD_NEW_PRODUCT_STOCK_IN, addNewProductStockIn);
  yield takeEvery(GET_PRODUCT_STOCK_IN_BY_ID, getProductStockInById);
  yield takeEvery(UPDATE_PRODUCT_STOCK_IN, updateProductStockIn);
  yield takeEvery(DELETE_PRODUCT_STOCK_IN, deleteProductStockIn);
  yield takeEvery(GET_CONFIRM_PRODUCT_STOCK_IN, getConfirmProductStockIn);
}

export default itemSaga;

export { getProductStockInUploadTemplate, getProductStockInProductToExcel, getProductStockInByIdRequest };
