import { call, put, takeEvery } from "redux-saga/effects";
import FileSaver from "file-saver";
import {
  ADD_NEW_STOCK_OUT,
  GET_STOCK_OUT_BY_ID,
  UPDATE_STOCK_OUT,
  DELETE_STOCK_OUT,
  GET_STOCK_OUTS,
  GET_CONFIRM_STOCK_OUT,
} from "./actionTypes";
import { get, post, del, put as update, getFile } from "../../helpers/api_helper"
import {
  getConfirmStockOutSuccess,
  getStockOutByIdSuccess,
  getStockOutsError,
  getStockOutsSuccess,
} from "./actions";

const getStockOutsRequest = query =>
  get("/api/StockOut/paging", { params: query });
const addNewStockOutRequest = item => post("/api/StockOut", item);
const getStockOutByIdRequest = id => get(`/api/StockOut/${id}`);
const getConfirmStockOutReqeust = id => get(`/api/StockOut/${id}/confirm`);
const validateStockOutRequest = id =>
    get(`/api/StockOut/${id}/validate`);
const updateStockOutRequest = item => update("/api/StockOut", item);
const deleteStockOutRequest = id => del(`/api/StockOut/${id}`);

const getStockOutItemToExcel = async (id, fileName) => {
  return await getFile(`/api/StockOut/${id}/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* getStockOuts({ payload }) {
  try {
    const response = yield call(getStockOutsRequest, payload);
    yield put(getStockOutsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutsError(error));
  }
}

function* addNewStockOut({ payload }) {
  try {
    yield call(addNewStockOutRequest, payload);
    const response = yield call(getStockOutsRequest, {});
    yield put(getStockOutsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutsError(error));
  }
}

function* updateStockOut({ payload }) {
  try {
    yield call(updateStockOutRequest, payload);
    const response = yield call(getStockOutsRequest, {});
    yield put(getStockOutsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutsError(error));
  }
}

function* getStockOutById({ payload }) {
  try {
    var response = yield call(getStockOutByIdRequest, payload);
    yield put(getStockOutByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutsError(error));
  }
}

function* getConfirmStockOut({ payload }) {
  try {
    const { stockOutId, type } = payload;

    let response = yield call(getConfirmStockOutReqeust, stockOutId);
    if (type === "List") {
      response = yield call(getStockOutsRequest, {});
      yield put(getStockOutsSuccess(response));
    }
    yield put(getConfirmStockOutSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutsError(error));
  }
}

function* deleteStockOut({ payload }) {
  try {
    yield call(deleteStockOutRequest, payload);
    const response = yield call(getStockOutsRequest, {});
    yield put(getStockOutsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockOutsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_OUTS, getStockOuts);
  yield takeEvery(ADD_NEW_STOCK_OUT, addNewStockOut);
  yield takeEvery(GET_STOCK_OUT_BY_ID, getStockOutById);
  yield takeEvery(UPDATE_STOCK_OUT, updateStockOut);
  yield takeEvery(DELETE_STOCK_OUT, deleteStockOut);
  yield takeEvery(GET_CONFIRM_STOCK_OUT, getConfirmStockOut);
}

export default itemSaga;

export {
  validateStockOutRequest,
  getStockOutItemToExcel,
  getStockOutByIdRequest
}
