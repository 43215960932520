import { SEVERITY } from "helpers/utils.js"
import {
  GET_PURCHASE_ORDER_ATTACHMENTS,
  GET_PURCHASE_ORDER_ATTACHMENTS_SUCCESS,
  GET_PURCHASE_ORDER_ATTACHMENT_BY_ID_SUCCESS,
  GET_PURCHASE_ORDER_ATTACHMENTS_ERROR,
  PURCHASE_ORDER_ATTACHMENT_CLEAR_ERROR,
  GET_PURCHASE_ORDER_ATTACHMENT_BY_ID,
  ADD_NEW_PURCHASE_ORDER_ATTACHMENT,
  UPDATE_PURCHASE_ORDER_ATTACHMENT,
  DELETE_PURCHASE_ORDER_ATTACHMENT,
} from "./actionTypes.js"

const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
}

const StockInItem = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case DELETE_PURCHASE_ORDER_ATTACHMENT:
    case UPDATE_PURCHASE_ORDER_ATTACHMENT:
    
    case GET_PURCHASE_ORDER_ATTACHMENTS:
      return {
        ...state,
        loading: true,
        item: null,
      }
    case GET_PURCHASE_ORDER_ATTACHMENTS_SUCCESS:
      const { itemCount, pageCount, data } = payload
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        item: null,
        loading: false,
      }
      
    
    case ADD_NEW_PURCHASE_ORDER_ATTACHMENT:
      return {
        ...state,
        item: null,
      }
    case GET_PURCHASE_ORDER_ATTACHMENT_BY_ID:
      return {
        ...state,
        item: null,
      }
    case GET_PURCHASE_ORDER_ATTACHMENT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      }

    case GET_PURCHASE_ORDER_ATTACHMENTS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement")
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      }
    case PURCHASE_ORDER_ATTACHMENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      }
    default:
      return state
  }
}

export default StockInItem
