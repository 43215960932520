// STORE
export const GET_STORE = "GET_STORE";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAIL = "GET_STORE_FAIL";

export const GET_STORE_BY_ID = "GET_STORE_BY_ID";
export const GET_STORE_BY_ID_SUCCESS = "GET_STORE_BY_ID_SUCCESS";

export const ADD_NEW_STORE = "ADD_NEW_STORE";
export const ADD_NEW_STORE_SUCCESS = "ADD_NEW_STORE_SUCCESS";

export const UPDATE_STORE = "UPDATE_STORE";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";

export const DELETE_STORE = "DELETE_STORE";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";

export const STORE_CLEAR_NOTIFY = "STORE_CLEAR_NOTIFY";
