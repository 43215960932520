import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_TRANSFER_LOCATION,
  GET_TRANSFER_LOCATION_BY_ID,
  UPDATE_TRANSFER_LOCATION,
  DELETE_TRANSFER_LOCATION,
  GET_PAGING_TRANSFER_LOCATION,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import {
  getTransferLocationByIdSuccess,
  getPagingTransferLocationError,
  getPagingTransferLocationSuccess,
} from "./actions";

const getPagingTransferLocationRequest = query =>
  get("/api/TransferLocation/paging", { params: query });
const addNewTransferLocationRequest = item => post("/api/TransferLocation", item);
const getTransferLocationByIdRequest = id => get(`/api/TransferLocation/${id}`);
const updateTransferLocationRequest = item => update("/api/TransferLocation", item);
const deleteTransferLocationRequest = id => del(`/api/TransferLocation/${id}`);

const getTransferLocationDropdownRequest = () =>
    get("/api/TransferLocation/dropdown");


function* getPagingTransferLocation({ payload }) {
  try {
    const response = yield call(getPagingTransferLocationRequest, payload);
    yield put(getPagingTransferLocationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingTransferLocationError(error));
  }
}

function* addNewTransferLocation({ payload }) {
  try {
    yield call(addNewTransferLocationRequest, payload);
    const response = yield call(getPagingTransferLocationRequest, {});
    yield put(getPagingTransferLocationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingTransferLocationError(error));
  }
}

function* updateTransferLocation({ payload }) {
  try {
    yield call(updateTransferLocationRequest, payload);
    const response = yield call(getPagingTransferLocationRequest, {});
    yield put(getPagingTransferLocationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingTransferLocationError(error));
  }
}

function* getTransferLocationById({ payload }) {
  try {
    let response = yield call(getTransferLocationByIdRequest, payload);
    yield put(getTransferLocationByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingTransferLocationError(error));
  }
}


function* deleteTransferLocation({ payload }) {
  try {
    yield call(deleteTransferLocationRequest, payload);
    const response = yield call(getPagingTransferLocationRequest, {});
    yield put(getPagingTransferLocationSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingTransferLocationError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PAGING_TRANSFER_LOCATION, getPagingTransferLocation);
  yield takeEvery(ADD_NEW_TRANSFER_LOCATION, addNewTransferLocation);
  yield takeEvery(GET_TRANSFER_LOCATION_BY_ID, getTransferLocationById);
  yield takeEvery(UPDATE_TRANSFER_LOCATION, updateTransferLocation);
  yield takeEvery(DELETE_TRANSFER_LOCATION, deleteTransferLocation);
}

export default itemSaga;

export {
  getTransferLocationDropdownRequest
}
