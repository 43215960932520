export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_ERROR = "GET_ITEMS_ERROR";

export const GET_ITEM_BY_ID = "GET_ITEM_BY_ID";
export const GET_ITEM_BY_ID_SUCCESS = "GET_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const ADD_NEW_ITEM_SUCCESS = "ADD_NEW_ITEM_SUCCESS";
export const ADD_NEW_ITEM_ERROR = "ADD_NEW_ITEM_ERROR";

export const UPDATE_ITEM = "UPDATE_ITEM";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_ERROR = "UPDATE_ITEM_ERROR";

export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

export const ITEM_CLEAR_ERROR = "ITEM_CLEAR_ERROR";

export const GET_PAGING_ITEM_OUT_HISTORY = "GET_PAGING_ITEM_OUT_HISTORY";
export const GET_PAGING_ITEM_OUT_HISTORY_SUCCESS = "GET_PAGING_ITEM_OUT_HISTORY_SUCCESS";
