import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_CODE_COLOR,
  GET_CODE_COLOR_BY_ID,
  UPDATE_CODE_COLOR,
  DELETE_CODE_COLOR,
  GET_PAGING_CODE_COLOR,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import {
  getCodeColorByIdSuccess,
  getPagingCodeColorError,
  getPagingCodeColorSuccess,
} from "./actions";

const getPagingCodeColorRequest = query =>
  get("/api/CodeColor/paging", { params: query });
const addNewCodeColorRequest = item => post("/api/CodeColor", item);
const getCodeColorByIdRequest = id => get(`/api/CodeColor/${id}`);
const updateCodeColorRequest = item => update("/api/CodeColor", item);
const deleteCodeColorRequest = id => del(`/api/CodeColor/${id}`);

const getCodeColorDropdownRequest = () =>
    get("/api/CodeColor/dropdown");


function* getPagingCodeColor({ payload }) {
  try {
    const response = yield call(getPagingCodeColorRequest, payload);
    yield put(getPagingCodeColorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingCodeColorError(error));
  }
}

function* addNewCodeColor({ payload }) {
  try {
    yield call(addNewCodeColorRequest, payload);
    const response = yield call(getPagingCodeColorRequest, {});
    yield put(getPagingCodeColorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingCodeColorError(error));
  }
}

function* updateCodeColor({ payload }) {
  try {
    yield call(updateCodeColorRequest, payload);
    const response = yield call(getPagingCodeColorRequest, {});
    yield put(getPagingCodeColorSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingCodeColorError(error));
  }
}

function* getCodeColorById({ payload }) {
  try {
    let response = yield call(getCodeColorByIdRequest, payload);
    yield put(getCodeColorByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingCodeColorError(error));
  }
}


function* deleteCodeColor({ payload }) {
  try {
    yield call(deleteCodeColorRequest, payload);
    const response = yield call(getPagingCodeColorRequest, {});
    yield put(getPagingCodeColorSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingCodeColorError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PAGING_CODE_COLOR, getPagingCodeColor);
  yield takeEvery(ADD_NEW_CODE_COLOR, addNewCodeColor);
  yield takeEvery(GET_CODE_COLOR_BY_ID, getCodeColorById);
  yield takeEvery(UPDATE_CODE_COLOR, updateCodeColor);
  yield takeEvery(DELETE_CODE_COLOR, deleteCodeColor);
}

export default itemSaga;

export {
  getCodeColorDropdownRequest
}
