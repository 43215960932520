import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MetaTags from "react-meta-tags"
import {
  Button, ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col, Collapse,
  Container, DropdownItem, DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input, Label,
  Row, Table
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatePicker from "react-datepicker"
import {
  timestampFileName
} from "../../helpers/utils"

import { exportProductStockOutToExcel } from "../../store/product-stock-out-product/saga"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getStockOutItems} from "../../store/stock-out-item/actions"
import {
  clearProductStockOutProductError,
  getProductStockOutProducts
} from "../../store/product-stock-out-product/actions"
import { clearStockOutError } from "../../store/stock-out/actions"
import { debounce } from "lodash"
import { Tbody } from "react-super-responsive-table"
import NumberFormat from "react-number-format"
import CustomPagination from "../../components/Common/CustomPagination"
import moment from "moment"
import Select from "react-select"
import { getAllPurchaseOrdersRequest } from "../../store/purchase-order/saga"
import { getAllClothingSizesRequest } from "../../store/clothing-size/saga"
import ProductPrintPreviewModel from "./ProductPrintPreviewModel"

const ItemStockOutReport = props => {
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ term, setTerm ] = useState("")
  const [ startDate, setStartDate ] = useState(null)
  const [ endDate, setEndDate ] = useState(null)
  const [ collapseIsOpen, setCollapseIsOpen ] = useState(false)
  const [ purchaseOrderOptions, setPurchaseOrderOptions ] = useState([])
  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])
  const [ purchaseOrders, setPurchaseOrders ] = useState([])
  const [ clothingSizes, setClothingSizes ] = useState([])

  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false)
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false)

  const {
    getStockOutItems,
    stockOutItem,
    productStockOut,
  } = props

  const { items, item, message, itemCount, pageCount, loading } =
      stockOutItem


  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  const handleExportToExcel = () => {
    exportProductStockOutToExcel({
      term,
      startDate,
      endDate,
      purchaseOrderIds: purchaseOrders.map(a => {
        return a.value
      }),
      clothingSizeIds: clothingSizes.map(a => {
        return a.value
      })
    }, `${ timestampFileName() }_Stock_Out_Products.xlsx`).then(() => {
      console.log('ok')
    })
  }

  useEffect(() => {
    getStockOutItems(
        {
          term,
          page,
          pageSize,
          startDate,
          endDate,
          // purchaseOrderIds: purchaseOrders.map(a => {
          //   return a.value
          // }),
          // clothingSizeIds: clothingSizes.map(a => {
          //   return a.value
          // })
        })
  }, [ page, pageSize, term ])

  useEffect(() => {
    getAllPurchaseOrdersRequest().then(data => {
      if (data) {
        setPurchaseOrderOptions(data.map(a => {
          return {
            key: a.id,
            value: a.id,
            label: a.code
          }
        }))
      }
    })
    getAllClothingSizesRequest().then(data => {
      if (data) {
        setClothingSizeOptions(data.map(a => {
          return {
            key: a.id,
            value: a.id,
            label: a.name
          }
        }))
      }
    })
  }, [])

  const handleSubmitFilter = () => {
    getProductStockOutProducts({
      term,
      page,
      pageSize,
      startDate,
      endDate,
      purchaseOrderIds: purchaseOrders.map(a => {
        return a.value
      }),
      clothingSizeIds: clothingSizes.map(a => {
        return a.value
      })
    })
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Accessory Stock Out Report | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Report" breadcrumbItem="Accessory Stock Out Report"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border-bottom pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => {
                                  const { value } = e.target
                                  debouncedQuickSearch(value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }>
                        <FormGroup>
                          <DatePicker
                              className="form-control"
                              selectsStart
                              name="startDate"
                              selected={ startDate }
                              startDate={ startDate }
                              endDate={ endDate }
                              maxDate={ endDate }
                              onChange={ date =>
                                  setStartDate(date)
                              }
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="Start Date"
                              isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 2 }>
                        <FormGroup>
                          <DatePicker
                              className="form-control"
                              name="endDate"
                              selectsEnd
                              selected={ endDate }
                              startDate={ startDate }
                              endDate={ endDate }
                              minDate={ startDate }
                              onChange={ date =>
                                  setEndDate(date)
                              }
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="End Date"
                              isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 2 }>
                        <Button
                            type="button"
                            outline
                            color="primary"
                            onClick={ handleSubmitFilter }
                        >
                          <i className="fas fa-filter"/> Filter
                        </Button>
                      </Col>
                      <Col md={ 4 }>
                        <div className="text-sm-end me-3">

                          <ButtonDropdown
                              isOpen={ moreActionIsOpen }
                              toggle={ () => setMoreActionIsOpen(!moreActionIsOpen) }
                          >
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                  onClick={ handleExportToExcel }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-export me-1"/>Export to
                                Excel
                              </DropdownItem>
                              <DropdownItem
                                  onClick={()=>{
                                    setModalPrintPreview(true);
                                  } }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-pdf me-1"/>Export to
                                PDF
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </div>
                      </Col>
                    </Row>
                    <div
                        style={ {
                          position: "absolute",
                          top: "1rem",
                          right: "0.5rem",
                          cursor: "pointer",
                        } }
                        onClick={ () => {
                          setCollapseIsOpen(!collapseIsOpen)
                        } }
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                            className={
                              collapseIsOpen
                                  ? "fas fa-angle-up"
                                  : "fas fa-angle-down"
                            }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={ collapseIsOpen }>
                    <CardBody className="pb-1">
                      {/*<Row>*/}
                      {/*  <Col md={ 4 }>*/}
                      {/*    <FormGroup>*/}
                      {/*      <Label>Purchase Order</Label>*/}
                      {/*      <Select*/}
                      {/*          name="purchaseOrders"*/}
                      {/*          value={ purchaseOrders }*/}
                      {/*          onChange={ option => setPurchaseOrders(option) }*/}
                      {/*          options={ purchaseOrderOptions }*/}
                      {/*          classNamePrefix="select2-selection"*/}
                      {/*          placeholder="Purchase Order"*/}
                      {/*          isClearable*/}
                      {/*          isMulti*/}
                      {/*      />*/}
                      {/*    </FormGroup>*/}
                      {/*  </Col>*/}
                      {/*  <Col md={ 4 }>*/}
                      {/*    <FormGroup>*/}
                      {/*      <Label>Clothing Size</Label>*/}
                      {/*      <Select*/}
                      {/*          name="clothingSizes"*/}
                      {/*          value={ clothingSizes }*/}
                      {/*          onChange={ option => setClothingSizes(option) }*/}
                      {/*          options={ clothingSizeOptions }*/}
                      {/*          classNamePrefix="select2-selection"*/}
                      {/*          placeholder="Clothing Size"*/}
                      {/*          isClearable*/}
                      {/*          isMulti*/}
                      {/*      />*/}
                      {/*    </FormGroup>*/}
                      {/*  </Col>*/}
                      {/*</Row>*/}
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-rep-plugin">
                      { loading ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary m-1" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                      ) : (
                          <div
                              className="table-responsive mb-0"
                              data-pattern="priority-columns"
                          >
                            <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered table-nowrap"
                            >
                              <thead>
                              <tr>
                                <th style={ { width: "80px" } }>#</th>
                                <th className="text-center">Code</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Quantity</th>
                                <th className="text-center">UoM</th>
                                <th className="text-center">Total</th>
                              </tr>
                              </thead>
                              <Tbody>
                                { items.map((item, index) => {
                                  let num = (page - 1) * pageSize + (index + 1)
                                  let totalQuantity =
                                      item.quantity * parseInt(item.unitOfMeasurementConversionRate)
                                  return (
                                      <tr key={ index }>
                                        <td style={ { textAlign: "right" } }>{ num }</td>
                                        <td>{ item.itemCode }</td>
                                        <td>{ item.itemName }</td>
                                        <td>{ moment(item.date).format('DD-MMM-YYYY') }</td>
                                        <td className="text-end">
                                          <NumberFormat
                                              value={ item.quantity }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </td>
                                        <td>{ item.unitOfMeasurementName }</td>
                                        <td className="text-end">
                                          <NumberFormat
                                              value={ totalQuantity ?? 0 }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </td>
                                      </tr>
                                  )
                                }) }
                              </Tbody>
                            </Table>
                          </div>
                      ) }
                    </div>
                    <CustomPagination
                        itemCount={ itemCount }
                        currentPage={ page }
                        totalPage={ pageCount }
                        defaultPageSize={ pageSize }
                        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                        onChangePage={ i => setPage(i) }
                        onPageSizeChange={ size => {
                          setPage(1)
                          setPageSize(size)
                        } }
                    />

                    <ProductPrintPreviewModel
                        isOpen={modalPrintPreview}
                        toggle={()=> setModalPrintPreview(!modalPrintPreview)}
                        term={term}
                        startDate={startDate}
                        endDate={endDate}
                        purchaseOrderIds={purchaseOrders.map(a =>{
                          return a.value
                        })}
                        clothingSizeIds={clothingSizes.map(a =>{
                          return a.value
                        })}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

ItemStockOutReport.propTypes = {
  productStockOut: PropTypes.object,
  stockOutItem: PropTypes.object,
  getProductStockOutProducts: PropTypes.func,
  getStockOutItems: PropTypes.func,
}

const mapStateToProps = ({ stockOutItem, productStockOut }) => {
  return {
    stockOutItem,
    productStockOut,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getStockOutItems,
      getProductStockOutProducts,
      clearProductStockOutProductError,

      clearStockOutError,
    })(ItemStockOutReport)
)
