import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
  getCompany,
  addNewStore,
  updateStore,
  getStoreById,
  deleteStore,
  clearStoreNotify,
  getStore,
} from "store/actions";
import StoreTable from "./StoreTable";
import ModalForm from "./ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";
import { companyService } from "services/company-service";

const StoreList = props => {
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [term, setTerm] = useState("");

  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);

  const {
    getStore,
    clearStoreNotify,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
  } = props;

  useEffect(() => {
    getStore({ term, page, pageSize });
  }, [term, page, pageSize]);

  useEffect(() => {
    companyService.getRequest().then(data => {
      if (data) {
        setCompanyOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            };
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearStoreNotify();
    }
  }, [message]);

  const toggleModalEdit = () => {
    setModalEditIsOpen(!modalEditIsOpen);
  };

  const toggleModalAdd = () => {
    setModalAddIsOpen(!modalAddIsOpen);
  };

  const toggleModalConfirmDelete = () => {
    setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen);
  };

  const handleOnEdit = id => {
    const { getStoreById } = props;
    getStoreById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = store => {
    const { updateStore } = props;
    updateStore(store);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = item => {
    const { addNewStore } = props;
    addNewStore(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    const { deleteStore } = props;
    deleteStore(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnPageChange = page => {
    setPage(page);
    setCurrentPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPage(1);
    setCurrentPage(1);
    setPageSize(pageSize);
  };

  const handleOnSearch = e => {
    const { value } = e.target;
    setTerm(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Store | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Store" breadcrumbItem="Store List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={handleOnSearch}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <StoreTable
                  items={items}
                  itemCount={itemCount}
                  currentPage={currentPage}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onEdit={handleOnEdit}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={handleOnPageChange}
                  onPageSizeChange={handleOnPageSizeChange}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Store"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  companyOptions={companyOptions}
                  toggle={toggleModalEdit}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Store"}
                isOpen={modalAddIsOpen}
                companyOptions={companyOptions}
                toggle={toggleModalAdd}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={toggleModalConfirmDelete}
                onSubmit={handleDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

StoreList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getCompany: PropTypes.func,
  getStore: PropTypes.func,
  addNewStore: PropTypes.func,
  updateStore: PropTypes.func,
  getStoreById: PropTypes.func,
  deleteStore: PropTypes.func,
  clearStoreNotify: PropTypes.func,
};

const mapStateToProps = ({ store }) => {
  return store;
};

export default withRouter(
  connect(mapStateToProps, {
    getCompany,
    getStore,
    addNewStore,
    updateStore,
    getStoreById,
    deleteStore,
    clearStoreNotify,
  })(StoreList)
);
