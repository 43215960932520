import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_ITEM_USAGE_LIMITATION,
  GET_ITEM_USAGE_LIMITATION_BY_ID,
  UPDATE_ITEM_USAGE_LIMITATION,
  DELETE_ITEM_USAGE_LIMITATION,
  GET_ITEM_USAGE_LIMITATIONS, 
  SAVE_BULK_ITEM_USAGE_LIMITATION,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getItemUsageLimitationByIdSuccess,
  getItemUsageLimitationsError,
  getItemUsageLimitationsSuccess, saveBulkItemUsageLimitationSuccess,
} from "./actions"

const getItemUsageLimitationsRequest = query =>
  get("/api/ItemUsageLimitation/paging", { params: query });

const getItemsInItemUsageRequest = query =>
    get("/api/ItemUsageLimitation/items", { params: query });

const addNewItemUsageLimitationRequest = item =>
  post("/api/ItemUsageLimitation", item);

const saveBulkItemUsageLimitationRequest = (purchaseOrderId, item) =>
    post(`/api/ItemUsageLimitation/${purchaseOrderId}/bulk`, item);

const getItemUsageLimitationByIdRequest = id =>
  get(`/api/ItemUsageLimitation/${id}`);
const updateItemUsageLimitationRequest = item =>
  update("/api/ItemUsageLimitation", item);
const deleteItemUsageLimitationRequest = id =>
  del(`/api/ItemUsageLimitation/${id}`);

function* getItemUsageLimitations({ payload }) {
  try {
    const response = yield call(getItemUsageLimitationsRequest, payload);
    yield put(getItemUsageLimitationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUsageLimitationsError(error));
  }
}

function* addNewItemUsageLimitation({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(addNewItemUsageLimitationRequest, payload);
    const response = yield call(getItemUsageLimitationsRequest, {
      purchaseOrderId,
    });
    yield put(getItemUsageLimitationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUsageLimitationsError(error));
  }
}

function* saveBulkItemUsageLimitation({ payload }) {
  try {
    const { data, purchaseOrderId } = payload;
    yield call(saveBulkItemUsageLimitationRequest, purchaseOrderId, data);
    const response = yield call(getItemUsageLimitationsRequest, {
      purchaseOrderId,
    });
    yield put(getItemUsageLimitationsSuccess(response));
    yield put(saveBulkItemUsageLimitationSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUsageLimitationsError(error));
  }
}

function* updateItemUsageLimitation({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(updateItemUsageLimitationRequest, payload);
    const response = yield call(getItemUsageLimitationsRequest, {
      purchaseOrderId,
    });
    yield put(getItemUsageLimitationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUsageLimitationsError(error));
  }
}

function* getItemUsageLimitationById({ payload }) {
  try {
    let response = yield call(getItemUsageLimitationByIdRequest, payload);
    yield put(getItemUsageLimitationByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUsageLimitationsError(error));
  }
}

function* deleteItemUsageLimitation({ payload }) {
  try {
    const { purchaseOrderId, id } = payload;
    yield call(deleteItemUsageLimitationRequest, id);
    const response = yield call(getItemUsageLimitationsRequest, {
      purchaseOrderId,
    });
    yield put(getItemUsageLimitationsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUsageLimitationsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_ITEM_USAGE_LIMITATIONS, getItemUsageLimitations);
  yield takeEvery(ADD_NEW_ITEM_USAGE_LIMITATION, addNewItemUsageLimitation);
  yield takeEvery(SAVE_BULK_ITEM_USAGE_LIMITATION, saveBulkItemUsageLimitation);
  yield takeEvery(ADD_NEW_ITEM_USAGE_LIMITATION, addNewItemUsageLimitation);
  yield takeEvery(GET_ITEM_USAGE_LIMITATION_BY_ID, getItemUsageLimitationById);
  yield takeEvery(UPDATE_ITEM_USAGE_LIMITATION, updateItemUsageLimitation);
  yield takeEvery(DELETE_ITEM_USAGE_LIMITATION, deleteItemUsageLimitation);
}

export default itemSaga;

export {
  getItemsInItemUsageRequest
}
