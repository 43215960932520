import {
  GET_PURCHASE_ORDER_ITEMS,
  GET_PURCHASE_ORDER_ITEMS_SUCCESS,
  GET_PURCHASE_ORDER_ITEMS_ERROR,
  GET_PURCHASE_ORDER_ITEM_BY_ID,
  GET_PURCHASE_ORDER_ITEM_BY_ID_SUCCESS,
  ADD_NEW_PURCHASE_ORDER_ITEM,
  ADD_NEW_PURCHASE_ORDER_ITEM_SUCCESS,
  UPDATE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM_SUCCESS,
  DELETE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM_SUCCESS,
  PURCHASE_ORDER_ITEM_CLEAR_ERROR,
  ADD_NEW_PURCHASE_ORDER_ITEM_ERROR,
  ADD_NEW_BULK_PURCHASE_ORDER_ITEM_SUCCESS,
  ADD_NEW_BULK_PURCHASE_ORDER_ITEM,
  ADD_NEW_BULK_PURCHASE_ORDER_ITEM_ERROR,
  SAVE_PURCHASE_ORDER_ITEMS,
  SAVE_PURCHASE_ORDER_ITEMS_SUCCESS,
  SAVE_PURCHASE_ORDER_ITEMS_ERROR,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_SUCCESS,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_ERROR
} from "./actionTypes"

// PurchaseOrderItem
export const getPurchaseOrderItems = query => ({
  type: GET_PURCHASE_ORDER_ITEMS,
  payload: query,
});

export const getPurchaseOrderItemsSuccess = payload => ({
  type: GET_PURCHASE_ORDER_ITEMS_SUCCESS,
  payload,
});

export const getPurchaseOrderItemsError = error => ({
  type: GET_PURCHASE_ORDER_ITEMS_ERROR,
  payload: error,
});

export const getPurchaseOrderItemById = id => ({
  type: GET_PURCHASE_ORDER_ITEM_BY_ID,
  payload: id,
});

export const getPurchaseOrderItemByIdSuccess = payload => ({
  type: GET_PURCHASE_ORDER_ITEM_BY_ID_SUCCESS,
  payload,
});

export const addNewPurchaseOrderItem = item => ({
  type: ADD_NEW_PURCHASE_ORDER_ITEM,
  payload: item,
});

export const addNewPurchaseOrderItemSuccess = item => ({
  type: ADD_NEW_PURCHASE_ORDER_ITEM_SUCCESS,
  payload: item,
});

export const addNewPurchaseOrderItemError = error => ({
  type: ADD_NEW_PURCHASE_ORDER_ITEM_ERROR,
  payload: error,
});

export const addNewBulkPurchaseOrderItem = item => ({
  type: ADD_NEW_BULK_PURCHASE_ORDER_ITEM,
  payload: item,
});

export const addNewBulkPurchaseOrderItemSuccess = item => ({
  type: ADD_NEW_BULK_PURCHASE_ORDER_ITEM_SUCCESS,
  payload: item,
});

export const addNewBulkPurchaseOrderItemError = error => ({
  type: ADD_NEW_BULK_PURCHASE_ORDER_ITEM_ERROR,
  payload: error,
});

export const updatePurchaseOrderItem = item => ({
  type: UPDATE_PURCHASE_ORDER_ITEM,
  payload: item,
});

export const updatePurchaseOrderItemSuccess = item => ({
  type: UPDATE_PURCHASE_ORDER_ITEM_SUCCESS,
  payload: item,
});

export const deletePurchaseOrderItem = item => ({
  type: DELETE_PURCHASE_ORDER_ITEM,
  payload: item,
});

export const deletePurchaseOrderItemSuccess = item => ({
  type: DELETE_PURCHASE_ORDER_ITEM_SUCCESS,
  payload: item,
});

export const clearPurchaseOrderItemError = () => ({
  type: PURCHASE_ORDER_ITEM_CLEAR_ERROR,
});

export const savePurchaseOrderItems = payload => ({
  type: SAVE_PURCHASE_ORDER_ITEMS,
  payload,
});

export const savePurchaseOrderItemsSuccess = payload => ({
  type: SAVE_PURCHASE_ORDER_ITEMS_SUCCESS,
  payload,
});

export const savePurchaseOrderItemsError = payload => ({
  type: SAVE_PURCHASE_ORDER_ITEMS_ERROR,
  payload,
});

export const getPurchaseOrderItemsByPurchaseOrderId = payload => ({
  type: GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID,
  payload,
});

export const getPurchaseOrderItemsByPurchaseOrderIdSuccess = payload => ({
  type: GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_SUCCESS,
  payload,
});

export const getPurchaseOrderItemsByPurchaseOrderIdError = payload => ({
  type: GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_ERROR,
  payload,
});
