import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DesignForm from "../../containers/purchase-order/DesignForm"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  savePurchaseOrderItems,
  getPurchaseOrderItemsByPurchaseOrderId, 
  clearPurchaseOrderItemError,
} from "../../store/purchase-order-item/actions"
import { clearPurchaseOrderError } from "../../store/purchase-order/actions"
import { toastMessage } from "../../helpers/utils"

const DesignFormTab = props => {
  const {
    purchaseOrderId,
    savePurchaseOrderItems,
    getPurchaseOrderItemsByPurchaseOrderId,
    clearPurchaseOrderItemError,
    purchaseOrderItem
  } = props

  const { item, message, loading, clothingSizes } = purchaseOrderItem
  
  useEffect(() => {
    getPurchaseOrderItemsByPurchaseOrderId(purchaseOrderId)
  }, [ purchaseOrderId ])

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearPurchaseOrderItemError();
    }
  }, [message]);

  const handleSubmit = items => {
    let data = {
      purchaseOrderId,
      items
    }

    savePurchaseOrderItems(data)
  }

  return (
      <>
        {
          loading ?
              <div className={"loading"}></div>
              : <DesignForm onSubmit={ handleSubmit } item={item}/> 
        }
        
      </>
  )
}

DesignFormTab.propTypes = {
  purchaseOrderId: PropTypes.string,
  savePurchaseOrderItems: PropTypes.func,
  getPurchaseOrderItemsByPurchaseOrderId: PropTypes.func,
  purchaseOrderItem: PropTypes.object,
  clearPurchaseOrderItemError: PropTypes.func,
}

const mapStateToProps = ({ purchaseOrderItem, purchaseOrder }) => {
  return {
    purchaseOrderItem,
    purchaseOrder,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPurchaseOrderItemsByPurchaseOrderId,
      savePurchaseOrderItems,
      clearPurchaseOrderError,
      clearPurchaseOrderItemError
    })(DesignFormTab)
)
