import { get, post, del, put as update } from "helpers/api_helper";
const getUsersRequest = async () => {
  return await get("/api/Account/users");
};

const getUserPagingRequest = async query => {
  return await post("api/Account/paging", query);
};

const sendNotificationRequest = async query => {
  return await post("api/PushNotification/pushMessage", query);
};

export const userService = {
  getUsersRequest,
  getUserPagingRequest,
  sendNotificationRequest,
};
