import {
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTES_FAIL,
  GET_ATTRIBUTE_BY_ID,
  GET_ATTRIBUTE_BY_ID_SUCCESS,
  ADD_NEW_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  ATTRIBUTE_CLEAR_ERROR,
} from "./actionTypes";

export const getAttributes = query => ({
  type: GET_ATTRIBUTES,
  payload: query,
});

export const getAttributesSuccess = attributes => ({
  type: GET_ATTRIBUTES_SUCCESS,
  payload: attributes,
});

export const getAttributesFail = error => ({
  type: GET_ATTRIBUTES_FAIL,
  payload: error,
});

export const getAttributeById = id => ({
  type: GET_ATTRIBUTE_BY_ID,
  payload: id,
});

export const getAttributeByIdSuccess = attribute => ({
  type: GET_ATTRIBUTE_BY_ID_SUCCESS,
  payload: attribute,
});

export const getAttributeByIdFail = error => ({
  type: GET_ATTRIBUTE_BY_ID_FAIL,
  payload: error,
});

export const addNewAttribute = attribute => ({
  type: ADD_NEW_ATTRIBUTE,
  payload: attribute,
});

export const updateAttribute = attribute => ({
  type: UPDATE_ATTRIBUTE,
  payload: attribute,
});

export const deleteAttribute = attribute => ({
  type: DELETE_ATTRIBUTE,
  payload: attribute,
});

export const clearAttributeError = () => ({
  type: ATTRIBUTE_CLEAR_ERROR,
});
