import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CuttingForm from "../../containers/cutting/CuttingForm"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  getPackagingEntryForm,
  savePackagingEntryForm,
  clearPackagingError,
} from "../../store/packaging/actions"

import { toastMessage } from "../../helpers/utils"

const PackagingFormTab = props => {
  const { getPackagingEntryForm, savePackagingEntryForm, clearPackagingError, purchaseOrderId, packaging } = props

  const { item, loading, message } = packaging

  useEffect(() => {
    getPackagingEntryForm(purchaseOrderId)
  }, []);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearPackagingError();
    }
  }, [message]);

  const handleOnSubmit=(items)=>{
    const data={
      purchaseOrderId,
      items
    };

    savePackagingEntryForm(data);
  }

  return (
      <>
        {
          loading ? <div className={ "loading" }></div> : <>
            {
                item &&
                <CuttingForm entryForm={ item }
                             onSubmit={handleOnSubmit}
                />
            }
          </>
        }
      </>

  )
}

PackagingFormTab.propTypes = {
  getPackagingEntryForm: PropTypes.func,
  savePackagingEntryForm: PropTypes.func,
  clearPackagingError: PropTypes.func,
  purchaseOrderId: PropTypes.string,
  packaging: PropTypes.object
}

const mapStateToProps = ({ packaging, purchaseOrder }) => {
  return {
    packaging,
    purchaseOrder,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPackagingEntryForm,
      savePackagingEntryForm,
      clearPackagingError
    })(PackagingFormTab)
)