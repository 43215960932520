import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardFooter, CardHeader, Table } from "reactstrap"
import DesignFormTableRow from "./DesignFormTableRow"
import { NIL } from "uuid"
import { isNumber } from "lodash"

const DesignForm = props => {
  const { onSubmit, item } = props

  const [ clothingSizes, setClothingSizes ] = useState([])
  const [ items, setItems ] = useState([])
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ isSubmitted, setIsSubmitted ] = useState(false)

  useEffect(() => {
    if (item) {
      setItems(item.items.map((a, index) => {
        return {
          ...a,
          index,
          isSelected: false,
          itemId: {
            key: a.itemId,
            value: a.itemId,
            label: `${ a.itemCode } - ${ a.itemName }`
          }
        }
      }))
      setClothingSizes(item.clothingSizes)
    }

  }, [ item ])

  const handleOnAddItem = () => {
    let newItem = {
      id: NIL,
      itemId: null,
      index: items.length,
      clothingSizes: clothingSizes.map(a => {
        return {
          id: a.id,
          quantity: '',
        }
      }),
    }
    setItems([ ...items, newItem ])
  }

  const handleOnDelete = (item) => {
    setItems(items.filter(i => i.index !== item.index))
  }

  const handleOnChange = (item) => {
    setItems(items.map(i => i.index === item.index ? item : i))
  }

  const handleSubmit = () => {
    setIsSubmitted(true)

    let hasNull = items.some(e => !e.itemId)
    
    if(!hasNull){
        let data = items.map(item => {
          return {
            ...item,
            itemId: item.itemId.value,
            clothingSizes: item.clothingSizes.map(clothingSize =>{
              return {
                ...clothingSize,
                quantity: isNumber(clothingSize.quantity) ? clothingSize.quantity : 0,
              }
            })
          }
        })
        onSubmit(data)
    }
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)

    setItems(items.map(item => {
      return {
        ...item,
        isSelected: checked
      }
    }))
  }

  const handleDeleteSelected = () => {
    setItems(items.filter(item => !item.isSelected))
    setIsHeaderSelected(false)
  }

  return (
      <>
        <Card>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <div className="text-sm-end">
              {/*<Button*/}
              {/*    type="button"*/}
              {/*    color="primary"*/}
              {/*    className="me-1"*/}
              {/*    onClick={ handleOnAddItem }*/}
              {/*>*/}
              {/*  <i className="fas fa-plus"/> Add New*/}
              {/*</Button>*/}
              {/*<Button color={ "danger" }*/}
              {/*        onClick={ handleDeleteSelected }*/}
              {/*        outline disabled={ !items.some(e => e.isSelected) }*/}
              {/*>*/}
              {/*  <i className="fas fa-trash me-1"/> Delete Selected*/}
              {/*</Button>*/}
            </div>
          </CardHeader>
          <CardBody>
            <Table
                // id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center" style={ { width: "350px" } }>Item</th>
                {
                  clothingSizes.map(size => {
                    return <th key={ size.id } className={ "text-center" }>{ size.name }</th>
                  })
                }
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>
              {
                items.sort((a, b) => a.index > b.index? -1: 1)
                    .map((item, index) => {
                  return <DesignFormTableRow
                      key={ index }
                      item={ item }
                      isSubmitted={ isSubmitted }
                      clothingSizes={ clothingSizes }
                      onChange={ handleOnChange }
                      onAdd={ handleOnAddItem }
                      onDelete={ handleOnDelete }

                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
          <CardFooter className={ "bg-transparent border-top text-end" }>
            <Button color="primary" onClick={ handleSubmit } type="submit">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </>
  )
}

DesignForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object,
}

export default DesignForm