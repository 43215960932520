import {
  GET_STOCK_ADJUSTMENT_ITEMS,
  GET_STOCK_ADJUSTMENT_ITEMS_SUCCESS,
  GET_STOCK_ADJUSTMENT_ITEMS_ERROR,
  GET_STOCK_ADJUSTMENT_ITEM_BY_ID,
  GET_STOCK_ADJUSTMENT_ITEM_BY_ID_SUCCESS,
  ADD_NEW_STOCK_ADJUSTMENT_ITEM,
  ADD_NEW_STOCK_ADJUSTMENT_ITEM_SUCCESS,
  UPDATE_STOCK_ADJUSTMENT_ITEM,
  UPDATE_STOCK_ADJUSTMENT_ITEM_SUCCESS,
  DELETE_STOCK_ADJUSTMENT_ITEM,
  DELETE_STOCK_ADJUSTMENT_ITEM_SUCCESS,
  STOCK_ADJUSTMENT_ITEM_CLEAR_ERROR,
  ADD_NEW_STOCK_ADJUSTMENT_ITEM_ERROR,
} from "./actionTypes";

// StockAdjustmentItem
export const getStockAdjustmentItems = query => ({
  type: GET_STOCK_ADJUSTMENT_ITEMS,
  payload: query,
});

export const getStockAdjustmentItemsSuccess = payload => ({
  type: GET_STOCK_ADJUSTMENT_ITEMS_SUCCESS,
  payload,
});

export const getStockAdjustmentItemsError = error => ({
  type: GET_STOCK_ADJUSTMENT_ITEMS_ERROR,
  payload: error,
});

export const getStockAdjustmentItemById = id => ({
  type: GET_STOCK_ADJUSTMENT_ITEM_BY_ID,
  payload: id,
});

export const getStockAdjustmentItemByIdSuccess = payload => ({
  type: GET_STOCK_ADJUSTMENT_ITEM_BY_ID_SUCCESS,
  payload,
});

export const addNewStockAdjustmentItem = item => ({
  type: ADD_NEW_STOCK_ADJUSTMENT_ITEM,
  payload: item,
});

export const addNewStockAdjustmentItemSuccess = item => ({
  type: ADD_NEW_STOCK_ADJUSTMENT_ITEM_SUCCESS,
  payload: item,
});

export const addNewStockAdjustmentItemError = error => ({
  type: ADD_NEW_STOCK_ADJUSTMENT_ITEM_ERROR,
  payload: error,
});

export const updateStockAdjustmentItem = item => ({
  type: UPDATE_STOCK_ADJUSTMENT_ITEM,
  payload: item,
});

export const updateStockAdjustmentItemSuccess = item => ({
  type: UPDATE_STOCK_ADJUSTMENT_ITEM_SUCCESS,
  payload: item,
});

export const deleteStockAdjustmentItem = item => ({
  type: DELETE_STOCK_ADJUSTMENT_ITEM,
  payload: item,
});

export const deleteStockAdjustmentItemSuccess = item => ({
  type: DELETE_STOCK_ADJUSTMENT_ITEM_SUCCESS,
  payload: item,
});

export const clearStockAdjustmentItemError = () => ({
  type: STOCK_ADJUSTMENT_ITEM_CLEAR_ERROR,
});
