import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Label, Row } from "reactstrap";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";

export class ResetPasswordModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      loading: false,
      isShow: false,
      isShowPwdConfirm: false,
    };
  }

  onOpened = () => {
    this.setState({
      loading: false,
    });
  };

  toggleShowhide = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }));
  };

  toggleShowhidePwdConfirm = () => {
    this.setState(prevState => ({
      isShowPwdConfirm: !prevState.isShowPwdConfirm,
    }));
  };

  render() {
    const { isShow, isShowPwdConfirm } = this.state;
    const { isOpen, toggle, item } = this.props;
    const { id, firstName, lastName } = item;

    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            userId: id,
            password: (this.state && this.state.password) || "",
            passwordConfirm: (this.state && this.state.passwordConfirm) || "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Please Enter Valid Password")
              .min(8, "Must be at least 8 characters long."),
            passwordConfirm: Yup.string()
              .required("Please Enter Valid Password Confirmation")
              .oneOf([Yup.ref("password"), null], "Passwords must match"),
          })}
          onSubmit={values => {
            this.props.onSubmit(values);
          }}
        >
          {({ errors, status, touched }) => (
            <Form className="form-horizontal">
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  Resetting password for{" "}
                  <span className="text-success">
                    {firstName} {lastName}
                  </span>
                </h5>
                <button
                  type="button"
                  onClick={toggle}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="password" className="form-label">
                        Password
                      </Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Field
                          name="password"
                          autoComplete="true"
                          type={isShow ? "text" : "password"}
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <button
                          className="btn btn-light "
                          type="button"
                          id="password-addon"
                          onClick={this.toggleShowhide}
                        >
                          <i
                            className={
                              "mdi " +
                              (isShow
                                ? "mdi-eye-off-outline"
                                : "mdi-eye-outline")
                            }
                          ></i>
                        </button>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="passwordConfirm" className="form-label">
                        Password Confirmation
                      </Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Field
                          name="passwordConfirm"
                          autoComplete="true"
                          type={isShowPwdConfirm ? "text" : "password"}
                          className={
                            "form-control" +
                            (errors.passwordConfirm && touched.passwordConfirm
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <button
                          className="btn btn-light "
                          type="button"
                          id="password-addon"
                          onClick={this.toggleShowhidePwdConfirm}
                        >
                          <i
                            className={
                              "mdi " +
                              (isShowPwdConfirm
                                ? "mdi-eye-off-outline"
                                : "mdi-eye-outline")
                            }
                          ></i>
                        </button>
                      </div>

                      <ErrorMessage
                        name="passwordConfirm"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Submit
                </Button>
                <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

ResetPasswordModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ResetPasswordModalForm;
