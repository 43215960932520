// TRANSFER_LOCATION
export const GET_PAGING_TRANSFER_LOCATION = "GET_PAGING_TRANSFER_LOCATION";
export const GET_PAGING_TRANSFER_LOCATION_SUCCESS = "GET_PAGING_TRANSFER_LOCATION_SUCCESS";
export const GET_PAGING_TRANSFER_LOCATION_ERROR = "GET_PAGING_TRANSFER_LOCATION_ERROR";

export const GET_TRANSFER_LOCATION_BY_ID = "GET_TRANSFER_LOCATION_BY_ID";
export const GET_TRANSFER_LOCATION_BY_ID_SUCCESS = "GET_TRANSFER_LOCATION_BY_ID_SUCCESS";

export const ADD_NEW_TRANSFER_LOCATION = "ADD_NEW_TRANSFER_LOCATION";
export const ADD_NEW_TRANSFER_LOCATION_SUCCESS = "ADD_NEW_TRANSFER_LOCATION_SUCCESS";
export const ADD_NEW_TRANSFER_LOCATION_ERROR = "ADD_NEW_TRANSFER_LOCATION_ERROR";

export const UPDATE_TRANSFER_LOCATION = "UPDATE_TRANSFER_LOCATION";
export const UPDATE_TRANSFER_LOCATION_SUCCESS = "UPDATE_TRANSFER_LOCATION_SUCCESS";
export const UPDATE_TRANSFER_LOCATION_ERROR = "UPDATE_TRANSFER_LOCATION_ERROR";

export const DELETE_TRANSFER_LOCATION = "DELETE_TRANSFER_LOCATION";
export const DELETE_TRANSFER_LOCATION_SUCCESS = "DELETE_TRANSFER_LOCATION_SUCCESS";

export const TRANSFER_LOCATION_CLEAR_ERROR = "TRANSFER_LOCATION_CLEAR_ERROR";
