import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_ITEM,
  GET_ITEM_BY_ID,
  UPDATE_ITEM,
  DELETE_ITEM,
  GET_ITEMS, GET_PAGING_ITEM_OUT_HISTORY,
} from "./actionTypes"
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import { getItemsSuccess, getItemByIdSuccess, getItemsError, getPagingItemOutHistorySuccess } from "./actions"
import FileSaver from "file-saver";
const getItemsRequest = query => post("/api/Item/paging", query);
const getAllItemsRequest = query => post("/api/Item/all", query);
const addNewItemRequest = Item => post("/api/Item", Item);
const getItemByIdRequest = id => get(`/api/Item/${id}`);
const updateItemRequest = Item => update("/api/Item", Item);
const deleteItemRequest = id => del(`/api/Item/${id}`);
const getPagingItemOutHistoryRequest = query => get("/api/Item/pagingItemOutHistory", { params: query });

const exportItemOutHistoryRequest = async (query, fileName) => {
  return await getFile(`/api/Item/itemOutHistoryExportToExcel`, {
    params: query,
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const getItemUploadTemplate = async fileName => {
  return await getFile(`/api/Item/uploadTemplate`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const getItemToExcel = async (fileName) => {
  return await getFile(`/api/Item/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* getItems({ payload }) {
  try {
    const response = yield call(getItemsRequest, payload);
    yield put(getItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemsError(error));
  }
}

function* getPagingItemOutHistory({ payload }) {
  try {
    const response = yield call(getPagingItemOutHistoryRequest, payload);
    yield put(getPagingItemOutHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemsError(error));
  }
}


function* addNewItem({ payload }) {
  try {
    yield call(addNewItemRequest, payload);
    const response = yield call(getItemsRequest, {});
    yield put(getItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemsError(error));
  }
}

function* updateItem({ payload }) {
  try {
    yield call(updateItemRequest, payload);
    const response = yield call(getItemsRequest, {});
    yield put(getItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemsError(error));
  }
}

function* getItemById({ payload }) {
  try {
    var response = yield call(getItemByIdRequest, payload);
    yield put(getItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemsError(error));
  }
}

function* deleteItem({ payload }) {
  try {
    yield call(deleteItemRequest, payload);
    const response = yield call(getItemsRequest, {});
    yield put(getItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_ITEMS, getItems);
  yield takeEvery(GET_PAGING_ITEM_OUT_HISTORY, getPagingItemOutHistory);
  yield takeEvery(ADD_NEW_ITEM, addNewItem);
  yield takeEvery(GET_ITEM_BY_ID, getItemById);
  yield takeEvery(UPDATE_ITEM, updateItem);
  yield takeEvery(DELETE_ITEM, deleteItem);
}

export default itemSaga;

export { getItemsRequest, getAllItemsRequest, getItemUploadTemplate, getItemToExcel, exportItemOutHistoryRequest };
