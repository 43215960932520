import {
  GET_TRANSPORTS,
  GET_TRANSPORTS_SUCCESS,
  GET_TRANSPORTS_FAIL,
  GET_TRANSPORT_BY_ID,
  GET_TRANSPORT_BY_ID_SUCCESS,
  ADD_NEW_TRANSPORT,
  UPDATE_TRANSPORT,
  DELETE_TRANSPORT,
  TRANSPORT_CLEAR_NOTIFY,
  ADD_NEW_TRANSPORT_SUCCESS,
  ADD_NEW_TRANSPORT_ERROR,
  UPDATE_TRANSPORT_SUCCESS,
  UPDATE_TRANSPORT_ERROR,
  DELETE_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_ERROR,
} from "./actionTypes";

// Transport
export const getTransports = query => ({
  type: GET_TRANSPORTS,
  payload: query,
});

export const getTransportsSuccess = items => ({
  type: GET_TRANSPORTS_SUCCESS,
  payload: items,
});

export const getTransportsFail = error => ({
  type: GET_TRANSPORTS_FAIL,
  payload: error,
});

export const getTransportById = id => ({
  type: GET_TRANSPORT_BY_ID,
  payload: id,
});

export const getTransportByIdSuccess = item => ({
  type: GET_TRANSPORT_BY_ID_SUCCESS,
  payload: item,
});

export const getTransportByIdFail = error => ({
  type: GET_TRANSPORT_BY_ID_FAIL,
  payload: error,
});

export const addNewTransport = item => ({
  type: ADD_NEW_TRANSPORT,
  payload: item,
});

export const addNewTransportSuccess = item => ({
  type: ADD_NEW_TRANSPORT_SUCCESS,
  payload: item,
});

export const addNewTransportError = item => ({
  type: ADD_NEW_TRANSPORT_ERROR,
  payload: item,
});

export const updateTransport = item => ({
  type: UPDATE_TRANSPORT,
  payload: item,
});

export const updateTransportSuccess = item => ({
  type: UPDATE_TRANSPORT_SUCCESS,
  payload: item,
});
export const updateTransportError = item => ({
  type: UPDATE_TRANSPORT_ERROR,
  payload: item,
});

export const deleteTransport = item => ({
  type: DELETE_TRANSPORT,
  payload: item,
});

export const deleteTransportSuccess = item => ({
  type: DELETE_TRANSPORT_SUCCESS,
  payload: item,
});

export const deleteTransportError = item => ({
  type: DELETE_TRANSPORT_ERROR,
  payload: item,
});

export const clearTransportNotify = () => ({
  type: TRANSPORT_CLEAR_NOTIFY,
});
