import CustomPagination from "components/Common/CustomPagination"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  savePackagingEntryForm,
  getPackagings,
  getPackagingById,
  updatePackaging,
  deletePackaging,
  clearPackagingError,
  getSewings
} from "store/actions"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap"
import ModalForm from "../../containers/packaging/ModalForm"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { toastMessage } from "helpers/utils"
import { debounce, sumBy } from "lodash"
import moment from "moment"
import CuttingSummaryCard from "../../containers/cutting/CuttingSummaryCard"
import PackagingModalForm from "../../containers/packaging/PackagingModalForm"

// Packaging
const PackagingItemTab = props => {
  const [ selectedId, setSelectedId ] = useState(null)
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ term, setTerm ] = useState("")

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false)
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const {
    getPackagings,
    getSewings,
    savePackagingEntryForm,
    getPackagingById,
    updatePackaging,
    deletePackaging,
    clearPackagingError,
    purchaseOrderId,
    sewing,
    packaging,
  } = props

  const {
    items,
    clothingSizes,
    purchaseOrderItems,
    item,
    message,
    itemCount,
    pageCount,
    loading,
    summary
  } = packaging
  
  const { summary: sewingSummary } = sewing;

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  useEffect(() => {
    getSewings({purchaseOrderId})
  }, [])

  useEffect(() => {
    
    getPackagings({ purchaseOrderId, term, page, pageSize })
  }, [ page, pageSize, term ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearPackagingError()
    }
  }, [ message ])

  const handleSubmit = items => {
    const data = {
      purchaseOrderId,
      items,
    }
    savePackagingEntryForm(data)
    setModalAddIsOpen(false)
  }

  const handleUpdate = data => {
    updatePackaging(data)
    setModalEditIsOpen(false)
  }

  const handleDelete = () => {
    deletePackaging({ purchaseOrderId, id: selectedId })
    setModalConfirmDeleteIsOpen(false)
  }

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target
                          debouncedQuickSearch(value)
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        { loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
            <>
              {
                  sewingSummary &&
                  <CuttingSummaryCard title={"Sewing Summary"} summary={ sewingSummary }/>
              }
              {
                  summary &&
                  <CuttingSummaryCard title={"Packaging Summary"} summary={ summary }/>
              }
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <CardTitle>Item Entries Detail</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                    >
                      <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered table-nowrap"
                      >
                        <thead>
                        <tr>
                          <th style={ { width: "80px" } }>#</th>
                          <th className="text-center">Item Code</th>
                          <th className="text-center">Size</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Created Date</th>
                          <th className="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        { items.map((item, index) => {
                          const { sewingEntries } = item
                          let num = (page - 1) * pageSize + (index + 1)

                          let total = sumBy(sewingEntries, sewingEntry => {
                            return sewingEntry.quantity
                          })

                          return (
                              <tr key={ index }>
                                <td style={ { textAlign: "right" } }>{ num }</td>
                                <td>
                                  { item.itemCode } - { item.itemName }
                                </td>
                                <td>{ item.clothingSizeName }</td>
                                <td className="text-end">{ item.quantity }</td>
                                <td className="text-end">
                                  { moment
                                      .utc(item.createdDate)
                                      .local()
                                      .format("DD-MMM-yyyy h:mm:ss a") }
                                </td>

                                <td>
                                  <div className="d-flex justify-content-center">
                                    <div className="d-flex gap-3">
                                      <Link className="text-primary" to="#">
                                        <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                            onClick={ () => {
                                              getPackagingById(item.id)
                                              setModalEditIsOpen(true)
                                            } }
                                        ></i>
                                      </Link>

                                      <Link className="text-danger" to="#">
                                        <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                            onClick={ () => {
                                              setSelectedId(item.id)
                                              setModalConfirmDeleteIsOpen(true)
                                            } }
                                        ></i>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                          )
                        }) }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <CustomPagination
                      itemCount={ itemCount }
                      currentPage={ page }
                      totalPage={ pageCount }
                      defaultPageSize={ pageSize }
                      pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                      onChangePage={ i => setPage(i) }
                      onPageSizeChange={ size => {
                        setPage(1)
                        setPageSize(size)
                      } }
                  />
                </CardBody>
              </Card>
            </>
        ) }

        <PackagingModalForm
            title="Add Packaging Item Entries"
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            clothingSizes={ clothingSizes }
            purchaseOrderItems={ purchaseOrderItems }
            purchaseOrderId={ purchaseOrderId }
            onSubmit={ handleSubmit }
        />
        
        { item ? (
            <ModalForm
                title="Edit Packaging Item"
                purchaseOrderId={ purchaseOrderId }
                item={ item }
                isOpen={ modalEditIsOpen }
                clothingSizes={ clothingSizes }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
      </div>
  )
}

PackagingItemTab.propTypes = {
  purchaseOrderId: PropTypes.string,
  packaging: PropTypes.object,
  sewing: PropTypes.object,
  purchaseOrder: PropTypes.object,
  stockAdjustmentStatus: PropTypes.string,
  getPackagings: PropTypes.func,
  getPackagingById: PropTypes.func,
  updatePackaging: PropTypes.func,
  deletePackaging: PropTypes.func,
  clearPackagingError: PropTypes.func,
  savePackagingEntryForm: PropTypes.func,
  getSewings: PropTypes.func,
}

const mapStateToProps = ({ sewing, packaging }) => {
  return {
    sewing,
    packaging,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPackagings,
      savePackagingEntryForm,
      getPackagingById,
      updatePackaging,
      deletePackaging,
      clearPackagingError,
      getSewings
    })(PackagingItemTab)
)
