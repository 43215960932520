import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERY_ORDER_ITEMS,
  ADD_NEW_DELIVERY_ORDER_ITEM,
  ADD_NEW_DELIVERY_ORDER_ITEMS,
  GET_DELIVERY_ORDER_ITEM_BY_ID,
  UPDATE_DELIVERY_ORDER_ITEM,
  DELETE_DELIVERY_ORDER_ITEM,
  DELETE_DELIVERY_ORDER_ITEMS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeliveryOrderItemsSuccess,
  getDeliveryOrderItemByIdSuccess,
  getDeliveryOrderItemsFail,
} from "./actions";
const getDeliveryOrderItemsRequest = query =>
  get("/api/DeliveryOrderItem/paging", { params: query });
const addNewDeliveryOrderItemRequest = role =>
  post("/api/DeliveryOrderItem", role);
const addNewDeliveryOrderItemsRequest = (deliveryOrderId, orderIds) =>
  update(`/api/DeliveryOrderItem/${deliveryOrderId}/bulkAddOrder`, orderIds);
const getDeliveryOrderItemByIdRequest = id =>
  get(`/api/DeliveryOrderItem/${id}`);
const updateDeliveryOrderItemRequest = role =>
  update("/api/DeliveryOrderItem", role);
const deleteDeliveryOrderItemRequest = id =>
  del(`/api/DeliveryOrderItem/${id}`);
const deleteDeliveryOrderItemsRequest = (deliveryOrderId, orderIds) =>
  update(`/api/DeliveryOrderItem/${deliveryOrderId}/bulkRemoveOrder`, orderIds);

function* fetchDeliveryOrderItems({ payload }) {
  try {
    const response = yield call(getDeliveryOrderItemsRequest, payload);
    yield put(getDeliveryOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrderItemsFail(error));
  }
}

function* addNewDeliveryOrderItem({ payload }) {
  try {
    yield call(addNewDeliveryOrderItemRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryOrderItemsRequest, { orderId });
    yield put(getDeliveryOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrderItemsFail(error));
  }
}

function* addNewDeliveryOrderItems({ payload }) {
  try {
    const { deliveryOrderId, orderIds } = payload;
    let response = yield call(
      addNewDeliveryOrderItemsRequest,
      deliveryOrderId,
      orderIds
    );
    response = yield call(getDeliveryOrderItemsRequest, {
      deliveryOrderId: response,
    });
    yield put(getDeliveryOrderItemsSuccess(response));
  } catch (err) {
    let error = err?.response?.data?.message ?? "Something has gone wrong.";
    if (error.includes("duplicate key")) {
      error = "Already exist in the list";
    }

    console.log(error);
    yield put(getDeliveryOrderItemsFail(error));

    // const error = err?.response?.data?.message ?? "Something has gone wrong.";
    // yield put(getDeliveryOrderItemsFail(error));
  }
}

function* updateDeliveryOrderItem({ payload }) {
  try {
    yield call(updateDeliveryOrderItemRequest, payload);
    const { orderId } = payload;
    const response = yield call(getDeliveryOrderItemsRequest, { orderId });
    yield put(getDeliveryOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrderItemsFail(error));
  }
}

function* getDeliveryOrderItemById({ payload }) {
  try {
    var response = yield call(getDeliveryOrderItemByIdRequest, payload);
    yield put(getDeliveryOrderItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrderItemsFail(error));
  }
}

function* deleteDeliveryOrderItem({ payload }) {
  try {
    const { id, orderId } = payload;
    yield call(deleteDeliveryOrderItemRequest, id);
    const response = yield call(getDeliveryOrderItemsRequest, { orderId });
    yield put(getDeliveryOrderItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrderItemsFail(error));
  }
}

function* deleteDeliveryOrderItems({ payload }) {
  try {
    const { deliveryOrderId, orderIds } = payload;
    let response = yield call(
      deleteDeliveryOrderItemsRequest,
      deliveryOrderId,
      orderIds
    );

    response = yield call(getDeliveryOrderItemsRequest, {
      deliveryOrderId: response,
    });
    yield put(getDeliveryOrderItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrderItemsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_DELIVERY_ORDER_ITEMS, fetchDeliveryOrderItems);
  yield takeEvery(ADD_NEW_DELIVERY_ORDER_ITEM, addNewDeliveryOrderItem);
  yield takeEvery(ADD_NEW_DELIVERY_ORDER_ITEMS, addNewDeliveryOrderItems);
  yield takeEvery(GET_DELIVERY_ORDER_ITEM_BY_ID, getDeliveryOrderItemById);
  yield takeEvery(UPDATE_DELIVERY_ORDER_ITEM, updateDeliveryOrderItem);
  yield takeEvery(DELETE_DELIVERY_ORDER_ITEM, deleteDeliveryOrderItem);
  yield takeEvery(DELETE_DELIVERY_ORDER_ITEMS, deleteDeliveryOrderItems);
}

export default roleSaga;
