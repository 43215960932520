import { SEVERITY } from "helpers/utils.js";
import {
  GET_STOCK_REQUEST_ITEMS,
  GET_STOCK_REQUEST_ITEMS_SUCCESS,
  GET_STOCK_REQUEST_ITEM_BY_ID_SUCCESS,
  GET_STOCK_REQUEST_ITEMS_ERROR,
  STOCK_REQUEST_ITEM_CLEAR_ERROR,
  GET_STOCK_REQUEST_ITEM_BY_ID,
  ADD_NEW_STOCK_REQUEST_ITEM,
  UPDATE_STOCK_REQUEST_ITEM,
  DELETE_STOCK_REQUEST_ITEM, 
  ADD_NEW_BULK_STOCK_REQUEST_ITEM,
} from "./actionTypes.js"
const initState = {
  items: [],
  item: null,
  clothingSizes: [],
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const stockRequestItem = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_STOCK_REQUEST_ITEM:
    case ADD_NEW_BULK_STOCK_REQUEST_ITEM:
    case UPDATE_STOCK_REQUEST_ITEM:
    case GET_STOCK_REQUEST_ITEMS:
      return {
        ...state,
        loading: true,
        item: null,
      };
    case GET_STOCK_REQUEST_ITEMS_SUCCESS:
      const { itemCount, pageCount, data, clothingSizes } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        clothingSizes,
        items: data,
        item: null,
        loading: false,
      };
    case ADD_NEW_STOCK_REQUEST_ITEM:
      return {
        ...state,
        item: null,
      };
    case GET_STOCK_REQUEST_ITEM_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_STOCK_REQUEST_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_STOCK_REQUEST_ITEMS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case STOCK_REQUEST_ITEM_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default stockRequestItem;
