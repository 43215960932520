import {
  GET_DELIVERER,
  GET_DELIVERER_SUCCESS,
  GET_DELIVERER_FAIL,
  GET_DELIVERER_BY_ID,
  GET_DELIVERER_BY_ID_SUCCESS,
  ADD_NEW_DELIVERER,
  ADD_NEW_DELIVERER_SUCCESS,
  UPDATE_DELIVERER,
  UPDATE_DELIVERER_SUCCESS,
  DELETE_DELIVERER,
  DELETE_DELIVERER_SUCCESS,
  DELIVERER_CLEAR_NOTIFY,
} from "./actionTypes";

// Deliverer
export const getDeliverer = id => ({
  type: GET_DELIVERER,
  payload: id,
});

export const getDelivererSuccess = items => ({
  type: GET_DELIVERER_SUCCESS,
  payload: items,
});

export const getDelivererFail = error => ({
  type: GET_DELIVERER_FAIL,
  payload: error,
});

export const getDelivererById = data => ({
  type: GET_DELIVERER_BY_ID,
  payload: data,
});

export const getDelivererByIdSuccess = item => ({
  type: GET_DELIVERER_BY_ID_SUCCESS,
  payload: item,
});

export const getDelivererByIdFail = error => ({
  type: GET_DELIVERER_BY_ID_FAIL,
  payload: error,
});

export const addNewDeliverer = payload => ({
  type: ADD_NEW_DELIVERER,
  payload,
});

export const addNewDelivererSuccess = payload => ({
  type: ADD_NEW_DELIVERER_SUCCESS,
  payload,
});

export const updateDeliverer = payload => ({
  type: UPDATE_DELIVERER,
  payload,
});

export const updateDelivererSuccess = payload => ({
  type: UPDATE_DELIVERER_SUCCESS,
  payload,
});

export const deleteDeliverer = payload => ({
  type: DELETE_DELIVERER,
  payload,
});

export const deleteDelivererSuccess = payload => ({
  type: DELETE_DELIVERER_SUCCESS,
  payload,
});

export const clearDelivererNotify = () => ({
  type: DELIVERER_CLEAR_NOTIFY,
});
