import { call, put, takeEvery } from "redux-saga/effects";

import { GET_INVOICE_PAYMENT_HISTORY } from "./actionTypes";

import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getInvoicePaymentHistorySuccess,
  getInvoicePaymentHistoryFail,
} from "./actions";

const getInvoicePaymentHistoryRequest = query =>
  post(`/api/InvoicePayment/paging`, query);

function* getPaymentHistory({ payload }) {
  try {
    const response = yield call(getInvoicePaymentHistoryRequest, payload);
    yield put(getInvoicePaymentHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicePaymentHistoryFail(error));
  }
}

function* InvoicePaymentHistorySaga() {
  yield takeEvery(GET_INVOICE_PAYMENT_HISTORY, getPaymentHistory);
}

export default InvoicePaymentHistorySaga;
