import { get, post, del, put as update } from "helpers/api_helper";

const createRequest = async data => {
  return await post("/api/category", data);
};
const getRequest = async () => {
  return await get("/api/category");
};
const getByIdRequest = async id => {
  return await get(`/api/category/${id}`);
};

export const categoryService = {
  createRequest,
  getRequest,
  getByIdRequest,
};
