import React from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/prints.css'
import NumberFormat from "react-number-format"
import moment from "moment"

const ProductStockInDetailToPrint = React.forwardRef((props, ref) => {
  const { item } = props
  const { productStockInProducts: products } = item

  return (<div ref={ ref }>
    <div className="print-container">
      <div className="print-content">
        <div className="header-section">
          <h2 className="header-title">Product Stock In</h2>
          <div className="header-section1">
            <div>
              <p>Code: <strong>#{ item.code }</strong></p>
              <p>Title: <strong>{ item.name }</strong></p>
            </div>
            <div>
              <p>Date: <strong>{moment(item.date)
                  .local()
                  .format("DD-MMM-YYYY")}</strong></p>
            </div>
          </div>
        </div>
        <table className="table-print">
          <thead>
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Name</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          {
            products.map((product, index) => {
                    let totalQuantity =
                        product.quantity * product.unitOfMeasurementConversionRate;
                    
              return <tr key={ index }>
                <td className={ "text-right" }>{ index + 1 }</td>
                <td>{ product.productCode }</td>
                <td>{ product.productName }</td>
                <td className={ "text-right" }>
                  <NumberFormat
                      value={ product.quantity }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                  />
                </td>
                <td>{ product.unitOfMeasurementName }</td>
                <td className={ "text-right" }>
                  <NumberFormat
                      value={ totalQuantity ?? 0 }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                  />
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
        <div className="footer-section">
          <div>
            <p>Requested By: </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/ }
          </div>
          <div>
            <p>Approved By: </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.approvedByUser}</strong></p>*/ }
          </div>
        </div>
      </div>
    </div>
  </div>)
})

ProductStockInDetailToPrint.displayName = 'ProductStockInDetailToPrint'

ProductStockInDetailToPrint.propTypes = {
  item: PropTypes.object,
}

export default ProductStockInDetailToPrint