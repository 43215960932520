import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getPurchaseOrderMaterial } from "../../store/purchase-order/actions"
import NumberFormat from "react-number-format"

const MaterialListTab = props => {
  const { purchaseOrderId, purchaseOrder } = props

  const { loading, material } = purchaseOrder
  let totalCutting = 0
  let totalMeter = 0

  return (
      <>
        { loading ? <div className={ "loading" }></div>:
            <>
              <Card className={ "mb-2" }>
                <CardHeader className="bg-transparent border-bottom">
                  <CardTitle>Fabric Item Detail</CardTitle>
                </CardHeader>
                <CardBody>

                  <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered table-nowrap"
                  >
                    <thead>
                    <tr>
                      <th style={ { width: "80px" } }>#</th>
                      <th className="text-center">Item Code</th>
                      { material && material.clothingSizes.map((clothingSize, index) => {
                        return (
                            <th key={ index } className="text-center">
                              { clothingSize.name }
                            </th>
                        )
                      }) }

                      <th className="text-center">Total Cutting</th>
                      <th className="text-center">Total Meter</th>
                      <th className="text-center">Total Base on UOM</th>
                    </tr>
                    </thead>
                    <tbody>
                    { material && material.materialItems.map((item, index) => {
                      totalCutting += item.totalCutting
                      totalMeter += item.totalMeter
                      return <tr key={ index }>
                        <td>{ index + 1 }</td>
                        <td>{ item.itemCode } - { item.itemName }</td>
                        { material && material.clothingSizes.map((clothingSize, index) => {

                          let materialItemEntry = item.materialItemEntries.find(e => e.clothingSizeId === clothingSize.id)

                          if (materialItemEntry) {
                            return (
                                <td key={ index } className="text-end">
                                  { materialItemEntry.quantity }
                                </td>
                            )
                          } else {
                            return (
                                <td key={ index } className="text-center">
                                  -
                                </td>
                            )
                          }

                        }) }

                        <td className={ "text-end" }>{ item.totalCutting }</td>
                        <td className={ "text-end" }>{ item.totalMeter }</td>
                        <td>
                          <ul className="mb-0">
                            { Object.keys(item.totalUom).map((key, index) => {

                              return (
                                  <li key={ index }>
                                    <div className={ "d-flex justify-content-between" }>
                                      <span className={ "me-3" }>{ key } :</span>
                                      <NumberFormat
                                          className={ "fw-bolder" }
                                          value={ item.totalUom[key] }
                                          displayType="text"
                                          thousandSeparator={ true }
                                          fixedDecimalScale={ false }
                                      />
                                    </div>

                                  </li>
                              )
                            }) }
                          </ul>
                        </td>
                      </tr>
                    })
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                      <th colSpan={ 2 }>Total</th>
                      { material && material.clothingSizes.map((clothingSize, index) => {
                            let totalQuantity = material.materialItems.reduce((total, item) => {
                              let materialItemEntry = item.materialItemEntries.find(e => e.clothingSizeId === clothingSize.id)
                              if (materialItemEntry) {
                                return total + materialItemEntry.quantity
                              } else {
                                return total
                              }
                            }, 0)
                            return (
                                <th key={ index } className="text-end">
                                  { totalQuantity }
                                </th>
                            )
                          }
                      ) }
                      <th className="text-end">{ totalCutting }</th>
                      <th className="text-end">{ totalMeter }</th>
                    </tr>
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
              <Card className={ "mb-2" }>
                <CardHeader className="bg-transparent border-bottom">
                  <CardTitle>Accessory Item Detail</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered table-nowrap"
                  >
                    <thead>
                    <tr>
                      <th style={ { width: "80px" } }>#</th>
                      <th className="text-center">Item Code</th>
                      <th className="text-center">Item Description</th>
                      <th className="text-center">Total Base on UOM</th>
                    </tr> 
                    </thead>
                    <tbody>
                    {
                      material?.materialAccessories?.map((acessory,index) =>{
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{acessory.code}</td>
                            <td>{acessory.name}</td>
                            <td>
                              <ul className="mb-0">
                                { Object.keys(acessory.totalUom).map((key, index) => {

                                  return (
                                      <li key={ index }>
                                        <div className={ "d-flex justify-content-between" }>
                                          <span className={ "me-3" }>{ key } :</span>
                                          <NumberFormat
                                              className={ "fw-bolder" }
                                              value={ acessory.totalUom[key] }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </div>

                                      </li>
                                  )
                                }) }
                              </ul>
                            </td>
                            <td></td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>  
                </CardBody>
              </Card>
            </>
        }

        
      </>
  )
}

MaterialListTab.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  purchaseOrder: PropTypes.object,
  getPurchaseOrderMaterial: PropTypes.func,
}


const mapStateToProps = ({ purchaseOrder }) => {
  return {
    purchaseOrder,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPurchaseOrderMaterial,

    })(MaterialListTab)
)
