import CustomPagination from "components/Common/CustomPagination"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Tbody, Thead, Tr } from "react-super-responsive-table"
import {
  getStockInItems,
  addNewStockInItem,
  getStockInItemById,
  updateStockInItem,
  deleteStockInItem,
  clearStockInItemError,
  getConfirmStockIn,
  clearStockInError,
} from "store/actions"
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
} from "reactstrap"
import ModalForm from "containers/stock-in-item/ModalForm"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { timestampFileName, toastMessage } from "helpers/utils"
import NumberFormat from "react-number-format"
import { debounce } from "lodash"
import StockInConfirmModal from "containers/stock-in/StockInConfirmModal"
import {
  getStockInUploadTemplate,
  getStockInItemToExcel,
} from "store/stock-in/saga"
import UploadModalForm from "containers/stock-in/UploadModalForm"

// StockInItem
const StockInItemTab = props => {
  const [ selectedId, setSelectedId ] = useState(null)
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ term, setTerm ] = useState("")
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false)

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false)
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ modalUpload, setModalUpload ] = useState(false)
  const {
    getStockInItems,
    addNewStockInItem,
    getStockInItemById,
    updateStockInItem,
    deleteStockInItem,
    clearStockInItemError,
    getConfirmStockIn,
    stockInId,
    stockInItem,
    stockIn,
    stockInStatus,
  } = props

  const { items, item, message, itemCount, pageCount, loading } = stockInItem

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  useEffect(() => {
  }, [])

  useEffect(() => {
    getStockInItems({ stockInId, term, page, pageSize })
  }, [ page, pageSize, term ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearStockInItemError()
    }
  }, [ message ])

  useEffect(() => {
    if (stockIn.message) {
      toastMessage(stockIn.message)
      clearStockInError()
    }
  }, [ stockIn.message ])

  const handleSubmit = data => {
    addNewStockInItem(data)
    setModalAddIsOpen(false)
  }

  const handleUpdate = data => {
    updateStockInItem(data)
    setModalEditIsOpen(false)
  }

  const handleDelete = () => {
    deleteStockInItem({ stockInId, id: selectedId })
    setModalConfirmDeleteIsOpen(false)
  }

  const handleSubmitStockConfirmation = () => {
    if (stockInId) {
      getConfirmStockIn({ stockInId, type: "Tab" })
      setModalConfirmStock(false)
    }
  }

  const handleSubmitUpload = () => {
    setModalUpload(false)
    getStockInItems({ stockInId, term, page, pageSize })
  }

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target
                          debouncedQuickSearch(value)
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  { stockInStatus === 0 && // 0 is Draft
                      <>
                        { items && items.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                outline
                                className="me-1"
                                onClick={ () => setModalConfirmStock(true) }
                            >
                              <i className="fas fa-check"/> Confirm into Stock
                            </Button>
                        ) }

                        <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={ () => setModalAddIsOpen(true) }
                        >
                          <i className="fas fa-plus"/> Add New
                        </Button>
                      </>
                  }

                  <ButtonDropdown
                      isOpen={ moreActionIsOpen }
                      toggle={ () => setMoreActionIsOpen(!moreActionIsOpen) }
                  >
                    <DropdownToggle caret color="primary" outline>
                      <i className="mdi mdi-dots-vertical"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      { stockInStatus === 0 &&
                          <>
                            <DropdownItem
                                onClick={ () => {
                                  getStockInUploadTemplate(
                                      `${ timestampFileName() }_Stock_In_items_Upload_Template.xlsx`
                                  )
                                } }
                                className="text-primary"
                            >
                              <i className="fas fa-file-download me-1"/> Download
                              Import Template
                            </DropdownItem>
                            <DropdownItem
                                onClick={ () => setModalUpload(true) }
                                className="text-primary"
                            >
                              <i className="fas fa-file-import me-1"/> Import From
                              Excel
                            </DropdownItem>
                          </>
                      }
                      <DropdownItem
                          onClick={ () => {
                            getStockInItemToExcel(
                                stockInId,
                                `${ timestampFileName() }_Stock_In_items.xlsx`
                            )
                          } }
                          className="text-primary"
                      >
                        <i className="fas fa-file-export me-1"/> Export to
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              { loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={ { width: "80px" } }>#</th>
                        <th className="text-center">Item Code</th>
                        <th className="text-center">Item Name</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">UoM</th>
                        <th className="text-center">Total</th>
                        <th className="text-center" style={ { width: "120px" } }>
                          Action
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      { items.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1)
                        let totalQuantity =
                            item.quantity * item.unitOfMeasurementConversionRate
                        return (
                            <tr key={ index }>
                              <td style={ { textAlign: "right" } }>{ num }</td>
                              <td className="text-end">{ item.itemCode }</td>
                              <td>{ item.itemName }</td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.quantity }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td>{ item.unitOfMeasurementName }</td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ totalQuantity ?? 0 }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td>
                                { stockInStatus !== 1 && ( // 1 is Confirmed
                                    <div className="d-flex gap-3">
                                      <Link className="text-primary" to="#">
                                        <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                            onClick={ () => {
                                              getStockInItemById(item.id)
                                              setModalEditIsOpen(true)
                                            } }
                                        ></i>
                                      </Link>

                                      <Link className="text-danger" to="#">
                                        <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                            onClick={ () => {
                                              setSelectedId(item.id)
                                              setModalConfirmDeleteIsOpen(true)
                                            } }
                                        ></i>
                                      </Link>
                                    </div>
                                ) }
                              </td>
                            </tr>
                        )
                      }) }
                      </tbody>
                    </Table>
                  </div>
              ) }
            </div>
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ pageCount }
                defaultPageSize={ pageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => setPage(i) }
                onPageSizeChange={ size => {
                  setPage(1)
                  setPageSize(size)
                } }
            />
          </CardBody>
        </Card>
        <ModalForm
            title="Add Unit of Measurement"
            isOpen={ modalAddIsOpen }
            stockInId={ stockInId }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            onSubmit={ handleSubmit }
        />
        { item ? (
            <ModalForm
                title="Edit Unit of Measurement"
                stockInId={ stockInId }
                item={ item }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
        <StockInConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />
        <UploadModalForm
            title="Import Items"
            stockInId={ stockInId }
            isOpen={ modalUpload }
            toggle={ () => setModalUpload(!modalUpload) }
            onSubmit={ handleSubmitUpload }
        />
      </div>
  )
}

StockInItemTab.propTypes = {
  stockInId: PropTypes.string,
  stockInItem: PropTypes.object,
  stockIn: PropTypes.object,
  stockInStatus: PropTypes.number,
  getStockInItems: PropTypes.func,
  addNewStockInItem: PropTypes.func,
  getStockInItemById: PropTypes.func,
  updateStockInItem: PropTypes.func,
  deleteStockInItem: PropTypes.func,
  clearStockInItemError: PropTypes.func,
  getConfirmStockIn: PropTypes.func,
  clearStockInError: PropTypes.func,
}

const mapStateToProps = ({ stockInItem, stockIn }) => {
  return {
    stockInItem,
    stockIn,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getStockInItems,
      addNewStockInItem,
      getStockInItemById,
      updateStockInItem,
      deleteStockInItem,
      clearStockInItemError,
      getConfirmStockIn,
      clearStockInError,
    })(StockInItemTab)
)
