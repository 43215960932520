// PACKAGING
export const GET_PACKAGINGS = "GET_PACKAGINGS";
export const GET_PACKAGINGS_SUCCESS = "GET_PACKAGINGS_SUCCESS";
export const GET_PACKAGINGS_ERROR = "GET_PACKAGINGS_ERROR";

export const GET_PACKAGING_BY_ID = "GET_PACKAGING_BY_ID";
export const GET_PACKAGING_BY_ID_SUCCESS = "GET_PACKAGING_BY_ID_SUCCESS";

export const ADD_NEW_PACKAGING = "ADD_NEW_PACKAGING";
export const ADD_NEW_PACKAGING_SUCCESS = "ADD_NEW_PACKAGING_SUCCESS";
export const ADD_NEW_PACKAGING_ERROR = "ADD_NEW_PACKAGING_ERROR";

export const UPDATE_PACKAGING = "UPDATE_PACKAGING";
export const UPDATE_PACKAGING_SUCCESS = "UPDATE_PACKAGING_SUCCESS";
export const UPDATE_PACKAGING_ERROR = "UPDATE_PACKAGING_ERROR";

export const DELETE_PACKAGING = "DELETE_PACKAGING";
export const DELETE_PACKAGING_SUCCESS = "DELETE_PACKAGING_SUCCESS";

export const PACKAGING_CLEAR_ERROR = "PACKAGING_CLEAR_ERROR";

export const GET_PACKAGING_ENTRY_FORM = "GET_PACKAGING_ENTRY_FORM";
export const GET_PACKAGING_ENTRY_FORM_SUCCESS = "GET_PACKAGING_ENTRY_FORM_SUCCESS";

export const SAVE_PACKAGING_ENTRY_FORM = "SAVE_PACKAGING_ENTRY_FORM";
export const SAVE_PACKAGING_ENTRY_FORM_SUCCESS = "SAVE_PACKAGING_ENTRY_FORM_SUCCESS";
