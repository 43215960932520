import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERY_AGENCY,
  ADD_NEW_DELIVERY_AGENCY,
  GET_DELIVERY_AGENCY_BY_ID,
  UPDATE_DELIVERY_AGENCY,
  DELETE_DELIVERY_AGENCY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeliveryAgencySuccess,
  getDeliveryAgencyByIdSuccess,
  getDeliveryAgencyFail,
  addNewDeliveryAgencySuccess,
  updateDeliveryAgencySuccess,
  deleteDeliveryAgencySuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/DeliveryAgency`);
const getDeliveryAgencyRequest = query =>
  get(`/api/DeliveryAgency/paging`, { params: query });

const addNewDeliveryAgencyRequest = item => post(`/api/DeliveryAgency`, item);
const getDeliveryAgencyByIdRequest = id => get(`/api/DeliveryAgency/${id}`);
const updateDeliveryAgencyRequest = item => update(`/api/DeliveryAgency`, item);
const deleteDeliveryAgencyRequest = id => del(`/api/DeliveryAgency/${id}`);

function* fetchDeliveryAgencys({ payload }) {
  try {
    const response = yield call(getDeliveryAgencyRequest, payload);

    yield put(getDeliveryAgencySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryAgencyFail(error));
  }
}

function* addNewDeliveryAgency({ payload }) {
  try {
    yield call(addNewDeliveryAgencyRequest, payload);
    const response = yield call(getDeliveryAgencyRequest);
    yield put(getDeliveryAgencySuccess(response));
    yield put(addNewDeliveryAgencySuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryAgencyFail(error));
  }
}

function* updateDeliveryAgency({ payload }) {
  try {
    yield call(updateDeliveryAgencyRequest, payload);
    const response = yield call(getDeliveryAgencyRequest);
    yield put(getDeliveryAgencySuccess(response));
    yield put(updateDeliveryAgencySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryAgencyFail(error));
  }
}

function* getDeliveryAgencyById({ payload }) {
  try {
    var response = yield call(getDeliveryAgencyByIdRequest, payload);
    yield put(getDeliveryAgencyByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryAgencyFail(error));
  }
}

function* deleteDeliveryAgency({ payload }) {
  try {
    yield call(deleteDeliveryAgencyRequest, payload);
    const response = yield call(getDeliveryAgencyRequest);
    yield put(getDeliveryAgencySuccess(response));
    yield put(deleteDeliveryAgencySuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryAgencyFail(error));
  }
}

function* DeliveryAgencySaga() {
  yield takeEvery(GET_DELIVERY_AGENCY, fetchDeliveryAgencys);
  yield takeEvery(ADD_NEW_DELIVERY_AGENCY, addNewDeliveryAgency);
  yield takeEvery(GET_DELIVERY_AGENCY_BY_ID, getDeliveryAgencyById);
  yield takeEvery(UPDATE_DELIVERY_AGENCY, updateDeliveryAgency);
  yield takeEvery(DELETE_DELIVERY_AGENCY, deleteDeliveryAgency);
}

export default DeliveryAgencySaga;

export { getDeliveryAgenciesRequest };
