import {
  GET_SETTING,
  GET_SETTING_SUCCESS,
  GET_SETTING_FAIL,
  GET_SETTING_BY_ID,
  GET_SETTING_BY_ID_SUCCESS,
  ADD_NEW_SETTING,
  UPDATE_SETTING,
  DELETE_SETTING,
  SETTING_CLEAR_ERROR,
  GET_SETTING_DEFAULT,
  GET_SETTING_DEFAULT_ERROR,
  GET_SETTING_DEFAULT_SUCCESS,
  ADD_NEW_SETTING_SUCCESS,
  UPDATE_SETTING_SUCCESS,
  SETTING_CLEAR_NOTIFY,
} from "./actionTypes";

//Setting
export const getSetting = id => ({
  type: GET_SETTING,
  payload: id,
});

export const getSettingSuccess = item => ({
  type: GET_SETTING_SUCCESS,
  payload: item,
});

export const getSettingFail = error => ({
  type: GET_SETTING_FAIL,
  payload: error,
});

export const getSettingById = data => ({
  type: GET_SETTING_BY_ID,
  payload: data,
});

export const getSettingByIdSuccess = data => ({
  type: GET_SETTING_BY_ID_SUCCESS,
  payload: data,
});

export const getSettingByIdFail = error => ({
  type: GET_SETTING_BY_ID_FAIL,
  payload: error,
});

export const getDefaultSetting = data => ({
  type: GET_SETTING_DEFAULT,
  payload: data,
});

export const getDefaultSettingSuccess = data => ({
  type: GET_SETTING_DEFAULT_SUCCESS,
  payload: data,
});

export const getDefaultSettingFail = error => ({
  type: GET_SETTING_DEFAULT_ERROR,
  payload: error,
});

export const addNewSetting = Setting => ({
  type: ADD_NEW_SETTING,
  payload: Setting,
});

export const addNewSettingSuccess = payload => ({
  type: ADD_NEW_SETTING_SUCCESS,
  payload,
});

export const updateSetting = payload => ({
  type: UPDATE_SETTING,
  payload,
});

export const updateSettingSuccess = payload => ({
  type: UPDATE_SETTING_SUCCESS,
  payload,
});

export const deleteSetting = Setting => ({
  type: DELETE_SETTING,
  payload: Setting,
});

export const clearSettingNotify = () => ({
  type: SETTING_CLEAR_NOTIFY,
});
