import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Table } from "reactstrap"
import { Tbody } from "react-super-responsive-table"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"

const StockOutValidateResultModal = ({ isOpen, toggle, title, items }) => {
  return (
      <Modal size="lg" isOpen={isOpen} scrollable toggle={toggle} backdrop="static">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
          >
            <Table
                id="tech-companies-1"
                className="table table-striped table-bordered table-nowrap"
            >
              <thead>
              <tr>
                <th style={ { width: "80px" } }>#</th>
                <th className="text-center">Item Code</th>
                <th className="text-center">Item Name</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">UoM</th>
                <th className="text-center">Total</th>
                <th className="text-center">Exceed</th>

              </tr>
              </thead>
              <Tbody>
                { items.map((item, index) => {
                  // let num = (page - 1) * pageSize + (index + 1)
                  const { valid } = item;
                  let totalQuantity =
                      item.quantity * item.unitOfMeasurementConversionRate;
                  return (
                      <tr key={ index }>
                        <td className={ !valid ? 'text-danger fw-bolder' : '' } style={ { textAlign: "right" } }>{ index+1 }</td>
                        <td className={ !valid ? 'text-danger fw-bolder' : '' }>{ item.itemCode }</td>
                        <td className={ !valid ? 'text-danger fw-bolder' : '' }>{ item.itemName }</td>
                        <td className={ !valid ? 'text-danger fw-bolder text-end' : ' text-end' }>
                          <NumberFormat
                              value={ item.quantity }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                          />
                        </td>
                        <td className={ !valid ? 'text-danger fw-bolder' : '' }>{ item.unitOfMeasurementName }</td>
                        <td className={ !valid ? 'text-danger fw-bolder text-end' : ' text-end' }>
                          <NumberFormat
                              value={ totalQuantity ?? 0 }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                          />
                        </td>
                        <td className={ !valid ? 'text-danger fw-bolder text-end' : ' text-end' }>
                          <NumberFormat
                              value={ item.exceed }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                          />
                        </td>
                      </tr>
                  )
                }) }
              </Tbody>
            </Table>
          </div>
        </div>
        <div className="modal-footer">
          <button
              type="button"
              onClick={toggle}
              className="btn btn-primary"
              data-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </Modal>
  )
}

StockOutValidateResultModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  items: PropTypes.array,
}

export default StockOutValidateResultModal