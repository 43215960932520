// PURCHASE_ORDER
export const GET_PURCHASE_ORDERS = "GET_PURCHASE_ORDERS";
export const GET_PURCHASE_ORDERS_SUCCESS = "GET_PURCHASE_ORDERS_SUCCESS";
export const GET_PURCHASE_ORDERS_ERROR = "GET_PURCHASE_ORDERS_ERROR";

export const GET_PURCHASE_ORDER_BY_ID = "GET_PURCHASE_ORDER_BY_ID";
export const GET_PURCHASE_ORDER_BY_ID_SUCCESS =
  "GET_PURCHASE_ORDER_BY_ID_SUCCESS";

export const GET_PURCHASE_ORDER_MATERIAL = "GET_PURCHASE_ORDER_MATERIAL";
export const GET_PURCHASE_ORDER_MATERIAL_SUCCESS =
    "GET_PURCHASE_ORDER_MATERIAL_SUCCESS";

export const ADD_NEW_PURCHASE_ORDER = "ADD_NEW_PURCHASE_ORDER";
export const ADD_NEW_PURCHASE_ORDER_SUCCESS = "ADD_NEW_PURCHASE_ORDER_SUCCESS";
export const ADD_NEW_PURCHASE_ORDER_ERROR = "ADD_NEW_PURCHASE_ORDER_ERROR";

export const UPDATE_PURCHASE_ORDER = "UPDATE_PURCHASE_ORDER";
export const UPDATE_PURCHASE_ORDER_SUCCESS = "UPDATE_PURCHASE_ORDER_SUCCESS";
export const UPDATE_PURCHASE_ORDER_ERROR = "UPDATE_PURCHASE_ORDER_ERROR";

export const DELETE_PURCHASE_ORDER = "DELETE_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS";

export const GET_CONFIRM_PURCHASE_ORDER = "GET_CONFIRM_PURCHASE_ORDER";
export const GET_CONFIRM_PURCHASE_ORDER_SUCCESS =
  "GET_CONFIRM_PURCHASE_ORDER_SUCCESS";

export const PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN = "PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN";
export const PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN_SUCCESS = "PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN_SUCCESS";

export const PURCHASE_ORDER_CLEAR_ERROR = "PURCHASE_ORDER_CLEAR_ERROR";

export const GET_PURCHASE_ORDER_SUMMARY = "GET_PURCHASE_ORDER_SUMMARY";
export const GET_PURCHASE_ORDER_SUMMARY_SUCCESS = "GET_PURCHASE_ORDER_SUMMARY_SUCCESS";
