export const GET_ORDER_NOTE = "GET_ORDER_NOTE";
export const GET_ORDER_NOTE_SUCCESS = "GET_ORDER_NOTE_SUCCESS";
export const GET_ORDER_NOTE_FAIL = "GET_ORDER_NOTE_FAIL";

export const GET_ORDER_NOTE_BY_ID = "GET_ORDER_NOTE_BY_ID";
export const GET_ORDER_NOTE_BY_ID_SUCCESS = "GET_ORDER_NOTE_BY_ID_SUCCESS";

export const ADD_NEW_ORDER_NOTE = "ADD_NEW_ORDER_NOTE";
export const ADD_NEW_ORDER_NOTE_SUCCESS = "ADD_NEW_ORDER_NOTE_SUCCESS";

export const UPDATE_ORDER_NOTE = "UPDATE_ORDER_NOTE";
export const UPDATE_ORDER_NOTE_SUCCESS = "UPDATE_ORDER_NOTE_SUCCESS";

export const DELETE_ORDER_NOTE = "DELETE_ORDER_NOTE";
export const DELETE_ORDER_NOTE_SUCCESS = "DELETE_ORDER_NOTE_SUCCESS";

export const ORDER_NOTE_CLEAR_NOTIFY = "ORDER_NOTE_CLEAR_NOTIFY";


