import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  // StockLocation
  getStockLocations,
  addNewStockLocation,
  getStockLocationById,
  updateStockLocation,
  deleteStockLocation,
  clearStockLocationError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ModalForm from "containers/stock-location/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";
import NumberFormat from "react-number-format"

const StockLocationTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
      useState(false);

  const {
    getStockLocations,
    addNewStockLocation,
    getStockLocationById,
    updateStockLocation,
    deleteStockLocation,
    clearStockLocationError,
    items,
    item,
    totalOnHand,
    totalOutgoing,
    message,
    itemCount,
    pageCount,
    loading,
    productId,
  } = props;

  useEffect(() => {
    console.log(productId);
    getStockLocations({ productId });
  }, []);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearStockLocationError();
    }
  }, [message]);

  const handleSubmit = data => {
    const dataSubmit = {
      ...data,
      productId,
    };

    addNewStockLocation(dataSubmit);
    setModalAddIsOpen(false);
  };

  const handleUpdate = data => {
    const dataSubmit = {
      ...data,
      productId,
    };
    updateStockLocation(dataSubmit);
    setModalEditIsOpen(false);
  };

  const handleDelete = () => {
    deleteStockLocation({ productId, id: selectedId });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={e => {
                          const { value } = e.target;
                          //   debouncedQuickSearch(value);
                        }}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={10}>
                {/*<div className="text-sm-end">*/}
                {/*  <Button*/}
                {/*      type="button"*/}
                {/*      color="primary"*/}
                {/*      className="me-1"*/}
                {/*      onClick={() => setModalAddIsOpen(true)}*/}
                {/*  >*/}
                {/*    <i className="fas fa-plus" /> Add New*/}
                {/*  </Button>*/}
                {/*</div>*/}
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th className={"text-center"} style={{ width: "80px" }}>#</th>
                        <th className="text-center">Location</th>
                        <th className="text-center">On Hand</th>
                        <th className="text-center">Outgoing</th>
                        <th className="text-center">Display Order</th>
                        <th>Note</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      {items.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1);
                        return (
                            <tr key={index}>
                              <td style={{ textAlign: "right" }}>{num}</td>
                              <td>{item.locationName}</td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.onHand }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.outgoing }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className={"text-end"}>
                                {item.displayOrder}
                              </td>
                              <td>
                                {item.note}
                              </td>
                              <td>
                                <div className="d-flex gap-3">
                                  <Link className="text-primary" to="#">
                                    <i
                                        className="mdi mdi-pencil font-size-18"
                                        id="edittooltip"
                                        onClick={() => {
                                          getStockLocationById(item.id);
                                          setModalEditIsOpen(true);
                                        }}
                                    ></i>
                                  </Link>

                                  <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => {
                                          setSelectedId(item.id);
                                          setModalConfirmDeleteIsOpen(true);
                                        }}
                                    ></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                      <tfoot>
                      <tr>
                        <th className={"text-end"} colSpan={2}>Total</th>
                        <th className={"text-end"}>
                          <NumberFormat
                              value={ totalOnHand }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                          />
                        
                        </th>
                        <th className={"text-end"}>
                          <NumberFormat
                              value={ totalOutgoing }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                          />
                        </th>
                        <th colSpan={3}></th>
                      </tr>
                      </tfoot>
                    </Table>
                  </div>
              )}
            </div>
            <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                // onChangePage={i => onChangePage(i)}
                // onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
        <ModalForm
            title="Add Unit of Measurement"
            isOpen={modalAddIsOpen}
            toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
            onSubmit={handleSubmit}
        />
        {item ? (
            <ModalForm
                title="Edit Stock Location"
                item={item}
                isOpen={modalEditIsOpen}
                toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                onSubmit={handleUpdate}
            />
        ) : null}

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={modalConfirmDeleteIsOpen}
            toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
            onSubmit={handleDelete}
        />
      </div>
  );
};

StockLocationTab.propTypes = {
  productId: PropTypes.string,
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  totalOnHand: PropTypes.number,
  totalOutgoing: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getStockLocations: PropTypes.func,
  addNewStockLocation: PropTypes.func,
  getStockLocationById: PropTypes.func,
  updateStockLocation: PropTypes.func,
  deleteStockLocation: PropTypes.func,
  clearStockLocationError: PropTypes.func,
};

const mapStateToProps = ({ stockLocation }) => {
  return stockLocation;
};

export default withRouter(
    connect(mapStateToProps, {
      getStockLocations,
      addNewStockLocation,
      getStockLocationById,
      updateStockLocation,
      deleteStockLocation,
      clearStockLocationError,
    })(StockLocationTab)
);
