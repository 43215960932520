import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from "react-number-format"
import moment from "moment/moment"

const ProductListToPrint = React.forwardRef((props, ref) => {
  const { products } = props
  return (
      <div ref={ ref }>
        <div className="print-container">
          <div className="print-content">
            <h4 className={"text-center mb-4"}>FINISH GOODS STOCK OUT LIST</h4>
            <table className="table-print">
              <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Code</th>
                <th>Name</th>
                <th>Size</th>
                <th>Date</th>
                <th>Quantity</th>
                <th>UoM</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              {
                  products && products.map((item, index) => {
                    const { productImages, onHandQuantity, stockUoMs } = item;
                    let totalQuantity =
                        item.quantity * parseInt(item.unitOfMeasurementConversionRate)
                    let productImage = productImages.length >0 ? productImages[0]: null;
                    return <tr key={index}>
                      <td className={ "text-right" }>{index + 1}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          {
                            productImage ?
                                <img style={{width:"80px", height:"50px", objectFit:"contain"}} src={productImage.url} alt=""/>
                                :
                                <div className="avatar-order">
                                  <i className="fas fa-tshirt text-primary"></i>
                                </div>
                          }
                        </div>
                      </td>
                      <td>{item.productCode}</td>
                      <td>{item.productName}</td>
                      <td>{item.clothingSizeName}</td>
                      <td>{ moment(item.date).format('DD-MMM-YYYY') }</td>
                      <td className="text-end">
                        <NumberFormat
                          value={ item.quantity }
                          displayType="text"
                          thousandSeparator={ true }
                          fixedDecimalScale={ false }
                        />
                      </td>
                      <td>{item.unitOfMeasurementName}</td>
                      <td className="text-end">
                        <NumberFormat
                            value={ totalQuantity ?? 0 }
                            displayType="text"
                            thousandSeparator={ true }
                            fixedDecimalScale={ false }
                        />
                      </td>
                    </tr>
                  })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
  )
})

ProductListToPrint.propTypes = {
  products: PropTypes.array,
}

ProductListToPrint.displayName="ProductListToPrint";

export default ProductListToPrint