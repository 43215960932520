import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getStockRequestById, getItemUnitOfMeasurements, updateStockRequest, submitWithStatusStockRequest } from "store/actions"
import StockRequestForm from "../../containers/stock-request/StockRequestForm"

const StockRequestDetail = props => {
  const { submitWithStatusStockRequest, updateStockRequest, match, history } = props

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null)
  const [ stockRequestType, setStockRequestType ] = useState('')

  const { getStockRequestById, stockRequest } = props
  const { item, validationType, invalid, loading } = stockRequest

  useEffect(() => {
    const { stockRequestType, id } = match.params
    setStockRequestType(stockRequestType)
    getStockRequestById(id)
    setId(id)
  }, [])

  const handleOnSubmit = (data) => {
    updateStockRequest({
      data,
      history,
      stockRequestType
    })
  }

  const handleOnCancel = () => {
    history.push(`/stock-request/${ stockRequestType }`)
  }

  const handleOnSubmitWithStatus = (data) => {
    submitWithStatusStockRequest({
      data,
      history,
      stockRequestType
    });
  }
  
  

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock Item Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            { loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <>
                  { item ? (
                      <>
                        <Breadcrumbs
                            title="Home"
                            backLink={ `/stock-request/${ stockRequestType }` }
                            breadcrumbItem={ `${ item.code } | Stock Item Detail` }
                        />
                        <Row>
                          <Col md={ 12 }>
                            <StockRequestForm
                                item={ item }
                                invalid={invalid}
                                validationType={validationType}
                                stockRequestType={ stockRequestType }
                                onSubmit={ handleOnSubmit }
                                onCancel={ handleOnCancel }
                                onSubmitWithStatus={ handleOnSubmitWithStatus }
                            />
                          </Col>
                        </Row>
                      </>
                  ) : null }
                </>
            ) }
          </Container>
        </div>
      </React.Fragment>
  )
}

StockRequestDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  stockRequest: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getStockRequestById: PropTypes.func,
  updateStockRequest: PropTypes.func,
  submitWithStatusStockRequest: PropTypes.func
}

const mapStateToProps = ({ stockRequest }) => {
  return {
    stockRequest,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getStockRequestById,
      getItemUnitOfMeasurements,
      updateStockRequest,
      submitWithStatusStockRequest
    })(StockRequestDetail)
)
