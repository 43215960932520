import React, { useEffect, useState } from "react";
import { NIL } from "uuid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getItemAttachments,
  updateItemAttachments,
  deleteItemAttachment,
} from "store/actions";

import { Button, Card, CardHeader, Col, Row } from "reactstrap";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ItemAttachmentTable from "containers/item-attachment/ItemAttachmentTable";
import ItemUploadModalForm from "containers/item-attachment/ItemUploadModalForm";

const ItemAttachmentTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [modalUploadForm, setModalUploadForm] = useState(false);
  const [modalRemoveImage, setModalRemoveImage] = useState(false);

  const {
    getItemAttachments,
    deleteItemAttachment,
    updateItemAttachments,
    items,
    item,
    message,
    itemCount,
    pageCount,
    loading,
    itemId,
  } = props;

  useEffect(() => {
    if (itemId) {
      getItemAttachments({ itemId, page, pageSize });
    }
  }, [itemId, page, pageSize]);

  const handleSubmitUpload = items => {
    updateItemAttachments({ itemId, attachments: items });
    setModalUploadForm(false);
  };

  const handleSubmitRemoveImage = () => {
    deleteItemAttachment({ id: selectedId, itemId });
    setModalRemoveImage(false);
  };

  return (
    <>
      <Card className="mb-2 rounded-2">
        <CardHeader className="bg-transparent border pb-1 pt-3">
          <Row className="g-1">
            <Col md={2}></Col>
            <Col md={10}>
              <div className="text-sm-end">
                <Button
                  color="primary"
                  onClick={() => setModalUploadForm(true)}
                >
                  <i className="fas fa-cloud-upload-alt" /> Upload files
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>
      {items && (
        <ItemAttachmentTable
          items={items}
          itemCount={itemCount}
          currentPage={page}
          totalPage={pageCount}
          loading={loading}
          defaultPageSize={pageSize}
          onConfirmDelete={id => {
            setSelectedId(id);
            setModalRemoveImage(true);
          }}
          onChangePage={page => setPage(page)}
          onPageSizeChange={size => {
            setPage(1);
            setPageSize(size);
          }}
        />
      )}

      <Row>
        <ItemUploadModalForm
          title={"Upload Attachments"}
          isOpen={modalUploadForm}
          toggle={() => setModalUploadForm(!modalUploadForm)}
          onSubmit={handleSubmitUpload}
        />
        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={modalRemoveImage}
          toggle={() => setModalRemoveImage(!modalRemoveImage)}
          onSubmit={handleSubmitRemoveImage}
        />
      </Row>
    </>
  );
};

ItemAttachmentTab.propTypes = {
  itemId: PropTypes.string,
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,

  getItemAttachments: PropTypes.func,
  updateItemAttachments: PropTypes.func,
  deleteItemAttachment: PropTypes.func,
};

const mapStateToProps = ({ itemAttachment }) => {
  return itemAttachment;
};

export default withRouter(
  connect(mapStateToProps, {
    getItemAttachments,
    updateItemAttachments,
    deleteItemAttachment,
    // updateOrder,
    // getOrderById,
    // updateItemAttachment,
    // deleteItemAttachment,
  })(ItemAttachmentTab)
);
