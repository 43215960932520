import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ShippingFeeTable from "containers/shipping-fee/ShippingFeeTable";
import ModalForm from "containers/shipping-fee/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import {
  getItemShippingFees,
  addNewItemShippingFee,
  updateItemShippingFee,
  getItemShippingFeeById,
  deleteItemShippingFee,
  clearItemShippingFeeError,
} from "../../store/item-shipping-fee/actions";

export class ShippingFee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      modalAdd: false,
      modalEdit: false,
      modalDelete: false,
    };
  }

  toggleAddModal = () => {
    this.setState(prevState => ({
      modalAdd: !prevState.modalAdd,
    }));
  };

  toggleEditModal = () => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
    }));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
    }));
  };

  handleOnAddNew = () => {
    this.setState({
      modalAdd: true,
    });
  };

  handleSubmit = data => {
    const { addNewItemShippingFee } = this.props;
    this.setState({
      modalAdd: false,
    });

    addNewItemShippingFee(data);
  };

  componentDidMount() {
    const { getItemShippingFees } = this.props;
    getItemShippingFees({ term: "" });
  }

  handleOnPageSizeChange = pageSize => {
    const { getItemShippingFees } = this.props;
    getItemShippingFees({ term: "", page: 1, pageSize });

    this.setState({
      pageSize,
    });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    const { getItemShippingFees } = this.props;
    getItemShippingFees({ term: "", page, pageSize });

    this.setState({
      currentPage: page,
    });
  };

  handleOnEdit = id => {
    this.props.getItemShippingFeeById(id);
    this.setState({
      id,
      modalEdit: true,
    });
  };

  handleUpdate = item => {
    this.props.updateItemShippingFee(item);
    this.setState({
      modalEdit: false,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalDelete: true,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteItemShippingFee(id);
    this.setState({ modalDelete: false });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/shipping-fee/${id}`);
  };

  handleOnSearch = e => {
    const { getItemShippingFees } = this.props;
    const { value } = e.target;
    getItemShippingFees({ term: value ?? "" });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Shipping Fee | JLS Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Settings" breadcrumbItem="Shipping Fee" />
            <Row>
              <Col lg="12">
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={8}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={this.handleOnAddNew}
                          >
                            <i className="fas fa-plus" /> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                {items && (
                  <ShippingFeeTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={this.handleOnEdit}
                    onConfirmDelete={this.handleConfirmDelete}
                    onViewDetail={this.handleOnViewDetail}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title="Add Shipping Fee"
                  toggle={this.toggleAddModal}
                  isOpen={this.state.modalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title="Edit Shipping Fee"
                    item={item}
                    toggle={this.toggleEditModal}
                    isOpen={this.state.modalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalDelete}
                  toggle={this.toggleDeleteModal}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ShippingFee.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getItemShippingFees: PropTypes.func,
  addNewItemShippingFee: PropTypes.func,
  updateItemShippingFee: PropTypes.func,
  getItemShippingFeeById: PropTypes.func,
  deleteItemShippingFee: PropTypes.func,
  clearOrderError: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ itemShippingFee }) => {
  return itemShippingFee;
};

export default withRouter(
  connect(mapStateToProps, {
    getItemShippingFees,
    addNewItemShippingFee,
    updateItemShippingFee,
    getItemShippingFeeById,
    deleteItemShippingFee,
    clearItemShippingFeeError,
  })(ShippingFee)
);
