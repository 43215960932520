import { SEVERITY } from "helpers/utils.js";
import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICE_BY_ID_SUCCESS,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL,
  DELETE_INVOICE,
  ADD_NEW_INVOICE_SUCCESS,
  INVOICE_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  totalAmount: 0,
  totalOutstanding: 0,
  totalPayment: 0,
  invoicedInvoiceIds: [],
  paidInvoiceIds: [],
  unpaidInvoiceIds: [],
  loading: false,
  message: null,
};

const OrderNote = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_INVOICE:
    case GET_INVOICE_DETAIL:
    case GET_INVOICES:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICES_SUCCESS:
      const {
        totalAmount,
        totalOutstanding,
        totalPayment,
        invoicedInvoiceIds,
        paidInvoiceIds,
        unpaidInvoiceIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        totalAmount,
        totalOutstanding,
        totalPayment,
        invoicedInvoiceIds,
        paidInvoiceIds,
        unpaidInvoiceIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case ADD_NEW_INVOICE_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice",
          text: "Invoice has been generated successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };

    case GET_INVOICE_DETAIL_SUCCESS:
    case GET_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case INVOICE_CLEAR_NOTIFY:
      return {
        ...state,
        error: null,
        message: null,
      };
    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: payload,
        message: {
          title: "Invoice",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    default:
      return state;
  }
};

export default OrderNote;
