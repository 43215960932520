import {
  GET_PAGING_TRANSFER_LOCATION,
  GET_PAGING_TRANSFER_LOCATION_SUCCESS,
  GET_PAGING_TRANSFER_LOCATION_ERROR,
  GET_TRANSFER_LOCATION_BY_ID,
  GET_TRANSFER_LOCATION_BY_ID_SUCCESS,
  ADD_NEW_TRANSFER_LOCATION,
  ADD_NEW_TRANSFER_LOCATION_SUCCESS,
  UPDATE_TRANSFER_LOCATION,
  UPDATE_TRANSFER_LOCATION_SUCCESS,
  DELETE_TRANSFER_LOCATION,
  DELETE_TRANSFER_LOCATION_SUCCESS,
  TRANSFER_LOCATION_CLEAR_ERROR,
  ADD_NEW_TRANSFER_LOCATION_ERROR,
} from "./actionTypes";
// TransferLocation
export const getPagingTransferLocation = query => ({
  type: GET_PAGING_TRANSFER_LOCATION,
  payload: query,
});

export const getPagingTransferLocationSuccess = payload => ({
  type: GET_PAGING_TRANSFER_LOCATION_SUCCESS,
  payload,
});

export const getPagingTransferLocationError = error => ({
  type: GET_PAGING_TRANSFER_LOCATION_ERROR,
  payload: error,
});

export const getTransferLocationById = id => ({
  type: GET_TRANSFER_LOCATION_BY_ID,
  payload: id,
});

export const getTransferLocationByIdSuccess = payload => ({
  type: GET_TRANSFER_LOCATION_BY_ID_SUCCESS,
  payload,
});

export const addNewTransferLocation = item => ({
  type: ADD_NEW_TRANSFER_LOCATION,
  payload: item,
});

export const addNewTransferLocationSuccess = item => ({
  type: ADD_NEW_TRANSFER_LOCATION_SUCCESS,
  payload: item,
});

export const addNewTransferLocationError = error => ({
  type: ADD_NEW_TRANSFER_LOCATION_ERROR,
  payload: error,
});

export const updateTransferLocation = item => ({
  type: UPDATE_TRANSFER_LOCATION,
  payload: item,
});

export const updateTransferLocationSuccess = item => ({
  type: UPDATE_TRANSFER_LOCATION_SUCCESS,
  payload: item,
});

export const deleteTransferLocation = item => ({
  type: DELETE_TRANSFER_LOCATION,
  payload: item,
});

export const deleteTransferLocationSuccess = item => ({
  type: DELETE_TRANSFER_LOCATION_SUCCESS,
  payload: item,
});

export const clearTransferLocationError = () => ({
  type: TRANSFER_LOCATION_CLEAR_ERROR,
});
