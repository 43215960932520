// COMPANY
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";

export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";

export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY";
export const ADD_NEW_COMPANY_SUCCESS = "ADD_NEW_COMPANY_SUCCESS";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";

export const COMPANY_CLEAR_NOTIFY = "COMPANY_CLEAR_NOTIFY";
