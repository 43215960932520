import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PRODUCT_IMAGES,
  ADD_NEW_PRODUCT_IMAGE,
  GET_PRODUCT_IMAGE_BY_ID,
  UPDATE_PRODUCT_IMAGE,
  UPDATE_PRODUCT_IMAGES,
  DELETE_PRODUCT_IMAGE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getProductImagesSuccess,
  getProductImageByIdSuccess,
  getProductImagesFail,
} from "./actions";
// ProductImage
const getProductImagesRequest = query =>
  get("/api/ProductImage/paging", { params: query });
const addNewProductImageRequest = role => post("/api/ProductImage", role);
const getProductImageByIdRequest = id => get(`/api/ProductImage/${id}`);
const updateProductImageRequest = role => update("/api/ProductImage", role);
const deleteProductImageRequest = id => del(`/api/ProductImage/${id}`);

const updateProductImagesRequest = ({ productId, attachments }) => {
  return update(`/api/Product/${productId}/attachments`, attachments);
};

function* fetchProductImages({ payload }) {
  try {
    const response = yield call(getProductImagesRequest, payload);
    yield put(getProductImagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductImagesFail(error));
  }
}

function* addNewProductImage({ payload }) {
  try {
    yield call(addNewProductImageRequest, payload);
    const { productId } = payload;
    const response = yield call(getProductImagesRequest, { productId });
    yield put(getProductImagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductImagesFail(error));
  }
}

function* updateProductImage({ payload }) {
  try {
    yield call(updateProductImageRequest, payload);
    const { productId } = payload;
    const response = yield call(getProductImagesRequest, { productId });
    yield put(getProductImagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductImagesFail(error));
  }
}

function* updateProductImages({ payload }) {
  try {
    console.log(payload);
    let response = yield call(updateProductImagesRequest, payload);

    response = yield call(getProductImagesRequest, {
      productId: response,
    });

    yield put(getProductImagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductImagesFail(error));
  }
}

function* getProductImageById({ payload }) {
  try {
    var response = yield call(getProductImageByIdRequest, payload);
    yield put(getProductImageByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductImagesFail(error));
  }
}

function* deleteProductImage({ payload }) {
  try {
    const { id, productId } = payload;
    let response = yield call(deleteProductImageRequest, id);
    response = yield call(getProductImagesRequest, { productId });
    yield put(getProductImagesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductImagesFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_PRODUCT_IMAGES, fetchProductImages);
  yield takeEvery(ADD_NEW_PRODUCT_IMAGE, addNewProductImage);
  yield takeEvery(GET_PRODUCT_IMAGE_BY_ID, getProductImageById);
  yield takeEvery(UPDATE_PRODUCT_IMAGE, updateProductImage);
  yield takeEvery(UPDATE_PRODUCT_IMAGES, updateProductImages);
  yield takeEvery(DELETE_PRODUCT_IMAGE, deleteProductImage);
}

export default roleSaga;
