import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_STOCK_ADJUSTMENT_ITEM,
  GET_STOCK_ADJUSTMENT_ITEM_BY_ID,
  UPDATE_STOCK_ADJUSTMENT_ITEM,
  DELETE_STOCK_ADJUSTMENT_ITEM,
  GET_STOCK_ADJUSTMENT_ITEMS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getStockAdjustmentItemByIdSuccess,
  getStockAdjustmentItemsError,
  getStockAdjustmentItemsSuccess,
} from "./actions";

const getStockAdjustmentItemsRequest = query =>
  get("/api/StockAdjustmentItem/paging", { params: query });
const addNewStockAdjustmentItemRequest = item =>
  post("/api/StockAdjustmentItem", item);
const getStockAdjustmentItemByIdRequest = id =>
  get(`/api/StockAdjustmentItem/${id}`);
const updateStockAdjustmentItemRequest = item =>
  update("/api/StockAdjustmentItem", item);
const deleteStockAdjustmentItemRequest = id =>
  del(`/api/StockAdjustmentItem/${id}`);

function* getStockAdjustmentItems({ payload }) {
  try {
    const response = yield call(getStockAdjustmentItemsRequest, payload);
    yield put(getStockAdjustmentItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentItemsError(error));
  }
}

function* addNewStockAdjustmentItem({ payload }) {
  try {
    const { stockAdjustmentId } = payload;
    yield call(addNewStockAdjustmentItemRequest, payload);
    const response = yield call(getStockAdjustmentItemsRequest, {
      stockAdjustmentId,
    });
    yield put(getStockAdjustmentItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentItemsError(error));
  }
}

function* updateStockAdjustmentItem({ payload }) {
  try {
    const { stockAdjustmentId } = payload;
    yield call(updateStockAdjustmentItemRequest, payload);
    const response = yield call(getStockAdjustmentItemsRequest, {
      stockAdjustmentId,
    });
    yield put(getStockAdjustmentItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentItemsError(error));
  }
}

function* getStockAdjustmentItemById({ payload }) {
  try {
    var response = yield call(getStockAdjustmentItemByIdRequest, payload);
    yield put(getStockAdjustmentItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentItemsError(error));
  }
}

function* deleteStockAdjustmentItem({ payload }) {
  try {
    const { stockAdjustmentId, id } = payload;
    yield call(deleteStockAdjustmentItemRequest, id);
    const response = yield call(getStockAdjustmentItemsRequest, {
      stockAdjustmentId,
    });
    yield put(getStockAdjustmentItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentItemsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_ADJUSTMENT_ITEMS, getStockAdjustmentItems);
  yield takeEvery(ADD_NEW_STOCK_ADJUSTMENT_ITEM, addNewStockAdjustmentItem);
  yield takeEvery(GET_STOCK_ADJUSTMENT_ITEM_BY_ID, getStockAdjustmentItemById);
  yield takeEvery(UPDATE_STOCK_ADJUSTMENT_ITEM, updateStockAdjustmentItem);
  yield takeEvery(DELETE_STOCK_ADJUSTMENT_ITEM, deleteStockAdjustmentItem);
}

export default itemSaga;
