// ITEM_USAGE_LIMITATION
export const GET_ITEM_USAGE_LIMITATIONS = "GET_ITEM_USAGE_LIMITATIONS";
export const GET_ITEM_USAGE_LIMITATIONS_SUCCESS =
  "GET_ITEM_USAGE_LIMITATIONS_SUCCESS";
export const GET_ITEM_USAGE_LIMITATIONS_ERROR = "GET_ITEM_USAGE_LIMITATIONS_ERROR";

export const GET_ITEM_USAGE_LIMITATION_BY_ID = "GET_ITEM_USAGE_LIMITATION_BY_ID";
export const GET_ITEM_USAGE_LIMITATION_BY_ID_SUCCESS =
  "GET_ITEM_USAGE_LIMITATION_BY_ID_SUCCESS";

export const ADD_NEW_ITEM_USAGE_LIMITATION = "ADD_NEW_ITEM_USAGE_LIMITATION";

export const ADD_NEW_ITEM_USAGE_LIMITATION_SUCCESS =
  "ADD_NEW_ITEM_USAGE_LIMITATION_SUCCESS";
export const ADD_NEW_ITEM_USAGE_LIMITATION_ERROR =
  "ADD_NEW_ITEM_USAGE_LIMITATION_ERROR";

export const SAVE_BULK_ITEM_USAGE_LIMITATION = "SAVE_BULK_ITEM_USAGE_LIMITATION";
export const SAVE_BULK_ITEM_USAGE_LIMITATION_SUCCESS =
    "SAVE_BULK_ITEM_USAGE_LIMITATION_SUCCESS";
export const ADD_NEW_BULK_ITEM_USAGE_LIMITATION_ERROR =
    "ADD_NEW_BULK_ITEM_USAGE_LIMITATION_ERROR";

export const UPDATE_ITEM_USAGE_LIMITATION = "UPDATE_ITEM_USAGE_LIMITATION";
export const UPDATE_ITEM_USAGE_LIMITATION_SUCCESS =
  "UPDATE_ITEM_USAGE_LIMITATION_SUCCESS";
export const UPDATE_ITEM_USAGE_LIMITATION_ERROR =
  "UPDATE_ITEM_USAGE_LIMITATION_ERROR";

export const DELETE_ITEM_USAGE_LIMITATION = "DELETE_ITEM_USAGE_LIMITATION";
export const DELETE_ITEM_USAGE_LIMITATION_SUCCESS =
  "DELETE_ITEM_USAGE_LIMITATION_SUCCESS";

export const ITEM_USAGE_LIMITATION_CLEAR_ERROR = "ITEM_USAGE_LIMITATION_CLEAR_ERROR";
