import { SEVERITY } from "helpers/utils.js";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  REGISTER_USER_PORTAL_SUCCESS,
  REGISTER_USER_PORTAL_ERROR,
  USER_CLEAR_NOTIFY,
  DELETE_USER_BY_ID_SUCCESS,
  DELETE_USER_BY_ID_ERROR,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const User = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_USER_PROFILE:
    case GET_ALL_USERS:
      return {
        ...state,
        item: null,
        loading: true,
      };
    case GET_ALL_USERS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        message: {
          title: "Update User Profile",
          text: "The User Profile has been updated successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        message: {
          title: "Update User Profile",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    case REGISTER_USER_PORTAL_SUCCESS:
      return {
        ...state,
        message: {
          title: "User Registration",
          text: "The User has been registered successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };

    case REGISTER_USER_PORTAL_ERROR:
      return {
        ...state,
        message: {
          title: "User Registration",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    case DELETE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        message: {
          title: "User Deletion",
          text: "User has been deleted successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };

    case DELETE_USER_BY_ID_ERROR:
      return {
        ...state,
        message: {
          title: "User Deletion",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    case USER_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };

    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        message: {
          title: "User",
          text: payload,
          severity: SEVERITY.DANGER,
        },
      };
    default:
      return state;
  }
};

export default User;
