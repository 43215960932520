import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getInvoicePaymentHistory } from "store/actions";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, Col, Input, Row } from "reactstrap";
import PaymentHistoryListTable from "containers/invoice-payment-history/PaymentHistoryListTable";
import InvoicePaymentSummary from "containers/invoice-payment/InvoicePaymentSummary";

export class InvoicePaymentTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      currentPage: 1,
      pageSize: 10,
    };
  }

  componentDidMount = () => {
    const { userId } = this.props;
    this.setState({
      userId,
    });
  };

  handleOnSearch = e => {
    const { userId } = this.state;
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getInvoicePaymentHistory({ term: value ?? "", userId });
  };

  handleOnPageChange = page => {
    const { pageSize, userId } = this.state;
    this.props.getInvoicePaymentHistory({
      page,
      pageSize,
      userId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { userId } = this.state;
    this.props.getInvoicePaymentHistory({
      page: 1,
      pageSize,
      userId,
    });

    this.setState({
      pageSize,
    });
  };

  handleStatusFilter = ids => {
    const { userId } = this.state;
    this.props.getInvoicePaymentHistory({ userId, ids });
  };

  handleSearch = () => {
    const { term, startDate, endDate, userId } = this.state;
    this.props.getInvoicePaymentHistory({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
      userId,
    });
  };

  render() {
    const { invoicePaymentHistotry } = this.props;
    const { pageSize, currentPage } = this.state;
    const { items, itemCount, pageCount, loading } = invoicePaymentHistotry;
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={10}></Col>
            </Row>
          </CardHeader>
        </Card>
        {items && (
          <>
            <InvoicePaymentSummary
              item={invoicePaymentHistotry}
              onStatusFilter={this.handleStatusFilter}
            />
            <PaymentHistoryListTable
              items={items}
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={pageCount}
              loading={loading}
              defaultPageSize={pageSize}
              onChangePage={this.handleOnPageChange}
              onPageSizeChange={this.handleOnPageSizeChange}
            />
          </>
        )}
      </>
    );
  }
}

InvoicePaymentTab.propTypes = {
  userId: PropTypes.string,
  invoicePaymentHistotry: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoicePaymentHistory: PropTypes.func,
};

const mapStateToProps = ({ invoicePaymentHistotry }) => {
  return { invoicePaymentHistotry };
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoicePaymentHistory,
  })(InvoicePaymentTab)
);
