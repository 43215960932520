import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap"
import NumberFormat from "react-number-format"

const CuttingSummaryCard = props => {
  const { summary, title } = props;
  const { items, clothingSizes } = summary;
  
  return (
      <Card className="mb-2">
        <CardHeader className="bg-transparent border-bottom">
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
          >
            <Table
                id="tech-companies-1"
                className="table table-striped table-bordered table-nowrap"
            >
              <thead>
              <tr>
                <th style={{ width: "80px" }}>#</th>
                <th className="text-center">Item Code</th>
                {clothingSizes.map((clothingSize, index) => {
                  return (
                      <th key={index} className="text-center">
                        {clothingSize.name}
                      </th>
                  );
                })}

                <th className="text-center">Total</th>
              </tr>
              </thead>
              <tbody>
              {items.map((item, index) => {
                const { itemId, entries } = item;
                // let num = (page - 1) * pageSize + (index + 1);
                let num = index+1;

                let total = 0;

                return (
                    <tr key={index}>
                      <td style={{ textAlign: "right" }}>{num}</td>
                      <td>
                        {item.itemCode} -{" "}
                        {item.itemName}
                      </td>
                      {clothingSizes.map((clothingSize, index) => {
                        
                        let findEntry = entries.find(e => e.clothingSizeId === clothingSize.id);
                        total += findEntry.quantity;
                        
                        return (
                            <td key={index} className="text-end">
                              <NumberFormat
                                  value={findEntry.quantity}
                                  displayType="text"
                                  thousandSeparator={true}
                                  fixedDecimalScale={false}
                              />
                            </td>
                        );
                      })}

                      <td className="text-end">
                        <NumberFormat
                            value={total}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                        />
                      </td>
                    </tr>
                );
              })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
  )
}

CuttingSummaryCard.propTypes = {
  summary: PropTypes.object.isRequired,
  title: PropTypes.string
}

export default CuttingSummaryCard