import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL as NIL_UUID } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import Select from "react-select";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
const defultOptions = [
  { key: 1, value: 0, label: "True" },
  { key: 2, value: 1, label: "False" },
];
class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL_UUID,
      label: "",
      symbol: "",
      isDefault: false,
      displayOrder: null,
      rate: 0,
    };
  }

  onOpened = () => {
    const { item } = this.props;
    if (item) {
      this.setState({ ...item });
    }
  };
  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { isOpen, toggle, title, onSubmit } = this.props;

    return (
      <Modal
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            id: this.state.id,
            label: (this.state && this.state.label) || "",
            symbol: (this.state && this.state.symbol) || "",
            displayOrder: (this.state && this.state.displayOrder) || 0,
            isDefault: (this.state && this.state.isDefault) || false,
            rate: (this.state && this.state.rate) || 0,
          }}
          validationSchema={Yup.object().shape({
            label: Yup.string().required("Please Enter Role Name"),
          })}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {({ errors, status, touched }) => (
            <Form className="needs-validation">
              <div className="modal-body">
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Label</Label>
                      <Field
                        name="label"
                        placeholder="Label"
                        type="text"
                        className={
                          "form-control" +
                          (errors.label && touched.label ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="label"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Symbol</Label>
                      <Field
                        name="symbol"
                        placeholder="Symbol"
                        type="text"
                        className={
                          "form-control" +
                          (errors.symbol && touched.symbol ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="symbol"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Display Order</Label>
                      <Field
                        name="displayOrder"
                        placeholder="Display Order"
                        type="text"
                        className={
                          "form-control" +
                          (errors.displayOrder && touched.displayOrder
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="displayOrder"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Rate</Label>
                      <Field
                        name="rate"
                        placeholder="Rate"
                        type="number"
                        step={0.01}
                        className={
                          "form-control" +
                          (errors.rate && touched.rate ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="rate"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckcolor1"
                        checked={this.state.isDefault}
                        onChange={e => {
                          this.setState({
                            isDefault: e.target.checked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckcolor1"
                      >
                        Is Default
                      </label>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
