import {
  GET_BANNERS,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  GET_BANNER_BY_ID,
  GET_BANNER_BY_ID_SUCCESS,
  ADD_NEW_BANNER,
  UPDATE_BANNER,
  DELETE_BANNER,
  BANNER_CLEAR_ERROR,
} from "./actionTypes";


export const getBanners = query => ({
  type: GET_BANNERS,
  payload: query,
});

export const getBannersSuccess = banners => ({
  type: GET_BANNERS_SUCCESS,
  payload: banners,
});

export const getBannersFail = error => ({
  type: GET_BANNERS_FAIL,
  payload: error,
});

export const getBannerById = id => ({
  type: GET_BANNER_BY_ID,
  payload: id,
});

export const getBannerByIdSuccess = banner => ({
  type: GET_BANNER_BY_ID_SUCCESS,
  payload: banner,
});

export const getBannerByIdFail = error => ({
  type: GET_BANNER_BY_ID_FAIL,
  payload: error,
});

export const addNewBanner = banner => ({
  type: ADD_NEW_BANNER,
  payload: banner,
});

export const updateBanner = banner => ({
  type: UPDATE_BANNER,
  payload: banner,
});

export const deleteBanner = banner => ({
  type: DELETE_BANNER,
  payload: banner,
});

export const clearBannerError = () => ({
  type: BANNER_CLEAR_ERROR,
});
