// DEVICE_INFO
export const GET_DEVICE_INFO = "GET_DEVICE_INFO";
export const GET_DEVICE_INFO_SUCCESS = "GET_DEVICE_INFO_SUCCESS";
export const GET_DEVICE_INFO_FAIL = "GET_DEVICE_INFO_FAIL";

export const GET_DEVICE_INFO_BY_ID = "GET_DEVICE_INFO_BY_ID";
export const GET_DEVICE_INFO_BY_ID_SUCCESS = "GET_DEVICE_INFO_BY_ID_SUCCESS";

export const ADD_NEW_DEVICE_INFO = "ADD_NEW_DEVICE_INFO";
export const ADD_NEW_DEVICE_INFO_SUCCESS = "ADD_NEW_DEVICE_INFO_SUCCESS";

export const UPDATE_DEVICE_INFO = "UPDATE_DEVICE_INFO";
export const UPDATE_DEVICE_INFO_SUCCESS = "UPDATE_DEVICE_INFO_SUCCESS";

export const DELETE_DEVICE_INFO = "DELETE_DEVICE_INFO";
export const DELETE_DEVICE_INFO_SUCCESS = "DELETE_DEVICE_INFO_SUCCESS";

export const DEVICE_INFO_CLEAR_NOTIFY = "DEVICE_INFO_CLEAR_NOTIFY";
