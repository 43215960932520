import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLE_BY_ID,
  GET_ROLE_BY_ID_SUCCESS,
  ADD_NEW_ROLE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE,
  ROLE_CLEAR_ERROR,
  ROLE_CLEAR_NOTIFY,
  UPDATE_PERMISSION_SUCCESS,
} from "./actionTypes";

// Role
export const getRoles = query => ({
  type: GET_ROLES,
  payload: query,
});

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
});

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
});

export const getRoleById = id => ({
  type: GET_ROLE_BY_ID,
  payload: id,
});

export const getRoleByIdSuccess = role => ({
  type: GET_ROLE_BY_ID_SUCCESS,
  payload: role,
});

export const getRoleByIdFail = error => ({
  type: GET_ROLE_BY_ID_FAIL,
  payload: error,
});

export const addNewRole = role => ({
  type: ADD_NEW_ROLE,
  payload: role,
});

export const updateRole = Role => ({
  type: UPDATE_ROLE,
  payload: Role,
});

export const updateRoleSuccess = payload => ({
  type: UPDATE_ROLE_SUCCESS,
  payload,
});

export const updatePermissionSuccess = payload => ({
  type: UPDATE_PERMISSION_SUCCESS,
  payload,
});

export const deleteRole = role => ({
  type: DELETE_ROLE,
  payload: role,
});

export const clearRoleError = () => ({
  type: ROLE_CLEAR_ERROR,
});

export const clearRoleNotify = () => ({
  type: ROLE_CLEAR_NOTIFY,
});
