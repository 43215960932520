import { call, put, takeEvery } from "redux-saga/effects";
import FileSaver from "file-saver";
import {
  ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT,
  GET_PRODUCT_STOCK_OUT_PRODUCT_BY_ID,
  UPDATE_PRODUCT_STOCK_OUT_PRODUCT,
  DELETE_PRODUCT_STOCK_OUT_PRODUCT,
  GET_PRODUCT_STOCK_OUT_PRODUCTS,
} from "./actionTypes";
import { get, post, del, put as update, getFile, postFile } from "../../helpers/api_helper"
import {
  getProductStockOutProductByIdSuccess,
  getProductStockOutProductsError,
  getProductStockOutProductsSuccess,
} from "./actions";

const getProductStockOutProductsRequest = data =>
  post("/api/ProductStockOutProduct/paging", data);

const getAllProductStockOutProductsRequest = data =>
    post("/api/ProductStockOutProduct/all", data);
const addNewProductStockOutProductRequest = item =>
  post("/api/ProductStockOutProduct", item);
const getProductStockOutProductByIdRequest = id =>
  get(`/api/ProductStockOutProduct/${id}`);
const updateProductStockOutProductRequest = item =>
  update("/api/ProductStockOutProduct", item);
const deleteProductStockOutProductRequest = id =>
  del(`/api/ProductStockOutProduct/${id}`);

const exportProductStockOutToExcel = async (payload, fileName) => {
  return await postFile(`/api/ProductStockOutProduct/exportToExcel`, payload, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* getProductStockOutProducts({ payload }) {
  try {
    const response = yield call(getProductStockOutProductsRequest, payload);
    yield put(getProductStockOutProductsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutProductsError(error));
  }
}

function* addNewProductStockOutProduct({ payload }) {
  try {
    const { productStockOutId } = payload;
    yield call(addNewProductStockOutProductRequest, payload);
    const response = yield call(getProductStockOutProductsRequest, {
      productStockOutId,
    });
    yield put(getProductStockOutProductsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutProductsError(error));
  }
}

function* updateProductStockOutProduct({ payload }) {
  try {
    const { productStockOutId } = payload;
    yield call(updateProductStockOutProductRequest, payload);
    const response = yield call(getProductStockOutProductsRequest, {
      productStockOutId,
    });
    yield put(getProductStockOutProductsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutProductsError(error));
  }
}

function* getProductStockOutProductById({ payload }) {
  try {
    var response = yield call(getProductStockOutProductByIdRequest, payload);
    yield put(getProductStockOutProductByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutProductsError(error));
  }
}

function* deleteProductStockOutProduct({ payload }) {
  try {
    const { productStockOutId, id } = payload;
    yield call(deleteProductStockOutProductRequest, id);
    const response = yield call(getProductStockOutProductsRequest, {
      productStockOutId,
    });
    yield put(getProductStockOutProductsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutProductsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PRODUCT_STOCK_OUT_PRODUCTS, getProductStockOutProducts);
  yield takeEvery(
    ADD_NEW_PRODUCT_STOCK_OUT_PRODUCT,
    addNewProductStockOutProduct
  );
  yield takeEvery(
    GET_PRODUCT_STOCK_OUT_PRODUCT_BY_ID,
    getProductStockOutProductById
  );
  yield takeEvery(
    UPDATE_PRODUCT_STOCK_OUT_PRODUCT,
    updateProductStockOutProduct
  );
  yield takeEvery(
    DELETE_PRODUCT_STOCK_OUT_PRODUCT,
    deleteProductStockOutProduct
  );
}

export default itemSaga;

export {
  exportProductStockOutToExcel,
  getAllProductStockOutProductsRequest
}
