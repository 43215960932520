import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_COMPANY,
  ADD_NEW_COMPANY,
  GET_COMPANY_BY_ID,
  UPDATE_COMPANY,
  DELETE_COMPANY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCompanySuccess,
  getCompanyByIdSuccess,
  getCompanyFail,
  addNewCompanySuccess,
  updateCompanySuccess,
  deleteCompanySuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/Company`);
const getCompanyRequest = query =>
  get(`/api/Company/paging`, { params: query });

const addNewCompanyRequest = item => post(`/api/Company`, item);
const getCompanyByIdRequest = id => get(`/api/Company/${id}`);
const updateCompanyRequest = item => update(`/api/Company`, item);
const deleteCompanyRequest = id => del(`/api/Company/${id}`);

function* fetchCompanys({ payload }) {
  try {
    const response = yield call(getCompanyRequest, payload);

    yield put(getCompanySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCompanyFail(error));
  }
}

function* addNewCompany({ payload }) {
  try {
    yield call(addNewCompanyRequest, payload);
    const response = yield call(getCompanyRequest);
    yield put(getCompanySuccess(response));
    yield put(addNewCompanySuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCompanyFail(error));
  }
}

function* updateCompany({ payload }) {
  try {
    yield call(updateCompanyRequest, payload);
    const response = yield call(getCompanyRequest);
    yield put(getCompanySuccess(response));
    yield put(updateCompanySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCompanyFail(error));
  }
}

function* getCompanyById({ payload }) {
  try {
    var response = yield call(getCompanyByIdRequest, payload);
    yield put(getCompanyByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCompanyFail(error));
  }
}

function* deleteCompany({ payload }) {
  try {
    yield call(deleteCompanyRequest, payload);
    const response = yield call(getCompanyRequest);
    yield put(getCompanySuccess(response));
    yield put(deleteCompanySuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCompanyFail(error));
  }
}

function* CompanySaga() {
  yield takeEvery(GET_COMPANY, fetchCompanys);
  yield takeEvery(ADD_NEW_COMPANY, addNewCompany);
  yield takeEvery(GET_COMPANY_BY_ID, getCompanyById);
  yield takeEvery(UPDATE_COMPANY, updateCompany);
  yield takeEvery(DELETE_COMPANY, deleteCompany);
}

export default CompanySaga;

export { getDeliveryAgenciesRequest };
