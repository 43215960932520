import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDER_TRACKING,
  ADD_NEW_ORDER_TRACKING,
  GET_ORDER_TRACKING_BY_ID,
  UPDATE_ORDER_TRACKING,
  DELETE_ORDER_TRACKING,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderTrackingSuccess,
  getOrderTrackingByIdSuccess,
  getOrderTrackingFail,
  addNewOrderTrackingSuccess,
  updateOrderTrackingSuccess,
  deleteOrderTrackingSuccess
} from "./actions";

const getOrderTrackingRequest = query =>
  get(`/api/OrderTracking/paging`, { params: query });
const addNewOrderTrackingRequest = ordertracking =>
  post(`/api/OrderTracking`, ordertracking);
const getOrderTrackingByIdRequest = data =>
  get(`/api/OrderTracking/${data.id}`);
const updateOrderTrackingRequest = ordertracking =>
  update(`/api/OrderTracking`, ordertracking);
const deleteOrderTrackingRequest = data => del(`/api/OrderTracking/${data.id}`);

function* fetchOrderTrackings({ payload }) {
  try {
    const response = yield call(getOrderTrackingRequest, payload);

    yield put(getOrderTrackingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderTrackingFail(error));
  }
}

function* addNewOrderTracking({ payload }) {
  try {
    yield call(addNewOrderTrackingRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderTrackingRequest, { orderId });
    yield put(getOrderTrackingSuccess(response));
    yield put(addNewOrderTrackingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderTrackingFail(error));
  }
}

function* updateOrderTracking({ payload }) {
  try {
    yield call(updateOrderTrackingRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderTrackingRequest, { orderId });
    yield put(getOrderTrackingSuccess(response));
    yield put(updateOrderTrackingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderTrackingFail(error));
  }
}

function* getOrderTrackingById({ payload }) {
  try {
    var response = yield call(getOrderTrackingByIdRequest, payload);
    yield put(getOrderTrackingByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderTrackingFail(error));
  }
}

function* deleteOrderTracking({ payload }) {
  try {
    yield call(deleteOrderTrackingRequest, payload);
    const { orderId } = payload;

    const response = yield call(getOrderTrackingRequest, { orderId });
    yield put(getOrderTrackingSuccess(response));
    yield put(deleteOrderTrackingSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderTrackingFail(error));
  }
}

function* orderTrackingSaga() {
  yield takeEvery(GET_ORDER_TRACKING, fetchOrderTrackings);
  yield takeEvery(ADD_NEW_ORDER_TRACKING, addNewOrderTracking);
  yield takeEvery(GET_ORDER_TRACKING_BY_ID, getOrderTrackingById);
  yield takeEvery(UPDATE_ORDER_TRACKING, updateOrderTracking);
  yield takeEvery(DELETE_ORDER_TRACKING, deleteOrderTracking);
}

export default orderTrackingSaga;
