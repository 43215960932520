import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table, UncontrolledButtonDropdown
} from "reactstrap"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { customSingleValue, formatItemOptionLabel, getMaxValue, getStockRequestTypeValue } from "../../helpers/utils"
import StockRequestItemRow from "../stock-request-item/StockRequestItemRow"
import { debounce, sumBy } from "lodash"
import { getItemUnitOfMeasurementsRequest } from "../../store/item-unit-of-measurement/saga"
import { NIL } from "uuid"
import { getItemsRequest } from "../../store/item/saga"
import { getAllPurchaseOrdersRequest } from "../../store/purchase-order/saga"
import moment from "moment"
import ConfirmationModal from "../../components/Common/ConfirmationModal"
import PrintPreviewModal from "../../pages/StockRequest/PrintPreviewModal"
import { validateStockItemLimitationRequest, validateStockRequest } from "../../store/stock-request/saga"
import StockRequestValidationModal from "./StockRequestValidationModal"
import { getItemsInItemUsageRequest } from "../../store/item-usage-limitation/saga"


const StockRequestForm = props => {
  const { stockRequestType, item, invalid, validationType, onSubmit, onSubmitWithStatus, onCancel } = props

  const [ term, setTerm ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ itemOptions, setItemOptions ] = useState([])
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ isSubmitted, setIsSubmitted ] = useState(false)
  const [ purchaseOrderOptions, setPurchaseOrderOptions ] = useState([])
  const [ modalConfirmation, setModalConfirmation ] = useState(false)
  const [ confirmationType, setConfirmationType ] = useState('')
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false)
  const [ modalValidation, setModalValidation ] = useState(false)
  const [ modalValidationItemLimitation, setModalValidationItemLimitation ] = useState(false)
  const [ stockRequestItems, setStockRequestItems ] = useState([])

  const SUBMIT = "SUBMIT"
  const APPROVE = "APPROVE"
  const CONFIRMED = "CONFIRMED"
  const REJECT = "REJECT"
  const CANCEL = "CANCEL"

  const [ stockRequest, setStockRequest ] = useState({
    id: NIL,
    code: '',
    status: 0,
    type: 0,
    invoiceNo: '',
    note: '',
    createdByUser: '',
    purchaseOrderId: null,
    dateRequest: moment().toDate(),
    stockRequestItems: [],
  })

  useEffect(() => {
    if (item) {
      setStockRequest({
        ...item,
        note: item.note ?? '',
        dateRequest: moment(item.dateRequest).toDate(),
        invoiceNo: item.invoiceNo ?? '',
        purchaseOrderId: {
          key: item.purchaseOrderId,
          value: item.purchaseOrderId,
          label: item.purchaseOrderCode,
        },
        stockRequestItems: item.stockRequestItems.map((stockRequestItem, index) => {
          return {
            ...stockRequestItem,
            index,
            note: stockRequestItem.note ?? '',
            itemId: {
              key: stockRequestItem.itemId,
              value: stockRequestItem.itemId,
              label: `${ stockRequestItem.itemCode } - ${ stockRequestItem.itemName }`,
            },

            isSelected: false,
            label: `${ stockRequestItem.itemCode } - ${ stockRequestItem.itemName }`,
            unitOfMeasurementId: {
              id: stockRequestItem.itemUnitOfMeasurementId,
              value: stockRequestItem.unitOfMeasurementId,
              label: stockRequestItem.itemUnitOfMeasurementTitle
            }
          }
        }),
      })
    } else {
      setStockRequest(
          {
            id: NIL,
            code: '',
            status: 0,
            type: getStockRequestTypeValue(stockRequestType),
            invoiceNo: '',
            note: '',
            createdByUser: '',
            purchaseOrderId: null,
            dateRequest: moment().toDate(),
            stockRequestItems: []
          }
      )
    }
  }, [ item ])

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  const getConfirmationText = (confirmationType) => {
    switch (confirmationType) {
      case SUBMIT:
        return 'Are you sure wanna submit for approval this?'
      case APPROVE:
        return 'Are you sure wanna approve this?'
      case CONFIRMED:
        return 'Are you sure wanna confirmed this?'
      case REJECT:
        return 'Are you sure wanna reject this?'
      case CANCEL:
        return 'Are you sure wanna cancel this?'
    }
  }


  useEffect(() => {
    getAllPurchaseOrdersRequest().then(data => {
      setPurchaseOrderOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.code,
            }
          })
      )
    })
  }, [])

  useEffect(() => {
    console.log(term);
    console.log(stockRequest);
    if (stockRequest?.purchaseOrderId) {
      setLoading(true)
      getItemsInItemUsageRequest({ term, purchaseOrderId: stockRequest.purchaseOrderId?.value }).then(data => {
        if (data) {
          setLoading(false)
          setItemOptions(data.map((a, index) => {
            return {
              key: a.id,
              value: a.id,
              type: a.type,
              itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
              quantity: a.onHandQuantity,
              label: `${ a.code } - ${ a.name }`
            }
          }))
        }
      })
    }
  }, [ term, stockRequest?.purchaseOrderId])

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    switch (name) {
      case 'purchaseOrderId':
        setStockRequest({
          ...stockRequest,
          [name]: valueType
        })

        break
      case 'itemId':
        if (valueType) {
          const { value, label } = valueType
          getItemUnitOfMeasurementsRequest({ itemId: value }).then(res => {
            const { data } = res
            let uomOptions = data?.map(a => {
              return {
                id: a.id,
                value: a.unitOfMeasurementId,
                label: a.title
              }
            }) ?? []

            let max = 0
            if (stockRequest.stockRequestItems.length > 0) {
              max = getMaxValue(
                  stockRequest.stockRequestItems.map(a => {
                    return a.index
                  })
              )
              max += 1
            } else {
              max = max + 1
            }

            let newItem = {
              index: max,
              id: NIL,
              itemId: valueType,
              label: label,
              quantity: 0,
              note: '',
              itemUnitOfMeasurementId: null,
              unitOfMeasurementId: uomOptions.length > 0 ? uomOptions[0] : null,
              uomOptions,
              isSelected: false
            }

            setStockRequest({ ...stockRequest, stockRequestItems: [ ...stockRequest.stockRequestItems, newItem ] })
          })
        }
        break
    }
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockRequest({
      ...stockRequest,
      stockRequestItems: stockRequest.stockRequestItems.map(item => ({ ...item, isSelected: checked }))
    })
  }

  const handleDeleteSelected = () => {
    setStockRequest({ ...stockRequest, stockRequestItems: stockRequest.stockRequestItems.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleStockRequestItemChange = item => {
    setStockRequest({
      ...stockRequest,
      stockRequestItems: stockRequest.stockRequestItems.map(a => a.index === item.index ? item : a)
    })
  }

  const handleDeleteStockRequestItem = (item) => {
    setStockRequest({
      ...stockRequest,
      stockRequestItems: stockRequest.stockRequestItems.filter(e => e.index !== item.index)
    })
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    setStockRequest({
      ...stockRequest,
      [name]: value
    })
  }

  const handleDateChange = (name, date) => {
    setStockRequest({ ...stockRequest, [name]: date })
  }

  const handleSubmit = () => {
    let valid = isValidateSubmit()
    let isValid = stockRequest.dateRequest && stockRequest.invoiceNo
    let hasNull = stockRequest.stockRequestItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)

    if (valid) {
      handleOnSubmit(stockRequest.status)
    }
  }

  const handleOnSubmit = (status) => {
    let data = {
      ...stockRequest,
      status,
      purchaseOrderId: stockRequest.purchaseOrderId?.value,
      dateRequest: stockRequest.dateRequest?.toISOString(),
      stockRequestItems: stockRequest.stockRequestItems.map(item => {
        return {
          ...item,
          itemId: item.itemId?.value,
          itemUnitOfMeasurementId: item.unitOfMeasurementId?.id,
          unitOfMeasurementId: item.unitOfMeasurementId?.value
        }
      })
    }

    onSubmit(data)
  }

  const handleCancel = () => {
    onCancel()
  }

  const isValidateSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockRequest.dateRequest && stockRequest.invoiceNo
    let hasNull = stockRequest.stockRequestItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)

    return !!(!hasNull && isValid && stockRequest.stockRequestItems.length > 0)

  }

  const validateAndConsumeStock = (status, stockRequest) => {
    validateStockItemLimitationRequest(stockRequest.id).then(res => {
      const { isValid, data } = res

      if (isValid) {
        // Send Validate Stock
        validateStockRequest(stockRequest.id).then(res => {
          if (res.isValid) {

            if (status === 6) { // Confirmed
              onSubmitWithStatus({
                id: stockRequest.id,
                status: status,
                type: "Tab",
              })
            } else {
              handleOnSubmit(status)
            }

            setModalConfirmation(false)
          } else {
            setModalValidation(true)
            setStockRequestItems(res.data.stockRequestItems)
          }
        })

      } else {
        setModalValidationItemLimitation(true)
        const { stockRequestItems } = data
        setStockRequestItems(stockRequestItems)
      }
    })
  }

  const handleOnSubmitWithStatus = () => {
    let status = 1
    switch (confirmationType) {
      case SUBMIT:
        status = 1
        break
      case APPROVE:
        status = 2
        break
      case REJECT:
        status = 3
        break
      case CANCEL:
        status = 5
        break
      case CONFIRMED:
        status = 6
        break
    }

    handleOnSubmit(status)
    setModalConfirmation(false)
  }

  return (
      <>
        <Row>
          <Col>
            <Card className={ "mb-2" }>
              <CardHeader className={ "bg-transparent border-bottom" }>
                <Row>
                  <Col>
                    <CardTitle>Info</CardTitle>
                  </Col>
                  <Col className={ 'text-end' }>
                    {
                      stockRequest.id !== NIL ?
                          <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                  onClick={ () => setModalPrintPreview(true) }
                                  className="text-primary"
                              >
                                <i className="mdi mdi-printer me-1"/> Print Preview
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown> : null
                    }
                  </Col>
                </Row>

              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <div
                          className={
                              "select2-container" +
                              (isSubmitted && !stockRequest.purchaseOrderId ? " is-invalid" : "")
                          }
                      >
                        <Label>Purchase Order</Label>
                        <Select
                            name="purchaseOrderId"
                            value={ stockRequest.purchaseOrderId }
                            onChange={ handleSelectChange }
                            options={ purchaseOrderOptions }
                            classNamePrefix="select2-selection"
                            isClearable
                        />
                      </div>
                      { isSubmitted && !stockRequest.purchaseOrderId && (
                          <div className="invalid-feedback-custom">
                            Purchase Order is required.
                          </div>
                      ) }
                    </div>

                    <FormGroup className="mb-3">
                      <Label htmlFor="">User</Label>
                      <Input
                          type="text"
                          name="createdByUser"
                          placeholder="User"
                          value={ stockRequest.createdByUser }
                          readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-3">
                      <Label htmlFor="">Reference No</Label>
                      <Input
                          type="text"
                          name="code"
                          placeholder="Auto Generate"
                          value={ stockRequest.code }
                          readOnly
                      />
                    </FormGroup>
                    <FormGroup className={ (isSubmitted && !stockRequest.dateRequest ? " is-invalid" : "") }>
                      <Label>Date Request</Label>
                      <DatePicker
                          className={ "form-control" + (isSubmitted && !stockRequest.dateRequest ? " is-invalid" : "") }
                          name="dateRequest"
                          selected={ stockRequest.dateRequest }
                          onChange={ date => handleDateChange('dateRequest', date) }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Date Request"
                          isClearable
                      />
                    </FormGroup>
                    { isSubmitted && !stockRequest.dateRequest && (
                        <div className="invalid-feedback-custom">
                          Date Request is required.
                        </div>
                    ) }
                  </Col>
                  <Col>
                    <FormGroup className={ "mb-3 " + (isSubmitted && !stockRequest.invoiceNo ? " is-invalid" : "") }>
                      <Label htmlFor="">Invoice No</Label>
                      <Input
                          type="text"
                          name="invoiceNo"
                          className={ isSubmitted && !stockRequest.invoiceNo ? " is-invalid" : "" }
                          placeholder="Invoice No"
                          value={ stockRequest.invoiceNo }
                          onChange={ handleOnChange }
                      />
                    </FormGroup>
                    { isSubmitted && !stockRequest.invoiceNo && (
                        <div className="invalid-feedback-custom">
                          Invoice No is required.
                        </div>
                    ) }
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className={ "mb-2" }>
              <CardHeader className={ "bg-transparent border-bottom" }>
                <Row>
                  <Col md={ 2 }>
                    <CardTitle className={ "pt-2" }>Items</CardTitle>
                  </Col>
                  <Col>
                  </Col>
                </Row>
              </CardHeader>
              <CardHeader className={ "bg-transparent border-bottom" }>
                <Row>
                  <Col>
                    <Label className={ "mt-2" }>Find Items:</Label>
                  </Col>
                  <Col md={ 8 }>
                    <Select
                        name="itemId"
                        value={ null }
                        placeholder={ "Find by Code, Name, ..." }
                        onChange={ handleSelectChange }
                        options={ itemOptions }
                        components={ {
                          SingleValue: customSingleValue
                        } }
                        onInputChange={ handleInputChange }
                        formatOptionLabel={ formatItemOptionLabel }
                        classNamePrefix="select2-selection"
                        isLoading={ loading }
                        isClearable
                    />
                  </Col>
                  <Col>
                    <div className="text-sm-end">
                      <Button color={ "danger" }
                              onClick={ handleDeleteSelected }
                              outline disabled={ !stockRequest.stockRequestItems.some(e => e.isSelected) }>
                        <i className="fas fa-trash me-1"/> Delete Selected
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                    id="tech-companies-1"
                    className="table-editable table table-striped table-bordered table-nowrap table-nowrap"
                >
                  <thead className={ "bg-primary text-white" }>
                  <tr>
                    <th className={ "text-center" } style={ { width: "80px" } }>
                      <input
                          type="checkbox"
                          className="form-check-input"
                          id="headerSelected"
                          checked={ isHeaderSelected }
                          onChange={ handleHeaderSelect }
                      />
                    </th>
                    <th className="text-center">Item</th>
                    <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                    <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                    <th className={ "text-center" } style={ { width: "250px" } }>Note</th>
                    <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    stockRequest.stockRequestItems
                        .sort((a, b) => a.index > b.index ? -1 : 1)
                        .map((item, index) => {
                          return <StockRequestItemRow
                              key={ index }
                              item={ item }
                              isSubmitted={ isSubmitted }
                              invalid={ invalid }
                              validationType={ validationType }
                              onChange={ handleStockRequestItemChange }
                              onDelete={ handleDeleteStockRequestItem }
                          />
                        })
                  }
                  </tbody>
                  {/*<tfoot>*/ }
                  {/*<tr>*/ }
                  {/*  <td className={ "text-end" } colSpan={ 2 }>*/ }
                  {/*    <strong>Total</strong>*/ }
                  {/*  </td>*/ }
                  {/*  <td className={ "text-end" }>*/ }
                  {/*    <strong>*/ }
                  {/*      { Number(sumBy(stockRequest.stockRequestItems, a => {*/ }
                  {/*        return Number(a.quantity)*/ }
                  {/*      })).toFixed(2) }*/ }
                  {/*    </strong>*/ }
                  {/*  </td>*/ }
                  {/*  <td></td>*/ }
                  {/*</tr>*/ }
                  {/*</tfoot>*/ }
                </Table>
                <div className={ (isSubmitted && stockRequest.stockRequestItems.length === 0 ? " is-invalid" : "") }>
                </div>
                { isSubmitted && stockRequest.stockRequestItems.length === 0 && (
                    <div className="invalid-feedback-custom">
                      Request items are required.
                    </div>
                ) }

                <FormGroup className={ "mb-3" }>
                  <Label htmlFor="">Note</Label>
                  <Input
                      type="textarea"
                      name="note"
                      rows={ 3 }
                      placeholder="Note..."
                      value={ stockRequest.note }
                      onChange={ handleOnChange }
                  />
                </FormGroup>
              </CardBody>

            </Card>
            <Card className={ "mb-2" }>
              <CardBody>
                { [ 0 ].includes(stockRequest.status) && // Draft, Submitted
                    <Button color="primary" className={ "me-1" }
                            onClick={ handleSubmit }
                            type="submit">
                      Save
                    </Button>
                }

                { [ 0, 3, 5 ].includes(stockRequest.status) &&
                    stockRequest.id !== NIL &&
                    stockRequest.stockRequestItems &&
                    stockRequest.stockRequestItems.length > 0 && // Draft, Rejected and Cancel
                    <Button
                        type="button"
                        color="primary"
                        className="me-1"
                        onClick={ () => {
                          let valid = isValidateSubmit()
                          if (valid) {
                            setModalConfirmation(true)
                            setConfirmationType(SUBMIT)
                          }
                        } }
                    >
                      Submit
                    </Button>
                }

                { stockRequest.status === 1 && stockRequestType === 'default' && // Submitted
                    stockRequest.stockRequestItems &&
                    stockRequest.stockRequestItems.length > 0 &&
                    <Button
                        type="button"
                        color="success"
                        className="me-1"
                        onClick={ () => {
                          let valid = isValidateSubmit()
                          if (valid) {
                            setModalConfirmation(true)
                            setConfirmationType(APPROVE)
                          }
                        } }
                    >
                      Approve
                    </Button>
                }
                { stockRequest.status === 2 && stockRequestType === 'default' && // Approved
                    stockRequest.stockRequestItems &&
                    stockRequest.stockRequestItems.length > 0 &&
                    <Button
                        type="button"
                        color="success"
                        className="me-1"
                        onClick={ () => {
                          let valid = isValidateSubmit()
                          if (valid) {
                            setModalConfirmation(true)
                            setConfirmationType(CONFIRMED)
                          }
                        } }
                    >
                      Confirm
                    </Button>
                }

                { [ 1, 2 ].includes(stockRequest.status) && stockRequestType === 'default' &&
                    stockRequest.stockRequestItems &&
                    stockRequest.stockRequestItems.length > 0 &&
                    <Button
                        type="button"
                        color="danger"
                        className="me-1"
                        onClick={ () => {
                          setModalConfirmation(true)
                          setConfirmationType(REJECT)
                        } }
                    >
                      Reject
                    </Button>
                }

                { [ 1 ].includes(stockRequest.status) && // Submitted
                    <Button color="secondary"
                            onClick={ () => {
                              setModalConfirmation(true)
                              setConfirmationType(CANCEL)
                            } }
                            type="button">
                      Cancel
                    </Button>
                }
              </CardBody>
            </Card>

            <ConfirmationModal
                title="Confirmation"
                isOpen={ modalConfirmation }
                text={ getConfirmationText(confirmationType) }
                toggle={ () => setModalConfirmation(!modalConfirmation) }
                onSubmit={ handleOnSubmitWithStatus }
            />
            <StockRequestValidationModal
                title={ "Stock Request Validation Result" }
                isStockValidation={ true }
                isOpen={ modalValidation }
                toggle={ () => setModalValidation(false) }
                stockRequestItems={ stockRequestItems }/>

            <StockRequestValidationModal
                title={ "Stock Request Validation Accessory Limited Result" }
                isOpen={ modalValidationItemLimitation }
                isStockValidation={ false }
                toggle={ () => {
                  setModalValidationItemLimitation(false)
                  setStockRequestItems([])
                } }
                stockRequestItems={ stockRequestItems }/>

            <PrintPreviewModal
                isOpen={ modalPrintPreview }
                stockRequestId={ stockRequest.id }
                toggle={ () => setModalPrintPreview(!modalPrintPreview) }/>
          </Col>
        </Row>
      </>
  )
}

StockRequestForm.propTypes = {
  item: PropTypes.object,
  invalid: PropTypes.bool,
  validationType: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitWithStatus: PropTypes.func,
  onCancel: PropTypes.func,
  stockRequestType: PropTypes.string
}

export default StockRequestForm