import { get, post, del, put as update } from "helpers/api_helper";

const createRequest = async data => {
  return await post("/api/Manufacturer", data);
};
const getRequest = async () => {
  return await get("/api/Manufacturer");
};
const getByIdRequest = async id => {
  return await get(`/api/Manufacturer/${id}`);
};

export const manufacturerService = {
  createRequest,
  getRequest,
  getByIdRequest,
};
