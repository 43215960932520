// ITEM_SHIPPING_FEE
export const GET_ITEM_SHIPPING_FEES = "GET_ITEM_SHIPPING_FEES";
export const GET_ITEM_SHIPPING_FEES_SUCCESS = "GET_ITEM_SHIPPING_FEES_SUCCESS";
export const GET_ITEM_SHIPPING_FEES_FAIL = "GET_ITEM_SHIPPING_FEES_FAIL";

export const GET_ITEM_SHIPPING_FEE_BY_ID = "GET_ITEM_SHIPPING_FEE_BY_ID";
export const GET_ITEM_SHIPPING_FEE_BY_ID_SUCCESS =
  "GET_ITEM_SHIPPING_FEE_BY_ID_SUCCESS";

export const ADD_NEW_ITEM_SHIPPING_FEE = "ADD_NEW_ITEM_SHIPPING_FEE";

export const UPDATE_ITEM_SHIPPING_FEE = "UPDATE_ITEM_SHIPPING_FEE";

export const DELETE_ITEM_SHIPPING_FEE = "DELETE_ITEM_SHIPPING_FEE";

export const ITEM_SHIPPING_FEE_CLEAR_ERROR = "ITEM_SHIPPING_FEE_CLEAR_ERROR";
