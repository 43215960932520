import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "toastr/build/toastr.min.css";
import { toastMessage } from "helpers/utils";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import {
  getOrderTracking,
  addNewOrderTracking,
  getOrderTrackingById,
  updateOrderTracking,
  deleteOrderTracking,
  clearOrderTrackingNotify,
} from "../../store/order-tracking/actions";

import OrderTrackingTable from "../../containers/order/OrderTrackingTable";
import OrderTrackingForm from "../../containers/order/OrderTrackingForm";

class OrderTrackingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      orderId: props.orderId,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getOrderTracking({
      page: 1,
      pageSize: 10,
      term: "",
      orderId: this.state.orderId,
    });
  }

  componentDidUpdate() {
    const { message, clearOrderTrackingNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearOrderTrackingNotify();
    }
  }

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = item => {
    item.orderId = this.state.orderId;
    this.props.addNewOrderTracking(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleOnEdit = id => {
    var data = {};
    data.orderId = this.state.orderId;
    data.id = id;
    this.props.getOrderTrackingById(data);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleUpdate = item => {
    item.orderId = this.state.orderId;
    this.props.updateOrderTracking(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    var data = {};
    data.orderId = this.state.orderId;
    data.id = this.state.id;
    this.props.deleteOrderTracking(data);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  handleOnSearch = e => {
    const { orderId } = this.state;
    const { value } = e.target;
    this.props.getOrderTracking({ term: value ?? "", orderId });
  };

  handleOnPageChange = page => {
    const { pageSize, orderId } = this.state;
    this.props.getOrderTracking({
      page,
      pageSize,
      orderId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { orderId } = this.state;
    this.props.getOrderTracking({
      page: 1,
      pageSize,
      orderId,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        {items && (
          <OrderTrackingTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onAddNew={this.handleOnAddNew}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onSearch={this.handleOnSearch}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
        <OrderTrackingForm
          title={"Add New Tracking"}
          isOpen={this.state.modalAddIsOpen}
          toggle={this.toggleModalAdd}
          onSubmit={this.handleSubmit}
        />

        {item && (
          <OrderTrackingForm
            title={"Edit Tracking"}
            item={item}
            isOpen={this.state.modalEditIsOpen}
            toggle={this.toggleModalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

OrderTrackingList.propTypes = {
  message: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrderTracking: PropTypes.func,
  getOrderTrackingById: PropTypes.func,
  addNewOrderTracking: PropTypes.func,
  updateOrderTracking: PropTypes.func,
  deleteOrderTracking: PropTypes.func,
  clearOrderTrackingNotify: PropTypes.func,
  orderId: PropTypes.string,
};

const mapStateToProps = ({ orderTracking }) => {
  return orderTracking;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderTracking,
    addNewOrderTracking,
    getOrderTrackingById,
    updateOrderTracking,
    deleteOrderTracking,
    clearOrderTrackingNotify,
  })(OrderTrackingList)
);
