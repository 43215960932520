import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
import { customSingleValue, formatItemOptionLabel, getMaxValue } from "../../helpers/utils"
import { debounce } from "lodash"
import { getItemsRequest } from "../../store/item/saga"
import NumberFormat from "react-number-format"


const DesignFormTableRow = props => {
  const { clothingSizes, onAdd, onDelete, onChange, item, isSubmitted } = props

  const [ term, setTerm ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ itemOptions, setItemOptions ] = useState([])

  useEffect(() => {
    setLoading(true)
    getItemsRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        setLoading(false)
        setItemOptions(res.data.map((a, index) => {
          return {
            key: a.id,
            value: a.id,
            type: a.type,
            itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
            quantity: a.onHandQuantity,
            label: `${ a.code } - ${ a.name }`
          }
        }))
      }
    })
  }, [ term ])

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    onChange(item.itemId = valueType)
  }

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  const handleOnAdd = () => {
    onAdd()
  }

  const handleClothingSizeOnChange = (values, clothingSize) => {
    const { value } = values
    clothingSize.quantity = value ? Number(value) : 0

    item.clothingSizes = item.clothingSizes.map(size => {
      return size.id === clothingSize.id ? clothingSize : size
    })

    onChange(item)
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    onChange({
      ...item,
      isSelected: checked
    })
  }

  return (
      <>
        <tr>
          <td className={ "input text-center pt-2" }>
            <input
                type="checkbox"
                className="form-check-input"
                checked={ item.isSelected }
                readOnly={ true }
                onChange={ handleCheckboxChange }
            />
          </td>
          <td className={ "input" }>
            <Select
                name="itemId"
                value={ item.itemId }
                placeholder={ "Find by Code, Name, ..." }
                className={ isSubmitted && !item.itemId ? "is-invalid" : '' }
                onChange={ handleSelectChange }
                options={ itemOptions }
                components={ {
                  SingleValue: customSingleValue
                } }
                onInputChange={ handleInputChange }
                formatOptionLabel={ formatItemOptionLabel }
                classNamePrefix="select2-selection"
                isLoading={ loading }
                // isClearable
            />
            { isSubmitted && !item.itemId &&
                <div className="invalid-feedback-custom">
                  Item is required.
                </div>
            }
          </td>

          {
            clothingSizes.map((size, index) => {
              let sizeItem = item.clothingSizes.find(e => e.id === size.id)
              if (sizeItem) {
                return <td key={ index } className={ "input" }>
                  <NumberFormat
                      value={sizeItem.quantity}
                      displayType="input"
                      className={"form-control text-end"}
                      placeholder={"0"}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      onValueChange={values => handleClothingSizeOnChange(values, sizeItem)}
                  />
                </td>
              } else {
                return <td key={ index } className={ "input" }>
                  <Input
                      type="number"
                      name="quantity"
                      className={ "text-end" }
                      max={ 1000 }
                      min={ 0 }
                      value={ 1000 }
                      onChange={ (e) => handleClothingSizeOnChange(e, size) }
                  />
                </td>
              }

            })
          }

          <td>
            <div className="d-flex gap-3 justify-content-center">
              <Link className="text-danger" to="#">
                <i className="fas fa-trash"
                   onClick={ () => {
                     onDelete(item)
                   } }
                ></i>
              </Link>
            </div>
          </td>
        </tr>
      </>
  )
}

DesignFormTableRow.propTypes = {
  item: PropTypes.object,
  clothingSizes: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  isSubmitted: PropTypes.bool,
}

export default DesignFormTableRow