import {
  GET_INVOICE_NOTE,
  GET_INVOICE_NOTE_SUCCESS,
  GET_INVOICE_NOTE_FAIL,
  GET_INVOICE_NOTE_BY_ID,
  GET_INVOICE_NOTE_BY_ID_SUCCESS,
  ADD_NEW_INVOICE_NOTE,
  ADD_NEW_INVOICE_NOTE_SUCCESS,
  UPDATE_INVOICE_NOTE,
  UPDATE_INVOICE_NOTE_SUCCESS,
  DELETE_INVOICE_NOTE,
  DELETE_INVOICE_NOTE_SUCCESS,
  INVOICE_NOTE_CLEAR_NOTIFY,
} from "./actionTypes";

export const getInvoiceNote = id => ({
  type: GET_INVOICE_NOTE,
  payload: id,
});

export const getInvoiceNoteSuccess = items => ({
  type: GET_INVOICE_NOTE_SUCCESS,
  payload: items,
});

export const getInvoiceNoteFail = error => ({
  type: GET_INVOICE_NOTE_FAIL,
  payload: error,
});

export const getInvoiceNoteById = data => ({
  type: GET_INVOICE_NOTE_BY_ID,
  payload: data,
});

export const getInvoiceNoteByIdSuccess = item => ({
  type: GET_INVOICE_NOTE_BY_ID_SUCCESS,
  payload: item,
});

export const getInvoiceNoteByIdFail = error => ({
  type: GET_INVOICE_NOTE_BY_ID_FAIL,
  payload: error,
});

export const addNewInvoiceNote = payload => ({
  type: ADD_NEW_INVOICE_NOTE,
  payload,
});

export const addNewInvoiceNoteSuccess = payload => ({
  type: ADD_NEW_INVOICE_NOTE_SUCCESS,
  payload,
});

export const updateInvoiceNote = payload => ({
  type: UPDATE_INVOICE_NOTE,
  payload,
});

export const updateInvoiceNoteSuccess = payload => ({
  type: UPDATE_INVOICE_NOTE_SUCCESS,
  payload,
});

export const deleteInvoiceNote = payload => ({
  type: DELETE_INVOICE_NOTE,
  payload,
});

export const deleteInvoiceNoteSuccess = payload => ({
  type: DELETE_INVOICE_NOTE_SUCCESS,
  payload,
});

export const clearInvoiceNoteNotify = () => ({
  type: INVOICE_NOTE_CLEAR_NOTIFY,
});
