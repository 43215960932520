import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
  getSupplier,
  getSupplierById,
  updateSupplier,
  addNewSupplier,
  deleteSupplier,
  clearSupplierNotify,
} from "store/actions";
import SupplierTable from "./SupplierTable";
import ModalForm from "./ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";
import { debounce } from "lodash";

const SupplierList = props => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  );

  const { getSupplier, message, items, item, loading, itemCount, pageCount } =
    props;

  useEffect(() => {
    getSupplier({ page, pageSize, term });
  }, [term, page, pageSize]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearSupplierNotify();
    }
  }, [message]);

  const toggleModalEdit = () => {
    setModalEditIsOpen(!modalEditIsOpen);
  };

  const toggleModalAdd = () => {
    setModalAddIsOpen(!modalAddIsOpen);
  };

  const toggleModalConfirmDelete = () => {
    setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen);
  };

  const handleOnEdit = id => {
    const { getSupplierById } = props;
    getSupplierById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = supplier => {
    const { updateSupplier } = props;
    updateSupplier(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = supplier => {
    const { addNewSupplier } = props;
    addNewSupplier(supplier);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    const { deleteSupplier } = props;
    deleteSupplier(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnPageChange = page => {
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPageSize(pageSize);
    setPage(1);
  };

  const handleOnSearch = e => {
    const { value } = e.target;
    debouncedFetchData(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Supplier | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Supplier" breadcrumbItem="Supplier List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={handleOnSearch}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <SupplierTable
                  items={items}
                  itemCount={itemCount}
                  currentPage={page}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onEdit={handleOnEdit}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={handleOnPageChange}
                  onPageSizeChange={handleOnPageSizeChange}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Supplier"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={toggleModalEdit}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Supplier"}
                isOpen={modalAddIsOpen}
                toggle={toggleModalAdd}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={toggleModalConfirmDelete}
                onSubmit={handleDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SupplierList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getSupplier: PropTypes.func,
  getSupplierById: PropTypes.func,
  updateSupplier: PropTypes.func,
  addNewSupplier: PropTypes.func,
  deleteSupplier: PropTypes.func,
  clearSupplierNotify: PropTypes.func,
};

const mapStateToProps = ({ supplier }) => {
  return supplier;
};

export default withRouter(
  connect(mapStateToProps, {
    getSupplier,
    getSupplierById,
    updateSupplier,
    addNewSupplier,
    deleteSupplier,
    clearSupplierNotify,
  })(SupplierList)
);
