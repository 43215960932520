import { SEVERITY } from "helpers/utils.js";
import {
  GET_PRODUCT_STOCK_OUTS,
  GET_PRODUCT_STOCK_OUTS_SUCCESS,
  GET_PRODUCT_STOCK_OUT_BY_ID_SUCCESS,
  GET_PRODUCT_STOCK_OUTS_ERROR,
  PRODUCT_STOCK_OUT_CLEAR_ERROR,
  GET_CONFIRM_PRODUCT_STOCK_OUT,
  GET_CONFIRM_PRODUCT_STOCK_OUT_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const productStockOut = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIRM_PRODUCT_STOCK_OUT:
    case GET_PRODUCT_STOCK_OUTS:
      return {
        ...state,
        loading: true,
      };
    case GET_CONFIRM_PRODUCT_STOCK_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
        message: {
          title: "Stock Out",
          text: "Stock Out has been confirmed successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };
    case GET_PRODUCT_STOCK_OUTS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_PRODUCT_STOCK_OUT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_PRODUCT_STOCK_OUTS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Code");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Item is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case PRODUCT_STOCK_OUT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default productStockOut;
