import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {
  getFinishedGoodss,
  clearPurchaseOrderError,
  generatePurchaseOrderProductStockIn
} from "store/actions";
import {
  Button,
  Card,
  CardBody, CardHeader, CardTitle, Col, Input, Row,

  Table,
} from "reactstrap"

import {toastMessage} from "helpers/utils";
import NumberFormat from "react-number-format";
import {debounce, sumBy} from "lodash";


// FinishedGoods
const FinishedGoodsTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");

  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
      useState(false);
  const {
    getFinishedGoodss,
    generatePurchaseOrderProductStockIn,
    clearPurchaseOrderError,
    purchaseOrderId,
    finishedGoods,
    purchaseOrder,
  } = props;

  const {
    items,
    clothingSizes,
    purchaseOrderItems,
    hasProductStockIn,
    loading,
  } = finishedGoods;
  
  const {
    generating
  } = purchaseOrder;

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term);
        setPage(1);
      }, 1000),
      []
  );

  useEffect(() => {
    getFinishedGoodss({purchaseOrderId, term, page, pageSize});
  }, [page, pageSize, term]);

  useEffect(() => {
    if (purchaseOrder.message) {
      toastMessage(purchaseOrder.message);
      clearPurchaseOrderError();
    }
  }, [purchaseOrder.message]);
  

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={12}>
                <div className="text-sm-end">
                  
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
            <>
              <Card className="mb-2">
                <CardHeader className="bg-transparent border-bottom">
                  <CardTitle>Summary</CardTitle>
                </CardHeader>
                <CardBody>
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={{width: "80px"}}>#</th>
                        <th className="text-center">Item Code</th>
                        {clothingSizes.map((clothingSize, index) => {
                          return (
                              <th key={index} className="text-center">
                                {clothingSize?.name}
                              </th>
                          );
                        })}

                        <th className="text-center">Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      {purchaseOrderItems.map((purchaseOrderItem, index) => {
                        const {itemId} = purchaseOrderItem;
                        let num = (page - 1) * pageSize + (index + 1);

                        let total = 0;

                        return (
                            <tr key={index}>
                              <td style={{textAlign: "right"}}>{num}</td>
                              <td>
                                {purchaseOrderItem.itemCode} -{" "}
                                {purchaseOrderItem.itemName}
                              </td>
                              {clothingSizes.map((clothingSize, index) => {
                                let findItems = items.filter(
                                    e =>
                                        e.itemId === itemId &&
                                        e.clothingSizeId === clothingSize?.id
                                );
                                let quantity = findItems
                                    ? sumBy(findItems, findItem => {
                                      return findItem.quantity;
                                    })
                                    : 0;

                                total += quantity;

                                return (
                                    <td key={index} className="text-end">
                                      <NumberFormat
                                          value={quantity}
                                          displayType="text"
                                          thousandSeparator={true}
                                          fixedDecimalScale={false}
                                      />
                                    </td>
                                );
                              })}

                              <td className="text-end">
                                <NumberFormat
                                    value={total}
                                    displayType="text"
                                    thousandSeparator={true}
                                    fixedDecimalScale={false}
                                />
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </>
        )}
      </div>
  );
};

FinishedGoodsTab.propTypes = {
  purchaseOrderId: PropTypes.string,
  finishedGoods: PropTypes.object,
  purchaseOrder: PropTypes.object,
  stockAdjustmentStatus: PropTypes.string,
  getFinishedGoodss: PropTypes.func,
  generatePurchaseOrderProductStockIn: PropTypes.func,
  clearPurchaseOrderError: PropTypes.func,
};

const mapStateToProps = ({finishedGoods, purchaseOrder}) => {
  return {
    finishedGoods,
    purchaseOrder
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getFinishedGoodss,
      generatePurchaseOrderProductStockIn,
      clearPurchaseOrderError
    })(FinishedGoodsTab)
);
