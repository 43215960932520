import { SEVERITY } from "helpers/utils.js";
import {
  GET_ORDER_VERIFY_TRACKING,
  GET_ORDER_VERIFY_TRACKING_SUCCESS,
  GET_ORDER_VERIFY_TRACKING_FAIL,
  GET_ORDER_VERIFY_TRACKING_BY_ID_SUCCESS,
  ADD_NEW_ORDER_VERIFY_TRACKING,
  ADD_NEW_ORDER_VERIFY_TRACKING_SUCCESS,
  UPDATE_ORDER_VERIFY_TRACKING,
  UPDATE_ORDER_VERIFY_TRACKING_SUCCESS,
  DELETE_ORDER_VERIFY_TRACKING,
  DELETE_ORDER_VERIFY_TRACKING_SUCCESS,
  ORDER_VERIFY_TRACKING_CLEAR_NOTIFY,
  BULK_ORDER_VERIFY_TRACKING_SUCCESS,
  BULK_ORDER_VERIFY_TRACKING,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  scanVerifiedIds: [],
  manualVerifiedIds: [],
  unverifiedIds: [],
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const orderVerifyTracking = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_ORDER_VERIFY_TRACKING:
    case UPDATE_ORDER_VERIFY_TRACKING:
    case DELETE_ORDER_VERIFY_TRACKING:
    case GET_ORDER_VERIFY_TRACKING:
    case BULK_ORDER_VERIFY_TRACKING:
      return {
        ...state,
        loading: true,
      };
    case BULK_ORDER_VERIFY_TRACKING_SUCCESS:
      return {
        ...state,
        loadin: false,
        message: {
          title: "Verify Order Tracking",
          text: "Verify Order Tracking has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_ORDER_VERIFY_TRACKING_SUCCESS:
      const {
        scanVerifiedIds,
        manualVerifiedIds,
        unverifiedIds,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        scanVerifiedIds,
        manualVerifiedIds,
        unverifiedIds,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ORDER_VERIFY_TRACKING_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case ADD_NEW_ORDER_VERIFY_TRACKING_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Note",
          text: "Invoice Note has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_ORDER_VERIFY_TRACKING_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Note",
          text: "Invoice Note has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELETE_ORDER_VERIFY_TRACKING_SUCCESS:
      return {
        ...state,
        message: {
          title: "Invoice Note",
          text: "Invoice Note has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_ORDER_VERIFY_TRACKING_FAIL:
      return {
        ...state,
        error: payload,
      };
    case ORDER_VERIFY_TRACKING_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default orderVerifyTracking;
