import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_LOCATION,
  GET_LOCATION_BY_ID,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_PAGING_LOCATION,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import {
  getLocationByIdSuccess,
  getPagingLocationError,
  getPagingLocationSuccess,
} from "./actions";

const getPagingLocationRequest = query =>
  get("/api/Location/paging", { params: query });
const addNewLocationRequest = item => post("/api/Location", item);
const getLocationByIdRequest = id => get(`/api/Location/${id}`);
const updateLocationRequest = item => update("/api/Location", item);
const deleteLocationRequest = id => del(`/api/Location/${id}`);

const getLocationDropdownRequest = () =>
    get("/api/Location/dropdown");


function* getPagingLocation({ payload }) {
  try {
    const response = yield call(getPagingLocationRequest, payload);
    yield put(getPagingLocationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingLocationError(error));
  }
}

function* addNewLocation({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(addNewLocationRequest, data);
    
    const response = yield call(getPagingLocationRequest, queryParams);
    yield put(getPagingLocationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingLocationError(error));
  }
}

function* updateLocation({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(updateLocationRequest, data);
    const response = yield call(getPagingLocationRequest, queryParams);
    yield put(getPagingLocationSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingLocationError(error));
  }
}

function* getLocationById({ payload }) {
  try {
    let response = yield call(getLocationByIdRequest, payload);
    yield put(getLocationByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingLocationError(error));
  }
}

function* deleteLocation({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(deleteLocationRequest, data);
    const response = yield call(getPagingLocationRequest, queryParams);
    yield put(getPagingLocationSuccess(response));
  } catch (error) {
    const err = error?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPagingLocationError(err));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PAGING_LOCATION, getPagingLocation);
  yield takeEvery(ADD_NEW_LOCATION, addNewLocation);
  yield takeEvery(GET_LOCATION_BY_ID, getLocationById);
  yield takeEvery(UPDATE_LOCATION, updateLocation);
  yield takeEvery(DELETE_LOCATION, deleteLocation);
}

export default itemSaga;

export {
  getLocationDropdownRequest
}
