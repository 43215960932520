import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  getSewings,
} from "store/actions";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import NumberFormat from "react-number-format";
import { debounce, sumBy } from "lodash";

// Sewing
const SewingTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const {
    getSewings,
    purchaseOrderId,
    sewing,
  } = props;

  const {
    items,
    clothingSizes,
    purchaseOrderItems,
    loading,
  } = sewing;

  
  return (
      <div>
        {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
            <>
              <Card className="mb-2">
                <CardBody>
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={{ width: "80px" }}>#</th>
                        <th className="text-center">Item Code</th>
                        {clothingSizes.map((clothingSize, index) => {
                          return (
                              <th key={index} className="text-center">
                                {clothingSize.name}
                              </th>
                          );
                        })}

                        <th className="text-center">Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      {purchaseOrderItems.map((purchaseOrderItem, index) => {
                        const { itemId } = purchaseOrderItem;
                        let num = (page - 1) * pageSize + (index + 1);

                        let total = 0;

                        return (
                            <tr key={index}>
                              <td style={{ textAlign: "right" }}>{num}</td>
                              <td>
                                {purchaseOrderItem.itemCode} -{" "}
                                {purchaseOrderItem.itemName}
                              </td>
                              {clothingSizes.map((clothingSize, index) => {
                                let findItems = items.filter(
                                    e =>
                                        e.itemId === itemId &&
                                        e.clothingSizeId === clothingSize.id
                                );
                                let quantity = findItems
                                    ? sumBy(findItems, findItem => {
                                      return findItem.quantity;
                                    })
                                    : 0;

                                total += quantity;

                                return (
                                    <td key={index} className="text-end">
                                      <NumberFormat
                                          value={quantity}
                                          displayType="text"
                                          thousandSeparator={true}
                                          fixedDecimalScale={false}
                                      />
                                    </td>
                                );
                              })}

                              <td className="text-end">
                                <NumberFormat
                                    value={total}
                                    displayType="text"
                                    thousandSeparator={true}
                                    fixedDecimalScale={false}
                                />
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              
            </>
        )}
      </div>
  );
};

SewingTab.propTypes = {
  purchaseOrderId: PropTypes.string,
  sewing: PropTypes.object,
  stockAdjustmentStatus: PropTypes.string,
  getSewings: PropTypes.func,
};

const mapStateToProps = ({ sewing }) => {
  return {
    sewing,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getSewings,
    })(SewingTab)
);
