import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Badge, Table } from "reactstrap";

export class OrderVerityTrackingNoCheckBoxTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
    };
  }

  renderVerify = item => {
    const { verified } = item;
    if (verified) {
      return (
        <Badge color="success" className="p-1">
          <i className="fas fa-check-circle"></i> Verified
        </Badge>
      );
    }
  };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      onChangePage,
      onPageSizeChange,
      items,
    } = this.props;

    return (
      <>
        <div>
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered table-nowrap align-middle"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Tracking Number</th>
                <th>Verified Type</th>
                <th>Verified By</th>
                <th>Verified Date</th>
                <th>Verified?</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((item, index) => {
                  let num = (currentPage - 1) * defaultPageSize + (index + 1);
                  const { verifiedDate } = item;
                  return (
                    <tr key={index}>
                      <td>{num}</td>
                      <td>{item.trackingNumber}</td>
                      <td>{item.verifiedTypeName}</td>
                      <td>{item.verifiedByUser}</td>
                      <td>
                        {verifiedDate &&
                          moment
                            .utc(verifiedDate)
                            .local()
                            .format("DD-MMM-yyyy h:mm:ss a")}
                      </td>
                      <td>{this.renderVerify(item)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 15, 20, 25, 30]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </>
    );
  }
}

OrderVerityTrackingNoCheckBoxTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onChange: PropTypes.func,
  showCheckbox: PropTypes.bool,
};

export default OrderVerityTrackingNoCheckBoxTable;
