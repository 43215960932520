import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_CUTTING,
  GET_CUTTING_BY_ID,
  UPDATE_CUTTING,
  DELETE_CUTTING,
  GET_CUTTINGS, GET_CUTTING_ENTRY_FORM, SAVE_CUTTING_ENTRY_FORM,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCuttingByIdSuccess, getCuttingEntryFormSuccess,
  getCuttingsError,
  getCuttingsSuccess, saveCuttingEntryFormSuccess,
} from "./actions"

const getCuttingsRequest = query =>
  get("/api/Cutting/paging", { params: query });
const addNewCuttingRequest = item => post("/api/Cutting", item);
const getCuttingByIdRequest = id => get(`/api/Cutting/${id}`);
const updateCuttingRequest = item => update("/api/Cutting", item);
const deleteCuttingRequest = id => del(`/api/Cutting/${id}`);

const getCuttingEntryFormRequest = id => get(`/api/Cutting/entryForm/${id}`);
const saveCuttingEntryFormRequest = item => post("/api/Cutting/entryForm", item);

function* getCuttings({ payload }) {
  try {
    const response = yield call(getCuttingsRequest, payload);
    yield put(getCuttingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* getCuttingEntryForm({ payload }) {
  try {
    const response = yield call(getCuttingEntryFormRequest, payload);
    yield put(getCuttingEntryFormSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* addNewCutting({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(addNewCuttingRequest, payload);
    const response = yield call(getCuttingsRequest, {
      purchaseOrderId,
    });
    yield put(getCuttingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* saveCuttingEntryForm({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(saveCuttingEntryFormRequest, payload);
    const response = yield call(getCuttingsRequest, {
      purchaseOrderId,
    });
    yield put(getCuttingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* updateCutting({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(updateCuttingRequest, payload);
    const response = yield call(getCuttingsRequest, {
      purchaseOrderId,
    });
    yield put(getCuttingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* getCuttingById({ payload }) {
  try {
    var response = yield call(getCuttingByIdRequest, payload);
    yield put(getCuttingByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* deleteCutting({ payload }) {
  try {
    const { purchaseOrderId, id } = payload;
    yield call(deleteCuttingRequest, id);
    const response = yield call(getCuttingsRequest, {
      purchaseOrderId,
    });
    yield put(getCuttingsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCuttingsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_CUTTINGS, getCuttings);
  yield takeEvery(GET_CUTTING_ENTRY_FORM, getCuttingEntryForm);
  yield takeEvery(SAVE_CUTTING_ENTRY_FORM, saveCuttingEntryForm);
  yield takeEvery(ADD_NEW_CUTTING, addNewCutting);
  yield takeEvery(GET_CUTTING_BY_ID, getCuttingById);
  yield takeEvery(UPDATE_CUTTING, updateCutting);
  yield takeEvery(DELETE_CUTTING, deleteCutting);
}

export default itemSaga;
