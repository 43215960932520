import {
  GET_DELIVERY_ORDERS,
  GET_DELIVERY_ORDERS_SUCCESS,
  GET_DELIVERY_ORDERS_FAIL,
  GET_DELIVERY_ORDER_BY_ID,
  GET_DELIVERY_ORDER_BY_ID_SUCCESS,
  ADD_NEW_DELIVERY_ORDER,
  UPDATE_DELIVERY_ORDER,
  DELETE_DELIVERY_ORDER,
  DELIVERY_ORDER_CLEAR_NOTIFY,
  ADD_NEW_DELIVERY_ORDER_SUCCESS,
  ADD_NEW_DELIVERY_ORDER_ERROR,
  UPDATE_DELIVERY_ORDER_SUCCESS,
  UPDATE_DELIVERY_ORDER_ERROR,
  DELETE_DELIVERY_ORDER_SUCCESS,
  DELETE_DELIVERY_ORDER_ERROR,
} from "./actionTypes";

// DeliveryOrder
export const getDeliveryOrders = query => ({
  type: GET_DELIVERY_ORDERS,
  payload: query,
});

export const getDeliveryOrdersSuccess = items => ({
  type: GET_DELIVERY_ORDERS_SUCCESS,
  payload: items,
});

export const getDeliveryOrdersFail = error => ({
  type: GET_DELIVERY_ORDERS_FAIL,
  payload: error,
});

export const getDeliveryOrderById = id => ({
  type: GET_DELIVERY_ORDER_BY_ID,
  payload: id,
});

export const getDeliveryOrderByIdSuccess = item => ({
  type: GET_DELIVERY_ORDER_BY_ID_SUCCESS,
  payload: item,
});

export const getDeliveryOrderByIdFail = error => ({
  type: GET_DELIVERY_ORDER_BY_ID_FAIL,
  payload: error,
});

export const addNewDeliveryOrder = item => ({
  type: ADD_NEW_DELIVERY_ORDER,
  payload: item,
});

export const addNewDeliveryOrderSuccess = item => ({
  type: ADD_NEW_DELIVERY_ORDER_SUCCESS,
  payload: item,
});

export const addNewDeliveryOrderError = item => ({
  type: ADD_NEW_DELIVERY_ORDER_ERROR,
  payload: item,
});

export const updateDeliveryOrder = item => ({
  type: UPDATE_DELIVERY_ORDER,
  payload: item,
});

export const updateDeliveryOrderSuccess = item => ({
  type: UPDATE_DELIVERY_ORDER_SUCCESS,
  payload: item,
});
export const updateDeliveryOrderError = item => ({
  type: UPDATE_DELIVERY_ORDER_ERROR,
  payload: item,
});

export const deleteDeliveryOrder = item => ({
  type: DELETE_DELIVERY_ORDER,
  payload: item,
});

export const deleteDeliveryOrderSuccess = item => ({
  type: DELETE_DELIVERY_ORDER_SUCCESS,
  payload: item,
});

export const deleteDeliveryOrderError = item => ({
  type: DELETE_DELIVERY_ORDER_ERROR,
  payload: item,
});

export const clearDeliveryOrderNotify = () => ({
  type: DELIVERY_ORDER_CLEAR_NOTIFY,
});
