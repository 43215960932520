import {
  GET_PRODUCT_STOCK_IN_PRODUCTS,
  GET_PRODUCT_STOCK_IN_PRODUCTS_SUCCESS,
  GET_PRODUCT_STOCK_IN_PRODUCTS_ERROR,
  GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID,
  GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_STOCK_IN_PRODUCT,
  ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_STOCK_IN_PRODUCT,
  UPDATE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS,
  DELETE_PRODUCT_STOCK_IN_PRODUCT,
  DELETE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS,
  PRODUCT_STOCK_IN_PRODUCT_CLEAR_ERROR,
  ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_ERROR,
} from "./actionTypes";

// ProductStockInProduct
export const getProductStockInProducts = query => ({
  type: GET_PRODUCT_STOCK_IN_PRODUCTS,
  payload: query,
});

export const getProductStockInProductsSuccess = payload => ({
  type: GET_PRODUCT_STOCK_IN_PRODUCTS_SUCCESS,
  payload,
});

export const getProductStockInProductsError = error => ({
  type: GET_PRODUCT_STOCK_IN_PRODUCTS_ERROR,
  payload: error,
});

export const getProductStockInProductById = id => ({
  type: GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID,
  payload: id,
});

export const getProductStockInProductByIdSuccess = payload => ({
  type: GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID_SUCCESS,
  payload,
});

export const addNewProductStockInProduct = item => ({
  type: ADD_NEW_PRODUCT_STOCK_IN_PRODUCT,
  payload: item,
});

export const addNewProductStockInProductSuccess = item => ({
  type: ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_SUCCESS,
  payload: item,
});

export const addNewProductStockInProductError = error => ({
  type: ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_ERROR,
  payload: error,
});

export const updateProductStockInProduct = item => ({
  type: UPDATE_PRODUCT_STOCK_IN_PRODUCT,
  payload: item,
});

export const updateProductStockInProductSuccess = item => ({
  type: UPDATE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS,
  payload: item,
});

export const deleteProductStockInProduct = item => ({
  type: DELETE_PRODUCT_STOCK_IN_PRODUCT,
  payload: item,
});

export const deleteProductStockInProductSuccess = item => ({
  type: DELETE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS,
  payload: item,
});

export const clearProductStockInProductError = () => ({
  type: PRODUCT_STOCK_IN_PRODUCT_CLEAR_ERROR,
});
