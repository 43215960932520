import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import Rating from "react-rating";
import { Link } from "react-router-dom";

class FeedbackTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onViewDetail,
      onChangePage,
      onPageSizeChange,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Action</th>
                      <th>User</th>
                      <th>Comment</th>
                      <th>Rating</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);

                      return (
                        <tr
                          key={index}
                          onDoubleClick={() => onViewDetail(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{num}</td>
                          <td>
                            <div className="text-center">
                              <Link
                                to="#"
                                className="text-primary"
                                onClick={() => onViewDetail(item.id)}
                              >
                                <i className="fas fa-list font-size-18"></i>
                              </Link>
                            </div>
                          </td>
                          <td>{item.user}</td>
                          <td>{item.comment}</td>
                          <td>
                            <Rating
                              max={5}
                              initialRating={item.rating}
                              emptySymbol="mdi mdi-star-outline text-danger fa-1x"
                              fullSymbol="mdi mdi-star text-danger fa-1x"
                              fractions={2}
                            />
                          </td>
                          <td>
                            {moment
                              .utc(item.createdDate)
                              .local()
                              .format("DD-MMM-yyyy h:mm:ss a")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

FeedbackTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default FeedbackTable;
