import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_BANNERS,
  ADD_NEW_BANNER,
  GET_BANNER_BY_ID,
  UPDATE_BANNER,
  DELETE_BANNER,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getBannersSuccess,
  getBannerByIdSuccess,
  getBannersFail,
} from "./actions";

const getBannersRequest = query => get("/api/banner/paging", { params: query });
const addNewBannerRequest = banner => post("/api/banner", banner);
const getBannerByIdRequest = id => get(`/api/banner/${id}`);
const updateBannerRequest = banner => update("/api/banner", banner);
const deleteBannerRequest = id => del(`/api/banner/${id}`);

function* fetchBanners({ payload }) {
  try {
    const response = yield call(getBannersRequest, payload);
    yield put(getBannersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getBannersFail(error));
  }
}

function* addNewBanner({ payload }) {
  try {
    yield call(addNewBannerRequest, payload);
    const response = yield call(getBannersRequest, {});
    yield put(getBannersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getBannersFail(error));
  }
}

function* updateBanner({ payload }) {
  try {
    yield call(updateBannerRequest, payload);
    const response = yield call(getBannersRequest, {});
    yield put(getBannersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getBannersFail(error));
  }
}

function* getBannerById({ payload }) {
  try {
    var response = yield call(getBannerByIdRequest, payload);
    yield put(getBannerByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getBannersFail(error));
  }
}

function* deleteBanner({ payload }) {
  try {
    yield call(deleteBannerRequest, payload);
    const response = yield call(getBannersRequest, {});
    yield put(getBannersSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getBannersFail(error));
  }
}

function* bannerSaga() {
  yield takeEvery(GET_BANNERS, fetchBanners);
  yield takeEvery(ADD_NEW_BANNER, addNewBanner);
  yield takeEvery(GET_BANNER_BY_ID, getBannerById);
  yield takeEvery(UPDATE_BANNER, updateBanner);
  yield takeEvery(DELETE_BANNER, deleteBanner);
}

export default bannerSaga;
