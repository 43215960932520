import {
  GET_PURCHASE_ORDER_ATTACHMENTS,
  GET_PURCHASE_ORDER_ATTACHMENTS_SUCCESS,
  GET_PURCHASE_ORDER_ATTACHMENTS_ERROR,
  GET_PURCHASE_ORDER_ATTACHMENT_BY_ID,
  GET_PURCHASE_ORDER_ATTACHMENT_BY_ID_SUCCESS,
  ADD_NEW_PURCHASE_ORDER_ATTACHMENT,
  ADD_NEW_PURCHASE_ORDER_ATTACHMENT_SUCCESS,
  UPDATE_PURCHASE_ORDER_ATTACHMENT,
  UPDATE_PURCHASE_ORDER_ATTACHMENT_SUCCESS,
  DELETE_PURCHASE_ORDER_ATTACHMENT,
  DELETE_PURCHASE_ORDER_ATTACHMENT_SUCCESS,
  PURCHASE_ORDER_ATTACHMENT_CLEAR_ERROR,
  ADD_NEW_PURCHASE_ORDER_ATTACHMENT_ERROR,
} from "./actionTypes"

// PurchaseOrderAttachment
export const getPurchaseOrderAttachments = query => ({
  type: GET_PURCHASE_ORDER_ATTACHMENTS,
  payload: query,
});

export const getPurchaseOrderAttachmentsSuccess = payload => ({
  type: GET_PURCHASE_ORDER_ATTACHMENTS_SUCCESS,
  payload,
});

export const getPurchaseOrderAttachmentsError = error => ({
  type: GET_PURCHASE_ORDER_ATTACHMENTS_ERROR,
  payload: error,
});

export const getPurchaseOrderAttachmentById = id => ({
  type: GET_PURCHASE_ORDER_ATTACHMENT_BY_ID,
  payload: id,
});

export const getPurchaseOrderAttachmentByIdSuccess = payload => ({
  type: GET_PURCHASE_ORDER_ATTACHMENT_BY_ID_SUCCESS,
  payload,
});

export const addNewPurchaseOrderAttachment = item => ({
  type: ADD_NEW_PURCHASE_ORDER_ATTACHMENT,
  payload: item,
});

export const addNewPurchaseOrderAttachmentSuccess = item => ({
  type: ADD_NEW_PURCHASE_ORDER_ATTACHMENT_SUCCESS,
  payload: item,
});

export const addNewPurchaseOrderAttachmentError = error => ({
  type: ADD_NEW_PURCHASE_ORDER_ATTACHMENT_ERROR,
  payload: error,
});

export const updatePurchaseOrderAttachment = item => ({
  type: UPDATE_PURCHASE_ORDER_ATTACHMENT,
  payload: item,
});

export const updatePurchaseOrderAttachmentSuccess = item => ({
  type: UPDATE_PURCHASE_ORDER_ATTACHMENT_SUCCESS,
  payload: item,
});

export const deletePurchaseOrderAttachment = item => ({
  type: DELETE_PURCHASE_ORDER_ATTACHMENT,
  payload: item,
});

export const deletePurchaseOrderAttachmentSuccess = item => ({
  type: DELETE_PURCHASE_ORDER_ATTACHMENT_SUCCESS,
  payload: item,
});

export const clearPurchaseOrderAttachmentError = () => ({
  type: PURCHASE_ORDER_ATTACHMENT_CLEAR_ERROR,
});
