import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Alert } from "reactstrap";

const ConfirmationModal = ({ isOpen, toggle, title, text, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Alert color="warning">{text}</Alert>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={onSubmit}>
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfirmationModal;