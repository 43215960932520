import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getPurchaseOrderById, getItemUnitOfMeasurements } from "store/actions";
import DesignSummaryCard from "../PurchaseOrder/DesignSummaryCard"
import PackagingFormTab from "./PackagingFormTab"
import PackagingTab from "../PurchaseOrder/PackagingTab"
import PackagingItemTab from "./PackagingItemTab"

const PackagingDetail = props => {
  const { match, history } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [id, setId] = useState(null);

  const { getPurchaseOrderById, purchaseOrder } = props;
  const { item, loading } = purchaseOrder;

  useEffect(() => {
    const { id } = match.params;
    getPurchaseOrderById(id);
    setId(id);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Packaging detail | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {item ? (
                <>
                  <Breadcrumbs
                    title="Home"
                    backLink={"/packaging"}
                    breadcrumbItem={`${item.code} | Packaging Detail`}
                  />
                  <Row>
                    <Col md={12}>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              setActiveTab("1");
                              //   getItemUnitOfMeasurements({ itemId: id });
                            }}
                          >
                            Packaging Item
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          {id ?<>
                            <PackagingItemTab purchaseOrderId={id}/>
                          </>  : null}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

PackagingDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  purchaseOrder: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getPurchaseOrderById: PropTypes.func,
};

const mapStateToProps = ({ purchaseOrder }) => {
  return {
    purchaseOrder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPurchaseOrderById,
    getItemUnitOfMeasurements,
  })(PackagingDetail)
);
