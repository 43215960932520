import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from "reactstrap"
import { useReactToPrint } from "react-to-print"
import { getAllItemsRequest } from "../../store/item/saga"
import ItemsToPrint from "./ItemsToPrint"

const ItemPrintPreviewModal = props => {
  const {
    title,
    isOpen,
    toggle,
    term,
    type,
    locations,
    categories,
    sortField,
    toggleSort,
  } = props

  const [ items, setItems ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      console.log("onBeforeGetContent print")
    },
    onAfterPrint: () => {
      console.log("After print")
      componentRef.current = null
      // setIsPrint(false);
    }
  })

  const onOpened = () => {
    setLoading(true)
    getAllItemsRequest({
      term,
      type: type?.value ?? null,
      locationIds: locations.map(a => {
        return a.value
      }),
      categoryIds: categories?.map(a => {
        return a.value
      }),
      sortBy: sortField,
      sortAsc: toggleSort,
    }).then(res => {
      setItems(res)
      setLoading(false)
    })
  }

  return (
      <Modal
          size="xl"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Print Preview
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {
            loading ?
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div> :
                <div style={ { height: "350px", overflow: "auto", background: "gray", padding: "10px" } }>
                  {
                      items.length > 0 &&
                      <ItemsToPrint ref={ componentRef } items={ items }/>
                  }
                </div>
          }
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handlePrint }>
            <i className="mdi mdi-printer"/> Print Now
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

ItemPrintPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.func,
  term: PropTypes.string,
  type: PropTypes.string,
  locations: PropTypes.array,
  categories: PropTypes.array,
  sortField: PropTypes.string,
  toggleSort: PropTypes.bool,
}

export default ItemPrintPreviewModal