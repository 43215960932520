import { SEVERITY } from "helpers/utils.js";
import {
  GET_ITEM_USAGE_LIMITATIONS,
  GET_ITEM_USAGE_LIMITATIONS_SUCCESS,
  GET_ITEM_USAGE_LIMITATION_BY_ID_SUCCESS,
  GET_ITEM_USAGE_LIMITATIONS_ERROR,
  ITEM_USAGE_LIMITATION_CLEAR_ERROR,
  GET_ITEM_USAGE_LIMITATION_BY_ID,
  ADD_NEW_ITEM_USAGE_LIMITATION,
  UPDATE_ITEM_USAGE_LIMITATION,
  DELETE_ITEM_USAGE_LIMITATION,
  SAVE_BULK_ITEM_USAGE_LIMITATION, SAVE_BULK_ITEM_USAGE_LIMITATION_SUCCESS,
} from "./actionTypes.js"
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const stockRequestItem = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_ITEM_USAGE_LIMITATION:
    case SAVE_BULK_ITEM_USAGE_LIMITATION:
    case UPDATE_ITEM_USAGE_LIMITATION:
    case GET_ITEM_USAGE_LIMITATIONS:
      return {
        ...state,
        loading: true,
        item: null,
      };
    case GET_ITEM_USAGE_LIMITATIONS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        item: null,
        loading: false,
      };
    case SAVE_BULK_ITEM_USAGE_LIMITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Item",
          text: 'Items have been saved successfully.',
          severity: SEVERITY.SUCCESS,
        },
      }
    case ADD_NEW_ITEM_USAGE_LIMITATION:
      return {
        ...state,
        item: null,
      };
    case GET_ITEM_USAGE_LIMITATION_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_ITEM_USAGE_LIMITATION_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_ITEM_USAGE_LIMITATIONS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case ITEM_USAGE_LIMITATION_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default stockRequestItem;
