import {
  GET_FINISHED_GOODSS,
  GET_FINISHED_GOODSS_SUCCESS,
  GET_FINISHED_GOODSS_ERROR,
  GET_FINISHED_GOODS_BY_ID,
  GET_FINISHED_GOODS_BY_ID_SUCCESS,
  ADD_NEW_FINISHED_GOODS,
  ADD_NEW_FINISHED_GOODS_SUCCESS,
  UPDATE_FINISHED_GOODS,
  UPDATE_FINISHED_GOODS_SUCCESS,
  DELETE_FINISHED_GOODS,
  DELETE_FINISHED_GOODS_SUCCESS,
  FINISHED_GOODS_CLEAR_ERROR,
  ADD_NEW_FINISHED_GOODS_ERROR,
  GET_FINISHED_GOODS_ENTRY_FORM,
  GET_FINISHED_GOODS_ENTRY_FORM_SUCCESS,
  SAVE_FINISHED_GOODS_ENTRY_FORM, SAVE_FINISHED_GOODS_ENTRY_FORM_SUCCESS, CHANGE_STATUS_FINISHED_GOODS,
} from "./actionTypes"

// FinishedGoods
export const getFinishedGoodss = query => ({
  type: GET_FINISHED_GOODSS,
  payload: query,
});

export const getFinishedGoodssSuccess = payload => ({
  type: GET_FINISHED_GOODSS_SUCCESS,
  payload,
});

export const getFinishedGoodssError = error => ({
  type: GET_FINISHED_GOODSS_ERROR,
  payload: error,
});

export const getFinishedGoodsById = id => ({
  type: GET_FINISHED_GOODS_BY_ID,
  payload: id,
});

export const getFinishedGoodsByIdSuccess = payload => ({
  type: GET_FINISHED_GOODS_BY_ID_SUCCESS,
  payload,
});

export const addNewFinishedGoods = item => ({
  type: ADD_NEW_FINISHED_GOODS,
  payload: item,
});

export const addNewFinishedGoodsSuccess = item => ({
  type: ADD_NEW_FINISHED_GOODS_SUCCESS,
  payload: item,
});

export const addNewFinishedGoodsError = error => ({
  type: ADD_NEW_FINISHED_GOODS_ERROR,
  payload: error,
});

export const updateFinishedGoods = item => ({
  type: UPDATE_FINISHED_GOODS,
  payload: item,
});

export const updateFinishedGoodsSuccess = item => ({
  type: UPDATE_FINISHED_GOODS_SUCCESS,
  payload: item,
});

export const deleteFinishedGoods = item => ({
  type: DELETE_FINISHED_GOODS,
  payload: item,
});

export const deleteFinishedGoodsSuccess = item => ({
  type: DELETE_FINISHED_GOODS_SUCCESS,
  payload: item,
});

export const clearFinishedGoodsError = () => ({
  type: FINISHED_GOODS_CLEAR_ERROR,
});

export const getFinishedGoodsEntryForm = query => ({
  type: GET_FINISHED_GOODS_ENTRY_FORM,
  payload: query,
});

export const getFinishedGoodsEntryFormSuccess = payload => ({
  type: GET_FINISHED_GOODS_ENTRY_FORM_SUCCESS,
  payload,
});

export const saveFinishedGoodsEntryForm = query => ({
  type: SAVE_FINISHED_GOODS_ENTRY_FORM,
  payload: query,
});

export const saveFinishedGoodsEntryFormSuccess = payload => ({
  type: SAVE_FINISHED_GOODS_ENTRY_FORM_SUCCESS,
  payload,
});

export const changeStatusFinishedGoods = item => ({
  type: CHANGE_STATUS_FINISHED_GOODS,
  payload: item,
});

