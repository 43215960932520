import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDER_VERIFY_TRACKING,
  ADD_NEW_ORDER_VERIFY_TRACKING,
  GET_ORDER_VERIFY_TRACKING_BY_ID,
  UPDATE_ORDER_VERIFY_TRACKING,
  DELETE_ORDER_VERIFY_TRACKING,
  BULK_ORDER_VERIFY_TRACKING,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderVerifyTrackingSuccess,
  getOrderVerifyTrackingByIdSuccess,
  getOrderVerifyTrackingFail,
  addNewOrderVerifyTrackingSuccess,
  updateOrderVerifyTrackingSuccess,
  deleteOrderVerifyTrackingSuccess,
  verifyBulkOrderVerifyTrackingSuccess,
  verifyBulkOrderVerifyTrackingError,
} from "./actions";

const getOrderVerifyTrackingRequest = query =>
  post(`/api/OrderVerifiedHistory/paging`, query);
const addNewOrderVerifyTrackingRequest = item =>
  post(`/api/OrderVerifyTracking`, item);
const getOrderVerifyTrackingByIdRequest = id =>
  get(`/api/OrderVerifyTracking/${id}`);
const updateOrderVerifyTrackingRequest = item =>
  update(`/api/OrderVerifyTracking`, item);
const deleteOrderVerifyTrackingRequest = id =>
  del(`/api/OrderVerifyTracking/${id}`);

const verifyBulkOrderVerifyTrackingRequest = item =>
  post(`/api/OrderVerifiedHistory/bulkVerify`, item);

function* fetchOrderVerifyTrackings({ payload }) {
  try {
    const response = yield call(getOrderVerifyTrackingRequest, payload);

    yield put(getOrderVerifyTrackingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderVerifyTrackingFail(error));
  }
}

function* addNewOrderVerifyTracking({ payload }) {
  try {
    yield call(addNewOrderVerifyTrackingRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getOrderVerifyTrackingRequest, { invoiceId });
    yield put(getOrderVerifyTrackingSuccess(response));
    yield put(addNewOrderVerifyTrackingSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderVerifyTrackingFail(error));
  }
}

function* updateOrderVerifyTracking({ payload }) {
  try {
    yield call(updateOrderVerifyTrackingRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getOrderVerifyTrackingRequest, { invoiceId });
    yield put(getOrderVerifyTrackingSuccess(response));
    yield put(updateOrderVerifyTrackingSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderVerifyTrackingFail(error));
  }
}

function* getOrderVerifyTrackingById({ payload }) {
  try {
    var response = yield call(getOrderVerifyTrackingByIdRequest, payload);
    yield put(getOrderVerifyTrackingByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderVerifyTrackingFail(error));
  }
}

function* deleteOrderVerifyTracking({ payload }) {
  try {
    const { id, invoiceId } = payload;
    yield call(deleteOrderVerifyTrackingRequest, id);

    const response = yield call(getOrderVerifyTrackingRequest, { invoiceId });
    yield put(getOrderVerifyTrackingSuccess(response));
    yield put(deleteOrderVerifyTrackingSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderVerifyTrackingFail(error));
  }
}

function* verifyBulkOrderVerifyTracking({ payload }) {
  try {
    const { orderId, data } = payload;
    console.log(orderId);
    yield call(verifyBulkOrderVerifyTrackingRequest, data);
    const response = yield call(getOrderVerifyTrackingRequest, { orderId });
    yield put(getOrderVerifyTrackingSuccess(response));
    yield put(verifyBulkOrderVerifyTrackingSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(verifyBulkOrderVerifyTrackingError(error));
  }
}

function* OrderVerifyTrackingSaga() {
  yield takeEvery(GET_ORDER_VERIFY_TRACKING, fetchOrderVerifyTrackings);
  yield takeEvery(ADD_NEW_ORDER_VERIFY_TRACKING, addNewOrderVerifyTracking);
  yield takeEvery(GET_ORDER_VERIFY_TRACKING_BY_ID, getOrderVerifyTrackingById);
  yield takeEvery(UPDATE_ORDER_VERIFY_TRACKING, updateOrderVerifyTracking);
  yield takeEvery(DELETE_ORDER_VERIFY_TRACKING, deleteOrderVerifyTracking);
  yield takeEvery(BULK_ORDER_VERIFY_TRACKING, verifyBulkOrderVerifyTracking);
}

export default OrderVerifyTrackingSaga;
