import {
  GET_USER_ITEM_SHIPPING_FEES,
  GET_USER_ITEM_SHIPPING_FEES_SUCCESS,
  GET_USER_ITEM_SHIPPING_FEES_FAIL,
  GET_USER_ITEM_SHIPPING_FEE_BY_ID,
  GET_USER_ITEM_SHIPPING_FEE_BY_ID_SUCCESS,
  ADD_NEW_USER_ITEM_SHIPPING_FEE,
  UPDATE_USER_ITEM_SHIPPING_FEE,
  DELETE_USER_ITEM_SHIPPING_FEE,
  USER_ITEM_SHIPPING_FEE_CLEAR_ERROR,
} from "./actionTypes";
// UserItemShippingFee
export const getUserItemShippingFees = query => ({
  type: GET_USER_ITEM_SHIPPING_FEES,
  payload: query,
});

export const getUserItemShippingFeesSuccess = items => ({
  type: GET_USER_ITEM_SHIPPING_FEES_SUCCESS,
  payload: items,
});

export const getUserItemShippingFeesFail = error => ({
  type: GET_USER_ITEM_SHIPPING_FEES_FAIL,
  payload: error,
});

export const getUserItemShippingFeeById = id => ({
  type: GET_USER_ITEM_SHIPPING_FEE_BY_ID,
  payload: id,
});

export const getUserItemShippingFeeByIdSuccess = item => ({
  type: GET_USER_ITEM_SHIPPING_FEE_BY_ID_SUCCESS,
  payload: item,
});

export const getUserItemShippingFeeByIdFail = error => ({
  type: GET_USER_ITEM_SHIPPING_FEE_BY_ID_FAIL,
  payload: error,
});

export const addNewUserItemShippingFee = item => ({
  type: ADD_NEW_USER_ITEM_SHIPPING_FEE,
  payload: item,
});

export const updateUserItemShippingFee = item => ({
  type: UPDATE_USER_ITEM_SHIPPING_FEE,
  payload: item,
});

export const deleteUserItemShippingFee = item => ({
  type: DELETE_USER_ITEM_SHIPPING_FEE,
  payload: item,
});

export const clearUserItemShippingFeeError = () => ({
  type: USER_ITEM_SHIPPING_FEE_CLEAR_ERROR,
});
