import { SEVERITY } from "helpers/utils.js";
import {
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEM_BY_ID_SUCCESS,
  GET_ITEMS_ERROR,
  ITEM_CLEAR_ERROR,
  GET_ITEM_BY_ID, GET_PAGING_ITEM_OUT_HISTORY, GET_PAGING_ITEM_OUT_HISTORY_SUCCESS,
} from "./actionTypes.js"
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const Category = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAGING_ITEM_OUT_HISTORY:
    case GET_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEM_BY_ID:
      return {
        ...state,
        item: null,
      };
      
    case GET_PAGING_ITEM_OUT_HISTORY_SUCCESS:
    case GET_ITEMS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_ITEMS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Code");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Item is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case ITEM_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default Category;
