import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from "react-number-format"
import { sumBy } from "lodash"

const ItemsToPrint = React.forwardRef((props, ref) => {
  const { items } = props
  
  return (
      <div ref={ ref }>
        <div className="print-container">
          <div className="print-content">
            <h4 className={"text-center mb-4"}>Accessory List</h4>
            <table className="table-print">
              <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Code</th>
                <th>Item Description</th>
                <th>Type</th>
                <th>On Hand</th>
              </tr>
              </thead>
              <tbody>

              { items.map((item, index) => {
                const { itemAttachments } = item
                let itemAttachment = itemAttachments.length > 0 ? itemAttachments[0] : null


                let num = (index + 1)

                const { locations, categories, itemUnitOfMeasurements } = item
                

                let itemUomGroups = Object.groupBy(itemUnitOfMeasurements.filter(e => !e.isBase), a => {
                  return a.unitOfMeasurementName
                })

                let baseItemUom = itemUnitOfMeasurements.find(e => e.isBase)
                let totalBase = sumBy(itemUnitOfMeasurements, a => {
                  return a.onHandQuantity * a.conversionRate
                });
                
                // if(itemUnitOfMeasurements.length === 0 || totalBase === 0) return

                return (
                    <tr key={ index } onDoubleClick={ () => onDetails(item.id) } style={ { cursor: "pointer" } }>
                      <td style={ { textAlign: "right" } }>{ num }</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          {
                            itemAttachment ?
                                <img style={ { width: "80px", height: "50px", objectFit: "contain" } }
                                     src={ itemAttachment.url } alt=""/> : <>
                                  <div className="avatar-order">
                                    <i className="fas fa-socks text-primary"></i>
                                  </div>
                                </>
                          }
                          </div>
                      </td>
                      <td>{ item.code }</td>
                      <td>{ item.name }</td>
                      <td>{ item.type }</td>
                      <td>
                        <ul className="mb-0">
                          { baseItemUom &&
                              <li>
                                <div className={ "d-flex justify-content-between" }>
                                  <span className={ "me-3" }>Total { baseItemUom?.unitOfMeasurementName } :</span>
                                  <NumberFormat
                                      className={ "fw-bolder" }
                                      value={ totalBase.toFixed(2) }
                                      displayType="text"
                                      thousandSeparator={ true }
                                      fixedDecimalScale={ false }
                                  />
                                </div>
                              </li>
                          }
                          {
                            Object.entries(itemUomGroups).map(([ key, value ]) => {
                              let total = sumBy(value, a => {
                                return a.onHandQuantity
                              })

                              return <li key={ key }>
                                <div className={ "d-flex justify-content-between" }>
                                  <span className={ "me-3" }>Total { key } :</span>
                                  <NumberFormat
                                      className={ "fw-bolder" }
                                      value={ total }
                                      displayType="text"
                                      thousandSeparator={ true }
                                      fixedDecimalScale={ false }
                                  />
                                </div>
                              </li>
                            })
                          }
                        </ul>
                      </td>
                    </tr>
                )
              }) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
  )
})

ItemsToPrint.propTypes = {
  items: PropTypes.array,
}

ItemsToPrint.displayName="ItemsToPrint";

export default ItemsToPrint