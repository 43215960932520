import DeviceInfoNotificationHistoryTable from "containers/device-info/DeviceInfoNotificationHistoryTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card, CardHeader, Col, Input, Row } from "reactstrap";
import { getDeviceInfoNotificationHistory } from "store/actions";

export class NotificationHistoryTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pageSize: 10,
      deviceInfoId: null,
    };
  }

  componentDidMount = () => {
    const { deviceInfoId } = this.props;
    this.setState({
      deviceInfoId,
    });
  };

  handleOnPageChange = page => {
    const { deviceInfoId, pageSize } = this.state;
    this.props.getDeviceInfoNotificationHistory({
      deviceInfoId,
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { deviceInfoId } = this.state;
    this.props.getDeviceInfoNotificationHistory({
      deviceInfoId,
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnSearch = e => {
    const { getDeviceInfoNotificationHistory } = this.props;
    const { deviceInfoId } = this.props;
    const { value } = e.target;
    this.setState({ term: value });
    getDeviceInfoNotificationHistory({
      term: value ?? "",
      deviceInfoId,
    });
  };

  render() {
    const { deviceInfoNotificationHistory } = this.props;
    const { items, itemCount, pageCount, loading } =
      deviceInfoNotificationHistory;
    const { pageSize, currentPage } = this.state;
    return (
      <>
        <Card className="mb-1 rounded-3">
          <CardHeader className="bg-transparent border pb-0 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <DeviceInfoNotificationHistoryTable
          items={items}
          itemCount={itemCount}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          totalPage={pageCount}
          loading={loading}
          onChangePage={this.handleOnPageChange}
          onPageSizeChange={this.handleOnPageSizeChange}
        />
      </>
    );
  }
}

NotificationHistoryTab.propTypes = {
  deviceInfoId: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  deviceInfoNotificationHistory: PropTypes.object,
  getDeviceInfoNotificationHistory: PropTypes.func,
};

const mapStateToProps = ({ deviceInfoNotificationHistory }) => {
  return {
    deviceInfoNotificationHistory,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getDeviceInfoNotificationHistory,
  })(NotificationHistoryTab)
);
