import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Input, Table } from "reactstrap";

export class OrderVerifyTrackingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
    };
  }

  componentDidMount() {
    const { items } = this.props;
    if (items && items.length > 0) {
      this.setState({ items });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { items } = props;

    if (items && items.length > 0 && state.items && state.items.length > 0) {
      if (items[0].id !== state.items[0].id) {
        return items;
      }
    }
    return null;
  }

  handleHeaderCheck = e => {
    const { checked } = e.target;
    const { items } = this.state;

    let newItems = items.map(a => {
      return {
        ...a,
        verified: checked,
      };
    });

    this.setState({
      isHeaderCheck: checked,
      verified: checked,
      items: newItems,
    });

    this.props.onChange(newItems);
  };

  handleRowCheck = (id, e) => {
    const { checked } = e.target;
    const { items } = this.state;
    let newItems = items.map(a => {
      if (a.id === id && checked) {
        return {
          ...a,
          verified: true,
        };
      } else if (a.id === id && !checked) {
        return {
          ...a,
          verified: false,
        };
      } else {
        return a;
      }
    });

    this.setState({
      isSelected: newItems.some(a => a.verified),
      items: newItems,
    });

    this.props.onChange(newItems);
  };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      onChangePage,
      onPageSizeChange,
      showCheckbox,
    } = this.props;
    const { items } = this.state;
    return (
      <>
        <div>
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered table-nowrap align-middle"
          >
            <thead>
              <tr>
                {showCheckbox && (
                  <th>
                    <Input
                      type="checkbox"
                      checked={this.state.isHeaderCheck}
                      onClick={this.handleHeaderCheck}
                      readOnly
                    />
                  </th>
                )}

                <th>Tracking Number</th>
                <th>Verified Type</th>
                <th>Verified By</th>
                <th>Verified Date</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((item, index) => {
                  const { verifiedDate } = item;
                  return (
                    <tr key={index}>
                      {showCheckbox && (
                        <td>
                          <Input
                            name={`order_verified_${item.id}`}
                            onClick={e => this.handleRowCheck(item.id, e)}
                            checked={item.verified}
                            type="checkbox"
                            readOnly
                          />
                        </td>
                      )}
                      <td>{item.trackingNumber}</td>
                      <td>{item.verifiedTypeName}</td>
                      <td>{item.verifiedByUser}</td>
                      <td>
                        {verifiedDate &&
                          moment
                            .utc(verifiedDate)
                            .local()
                            .format("DD-MMM-yyyy h:mm:ss a")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 15, 20, 25, 30]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </>
    );
  }
}

OrderVerifyTrackingTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onChange: PropTypes.func,
  showCheckbox: PropTypes.bool,
};

export default OrderVerifyTrackingTable;
