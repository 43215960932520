import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDER_ITEMS,
  ADD_NEW_ORDER_ITEM,
  GET_ORDER_ITEM_BY_ID,
  UPDATE_ORDER_ITEM,
  DELETE_ORDER_ITEM,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderItemsSuccess,
  getOrderItemByIdSuccess,
  getOrderItemsFail,
} from "./actions";
import { getOrderByIdSuccess } from "store/actions";
// OrderItem
const getOrderItemsRequest = query =>
  get("/api/OrderItem/paging", { params: query });
const addNewOrderItemRequest = role => post("/api/OrderItem", role);
const getOrderItemByIdRequest = id => get(`/api/OrderItem/${id}`);
const updateOrderItemRequest = role => update("/api/OrderItem", role);
const deleteOrderItemRequest = id => del(`/api/OrderItem/${id}`);

const getOrderByIdRequest = id => get(`/api/Order/${id}`);

function* fetchOrderItems({ payload }) {
  try {
    const response = yield call(getOrderItemsRequest, payload);
    yield put(getOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderItemsFail(error));
  }
}

function* addNewOrderItem({ payload }) {
  try {
    yield call(addNewOrderItemRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderItemsRequest, { orderId });
    yield put(getOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderItemsFail(error));
  }
}

function* updateOrderItem({ payload }) {
  try {
    yield call(updateOrderItemRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderItemsRequest, { orderId });
    yield put(getOrderItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderItemsFail(error));
  }
}

function* getOrderItemById({ payload }) {
  try {
    var response = yield call(getOrderItemByIdRequest, payload);
    yield put(getOrderItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderItemsFail(error));
  }
}

function* deleteOrderItem({ payload }) {
  try {
    const { id, orderId } = payload;
    yield call(deleteOrderItemRequest, id);
    const response = yield call(getOrderItemsRequest, { orderId });
    yield put(getOrderItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderItemsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ORDER_ITEMS, fetchOrderItems);
  yield takeEvery(ADD_NEW_ORDER_ITEM, addNewOrderItem);
  yield takeEvery(GET_ORDER_ITEM_BY_ID, getOrderItemById);
  yield takeEvery(UPDATE_ORDER_ITEM, updateOrderItem);
  yield takeEvery(DELETE_ORDER_ITEM, deleteOrderItem);
}

export default roleSaga;
