// DELIVERER
export const GET_DELIVERER = "GET_DELIVERER";
export const GET_DELIVERER_SUCCESS = "GET_DELIVERER_SUCCESS";
export const GET_DELIVERER_FAIL = "GET_DELIVERER_FAIL";

export const GET_DELIVERER_BY_ID = "GET_DELIVERER_BY_ID";
export const GET_DELIVERER_BY_ID_SUCCESS = "GET_DELIVERER_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERER = "ADD_NEW_DELIVERER";
export const ADD_NEW_DELIVERER_SUCCESS = "ADD_NEW_DELIVERER_SUCCESS";

export const UPDATE_DELIVERER = "UPDATE_DELIVERER";
export const UPDATE_DELIVERER_SUCCESS = "UPDATE_DELIVERER_SUCCESS";

export const DELETE_DELIVERER = "DELETE_DELIVERER";
export const DELETE_DELIVERER_SUCCESS = "DELETE_DELIVERER_SUCCESS";

export const DELIVERER_CLEAR_NOTIFY = "DELIVERER_CLEAR_NOTIFY";
