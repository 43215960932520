import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "toastr/build/toastr.min.css";
import { Card, CardBody, Input } from "reactstrap";

import { getOrderNotificationHistory } from "../../store/order-notification-history/actions";

import OrderNotificationHistoryTable from "../../containers/order-notification-history/OrderNotificationHistoryTable";

class OrderNotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  componentDidMount() {
    const { orderId, getOrderNotificationHistory } = this.props;
    getOrderNotificationHistory({
      page: 1,
      pageSize: 10,
      term: "",
      orderId,
    });
  }

  handleOnChangePage = page => {
    const { orderId, getOrderNotificationHistory } = this.props;
    const { pageSize } = this.state;
    getOrderNotificationHistory({ orderId, page, pageSize });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = size => {
    const { orderId, getOrderNotificationHistory } = this.props;
    getOrderNotificationHistory({ orderId, page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };

  handleOnSearch = e => {
    const { orderId, getOrderNotificationHistory } = this.props;
    const { value } = e.target;
    getOrderNotificationHistory({ term: value ?? "", orderId });

    this.setState({
      term: value ?? "",
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, itemCount, pageCount, loading } = this.props;

    return (
      <>
        <>
          <Card className="mb-2">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={this.handleOnSearch}
                      ></Input>
                      <i className="fas fa-search search-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {items && (
                <Card>
                  <CardBody>
                    <OrderNotificationHistoryTable
                      items={items}
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={pageCount}
                      defaultPageSize={pageSize}
                      onChangePage={this.handleOnChangePage}
                      onPageSizeChange={this.handleOnPageSizeChange}
                    />
                  </CardBody>
                </Card>
              )}
            </>
          )}
        </>
      </>
    );
  }
}

OrderNotificationList.propTypes = {
  message: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrderNotificationHistory: PropTypes.func,
  orderId: PropTypes.string,
};

const mapStateToProps = ({ orderNotificationHistory }) => {
  return orderNotificationHistory;
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderNotificationHistory,
  })(OrderNotificationList)
);
