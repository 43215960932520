import React, { Component } from "react";
import { NIL, NIL as NIL_UUID } from "uuid";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

import UserSelectionModalList from "./UserSelectionModalList";
import SelectionListModal from "containers/shipping-contact/SelectionListModal";
import ModalFormShippingContact from "containers/shipping-contact/ModalFormShippingContact";

import { userService } from "services/user-service";
import { categoryService } from "services/category-service";
import { currencyService } from "services/currency-service";
import { unitService } from "services/unit-service";
import { itemShippingFeeService } from "services/item-shipping-fee-service";
import OrderItemRow from "containers/order/OrderItemRow";
import { getMaxValue } from "helpers/utils";
import {
  addNewShippingContactRequest,
  getDefaultShippingContactRequest,
  getShippingContactByIdRequest,
  getShippingContactRequest,
} from "store/shipping-contact/saga";
import _, { join } from "lodash";
import OrderAdditionalEntryForm from "containers/order/OrderAdditionalEntryForm";
const transportationTypeOptions = [
  { key: 1, value: "Sea", label: "Sea" },
  { key: 2, value: "Air", label: "Air" },
];

const deliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Korea Warehouse" },
  { key: 2, value: 8, label: "Ready for delivery to Cambodia" },
  { key: 3, value: 2, label: "Delivery To Cambodia" },
  { key: 4, value: 3, label: "In Cambodia Warehouse" },
  { key: 5, value: 4, label: "Delivery To Customer" },
  { key: 6, value: 5, label: "Completed" },
  { key: 7, value: 6, label: "Rejected" },
  { key: 8, value: 7, label: "Closed" },
];

const paymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

const typeOptions = [
  { key: 0, value: 0, label: "Box" },
  { key: 1, value: 1, label: "Unit" },
  { key: 2, value: 2, label: "Pallet" },
];

const formatOptionLabel = ({ label, phones, address }) => {
  let phoneArr = phones
    ? phones.map(phone => {
        return phone.phoneNumber;
      })
    : [];
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-1">
        <i className="fas fa-user"></i>: <strong>{label}</strong>
      </span>
      <span className="mb-1">
        <i className="fas fa-phone"></i>: {join(phoneArr, ", ")}
      </span>
      <span className="mb-1">
        <i className="fas fa-address-book"></i>: {address}
      </span>
    </div>
  );
};

export class OrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL_UUID,
      senderId: null,
      receiverId: null,
      transportationType: { key: 1, value: "Sea", label: "Sea" },
      deliveryStatus: { key: 0, value: 0, label: "Draft" },
      paymentStatus: { key: 0, value: 0, label: "Draft" },
      trackingNumber: "",
      destination: "",
      description: "",
      price: 0.0,
      currencyId: null,
      quantity: 0,
      unitId: null,
      weight: 0,
      weightExtra: 0,
      weightComment: "",
      totalAmount: 0.0,
      itemPriceFeeAmount: 0.0,
      weightFeeAmount: 0.0,
      discountPercentage: 0.0,
      discountAmount: 0.0,
      originalReference: "",
      type: { key: 0, value: 0, label: "Box" },
      orderItems: [],
      unitFee: 0.0,
      unitFeeAmount: 0.0,
      isDelivery: false,
      isPickup: false,
      deliveryFee: 0.0,
      pickupFee: 0.0,
      pickupPaymentStatus: 0,
      deliveryPaymentStatus: 0,
      cbmCurrencyId: null,
      cbmExchangeRate: 0,
      exchangeRate: 0,
      cbmRate: 0,
      orderAdditionalEntries: [
        {
          id: NIL,
          index: 0,
          orderId: null,
          currencyId: null,
          title: "",
          amount: 0,
        },
      ],

      addItemModalIsOpen: false,
      modalEditItem: false,
      modalDeleteOrderItem: false,
      senderUsers: [],
      receiverUsers: [],
      currencyOptions: [],
      unitOptions: [],
      categoryOptions: [],
      categories: [],
      attachments: [],

      userSelectedIsOpen: false,
      modalSelectReceiver: false,
      modalSelectSender: false,
      modalAddShippingAddress: false,
      modalSelectionShippingAddress: false,

      selectedOrderItem: null,
      submitted: false,
      isGallery: false,
      photoIndex: 0,
      selectedId: null,
      totalVolume: 0,
      orderItemShippingFee: 0,
      orderItemShippingFeeKRW: 0,
      totalOrderAdditionalEntry: 0,
    };
  }

  handleWeightChange = (name, value) => {
    const {
      senderId,
      orderItemShippingFeeKRW,
      unitFeeAmount,
      pickupFee,
      deliveryFee,
      transportationType,
      orderAdditionalEntries,
      exchangeRate,
    } = this.state;

    let weight, weightExtra;

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries,
      exchangeRate,
    });

    this.setState({
      [name]: value,
    });

    switch (name) {
      case "weight":
        weight = value ?? 0;
        weightExtra = this.state.weightExtra;
        break;
      case "weightExtra":
        weight = this.state.weight;
        weightExtra = value ?? 0;
        break;
    }

    let totalWeight = Number(weight) + Number(weightExtra);

    if (senderId) {
      itemShippingFeeService
        .getByQuery({
          userId: senderId.value,
          transportationType: transportationType?.value ?? "Sea",
          type: 0, // Weight
          itemPrice: Number(totalWeight ?? 0),
        })
        .then(data => {
          const weightFeeAmount = totalWeight * Number(data.value ?? 0);

          const totalAmount =
            Number(orderItemShippingFeeKRW ?? 0) +
            Number(unitFeeAmount ?? 0) +
            weightFeeAmount +
            Number(pickupFee ?? 0) +
            Number(deliveryFee ?? 0) +
            Number(totalOrderAdditionalEntry);
          this.setState({
            weightFeeAmount,
            totalAmount: Number(totalAmount).toFixed(2),
          });
        });
    }
  };

  handlePriceChange = values => {
    const { value } = values;
    this.setState({
      price: value,
    });
  };

  toggleSelectReceiverModal = () => {
    this.setState(prevState => ({
      modalSelectReceiver: !prevState.modalSelectReceiver,
    }));
  };

  toggleSelectSenderModal = () => {
    this.setState(prevState => ({
      modalSelectSender: !prevState.modalSelectSender,
    }));
  };

  toggleUserSelect = () => {
    this.setState(prevState => ({
      userSelectedIsOpen: !prevState.userSelectedIsOpen,
    }));
  };

  toggleAddShippingAddress = () => {
    this.setState(prevState => ({
      modalAddShippingAddress: !prevState.modalAddShippingAddress,
    }));
  };

  toggleSelectionShippingAddress = () => {
    this.setState(prevState => ({
      modalSelectionShippingAddress: !prevState.modalSelectionShippingAddress,
    }));
  };

  handleSelectReceiverUser = user => {
    this.populateShippingAddress(user.id);
    this.setState({
      modalSelectReceiver: false,
      receiverId: {
        key: user.id,
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      },
    });
  };

  handleSelectSenderUser = user => {
    this.setState({
      modalSelectSender: false,
      senderId: {
        key: user.id,
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      },
    });
  };

  handleSelectSender = () => {
    this.setState({
      modalSelectSender: true,
    });
  };

  handleSelectReceiver = () => {
    this.setState({
      modalSelectReceiver: true,
    });
  };

  hanldeSubmitShippingAddress = shippingAddress => {
    addNewShippingContactRequest(shippingAddress)
      .then(id => {
        const { userId } = shippingAddress;
        getShippingContactByIdRequest(id).then(data => {
          if (data) {
            this.populateShippingAddress(userId);
            this.setState({
              modalAddShippingAddress: false,
              shippingContactId: {
                key: data.id,
                value: data.id,
                label: data.contact,
                phones: data.phones,
                address: data.address,
              },
            });
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSelectionShippingAddress = () => {
    this.setState({
      modalSelectionShippingAddress: true,
    });
  };

  handleSubmitSelectionShippingAddress = item => {
    if (item) {
      this.setState({
        modalSelectionShippingAddress: false,
        shippingContactId: {
          key: item.id,
          value: item.id,
          label: item.contact,
          address: item.address,
        },
      });
    }
  };

  populateShippingAddress = userId => {
    getShippingContactRequest({ userId }).then(res => {
      const { data } = res;
      this.setState({
        shippingAddressOptions: data.map(item => {
          const { id, contact, phones, address } = item;
          return {
            key: id,
            value: id,
            label: contact,
            phones,
            address,
          };
        }),
      });
    });
  };

  componentDidMount = () => {
    const { order } = this.props;

    if (order) {
      this.setValueToState(order);
    } else {
      currencyService.getByQueryRequest({ label: "USD" }).then(data => {
        if (data) {
          this.setState({
            cbmExchangeRate: data.rate,
            exchangeRate: data.rate,
            cbmCurrencyId: {
              key: data.id,
              value: data.id,
              label: data.symbol,
            },
          });
        }
      });
    }

    userService.getUsersRequest().then(records => {
      if (records) {
        const users = records.map(user => {
          return {
            key: user.id,
            value: user.id,
            label: `${user.firstName} ${user.lastName} | ${user.uniqueId}`,
          };
        });
        this.setState({
          senderUsers: users,
          receiverUsers: users,
        });
      }
    });

    categoryService.getRequest().then(data => {
      if (data) {
        this.setState({
          categoryOptions: data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.name,
              };
            }),
        });
      }
    });

    currencyService.getRequest().then(data => {
      if (data) {
        this.setState({
          currencyOptions: data
            .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.symbol,
              };
            }),
        });
      }
    });

    unitService.getRequest().then(data => {
      if (data) {
        this.setState({
          unitOptions: data.map(item => {
            return {
              key: item.id,
              value: item.id,
              label: item.name,
            };
          }),
        });
      }
    });

    currencyService.getDefaultRequest().then(data => {
      if (data) {
        this.setState({
          currencyId: {
            key: data.id,
            value: data.id,
            label: data.symbol,
          },
        });
      }
    });
  };

  calculateOrderAdditionalEntry = ({
    orderAdditionalEntries,
    exchangeRate,
  }) => {
    let totalOrderAdditionalEntry = 0;

    orderAdditionalEntries.forEach(item => {
      const { amount } = item;
      if (item.currencyId) {
        const { name } = item.currencyId;
        if (name === "USD") {
          totalOrderAdditionalEntry += Number(amount) * exchangeRate;
        } else {
          totalOrderAdditionalEntry += Number(amount);
        }
      }
    });

    return totalOrderAdditionalEntry;
  };

  static getDerivedStateFromProps(props, state) {
    const { order } = props;
    if (order) {
      if (order.id !== state.id) {
        const {
          orderItems,
          orderAdditionalEntries,
          cbmRate,
          exchangeRate,
          shippingContactObject,
        } = order;

        let totalVolume = _.sumBy(orderItems, a => {
          return a.volume;
        });

        let orderItemShippingFee = Number((totalVolume ?? 0) * cbmRate).toFixed(
          2
        );

        return {
          ...order,
          senderId: {
            key: order.senderId,
            value: order.senderId,
            label: order.sender,
          },
          receiverId: {
            key: order.receiverId,
            value: order.receiverId,
            label: order.receiver,
          },
          shippingContactId: order.shippingContactId
            ? {
                key: shippingContactObject.id,
                value: order.shippingContactId,
                label: shippingContactObject.contact,
                phones: shippingContactObject.phones,
                address: shippingContactObject.address,
              }
            : null,
          transportationType: order.transportationType
            ? transportationTypeOptions.find(
                e => e.value === order.transportationType
              )
            : null,
          deliveryStatus: {
            key: order.deliveryStatus,
            value: order.deliveryStatus,
            label: order.deliveryStatusName,
          },

          currencyId: {
            key: order.currencyId,
            value: order.currencyId,
            label: order.currency,
          },
          cbmCurrencyId: {
            key: order.cbmCurrencyId,
            value: order.cbmCurrencyId,
            label: order.cbmCurrency,
          },
          unitId: {
            key: order.unitId,
            value: order.unitId,
            label: order.unit,
          },
          type: typeOptions.find(e => e.value === order.type),
          categories: order.categories.map(category => {
            return {
              key: category.id,
              value: category.id,
              label: category.name,
            };
          }),
          totalVolume: Number(totalVolume).toFixed(4),
          orderItemShippingFee,
          orderItemShippingFeeKRW: orderItemShippingFee * exchangeRate,

          orderAdditionalEntries:
            orderAdditionalEntries && orderAdditionalEntries.length > 0
              ? orderAdditionalEntries.map(a => {
                  return {
                    ...a,
                    currencyId: a.currencyId
                      ? {
                          key: a.currencyId,
                          value: a.currencyId,
                          label: a.currencySymbol,
                          name: a.currencyLabel,
                        }
                      : {},
                  };
                })
              : [
                  {
                    id: NIL,
                    index: 0,
                    orderId: null,
                    currencyId: null,
                    title: "",
                    amount: 0,
                  },
                ],
        };
      }
    }
    return null;
  }

  setValueToState = item => {
    const {
      orderItems,
      orderAdditionalEntries,
      cbmRate,
      shippingContactObject,
      exchangeRate,
    } = item;

    let totalVolume = _.sumBy(orderItems, a => {
      return a.volume;
    });

    let newOrderAdditionalEntries =
      orderAdditionalEntries && orderAdditionalEntries.length > 0
        ? orderAdditionalEntries.map((a, index) => {
            return {
              ...a,
              index,
              currencyId: a.currencyId
                ? {
                    key: a.currencyId,
                    value: a.currencyId,
                    label: a.currencySymbol,
                    name: a.currencyLabel,
                  }
                : {},
            };
          })
        : [
            {
              id: NIL,
              index: 0,
              orderId: null,
              currencyId: null,
              title: "",
              amount: 0,
            },
          ];

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries: newOrderAdditionalEntries,
      exchangeRate,
    });

    console.log(totalOrderAdditionalEntry);

    let weightFeeAmount = Number(item.weightFeeAmount ?? 0);

    let unitFeeAmount = item.unitFeeAmount;
    let orderItemShippingFee = Number((totalVolume ?? 0) * cbmRate).toFixed(2);
    let orderItemShippingFeeKRW = orderItemShippingFee * exchangeRate;

    let totalAmount =
      Number(unitFeeAmount ?? 0) +
      weightFeeAmount +
      Number(item.pickupFee ?? 0) +
      Number(item.deliveryFee ?? 0) +
      Number(orderItemShippingFeeKRW) +
      Number(totalOrderAdditionalEntry);

    console.log(totalAmount);

    this.setState({
      ...item,
      senderId: {
        key: item.senderId,
        value: item.senderId,
        label: item.sender,
      },
      receiverId: {
        key: item.receiverId,
        value: item.receiverId,
        label: item.receiver,
      },
      shippingContactId: item.shippingContactId
        ? {
            key: shippingContactObject.id,
            value: item.shippingContactId,
            label: shippingContactObject.contact,
            phones: shippingContactObject.phones,
            address: shippingContactObject.address,
          }
        : null,
      transportationType: item.transportationType
        ? transportationTypeOptions.find(
            e => e.value === item.transportationType
          )
        : null,
      deliveryStatus: {
        key: item.deliveryStatus,
        value: item.deliveryStatus,
        label: item.deliveryStatusName,
      },
      paymentStatus: {
        key: item.paymentStatus,
        value: item.paymentStatus,
        label: item.paymentStatusName,
      },

      currencyId: {
        key: item.currencyId,
        value: item.currencyId,
        label: item.currency,
      },
      cbmCurrencyId: {
        key: item.cbmCurrencyId,
        value: item.cbmCurrencyId,
        label: item.cbmCurrency,
      },
      unitId: {
        key: item.unitId,
        value: item.unitId,
        label: item.unit,
      },
      type: typeOptions.find(e => e.value === item.type),
      categories: item.categories.map(category => {
        return {
          key: category.id,
          value: category.id,
          label: category.name,
        };
      }),

      orderAdditionalEntries: newOrderAdditionalEntries,
      totalVolume: Number(totalVolume).toFixed(4),
      orderItemShippingFee: Number(orderItemShippingFee ?? 0).toFixed(2),
      orderItemShippingFeeKRW,
      totalAmount: Number(totalAmount).toFixed(0),
    });

    if (item.receiverId) {
      this.populateShippingAddress(item.receiverId);
    }
  };

  handleSubmit = () => {
    this.setState({
      submitted: true,
    });

    const {
      id,
      senderId,
      receiverId,
      shippingContactId,
      transportationType,
      deliveryStatus,
      paymentStatus,
      destination,
      trackingNumber,
      description,
      price,
      currencyId,
      quantity,
      unitId,
      weight,
      weightExtra,
      weightComment,
      totalAmount,
      itemPriceFeeAmount,
      weightFeeAmount,
      discountPercentage,
      discountAmount,
      originalReference,
      attachments,
      categories,
      orderItems,
      type,
      unitFee,
      unitFeeAmount,
      isDelivery,
      isPickup,
      deliveryFee,
      pickupFee,
      pickupPaymentStatus,
      deliveryPaymentStatus,
      exchangeRate,
      cbmCurrencyId,
      cbmExchangeRate,
      cbmRate,
      orderAdditionalEntries,
    } = this.state;

    var isValid =
      senderId &&
      receiverId &&
      shippingContactId &&
      transportationType &&
      currencyId &&
      unitId &&
      type &&
      quantity > 0;
    if (isValid) {
      const data = {
        id,
        senderId: senderId.value,
        receiverId: receiverId.value,
        transportationType: transportationType?.value ?? null,
        deliveryStatus: deliveryStatus.value,
        paymentStatus: paymentStatus.value,
        shippingContactId: shippingContactId?.value ?? null,
        destination: destination,
        trackingNumber: trackingNumber,
        description,
        price,
        currencyId: currencyId?.value,
        quantity,
        unitId: unitId?.value,
        type: type.value,
        unitFee,
        unitFeeAmount,
        weight,
        weightExtra: weightExtra ?? 0,
        weightComment,
        totalAmount,
        isDelivery,
        isPickup,
        deliveryFee,
        pickupFee,
        pickupPaymentStatus,
        deliveryPaymentStatus,
        itemPriceFeeAmount,
        weightFeeAmount,
        discountPercentage,
        discountAmount,
        originalReference,
        attachments,
        exchangeRate,
        cbmCurrencyId: cbmCurrencyId?.value ?? null,
        cbmExchangeRate,
        cbmRate,
        categories: categories.map(category => {
          return {
            id: category.value,
            name: category.label,
          };
        }),
        orderItems,
        orderAdditionalEntries:
          orderAdditionalEntries &&
          orderAdditionalEntries
            .filter(e => this.isValidOrderAdditionalEntry(e))
            .map((a, index) => {
              return {
                id: a.id,
                index,
                orderId: id,
                currencyId: a.currencyId?.value,
                title: a.title,
                amount: a.amount,
              };
            }),
      };

      this.props.onSubmit(data);
    }
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
    switch (name) {
      case "receiverId":
        if (valueType) {
          this.populateShippingAddress(valueType.value);
          this.populateDefaultShippingAddress(valueType.value);
        }
        break;
      case "type":
        console.log("ok");
        break;
      case "transportationType":
        console.log("O");
        break;
      default:
        break;
    }
  };

  populateDefaultShippingAddress = userId => {
    getDefaultShippingContactRequest({ userId }).then(data => {
      if (data) {
        const { phones, contact, address } = data;
        this.setState({
          shippingContactId: {
            key: data.id,
            value: data.id,
            label: contact,
            phones,
            address,
          },
        });
      } else {
        this.setState({
          shippingContactId: null,
        });
      }
    });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleShippingAddressSelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      this.setState({
        modalAddShippingAddress: true,
        contact: newValue.value,
      });
    } else {
      this.setState({
        shippingContactId: newValue,
      });
    }
  };

  handleCategorySelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true);
      const { value } = newItem;
      const category = {
        name: value,
      };

      categoryService.createRequest(category).then(id => {
        if (id) {
          categoryService.getByIdRequest(id).then(data => {
            if (data) {
              const newOption = {
                key: data.id,
                value: data.id,
                label: data.name,
              };
              this.setState(prevState => ({
                categories: prevState.categories.concat(newOption),
                categoryOptions: prevState.categoryOptions.concat(newOption),
              }));
            }
          });
        }
      });
    } else {
      this.setState({
        categories: newValue,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleDiscountPercentageChange = obj => {
    const {
      orderItemShippingFeeKRW,
      weightFeeAmount,
      unitFeeAmount,
      pickupFee,
      deliveryFee,
      orderAdditionalEntries,
      exchangeRate,
    } = this.state;
    const { value } = obj;

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries,
      exchangeRate,
    });

    const totalAmount =
      Number(orderItemShippingFeeKRW ?? 0) +
      Number(weightFeeAmount ?? 0) +
      Number(unitFeeAmount ?? 0) +
      Number(pickupFee ?? 0) +
      Number(deliveryFee ?? 0) +
      Number(totalOrderAdditionalEntry);

    const discountAmount = Number((totalAmount * value ?? 0) / 100);
    this.setState({
      discountPercentage: value,
      discountAmount,
      totalAmount: Number(totalAmount ?? 0) - Number(discountAmount ?? 0),
    });
  };

  handleOnOrderItemChange = orderItem => {
    const {
      orderItems,
      weightFeeAmount,
      unitFeeAmount,
      pickupFee,
      deliveryFee,
      cbmExchangeRate,
      exchangeRate,
      senderId,
      transportationType,
      orderAdditionalEntries,
    } = this.state;
    let newOrderItems = orderItems.map(a => {
      return a.displayOrder === orderItem.displayOrder ? orderItem : a;
    });

    let totalVolume = _.sumBy(newOrderItems, a => {
      return a.volume;
    });

    if (senderId && transportationType) {
      let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
        orderAdditionalEntries,
        exchangeRate,
      });

      itemShippingFeeService
        .getByQuery({
          userId: senderId.value,
          transportationType: transportationType?.value ?? "Sea",
          type: 1, // CBM RATE
          itemPrice: Number(totalVolume ?? 0),
        })
        .then(data => {
          const { value } = data;
          let orderItemShippingFee = Number((totalVolume ?? 0) * value);
          let orderItemShippingFeeKRW = cbmExchangeRate * orderItemShippingFee;
          let totalAmount =
            Number(weightFeeAmount ?? 0) +
            Number(orderItemShippingFeeKRW ?? 0) +
            Number(unitFeeAmount ?? 0) +
            Number(pickupFee ?? 0) +
            Number(deliveryFee ?? 0) +
            Number(totalOrderAdditionalEntry);

          this.setState({
            orderItems: newOrderItems,
            cbmRate: value,
            totalVolume: Number(totalVolume).toFixed(4),
            orderItemShippingFee: Number(orderItemShippingFee).toFixed(2),
            orderItemShippingFeeKRW: Number(orderItemShippingFeeKRW).toFixed(2),
            totalAmount: Number(totalAmount).toFixed(2),
          });
        });
    }
  };

  handleOnRemoveOrderItem = displayOrder => {
    const {
      orderItems,
      weightFeeAmount,
      unitFeeAmount,
      pickupFee,
      deliveryFee,
      exchangeRate,
      cbmExchangeRate,
      senderId,
      transportationType,
      orderAdditionalEntries,
    } = this.state;
    let newOrderItems = orderItems.filter(a => a.displayOrder !== displayOrder);

    let totalVolume = _.sumBy(newOrderItems, a => {
      return a.volume;
    });

    if (senderId && transportationType) {
      let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
        orderAdditionalEntries,
        exchangeRate,
      });
      itemShippingFeeService
        .getByQuery({
          userId: senderId.value,
          transportationType: transportationType?.value ?? "Sea",
          type: 1, // CBM RATE
          itemPrice: Number(totalVolume ?? 0),
        })
        .then(data => {
          const { value } = data;

          let orderItemShippingFee = Number((totalVolume ?? 0) * value);
          let orderItemShippingFeeKRW = cbmExchangeRate * orderItemShippingFee;
          let totalAmount =
            Number(weightFeeAmount ?? 0) +
            Number(orderItemShippingFeeKRW ?? 0) +
            Number(unitFeeAmount ?? 0) +
            Number(pickupFee ?? 0) +
            Number(deliveryFee ?? 0) +
            Number(totalOrderAdditionalEntry);

          this.setState({
            orderItems: newOrderItems,
            cbmRate: value,
            totalVolume: Number(totalVolume).toFixed(4),
            orderItemShippingFee: Number(orderItemShippingFee).toFixed(2),
            orderItemShippingFeeKRW: Number(orderItemShippingFeeKRW).toFixed(2),
            totalAmount: Number(totalAmount).toFixed(2),
          });
        });
    }
  };

  handleUnitFeeChange = (name, e) => {
    const {
      orderItemShippingFeeKRW,
      weightFeeAmount,
      pickupFee,
      deliveryFee,
      orderAdditionalEntries,
      exchangeRate,
    } = this.state;
    const { value } = e;

    this.setState({
      [name]: value,
    });

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries,
      exchangeRate,
    });

    const unitFeeAmount = Number(value ?? 0);
    const totalAmount =
      Number(orderItemShippingFeeKRW ?? 0) +
      Number(weightFeeAmount ?? 0) +
      Number(unitFeeAmount ?? 0) +
      Number(pickupFee ?? 0) +
      Number(deliveryFee ?? 0) +
      Number(totalOrderAdditionalEntry);

    this.setState({
      unitFeeAmount: Number(unitFeeAmount).toFixed(2),
      totalAmount: Number(totalAmount),
    });
  };

  handlePickupFeeChange = (name, e) => {
    const {
      orderItemShippingFeeKRW,
      weightFeeAmount,
      unitFeeAmount,
      deliveryFee,
      orderAdditionalEntries,
      exchangeRate,
    } = this.state;
    const { value } = e;
    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries,
      exchangeRate,
    });

    this.setState({
      [name]: value,
    });

    let totalAmount =
      Number(orderItemShippingFeeKRW ?? 0) +
      Number(weightFeeAmount ?? 0) +
      Number(unitFeeAmount ?? 0) +
      Number(value ?? 0) +
      Number(deliveryFee ?? 0) +
      Number(totalOrderAdditionalEntry);
    this.setState({
      totalAmount: Number(totalAmount),
    });
  };

  handleDelveryFeeChange = (name, e) => {
    const {
      orderItemShippingFeeKRW,
      weightFeeAmount,
      unitFeeAmount,
      pickupFee,
      orderAdditionalEntries,
      exchangeRate,
    } = this.state;
    const { value } = e;

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries,
      exchangeRate,
    });

    this.setState({
      [name]: value,
    });

    let totalAmount =
      Number(orderItemShippingFeeKRW ?? 0) +
      Number(weightFeeAmount ?? 0) +
      Number(unitFeeAmount ?? 0) +
      Number(value ?? 0) +
      Number(pickupFee ?? 0) +
      Number(totalOrderAdditionalEntry);
    this.setState({
      totalAmount: Number(totalAmount),
    });
  };

  handleAddNewOrderItem = () => {
    const { orderItems } = this.state;

    let max = 0;

    if (orderItems.length > 0) {
      max = getMaxValue(
        orderItems.map(a => {
          return a.displayOrder;
        })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newOrderItem = {
      id: NIL_UUID,
      title: "",
      orderId: this.state.id,
      displayOrder: max,
      quantity: "",
      length: "",
      width: "",
      height: "",
      volume: "",
    };

    this.setState(prevState => ({
      orderItems: prevState.orderItems.concat(newOrderItem),
    }));
  };

  handleOnAdd = () => {
    const { orderAdditionalEntries } = this.state;

    let max = 0;

    if (orderAdditionalEntries.length > 0) {
      max = getMaxValue(
        orderAdditionalEntries.map(a => {
          return a.index;
        })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newItem = {
      index: max,
      id: NIL,
      title: "",
      currencyId: null,
      orderId: null,
      amount: 0,
    };

    this.setState(prevState => ({
      orderAdditionalEntries: prevState.orderAdditionalEntries.concat(newItem),
    }));
  };

  handleOnRemove = item => {
    const {
      orderAdditionalEntries,
      weightFeeAmount,
      unitFeeAmount,
      pickupFee,
      orderItemShippingFeeKRW,
      deliveryFee,
      exchangeRate,
    } = this.state;

    let newOrderAdditionalEntries = orderAdditionalEntries.filter(
      e => e.index !== item.index
    );

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries: newOrderAdditionalEntries,
      exchangeRate,
    });

    let totalAmount =
      Number(weightFeeAmount ?? 0) +
      Number(unitFeeAmount ?? 0) +
      Number(pickupFee ?? 0) +
      Number(deliveryFee ?? 0) +
      Number(orderItemShippingFeeKRW ?? 0);
    totalOrderAdditionalEntry;

    this.setState({
      totalAmount: Number(totalAmount).toFixed(0),
      orderAdditionalEntries: newOrderAdditionalEntries,
    });
  };

  handleOnChange = item => {
    const {
      orderAdditionalEntries,
      weightFeeAmount,
      unitFeeAmount,
      orderItemShippingFeeKRW,
      pickupFee,
      deliveryFee,
      exchangeRate,
    } = this.state;

    let totalOrderAdditionalEntry = this.calculateOrderAdditionalEntry({
      orderAdditionalEntries,
      exchangeRate,
    });

    let totalAmount =
      Number(weightFeeAmount ?? 0) +
      Number(unitFeeAmount ?? 0) +
      Number(pickupFee ?? 0) +
      Number(deliveryFee ?? 0) +
      Number(orderItemShippingFeeKRW ?? 0) +
      totalOrderAdditionalEntry;

    this.setState(prevState => ({
      totalAmount: Number(totalAmount).toFixed(0),
      orderAdditionalEntries: prevState.orderAdditionalEntries.map(a => {
        return a.index === item.index ? item : a;
      }),
    }));
  };

  isValidOrderAdditionalEntry = item => {
    return item.currencyId && item.title && item.amount;
  };

  render() {
    const {
      submitted,
      senderId,
      receiverId,
      shippingContactId,
      transportationType,
      deliveryStatus,
      paymentStatus,
      senderUsers,
      receiverUsers,
      categoryOptions,
      currencyOptions,
      unitOptions,
      orderAdditionalEntries,
      orderItemShippingFeeKRW,
      unitFee,
      unitFeeAmount,
      weightFeeAmount,
      totalAmount,
    } = this.state;

    const currencyLabel = "";

    // console.log("Order Item: ", orderItemShippingFeeKRW);
    // console.log("Unit Fee: ", unitFee);
    // console.log("unitFeeAmount: ", unitFeeAmount);
    // console.log("weightFeeAmount:  ", weightFeeAmount);
    // console.log("Total Amount: " + totalAmount);

    return (
      <Row>
        <Col lg={12}>
          <Card className="mb-3">
            <CardBody className="pt-3">
              <CardTitle>Order</CardTitle>
              <Row>
                <Col md={4}>
                  <div className="mb-2">
                    <Label>Sender</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (submitted && !senderId ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <Select
                          name="senderId"
                          value={this.state.senderId}
                          onChange={this.handleSelectChange}
                          options={senderUsers}
                          classNamePrefix="select2-selection"
                          isClearable
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={this.handleSelectSender}
                        outline
                      >
                        <i className="bx bx-search-alt"></i> Find
                      </Button>
                    </FormGroup>
                    {submitted && !senderId && (
                      <div className="invalid-feedback-custom">
                        Sender is required.
                      </div>
                    )}
                  </div>
                  <FormGroup
                    className={
                      "select2-container" +
                      (submitted && !transportationType ? " is-invalid" : "")
                    }
                  >
                    <Label>Transportation Type</Label>
                    <Select
                      name="transportationType"
                      value={this.state.transportationType}
                      onChange={this.handleSelectChange}
                      options={transportationTypeOptions}
                      classNamePrefix="select2-selection"
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !transportationType && (
                    <div className="invalid-feedback-custom">
                      Transportation Type is required.
                    </div>
                  )}
                  <FormGroup
                    className={
                      "select2-container" +
                      (submitted && !deliveryStatus ? " is-invalid" : "")
                    }
                  >
                    <Label>Delivery Status</Label>
                    <Select
                      name="deliveryStatus"
                      value={this.state.deliveryStatus}
                      onChange={this.handleSelectChange}
                      options={deliveryStatusOptions}
                      classNamePrefix="select2-selection"
                      // isDisabled
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !deliveryStatus && (
                    <div className="invalid-feedback-custom">
                      Delivery Status is required.
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div className="mb-2">
                    <Label>Receiver</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (submitted && !receiverId ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <Select
                          name="receiverId"
                          value={this.state.receiverId}
                          onChange={this.handleSelectChange}
                          options={receiverUsers}
                          classNamePrefix="select2-selection"
                          isClearable
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={this.handleSelectReceiver}
                        outline
                      >
                        <i className="bx bx-search-alt"></i> Find
                      </Button>
                    </FormGroup>
                    {submitted && !receiverId && (
                      <div className="invalid-feedback-custom">
                        Receiver is required.
                      </div>
                    )}
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Tracking Number</Label>
                    <Input
                      type="text"
                      name="trackingNumber"
                      readOnly
                      value={this.state.trackingNumber}
                    />
                  </FormGroup>
                  <FormGroup
                    className={
                      "select2-container" +
                      (submitted && !paymentStatus ? " is-invalid" : "")
                    }
                  >
                    <Label>Shipping Payment Status</Label>
                    <Select
                      name="paymentStatus"
                      value={this.state.paymentStatus}
                      onChange={this.handleSelectChange}
                      options={paymentStatusOptions}
                      classNamePrefix="select2-selection"
                      isDisabled
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !paymentStatus && (
                    <div className="invalid-feedback-custom">
                      Payment Status is required.
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div className="mb-2">
                    <Label>Shipping Contact</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (submitted && !shippingContactId ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <CreatableSelect
                          name="shippingContactId"
                          value={this.state.shippingContactId}
                          onChange={this.handleShippingAddressSelectChange}
                          options={this.state.shippingAddressOptions}
                          className="custom-shipping-addr"
                          classNamePrefix="select2-selection"
                          formatOptionLabel={formatOptionLabel}
                          isDisabled={!receiverId}
                          isClearable
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={this.handleSelectionShippingAddress}
                        outline
                        disabled={!receiverId}
                      >
                        <i className="bx bx-search-alt"></i> Find
                      </Button>
                    </FormGroup>
                    {submitted && !shippingContactId && (
                      <div className="invalid-feedback-custom">
                        Shipping Contact is required.
                      </div>
                    )}
                  </div>

                  <FormGroup className="mb-3">
                    <Label htmlFor="originalReference">
                      Original Reference
                    </Label>
                    <Input
                      type="text"
                      name="originalReference"
                      placeholder="Original Reference"
                      value={this.state.originalReference}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>Order Detail</CardTitle>
              <Row>
                <Col md={4}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Description</Label>
                    <Input
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={this.state.description}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="select2-container">
                    <Label>Category</Label>
                    <CreatableSelect
                      name="categories"
                      value={this.state.categories}
                      isMulti={true}
                      onChange={this.handleCategorySelectChange}
                      options={categoryOptions}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <div
                      className={
                        "select2-container" +
                        (submitted && !this.state.type ? " is-invalid" : "")
                      }
                    >
                      <Label>Type</Label>
                      <Select
                        name="type"
                        value={this.state.type}
                        onChange={this.handleSelectChange}
                        options={typeOptions}
                        classNamePrefix="select2-selection"
                        isClearable
                      />
                    </div>
                    {submitted && !this.state.type && (
                      <div className="invalid-feedback-custom">
                        Type is required.
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Item Price</Label>
                    <NumberFormat
                      name="price"
                      value={this.state.price}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                      onValueChange={values => this.handlePriceChange(values)}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <div
                      className={
                        "select2-container" +
                        (submitted && !this.state.currencyId
                          ? " is-invalid"
                          : "")
                      }
                    >
                      <Label>Currency</Label>
                      <Select
                        name="currencyId"
                        value={this.state.currencyId}
                        onChange={this.handleSelectChange}
                        options={currencyOptions}
                        classNamePrefix="test select2-selection"
                        isClearable
                        isDisabled
                      />
                    </div>
                    {submitted && !this.state.currencyId && (
                      <div className="invalid-feedback-custom">
                        Currency is required.
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-3">
                    <Label>Quantity</Label>
                    <NumberFormat
                      name="quantity"
                      value={this.state.quantity}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      onValueChange={values => {
                        const { value } = values;
                        this.setState({ quantity: value });
                      }}
                    />

                    {submitted && !this.state.quantity && (
                      <div className="invalid-feedback-custom">
                        Quantity is required.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <div
                      className={
                        "select2-container" +
                        (submitted && !this.state.unitId ? " is-invalid" : "")
                      }
                    >
                      <Label>Unit</Label>
                      <Select
                        name="unitId"
                        value={this.state.unitId}
                        onChange={this.handleSelectChange}
                        options={unitOptions}
                        classNamePrefix="select2-selection"
                        isClearable
                      />
                    </div>
                    {submitted && !this.state.unitId && (
                      <div className="invalid-feedback-custom">
                        Unit is required.
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>
                CBM Item Detail
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: "-19px",
                      top: "-36px",
                    }}
                  >
                    <Button
                      onClick={this.handleAddNewOrderItem}
                      style={{
                        background: "transparent",
                        color: "#495057",
                        border: "none",
                      }}
                      outline
                    >
                      <i className="fas fa-plus" />
                    </Button>
                  </div>
                </div>
              </CardTitle>
              {this.state.orderItems.map((item, index) => {
                return (
                  <OrderItemRow
                    key={index}
                    isFirst={index === 0}
                    item={item}
                    onChange={this.handleOnOrderItemChange}
                    onRemove={this.handleOnRemoveOrderItem}
                  />
                );
              })}
              {this.state.orderItems.length > 0 && (
                <Row style={{ paddingRight: "20px", paddingTop: "5px" }}>
                  <Col md={3}></Col>
                  <Col md={9}>
                    <div className="d-flex">
                      <div className="d-flex" style={{ width: "100%" }}></div>
                      <div className="d-flex" style={{ width: "100%" }}></div>
                      <div className="d-flex" style={{ width: "100%" }}></div>
                      <div className="d-flex" style={{ width: "100%" }}>
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Total Volume</Label>
                          <Label className="pt-2">CBM Rate (USD)</Label>
                          <Label className="pt-2">Shipping Fee (USD)</Label>
                          <Label className="pt-2">Exchange Rate (KRW)</Label>
                          <Label className="pt-2">Shipping Fee (KRW)</Label>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-row"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="d-flex flex-column"
                          style={{ width: "100%" }}
                        >
                          <NumberFormat
                            name="totalVolume"
                            value={this.state.totalVolume}
                            className="form-control text-end"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                          <NumberFormat
                            name="cbmRates"
                            value={Number(this.state.cbmRate).toFixed(2)}
                            className="form-control text-end"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />

                          <NumberFormat
                            name="orderItemShippingFee"
                            value={this.state.orderItemShippingFee}
                            className="form-control text-end"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                          <NumberFormat
                            name="cbmExchangeRate"
                            value={this.state.cbmExchangeRate}
                            className="form-control text-end"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                          <NumberFormat
                            name="orderItemShippingFeeKRW"
                            value={this.state.orderItemShippingFeeKRW}
                            className="form-control text-end"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={9}>
          <Card>
            <CardBody>
              <CardTitle>Weight and Fee</CardTitle>
              <Row>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Actual Weight (kg)</Label>
                    <NumberFormat
                      name="weight"
                      value={this.state.weight}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      onValueChange={values => {
                        const { value } = values;
                        this.handleWeightChange("weight", value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Weight Extra (kg)</Label>
                    <NumberFormat
                      name="weightExtra"
                      value={this.state.weightExtra}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      onValueChange={values => {
                        const { value } = values;
                        this.handleWeightChange("weightExtra", value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Weight Total (kg)</Label>
                    <Input
                      type="number"
                      name="weight"
                      className="text-end"
                      placeholder="Weight"
                      value={
                        Number(this.state.weight ?? 0) +
                        Number(this.state.weightExtra ?? 0)
                      }
                      readOnly
                      // onChange={this.handleWeightChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Comment</Label>
                    <Input
                      type="text"
                      name="weightComment"
                      placeholder="Weight Comment"
                      value={this.state.weightComment}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3">
                    <Label>Amount</Label>
                    <NumberFormat
                      name="weightFeeAmount"
                      readOnly
                      value={this.state.weightFeeAmount}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Unit Fee</CardTitle>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Unit Fee (KRW)</Label>

                    <NumberFormat
                      name="unitFee"
                      value={this.state.unitFee}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      onValueChange={values =>
                        this.handleUnitFeeChange("unitFee", values)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>Additional Services</CardTitle>
              {orderAdditionalEntries &&
                orderAdditionalEntries.map((a, index) => {
                  return (
                    <OrderAdditionalEntryForm
                      key={index}
                      item={a}
                      isPrimary={index === 0}
                      onAdd={this.handleOnAdd}
                      onRemove={this.handleOnRemove}
                      onChange={this.handleOnChange}
                    />
                  );
                })}
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Pickup</CardTitle>
              <Row>
                <Col md={12}>
                  <div className="form-check form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckcolor1"
                      checked={this.state.isPickup}
                      onChange={e => {
                        this.setState({
                          isPickup: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                    >
                      Is Pickup ?
                    </label>
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Pickup Fee (KRW)</Label>
                    <NumberFormat
                      name="pickupFee"
                      readOnly={!this.state.isPickup}
                      value={this.state.pickupFee}
                      className="form-control text-end"
                      onValueChange={obj => {
                        this.handlePickupFeeChange("pickupFee", obj);
                      }}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Delivery</CardTitle>
              <Row>
                <Col md={12}>
                  <div className="form-check form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckcolor1"
                      checked={this.state.isDelivery}
                      onChange={e => {
                        this.setState({
                          isDelivery: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                    >
                      Is Delivery ?
                    </label>
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Delivery Fee (KRW)</Label>
                    <NumberFormat
                      name="deliveryFee"
                      readOnly={!this.state.isDelivery}
                      value={this.state.deliveryFee}
                      className="form-control text-end"
                      onValueChange={obj => {
                        this.handleDelveryFeeChange("deliveryFee", obj);
                      }}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Discount</CardTitle>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <Label>Percentage</Label>
                    <NumberFormat
                      name="discountPercentage"
                      value={this.state.discountPercentage}
                      className="form-control text-end"
                      fixedDecimalScale={false}
                      onValueChange={obj => {
                        this.handleDiscountPercentageChange(obj);
                      }}
                      prefix={`% `}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Amount</Label>
                    <NumberFormat
                      name="discountAmount"
                      value={this.state.discountAmount}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Total Fee</CardTitle>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <Label>Total Amount (KRW)</Label>
                    <NumberFormat
                      name="totalAmount"
                      readOnly
                      value={this.state.totalAmount}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </FormGroup>
                  {/* <FormGroup className="mb-3">
                    <Label>Total Amount (USD)</Label>
                    <NumberFormat
                      name="totalAmount"
                      readOnly
                      value={convertWONtoUSD(this.state.totalAmount).toFixed(2)}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </FormGroup> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" onClick={this.handleSubmit}>
                  Submit{" "}
                </Button>{" "}
                <Button color="primary" onClick={this.handleCancel} outline>
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>

          <UserSelectionModalList
            title={"Find Reciever"}
            isOpen={this.state.modalSelectReceiver}
            toggle={this.toggleSelectReceiverModal}
            onSubmit={this.handleSelectReceiverUser}
          />
          <UserSelectionModalList
            title={"Find Sender"}
            isOpen={this.state.modalSelectSender}
            toggle={this.toggleSelectSenderModal}
            onSubmit={this.handleSelectSenderUser}
          />
          <SelectionListModal
            title={"Find Shipping Contact"}
            userId={receiverId?.value ?? null}
            isOpen={this.state.modalSelectionShippingAddress}
            toggle={this.toggleSelectionShippingAddress}
            onSubmit={this.handleSubmitSelectionShippingAddress}
          />
          <ModalFormShippingContact
            title={"Add New Shipping Contact"}
            contact={this.state.contact}
            userId={receiverId?.value ?? null}
            isOpen={this.state.modalAddShippingAddress}
            toggle={this.toggleAddShippingAddress}
            onSubmit={this.hanldeSubmitShippingAddress}
          />
        </Col>
      </Row>
    );
  }
}

OrderForm.propTypes = {
  id: PropTypes.string,
  order: PropTypes.object,
  orderItem: PropTypes.object,
  isAddNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default OrderForm;
