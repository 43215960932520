import {
  GET_STOCK_OUT_ITEMS,
  GET_STOCK_OUT_ITEMS_SUCCESS,
  GET_STOCK_OUT_ITEMS_ERROR,
  GET_STOCK_OUT_ITEM_BY_ID,
  GET_STOCK_OUT_ITEM_BY_ID_SUCCESS,
  ADD_NEW_STOCK_OUT_ITEM,
  ADD_NEW_STOCK_OUT_ITEM_SUCCESS,
  UPDATE_STOCK_OUT_ITEM,
  UPDATE_STOCK_OUT_ITEM_SUCCESS,
  DELETE_STOCK_OUT_ITEM,
  DELETE_STOCK_OUT_ITEM_SUCCESS,
  STOCK_OUT_ITEM_CLEAR_ERROR,
  ADD_NEW_STOCK_OUT_ITEM_ERROR,
} from "./actionTypes";

// StockOutItem
export const getStockOutItems = query => ({
  type: GET_STOCK_OUT_ITEMS,
  payload: query,
});

export const getStockOutItemsSuccess = payload => ({
  type: GET_STOCK_OUT_ITEMS_SUCCESS,
  payload,
});

export const getStockOutItemsError = error => ({
  type: GET_STOCK_OUT_ITEMS_ERROR,
  payload: error,
});

export const getStockOutItemById = id => ({
  type: GET_STOCK_OUT_ITEM_BY_ID,
  payload: id,
});

export const getStockOutItemByIdSuccess = payload => ({
  type: GET_STOCK_OUT_ITEM_BY_ID_SUCCESS,
  payload,
});

export const addNewStockOutItem = item => ({
  type: ADD_NEW_STOCK_OUT_ITEM,
  payload: item,
});

export const addNewStockOutItemSuccess = item => ({
  type: ADD_NEW_STOCK_OUT_ITEM_SUCCESS,
  payload: item,
});

export const addNewStockOutItemError = error => ({
  type: ADD_NEW_STOCK_OUT_ITEM_ERROR,
  payload: error,
});

export const updateStockOutItem = item => ({
  type: UPDATE_STOCK_OUT_ITEM,
  payload: item,
});

export const updateStockOutItemSuccess = item => ({
  type: UPDATE_STOCK_OUT_ITEM_SUCCESS,
  payload: item,
});

export const deleteStockOutItem = item => ({
  type: DELETE_STOCK_OUT_ITEM,
  payload: item,
});

export const deleteStockOutItemSuccess = item => ({
  type: DELETE_STOCK_OUT_ITEM_SUCCESS,
  payload: item,
});

export const clearStockOutItemError = () => ({
  type: STOCK_OUT_ITEM_CLEAR_ERROR,
});
