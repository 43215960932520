import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  getProductStockOutById,
  getItemUnitOfMeasurements,
  updateProductStockOut
} from "store/actions";

import ProductStockOutProductTab from "./ProductStockOutProductTab";
import ProductStockInForm from "../../containers/product-stock-in/ProductStockInForm"
import ProductStockOutForm from "../../containers/product-stock-out/ProductStockOutForm"

const ProductStockOutDetail = props => {
  const { match, history } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [id, setId] = useState(null);

  const { getProductStockOutById, updateProductStockOut, productStockOut } = props;
  const { item, loading } = productStockOut;

  useEffect(() => {
    const { id } = match.params;
    getProductStockOutById(id);
    setId(id);
  }, []);

  const handleOnSubmit = (data) => {
    updateProductStockOut(data)
    history.push(`/product-stock-out`)
  }

  const handleOnCancel = () =>{
    history.push(`/product-stock-out`);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Stock Out Detail | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {item ? (
                <>
                  <Breadcrumbs
                    title="Home"
                    backLink={"/product-stock-out"}
                    breadcrumbItem={`${item.code} | Stock Out Detail`}
                  />
                  <ProductStockOutForm item={item} onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
                </>
              ) : null}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

ProductStockOutDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  productStockOut: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getProductStockOutById: PropTypes.func,
  updateProductStockOut: PropTypes.func,
};

const mapStateToProps = ({ productStockOut }) => {
  return {
    productStockOut,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getProductStockOutById,
    getItemUnitOfMeasurements,
    updateProductStockOut
  })(ProductStockOutDetail)
);
