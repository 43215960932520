import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Button,
  Card,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";

import { getInvoicePaymentHistory } from "../../store/invoice-payment-history/actions";

import PaymentHistoryListTable from "containers/invoice-payment-history/PaymentHistoryListTable";
import moment from "moment";
import { invoicePaymentService } from "services/invoice-payment-service";
import { timestampFileName } from "helpers/utils";

export class PaymentHistoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      invoiceId: null,
      currentPage: 1,
      pageSize: 10,
      startDate: null,
      endDate: null,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      invoicePayment: null,
    };
  }

  componentDidMount() {
    this.props.getInvoicePaymentHistory({ page: 1, pageSize: 10, term: "" });
  }

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getInvoicePaymentHistory({ term: value ?? "" });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getInvoicePaymentHistory({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getInvoicePaymentHistory({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleSearch = () => {
    const { term, startDate, endDate } = this.state;
    this.props.getInvoicePaymentHistory({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
    });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleExportToExcel = () => {
    const { term, startDate, endDate } = this.state;
    invoicePaymentService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
      },
      `${timestampFileName()}_Invoice_Payment_List.xlsx`
    );
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Home"
              breadcrumbItem="Invoice payment history List"
            />
            <Row>
              <Col md={12}>
                <Card className="mb-3 rounded-2">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-3 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              this.handleDateChange(date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          className="d-lg-none w-100"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>

                        <Button
                          type="button"
                          outline
                          color="primary"
                          className="d-none d-lg-block"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            size="md"
                            onClick={this.handleExportToExcel}
                          >
                            <i className="fas fa-file-excel" /> Export to Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
                {items && (
                  <PaymentHistoryListTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

PaymentHistoryList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoicePaymentHistory: PropTypes.func,
};

const mapStateToProps = ({ invoicePaymentHistotry }) => {
  return invoicePaymentHistotry;
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoicePaymentHistory,
  })(PaymentHistoryList)
);
