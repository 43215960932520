import { SEVERITY } from "helpers/utils.js";
import {
  GET_ORDER_TRACKING,
  GET_ORDER_TRACKING_SUCCESS,
  GET_ORDER_TRACKING_FAIL,
  GET_ORDER_TRACKING_BY_ID_SUCCESS,
  ADD_NEW_ORDER_TRACKING,
  ADD_NEW_ORDER_TRACKING_SUCCESS,
  UPDATE_ORDER_TRACKING,
  UPDATE_ORDER_TRACKING_SUCCESS,
  DELETE_ORDER_TRACKING,
  DELETE_ORDER_TRACKING_SUCCESS,
  ORDER_TRACKING_CLEAR_NOTIFY
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const OrderTracking = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_ORDER_TRACKING:
    case UPDATE_ORDER_TRACKING:
    case DELETE_ORDER_TRACKING:
    case GET_ORDER_TRACKING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_TRACKING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_ORDER_TRACKING_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_ORDER_TRACKING_FAIL:
      return {
        ...state,
        error: payload,
      };
    case ADD_NEW_ORDER_TRACKING_SUCCESS: 
      return {
        ...state,
        message: {
          title: "Order Tracking",
          text: "Order Tracking has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case UPDATE_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Tracking",
          text: "Order Tracking has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Tracking",
          text: "Order Tracking has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case ORDER_TRACKING_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default OrderTracking;
