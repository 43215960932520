import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import NumberFormat from "react-number-format";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  invoicePaymentStatusOptions,
  paymentMethodOptions,
} from "helpers/utils";
import { currencyService } from "services/currency-service";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import "react-fine-uploader/gallery/gallery.css";
import moment from "moment";
import { Link } from "react-router-dom";

var uploader;

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      invoiceId: null,
      amount: 0.0,
      paymentMethod: null,
      currencyId: null,
      paymentStatus: null,
      date: null,
      note: "",
      invoicePaymentAttachments: [],

      submitted: false,
      currencyOptions: [],
    };

    uploader = new FineUploaderTraditional({
      options: {
        multiple: true,
        request: {
          method: "POST",
          endpoint: `${process.env.REACT_APP_API_URL}/api/FileUpload/paymentAttachment`,
        },
        validation: {
          sizeLimit: 104857600, // 100MB
        },
      },
    });
  }

  componentDidMount() {
    uploader.on("submitted", id => {
      this.setState({
        uploading: true,
      });
    });

    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        const attachment = {
          fileName: responseJSON.fileName,
          url: responseJSON.url,
        };

        this.setState({
          invoicePaymentAttachments:
            this.state.invoicePaymentAttachments.concat(attachment),
        });
      } else {
        this.setState({
          fail: true,
          error: responseJSON.message,
        });
      }
    });

    uploader.on("error", (id, name, errorReason) => {
      console.log(errorReason, id, name);
      // this.setState({
      //   fail: true,
      //   error: errorReason,
      // });
    });

    currencyService.getRequest().then(data => {
      if (data) {
        this.setState({
          currencyOptions: data
            .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.symbol,
              };
            }),
        });
      }
    });

    currencyService.getDefaultRequest().then(data => {
      if (data) {
        this.setState({
          currencyId: {
            key: data.id,
            value: data.id,
            label: data.symbol,
          },
        });
      }
    });
  }

  onOpened = () => {
    const { invoicePayment, invoiceId } = this.props;

    if (invoicePayment) {
      this.setState({
        ...invoicePayment,
        currencyId: {
          key: invoicePayment.currencyId,
          value: invoicePayment.currencyId,
          label: invoicePayment.currency,
        },
        date: invoicePayment.date ? moment(invoicePayment.date).toDate() : null,
        paymentMethod: paymentMethodOptions.find(
          e => e.value === invoicePayment.paymentMethod
        ),

        paymentStatus: invoicePaymentStatusOptions.find(
          e => e.value === invoicePayment.paymentStatus
        ),
      });
    } else {
      this.setState({
        id: NIL,
        invoiceId,
        amount: 0.0,
        paymentMethod: null,
        paymentStatus: null,
        date: moment().toDate(),
        note: "",
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleAmountChange = values => {
    const { value } = values;
    this.setState({
      amount: value,
    });
  };
  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSubmit = () => {
    const {
      id,
      invoiceId,
      amount,
      currencyId,
      paymentMethod,
      paymentStatus,
      date,
      note,
      invoicePaymentAttachments,
    } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = amount && paymentMethod && date;

    if (isValid) {
      const invoicePayment = {
        id,
        invoiceId: invoiceId,
        amount,
        paymentMethod: paymentMethod?.value,
        paymentStatus: paymentStatus?.value,
        currencyId: currencyId.value,
        date,
        invoicePaymentAttachments,
        note: note ?? "",
      };

      this.props.onSubmit(invoicePayment);
    }
  };

  handleRemoveAttachment = url => {
    this.setState(prevState => ({
      invoicePaymentAttachments: prevState.invoicePaymentAttachments.filter(
        e => e.url !== url
      ),
    }));
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const {
      submitted,
      amount,
      paymentMethod,
      paymentStatus,
      date,
      currencyOptions,
      invoicePaymentAttachments,
    } = this.state;
    const currencyLabel = "";
    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <FormGroup
                  className={submitted && amount === 0.0 ? " is-invalid" : ""}
                >
                  <Label htmlFor="totalAmount">Amount</Label>
                  <NumberFormat
                    name="amount"
                    value={amount}
                    className={
                      "form-control text-end" +
                      (submitted && amount === 0.0 ? " is-invalid" : "")
                    }
                    thousandSeparator={true}
                    fixedDecimalScale={false}
                    prefix={`${currencyLabel} `}
                    onValueChange={values => this.handleAmountChange(values)}
                  />
                </FormGroup>
                {submitted && amount === 0.0 && (
                  <div className="invalid-feedback-custom">
                    Amount is required.
                  </div>
                )}
              </div>

              <div className="mb-3">
                <div
                  className={
                    "select2-container" +
                    (submitted && !this.state.currencyId ? " is-invalid" : "")
                  }
                >
                  <Label>Currency</Label>
                  <Select
                    name="currencyId"
                    value={this.state.currencyId}
                    onChange={this.handleSelectChange}
                    options={currencyOptions}
                    classNamePrefix="test select2-selection"
                    isClearable
                    isDisabled
                  />
                </div>
                {submitted && !this.state.currencyId && (
                  <div className="invalid-feedback-custom">
                    Currency is required.
                  </div>
                )}
              </div>

              <div className="mb-3">
                <div
                  className={submitted && !paymentMethod ? " is-invalid" : ""}
                >
                  <Label>Payment Method</Label>
                  <Select
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={this.handleSelectChange}
                    options={paymentMethodOptions}
                    classNamePrefix="test select2-selection"
                    isClearable
                  />
                </div>
                {submitted && !paymentMethod && (
                  <div className="invalid-feedback-custom">
                    Payment Method is required.
                  </div>
                )}
              </div>

              <div className="mb-3">
                <div
                  className={submitted && !paymentStatus ? " is-invalid" : ""}
                >
                  <Label>Payment Status</Label>
                  <Select
                    name="paymentStatus"
                    value={paymentStatus}
                    onChange={this.handleSelectChange}
                    options={invoicePaymentStatusOptions}
                    classNamePrefix="test select2-selection"
                    isClearable
                  />
                </div>
                {submitted && !paymentStatus && (
                  <div className="invalid-feedback-custom">
                    Payment Status is required.
                  </div>
                )}
              </div>
              <div className="mb-3">
                <FormGroup className={submitted && !date ? " is-invalid" : ""}>
                  <Label>Payment Date</Label>
                  <DatePicker
                    className={
                      "form-control " +
                      (submitted && !date ? " is-invalid" : "")
                    }
                    selectsStart
                    name="date"
                    selected={this.state.date}
                    onChange={date => this.handleDateChange(date, "date")}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Payment Date"
                    isClearable
                  />
                </FormGroup>
                {submitted && !date && (
                  <div className="invalid-feedback-custom">
                    Date is required.
                  </div>
                )}
              </div>
              <FormGroup className="mb-3">
                <Label htmlFor="invoiceNo">Note</Label>
                <Input
                  type="textarea"
                  name="note"
                  rows={3}
                  placeholder="Note..."
                  value={this.state.note}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Gallery uploader={uploader} />
              {invoicePaymentAttachments &&
                invoicePaymentAttachments.length > 0 && (
                  <Card className="border mt-2">
                    <CardBody className="p-0">
                      <table className="table table-nowrap align-middle table-hover mb-0 table">
                        <tbody>
                          {invoicePaymentAttachments.map(
                            (attachment, index) => {
                              const { fileName, url } = attachment;
                              return (
                                <tr key={index}>
                                  <td>
                                    <h5 className="text-truncate font-size-14 mb-1">
                                      {fileName}
                                    </h5>
                                  </td>
                                  <td width={"90px"}>
                                    <div>
                                      <ul className="list-inline mb-0 font-size-16">
                                        <li className="list-inline-item">
                                          <a
                                            href={url}
                                            className="text-success p-1"
                                            target="_blank"
                                            rel="noreferrer"
                                            download={fileName}
                                          >
                                            <i className="bx bx-download" />
                                          </a>
                                        </li>{" "}
                                        <li className="list-inline-item">
                                          <Link
                                            to="#"
                                            className="text-danger p-1"
                                            onClick={() =>
                                              this.handleRemoveAttachment(url)
                                            }
                                          >
                                            <i className="bx bxs-trash" />
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                )}
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  invoiceId: PropTypes.string,
  invoicePayment: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
