// CODE_COLOR
export const GET_PAGING_CODE_COLOR = "GET_PAGING_CODE_COLOR";
export const GET_PAGING_CODE_COLOR_SUCCESS = "GET_PAGING_CODE_COLOR_SUCCESS";
export const GET_PAGING_CODE_COLOR_ERROR = "GET_PAGING_CODE_COLOR_ERROR";

export const GET_CODE_COLOR_BY_ID = "GET_CODE_COLOR_BY_ID";
export const GET_CODE_COLOR_BY_ID_SUCCESS = "GET_CODE_COLOR_BY_ID_SUCCESS";

export const ADD_NEW_CODE_COLOR = "ADD_NEW_CODE_COLOR";
export const ADD_NEW_CODE_COLOR_SUCCESS = "ADD_NEW_CODE_COLOR_SUCCESS";
export const ADD_NEW_CODE_COLOR_ERROR = "ADD_NEW_CODE_COLOR_ERROR";

export const UPDATE_CODE_COLOR = "UPDATE_CODE_COLOR";
export const UPDATE_CODE_COLOR_SUCCESS = "UPDATE_CODE_COLOR_SUCCESS";
export const UPDATE_CODE_COLOR_ERROR = "UPDATE_CODE_COLOR_ERROR";

export const DELETE_CODE_COLOR = "DELETE_CODE_COLOR";
export const DELETE_CODE_COLOR_SUCCESS = "DELETE_CODE_COLOR_SUCCESS";

export const CODE_COLOR_CLEAR_ERROR = "CODE_COLOR_CLEAR_ERROR";
