// STOCK_IN
export const GET_STOCK_INS = "GET_STOCK_INS";
export const GET_STOCK_INS_SUCCESS = "GET_STOCK_INS_SUCCESS";
export const GET_STOCK_INS_ERROR = "GET_STOCK_INS_ERROR";

export const GET_STOCK_IN_BY_ID = "GET_STOCK_IN_BY_ID";
export const GET_STOCK_IN_BY_ID_SUCCESS = "GET_STOCK_IN_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_IN = "ADD_NEW_STOCK_IN";
export const ADD_NEW_STOCK_IN_SUCCESS = "ADD_NEW_STOCK_IN_SUCCESS";
export const ADD_NEW_STOCK_IN_ERROR = "ADD_NEW_STOCK_IN_ERROR";

export const UPDATE_STOCK_IN = "UPDATE_STOCK_IN";
export const UPDATE_STOCK_IN_SUCCESS = "UPDATE_STOCK_IN_SUCCESS";
export const UPDATE_STOCK_IN_ERROR = "UPDATE_STOCK_IN_ERROR";

export const DELETE_STOCK_IN = "DELETE_STOCK_IN";
export const DELETE_STOCK_IN_SUCCESS = "DELETE_STOCK_IN_SUCCESS";

export const GET_CONFIRM_STOCK_IN = "GET_CONFIRM_STOCK_IN";
export const GET_CONFIRM_STOCK_IN_SUCCESS = "GET_CONFIRM_STOCK_IN_SUCCESS";

export const STOCK_IN_CLEAR_ERROR = "STOCK_IN_CLEAR_ERROR";
