import {
  GET_PRODUCT_STOCK_OUTS,
  GET_PRODUCT_STOCK_OUTS_SUCCESS,
  GET_PRODUCT_STOCK_OUTS_ERROR,
  GET_PRODUCT_STOCK_OUT_BY_ID,
  GET_PRODUCT_STOCK_OUT_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_STOCK_OUT,
  ADD_NEW_PRODUCT_STOCK_OUT_SUCCESS,
  UPDATE_PRODUCT_STOCK_OUT,
  UPDATE_PRODUCT_STOCK_OUT_SUCCESS,
  DELETE_PRODUCT_STOCK_OUT,
  DELETE_PRODUCT_STOCK_OUT_SUCCESS,
  PRODUCT_STOCK_OUT_CLEAR_ERROR,
  ADD_NEW_PRODUCT_STOCK_OUT_ERROR,
  GET_CONFIRM_PRODUCT_STOCK_OUT,
  GET_CONFIRM_PRODUCT_STOCK_OUT_SUCCESS,
} from "./actionTypes";

// ProductStockOut
export const getProductStockOuts = query => ({
  type: GET_PRODUCT_STOCK_OUTS,
  payload: query,
});

export const getProductStockOutsSuccess = payload => ({
  type: GET_PRODUCT_STOCK_OUTS_SUCCESS,
  payload,
});

export const getProductStockOutsError = error => ({
  type: GET_PRODUCT_STOCK_OUTS_ERROR,
  payload: error,
});

export const getProductStockOutById = id => ({
  type: GET_PRODUCT_STOCK_OUT_BY_ID,
  payload: id,
});

export const getProductStockOutByIdSuccess = payload => ({
  type: GET_PRODUCT_STOCK_OUT_BY_ID_SUCCESS,
  payload,
});

export const addNewProductStockOut = item => ({
  type: ADD_NEW_PRODUCT_STOCK_OUT,
  payload: item,
});

export const addNewProductStockOutSuccess = item => ({
  type: ADD_NEW_PRODUCT_STOCK_OUT_SUCCESS,
  payload: item,
});

export const addNewProductStockOutError = error => ({
  type: ADD_NEW_PRODUCT_STOCK_OUT_ERROR,
  payload: error,
});

export const updateProductStockOut = item => ({
  type: UPDATE_PRODUCT_STOCK_OUT,
  payload: item,
});

export const updateProductStockOutSuccess = item => ({
  type: UPDATE_PRODUCT_STOCK_OUT_SUCCESS,
  payload: item,
});

export const deleteProductStockOut = item => ({
  type: DELETE_PRODUCT_STOCK_OUT,
  payload: item,
});

export const deleteProductStockOutSuccess = item => ({
  type: DELETE_PRODUCT_STOCK_OUT_SUCCESS,
  payload: item,
});

export const clearProductStockOutError = () => ({
  type: PRODUCT_STOCK_OUT_CLEAR_ERROR,
});

export const getConfirmProductStockOut = payload => ({
  type: GET_CONFIRM_PRODUCT_STOCK_OUT,
  payload,
});

export const getConfirmProductStockOutSuccess = payload => ({
  type: GET_CONFIRM_PRODUCT_STOCK_OUT_SUCCESS,
  payload,
});
