import { SEVERITY } from "helpers/utils.js"
import {
  GET_PURCHASE_ORDER_ITEMS,
  GET_PURCHASE_ORDER_ITEMS_SUCCESS,
  GET_PURCHASE_ORDER_ITEM_BY_ID_SUCCESS,
  GET_PURCHASE_ORDER_ITEMS_ERROR,
  PURCHASE_ORDER_ITEM_CLEAR_ERROR,
  GET_PURCHASE_ORDER_ITEM_BY_ID,
  ADD_NEW_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM,
  ADD_NEW_BULK_PURCHASE_ORDER_ITEM,
  SAVE_PURCHASE_ORDER_ITEMS,
  SAVE_PURCHASE_ORDER_ITEMS_ERROR,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_SUCCESS,
  GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_ERROR,
  SAVE_PURCHASE_ORDER_ITEMS_SUCCESS,
} from "./actionTypes.js"

const initState = {
  items: [],
  item: null,
  clothingSizes: [],
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
}

const StockInItem = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case DELETE_PURCHASE_ORDER_ITEM:
    case UPDATE_PURCHASE_ORDER_ITEM:
    case SAVE_PURCHASE_ORDER_ITEMS:
    case GET_PURCHASE_ORDER_ITEMS:
      return {
        ...state,
        loading: true,
        item: null,
      }
    case GET_PURCHASE_ORDER_ITEMS_SUCCESS:
      const { itemCount, pageCount, data, clothingSizes } = payload
      return {
        ...state,
        itemCount,
        pageCount,
        clothingSizes,
        items: data,
        item: null,
        loading: false,
      }
      
    case SAVE_PURCHASE_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        message: {
          title: "Purchase Order Item",
          text: "Have been saved successfully!",
          severity: SEVERITY.SUCCESS,
        },
        loading: false
      }
    case ADD_NEW_PURCHASE_ORDER_ITEM:
      return {
        ...state,
        item: null,
      }
    case ADD_NEW_BULK_PURCHASE_ORDER_ITEM:
      return {
        ...state,
        item: null,
      }
    case GET_PURCHASE_ORDER_ITEM_BY_ID:
    case GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID:
      return {
        ...state,
        item: null,
      }
    case GET_PURCHASE_ORDER_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      }

    case GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      }
    case GET_PURCHASE_ORDER_ITEMS_ERROR:
    case GET_PURCHASE_ORDER_ITEMS_BY_PURCHASE_ID_ERROR:
    case SAVE_PURCHASE_ORDER_ITEMS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement")
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      }
    case PURCHASE_ORDER_ITEM_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      }
    default:
      return state
  }
}

export default StockInItem
