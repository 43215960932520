import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Alert } from "reactstrap";

export class ConfirmDeleteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isOpen, toggle, title, onSubmit } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Alert color="warning">Are you sure want to delete this ?</Alert>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={onSubmit}>
            Delete
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ConfirmDeleteModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfirmDeleteModal;
