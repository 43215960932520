import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL as NIL_UUID } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row } from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { userService } from "services/user-service";
import UserSelectionModalList from "./UserSelectionModalList";
import ShippingAddressModalForm from "containers/shipping-contact/ModalFormShippingContact";
import SelectionListModal from "containers/shipping-contact/SelectionListModal";
import {
  addNewShippingContactRequest,
  getShippingContactByIdRequest,
  getShippingContactRequest,
} from "store/shipping-contact/saga";

const transportationTypeOptions = [
  { key: 1, value: "Air", label: "Air" },
  { key: 2, value: "Sea", label: "Sea" },
];

const formatOptionLabel = ({ label, phone, address }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-1">
        <i className="fas fa-user"></i>: <strong>{label}</strong>
      </span>
      <span className="mb-1">
        <i className="fas fa-phone"></i>: {phone}
      </span>
      <span className="mb-1">
        <i className="fas fa-address-book"></i>: {address}
      </span>
    </div>
  );
};

export class OrderModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL_UUID,
      senderId: null,
      receiverId: null,
      shippingContactId: null,
      transportationType: null,
      destination: "",
      items: [],
      newItem: null,
      addItemModalIsOpen: false,
      senderUsers: [],
      receiverUsers: [],
      userSelectedIsOpen: false,
      modalSelectReceiver: false,
      modalSelectSender: false,
      modalAddShippingAddress: false,
      modalSelectionShippingAddress: false,
      contact: "",
      submitted: false,
      shippingAddressOptions: [],
    };
  }

  toggleSelectReceiverModal = () => {
    this.setState(prevState => ({
      modalSelectReceiver: !prevState.modalSelectReceiver,
    }));
  };

  toggleSelectSenderModal = () => {
    this.setState(prevState => ({
      modalSelectSender: !prevState.modalSelectSender,
    }));
  };

  toggleUserSelect = () => {
    this.setState(prevState => ({
      userSelectedIsOpen: !prevState.userSelectedIsOpen,
    }));
  };

  toggleAddShippingAddress = () => {
    this.setState(prevState => ({
      modalAddShippingAddress: !prevState.modalAddShippingAddress,
    }));
  };

  toggleSelectionShippingAddress = () => {
    this.setState(prevState => ({
      modalSelectionShippingAddress: !prevState.modalSelectionShippingAddress,
    }));
  };

  componentDidMount = () => {
    userService.getUsersRequest().then(records => {
      if (records) {
        const users = records.map(user => {
          return {
            key: user.id,
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          };
        });
        this.setState({
          senderUsers: users,
          receiverUsers: users,
        });
      }
    });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });

    if (name === "receiverId") {
      if (valueType) {
        const { value } = valueType;
        this.populateShippingAddress(value);
      }
    }
  };

  handleSelectReceiver = () => {
    this.setState({
      modalSelectReceiver: true,
    });
  };

  handleSelectSender = () => {
    this.setState({
      modalSelectSender: true,
    });
  };

  handleSelectReceiverUser = user => {
    this.setState({
      modalSelectReceiver: false,
      receiverId: {
        key: user.id,
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      },
    });
  };

  handleSelectSenderUser = user => {
    this.setState({
      modalSelectSender: false,
      senderId: {
        key: user.id,
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      },
    });
  };

  handleAddNewShippingAddress = () => {
    this.setState({
      modalAddShippingAddress: true,
    });
  };

  handleSubmit = () => {
    this.setState({
      submitted: true,
    });
    const {
      id,
      senderId,
      receiverId,
      transportationType,
      destination,
      shippingContactId,
    } = this.state;
    if (senderId && receiverId && transportationType) {
      const data = {
        id,
        senderId: senderId?.value,
        receiverId: receiverId?.value,
        shippingContactId: shippingContactId?.value ?? null,
        transportationType: transportationType?.value,
        destination: destination ?? "",
      };

      this.props.onSubmit(data);
    }
  };

  hanldeSubmitShippingAddress = shippingAddress => {
    addNewShippingContactRequest(shippingAddress)
      .then(id => {
        const { userId } = shippingAddress;
        getShippingContactByIdRequest(id).then(data => {
          if (data) {
            this.populateShippingAddress(userId);
            this.setState({
              modalAddShippingAddress: false,
              shippingContactId: {
                key: data.id,
                value: data.id,
                label: data.contact,
                phone: data.phone,
                address: data.address,
              },
            });
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleShippingAddressSelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      this.setState({
        modalAddShippingAddress: true,
        contact: newValue.value,
      });
    } else {
      this.setState({
        shippingContactId: newValue,
      });
    }
  };

  populateShippingAddress = userId => {
    getShippingContactRequest({ userId }).then(data => {
      this.setState({
        shippingAddressOptions: data.map(item => {
          const { id, contact, phone, address } = item;
          return {
            key: id,
            value: id,
            label: contact,
            phone,
            address,
          };
        }),
      });
    });
  };

  handleSelectionShippingAddress = () => {
    this.setState({
      modalSelectionShippingAddress: true,
    });
  };

  handleSubmitSelectionShippingAddress = item => {
    if (item) {
      this.setState({
        modalSelectionShippingAddress: false,
        shippingContactId: {
          key: item.id,
          value: item.id,
          label: item.contact,
          phone: item.phone,
          address: item.address,
        },
      });
    }
  };

  onOpened = () => {
    this.setState({
      id: NIL_UUID,
      senderId: null,
      receiverId: null,
      shippingContactId: null,
      transportationType: null,
      destination: "",
      items: [],
      newItem: null,
      addItemModalIsOpen: false,
      // senderUsers: [],
      // receiverUsers: [],
      userSelectedIsOpen: false,
      modalSelectReceiver: false,
      modalSelectSender: false,
      modalAddShippingAddress: false,
      modalSelectionShippingAddress: false,
      contact: "",
      submitted: false,
      shippingAddressOptions: [],
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const {
      senderUsers,
      receiverUsers,
      senderId,
      receiverId,
      shippingContactId,
      transportationType,
      submitted,
    } = this.state;

    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <div className="mb-2">
                <Label>Sender</Label>
                <FormGroup
                  className={
                    "select2-container d-flex " +
                    (submitted && !senderId ? "is-invalid" : "")
                  }
                >
                  <div style={{ flexGrow: "1" }}>
                    <Select
                      name="senderId"
                      value={this.state.senderId}
                      onChange={this.handleSelectChange}
                      options={senderUsers}
                      classNamePrefix="select2-selection"
                      isClearable
                    />
                  </div>
                  <Button
                    color="primary"
                    onClick={this.handleSelectSender}
                    outline
                  >
                    <i className="bx bx-search-alt"></i> Find
                  </Button>
                </FormGroup>
                {submitted && !senderId && (
                  <div className="invalid-feedback-custom">
                    Sender is required.
                  </div>
                )}
              </div>
              <div className="mb-2">
                <Label>Receiver</Label>
                <FormGroup
                  className={
                    "select2-container d-flex " +
                    (submitted && !receiverId ? "is-invalid" : "")
                  }
                >
                  <div style={{ flexGrow: "1" }}>
                    <Select
                      name="receiverId"
                      value={this.state.receiverId}
                      onChange={this.handleSelectChange}
                      options={receiverUsers}
                      classNamePrefix="select2-selection"
                      isClearable
                    />
                  </div>
                  <Button
                    color="primary"
                    onClick={this.handleSelectReceiver}
                    outline
                  >
                    <i className="bx bx-search-alt"></i> Find
                  </Button>
                </FormGroup>
                {submitted && !receiverId && (
                  <div className="invalid-feedback-custom">
                    Receiver is required.
                  </div>
                )}
              </div>
              <div className="mb-2">
                <Label>Shipping Contact</Label>
                <FormGroup
                  className={
                    "select2-container d-flex " +
                    (submitted && !shippingContactId ? "is-invalid" : "")
                  }
                >
                  <div style={{ flexGrow: "1" }}>
                    <CreatableSelect
                      name="shippingContactId"
                      value={this.state.shippingContactId}
                      onChange={this.handleShippingAddressSelectChange}
                      options={this.state.shippingAddressOptions}
                      classNamePrefix="select2-selection"
                      className="custom-shipping-addr"
                      formatOptionLabel={formatOptionLabel}
                      isDisabled={!receiverId}
                      // style={customStyles}
                      isClearable
                    />
                  </div>
                  <Button
                    color="primary"
                    onClick={this.handleSelectionShippingAddress}
                    outline
                    disabled={!receiverId}
                  >
                    <i className="bx bx-search-alt"></i> Find
                  </Button>
                </FormGroup>
                {submitted && !shippingContactId && (
                  <div className="invalid-feedback-custom">
                    Shipping Contact is required.
                  </div>
                )}
              </div>
              <FormGroup
                className={
                  "select2-container" +
                  (submitted && !transportationType ? " is-invalid" : "")
                }
              >
                <Label>Transportation Type</Label>
                <Select
                  name="transportationType"
                  value={this.state.transportationType}
                  onChange={this.handleSelectChange}
                  options={transportationTypeOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
              {submitted && !transportationType && (
                <div className="invalid-feedback-custom">
                  Transportation Type is required.
                </div>
              )}
            </Col>
            <UserSelectionModalList
              title={"Find Reciever"}
              isOpen={this.state.modalSelectReceiver}
              toggle={this.toggleSelectReceiverModal}
              onSubmit={this.handleSelectReceiverUser}
            />
            <UserSelectionModalList
              title={"Find Sender"}
              isOpen={this.state.modalSelectSender}
              toggle={this.toggleSelectSenderModal}
              onSubmit={this.handleSelectSenderUser}
            />
            <SelectionListModal
              title={"Find Shipping Contact"}
              userId={receiverId?.value ?? null}
              isOpen={this.state.modalSelectionShippingAddress}
              toggle={this.toggleSelectionShippingAddress}
              onSubmit={this.handleSubmitSelectionShippingAddress}
            />
            <ShippingAddressModalForm
              title={"Add New Shipping Contact"}
              contact={this.state.contact}
              userId={receiverId?.value ?? null}
              isOpen={this.state.modalAddShippingAddress}
              toggle={this.toggleAddShippingAddress}
              onSubmit={this.hanldeSubmitShippingAddress}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

OrderModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default OrderModalForm;
