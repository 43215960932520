export const GET_ATTRIBUTES = "GET_ATTRIBUTES";
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
export const GET_ATTRIBUTES_FAIL = "GET_ATTRIBUTES_FAIL";

export const GET_ATTRIBUTE_BY_ID = "GET_ATTRIBUTE_BY_ID";
export const GET_ATTRIBUTE_BY_ID_SUCCESS = "GET_ATTRIBUTE_BY_ID_SUCCESS";

export const ADD_NEW_ATTRIBUTE = "ADD_NEW_ATTRIBUTE";

export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";

export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";

export const ATTRIBUTE_CLEAR_ERROR = "ATTRIBUTE_CLEAR_ERROR";
