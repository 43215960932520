// LOCATION
export const GET_PAGING_LOCATION = "GET_PAGING_LOCATION";
export const GET_PAGING_LOCATION_SUCCESS = "GET_PAGING_LOCATION_SUCCESS";
export const GET_PAGING_LOCATION_ERROR = "GET_PAGING_LOCATION_ERROR";

export const GET_LOCATION_BY_ID = "GET_LOCATION_BY_ID";
export const GET_LOCATION_BY_ID_SUCCESS = "GET_LOCATION_BY_ID_SUCCESS";

export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const ADD_NEW_LOCATION_SUCCESS = "ADD_NEW_LOCATION_SUCCESS";
export const ADD_NEW_LOCATION_ERROR = "ADD_NEW_LOCATION_ERROR";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
