import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_PRODUCT_STOCK_IN_PRODUCT,
  GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID,
  UPDATE_PRODUCT_STOCK_IN_PRODUCT,
  DELETE_PRODUCT_STOCK_IN_PRODUCT,
  GET_PRODUCT_STOCK_IN_PRODUCTS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getProductStockInProductByIdSuccess,
  getProductStockInProductsError,
  getProductStockInProductsSuccess,
} from "./actions";

const getProductStockInProductsRequest = query =>
  get("/api/ProductStockInProduct/paging", { params: query });
const addNewProductStockInProductRequest = item =>
  post("/api/ProductStockInProduct", item);
const getProductStockInProductByIdRequest = id =>
  get(`/api/ProductStockInProduct/${id}`);
const updateProductStockInProductRequest = item =>
  update("/api/ProductStockInProduct", item);
const deleteProductStockInProductRequest = id =>
  del(`/api/ProductStockInProduct/${id}`);

function* getProductStockInProducts({ payload }) {
  try {
    const response = yield call(getProductStockInProductsRequest, payload);
    yield put(getProductStockInProductsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInProductsError(error));
  }
}

function* addNewProductStockInProduct({ payload }) {
  try {
    const { productStockInId } = payload;
    yield call(addNewProductStockInProductRequest, payload);
    const response = yield call(getProductStockInProductsRequest, {
      productStockInId,
    });
    yield put(getProductStockInProductsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInProductsError(error));
  }
}

function* updateProductStockInProduct({ payload }) {
  try {
    const { productStockInId } = payload;
    yield call(updateProductStockInProductRequest, payload);
    const response = yield call(getProductStockInProductsRequest, {
      productStockInId,
    });
    yield put(getProductStockInProductsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInProductsError(error));
  }
}

function* getProductStockInProductById({ payload }) {
  try {
    var response = yield call(getProductStockInProductByIdRequest, payload);
    yield put(getProductStockInProductByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInProductsError(error));
  }
}

function* deleteProductStockInProduct({ payload }) {
  try {
    const { productStockInId, id } = payload;
    yield call(deleteProductStockInProductRequest, id);
    const response = yield call(getProductStockInProductsRequest, {
      productStockInId,
    });
    yield put(getProductStockInProductsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockInProductsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PRODUCT_STOCK_IN_PRODUCTS, getProductStockInProducts);
  yield takeEvery(
    ADD_NEW_PRODUCT_STOCK_IN_PRODUCT,
    addNewProductStockInProduct
  );
  yield takeEvery(
    GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID,
    getProductStockInProductById
  );
  yield takeEvery(UPDATE_PRODUCT_STOCK_IN_PRODUCT, updateProductStockInProduct);
  yield takeEvery(DELETE_PRODUCT_STOCK_IN_PRODUCT, deleteProductStockInProduct);
}

export default itemSaga;
