import {
  GET_UNIT_OF_MEASUREMENTS,
  GET_UNIT_OF_MEASUREMENTS_SUCCESS,
  GET_UNIT_OF_MEASUREMENTS_ERROR,
  GET_UNIT_OF_MEASUREMENT_BY_ID,
  GET_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS,
  ADD_NEW_UNIT_OF_MEASUREMENT,
  ADD_NEW_UNIT_OF_MEASUREMENT_SUCCESS,
  UPDATE_UNIT_OF_MEASUREMENT,
  UPDATE_UNIT_OF_MEASUREMENT_SUCCESS,
  DELETE_UNIT_OF_MEASUREMENT,
  DELETE_UNIT_OF_MEASUREMENT_SUCCESS,
  UNIT_OF_MEASUREMENT_CLEAR_ERROR,
  ADD_NEW_UNIT_OF_MEASUREMENT_ERROR,
} from "./actionTypes";
// UnitOfMeasurement
export const getUnitOfMeasurements = query => ({
  type: GET_UNIT_OF_MEASUREMENTS,
  payload: query,
});

export const getUnitOfMeasurementsSuccess = payload => ({
  type: GET_UNIT_OF_MEASUREMENTS_SUCCESS,
  payload,
});

export const getUnitOfMeasurementsError = error => ({
  type: GET_UNIT_OF_MEASUREMENTS_ERROR,
  payload: error,
});

export const getUnitOfMeasurementById = id => ({
  type: GET_UNIT_OF_MEASUREMENT_BY_ID,
  payload: id,
});

export const getUnitOfMeasurementByIdSuccess = payload => ({
  type: GET_UNIT_OF_MEASUREMENT_BY_ID_SUCCESS,
  payload,
});

export const addNewUnitOfMeasurement = item => ({
  type: ADD_NEW_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const addNewUnitOfMeasurementSuccess = item => ({
  type: ADD_NEW_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const addNewUnitOfMeasurementError = error => ({
  type: ADD_NEW_UNIT_OF_MEASUREMENT_ERROR,
  payload: error,
});

export const updateUnitOfMeasurement = item => ({
  type: UPDATE_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const updateUnitOfMeasurementSuccess = item => ({
  type: UPDATE_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const deleteUnitOfMeasurement = item => ({
  type: DELETE_UNIT_OF_MEASUREMENT,
  payload: item,
});

export const deleteUnitOfMeasurementSuccess = item => ({
  type: DELETE_UNIT_OF_MEASUREMENT_SUCCESS,
  payload: item,
});

export const clearUnitOfMeasurementError = () => ({
  type: UNIT_OF_MEASUREMENT_CLEAR_ERROR,
});
