import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_NEW_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_BY_ID,
  UPDATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  GET_CONFIRM_PURCHASE_ORDER,
  PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN,
  GET_PURCHASE_ORDER_MATERIAL,
  GET_PURCHASE_ORDER_SUMMARY,
} from "./actionTypes"
import FileSaver from "file-saver"
import { get, post, del, put as update, getFile } from "../../helpers/api_helper"
import {
  generatePurchaseOrderProductStockInSuccess,
  getConfirmPurchaseOrderSuccess,
  getPurchaseOrderByIdSuccess, getPurchaseOrderMaterialSuccess,
  getPurchaseOrdersError,
  getPurchaseOrdersSuccess, getPurchaseOrderSummarySuccess,
} from "./actions"
import { getFinishedGoodss, getFinishedGoodssSuccess } from "../finished-goods/actions"
import { getFinishedGoodssRequest } from "../finished-goods/saga"

const getAllPurchaseOrdersRequest = () => get("/api/PurchaseOrder")

const getPurchaseOrdersRequest = query =>
    get("/api/PurchaseOrder/paging", { params: query })
const addNewPurchaseOrderRequest = item => post("/api/PurchaseOrder", item)
const validatePurchaseOrderStockRequest = item => post("/api/PurchaseOrder/validateStock", item)
const getPurchaseOrderByIdRequest = id => get(`/api/PurchaseOrder/${ id }`)
const getConfirmPurchaseOrderReqeust = id =>
    get(`/api/PurchaseOrder/${ id }/confirm`)
const updatePurchaseOrderRequest = item => update("/api/PurchaseOrder", item)
const deletePurchaseOrderRequest = id => del(`/api/PurchaseOrder/${ id }`)
const generatePurchaseOrderProductStockInRequest = id => get(`/api/PurchaseOrder/${ id }/generateStockIn`)

const getPurchaseOrderMaterialRequest = id => get(`/api/PurchaseOrder/${ id }/material`)
const getPurchaseOrderSummaryRequest = query =>
    get("/api/PurchaseOrder/summary", { params: query })

const getPurchaseOrderSummaryToExcel = async (query, fileName,) => {
  return await getFile(`/api/PurchaseOrder/summaryExportToExcel`, {
    params: query,
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res
      FileSaver.saveAs(new Blob([ data ]), fileName)
    }
  })
}

function* getPurchaseOrders({ payload }) {
  try {
    const response = yield call(getPurchaseOrdersRequest, payload)
    yield put(getPurchaseOrdersSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* addNewPurchaseOrder({ payload }) {
  try {
    yield call(addNewPurchaseOrderRequest, payload)
    const response = yield call(getPurchaseOrdersRequest, {})
    yield put(getPurchaseOrdersSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* updatePurchaseOrder({ payload }) {
  try {
    yield call(updatePurchaseOrderRequest, payload)
    const response = yield call(getPurchaseOrdersRequest, {})
    yield put(getPurchaseOrdersSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* getPurchaseOrderById({ payload }) {
  try {
    var response = yield call(getPurchaseOrderByIdRequest, payload)
    yield put(getPurchaseOrderByIdSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* getPurchaseOrderMaterial({ payload }) {
  try {
    let response = yield call(getPurchaseOrderMaterialRequest, payload)
    console.log()
    yield put(getPurchaseOrderMaterialSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* generatePurchaseOrderProductStockIn({ payload }) {
  try {
    const { data, queryParam } = payload
    let response = yield call(generatePurchaseOrderProductStockInRequest, data)
    yield put(generatePurchaseOrderProductStockInSuccess(response))
    
    yield put(getFinishedGoodss({ purchaseOrderId: data }))
    response = yield call(getFinishedGoodssRequest, queryParam)
    yield put(getFinishedGoodssSuccess(response))
    
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* getConfirmPurchaseOrder({ payload }) {
  try {
    const { PurchaseOrderId, type } = payload

    let response = yield call(getConfirmPurchaseOrderReqeust, PurchaseOrderId)
    if (type === "List") {
      response = yield call(getPurchaseOrdersRequest, {})
      yield put(getPurchaseOrdersSuccess(response))
    }
    yield put(getConfirmPurchaseOrderSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* deletePurchaseOrder({ payload }) {
  try {
    yield call(deletePurchaseOrderRequest, payload)
    const response = yield call(getPurchaseOrdersRequest, {})
    yield put(getPurchaseOrdersSuccess(response))
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* getPurchaseOrderSummary({ payload }) {
  try {
    const response = yield call(getPurchaseOrderSummaryRequest, payload)
    yield put(getPurchaseOrderSummarySuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getPurchaseOrdersError(error))
  }
}

function* itemSaga() {
  yield takeEvery(GET_PURCHASE_ORDERS, getPurchaseOrders)
  yield takeEvery(ADD_NEW_PURCHASE_ORDER, addNewPurchaseOrder)
  yield takeEvery(GET_PURCHASE_ORDER_BY_ID, getPurchaseOrderById)
  yield takeEvery(GET_PURCHASE_ORDER_MATERIAL, getPurchaseOrderMaterial)
  yield takeEvery(UPDATE_PURCHASE_ORDER, updatePurchaseOrder)
  yield takeEvery(DELETE_PURCHASE_ORDER, deletePurchaseOrder)
  yield takeEvery(GET_CONFIRM_PURCHASE_ORDER, getConfirmPurchaseOrder)
  yield takeEvery(PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN, generatePurchaseOrderProductStockIn)
  yield takeEvery(GET_PURCHASE_ORDER_SUMMARY, getPurchaseOrderSummary)
}

export default itemSaga

export {
  getPurchaseOrderByIdRequest,
  getAllPurchaseOrdersRequest,
  getPurchaseOrderMaterialRequest,
  getPurchaseOrderSummaryToExcel,
  validatePurchaseOrderStockRequest
}
