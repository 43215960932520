import { SEVERITY } from "helpers/utils.js";
import {
  GET_CLOTHING_SIZES,
  GET_CLOTHING_SIZES_SUCCESS,
  GET_CLOTHING_SIZE_BY_ID_SUCCESS,
  GET_CLOTHING_SIZES_ERROR,
  CLOTHING_SIZE_CLEAR_ERROR,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const clothingSize = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLOTHING_SIZES:
      return {
        ...state,
        loading: true,
      };
    case GET_CLOTHING_SIZES_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_CLOTHING_SIZE_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_CLOTHING_SIZES_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ClothingSize_Name");
      return {
        ...state,
        message: {
          title: "Clothing Size",
          text: hasUniqueCode ? "Clothing Size is duplicate Name." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case CLOTHING_SIZE_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default clothingSize;
