export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";
export const GET_UNITS_FAIL = "GET_UNITS_FAIL";

export const GET_UNIT_BY_ID = "GET_UNIT_BY_ID";
export const GET_UNIT_BY_ID_SUCCESS = "GET_UNIT_BY_ID_SUCCESS";

export const ADD_NEW_UNIT = "ADD_NEW_UNIT";

export const UPDATE_UNIT = "UPDATE_UNIT";

export const DELETE_UNIT = "DELETE_UNIT";

export const UNIT_CLEAR_ERROR = "UNIT_CLEAR_ERROR";
