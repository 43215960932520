import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Badge, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";
import Form from "containers/transport/Form";
import {
  updateOrder,
  getOrderById,
  updateOrderAttachment,
  deleteOrderAttachment,
  getOrderAttachments,
  getTransportOrders,
  getTransportById,
  updateTransport,
  getTransportNote,
} from "store/actions";
import ReceiveOrderTab from "./ReceiveOrderTab";

export class ReceiveOrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { match, getTransportById } = this.props;
    const { id } = match.params;

    if (id !== NIL) {
      this.setState({
        id,
      });
      getTransportById(id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { match, getTransportById } = props;
    const { id } = match.params;
    if (id !== state.id) {
      getTransportById(id);
      return id;
    }
    return null;
  }

  handleUpdateAttachment = attachments => {
    const payload = {
      id: this.state.id,
      attachments,
    };

    this.props.updateOrderAttachment(payload);
  };

  handlePopulateAttachment = () => {
    const { id } = this.state;
    this.props.getOrderAttachments({ orderId: id });
  };

  handleSubmit = order => {
    const { updateOrder, history } = this.props;
    const payload = {
      history,
      data: order,
    };
    updateOrder(payload);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/transport");
  };

  handleOnBackToList = () => {
    const { history } = this.props;
    history.push("/transport");
  };

  handlePopulateTransportOrder = () => {
    const { id } = this.state;
    const { getTransportOrders } = this.props;
    getTransportOrders({ transportId: id });
  };

  handlePopulateTranspot = () => {
    const { id } = this.state;
    const { getTransportById } = this.props;
    getTransportById(id);
  };
  handlePopulateTransportNote = () => {
    const { id } = this.state;
    this.props.getTransportNote({ transportId: id });
  };

  handleOnSubmit = data => {
    this.props.updateTransport(data);
  };

  render() {
    const { item, loading } = this.props;

    let orderCount = item?.orders?.length ?? 0;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Receive Order Detail | JLS Express</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Home"
              backLink={"/receive-order"}
              breadcrumbItem="Receive Order Detail"
            />
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                        this.handlePopulateTranspot();
                      }}
                    >
                      General
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                        this.handlePopulateTransportOrder();
                      }}
                    >
                      Orders
                      <Badge color="success" className="ms-1">
                        {orderCount}
                      </Badge>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {loading ? (
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-border text-primary m-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {item && (
                          <Form
                            item={item}
                            onSubmit={this.handleOnSubmit}
                            isReceiver={true}
                            showStatus
                          />
                        )}
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.id !== NIL && (
                      <ReceiveOrderTab transportId={this.state.id} />
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ReceiveOrderDetail.propTypes = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getOrderById: PropTypes.func,
  updateOrder: PropTypes.func,
  updateOrderAttachment: PropTypes.func,
  deleteOrderAttachment: PropTypes.func,
  getOrderAttachments: PropTypes.func,
  getTransportOrders: PropTypes.func,
  getTransportById: PropTypes.func,
  updateTransport: PropTypes.func,
  getTransportNote: PropTypes.func,
};

const mapStateToProps = ({ transport }) => {
  return transport;
};

export default withRouter(
  connect(mapStateToProps, {
    updateOrder,
    getOrderById,
    updateOrderAttachment,
    deleteOrderAttachment,
    getOrderAttachments,
    getTransportOrders,
    getTransportById,
    updateTransport,
    getTransportNote,
  })(ReceiveOrderDetail)
);
