// ITEM_SHIPPING_FEE_ENTRY_ENTRY
export const GET_ITEM_SHIPPING_FEE_ENTRYS = "GET_ITEM_SHIPPING_FEE_ENTRYS";
export const GET_ITEM_SHIPPING_FEE_ENTRYS_SUCCESS =
  "GET_ITEM_SHIPPING_FEE_ENTRYS_SUCCESS";
export const GET_ITEM_SHIPPING_FEE_ENTRYS_FAIL =
  "GET_ITEM_SHIPPING_FEE_ENTRYS_FAIL";

export const GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID =
  "GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID";
export const GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_SUCCESS =
  "GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_SUCCESS";

export const ADD_NEW_ITEM_SHIPPING_FEE_ENTRY =
  "ADD_NEW_ITEM_SHIPPING_FEE_ENTRY";

export const UPDATE_ITEM_SHIPPING_FEE_ENTRY = "UPDATE_ITEM_SHIPPING_FEE_ENTRY";

export const DELETE_ITEM_SHIPPING_FEE_ENTRY = "DELETE_ITEM_SHIPPING_FEE_ENTRY";

export const ITEM_SHIPPING_FEE_ENTRY_CLEAR_ERROR =
  "ITEM_SHIPPING_FEE_ENTRY_CLEAR_ERROR";
