import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ATTRIBUTES,
  ADD_NEW_ATTRIBUTE,
  GET_ATTRIBUTE_BY_ID,
  UPDATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getAttributesSuccess,
  getAttributeByIdSuccess,
  getAttributesFail,
} from "./actions";

const getAttributesRequest = query =>
  get("/api/attribute/paging", { params: query });
const addNewAttributeRequest = attribute => post("/api/attribute", attribute);
const getAttributeByIdRequest = id => get(`/api/attribute/${id}`);
const updateAttributeRequest = attribute => update("/api/attribute", attribute);
const deleteAttributeRequest = id => del(`/api/attribute/${id}`);

function* fetchAttributes({ payload }) {
  try {
    const response = yield call(getAttributesRequest, payload);
    console.log(response);
    yield put(getAttributesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAttributesFail(error));
  }
}

function* addNewAttribute({ payload }) {
  try {
    yield call(addNewAttributeRequest, payload);
    const response = yield call(getAttributesRequest, {});
    yield put(getAttributesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAttributesFail(error));
  }
}

function* updateAttribute({ payload }) {
  try {
    yield call(updateAttributeRequest, payload);
    const response = yield call(getAttributesRequest, {});
    yield put(getAttributesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAttributesFail(error));
  }
}

function* getAttributeById({ payload }) {
  try {
    var response = yield call(getAttributeByIdRequest, payload);
    yield put(getAttributeByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAttributesFail(error));
  }
}

function* deleteAttribute({ payload }) {
  try {
    yield call(deleteAttributeRequest, payload);
    const response = yield call(getAttributesRequest, {});
    yield put(getAttributesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getAttributesFail(error));
  }
}

function* attributeSaga() {
  yield takeEvery(GET_ATTRIBUTES, fetchAttributes);
  yield takeEvery(ADD_NEW_ATTRIBUTE, addNewAttribute);
  yield takeEvery(GET_ATTRIBUTE_BY_ID, getAttributeById);
  yield takeEvery(UPDATE_ATTRIBUTE, updateAttribute);
  yield takeEvery(DELETE_ATTRIBUTE, deleteAttribute);
}

export default attributeSaga;
