import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICE_BY_ID,
  GET_INVOICE_BY_ID_SUCCESS,
  ADD_NEW_INVOICE,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  INVOICE_CLEAR_ERROR,
  GET_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_SUCCESS,
  INVOICE_CLEAR_NOTIFY,
  ADD_NEW_INVOICE_SUCCESS,
} from "./actionTypes";

// Invoice
export const getInvoices = query => ({
  type: GET_INVOICES,
  payload: query,
});

export const getInvoicesSuccess = items => ({
  type: GET_INVOICES_SUCCESS,
  payload: items,
});

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
});

export const getInvoiceById = data => ({
  type: GET_INVOICE_BY_ID,
  payload: data,
});

export const getInvoiceByIdSuccess = item => ({
  type: GET_INVOICE_BY_ID_SUCCESS,
  payload: item,
});

export const getInvoiceByIdFail = error => ({
  type: GET_INVOICE_BY_ID_FAIL,
  payload: error,
});

export const getInvoiceDetail = id => ({
  type: GET_INVOICE_DETAIL,
  payload: id,
});

export const getInvoiceDetailSuccess = payload => ({
  type: GET_INVOICE_DETAIL_SUCCESS,
  payload,
});

export const addNewInvoice = item => ({
  type: ADD_NEW_INVOICE,
  payload: item,
});

export const addNewInvoiceSuccess = payload => ({
  type: ADD_NEW_INVOICE_SUCCESS,
  payload,
});

export const updateInvoice = item => ({
  type: UPDATE_INVOICE,
  payload: item,
});

export const deleteInvoice = item => ({
  type: DELETE_INVOICE,
  payload: item,
});

export const clearInvoiceError = () => ({
  type: INVOICE_CLEAR_ERROR,
});

export const clearInvoiceNotify = () => ({
  type: INVOICE_CLEAR_NOTIFY,
});
