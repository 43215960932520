// SETTING
export const GET_SETTING = "GET_SETTING";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_FAIL = "GET_SETTING_FAIL";

export const GET_SETTING_DEFAULT = "GET_SETTING_DEFAULT";
export const GET_SETTING_DEFAULT_SUCCESS = "GET_SETTING_DEFAULT_SUCCESS";
export const GET_SETTING_DEFAULT_ERROR = "GET_SETTING_DEFAULT_ERROR";

export const GET_SETTING_BY_ID = "GET_SETTING_BY_ID";
export const GET_SETTING_BY_ID_SUCCESS = "GET_SETTING_BY_ID_SUCCESS";

export const ADD_NEW_SETTING = "ADD_NEW_SETTING";
export const ADD_NEW_SETTING_SUCCESS = "ADD_NEW_SETTING_SUCCESS";
export const ADD_NEW_SETTING_ERROR = "ADD_NEW_SETTING_ERROR";

export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_ERROR = "UPDATE_SETTING_ERROR";

export const DELETE_SETTING = "DELETE_SETTING";

export const SETTING_CLEAR_NOTIFY = "SETTING_CLEAR_NOTIFY";
