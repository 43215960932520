import {
  GET_PRODUCT_STOCK_INS,
  GET_PRODUCT_STOCK_INS_SUCCESS,
  GET_PRODUCT_STOCK_INS_ERROR,
  GET_PRODUCT_STOCK_IN_BY_ID,
  GET_PRODUCT_STOCK_IN_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_STOCK_IN,
  ADD_NEW_PRODUCT_STOCK_IN_SUCCESS,
  UPDATE_PRODUCT_STOCK_IN,
  UPDATE_PRODUCT_STOCK_IN_SUCCESS,
  DELETE_PRODUCT_STOCK_IN,
  DELETE_PRODUCT_STOCK_IN_SUCCESS,
  PRODUCT_STOCK_IN_CLEAR_ERROR,
  ADD_NEW_PRODUCT_STOCK_IN_ERROR,
  GET_CONFIRM_PRODUCT_STOCK_IN,
  GET_CONFIRM_PRODUCT_STOCK_IN_SUCCESS,
} from "./actionTypes";
export const getProductStockIns = query => ({
  type: GET_PRODUCT_STOCK_INS,
  payload: query,
});

export const getProductStockInsSuccess = payload => ({
  type: GET_PRODUCT_STOCK_INS_SUCCESS,
  payload,
});

export const getProductStockInsError = error => ({
  type: GET_PRODUCT_STOCK_INS_ERROR,
  payload: error,
});

export const getProductStockInById = id => ({
  type: GET_PRODUCT_STOCK_IN_BY_ID,
  payload: id,
});

export const getProductStockInByIdSuccess = payload => ({
  type: GET_PRODUCT_STOCK_IN_BY_ID_SUCCESS,
  payload,
});

export const addNewProductStockIn = item => ({
  type: ADD_NEW_PRODUCT_STOCK_IN,
  payload: item,
});

export const addNewProductStockInSuccess = item => ({
  type: ADD_NEW_PRODUCT_STOCK_IN_SUCCESS,
  payload: item,
});

export const addNewProductStockInError = error => ({
  type: ADD_NEW_PRODUCT_STOCK_IN_ERROR,
  payload: error,
});

export const updateProductStockIn = item => ({
  type: UPDATE_PRODUCT_STOCK_IN,
  payload: item,
});

export const updateProductStockInSuccess = item => ({
  type: UPDATE_PRODUCT_STOCK_IN_SUCCESS,
  payload: item,
});

export const deleteProductStockIn = item => ({
  type: DELETE_PRODUCT_STOCK_IN,
  payload: item,
});

export const deleteProductStockInSuccess = item => ({
  type: DELETE_PRODUCT_STOCK_IN_SUCCESS,
  payload: item,
});

export const clearProductStockInError = () => ({
  type: PRODUCT_STOCK_IN_CLEAR_ERROR,
});

export const getConfirmProductStockIn = payload => ({
  type: GET_CONFIRM_PRODUCT_STOCK_IN,
  payload,
});

export const getConfirmProductStockInSuccess = payload => ({
  type: GET_CONFIRM_PRODUCT_STOCK_IN_SUCCESS,
  payload,
});
