import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_TRANSPORT_NOTE,
  ADD_NEW_TRANSPORT_NOTE,
  GET_TRANSPORT_NOTE_BY_ID,
  UPDATE_TRANSPORT_NOTE,
  DELETE_TRANSPORT_NOTE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getTransportNoteSuccess,
  getTransportNoteByIdSuccess,
  getTransportNoteFail,
} from "./actions";

const getTransportNoteRequest = query =>
  get(`/api/TransportNote/paging`, { params: query });
const addNewTransportNoteRequest = transportnote =>
  post(`/api/TransportNote`, transportnote);
const getTransportNoteByIdRequest = data =>
  get(`/api/TransportNote/${data.id}`);
const updateTransportNoteRequest = transportnote =>
  update(`/api/TransportNote`, transportnote);
const deleteTransportNoteRequest = data => del(`/api/TransportNote/${data.id}`);

function* fetchTransportNote({ payload }) {
  try {
    const response = yield call(getTransportNoteRequest, payload);

    yield put(getTransportNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportNoteFail(error));
  }
}

function* addNewTransportNote({ payload }) {
  try {
    console.log(payload);
    yield call(addNewTransportNoteRequest, payload);
    const { transportId } = payload;
    const response = yield call(getTransportNoteRequest, { transportId });
    yield put(getTransportNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportNoteFail(error));
  }
}

function* updateTransportNote({ payload }) {
  try {
    yield call(updateTransportNoteRequest, payload);
    const { transportId } = payload;
    const response = yield call(getTransportNoteRequest, { transportId });
    yield put(getTransportNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportNoteFail(error));
  }
}

function* getTransportNoteById({ payload }) {
  try {
    var response = yield call(getTransportNoteByIdRequest, payload);
    yield put(getTransportNoteByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportNoteFail(error));
  }
}

function* deleteTransportNote({ payload }) {
  try {
    yield call(deleteTransportNoteRequest, payload);
    const { transportId } = payload;

    const response = yield call(getTransportNoteRequest, { transportId });
    yield put(getTransportNoteSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportNoteFail(error));
  }
}

function* transportNoteSaga() {
  yield takeEvery(GET_TRANSPORT_NOTE, fetchTransportNote);
  yield takeEvery(ADD_NEW_TRANSPORT_NOTE, addNewTransportNote);
  yield takeEvery(GET_TRANSPORT_NOTE_BY_ID, getTransportNoteById);
  yield takeEvery(UPDATE_TRANSPORT_NOTE, updateTransportNote);
  yield takeEvery(DELETE_TRANSPORT_NOTE, deleteTransportNote);
}

export default transportNoteSaga;
