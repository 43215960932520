import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import PermissionRow from "containers/permission/PermissionRow";

export class PermissionTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      permissionGroups: [],
    };
  }

  componentDidMount() {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { item } = props;

    if (item) {
      const { id } = item;

      if (id !== state.id) {
        return {
          ...item,
        };
      }
    }

    return null;
  }

  handlePermissionRowChange = item => {
    this.setState(prevState => ({
      permissionGroups: prevState.permissionGroups.map(obj => {
        return obj.name === item.name ? item : obj;
      }),
    }));
  };

  handleSubmit = () => {
    const { id, name, permissionGroups } = this.state;

    const item = {
      id,
      name,
      permissionGroups,
    };

    this.props.onSubmit(item);
  };

  render() {
    const { permissionGroups } = this.state;
    return (
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <CardBody>
              {permissionGroups &&
                permissionGroups.map((permissionGroup, index) => {
                  return (
                    <PermissionRow
                      index={index}
                      permissionGroup={permissionGroup}
                      key={index}
                      onChange={this.handlePermissionRowChange}
                    />
                  );
                })}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" onClick={this.handleSubmit}>
                  Submit{" "}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

PermissionTab.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default PermissionTab;
