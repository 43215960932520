import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import {
  getStockAdjustmentById,
  getItemUnitOfMeasurements,
  updateStockAdjustment
} from "store/actions"
import StockAdjustmentForm from "../../containers/stock-adjustment/StockAdjustmentForm"

const StockAdjustmentDetail = props => {
  const { match, history } = props

  const [ activeTab, setActiveTab ] = useState("1")
  const [ id, setId ] = useState(null)

  const { getStockAdjustmentById, updateStockAdjustment, stockAdjustment } = props
  const { item, loading } = stockAdjustment

  useEffect(() => {
    const { id } = match.params
    getStockAdjustmentById(id)
    setId(id)
  }, [])

  const handleOnSubmit = (data) => {
    updateStockAdjustment(data)
    history.push(`/stock-adjustment`)
  }

  const handleOnCancel = () => {
    history.push(`/stock-adjustment`)
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock Adjustment Detail | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            { loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <>
                  { item ? (
                      <>
                        <Breadcrumbs
                            title="Home"
                            backLink={ "/stock-adjustment" }
                            breadcrumbItem={ `${ item.code } | Stock Adjustment Detail` }
                        />
                        
                        <StockAdjustmentForm item={ item } onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
                      </>
                  ) : null }
                </>
            ) }
          </Container>
        </div>
      </React.Fragment>
  )
}

StockAdjustmentDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  stockAdjustment: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getStockAdjustmentById: PropTypes.func,
  updateStockAdjustment: PropTypes.func,
}

const mapStateToProps = ({ stockAdjustment }) => {
  return {
    stockAdjustment,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getStockAdjustmentById,
      getItemUnitOfMeasurements,
      updateStockAdjustment,
    })(StockAdjustmentDetail)
)
