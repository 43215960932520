import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDER_ATTACHMENTS,
  ADD_NEW_ORDER_ATTACHMENT,
  GET_ORDER_ATTACHMENT_BY_ID,
  UPDATE_ORDER_ATTACHMENT,
  UPDATE_ORDER_ATTACHMENTS,
  DELETE_ORDER_ATTACHMENT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getOrderAttachmentsSuccess,
  getOrderAttachmentByIdSuccess,
  getOrderAttachmentsFail,
} from "./actions";
// OrderAttachment
const getOrderAttachmentsRequest = query =>
  get("/api/OrderAttachment/paging", { params: query });
const addNewOrderAttachmentRequest = role => post("/api/OrderAttachment", role);
const getOrderAttachmentByIdRequest = id => get(`/api/OrderAttachment/${id}`);
const updateOrderAttachmentRequest = role =>
  update("/api/OrderAttachment", role);
const deleteOrderAttachmentRequest = id => del(`/api/OrderAttachment/${id}`);

const updateOrderAttachmentsRequest = ({ orderId, attachments }) => {
  return update(`/api/Order/${orderId}/attachments`, attachments);
};

function* fetchOrderAttachments({ payload }) {
  try {
    const response = yield call(getOrderAttachmentsRequest, payload);
    yield put(getOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderAttachmentsFail(error));
  }
}

function* addNewOrderAttachment({ payload }) {
  try {
    yield call(addNewOrderAttachmentRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderAttachmentsRequest, { orderId });
    yield put(getOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderAttachmentsFail(error));
  }
}

function* updateOrderAttachment({ payload }) {
  try {
    yield call(updateOrderAttachmentRequest, payload);
    const { orderId } = payload;
    const response = yield call(getOrderAttachmentsRequest, { orderId });
    yield put(getOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderAttachmentsFail(error));
  }
}

function* updateOrderAttachments({ payload }) {
  try {
    console.log(payload);
    let response = yield call(updateOrderAttachmentsRequest, payload);

    response = yield call(getOrderAttachmentsRequest, {
      orderId: response,
    });

    yield put(getOrderAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderAttachmentsFail(error));
  }
}

function* getOrderAttachmentById({ payload }) {
  try {
    var response = yield call(getOrderAttachmentByIdRequest, payload);
    yield put(getOrderAttachmentByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderAttachmentsFail(error));
  }
}

function* deleteOrderAttachment({ payload }) {
  try {
    const { id, orderId } = payload;
    let response = yield call(deleteOrderAttachmentRequest, id);
    response = yield call(getOrderAttachmentsRequest, { orderId });
    yield put(getOrderAttachmentsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderAttachmentsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ORDER_ATTACHMENTS, fetchOrderAttachments);
  yield takeEvery(ADD_NEW_ORDER_ATTACHMENT, addNewOrderAttachment);
  yield takeEvery(GET_ORDER_ATTACHMENT_BY_ID, getOrderAttachmentById);
  yield takeEvery(UPDATE_ORDER_ATTACHMENT, updateOrderAttachment);
  yield takeEvery(UPDATE_ORDER_ATTACHMENTS, updateOrderAttachments);
  yield takeEvery(DELETE_ORDER_ATTACHMENT, deleteOrderAttachment);
}

export default roleSaga;
