import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ITEM_SHIPPING_FEES,
  ADD_NEW_ITEM_SHIPPING_FEE,
  GET_ITEM_SHIPPING_FEE_BY_ID,
  UPDATE_ITEM_SHIPPING_FEE,
  DELETE_ITEM_SHIPPING_FEE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getItemShippingFeesSuccess,
  getItemShippingFeeByIdSuccess,
  getItemShippingFeesFail,
} from "./actions";
// ItemShippingFee
const getItemShippingFeesRequest = query =>
  get("/api/ItemShippingFee/paging", { params: query });
const addNewItemShippingFeeRequest = role => post("/api/ItemShippingFee", role);
const getItemShippingFeeByIdRequest = id => get(`/api/ItemShippingFee/${id}`);
const updateItemShippingFeeRequest = role =>
  update("/api/ItemShippingFee", role);
const deleteItemShippingFeeRequest = id => del(`/api/ItemShippingFee/${id}`);

function* fetchItemShippingFees({ payload }) {
  try {
    const response = yield call(getItemShippingFeesRequest, payload);
    yield put(getItemShippingFeesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeesFail(error));
  }
}

function* addNewItemShippingFee({ payload }) {
  try {
    yield call(addNewItemShippingFeeRequest, payload);
    const response = yield call(getItemShippingFeesRequest, {});
    yield put(getItemShippingFeesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeesFail(error));
  }
}

function* updateItemShippingFee({ payload }) {
  try {
    yield call(updateItemShippingFeeRequest, payload);
    const response = yield call(getItemShippingFeesRequest, {});
    yield put(getItemShippingFeesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeesFail(error));
  }
}

function* getItemShippingFeeById({ payload }) {
  try {
    var response = yield call(getItemShippingFeeByIdRequest, payload);
    yield put(getItemShippingFeeByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeesFail(error));
  }
}

function* deleteItemShippingFee({ payload }) {
  try {
    yield call(deleteItemShippingFeeRequest, payload);
    const response = yield call(getItemShippingFeesRequest, {});
    yield put(getItemShippingFeesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemShippingFeesFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ITEM_SHIPPING_FEES, fetchItemShippingFees);
  yield takeEvery(ADD_NEW_ITEM_SHIPPING_FEE, addNewItemShippingFee);
  yield takeEvery(GET_ITEM_SHIPPING_FEE_BY_ID, getItemShippingFeeById);
  yield takeEvery(UPDATE_ITEM_SHIPPING_FEE, updateItemShippingFee);
  yield takeEvery(DELETE_ITEM_SHIPPING_FEE, deleteItemShippingFee);
}

export default roleSaga;
