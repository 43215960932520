// FINISHED_GOODS
export const GET_FINISHED_GOODSS = "GET_FINISHED_GOODSS";
export const GET_FINISHED_GOODSS_SUCCESS = "GET_FINISHED_GOODSS_SUCCESS";
export const GET_FINISHED_GOODSS_ERROR = "GET_FINISHED_GOODSS_ERROR";

export const GET_FINISHED_GOODS_BY_ID = "GET_FINISHED_GOODS_BY_ID";
export const GET_FINISHED_GOODS_BY_ID_SUCCESS = "GET_FINISHED_GOODS_BY_ID_SUCCESS";

export const ADD_NEW_FINISHED_GOODS = "ADD_NEW_FINISHED_GOODS";
export const ADD_NEW_FINISHED_GOODS_SUCCESS = "ADD_NEW_FINISHED_GOODS_SUCCESS";
export const ADD_NEW_FINISHED_GOODS_ERROR = "ADD_NEW_FINISHED_GOODS_ERROR";

export const UPDATE_FINISHED_GOODS = "UPDATE_FINISHED_GOODS";
export const UPDATE_FINISHED_GOODS_SUCCESS = "UPDATE_FINISHED_GOODS_SUCCESS";
export const UPDATE_FINISHED_GOODS_ERROR = "UPDATE_FINISHED_GOODS_ERROR";

export const DELETE_FINISHED_GOODS = "DELETE_FINISHED_GOODS";
export const DELETE_FINISHED_GOODS_SUCCESS = "DELETE_FINISHED_GOODS_SUCCESS";

export const FINISHED_GOODS_CLEAR_ERROR = "FINISHED_GOODS_CLEAR_ERROR";

export const GET_FINISHED_GOODS_ENTRY_FORM = "GET_FINISHED_GOODS_ENTRY_FORM";
export const GET_FINISHED_GOODS_ENTRY_FORM_SUCCESS = "GET_FINISHED_GOODS_ENTRY_FORM_SUCCESS";

export const SAVE_FINISHED_GOODS_ENTRY_FORM = "SAVE_FINISHED_GOODS_ENTRY_FORM";
export const SAVE_FINISHED_GOODS_ENTRY_FORM_SUCCESS = "SAVE_FINISHED_GOODS_ENTRY_FORM_SUCCESS";

export const CHANGE_STATUS_FINISHED_GOODS = "CHANGE_STATUS_FINISHED_GOODS";
