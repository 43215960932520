// DELIVERY_ORDER
export const GET_DELIVERY_ORDERS = "GET_DELIVERY_ORDERS";
export const GET_DELIVERY_ORDERS_SUCCESS = "GET_DELIVERY_ORDERS_SUCCESS";
export const GET_DELIVERY_ORDERS_FAIL = "GET_DELIVERY_ORDERS_FAIL";

export const GET_DELIVERY_ORDER_BY_ID = "GET_DELIVERY_ORDER_BY_ID";
export const GET_DELIVERY_ORDER_BY_ID_SUCCESS =
  "GET_DELIVERY_ORDER_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERY_ORDER = "ADD_NEW_DELIVERY_ORDER";
export const ADD_NEW_DELIVERY_ORDER_SUCCESS = "ADD_NEW_DELIVERY_ORDER_SUCCESS";
export const ADD_NEW_DELIVERY_ORDER_ERROR = "ADD_NEW_DELIVERY_ORDER_ERROR";

export const UPDATE_DELIVERY_ORDER = "UPDATE_DELIVERY_ORDER";
export const UPDATE_DELIVERY_ORDER_SUCCESS = "UPDATE_DELIVERY_ORDER_SUCCESS";
export const UPDATE_DELIVERY_ORDER_ERROR = "UPDATE_DELIVERY_ORDER_ERROR";

export const DELETE_DELIVERY_ORDER = "DELETE_DELIVERY_ORDER";
export const DELETE_DELIVERY_ORDER_SUCCESS = "DELETE_DELIVERY_ORDER_SUCCESS";
export const DELETE_DELIVERY_ORDER_ERROR = "DELETE_DELIVERY_ORDER_ERROR";

export const DELIVERY_ORDER_CLEAR_NOTIFY = "DELIVERY_ORDER_CLEAR_NOTIFY";
