export const GET_INVOICE_NOTE = "GET_INVOICE_NOTE";
export const GET_INVOICE_NOTE_SUCCESS = "GET_INVOICE_NOTE_SUCCESS";
export const GET_INVOICE_NOTE_FAIL = "GET_INVOICE_NOTE_FAIL";

export const GET_INVOICE_NOTE_BY_ID = "GET_INVOICE_NOTE_BY_ID";
export const GET_INVOICE_NOTE_BY_ID_SUCCESS = "GET_INVOICE_NOTE_BY_ID_SUCCESS";

export const ADD_NEW_INVOICE_NOTE = "ADD_NEW_INVOICE_NOTE";
export const ADD_NEW_INVOICE_NOTE_SUCCESS = "ADD_NEW_INVOICE_NOTE_SUCCESS";

export const UPDATE_INVOICE_NOTE = "UPDATE_INVOICE_NOTE";
export const UPDATE_INVOICE_NOTE_SUCCESS = "UPDATE_INVOICE_NOTE_SUCCESS";

export const DELETE_INVOICE_NOTE = "DELETE_INVOICE_NOTE";
export const DELETE_INVOICE_NOTE_SUCCESS = "DELETE_INVOICE_NOTE_SUCCESS";

export const INVOICE_NOTE_CLEAR_NOTIFY = "INVOICE_NOTE_CLEAR_NOTIFY";
