import {
  GET_STOCK_OUTS,
  GET_STOCK_OUTS_SUCCESS,
  GET_STOCK_OUTS_ERROR,
  GET_STOCK_OUT_BY_ID,
  GET_STOCK_OUT_BY_ID_SUCCESS,
  ADD_NEW_STOCK_OUT,
  ADD_NEW_STOCK_OUT_SUCCESS,
  UPDATE_STOCK_OUT,
  UPDATE_STOCK_OUT_SUCCESS,
  DELETE_STOCK_OUT,
  DELETE_STOCK_OUT_SUCCESS,
  STOCK_OUT_CLEAR_ERROR,
  ADD_NEW_STOCK_OUT_ERROR,
  GET_CONFIRM_STOCK_OUT,
  GET_CONFIRM_STOCK_OUT_SUCCESS,
} from "./actionTypes";
// StockOut
export const getStockOuts = query => ({
  type: GET_STOCK_OUTS,
  payload: query,
});

export const getStockOutsSuccess = payload => ({
  type: GET_STOCK_OUTS_SUCCESS,
  payload,
});

export const getStockOutsError = error => ({
  type: GET_STOCK_OUTS_ERROR,
  payload: error,
});

export const getStockOutById = id => ({
  type: GET_STOCK_OUT_BY_ID,
  payload: id,
});

export const getStockOutByIdSuccess = payload => ({
  type: GET_STOCK_OUT_BY_ID_SUCCESS,
  payload,
});

export const addNewStockOut = item => ({
  type: ADD_NEW_STOCK_OUT,
  payload: item,
});

export const addNewStockOutSuccess = item => ({
  type: ADD_NEW_STOCK_OUT_SUCCESS,
  payload: item,
});

export const addNewStockOutError = error => ({
  type: ADD_NEW_STOCK_OUT_ERROR,
  payload: error,
});

export const updateStockOut = item => ({
  type: UPDATE_STOCK_OUT,
  payload: item,
});

export const updateStockOutSuccess = item => ({
  type: UPDATE_STOCK_OUT_SUCCESS,
  payload: item,
});

export const deleteStockOut = item => ({
  type: DELETE_STOCK_OUT,
  payload: item,
});

export const deleteStockOutSuccess = item => ({
  type: DELETE_STOCK_OUT_SUCCESS,
  payload: item,
});

export const clearStockOutError = () => ({
  type: STOCK_OUT_CLEAR_ERROR,
});

export const getConfirmStockOut = payload => ({
  type: GET_CONFIRM_STOCK_OUT,
  payload,
});

export const getConfirmStockOutSuccess = payload => ({
  type: GET_CONFIRM_STOCK_OUT_SUCCESS,
  payload,
});
