// Sale Order
const order = {
  enable: "Order.Enable",
  enableOnMobile: "Order.EnableOnMobile",
  read: "Order.Read",
  write: "Order.Write",
  delete: "Order.Delete",
  export: "Order.Export",
};

// Transport
const receiveOrder = {
  enable: "ReceiveOrder.Enable",
};

const transport = {
  enable: "Transport.Enable",
  read: "Transport.Read",
  write: "Transport.Write",
  delete: "Transport.Delete",
};

// Delivery order
const deliveryOrder = {
  enable: "DeliveryOrder.Enable",
};

const deliveryAgency = {
  enable: "DeliveryAgency.Enable",
};

const deliverer = {
  enable: "Deliverer.Enable",
};

// Invoice
const invoice = {
  enable: "Invoice.Enable",
};

const invoiceNote = {
  enable: "InvoiceNote.Enable",
  read: "InvoiceNote.Read",
  write: "InvoiceNote.Write",
  delete: "InvoiceNote.Delete",
};

const invoicePayment = {
  enable: "InvoicePayment.Enable",
  read: "InvoicePayment.Read",
  write: "InvoicePayment.Write",
  delete: "InvoicePayment.Delete",
  export: "InvoicePayment.Export",
};

const paymentHistory = {
  enable: "PaymentHistory.Enable",
};

// Inventory
const unit = {
  enable: "Unit.Enable",
  read: "Unit.Read",
  write: "Unit.Write",
  delete: "Unit.Delete",
};

const warehouse = {
  enable: "Warehouse.Enable",
  read: "Warehouse.Read",
  write: "Warehouse.Write",
  delete: "Warehouse.Delete",
};

const category = {
  enable: "Category.Enable",
  read: "Category.Read",
  write: "Category.Write",
  delete: "Category.Delete",
};

const attribute = {
  enable: "Attribute.Enable",
};

const banner = {
  enable: "Banner.Enable",
  read: "Banner.Read",
  write: "Banner.Write",
  delete: "Banner.Delete",
};


const location = {
  enable: "Location.Enable",
  read: "Location.Read",
  write: "Location.Write",
  delete: "Location.Delete",
};

// User Management
const user = {
  enable: "User.Enable",
};

const role = {
  enable: "Role.Enable",
};

const permissionEntry = {
  enable: "Permission.Enable",
};

// Reports
const orderReport = {
  enable: "OrderReport.Enable",
};

const orderVerifyTracking = {
  enable: "OrderVerifyTracking.Enable",
};

const sendOrderReport = {
  enable: "SendOrderReport.Enable",
};

const receiveOrderReport = {
  enable: "ReceiveOrderReport.Enable",
};

const deliveryOrderReport = {
  enable: "DeliveryOrderReport.Enable",
};

const invoiceReport = {
  enable: "InvoiceReport.Enable",
};

// Settings
const setting = {
  enable: "Setting.Enable",
};

const currency = {
  enable: "Currency.Enable",
};

const shippingFee = {
  enable: "ShippingFee.Enable",
};

const deviceInfo = {
  enable: "DeviceInfo.Enable",
};

const clothingSize = {
  enable: "ClothingSize.Enable",
};

const transferLocation = {
  enable: "TransferLocation.Enable",
};

const codeColor = {
  enable: "CodeColor.Enable",
  read: "CodeColor.Read",
  write: "CodeColor.Write",
  delete: "CodeColor.Delete",
};

const unitOfMeasurement = {
  enable: "UnitOfMeasurement.Enable",
};

const productStockIn = {
  enable: "ProductStockIn.Enable",
};

const productStockOut = {
  enable: "ProductStockOut.Enable",
};

const stockIn = {
  enable: "StockIn.Enable",
};

const stockOut = {
  enable: "StockOut.Enable",
};

const stockAdjustment = {
  enable: "StockAdjustment.Enable",
};

const product = {
  enable: "Product.Enable",
  read: "Product.Read",
  write: "Product.Write",
  delete: "Product.Delete",
};

const item = {
  enable: "Item.Enable",
  read: "Item.Read",
  write: "Item.Write",
  delete: "Item.Delete",
};

const packaging = {
  enable: "Packaging.Enable",
};

const finishedGoods = {
  enable: "FinishedGoods.Enable",
};

const packagingStockRequest = {
  enable: "PackagingStockRequest.Enable",
};

const sewing = {
  enable: "Sewing.Enable",
};
const sewingStockRequest = {
  enable: "SewingStockRequest.Enable",
};

const cutting = {
  enable: "Cutting.Enable",
};

const cuttingStockRequest = {
  enable: "CuttingStockRequest.Enable",
};

const purchaseOrder = {
  enable: "PurchaseOrder.Enable",
};

const stockRequest = {
  enable: "StockRequest.Enable",
  read: "StockRequest.Read",
  write: "StockRequest.Write",
  delete: "StockRequest.Delete",
};
const stockRequestItem = {
  read: "StockRequestItem.Read",
  write: "StockRequestItem.Write",
  delete: "StockRequestItem.Delete",
};
const adjustStock={
  enable: "AdjustStock.Enable",
}

export const permission = {
  order,

  transport,
  receiveOrder,

  deliveryOrder,
  deliveryAgency,
  deliverer,

  invoice,
  invoiceNote,
  invoicePayment,
  paymentHistory,

  unit,
  warehouse,
  category,
  attribute,
  banner,
  
  unitOfMeasurement,
  clothingSize,
  transferLocation,
  codeColor,
  user,
  role,
  permissionEntry,
  location,

  orderReport,
  orderVerifyTracking,
  sendOrderReport,
  receiveOrderReport,
  deliveryOrderReport,
  invoiceReport,

  setting,
  currency,
  shippingFee,
  deviceInfo,
  productStockIn,
  productStockOut,
  stockIn,
  stockOut,
  stockAdjustment,
  product,
  item,
  packaging,
  packagingStockRequest,
  sewing,
  sewingStockRequest,
  cutting,
  cuttingStockRequest,
  purchaseOrder,
  stockRequest,
  stockRequestItem,
  finishedGoods,
  adjustStock
};
