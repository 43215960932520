import {
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_IMAGES_SUCCESS,
  GET_PRODUCT_IMAGES_FAIL,
  GET_PRODUCT_IMAGE_BY_ID,
  GET_PRODUCT_IMAGE_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT_IMAGE,
  UPDATE_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  PRODUCT_IMAGE_CLEAR_ERROR,
  UPDATE_PRODUCT_IMAGES,
} from "./actionTypes";

// ProductImage
export const getProductImages = query => ({
  type: GET_PRODUCT_IMAGES,
  payload: query,
});

export const getProductImagesSuccess = items => ({
  type: GET_PRODUCT_IMAGES_SUCCESS,
  payload: items,
});

export const getProductImagesFail = error => ({
  type: GET_PRODUCT_IMAGES_FAIL,
  payload: error,
});

export const getProductImageById = id => ({
  type: GET_PRODUCT_IMAGE_BY_ID,
  payload: id,
});

export const getProductImageByIdSuccess = item => ({
  type: GET_PRODUCT_IMAGE_BY_ID_SUCCESS,
  payload: item,
});

export const getProductImageByIdFail = error => ({
  type: GET_PRODUCT_IMAGE_BY_ID_FAIL,
  payload: error,
});

export const addNewProductImage = item => ({
  type: ADD_NEW_PRODUCT_IMAGE,
  payload: item,
});

export const updateProductImage = item => ({
  type: UPDATE_PRODUCT_IMAGE,
  payload: item,
});

export const updateProductImages = payload => ({
  type: UPDATE_PRODUCT_IMAGES,
  payload,
});

export const deleteProductImage = item => ({
  type: DELETE_PRODUCT_IMAGE,
  payload: item,
});

export const clearProductImageError = () => ({
  type: PRODUCT_IMAGE_CLEAR_ERROR,
});
