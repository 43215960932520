import {
  GET_STOCK_REQUEST_ITEMS,
  GET_STOCK_REQUEST_ITEMS_SUCCESS,
  GET_STOCK_REQUEST_ITEMS_ERROR,
  GET_STOCK_REQUEST_ITEM_BY_ID,
  GET_STOCK_REQUEST_ITEM_BY_ID_SUCCESS,
  ADD_NEW_STOCK_REQUEST_ITEM,
  ADD_NEW_STOCK_REQUEST_ITEM_SUCCESS,
  UPDATE_STOCK_REQUEST_ITEM,
  UPDATE_STOCK_REQUEST_ITEM_SUCCESS,
  DELETE_STOCK_REQUEST_ITEM,
  DELETE_STOCK_REQUEST_ITEM_SUCCESS,
  STOCK_REQUEST_ITEM_CLEAR_ERROR,
  ADD_NEW_STOCK_REQUEST_ITEM_ERROR, ADD_NEW_BULK_STOCK_REQUEST_ITEM,
} from "./actionTypes"

// StockRequestItem
export const getStockRequestItems = query => ({
  type: GET_STOCK_REQUEST_ITEMS,
  payload: query,
});

export const getStockRequestItemsSuccess = payload => ({
  type: GET_STOCK_REQUEST_ITEMS_SUCCESS,
  payload,
});

export const getStockRequestItemsError = error => ({
  type: GET_STOCK_REQUEST_ITEMS_ERROR,
  payload: error,
});

export const getStockRequestItemById = id => ({
  type: GET_STOCK_REQUEST_ITEM_BY_ID,
  payload: id,
});

export const getStockRequestItemByIdSuccess = payload => ({
  type: GET_STOCK_REQUEST_ITEM_BY_ID_SUCCESS,
  payload,
});

export const addNewStockRequestItem = item => ({
  type: ADD_NEW_STOCK_REQUEST_ITEM,
  payload: item,
});


export const addNewStockRequestItemSuccess = item => ({
  type: ADD_NEW_STOCK_REQUEST_ITEM_SUCCESS,
  payload: item,
});

export const addNewStockRequestItemError = error => ({
  type: ADD_NEW_STOCK_REQUEST_ITEM_ERROR,
  payload: error,
});

export const addNewBulkStockRequestItem = items => ({
  type: ADD_NEW_BULK_STOCK_REQUEST_ITEM,
  payload: items,
});


export const updateStockRequestItem = item => ({
  type: UPDATE_STOCK_REQUEST_ITEM,
  payload: item,
});

export const updateStockRequestItemSuccess = item => ({
  type: UPDATE_STOCK_REQUEST_ITEM_SUCCESS,
  payload: item,
});

export const deleteStockRequestItem = item => ({
  type: DELETE_STOCK_REQUEST_ITEM,
  payload: item,
});

export const deleteStockRequestItemSuccess = item => ({
  type: DELETE_STOCK_REQUEST_ITEM_SUCCESS,
  payload: item,
});

export const clearStockRequestItemError = () => ({
  type: STOCK_REQUEST_ITEM_CLEAR_ERROR,
});
