// PRODUCT_STOCK_IN_PRODUCT
export const GET_PRODUCT_STOCK_IN_PRODUCTS = "GET_PRODUCT_STOCK_IN_PRODUCTS";
export const GET_PRODUCT_STOCK_IN_PRODUCTS_SUCCESS =
  "GET_PRODUCT_STOCK_IN_PRODUCTS_SUCCESS";
export const GET_PRODUCT_STOCK_IN_PRODUCTS_ERROR =
  "GET_PRODUCT_STOCK_IN_PRODUCTS_ERROR";

export const GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID =
  "GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID";
export const GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID_SUCCESS =
  "GET_PRODUCT_STOCK_IN_PRODUCT_BY_ID_SUCCESS";

export const ADD_NEW_PRODUCT_STOCK_IN_PRODUCT =
  "ADD_NEW_PRODUCT_STOCK_IN_PRODUCT";
export const ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_SUCCESS =
  "ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_SUCCESS";
export const ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_ERROR =
  "ADD_NEW_PRODUCT_STOCK_IN_PRODUCT_ERROR";

export const UPDATE_PRODUCT_STOCK_IN_PRODUCT =
  "UPDATE_PRODUCT_STOCK_IN_PRODUCT";
export const UPDATE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS =
  "UPDATE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_STOCK_IN_PRODUCT_ERROR =
  "UPDATE_PRODUCT_STOCK_IN_PRODUCT_ERROR";

export const DELETE_PRODUCT_STOCK_IN_PRODUCT =
  "DELETE_PRODUCT_STOCK_IN_PRODUCT";
export const DELETE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS =
  "DELETE_PRODUCT_STOCK_IN_PRODUCT_SUCCESS";

export const PRODUCT_STOCK_IN_PRODUCT_CLEAR_ERROR =
  "PRODUCT_STOCK_IN_PRODUCT_CLEAR_ERROR";
