import {
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR,
  GET_ITEM_BY_ID,
  GET_ITEM_BY_ID_SUCCESS,
  ADD_NEW_ITEM,
  ADD_NEW_ITEM_SUCCESS,
  UPDATE_ITEM,
  UPDATE_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_SUCCESS,
  ITEM_CLEAR_ERROR,
  ADD_NEW_ITEM_ERROR, GET_PAGING_ITEM_OUT_HISTORY, GET_PAGING_ITEM_OUT_HISTORY_SUCCESS,
} from "./actionTypes"


export const getItems = query => ({
  type: GET_ITEMS,
  payload: query,
});

export const getItemsSuccess = payload => ({
  type: GET_ITEMS_SUCCESS,
  payload,
});

export const getItemsError = error => ({
  type: GET_ITEMS_ERROR,
  payload: error,
});

export const getItemById = id => ({
  type: GET_ITEM_BY_ID,
  payload: id,
});

export const getItemByIdSuccess = payload => ({
  type: GET_ITEM_BY_ID_SUCCESS,
  payload,
});

export const addNewItem = item => ({
  type: ADD_NEW_ITEM,
  payload: item,
});

export const addNewItemSuccess = item => ({
  type: ADD_NEW_ITEM_SUCCESS,
  payload: item,
});

export const addNewItemError = error => ({
  type: ADD_NEW_ITEM_ERROR,
  payload: error,
});

export const updateItem = item => ({
  type: UPDATE_ITEM,
  payload: item,
});

export const updateItemSuccess = item => ({
  type: UPDATE_ITEM_SUCCESS,
  payload: item,
});

export const deleteItem = item => ({
  type: DELETE_ITEM,
  payload: item,
});

export const deleteItemSuccess = item => ({
  type: DELETE_ITEM_SUCCESS,
  payload: item,
});

export const clearItemError = () => ({
  type: ITEM_CLEAR_ERROR,
});

export const getPagingItemOutHistory = query => ({
  type: GET_PAGING_ITEM_OUT_HISTORY,
  payload: query,
});

export const getPagingItemOutHistorySuccess = query => ({
  type: GET_PAGING_ITEM_OUT_HISTORY_SUCCESS,
  payload: query,
});
