import {
  GET_STOCK_REQUESTS,
  GET_STOCK_REQUESTS_SUCCESS,
  GET_STOCK_REQUESTS_ERROR,
  GET_STOCK_REQUEST_BY_ID,
  GET_STOCK_REQUEST_BY_ID_SUCCESS,
  ADD_NEW_STOCK_REQUEST,
  ADD_NEW_STOCK_REQUEST_SUCCESS,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_REQUEST_SUCCESS,
  DELETE_STOCK_REQUEST,
  DELETE_STOCK_REQUEST_SUCCESS,
  STOCK_REQUEST_CLEAR_ERROR,
  ADD_NEW_STOCK_REQUEST_ERROR,
  GET_CONFIRM_STOCK_REQUEST,
  GET_CONFIRM_STOCK_REQUEST_SUCCESS,
  SUBMIT_WITH_STATUS_STOCK_REQUEST,
  SUBMIT_WITH_STATUS_STOCK_REQUEST_SUCCESS,
  SUBMIT_WITH_STATUS_STOCK_REQUEST_ERROR, UPDATE_STOCK_REQUEST_ERROR,
} from "./actionTypes"
// StockRequest
export const getStockRequests = query => ({
  type: GET_STOCK_REQUESTS,
  payload: query,
});

export const getStockRequestsSuccess = payload => ({
  type: GET_STOCK_REQUESTS_SUCCESS,
  payload,
});

export const getStockRequestsError = error => ({
  type: GET_STOCK_REQUESTS_ERROR,
  payload: error,
});

export const getStockRequestById = id => ({
  type: GET_STOCK_REQUEST_BY_ID,
  payload: id,
});

export const getStockRequestByIdSuccess = payload => ({
  type: GET_STOCK_REQUEST_BY_ID_SUCCESS,
  payload,
});

export const addNewStockRequest = item => ({
  type: ADD_NEW_STOCK_REQUEST,
  payload: item,
});

export const addNewStockRequestSuccess = item => ({
  type: ADD_NEW_STOCK_REQUEST_SUCCESS,
  payload: item,
});

export const addNewStockRequestError = error => ({
  type: ADD_NEW_STOCK_REQUEST_ERROR,
  payload: error,
});

export const updateStockRequest = item => ({
  type: UPDATE_STOCK_REQUEST,
  payload: item,
});

export const updateStockRequestSuccess = item => ({
  type: UPDATE_STOCK_REQUEST_SUCCESS,
  payload: item,
});

export const updateStockRequestError = item => ({
  type: UPDATE_STOCK_REQUEST_ERROR,
  payload: item,
});

export const deleteStockRequest = item => ({
  type: DELETE_STOCK_REQUEST,
  payload: item,
});

export const deleteStockRequestSuccess = item => ({
  type: DELETE_STOCK_REQUEST_SUCCESS,
  payload: item,
});

export const clearStockRequestError = () => ({
  type: STOCK_REQUEST_CLEAR_ERROR,
});

export const getConfirmStockRequest = payload => ({
  type: GET_CONFIRM_STOCK_REQUEST,
  payload,
});

export const getConfirmStockRequestSuccess = () => ({
  type: GET_CONFIRM_STOCK_REQUEST_SUCCESS,
});

export const submitWithStatusStockRequest = payload => ({
  type: SUBMIT_WITH_STATUS_STOCK_REQUEST,
  payload,
});

export const submitWithStatusStockRequestSuccess = payload => ({
  type: SUBMIT_WITH_STATUS_STOCK_REQUEST_SUCCESS,
  payload,
});
