import { SEVERITY, toastMessage } from "helpers/utils.js"
import {
  GET_PAGING_LOCATION,
  GET_PAGING_LOCATION_SUCCESS,
  GET_LOCATION_BY_ID_SUCCESS,
  GET_PAGING_LOCATION_ERROR,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const StockIn = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAGING_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGING_LOCATION_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_LOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_PAGING_LOCATION_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Code");
      toastMessage({
        title: "Location",
        text: hasUniqueCode ? "Item is duplicate code." : payload,
        severity: SEVERITY.DANGER,
      });
      
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default StockIn;
