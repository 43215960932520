// ORDER_ITEM
export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS";
export const GET_ORDER_ITEMS_SUCCESS = "GET_ORDER_ITEMS_SUCCESS";
export const GET_ORDER_ITEMS_FAIL = "GET_ORDER_ITEMS_FAIL";

export const GET_ORDER_ITEM_BY_ID = "GET_ORDER_ITEM_BY_ID";
export const GET_ORDER_ITEM_BY_ID_SUCCESS = "GET_ORDER_ITEM_BY_ID_SUCCESS";

export const ADD_NEW_ORDER_ITEM = "ADD_NEW_ORDER_ITEM";

export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM";

export const DELETE_ORDER_ITEM = "DELETE_ORDER_ITEM";

export const ORDER_ITEM_CLEAR_ERROR = "ORDER_ITEM_CLEAR_ERROR";
