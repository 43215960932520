import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
} from "reactstrap";

export class OrderNoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      note: "",
      orderId: null,
      submitted: false,
    };
  }

  onOpened = () => {
    const { item, orderId } = this.props;
    if (item) {
      this.setState({
        ...item,
        orderId,
      });
    } else {
      this.setState({
        id: NIL,
        orderId,
        submitted: false,
        note: "",
      });
    }
  };

  componentDidMount = () => {};

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, note, orderId } = this.state;

    this.setState({
      submitted: true,
    });

    if (note) {
      const data = {
        id,
        orderId,
        note,
      };
      this.props.onSubmit(data);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Note</Label>
                <Input
                  type="textarea"
                  name="note"
                  rows={5}
                  placeholder="Note"
                  className={submitted && !this.state.note ? "is-invalid" : ""}
                  value={this.state.note}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.note && (
                  <div className="invalid-feedback-custom">
                    Note is required.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

OrderNoteForm.propTypes = {
  item: PropTypes.object,
  orderId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default OrderNoteForm;
