import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
  getProductStockOuts,
  addNewProductStockOut,
  updateProductStockOut,
  getProductStockOutById,
  deleteProductStockOut,
  clearProductStockOutError,
  getConfirmProductStockOut,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";
import { debounce } from "lodash";
import ModalForm from "containers/product-stock-out/ModalForm";
import ProductStockOutTable from "containers/product-stock-out/ProductStockOutTable";
import ProductStockOutConfirmModal from "containers/product-stock-out/ProductStockOutConfirmModal";
import { validateProductStockOutRequest } from "../../store/product-stock-out/saga"
import ProductStockOutValidateResultModal from "../../containers/product-stock-out/ProductStockOutValidateResultModal"

const ProductStockOutList = props => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [modalConfirmStock, setModalConfirmStock] = useState(false);
  const [ modalValidateResult, setModalValidateResult ] = useState(false)
  const [ resultValidationData, setResultValidationData ] = useState([])

  const {
    getProductStockOuts,
    addNewProductStockOut,
    deleteProductStockOut,
    updateProductStockOut,
    clearProductStockOutError,
    getProductStockOutById,
    getConfirmProductStockOut,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
    history,
  } = props;

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );

  useEffect(() => {
    getProductStockOuts({ page, pageSize, term });
  }, [page, pageSize, term]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductStockOutError();
    }
  }, [message]);

  const handleUpdate = supplier => {
    updateProductStockOut(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    history.push(`/product-stock-out/add-new`);
  };

  const handleSubmit = item => {
    addNewProductStockOut(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteProductStockOut(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnDetail = id => {
    history.push(`/product-stock-out/${id}`);
  };

  const handleConfirmStock = id => {
    setId(id);
    
    validateProductStockOutRequest(id).then(res => {
      if (res) {
        const { hasInvalid, results } = res

        if (hasInvalid) {
          setModalValidateResult(hasInvalid)
          setResultValidationData(results)
        }else{
          setModalConfirmStock(true);
        }
      }
    })
  };

  const handleSubmitStockConfirmation = () => {
    getConfirmProductStockOut({ productStockOutId: id, type: "List" });
    setModalConfirmStock(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Stock Out | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Product Stock In"
            breadcrumbItem="Product Stock Out List"
          />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e => {
                              debouncedFetchData(e.target.value);
                            }}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <ProductStockOutTable
                  items={items}
                  itemCount={itemCount}
                  page={page}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onDetails={handleOnDetail}
                  onConfirm={handleConfirmStock}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={page => setPage(page)}
                  onPageSizeChange={pageSize => {
                    setPage(1);
                    setPageSize(pageSize);
                  }}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Stock Out"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Stock Out"}
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() =>
                  setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                }
                onSubmit={handleDelete}
              />
              <ProductStockOutConfirmModal
                title="Confirm item into stock"
                isOpen={modalConfirmStock}
                toggle={() => setModalConfirmStock(!modalConfirmStock)}
                onSubmit={handleSubmitStockConfirmation}
              />

              {
                resultValidationData.length > 0 ?
                    <ProductStockOutValidateResultModal
                        title={ "Validate Product Stock Out Result" }
                        isOpen={ modalValidateResult }
                        toggle={ () => setModalValidateResult(!modalValidateResult) }
                        items={ resultValidationData }
                    /> : null
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProductStockOutList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,

  getProductStockOuts: PropTypes.func,
  addNewProductStockOut: PropTypes.func,
  updateProductStockOut: PropTypes.func,
  getProductStockOutById: PropTypes.func,
  deleteProductStockOut: PropTypes.func,
  clearProductStockOutError: PropTypes.func,
  getConfirmProductStockOut: PropTypes.func,
};

const mapStateToProps = ({ productStockOut }) => {
  return productStockOut;
};

export default withRouter(
  connect(mapStateToProps, {
    getProductStockOuts,
    addNewProductStockOut,
    updateProductStockOut,
    getProductStockOutById,
    deleteProductStockOut,
    clearProductStockOutError,
    getConfirmProductStockOut,
  })(ProductStockOutList)
);
