// INVOICE_PAYMENT
export const GET_INVOICE_PAYMENT = "GET_INVOICE_PAYMENT";
export const GET_INVOICE_PAYMENT_SUCCESS = "GET_INVOICE_PAYMENT_SUCCESS";
export const GET_INVOICE_PAYMENT_FAIL = "GET_INVOICE_PAYMENT_FAIL";

export const GET_INVOICE_PAYMENT_BY_ID = "GET_INVOICE_PAYMENT_BY_ID";
export const GET_INVOICE_PAYMENT_BY_ID_SUCCESS =
  "GET_INVOICE_PAYMENT_BY_ID_SUCCESS";

export const ADD_NEW_INVOICE_PAYMENT = "ADD_NEW_INVOICE_PAYMENT";
export const ADD_NEW_INVOICE_PAYMENT_SUCCESS =
  "ADD_NEW_INVOICE_PAYMENT_SUCCESS";

export const UPDATE_INVOICE_PAYMENT = "UPDATE_INVOICE_PAYMENT";
export const UPDATE_INVOICE_PAYMENT_SUCCESS = "UPDATE_INVOICE_PAYMENT_SUCCESS";

export const DELETE_INVOICE_PAYMENT = "DELETE_INVOICE_PAYMENT";
export const DELETE_INVOICE_PAYMENT_SUCCESS = "DELETE_INVOICE_PAYMENT_SUCCESS";

export const INVOICE_PAYMENT_CLEAR_NOTIFY = "INVOICE_PAYMENT_CLEAR_NOTIFY";
