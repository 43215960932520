// STOCK_ADJUSTMENT
export const GET_STOCK_ADJUSTMENTS = "GET_STOCK_ADJUSTMENTS";
export const GET_STOCK_ADJUSTMENTS_SUCCESS = "GET_STOCK_ADJUSTMENTS_SUCCESS";
export const GET_STOCK_ADJUSTMENTS_ERROR = "GET_STOCK_ADJUSTMENTS_ERROR";

export const GET_STOCK_ADJUSTMENT_BY_ID = "GET_STOCK_ADJUSTMENT_BY_ID";
export const GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS =
  "GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_ADJUSTMENT = "ADD_NEW_STOCK_ADJUSTMENT";
export const ADD_NEW_STOCK_ADJUSTMENT_SUCCESS =
  "ADD_NEW_STOCK_ADJUSTMENT_SUCCESS";
export const ADD_NEW_STOCK_ADJUSTMENT_ERROR = "ADD_NEW_STOCK_ADJUSTMENT_ERROR";

export const UPDATE_STOCK_ADJUSTMENT = "UPDATE_STOCK_ADJUSTMENT";
export const UPDATE_STOCK_ADJUSTMENT_SUCCESS =
  "UPDATE_STOCK_ADJUSTMENT_SUCCESS";
export const UPDATE_STOCK_ADJUSTMENT_ERROR = "UPDATE_STOCK_ADJUSTMENT_ERROR";

export const DELETE_STOCK_ADJUSTMENT = "DELETE_STOCK_ADJUSTMENT";
export const DELETE_STOCK_ADJUSTMENT_SUCCESS =
  "DELETE_STOCK_ADJUSTMENT_SUCCESS";

export const GET_CONFIRM_STOCK_ADJUSTMENT = "GET_CONFIRM_STOCK_ADJUSTMENT";
export const GET_CONFIRM_STOCK_ADJUSTMENT_SUCCESS =
  "GET_CONFIRM_STOCK_ADJUSTMENT_SUCCESS";

export const STOCK_ADJUSTMENT_CLEAR_ERROR = "STOCK_ADJUSTMENT_CLEAR_ERROR";
