import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { hasAccess } from "helpers/utils"
import { permission } from "constants/permission"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for ( let i = 0; i < items.length; ++i ) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                  currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
        <React.Fragment>
          <SimpleBar className="h-100" ref={ this.refDiv }>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{ this.props.t("Menu") }</li>
                <li>
                  <Link to="/#">
                    <i className="bx bx-home-circle"/>
                    <span>{ this.props.t("Dashboards") }</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/dashboard">{ this.props.t("Default") }</Link>
                    </li>
                  </ul>
                </li>
                { hasAccess(permission.purchaseOrder.enable) && (
                    <li>
                      <Link to="/purchase-order">
                        <i className="fas fa-shopping-cart"/>
                        <span>{ this.props.t("Purchase Order") }</span>
                      </Link>
                    </li>
                ) }
                { (hasAccess(permission.cuttingStockRequest.enable) ||
                    hasAccess(permission.cutting.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-boxes"/>
                        <span>Cutting</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.cuttingStockRequest.enable) && (
                            <li>
                              <Link to="/stock-request/cutting">Stock Request</Link>
                            </li>
                        ) }
                        { hasAccess(permission.cutting.enable) && (
                            <li>
                              <Link to="/cutting">Purchase Order</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }

                { (hasAccess(permission.sewingStockRequest.enable) ||
                    hasAccess(permission.sewing.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-boxes"/>
                        <span>Sewing</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.sewingStockRequest.enable) && (
                            <li>
                              <Link to="/stock-request/sewing">Stock Request</Link>
                            </li>
                        ) }
                        { hasAccess(permission.sewing.enable) && (
                            <li>
                              <Link to="/sewing">Purchase Order</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { (hasAccess(permission.packagingStockRequest.enable) ||
                    hasAccess(permission.packaging.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-boxes"/>
                        <span>Packaging</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.packagingStockRequest.enable) && (
                            <li>
                              <Link to="/stock-request/packaging">Stock Request</Link>
                            </li>
                        ) }
                        { hasAccess(permission.packaging.enable) && (
                            <li>
                              <Link to="/packaging">Purchase Order</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { hasAccess(permission.finishedGoods.enable) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-boxes"/>
                        <span>Finished Goods</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.finishedGoods.enable) && (
                            <li>
                              <Link to="/finished-goods">Purchase Order</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { hasAccess(permission.stockRequest.enable) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-boxes"/>
                        <span>Stock Management</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.stockRequest.enable) && (
                            <li>
                              <Link to="/stock-request">Stock Request</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { (hasAccess(permission.item.enable) ||
                    hasAccess(permission.product.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-boxes"/>
                        <span>Inventory</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.item.enable) && (
                            <li>
                              <Link to="/item">Accessories</Link>
                            </li>
                        ) }
                        { hasAccess(permission.product.enable) && (
                            <li>
                              <Link to="/product">Finish Goods</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }

                { (hasAccess(permission.stockIn.enable) ||
                    hasAccess(permission.stockOut.enable) ||
                    hasAccess(permission.stockAdjustment.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-warehouse"/>
                        <span>Accessories Stock</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.stockIn.enable) && (
                            <li>
                              <Link to="/stock-in">Stock-In</Link>
                            </li>
                        ) }
                        { hasAccess(permission.stockOut.enable) && (
                            <li>
                              <Link to="/stock-Out">Stock-Out</Link>
                            </li>
                        ) }
                        { hasAccess(permission.stockAdjustment.enable) && (
                            <li>
                              <Link to="/stock-adjustment">Stock Adjustment</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { (hasAccess(permission.productStockIn.enable) ||
                    hasAccess(permission.productStockOut.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-warehouse"/>
                        <span>Finish Goods Stock</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.productStockIn.enable) && (
                            <li>
                              <Link to="/product-stock-in">Stock-In</Link>
                            </li>
                        ) }
                        { hasAccess(permission.productStockOut.enable) && (
                            <li>
                              <Link to="/product-stock-out">Stock-Out</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { (hasAccess(permission.unitOfMeasurement.enable) ||
                    hasAccess(permission.transferLocation.enable) ||
                    hasAccess(permission.codeColor.enable) ||
                    hasAccess(permission.location.enable) ||
                    hasAccess(permission.category.enable) ||
                    hasAccess(permission.clothingSize.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-users-cog"/>
                        <span>{ this.props.t("Configuration") }</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.unitOfMeasurement.enable) && (
                            <li>
                              <Link to="/unit-of-measurement">
                                { this.props.t("Unit Of Measurement") }
                              </Link>
                            </li>
                        ) }
                        { hasAccess(permission.clothingSize.enable) && (
                            <li>
                              <Link to="/clothing-size">
                                { this.props.t("Clothing Size") }
                              </Link>
                            </li>
                        ) }
                        { hasAccess(permission.transferLocation.enable) && (
                            <li>
                              <Link to="/transfer-location">
                                { this.props.t("Transfer Location") }
                              </Link>
                            </li>
                        ) }
                        { hasAccess(permission.codeColor.enable) && (
                            <li>
                              <Link to="/code-color">
                                { this.props.t("Code Color") }
                              </Link>
                            </li>
                        ) }

                        { hasAccess(permission.location.enable) && (
                            <li>
                              <Link to="/location">
                                { this.props.t("Location") }
                              </Link>
                            </li>
                        ) }

                        { hasAccess(permission.category.enable) && (
                            <li>
                              <Link to="/category">
                                { this.props.t("Category") }
                              </Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                { (hasAccess(permission.user.enable) ||
                    hasAccess(permission.role.enable) ||
                    hasAccess(permission.permissionEntry.enable)) && (
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-users-cog"/>
                        <span>{ this.props.t("User Management") }</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        { hasAccess(permission.user.enable) && (
                            <li>
                              <Link to="/user">{ this.props.t("User") }</Link>
                            </li>
                        ) }
                        { hasAccess(permission.role.enable) && (
                            <li>
                              <Link to="/role">{ this.props.t("Role") }</Link>
                            </li>
                        ) }
                        { hasAccess(permission.permissionEntry.enable) && (
                            <li>
                              <Link to="/permission">{ this.props.t("Permission") }</Link>
                            </li>
                        ) }
                      </ul>
                    </li>
                ) }
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="far fa-chart-bar"/>
                    <span>{ this.props.t("Reports") }</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/item-stock-in-report">
                        { this.props.t("Accessory Stock In") }
                      </Link>
                    </li>
                    <li>
                      <Link to="/item-stock-out-report">
                        { this.props.t("Accessory Stock Out") }
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-stock-out-report">
                        { this.props.t("Finished Goods Stock Out") }
                      </Link>
                    </li>
                    <li>
                      <Link to="/purchase-order-summary-report">
                        { this.props.t("Purchase order Summary") }
                      </Link>
                    </li>

                    <li>
                      <Link to="/sewing-outstanding-report">
                        { this.props.t("Sewing Outstanding") }
                      </Link>
                    </li>
                  </ul>
                </li>

              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
