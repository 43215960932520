export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";

export const REGISTER_USER_PORTAL = "REGISTER_USER_PORTAL";
export const REGISTER_USER_PORTAL_SUCCESS = "REGISTER_USER_PORTAL_SUCCESS";
export const REGISTER_USER_PORTAL_ERROR = "REGISTER_USER_PORTAL_ERROR";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";

export const UPDATE_BY_USER = "UPDATE_BY_USER";
export const UPDATE_BY_USER_SUCCESS = "UPDATE_BY_USER_SUCCESS";

export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_USER_BY_ID_SUCCESS = "DELETE_USER_BY_ID_SUCCESS";
export const DELETE_USER_BY_ID_ERROR = "DELETE_USER_BY_ID_ERROR";

export const USER_CLEAR_NOTIFY = "USER_CLEAR_NOTIFY";
