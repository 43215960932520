import React, { Component } from "react";
import UserForm from "containers/user/UserForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUserProfile, clearUserNotify } from "store/actions";
import { toastMessage } from "helpers/utils";

export class UserInfoTab extends Component {
  constructor(props) {
    super(props);

    this.state = { id: null };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.setState({
        id,
      });
    }
  }

  componentDidUpdate() {
    const { message, clearUserNotify } = this.props;

    if (message) {
      toastMessage(message);
      clearUserNotify();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { id } = props;
    if (id !== state.id) {
      return id;
    }

    return null;
  }

  handleSubmit = item => {
    this.props.updateUserProfile(item);
  };

  render() {
    const { item, loading } = this.props;
    return (
      <>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>{item && <UserForm item={item} onSubmit={this.handleSubmit} />}</>
        )}
      </>
    );
  }
}

UserInfoTab.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object,

  loading: PropTypes.bool,
  item: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getUserById: PropTypes.func,
  updateUserProfile: PropTypes.func,
  clearUserNotify: PropTypes.func,
};

const mapStateToProps = ({ user }) => {
  return user;
};

export default withRouter(
  connect(mapStateToProps, { updateUserProfile, clearUserNotify })(UserInfoTab)
);
