import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import Gallery from "react-fine-uploader"
import FineUploaderTraditional from "fine-uploader-wrappers"
import { NIL } from "uuid"

const uploader = new FineUploaderTraditional({
  options: {
    multiple: false,
    request: {
      method: "POST",
      endpoint: `${ process.env.REACT_APP_API_URL }/api/FileUpload/purchaseOrderAttachment`,
    },
    validation: {
      sizeLimit: 104857600, // 100MB
    },
  },
})

const AttachmentUploadModalForm = props => {
  const { isOpen, toggle, title, purchaseOrderId, onSubmit } = props
  const [ loading, setLoading ] = useState(false)
  const [ submitted, setSubmitted ] = useState(false)
  const [ item, setItem ] = useState({
    id: NIL,
    purchaseOrderId,
    title: '',
    fileName: '',
    url: '',
  })

  useEffect(() => {
    uploader.on("submitted", id => {
      // this.setState({
      //   uploading: true,
      // });
    })

    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        const attachment = {
          fileName: responseJSON.fileName,
          url: responseJSON.url,
        }

        setItem({
          ...item,
          fileName: responseJSON.fileName,
          url: responseJSON.url
        })

        // this.setState({
        //   attachments: this.state.attachments.concat(attachment),
        // });
      } else {
        // this.setState({
        //   fail: true,
        //   error: responseJSON.message,
        // });
      }
    })

    uploader.on("error", (id, name, errorReason) => {
      console.log(errorReason, id, name)
      // this.setState({
      //   fail: true,
      //   error: errorReason,
      // });
    })
  }, [])

  const handleSubmit = () => {
    setSubmitted(true)

    if (item.title) {
      onSubmit(item)
    }
  }

  const onOpened = () => {
    const { item } = props;
    setSubmitted(false);
    
    if(item){
      setItem({
        ...item
      })
    }else{
      setItem({
        id: NIL,
        purchaseOrderId,
        title: '',
        fileName: '',
        url: '',
      })  
    }
    
  }

  return (
      <Modal
          size="md"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={ 12 }>
              <FormGroup className="mb-3">
                <Label htmlFor="">Title</Label>
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    autoComplete="off"
                    className={ submitted && !item.title ? "is-invalid" : "" }
                    value={ item.title }
                    onChange={ e => {
                      const { value } = e.target
                      setItem({
                        ...item,
                        title: value
                      })
                    }
                    }
                />
                { submitted && !item.title && (
                    <div className="invalid-feedback-custom">Title is required.</div>
                ) }
              </FormGroup>
              <FormGroup>
                <Gallery uploader={ uploader }/>
                <div  className={ submitted && !item.fileName ? "is-invalid" : "" }>
                </div>
                { submitted && !item.fileName && (
                    <div className="invalid-feedback-custom">Upload is required.</div>
                ) }
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

AttachmentUploadModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.object,
  purchaseOrderId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default AttachmentUploadModalForm