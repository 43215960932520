import { SEVERITY } from "helpers/utils.js";
import {
  GET_STOCK_ADJUSTMENTS,
  GET_STOCK_ADJUSTMENTS_SUCCESS,
  GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS,
  GET_STOCK_ADJUSTMENTS_ERROR,
  STOCK_ADJUSTMENT_CLEAR_ERROR,
  GET_CONFIRM_STOCK_ADJUSTMENT,
  GET_CONFIRM_STOCK_ADJUSTMENT_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const StockIn = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIRM_STOCK_ADJUSTMENT:
    case GET_STOCK_ADJUSTMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_CONFIRM_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
        message: {
          title: "Stock Adjusment ",
          text: "Stock Adjusment has been confirmed successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };
    case GET_STOCK_ADJUSTMENTS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_STOCK_ADJUSTMENT_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_STOCK_ADJUSTMENTS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Code");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Item is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case STOCK_ADJUSTMENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default StockIn;
