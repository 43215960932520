import React from 'react'
import PropTypes from 'prop-types'
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StockAdjustmentForm from "../../containers/stock-adjustment/StockAdjustmentForm"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { addNewStockAdjustment} from "../../store/stock-adjustment/actions"

const AddNewStockAdjustment = props => {
  const { addNewStockAdjustment, history } = props

  const handleOnSubmit = (data) => {
    addNewStockAdjustment(data)
    history.push(`/stock-adjustment`)
  }

  const handleOnCancel = () => {
    history.push(`/stock-adjustment`);
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock Adjustment | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ `/stock-adjustment` }
                breadcrumbItem={ `Add New Stock Adjustment` }
            />
            <Row>
              <Col md={ 12 }>
                <StockAdjustmentForm onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

AddNewStockAdjustment.propTypes = {
  addNewStockAdjustment: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = ({ stockAdjustment }) => {
  return stockAdjustment
}

export default withRouter(
    connect(mapStateToProps, {
      addNewStockAdjustment,
    })(AddNewStockAdjustment)
)
