import React, { useEffect, useState } from "react";
import { Alert, Button, Modal } from "reactstrap";
import PropTypes from "prop-types";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

const UploadModalForm = ({ isOpen, toggle, title, onSubmit }) => {
  const [error, setError] = useState(null);
  
  const uploader = new FineUploaderTraditional({
    options: {
      multiple: false,
      request: {
        method: "POST",
        endpoint: `${process.env.REACT_APP_API_URL}/api/Item/importFromExcel`,
      },
      validation: {
        sizeLimit: 104857600, // 100MB
        allowedExtensions: ["xlsx"],
      },

      autoUpload: false,
      callbacks: {
        onSubmitted: function (id, name) {
          console.log(id);
        },
        onComplete: (id, name, responseJSON, xhr) => {
          if (responseJSON.success) {
            console.log(name);
          }
        },
        onError: (id, name, errorReason, xhr) => {
          setError(errorReason);
        },
      },
    },
  });

  const onOpened = () => {
    setError(null);
  };

  const handleSubmit = () => {
    uploader.methods.uploadStoredFiles();
  };

  return (
    <Modal
      size="md"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {error ? <Alert color="danger">{error}</Alert> : null}
      <div className="modal-body">
        <Gallery uploader={uploader} />
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit} type="submit">
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

UploadModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default UploadModalForm;
