import { call, put, takeEvery } from "redux-saga/effects";
import FileSaver from "file-saver";
import {
  ADD_NEW_SEWING,
  GET_SEWING_BY_ID,
  UPDATE_SEWING,
  DELETE_SEWING,
  GET_SEWINGS, GET_SEWING_ENTRY_FORM, SAVE_SEWING_ENTRY_FORM, GET_PAGING_SEWING_OUTSTANDING_REPORT,
} from "./actionTypes"
import { get, post, del, put as update, postFile, getFile } from "../../helpers/api_helper"
import {
  getPagingSewingOutstandingReportSuccess,
  getSewingByIdSuccess, 
  getSewingEntryFormSuccess,
  getSewingsError,
  getSewingsSuccess,
} from "./actions"

const getSewingsRequest = query => get("/api/Sewing/paging", { params: query });
const addNewSewingRequest = item => post("/api/Sewing", item);
const getSewingByIdRequest = id => get(`/api/Sewing/${id}`);
const updateSewingRequest = item => update("/api/Sewing", item);
const deleteSewingRequest = id => del(`/api/Sewing/${id}`);
const getPagingSewingOutstandingReportRequest = query => get("/api/Sewing/pagingSewingOutstandingReport", { params: query });
const getSewingEntryFormRequest = id => get(`/api/Sewing/entryForm/${id}`);
const saveSewingEntryFormRequest = item => post("/api/Sewing/entryForm", item);
const validateSewingEntryFormRequest = item => post("/api/Sewing/validateEntryForm", item);
const validateSewingRequest = item => post("/api/Sewing/validateEntry", item);

const exportSewingOutstanding = async (query, fileName) => {
  return await getFile(`/api/Sewing/sewingOutstandingReportExportToExcel`, {
    params: query,
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* getSewings({ payload }) {
  try {
    const response = yield call(getSewingsRequest, payload);
    yield put(getSewingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* addNewSewing({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(addNewSewingRequest, payload);
    const response = yield call(getSewingsRequest, {
      purchaseOrderId,
    });
    yield put(getSewingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* updateSewing({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(updateSewingRequest, payload);
    const response = yield call(getSewingsRequest, {
      purchaseOrderId,
    });
    yield put(getSewingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* getSewingById({ payload }) {
  try {
    var response = yield call(getSewingByIdRequest, payload);
    yield put(getSewingByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* deleteSewing({ payload }) {
  try {
    const { purchaseOrderId, id } = payload;
    yield call(deleteSewingRequest, id);
    const response = yield call(getSewingsRequest, {
      purchaseOrderId,
    });
    yield put(getSewingsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* getSewingEntryForm({ payload }) {
  try {
    const response = yield call(getSewingEntryFormRequest, payload);
    yield put(getSewingEntryFormSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* saveSewingEntryForm({ payload }) {
  try {
    const { purchaseOrderId } = payload;
    yield call(saveSewingEntryFormRequest, payload);
    const response = yield call(getSewingsRequest, {
      purchaseOrderId,
    });
    yield put(getSewingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* getPagingSewingOutstandingReport({ payload }) {
  try {
    const response = yield call(getPagingSewingOutstandingReportRequest, payload);
    yield put(getPagingSewingOutstandingReportSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSewingsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_SEWINGS, getSewings);
  yield takeEvery(ADD_NEW_SEWING, addNewSewing);
  yield takeEvery(GET_SEWING_BY_ID, getSewingById);
  yield takeEvery(UPDATE_SEWING, updateSewing);
  yield takeEvery(DELETE_SEWING, deleteSewing);
  yield takeEvery(GET_SEWING_ENTRY_FORM, getSewingEntryForm);
  yield takeEvery(SAVE_SEWING_ENTRY_FORM, saveSewingEntryForm);
  yield takeEvery(GET_PAGING_SEWING_OUTSTANDING_REPORT, getPagingSewingOutstandingReport);
}

export default itemSaga;

export {
  validateSewingEntryFormRequest,
  validateSewingRequest,
  exportSewingOutstanding
}
