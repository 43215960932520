import CustomPagination from "components/Common/CustomPagination";
import { displayNotificationStatus } from "helpers/utils";
import moment from "moment";

import PropTypes from "prop-types";
import React, { Component } from "react";

import { Card, CardBody, Table } from "reactstrap";

export class DeviceInfoNotificationHistoryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onChangePage,
      onPageSizeChange,
    } = this.props;

    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="table-responsive" data-pattern="priority-columns">
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap align-middle"
                >
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Title</th>
                      <th>Body</th>
                      <th>Status</th>
                      <th>Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);
                      return (
                        <tr key={index}>
                          <td className="text-end">{num}</td>
                          <td>{item.title}</td>
                          <td>{item.body}</td>
                          <td>{displayNotificationStatus(item)}</td>
                          <td>
                            {moment
                              .utc(item.createdDate)
                              .local()
                              .format("DD-MMM-yyyy h:mm:ss a")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

DeviceInfoNotificationHistoryTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  showUserInfo: PropTypes.bool,
  loading: PropTypes.bool,

  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default DeviceInfoNotificationHistoryTable;
