import { SEVERITY } from "helpers/utils.js";
import {
  GET_ORDER_NOTE,
  GET_ORDER_NOTE_SUCCESS,
  GET_ORDER_NOTE_FAIL,
  GET_ORDER_NOTE_BY_ID_SUCCESS,
  ADD_NEW_ORDER_NOTE,
  ADD_NEW_ORDER_NOTE_SUCCESS,
  UPDATE_ORDER_NOTE,
  UPDATE_ORDER_NOTE_SUCCESS,
  DELETE_ORDER_NOTE,
  DELETE_ORDER_NOTE_SUCCESS,
  ORDER_NOTE_CLEAR_NOTIFY
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const OrderNote = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_ORDER_NOTE:
    case UPDATE_ORDER_NOTE:
    case DELETE_ORDER_NOTE:
    case GET_ORDER_NOTE:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_NOTE_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
      
    case GET_ORDER_NOTE_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case GET_ORDER_NOTE_FAIL:
      return {
        ...state,
        error: payload,
      };

    case ADD_NEW_ORDER_NOTE_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case UPDATE_ORDER_NOTE_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_ORDER_NOTE_SUCCESS:
      return {
        ...state,
        message: {
          title: "Order Note",
          text: "Order Note has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case ORDER_NOTE_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default OrderNote;
