import {
  GET_STOCK_IN_ITEMS,
  GET_STOCK_IN_ITEMS_SUCCESS,
  GET_STOCK_IN_ITEMS_ERROR,
  GET_STOCK_IN_ITEM_BY_ID,
  GET_STOCK_IN_ITEM_BY_ID_SUCCESS,
  ADD_NEW_STOCK_IN_ITEM,
  ADD_NEW_STOCK_IN_ITEM_SUCCESS,
  UPDATE_STOCK_IN_ITEM,
  UPDATE_STOCK_IN_ITEM_SUCCESS,
  DELETE_STOCK_IN_ITEM,
  DELETE_STOCK_IN_ITEM_SUCCESS,
  STOCK_IN_ITEM_CLEAR_ERROR,
  ADD_NEW_STOCK_IN_ITEM_ERROR,
} from "./actionTypes";

// StockInItem
export const getStockInItems = query => ({
  type: GET_STOCK_IN_ITEMS,
  payload: query,
});

export const getStockInItemsSuccess = payload => ({
  type: GET_STOCK_IN_ITEMS_SUCCESS,
  payload,
});

export const getStockInItemsError = error => ({
  type: GET_STOCK_IN_ITEMS_ERROR,
  payload: error,
});

export const getStockInItemById = id => ({
  type: GET_STOCK_IN_ITEM_BY_ID,
  payload: id,
});

export const getStockInItemByIdSuccess = payload => ({
  type: GET_STOCK_IN_ITEM_BY_ID_SUCCESS,
  payload,
});

export const addNewStockInItem = item => ({
  type: ADD_NEW_STOCK_IN_ITEM,
  payload: item,
});

export const addNewStockInItemSuccess = item => ({
  type: ADD_NEW_STOCK_IN_ITEM_SUCCESS,
  payload: item,
});

export const addNewStockInItemError = error => ({
  type: ADD_NEW_STOCK_IN_ITEM_ERROR,
  payload: error,
});

export const updateStockInItem = item => ({
  type: UPDATE_STOCK_IN_ITEM,
  payload: item,
});

export const updateStockInItemSuccess = item => ({
  type: UPDATE_STOCK_IN_ITEM_SUCCESS,
  payload: item,
});

export const deleteStockInItem = item => ({
  type: DELETE_STOCK_IN_ITEM,
  payload: item,
});

export const deleteStockInItemSuccess = item => ({
  type: DELETE_STOCK_IN_ITEM_SUCCESS,
  payload: item,
});

export const clearStockInItemError = () => ({
  type: STOCK_IN_ITEM_CLEAR_ERROR,
});
