// STOCK_OUT
export const GET_STOCK_OUTS = "GET_STOCK_OUTS";
export const GET_STOCK_OUTS_SUCCESS = "GET_STOCK_OUTS_SUCCESS";
export const GET_STOCK_OUTS_ERROR = "GET_STOCK_OUTS_ERROR";

export const GET_STOCK_OUT_BY_ID = "GET_STOCK_OUT_BY_ID";
export const GET_STOCK_OUT_BY_ID_SUCCESS = "GET_STOCK_OUT_BY_ID_SUCCESS";

export const ADD_NEW_STOCK_OUT = "ADD_NEW_STOCK_OUT";
export const ADD_NEW_STOCK_OUT_SUCCESS = "ADD_NEW_STOCK_OUT_SUCCESS";
export const ADD_NEW_STOCK_OUT_ERROR = "ADD_NEW_STOCK_OUT_ERROR";

export const UPDATE_STOCK_OUT = "UPDATE_STOCK_OUT";
export const UPDATE_STOCK_OUT_SUCCESS = "UPDATE_STOCK_OUT_SUCCESS";
export const UPDATE_STOCK_OUT_ERROR = "UPDATE_STOCK_OUT_ERROR";

export const DELETE_STOCK_OUT = "DELETE_STOCK_OUT";
export const DELETE_STOCK_OUT_SUCCESS = "DELETE_STOCK_OUT_SUCCESS";

export const GET_CONFIRM_STOCK_OUT = "GET_CONFIRM_STOCK_OUT";
export const GET_CONFIRM_STOCK_OUT_SUCCESS = "GET_CONFIRM_STOCK_OUT_SUCCESS";

export const STOCK_OUT_CLEAR_ERROR = "STOCK_OUT_CLEAR_ERROR";
