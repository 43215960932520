// CLOTHING_SIZE
export const GET_CLOTHING_SIZES = "GET_CLOTHING_SIZES";
export const GET_CLOTHING_SIZES_SUCCESS = "GET_CLOTHING_SIZES_SUCCESS";
export const GET_CLOTHING_SIZES_ERROR = "GET_CLOTHING_SIZES_ERROR";

export const GET_CLOTHING_SIZE_BY_ID = "GET_CLOTHING_SIZE_BY_ID";
export const GET_CLOTHING_SIZE_BY_ID_SUCCESS =
  "GET_CLOTHING_SIZE_BY_ID_SUCCESS";

export const ADD_NEW_CLOTHING_SIZE = "ADD_NEW_CLOTHING_SIZE";
export const ADD_NEW_CLOTHING_SIZE_SUCCESS = "ADD_NEW_CLOTHING_SIZE_SUCCESS";
export const ADD_NEW_CLOTHING_SIZE_ERROR = "ADD_NEW_CLOTHING_SIZE_ERROR";

export const UPDATE_CLOTHING_SIZE = "UPDATE_CLOTHING_SIZE";
export const UPDATE_CLOTHING_SIZE_SUCCESS = "UPDATE_CLOTHING_SIZE_SUCCESS";
export const UPDATE_CLOTHING_SIZE_ERROR = "UPDATE_CLOTHING_SIZE_ERROR";

export const DELETE_CLOTHING_SIZE = "DELETE_CLOTHING_SIZE";
export const DELETE_CLOTHING_SIZE_SUCCESS = "DELETE_CLOTHING_SIZE_SUCCESS";

export const CLOTHING_SIZE_CLEAR_ERROR = "CLOTHING_SIZE_CLEAR_ERROR";
