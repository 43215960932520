import CustomPagination from "components/Common/CustomPagination"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  getPackagings,
  getFinishedGoodss,
  saveFinishedGoodsEntryForm,
  getFinishedGoodsById,
  updateFinishedGoods,
  deleteFinishedGoods,
  clearFinishedGoodsError,
  clearPurchaseOrderError,
  generatePurchaseOrderProductStockIn,
  changeStatusFinishedGoods
} from "store/actions"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap"
import ModalForm from "containers/finished-goods/ModalForm"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { toastMessage } from "helpers/utils"
import { debounce, sumBy } from "lodash"
import moment from "moment"
import CuttingSummaryCard from "../../containers/cutting/CuttingSummaryCard"
import ConfirmationModal from "../../components/Common/ConfirmationModal"
import FinishedGoodsModalForm from "../../containers/finished-goods/FinishedGoodsModalForm"

// FinishedGoods
const FinishedGoodsItemTab = props => {
  const [ selectedId, setSelectedId ] = useState(null)
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(100)
  const [ term, setTerm ] = useState("")

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false)
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ modalConfirmGenerate, setModalConfirmGenerate ] = useState(false)

  const disableStatuses = [ 0, 2, 3 ]

  const {
    getFinishedGoodss,
    getPackagings,
    saveFinishedGoodsEntryForm,
    getFinishedGoodsById,
    updateFinishedGoods,
    deleteFinishedGoods,
    clearFinishedGoodsError,
    generatePurchaseOrderProductStockIn,
    changeStatusFinishedGoods,
    purchaseOrderId,
    finishedGoods,
    packaging,
    purchaseOrder,
  } = props

  const {
    items,
    clothingSizes,
    purchaseOrderItems,
    item,
    message,
    itemCount,
    pageCount,
    loading,
    summary
  } = finishedGoods

  const {
    generating
  } = purchaseOrder

  const {summary: packagingSummary} = packaging;

  useEffect(() => {
    getPackagings({purchaseOrderId})
  }, [])

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  useEffect(() => {
    getFinishedGoodss({ purchaseOrderId, term, page, pageSize })
  }, [ page, pageSize, term ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearFinishedGoodsError()
    }
  }, [ message ])

  useEffect(() => {
    if (purchaseOrder.message) {
      toastMessage(purchaseOrder.message)
      clearPurchaseOrderError()
    }
  }, [ purchaseOrder.message ])


  const handleSubmit = items => {
    saveFinishedGoodsEntryForm({
      data: {
        purchaseOrderId,
        items,
      },
      queryParam: {
        purchaseOrderId,
        pageSize
      }
    })
    setModalAddIsOpen(false)
  }

  const handleUpdate = data => {
    updateFinishedGoods({
      data,
      queryParam: {
        purchaseOrderId,
        pageSize
      }
    })
    setModalEditIsOpen(false)
  }

  const handleDelete = () => {
    deleteFinishedGoods({
      data: selectedId,
      queryParam: {
        purchaseOrderId,
        pageSize
      }
    })
    setModalConfirmDeleteIsOpen(false)
  }

  const handleGenerateStock = () => {
    
    generatePurchaseOrderProductStockIn({
      data: purchaseOrderId,
      queryParam: {
        purchaseOrderId,
        pageSize
      }
    })
    setModalConfirmGenerate(false)
  }
  
  const handleOnChangeStatus = (item)=>{
    changeStatusFinishedGoods({
      data: item.id,
      queryParam: {
        purchaseOrderId,
        pageSize  
      }
    })
  }

  const displayStatus = item => {
    let color = ""
    const { status, statusName } = item
    switch (status) {
      case 0:
        color = "info"
        break
      case 1:
        color = "warning"
        break
      case 2:
        color = "primary"
        break
      case 3:
        color = "success"
        break
    }
    return (
        <>
          {
            [ 0, 1 ].includes(status) ?
                <Badge color={ color } onClick={ () => handleOnChangeStatus(item) }
                       className="px-2 py-2 btn-xs btn-outline clickable">
                  <span>{ statusName }</span>
                </Badge> :
                <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
                  <span>{ statusName }</span>
                </Badge>
          }

        </>
    )
  }

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target
                          debouncedQuickSearch(value)
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>

                  <Button
                      type="button"
                      color="primary"
                      disabled={ !items.some(e => e.status === 1) }
                      onClick={ () => {
                        setModalConfirmGenerate(true)
                        // 
                      } }
                  >
                    <span className={ "me-1" }><i
                        className={ `fas fa-sync` + (generating ? ' bx-spin' : '') }/></span> Generate Stock In
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        { loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
        ) : (
            <>
              {
                  packagingSummary &&
                  <CuttingSummaryCard title={"Packaging Summary"} summary={ packagingSummary }/>
              }
              {
                  summary &&
                  <CuttingSummaryCard title={"Finished Goods Summary"} summary={ summary }/>
              }
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <CardTitle>Item Entries</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                    >
                      <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered table-nowrap"
                      >
                        <thead>
                        <tr>
                          <th style={ { width: "80px" } }>#</th>
                          <th className="text-center">Item Code</th>
                          <th className="text-center">Size</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Created Date</th>
                          <th className="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        { items.map((item, index) => {
                          const { sewingEntries } = item
                          let num = (page - 1) * pageSize + (index + 1)

                          let total = sumBy(sewingEntries, sewingEntry => {
                            return sewingEntry.quantity
                          })

                          return (
                              <tr key={ index }>
                                <td style={ { textAlign: "right" } }>{ num }</td>
                                <td>
                                  { item.itemCode } - { item.itemName }
                                </td>
                                <td>{ item.clothingSizeName }</td>
                                <td className="text-end">{ item.quantity }</td>
                                <td className="text-end">{ displayStatus(item) }</td>
                                <td className="text-end">
                                  { moment
                                      .utc(item.createdDate)
                                      .local()
                                      .format("DD-MMM-yyyy h:mm:ss a") }
                                </td>

                                <td>
                                  {
                                      [ 0, 1, 2 ].includes(item.status) && // Draft, Pending and Generated
                                      <div className="d-flex justify-content-center">
                                        <div className="d-flex gap-3">
                                          <Link className="text-primary" to="#">
                                            <i
                                                className="mdi mdi-pencil font-size-18"
                                                id="edittooltip"
                                                onClick={ () => {
                                                  getFinishedGoodsById(item.id)
                                                  setModalEditIsOpen(true)
                                                } }
                                            ></i>
                                          </Link>

                                          <Link className="text-danger" to="#">
                                            <i
                                                className="mdi mdi-delete font-size-18"
                                                id="deletetooltip"
                                                onClick={ () => {
                                                  setSelectedId(item.id)
                                                  setModalConfirmDeleteIsOpen(true)
                                                } }
                                            ></i>
                                          </Link>
                                        </div>
                                      </div>
                                  }
                                </td>
                              </tr>
                          )
                        }) }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <CustomPagination
                      itemCount={ itemCount }
                      currentPage={ page }
                      totalPage={ pageCount }
                      defaultPageSize={ pageSize }
                      pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                      onChangePage={ i => setPage(i) }
                      onPageSizeChange={ size => {
                        setPage(1)
                        setPageSize(size)
                      } }
                  />
                </CardBody>
              </Card>
            </>
        ) }

        <FinishedGoodsModalForm
            title="Add Finished Goods Item Entries"
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            clothingSizes={ clothingSizes }
            purchaseOrderItems={ purchaseOrderItems }
            purchaseOrderId={ purchaseOrderId }
            onSubmit={ handleSubmit }
        />

        { item ? (
            <ModalForm
                title="Edit Item"
                purchaseOrderId={ purchaseOrderId }
                item={ item }
                isOpen={ modalEditIsOpen }
                clothingSizes={ clothingSizes }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />

        <ConfirmationModal
            title="Confirmation To Generate Product Stock In"
            isOpen={ modalConfirmGenerate }
            text={ "Are you sure wanna generate these Finished Goods to Product Stock In?" }
            toggle={ () => setModalConfirmGenerate(!modalConfirmGenerate) }
            onSubmit={ handleGenerateStock }
        />
      </div>
  )
}

FinishedGoodsItemTab.propTypes = {
  purchaseOrderId: PropTypes.string,
  finishedGoods: PropTypes.object,
  purchaseOrder: PropTypes.object,
  packaging: PropTypes.object,
  stockAdjustmentStatus: PropTypes.string,
  getFinishedGoodss: PropTypes.func,
  getFinishedGoodsById: PropTypes.func,
  updateFinishedGoods: PropTypes.func,
  deleteFinishedGoods: PropTypes.func,
  clearFinishedGoodsError: PropTypes.func,
  saveFinishedGoodsEntryForm: PropTypes.func,
  clearPurchaseOrderError: PropTypes.func,
  generatePurchaseOrderProductStockIn: PropTypes.func,
  changeStatusFinishedGoods: PropTypes.func,
  getPackagings: PropTypes.func,
}

const mapStateToProps = ({ packaging, finishedGoods, purchaseOrder }) => {
  return {
    packaging,
    finishedGoods,
    purchaseOrder
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getFinishedGoodss,
      getFinishedGoodsById,
      updateFinishedGoods,
      deleteFinishedGoods,
      clearFinishedGoodsError,
      saveFinishedGoodsEntryForm,
      clearPurchaseOrderError,
      generatePurchaseOrderProductStockIn,
      changeStatusFinishedGoods,
      getPackagings,
    })(FinishedGoodsItemTab)
)
