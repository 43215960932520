import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_UNIT_OF_MEASUREMENT,
  GET_UNIT_OF_MEASUREMENT_BY_ID,
  UPDATE_UNIT_OF_MEASUREMENT,
  DELETE_UNIT_OF_MEASUREMENT,
  GET_UNIT_OF_MEASUREMENTS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getUnitOfMeasurementByIdSuccess,
  getUnitOfMeasurementsError,
  getUnitOfMeasurementsSuccess,
} from "./actions";

const getUnitOfMeasurementsRequest = query =>
  get("/api/UnitOfMeasurement/paging", { params: query });
const addNewUnitOfMeasurementRequest = item =>
  post("/api/UnitOfMeasurement", item);
const getUnitOfMeasurementByIdRequest = id =>
  get(`/api/UnitOfMeasurement/${id}`);
const updateUnitOfMeasurementRequest = item =>
  update("/api/UnitOfMeasurement", item);
const deleteUnitOfMeasurementRequest = id =>
  del(`/api/UnitOfMeasurement/${id}`);

function* getUnitOfMeasurements({ payload }) {
  try {
    const response = yield call(getUnitOfMeasurementsRequest, payload);
    yield put(getUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUnitOfMeasurementsError(error));
  }
}

function* addNewUnitOfMeasurement({ payload }) {
  try {
    yield call(addNewUnitOfMeasurementRequest, payload);
    const response = yield call(getUnitOfMeasurementsRequest, {});
    yield put(getUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUnitOfMeasurementsError(error));
  }
}

function* updateUnitOfMeasurement({ payload }) {
  try {
    yield call(updateUnitOfMeasurementRequest, payload);
    const response = yield call(getUnitOfMeasurementsRequest, {});
    yield put(getUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUnitOfMeasurementsError(error));
  }
}

function* getUnitOfMeasurementById({ payload }) {
  try {
    var response = yield call(getUnitOfMeasurementByIdRequest, payload);
    yield put(getUnitOfMeasurementByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUnitOfMeasurementsError(error));
  }
}

function* deleteUnitOfMeasurement({ payload }) {
  try {
    yield call(deleteUnitOfMeasurementRequest, payload);
    const response = yield call(getUnitOfMeasurementsRequest, {});
    yield put(getUnitOfMeasurementsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUnitOfMeasurementsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_UNIT_OF_MEASUREMENTS, getUnitOfMeasurements);
  yield takeEvery(ADD_NEW_UNIT_OF_MEASUREMENT, addNewUnitOfMeasurement);
  yield takeEvery(GET_UNIT_OF_MEASUREMENT_BY_ID, getUnitOfMeasurementById);
  yield takeEvery(UPDATE_UNIT_OF_MEASUREMENT, updateUnitOfMeasurement);
  yield takeEvery(DELETE_UNIT_OF_MEASUREMENT, deleteUnitOfMeasurement);
}

export default itemSaga;
