import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";

export class OrderSummry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moreStatusIsOpen: false,
    };
  }

  toggleMoreStatus = () => {
    this.setState(prevState => ({
      moreStatusIsOpen: !prevState.moreStatusIsOpen,
    }));
  };

  render() {
    const { item } = this.props;

    const {
      closedOrderIds,
      completedOrderIds,
      deliveryToCambodiaOrderIds,
      deliveryToCustomerOrderIds,
      draftOrderIds,
      inCambodiaWarehouseOrderIds,
      inKoreaWarehouseOrderIds,
      readyForDeliveryToCambodiaOrderIds,
      readyForDeliveryToCustomerOrderIds,
      rejectedOrderIds,
    } = item;

    let totalOrder = [];
    totalOrder = totalOrder
      .concat(closedOrderIds)
      .concat(completedOrderIds)
      .concat(deliveryToCambodiaOrderIds)
      .concat(deliveryToCustomerOrderIds)
      .concat(draftOrderIds)
      .concat(inCambodiaWarehouseOrderIds)
      .concat(inKoreaWarehouseOrderIds)
      .concat(readyForDeliveryToCambodiaOrderIds)
      .concat(readyForDeliveryToCustomerOrderIds)
      .concat(rejectedOrderIds);

    return (
      <Card className="mb-0">
        <CardHeader className="bg-transparent border">
          <CardTitle>Order Summary</CardTitle>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={6}>
                  <Card
                    color="info"
                    className="mini-stats-wid mt-1 mb-2 border"
                  >
                    <CardBody className="p-2">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">All</p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={totalOrder?.length ?? 0}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card
                    color="primary"
                    className="mini-stats-wid mt-1 mb-2 border"
                  >
                    <CardBody className="p-2">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">Draft</p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={draftOrderIds?.length ?? 0}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={6}>
                  <Card
                    color="info"
                    className="mini-stats-wid mt-1 mb-2 border"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            In KO Warehouse
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={inKoreaWarehouseOrderIds?.length ?? 0}
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card
                    color="warning"
                    className="mini-stats-wid mt-1 mb-2 border"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            Delivery To CAM
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={deliveryToCambodiaOrderIds?.length ?? 0}
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={6}>
                  <Card
                    color="secondary"
                    className="mini-stats-wid mt-1 mb-2 border"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            In CAM Warehouse
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={inCambodiaWarehouseOrderIds?.length ?? 0}
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card
                    color="success"
                    className="mini-stats-wid mt-1 mb-2 border"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            Completed
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={completedOrderIds?.length ?? 0}
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              cursor: "pointer",
            }}
            onClick={this.toggleMoreStatus}
          >
            <span className="font-size-24 text-primary ps-2 pe-2">
              <i
                className={
                  this.state.moreStatusIsOpen
                    ? "fas fa-angle-up"
                    : "fas fa-angle-down"
                }
              ></i>
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={this.state.moreStatusIsOpen}>
          <CardBody className="pb-1">
            <Row>
              <Col md={4}>
                <Row>
                  <Col md={6}>
                    <Card
                      color="dark"
                      className="mini-stats-wid mt-1 mb-2 border"
                    >
                      <CardBody className="p-2">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-white font-size-12 fw-bold">
                              Closed
                            </p>
                            <h4 className="mb-0 text-white text-center">
                              <NumberFormat
                                value={closedOrderIds?.length ?? 0}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                              />
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card
                      color="danger"
                      className="mini-stats-wid mt-1 mb-2 border"
                    >
                      <CardBody className="p-2">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-white font-size-12 fw-bold">
                              Rejected
                            </p>
                            <h4 className="mb-0 text-white text-center">
                              <NumberFormat
                                value={rejectedOrderIds?.length ?? 0}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                              />
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={8}>
                <div className="d-flex">
                  <Card
                    color="info"
                    className="mini-stats-wid mt-1 mb-2 border me-3"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            Ready for delivery to CAM
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={
                                readyForDeliveryToCambodiaOrderIds?.length ?? 0
                              }
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card
                    color="info"
                    className="mini-stats-wid mt-1 mb-2 border me-3"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            Delivery To Customer
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={deliveryToCustomerOrderIds?.length ?? 0}
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card
                    color="info"
                    className="mini-stats-wid mt-1 mb-2 border me-3"
                  >
                    <CardBody className="p-2">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-white font-size-12 fw-bold">
                            Ready For Delivery To Customer
                          </p>
                          <h4 className="mb-0 text-white text-center">
                            <NumberFormat
                              value={
                                readyForDeliveryToCustomerOrderIds?.length ?? 0
                              }
                              displayType="text"
                              thousandSeparator={true}
                              fixedDecimalScale={false}
                            />
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

OrderSummry.propTypes = {
  item: PropTypes.object,
};

export default OrderSummry;
