import CustomPagination from "components/Common/CustomPagination"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Tbody, Thead, Tr } from "react-super-responsive-table"
import {
  getStockOutItems,
  addNewStockOutItem,
  getStockOutItemById,
  updateStockOutItem,
  deleteStockOutItem,
  clearStockOutItemError,
  getConfirmStockOut,
  clearStockOutError,
} from "store/actions"
import {
  Button, ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  Input,
  Row,
  Table,
} from "reactstrap"
import ModalForm from "containers/stock-out-item/ModalForm"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { timestampFileName, toastMessage } from "helpers/utils"
import NumberFormat from "react-number-format"
import { debounce } from "lodash"
import StockOutConfirmModal from "containers/stock-out/StockOutConfirmModal"
import StockOutValidateResultModal from "../../containers/stock-out/StockOutValidateResultModal"
import { validateProductStockOutRequest } from "../../store/product-stock-out/saga"
import { getStockOutItemToExcel, validateStockOutRequest } from "../../store/stock-out/saga"
import { getStockInItemToExcel, getStockInUploadTemplate } from "../../store/stock-in/saga"

// StockOutItem
const StockOutItemTab = props => {
  const [ selectedId, setSelectedId ] = useState(null)
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ term, setTerm ] = useState("")

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false)
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false)
  const [ modalValidateResult, setModalValidateResult ] = useState(false)
  const [ resultValidationData, setResultValidationData ] = useState([])

  const {
    getStockOutItems,
    addNewStockOutItem,
    getStockOutItemById,
    updateStockOutItem,
    deleteStockOutItem,
    clearStockOutItemError,
    getConfirmStockOut,
    stockOutId,
    stockOutItem,
    stockOut,
    stockInStatus,
  } = props

  const { items, item, message, itemCount, pageCount, loading } = stockOutItem

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  useEffect(() => {
    getStockOutItems({ stockOutId, term, page, pageSize })
  }, [ page, pageSize, term ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearStockOutItemError()
    }
  }, [ message ])

  useEffect(() => {
    if (stockOut.message) {
      toastMessage(stockOut.message)
      clearStockOutError()
    }
  }, [ stockOut.message ])

  const handleSubmit = data => {
    addNewStockOutItem(data)
    setModalAddIsOpen(false)
  }

  const handleUpdate = data => {
    updateStockOutItem(data)
    setModalEditIsOpen(false)
  }

  const handleDelete = () => {
    deleteStockOutItem({ stockOutId, id: selectedId })
    setModalConfirmDeleteIsOpen(false)
  }

  const handleSubmitStockConfirmation = () => {
    if (stockOutId) {
      getConfirmStockOut({ stockOutId, type: "Tab" })
      setModalConfirmStock(false)
    }
  }

  const handleValidateStockOut = () => {
    validateStockOutRequest(stockOutId).then(res => {
      if (res) {
        const { hasInvalid, results } = res

        if (hasInvalid) {
          setModalValidateResult(hasInvalid)
          setResultValidationData(results)
        } else {
          setModalConfirmStock(true)
        }
      }
    })
  }

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target
                          debouncedQuickSearch(value)
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  { stockInStatus !== 1 && ( // 1 is Confirmed
                      <>
                        { items && items.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                outline
                                className="me-1"
                                onClick={ () => handleValidateStockOut() }
                            >
                              <i className="fas fa-check"/> Confirm Stock Out
                            </Button>
                        ) }

                        <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={ () => setModalAddIsOpen(true) }
                        >
                          <i className="fas fa-plus"/> Add New
                        </Button>
                      </>
                  ) }
                  <ButtonDropdown
                      isOpen={ moreActionIsOpen }
                      toggle={ () => setMoreActionIsOpen(!moreActionIsOpen) }
                  >
                    <DropdownToggle caret color="primary" outline>
                      <i className="mdi mdi-dots-vertical"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                          onClick={ () => {
                            getStockOutItemToExcel(
                                stockOutId,
                                `${ timestampFileName() }_Stock_Out_items.xlsx`
                            )
                          } }
                          className="text-primary"
                      >
                        <i className="fas fa-file-export me-1"/> Export to
                        Excel
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>

              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              { loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                        <tr>
                          <th style={ { width: "80px" } }>#</th>
                          <th className="text-center">Item Code</th>
                          <th className="text-center">Item Name</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">UoM</th>
                          <th className="text-center">Total</th>
                          <th className="text-center" style={ { width: "120px" } }>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { items.map((item, index) => {
                          let num = (page - 1) * pageSize + (index + 1)
                          let totalQuantity =
                              item.quantity * parseInt(item.unitOfMeasurementConversionRate)
                          
                          return (
                              <tr key={ index }>
                                <td style={ { textAlign: "right" } }>{ num }</td>
                                <td className="text-end">{ item.itemCode }</td>
                                <td>{ item.itemName }</td>
                                <td className="text-end">
                                  <NumberFormat
                                      value={ item.quantity }
                                      displayType="text"
                                      thousandSeparator={ true }
                                      fixedDecimalScale={ false }
                                  />
                                </td>
                                <td>{ item.unitOfMeasurementName }</td>
                                <td className="text-end">
                                  <NumberFormat
                                      value={ totalQuantity ?? 0 }
                                      displayType="text"
                                      thousandSeparator={ true }
                                      fixedDecimalScale={ false }
                                  />
                                </td>
                                <td>
                                  { stockInStatus !== 1 && ( // 1 is Confirmed
                                      <div className="d-flex gap-3">
                                        <Link className="text-primary" to="#">
                                          <i
                                              className="mdi mdi-pencil font-size-18"
                                              id="edittooltip"
                                              onClick={ () => {
                                                getStockOutItemById(item.id)
                                                setModalEditIsOpen(true)
                                              } }
                                          ></i>
                                        </Link>

                                        <Link className="text-danger" to="#">
                                          <i
                                              className="mdi mdi-delete font-size-18"
                                              id="deletetooltip"
                                              onClick={ () => {
                                                setSelectedId(item.id)
                                                setModalConfirmDeleteIsOpen(true)
                                              } }
                                          ></i>
                                        </Link>
                                      </div>
                                  ) }
                                </td>
                              </tr>
                          )
                        }) }
                      </tbody>
                    </Table>
                  </div>
              ) }
            </div>
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ pageCount }
                defaultPageSize={ pageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => setPage(i) }
                onPageSizeChange={ size => {
                  setPage(1)
                  setPageSize(size)
                } }
            />
          </CardBody>
        </Card>
        <ModalForm
            title="Add Unit of Measurement"
            isOpen={ modalAddIsOpen }
            stockOutId={ stockOutId }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            onSubmit={ handleSubmit }
        />
        { item ? (
            <ModalForm
                title="Edit Unit of Measurement"
                stockOutId={ stockOutId }
                item={ item }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
        <StockOutConfirmModal
            title="Confirm item out stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />

        {
          resultValidationData.length > 0 ?
              <StockOutValidateResultModal
                  title={ "Validate Item Stock Out Result" }
                  isOpen={ modalValidateResult }
                  toggle={ () => setModalValidateResult(!modalValidateResult) }
                  items={ resultValidationData }
              /> : null
        }
      </div>
  )
}

StockOutItemTab.propTypes = {
  stockOutId: PropTypes.string,
  stockOutItem: PropTypes.object,
  stockOut: PropTypes.object,
  stockInStatus: PropTypes.number,
  getStockOutItems: PropTypes.func,
  addNewStockOutItem: PropTypes.func,
  getStockOutItemById: PropTypes.func,
  updateStockOutItem: PropTypes.func,
  deleteStockOutItem: PropTypes.func,
  clearStockOutItemError: PropTypes.func,
  getConfirmStockOut: PropTypes.func,
  clearStockOutError: PropTypes.func,
}

const mapStateToProps = ({ stockOutItem, stockOut }) => {
  return {
    stockOutItem,
    stockOut,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getStockOutItems,
      addNewStockOutItem,
      getStockOutItemById,
      updateStockOutItem,
      deleteStockOutItem,
      clearStockOutItemError,
      getConfirmStockOut,
      clearStockOutError,
    })(StockOutItemTab)
)
