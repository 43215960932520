import React, { useCallback, useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { debounce } from "lodash"
import { NIL } from "uuid"
import Select from "react-select"

import { getItemsRequest } from "store/item/saga"

import ItemSelectionModal from "containers/item/ItemSelectionModal"
import { validatePackagingRequest } from "../../store/packaging/saga"
// purchaseOrderId
const ModalForm = ({
                     isOpen,
                     toggle,
                     title,
                     purchaseOrderId,
                     clothingSizes,
                     item,
                     onSubmit,
                   }) => {
  const [ id, setId ] = useState(NIL)
  const [ itemId, setItemId ] = useState(null)
  const [ clothingSizeId, setClothingSizeId ] = useState(null)
  const [ quantity, setQuantity ] = useState(0)
  const [ valid, setValid ] = useState(false);
  const [ exceed, setExceed ] = useState(0);

  const [ term, setTerm ] = useState("")
  const [ submitted, setSubmitted ] = useState(false)

  const [ isLoadingItem, setIsLoadingItem ] = useState(false)
  const [ modalSelectItemIsOpen, setModalSelectItemIsOpen ] = useState(false)
  const [ itemOptions, setItemOptions ] = useState({})
  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])

  const debouncedFetchItems = useCallback(
      debounce(term => {
        setTerm(term)
      }, 1000),
      []
  )

  useEffect(() => {
    setClothingSizeOptions(
        clothingSizes.map(item => {
          return {
            key: item.id,
            value: item.id,
            label: item.name,
          }
        })
    )
  }, [ clothingSizes ])

  useEffect(() => {
    setIsLoadingItem(true)
    getItemsRequest({ term }).then(res => {
      const { data } = res
      setItemOptions(
          data.map(a => {
            return {
              id: a.id,
              value: a.id,
              label: `${ a.code } - ${ a.name }`,
            }
          })
      )
      setIsLoadingItem(false)
    })
  }, [ term ])

  const onOpened = () => {
    if (item) {
      setId(item.id)
      setItemId({
        id: item.itemId,
        value: item.itemId,
        label: `${ item.itemCode } - ${ item.itemName }`,
      })
      setQuantity(item.quantity)
      setClothingSizeId({
        key: item.clothingSizeId,
        value: item.clothingSizeId,
        label: item.clothingSizeName,
      })
    } else {
      setId(NIL)
      setItemId(null)
      setClothingSizeId(null)
      setQuantity(0)
      setSubmitted(false)
      setExceed(0);
      setValid(false);
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    let isValid = itemId

    if (isValid) {
      const item = {
        id,
        purchaseOrderId,
        itemId: itemId?.value,
        clothingSizeId: clothingSizeId?.value,
        quantity,
      }

      validatePackagingRequest(item).then(data => {
        if(data.valid){
          onSubmit(data)
        }else{
          setValid(false);
          setExceed(data.exceed);
        }
      })

      // setItemId(null)
    }
  }

  const handleSelectedItem = item => {
    setItemId({
      id: item.id,
      value: item.id,
      label: `${ item.code } - ${ item.name }`,
    })
    setModalSelectItemIsOpen(false)
  }

  return (
      <>
        <Modal
            size="md"
            onOpened={ onOpened }
            isOpen={ isOpen }
            toggle={ toggle }
            backdrop="static"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              { title }
            </h5>
            <button
                type="button"
                onClick={ toggle }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col md={ 12 }>
                <div className="d-flex">
                  <FormGroup className="flex-grow-1">
                    <Label>Item</Label>
                    <div className="d-flex">
                      <Input
                          type="text"
                          value={ itemId?.label || "" }
                          className={ `form-control me-1` }
                          readOnly={ true }
                      />
                    </div>
                  </FormGroup>
                </div>
                <FormGroup>
                  <Label>Size</Label>
                  <Select
                      isDisabled
                      name="clothingSizeId"
                      value={ clothingSizeId }
                      onChange={ option => {
                        setClothingSizeId(option)
                      } }
                      options={ clothingSizeOptions }
                      classNamePrefix="select2-selection"
                      placeholder="Select Size"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Quantity</Label>
                  <Input
                      type="number"
                      name="quantity"
                      placeholder="Quantity"
                      className={
                          "form-control " +
                          (((submitted && !valid)) ? "is-invalid" : "")
                      }
                      value={ quantity }
                      onChange={ e => {
                        setQuantity(e.target.value)
                      } }
                  />
                  { submitted && !valid && (
                      <div className="invalid-feedback-custom">
                        {exceed} items exceed sewing quantity
                      </div>
                  ) }
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="primary" onClick={ handleSubmit } type="submit">
              Submit
            </Button>
            <button
                type="button"
                onClick={ toggle }
                className="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
        <ItemSelectionModal
            title="Item Selection"
            isOpen={ modalSelectItemIsOpen }
            toggle={ () => setModalSelectItemIsOpen(!modalSelectItemIsOpen) }
            onSubmit={ handleSelectedItem }
            selectedPurchaseOrderId={ purchaseOrderId }
            selectedTypeOption={ {
              key: 2,
              value: "Cloth",
              label: "Cloth",
            } }
        />
      </>
  )
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  purchaseOrderId: PropTypes.string,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  clothingSizes: PropTypes.array,
}

export default ModalForm
