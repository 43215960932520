import { call, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_USER_BY_ID,
  GET_ALL_USERS,
  GET_USER_BY_ID,
  REGISTER_USER_PORTAL,
  RESET_USER_PASSWORD,
  UPDATE_BY_USER,
  UPDATE_USER_PROFILE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getUsersSuccess,
  getUserByIdSuccess,
  getUsersFail,
  registerUserPortalError,
  registerUserPortalSuccess,
  updateUserProfileError,
  updateUserProfileSuccess,
  deleteUserByIdSuccess,
  deleteUserByIdError,
} from "./actions";

const registerUserPortalRequest = user => post("/api/account/register", user);
const getUsersRequest = query => get("api/account/paging", { params: query });
const getUserByIdRequest = id => get(`/api/account/user/${id}`);
const deleteUserByIdRequest = query =>
  get(`/api/Account/delete`, { params: query });
const updateUerRequest = user => post("/api/account/updateUser", user);
const updateUserProfileRequest = user =>
  post("/api/Account/updateUserProfile", user);
const resetUserPasswordRequest = user =>
  post("/api/Account/resetPassword", user);

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersRequest, payload);
    yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* registerUserPortal({ payload }) {
  try {
    var response = yield call(registerUserPortalRequest, payload);
    response = yield call(getUsersRequest, {});
    yield put(registerUserPortalSuccess());
    yield put(getUsersSuccess(response));
  } catch (err) {
    let error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(registerUserPortalError(error));
  }
}

function* updateUer({ payload }) {
  try {
    var response = yield call(updateUerRequest, payload);
    response = yield call(getUserByIdRequest, response);
    yield put(getUserByIdSuccess(response));
    //const response = yield call(getUsersRequest, {});
    // yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* updateUserProfile({ payload }) {
  try {
    var response = yield call(updateUserProfileRequest, payload);
    response = yield call(getUserByIdRequest, response);
    yield put(getUserByIdSuccess(response));
    yield put(updateUserProfileSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(updateUserProfileError(error));
  }
}

function* resetUserPassword({ payload }) {
  try {
    yield call(resetUserPasswordRequest, payload);
    const response = yield call(getUsersRequest, {});
    yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* getUserById({ payload }) {
  try {
    var response = yield call(getUserByIdRequest, payload);
    yield put(getUserByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getUsersFail(error));
  }
}

function* deleteUserById({ payload }) {
  try {
    var response = yield call(deleteUserByIdRequest, payload);
    yield put(deleteUserByIdSuccess(response));
    response = yield call(getUsersRequest, {});
    yield put(getUsersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(deleteUserByIdError(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_ALL_USERS, fetchUsers);
  yield takeEvery(GET_USER_BY_ID, getUserById);
  yield takeEvery(UPDATE_BY_USER, updateUer);
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
  yield takeEvery(RESET_USER_PASSWORD, resetUserPassword);
  yield takeEvery(REGISTER_USER_PORTAL, registerUserPortal);
  yield takeEvery(DELETE_USER_BY_ID, deleteUserById);
}

export default userSaga;
