import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getPurchaseOrderItems,
} from "store/actions";
import {
  Card,
  CardBody,
  CardHeader, 
  CardTitle,
  Table,
} from "reactstrap"
import NumberFormat from "react-number-format";
import { sumBy } from "lodash"

const DesignSummaryCard = props => {
  
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  
  const {
    getPurchaseOrderItems,
    purchaseOrderId,
    purchaseOrderItem,
    
  } = props;

  const { items, clothingSizes, item, message, itemCount, pageCount, loading } =
      purchaseOrderItem;

  useEffect(() => {
    getPurchaseOrderItems({ purchaseOrderId, term, page, pageSize });
  }, [page, pageSize, term]);
  
  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <CardTitle>Design Summary</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={{ width: "80px" }}>#</th>
                        <th className="text-center">Item Code</th>
                        <th className="text-center">Type</th>
                        {clothingSizes.map((clothingSize, index) => {
                          return (
                              <th key={index} className="text-center">
                                {clothingSize.name}
                              </th>
                          );
                        })}

                        <th className="text-center">Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      {items.map((item, index) => {
                        const { purchaseOrderItemEntries } = item;
                        let num = (page - 1) * pageSize + (index + 1);

                        let total = sumBy(
                            purchaseOrderItemEntries,
                            purchaseOrderItemEntry => {
                              return purchaseOrderItemEntry.quantity;
                            }
                        );

                        return (
                            <tr key={index}>
                              <td style={{ textAlign: "right" }}>{num}</td>
                              <td>
                                {item.itemCode} - {item.itemName}
                              </td>
                              <td>{item.type}</td>
                              {clothingSizes.map((clothingSize, index) => {
                                let findPurchaseOrderItemEntries =
                                    purchaseOrderItemEntries.filter(
                                        e => e.clothingSizeId === clothingSize.id
                                    );

                                let quantity = findPurchaseOrderItemEntries
                                    ? sumBy(
                                        findPurchaseOrderItemEntries,
                                        findPurchaseOrderItemEntry => {
                                          return findPurchaseOrderItemEntry.quantity;
                                        }
                                    )
                                    : 0;

                                return (
                                    <td key={index} className="text-end">
                                      <NumberFormat
                                          value={quantity}
                                          displayType="text"
                                          thousandSeparator={true}
                                          fixedDecimalScale={false}
                                      />
                                    </td>
                                );
                              })}

                              <td className="text-end">
                                <NumberFormat
                                    value={total}
                                    displayType="text"
                                    thousandSeparator={true}
                                    fixedDecimalScale={false}
                                />
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </Table>
                  </div>
              )}
            </div>
            <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                onChangePage={i => setPage(i)}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
            />
          </CardBody>
        </Card>
      </div>
  );
};

DesignSummaryCard.propTypes = {
  purchaseOrderId: PropTypes.string,
  purchaseOrderItem: PropTypes.object,
  stockAdjustmentStatus: PropTypes.string,
  getPurchaseOrderItems: PropTypes.func,
};

const mapStateToProps = ({ purchaseOrderItem }) => {
  return {
    purchaseOrderItem,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPurchaseOrderItems,
    })(DesignSummaryCard)
);
