import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DELIVERY_ORDERS,
  ADD_NEW_DELIVERY_ORDER,
  GET_DELIVERY_ORDER_BY_ID,
  UPDATE_DELIVERY_ORDER,
  DELETE_DELIVERY_ORDER,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeliveryOrdersSuccess,
  getDeliveryOrderByIdSuccess,
  getDeliveryOrdersFail,
  addNewDeliveryOrderSuccess,
  addNewDeliveryOrderError,
  updateDeliveryOrderSuccess,
  deleteDeliveryOrderSuccess,
  deleteDeliveryOrderError,
} from "./actions";

// DeliveryOrder
const getDeliveryOrdersRequest = query =>
  post("/api/DeliveryOrder/paging", query);

const addNewDeliveryOrderRequest = role => post("/api/DeliveryOrder", role);
const getDeliveryOrderByIdRequest = id => get(`/api/DeliveryOrder/${id}`);
const updateDeliveryOrderRequest = role => update("/api/DeliveryOrder", role);
const deleteDeliveryOrderRequest = id => del(`/api/DeliveryOrder/${id}`);

function* fetchDeliveryOrders({ payload }) {
  try {
    const response = yield call(getDeliveryOrdersRequest, payload);
    yield put(getDeliveryOrdersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrdersFail(error));
  }
}

function* addNewDeliveryOrder({ payload }) {
  try {
    const { history, data } = payload;
    let response = yield call(addNewDeliveryOrderRequest, data);
    yield put(addNewDeliveryOrderSuccess(response));
    history.push(`/delivery-order/${response}`);
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(addNewDeliveryOrderError(error));
  }
}

function* updateDeliveryOrder({ payload }) {
  try {
    let response = yield call(updateDeliveryOrderRequest, payload);
    response = yield call(getDeliveryOrderByIdRequest, response);
    yield put(getDeliveryOrderByIdSuccess(response));
    yield put(updateDeliveryOrderSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrdersFail(error));
    yield put(getDeliveryOrdersFail(error));
  }
}

function* getDeliveryOrderById({ payload }) {
  try {
    var response = yield call(getDeliveryOrderByIdRequest, payload);
    yield put(getDeliveryOrderByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeliveryOrdersFail(error));
  }
}

function* deleteDeliveryOrder({ payload }) {
  try {
    yield call(deleteDeliveryOrderRequest, payload);
    const response = yield call(getDeliveryOrdersRequest, {});
    yield put(getDeliveryOrdersSuccess(response));
    yield put(deleteDeliveryOrderSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(deleteDeliveryOrderError(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_DELIVERY_ORDERS, fetchDeliveryOrders);
  yield takeEvery(ADD_NEW_DELIVERY_ORDER, addNewDeliveryOrder);
  yield takeEvery(GET_DELIVERY_ORDER_BY_ID, getDeliveryOrderById);
  yield takeEvery(UPDATE_DELIVERY_ORDER, updateDeliveryOrder);
  yield takeEvery(DELETE_DELIVERY_ORDER, deleteDeliveryOrder);
}

export default roleSaga;
