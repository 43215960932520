import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT,
  GET_PRODUCT_UNIT_OF_MEASUREMENT_BY_ID,
  UPDATE_PRODUCT_UNIT_OF_MEASUREMENT,
  DELETE_PRODUCT_UNIT_OF_MEASUREMENT,
  GET_PRODUCT_UNIT_OF_MEASUREMENTS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getProductUnitOfMeasurementByIdSuccess,
  getProductUnitOfMeasurementsError,
  getProductUnitOfMeasurementsSuccess,
} from "./actions";

const getProductUnitOfMeasurementsRequest = query =>
  get("/api/ProductUnitOfMeasurement/paging", { params: query });
const addNewProductUnitOfMeasurementRequest = item =>
  post("/api/ProductUnitOfMeasurement", item);
const getProductUnitOfMeasurementByIdRequest = id =>
  get(`/api/ProductUnitOfMeasurement/${id}`);
const updateProductUnitOfMeasurementRequest = item =>
  update("/api/ProductUnitOfMeasurement", item);
const deleteProductUnitOfMeasurementRequest = id =>
  del(`/api/ProductUnitOfMeasurement/${id}`);

function* getProductUnitOfMeasurements({ payload }) {
  try {
    const response = yield call(getProductUnitOfMeasurementsRequest, payload);
    yield put(getProductUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUnitOfMeasurementsError(error));
  }
}

function* addNewProductUnitOfMeasurement({ payload }) {
  try {
    const { productId } = payload;
    yield call(addNewProductUnitOfMeasurementRequest, payload);
    const response = yield call(getProductUnitOfMeasurementsRequest, {
      productId,
    });
    yield put(getProductUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUnitOfMeasurementsError(error));
  }
}

function* updateProductUnitOfMeasurement({ payload }) {
  try {
    const { productId } = payload;
    yield call(updateProductUnitOfMeasurementRequest, payload);
    const response = yield call(getProductUnitOfMeasurementsRequest, {
      productId,
    });
    yield put(getProductUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUnitOfMeasurementsError(error));
  }
}

function* getProductUnitOfMeasurementById({ payload }) {
  try {
    var response = yield call(getProductUnitOfMeasurementByIdRequest, payload);
    yield put(getProductUnitOfMeasurementByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUnitOfMeasurementsError(error));
  }
}

function* deleteProductUnitOfMeasurement({ payload }) {
  try {
    const { productId, id } = payload;
    yield call(deleteProductUnitOfMeasurementRequest, id);
    const response = yield call(getProductUnitOfMeasurementsRequest, {
      productId,
    });
    yield put(getProductUnitOfMeasurementsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductUnitOfMeasurementsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(
    GET_PRODUCT_UNIT_OF_MEASUREMENTS,
    getProductUnitOfMeasurements
  );
  yield takeEvery(
    ADD_NEW_PRODUCT_UNIT_OF_MEASUREMENT,
    addNewProductUnitOfMeasurement
  );
  yield takeEvery(
    GET_PRODUCT_UNIT_OF_MEASUREMENT_BY_ID,
    getProductUnitOfMeasurementById
  );
  yield takeEvery(
    UPDATE_PRODUCT_UNIT_OF_MEASUREMENT,
    updateProductUnitOfMeasurement
  );
  yield takeEvery(
    DELETE_PRODUCT_UNIT_OF_MEASUREMENT,
    deleteProductUnitOfMeasurement
  );
}

export default itemSaga;

export { getProductUnitOfMeasurementsRequest };
