import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_INVOICE_NOTE,
  ADD_NEW_INVOICE_NOTE,
  GET_INVOICE_NOTE_BY_ID,
  UPDATE_INVOICE_NOTE,
  DELETE_INVOICE_NOTE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getInvoiceNoteSuccess,
  getInvoiceNoteByIdSuccess,
  getInvoiceNoteFail,
  addNewInvoiceNoteSuccess,
  updateInvoiceNoteSuccess,
  deleteInvoiceNoteSuccess,
} from "./actions";

const getInvoiceNoteRequest = query =>
  get(`/api/InvoiceNote/paging`, { params: query });
const addNewInvoiceNoteRequest = item => post(`/api/InvoiceNote`, item);
const getInvoiceNoteByIdRequest = id => get(`/api/InvoiceNote/${id}`);
const updateInvoiceNoteRequest = item => update(`/api/InvoiceNote`, item);
const deleteInvoiceNoteRequest = id => del(`/api/InvoiceNote/${id}`);

function* fetchInvoiceNotes({ payload }) {
  try {
    const response = yield call(getInvoiceNoteRequest, payload);

    yield put(getInvoiceNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoiceNoteFail(error));
  }
}

function* addNewInvoiceNote({ payload }) {
  try {
    yield call(addNewInvoiceNoteRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getInvoiceNoteRequest, { invoiceId });
    yield put(getInvoiceNoteSuccess(response));
    yield put(addNewInvoiceNoteSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoiceNoteFail(error));
  }
}

function* updateInvoiceNote({ payload }) {
  try {
    yield call(updateInvoiceNoteRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getInvoiceNoteRequest, { invoiceId });
    yield put(getInvoiceNoteSuccess(response));
    yield put(updateInvoiceNoteSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoiceNoteFail(error));
  }
}

function* getInvoiceNoteById({ payload }) {
  try {
    var response = yield call(getInvoiceNoteByIdRequest, payload);
    yield put(getInvoiceNoteByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoiceNoteFail(error));
  }
}

function* deleteInvoiceNote({ payload }) {
  try {
    const { id, invoiceId } = payload;
    yield call(deleteInvoiceNoteRequest, id);

    const response = yield call(getInvoiceNoteRequest, { invoiceId });
    yield put(getInvoiceNoteSuccess(response));
    yield put(deleteInvoiceNoteSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoiceNoteFail(error));
  }
}

function* invoiceNoteSaga() {
  yield takeEvery(GET_INVOICE_NOTE, fetchInvoiceNotes);
  yield takeEvery(ADD_NEW_INVOICE_NOTE, addNewInvoiceNote);
  yield takeEvery(GET_INVOICE_NOTE_BY_ID, getInvoiceNoteById);
  yield takeEvery(UPDATE_INVOICE_NOTE, updateInvoiceNote);
  yield takeEvery(DELETE_INVOICE_NOTE, deleteInvoiceNote);
}

export default invoiceNoteSaga;
