// ORDER_ATTACHMENT
export const GET_ORDER_ATTACHMENTS = "GET_ORDER_ATTACHMENTS";
export const GET_ORDER_ATTACHMENTS_SUCCESS = "GET_ORDER_ATTACHMENTS_SUCCESS";
export const GET_ORDER_ATTACHMENTS_FAIL = "GET_ORDER_ATTACHMENTS_FAIL";

export const GET_ORDER_ATTACHMENT_BY_ID = "GET_ORDER_ATTACHMENT_BY_ID";
export const GET_ORDER_ATTACHMENT_BY_ID_SUCCESS =
  "GET_ORDER_ATTACHMENT_BY_ID_SUCCESS";

export const ADD_NEW_ORDER_ATTACHMENT = "ADD_NEW_ORDER_ATTACHMENT";

export const UPDATE_ORDER_ATTACHMENT = "UPDATE_ORDER_ATTACHMENT";
export const UPDATE_ORDER_ATTACHMENTS = "UPDATE_ORDER_ATTACHMENTS";

export const DELETE_ORDER_ATTACHMENT = "DELETE_ORDER_ATTACHMENT";

export const ORDER_ATTACHMENT_CLEAR_ERROR = "ORDER_ATTACHMENT_CLEAR_ERROR";
