import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from "reactstrap"
import NumberFormat from "react-number-format"

const FinishedGoodsFormTableRow = props => {
  const { num, item, clothingSizes, submitted, onChange } = props;
  const [ invalid, setInvalid ] = useState(false);

  const handleClothingSizeOnChange = (values, entry) => {
    const { value } = values
    entry.quantity = value ? Number(value) : 0

    item.entries = item.entries.map(size => {
      return size.id === entry.id ? entry : size
    })

    onChange(item)
  }

  return (
      <>
        <tr>
          <td className={ "input text-center pt-2" }>
            {num}
          </td>
          <td className={ "input" }>
            {item.itemCode} - {item.itemName}
          </td>

          {
            item.entries.map((entry, index) => {
              return <td key={ index } className={ "input" }>
                <NumberFormat
                    value={entry.quantity}
                    displayType="input"
                    className={
                        "form-control text-end " +
                        (((submitted && !entry.valid)) ? "is-invalid" : "")
                    }
                    placeholder={"0"}
                    thousandSeparator={true}
                    fixedDecimalScale={false}
                    onValueChange={values => handleClothingSizeOnChange(values, entry)}
                />
                { submitted && !entry.valid && (
                    <div className="invalid-feedback-custom">
                      {entry.exceed} items exceed packaging quantity
                    </div>
                ) }
              </td>
            })
          }
        </tr>
      </>
  )
}

FinishedGoodsFormTableRow.propTypes = {
  item: PropTypes.object,
  clothingSizes: PropTypes.array,
  onChange: PropTypes.func,
  num: PropTypes.number,
  submitted: PropTypes.bool
}

export default FinishedGoodsFormTableRow