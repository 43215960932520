import FileSaver from "file-saver";
import { getFile } from "helpers/api_helper";

const exportToExcelRequest = async (query, fileName) => {
  return await getFile(`/api/InvoicePayment/exportToExcel`, {
    params: query,
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

export const invoicePaymentService = {
  exportToExcelRequest,
};
