import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container, Button, Card, Input, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import {
  getDeliveryAgency,
  addNewDeliveryAgency,
  updateDeliveryAgency,
  getDeliveryAgencyById,
  deleteDeliveryAgency,
  clearDeliveryAgencyNotify,
} from "../../store/actions";

import DeliveryAgencyTable from "containers/delivery-agency/DeliveryAgencyTable";
import ModalForm from "containers/delivery-agency/ModalForm";
import { toastMessage } from "helpers/utils";
import { transportService } from "services/transport-service";
import { timestampFileName } from "helpers/utils";

import moment from "moment";

class DeliveryAgencyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
      statuses: null,

      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      moreActionIsOpen: false,
      isOpen: false,
    };
  }

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getDeliveryAgency({ page: 1, pageSize: 10, term: "" });
  }

  handleOnEdit = id => {
    this.props.getDeliveryAgencyById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getDeliveryAgency({ term: value ?? "" });
  };

  handleSearch = () => {
    const { term, startDate, endDate, statuses } = this.state;

    this.props.getDeliveryAgency({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      statuses: statuses.map(a => {
        return a.value;
      }),
    });
  };

  handleSubmit = item => {
    this.props.addNewDeliveryAgency(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleUpdate = item => {
    this.props.updateDeliveryAgency(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteDeliveryAgency(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  componentDidUpdate() {
    const { message, clearDeliveryAgencyNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearDeliveryAgencyNotify();
    }
  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getDeliveryAgency({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getDeliveryAgency({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleExportToExcel = () => {
    const { term, startDate, endDate } = this.state;
    transportService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
      },
      `${timestampFileName()}_Send_Order_List.xlsx`
    );
  };

  toggleMoreAction = () => {
    this.setState(prevState => ({
      moreActionIsOpen: !prevState.moreActionIsOpen,
    }));
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Delivery Agency List | JLS Express</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Delivery Agency List" />
            <Row>
              <Col lg={12}>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={this.handleOnAddNew}
                          >
                            <i className="fas fa-plus" /> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <DeliveryAgencyTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={this.handleOnEdit}
                    onViewDetail={this.handleOnViewDetail}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title={"Add Delivery Agency"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit Delivery Agency"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

DeliveryAgencyList.propTypes = {
  items: PropTypes.array,
  message: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getDeliveryAgency: PropTypes.func,
  addNewDeliveryAgency: PropTypes.func,
  updateDeliveryAgency: PropTypes.func,
  getDeliveryAgencyById: PropTypes.func,
  deleteDeliveryAgency: PropTypes.func,
  clearDeliveryAgencyNotify: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ deliveryAgency }) => {
  return deliveryAgency;
};

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryAgency,
    addNewDeliveryAgency,
    updateDeliveryAgency,
    getDeliveryAgencyById,
    deleteDeliveryAgency,
    clearDeliveryAgencyNotify,
  })(DeliveryAgencyList)
);
