import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAIL,
  GET_CURRENCY_BY_ID,
  GET_CURRENCY_BY_ID_SUCCESS,
  ADD_NEW_CURRENCY,
  UPDATE_CURRENCY,
  DELETE_CURRENCY,
  CURRENCY_CLEAR_ERROR,
} from "./actionTypes";


export const getCurrencies = query => ({
  type: GET_CURRENCIES,
  payload: query,
});

export const getCurrenciesSuccess = currencies => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: currencies,
});

export const getCurrenciesFail = error => ({
  type: GET_CURRENCIES_FAIL,
  payload: error,
});

export const getCurrencyById = id => ({
  type: GET_CURRENCY_BY_ID,
  payload: id,
});

export const getCurrencyByIdSuccess = currency => ({
  type: GET_CURRENCY_BY_ID_SUCCESS,
  payload: currency,
});

export const getCurrencyByIdFail = error => ({
  type: GET_CURRENCY_BY_ID_FAIL,
  payload: error,
});

export const addNewCurrency = currency => ({
  type: ADD_NEW_CURRENCY,
  payload: currency,
});

export const updateCurrency = currency => ({
  type: UPDATE_CURRENCY,
  payload: currency,
});

export const deleteCurrency = currency => ({
  type: DELETE_CURRENCY,
  payload: currency,
});

export const clearCurrencyError = () => ({
  type: CURRENCY_CLEAR_ERROR,
});
