import {
  GET_ORDER_ITEMS,
  GET_ORDER_ITEMS_SUCCESS,
  GET_ORDER_ITEMS_FAIL,
  GET_ORDER_ITEM_BY_ID,
  GET_ORDER_ITEM_BY_ID_SUCCESS,
  ADD_NEW_ORDER_ITEM,
  UPDATE_ORDER_ITEM,
  DELETE_ORDER_ITEM,
  ORDER_ITEM_CLEAR_ERROR,
} from "./actionTypes";

// OrderItem
export const getOrderItems = query => ({
  type: GET_ORDER_ITEMS,
  payload: query,
});

export const getOrderItemsSuccess = items => ({
  type: GET_ORDER_ITEMS_SUCCESS,
  payload: items,
});

export const getOrderItemsFail = error => ({
  type: GET_ORDER_ITEMS_FAIL,
  payload: error,
});

export const getOrderItemById = id => ({
  type: GET_ORDER_ITEM_BY_ID,
  payload: id,
});

export const getOrderItemByIdSuccess = item => ({
  type: GET_ORDER_ITEM_BY_ID_SUCCESS,
  payload: item,
});

export const getOrderItemByIdFail = error => ({
  type: GET_ORDER_ITEM_BY_ID_FAIL,
  payload: error,
});

export const addNewOrderItem = item => ({
  type: ADD_NEW_ORDER_ITEM,
  payload: item,
});

export const updateOrderItem = item => ({
  type: UPDATE_ORDER_ITEM,
  payload: item,
});

export const deleteOrderItem = item => ({
  type: DELETE_ORDER_ITEM,
  payload: item,
});

export const clearOrderItemError = () => ({
  type: ORDER_ITEM_CLEAR_ERROR,
});
