import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getStockOutById, getItemUnitOfMeasurements, updateStockOut } from "store/actions";
import StockOutItemTab from "./StockOutItemTab";
import StockOutForm from "../../containers/stock-out/StockOutForm"

const StockOutDetail = props => {
  const { match, history } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [id, setId] = useState(null);

  const { getStockOutById, updateStockOut, stockOut } = props;
  const { item, loading } = stockOut;

  useEffect(() => {
    const { id } = match.params;
    getStockOutById(id);
    setId(id);
  }, []);

  const handleOnSubmit = (data) => {
    updateStockOut(data)
    history.push(`/stock-out`)
  }

  const handleOnCancel = () =>{
    history.push(`/stock-out`);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stock Out Detail | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {item ? (
                <>
                  <Breadcrumbs
                    title="Home"
                    backLink={"/stock-out"}
                    breadcrumbItem={`${item.code} | Item Stock Out Detail`}
                  />
                  <StockOutForm item={ item } onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
                </>
              ) : null}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

StockOutDetail.propTypes = {
  product: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  stockOut: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getItemUnitOfMeasurements: PropTypes.func,
  getStockOutById: PropTypes.func,
  updateStockOut: PropTypes.func
};

const mapStateToProps = ({ stockOut }) => {
  return {
    stockOut,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getStockOutById,
    getItemUnitOfMeasurements,
    updateStockOut,
  })(StockOutDetail)
);
