import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL as NIL_UUID } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Input, Row } from "reactstrap";

import Select from "react-select";

import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";
import "react-fine-uploader/gallery/gallery.css";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

var uploader;
const transportationTypeOptions = [
  { key: 1, value: 0, label: "Slider" },
  { key: 2, value: 1, label: "Landing" },
];

class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL_UUID,
      title: "",
      subTitle: "",
      displayOrder: 0,
      url: "",
      link: "",
      type: transportationTypeOptions[0],
      submitted: false,
    };

    uploader = new FineUploaderTraditional({
      options: {
        multiple: true,
        request: {
          method: "POST",
          endpoint: `${process.env.REACT_APP_API_URL}/api/FileUpload/banner`,
        },
        validation: {
          sizeLimit: 104857600, // 100MB
        },
      },
    });

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    uploader.on("submitted", id => {
      this.setState({
        uploading: true,
      });
    });

    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        const attachment = {
          fileName: responseJSON.fileName,
          url: responseJSON.url,
        };

        this.setState({
          url: attachment.url,
        });
      } else {
        this.setState({
          fail: true,
          error: responseJSON.message,
        });
      }
    });

    uploader.on("error", (id, name, errorReason) => {
      console.log(errorReason, id, name);
      // this.setState({
      //   fail: true,
      //   error: errorReason,
      // });
    });
  }

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleSubmit = () => {
    const { id, title, type, url, displayOrder, link, subTitle } = this.state;
    this.setState({
      submitted: true,
    });
    if (title && url) {
      const data = {
        id,
        title,
        subTitle,
        type: type?.value ?? 0,
        url,
        link,
        displayOrder,
      };

      this.props.onSubmit(data);
    }
  };

  onOpened = () => {
    const { item } = this.props;
    if (item) {
      this.setState({
        ...item,
        type:
          item.type != null
            ? transportationTypeOptions.find(e => e.value === item.type)
            : null,
      });
    } else {
      this.setState({
        id: NIL_UUID,
        displayOrder: 0,
        title: "",
        subTitle: "",
        displayOrder: 0,
        url: "",
        link: "",
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;

    return (
      <Modal
        size="lg"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={6}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Title</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="title"
                  className={submitted && !this.state.title ? "is-invalid" : ""}
                  value={this.state.title}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.title && (
                  <div className="invalid-feedback-custom">
                    Title is required.
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Sub Title</Label>
                <Input
                  type="text"
                  name="subTitle"
                  placeholder="Sub Title"
                  value={this.state.subTitle}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <div>
                  <Label>Type</Label>
                  <Select
                    name="type"
                    value={this.state.type}
                    onChange={this.handleSelectChange}
                    options={transportationTypeOptions}
                    classNamePrefix="test select2-selection"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Display Order</Label>
                <Input
                  type="number"
                  name="displayOrder"
                  placeholder="Display Order"
                  value={this.state.displayOrder}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Link</Label>
                <Input
                  type="text"
                  name="link"
                  placeholder="Link"
                  value={this.state.link}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <h5>Attachments</h5>
              {this.state.url && (
                <img
                  // className="img-thumbnail"
                  alt={this.state.title ?? "No Title"}
                  width={"100%"}
                  src={this.state.url}
                />
              )}
              {submitted && !this.state.url && (
                <Label style={{ color: "red" }}>Image is required.</Label>
              )}
              <Gallery uploader={uploader} />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {hasAccess(permission.banner.write) && (
            <Button color="primary" type="submit" onClick={this.handleSubmit}>
              Submit
            </Button>
          )}
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  orderId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
