import { SEVERITY } from "helpers/utils.js";
import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLE_BY_ID,
  GET_ROLE_BY_ID_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  ROLE_CLEAR_NOTIFY,
  UPDATE_PERMISSION_SUCCESS,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const Role = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ROLE:
    case GET_ROLES:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLES_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        message: {
          title: "Permission",
          text: "Permission has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        message: {
          title: "Role",
          text: "Role has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case GET_ROLE_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };
    case ROLE_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    case GET_ROLES_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default Role;
