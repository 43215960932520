import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_SUPPLIER,
  ADD_NEW_SUPPLIER,
  GET_SUPPLIER_BY_ID,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getSupplierSuccess,
  getSupplierByIdSuccess,
  getSupplierFail,
  addNewSupplierSuccess,
  updateSupplierSuccess,
  deleteSupplierSuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/Supplier`);
const getSupplierRequest = query =>
  get(`/api/Supplier/paging`, { params: query });

const addNewSupplierRequest = item => post(`/api/Supplier`, item);
const getSupplierByIdRequest = id => get(`/api/Supplier/${id}`);
const updateSupplierRequest = item => update(`/api/Supplier`, item);
const deleteSupplierRequest = id => del(`/api/Supplier/${id}`);

function* fetchSuppliers({ payload }) {
  try {
    const response = yield call(getSupplierRequest, payload);

    yield put(getSupplierSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSupplierFail(error));
  }
}

function* addNewSupplier({ payload }) {
  try {
    yield call(addNewSupplierRequest, payload);
    const response = yield call(getSupplierRequest);
    yield put(getSupplierSuccess(response));
    yield put(addNewSupplierSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSupplierFail(error));
  }
}

function* updateSupplier({ payload }) {
  try {
    yield call(updateSupplierRequest, payload);
    const response = yield call(getSupplierRequest);
    yield put(getSupplierSuccess(response));
    yield put(updateSupplierSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSupplierFail(error));
  }
}

function* getSupplierById({ payload }) {
  try {
    var response = yield call(getSupplierByIdRequest, payload);
    yield put(getSupplierByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSupplierFail(error));
  }
}

function* deleteSupplier({ payload }) {
  try {
    yield call(deleteSupplierRequest, payload);
    const response = yield call(getSupplierRequest);
    yield put(getSupplierSuccess(response));
    yield put(deleteSupplierSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getSupplierFail(error));
  }
}

function* SupplierSaga() {
  yield takeEvery(GET_SUPPLIER, fetchSuppliers);
  yield takeEvery(ADD_NEW_SUPPLIER, addNewSupplier);
  yield takeEvery(GET_SUPPLIER_BY_ID, getSupplierById);
  yield takeEvery(UPDATE_SUPPLIER, updateSupplier);
  yield takeEvery(DELETE_SUPPLIER, deleteSupplier);
}

export default SupplierSaga;

export { getDeliveryAgenciesRequest };
