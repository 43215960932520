import {
  GET_ITEM_ATTACHMENTS,
  GET_ITEM_ATTACHMENTS_SUCCESS,
  GET_ITEM_ATTACHMENTS_FAIL,
  GET_ITEM_ATTACHMENT_BY_ID,
  GET_ITEM_ATTACHMENT_BY_ID_SUCCESS,
  ADD_NEW_ITEM_ATTACHMENT,
  UPDATE_ITEM_ATTACHMENT,
  DELETE_ITEM_ATTACHMENT,
  ITEM_ATTACHMENT_CLEAR_ERROR,
  UPDATE_ITEM_ATTACHMENTS,
} from "./actionTypes";

// ItemAttachment
export const getItemAttachments = query => ({
  type: GET_ITEM_ATTACHMENTS,
  payload: query,
});

export const getItemAttachmentsSuccess = items => ({
  type: GET_ITEM_ATTACHMENTS_SUCCESS,
  payload: items,
});

export const getItemAttachmentsFail = error => ({
  type: GET_ITEM_ATTACHMENTS_FAIL,
  payload: error,
});

export const getItemAttachmentById = id => ({
  type: GET_ITEM_ATTACHMENT_BY_ID,
  payload: id,
});

export const getItemAttachmentByIdSuccess = item => ({
  type: GET_ITEM_ATTACHMENT_BY_ID_SUCCESS,
  payload: item,
});

export const getItemAttachmentByIdFail = error => ({
  type: GET_ITEM_ATTACHMENT_BY_ID_FAIL,
  payload: error,
});

export const addNewItemAttachment = item => ({
  type: ADD_NEW_ITEM_ATTACHMENT,
  payload: item,
});

export const updateItemAttachment = item => ({
  type: UPDATE_ITEM_ATTACHMENT,
  payload: item,
});

export const updateItemAttachments = payload => ({
  type: UPDATE_ITEM_ATTACHMENTS,
  payload,
});

export const deleteItemAttachment = item => ({
  type: DELETE_ITEM_ATTACHMENT,
  payload: item,
});

export const clearItemAttachmentError = () => ({
  type: ITEM_ATTACHMENT_CLEAR_ERROR,
});
