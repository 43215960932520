import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import OrderForm from "./OrderForm";
import { addNewOrder } from "store/order/actions";

export class AddNewOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick = () => {
    const { history } = this.props;
    history.push(`/order/edit/${12}`);
  };

  handleSubmit = item => {
    const { history } = this.props;
    const payload = {
      data: item,
      history,
    };
    this.props.addNewOrder(payload);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/order");
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>New Order | JLS Express</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Add New"
              breadcrumbItem="Add New Order"
              backLink={"/order"}
            />
            <OrderForm
              onSubmit={this.handleSubmit}
              isAddNew={true}
              onCancel={this.handleCancel}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AddNewOrder.propTypes = {
  history: PropTypes.object,
  addNewOrder: PropTypes.func,
};

const mapStateToProps = ({ order }) => {
  return order;
};

export default withRouter(
  connect(mapStateToProps, {
    // getOrders,
    addNewOrder,
    // updateOrder,
    // getOrderById,
    // deleteOrder,
    // clearOrderError,
  })(AddNewOrder)
);
