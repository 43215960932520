export const GET_ORDER_TRACKING = "GET_ORDER_TRACKING";
export const GET_ORDER_TRACKING_SUCCESS = "GET_ORDER_TRACKING_SUCCESS";
export const GET_ORDER_TRACKING_FAIL = "GET_ORDER_TRACKING_FAIL";

export const GET_ORDER_TRACKING_BY_ID = "GET_ORDER_TRACKING_BY_ID";
export const GET_ORDER_TRACKING_BY_ID_SUCCESS = "GET_ORDER_TRACKING_BY_ID_SUCCESS";

export const ADD_NEW_ORDER_TRACKING = "ADD_NEW_ORDER_TRACKING";
export const ADD_NEW_ORDER_TRACKING_SUCCESS = "ADD_NEW_ORDER_TRACKING_SUCCESS";

export const UPDATE_ORDER_TRACKING = "UPDATE_ORDER_TRACKING";
export const UPDATE_ORDER_TRACKING_SUCCESS = "UPDATE_ORDER_TRACKING_SUCCESS";

export const DELETE_ORDER_TRACKING = "DELETE_ORDER_TRACKING";
export const DELETE_ORDER_TRACKING_SUCCESS = "DELETE_ORDER_TRACKING_SUCCESS";

export const ORDER_TRACKING_CLEAR_NOTIFY = "ORDER_TRACKING_CLEAR_NOTIFY";
