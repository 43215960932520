import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSION_BY_ID,
  GET_PERMISSION_BY_ID_SUCCESS,
  ADD_NEW_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  PERMISSION_CLEAR_ERROR,
} from "./actionTypes";


export const getPermissions = query => ({
  type: GET_PERMISSIONS,
  payload: query,
});

export const getPermissionsSuccess = permissions => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const getPermissionsFail = error => ({
  type: GET_PERMISSIONS_FAIL,
  payload: error,
});

export const getPermissionById = id => ({
  type: GET_PERMISSION_BY_ID,
  payload: id,
});

export const getPermissionByIdSuccess = permission => ({
  type: GET_PERMISSION_BY_ID_SUCCESS,
  payload: permission,
});

export const getPermissionByIdFail = error => ({
  type: GET_PERMISSION_BY_ID_FAIL,
  payload: error,
});

export const addNewPermission = permission => ({
  type: ADD_NEW_PERMISSION,
  payload: permission,
});

export const updatePermission = Permission => ({
  type: UPDATE_PERMISSION,
  payload: Permission,
});

export const deletePermission = permission => ({
  type: DELETE_PERMISSION,
  payload: permission,
});

export const clearPermissionError = () => ({
  type: PERMISSION_CLEAR_ERROR,
});
