import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Badge, Button, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";

import {
  getDeviceInfoNotificationHistory,
  getDeviceInfoById,
} from "store/actions";

import NotificationHistoryTab from "./NotificationHistoryTab";

export class DeviceInfoDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
      userId: null,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { match, getDeviceInfoNotificationHistory, getDeviceInfoById } =
      this.props;
    const { id, userId } = match.params;
    if (id !== NIL) {
      this.setState({
        id,
        userId,
      });
      getDeviceInfoById(id);
      getDeviceInfoNotificationHistory({ deviceInfoId: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { match, getDeviceInfoNotificationHistory } = props;
    const { id } = match.params;
    if (id !== state.id) {
      getDeviceInfoNotificationHistory({ deviceInfoId: id });
      return id;
    }
    return null;
  }

  handlePopulateDeviceNotificationHistory = () => {
    const { id } = this.state;
    const { getDeviceInfoNotificationHistory } = this.props;
    getDeviceInfoNotificationHistory({ deviceInfoId: id });
  };

  render() {
    const { userId } = this.state;
    const { deviceInfo } = this.props;
    const { item } = deviceInfo;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Device Info Detail | JLS Express</title>
          </MetaTags>
          <Container fluid>
            {item && userId && (
              <Breadcrumbs
                title="Home"
                backLink={`/user/${userId}/7`}
                breadcrumbItem={`${item.brand} ${item.model} - Device Info Detail`}
              />
            )}
            {item && !userId && (
              <Breadcrumbs
                title="Home"
                backLink={"/device-info"}
                breadcrumbItem={`${item.brand} ${item.model} - Device Info Detail`}
              />
            )}
            {!item && !userId && (
              <>
                <Breadcrumbs
                  title="Home"
                  backLink={"/device-info"}
                  breadcrumbItem="Device Info Detail"
                />
              </>
            )}

            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                        this.handlePopulateDeviceNotificationHistory();
                      }}
                    >
                      Notification History
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {this.state.id && this.state.id !== null && (
                      <NotificationHistoryTab deviceInfoId={this.state.id} />
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

DeviceInfoDetail.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  deviceInfo: PropTypes.object,

  getDeviceInfoById: PropTypes.func,
  getDeviceInfoNotificationHistory: PropTypes.func,
};

const mapStateToProps = ({ deviceInfo, deviceInfoNotificationHistory }) => {
  return {
    deviceInfo,
    deviceInfoNotificationHistory,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getDeviceInfoNotificationHistory,
    getDeviceInfoById,
  })(DeviceInfoDetail)
);
