import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { getPagingItemOutHistory } from "../../store/item/actions"
import { debounce } from "lodash"
import { timestampFileName } from "../../helpers/utils"
import CustomPagination from "../../components/Common/CustomPagination"
import { exportItemOutHistoryRequest } from "../../store/item/saga"
import moment from "moment/moment"

const ItemOutHistoryTab = props => {
  const {
    getPagingItemOutHistory,
    accessory,
    itemId
  } = props


  const { items, itemCount, pageCount, loading } = accessory
  const [ term, setTerm ] = useState()
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ selectedId, setSelectedId ] = useState(null)

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
        setPage(1)
      }, 1000),
      []
  )

  useEffect(() => {
    getPagingItemOutHistory({ itemId, term, page, pageSize })
  }, [ term, page, pageSize ])

  const handleExportToExcel = () => {
    exportItemOutHistoryRequest({
      itemId,
    }, `${ timestampFileName() }_Item_Out_History.xlsx`).then(() => {
      console.log('ok')
    })
  }

  return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target
                          debouncedQuickSearch(value)
                        } }
                    />
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      outline
                      className="me-1"
                      onClick={ handleExportToExcel }
                  >
                    <i className="fas fa-file-export me-1"/> Export
                    to Excel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {
              loading ? <div className={ "loading" }></div> :
                  <>
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th className={ "text-center" } style={ { width: "80px" } }>#</th>
                        <th className={ "text-center" }>Purcahse Order</th>
                        <th className={ "text-center" }>Date Requested</th>
                        <th className={ "text-center" }>Type</th>
                        <th className={ "text-center" }>Quantity</th>
                        <th className={ "text-center" }>UOM</th>

                      </tr>
                      </thead>
                      <tbody>
                      {
                        items.map((item, index) => {
                          let num = (page - 1) * pageSize + (index + 1)
                          return <tr key={ index }>
                            <td className={ "text-end" }>{ num }</td>
                            <td>{ item.purcahseOrderCode }</td>
                            <td>{ moment(item.stockRequestDate).format("DD-MMM-YYYY") }</td>
                            <td>{ item.typeName }</td>
                            <td className={ 'text-end' }>{ item.quantity }</td>
                            <td>{ item.unitOfMeasurementName }</td>
                          </tr>
                        })
                      }
                      </tbody>
                    </Table>

                    <CustomPagination
                        itemCount={ itemCount }
                        currentPage={ page }
                        totalPage={ pageCount }
                        defaultPageSize={ pageSize }
                        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                        onChangePage={ i => setPage(i) }
                        onPageSizeChange={ size => {
                          setPage(1)
                          setPageSize(size)
                        } }
                    />
                  </>
            }
          </CardBody>
        </Card>


      </>
  )
}

ItemOutHistoryTab.propTypes = {
  itemId: PropTypes.string.isRequired,
  accessory: PropTypes.object,
  getPagingItemOutHistory: PropTypes.func,
}

const mapStateToProps = ({ item }) => {
  return {
    accessory: item
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getPagingItemOutHistory,

    })(ItemOutHistoryTab)
)
