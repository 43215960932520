import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { MetaTags } from "react-meta-tags"
import {
  Col,
  Container, 
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import StockRequestForm from "../../containers/stock-request/StockRequestForm"
import { withRouter } from "react-router-dom"
import {
  addNewStockRequest
} from "../../store/stock-request/actions"

const AddNewStockRequest = props => {
  const { addNewStockRequest, match, history } = props
  const [ stockRequestType, setStockRequestType ] = useState('')
  
  
  useEffect(() => {
    const { stockRequestType } = match.params
    setStockRequestType(stockRequestType)
  }, [])
  
  
  const handleOnSubmit = (data) => {
    addNewStockRequest({
      history,
      data,
      stockRequestType
    })
    // history.push(`/stock-request/${ stockRequestType }`)
  }

  const handleOnCancel = ()=>{
    history.push(`/stock-request/${ stockRequestType }`)
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Stock Request | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ `/stock-request/${ stockRequestType }` }
                breadcrumbItem={ `Add New Stock Request` }
            />
            <Row>
              <Col md={ 12 }>
                {stockRequestType &&
                <StockRequestForm
                    stockRequestType={stockRequestType}    
                    onSubmit={handleOnSubmit} 
                    onCancel={handleOnCancel}/>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

AddNewStockRequest.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  addNewStockRequest: PropTypes.func
}

const mapStateToProps = ({ stockRequest }) => {
  return stockRequest;
};

export default withRouter(
    connect(mapStateToProps, {
      addNewStockRequest,
    })(AddNewStockRequest)
);