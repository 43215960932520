import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, Col, Input, Row } from "reactstrap";
import { getOrders } from "store/actions";
import UserOrderTable from "containers/order/UserOrderTable";
import OrderSummry from "containers/dashboard/OrderSummry";

export class ReceiveOrderTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pageSize: 10,
      userId: null,
    };
  }

  handleOnSearch = e => {
    const { getOrders } = this.props;
    const { userId } = this.state;
    const { value } = e.target;
    this.setState({ term: value });
    getOrders({ term: value ?? "", receiverId: userId });
  };

  componentDidMount = () => {
    const { userId } = this.props;
    this.setState({ userId });
  };

  handleOnPageSizeChange = pageSize => {
    const { userId } = this.state;
    const { getOrders } = this.props;
    getOrders({ term: "", page: 1, pageSize, receiverId: userId });

    this.setState({
      pageSize,
    });
  };

  handleOnPageChange = page => {
    const { pageSize, term, userId } = this.state;

    const { getOrders } = this.props;
    getOrders({
      page,
      pageSize,
      term: term ?? "",
      receiverId: userId,
    });

    this.setState({
      currentPage: page,
    });
  };

  render() {
    const { order } = this.props;
    const { pageSize, currentPage } = this.state;
    const { items, itemCount, pageCount, loading } = order;
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={6}></Col>
              <Col md={4}></Col>
            </Row>
          </CardHeader>
        </Card>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <OrderSummry item={order} />
            <UserOrderTable
              items={items}
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={pageCount}
              loading={loading}
              defaultPageSize={pageSize}
              onChangePage={this.handleOnPageChange}
              onPageSizeChange={this.handleOnPageSizeChange}
            />
          </>
        )}
      </>
    );
  }
}

ReceiveOrderTab.propTypes = {
  userId: PropTypes.string,
  order: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getOrders: PropTypes.func,
};

const mapStateToProps = ({ order }) => {
  return {
    order,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getOrders,
  })(ReceiveOrderTab)
);
