export const GET_ALLCATEGORIES = "GET_ALLCATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const CATEGORY_CLEAR_ERROR = "CATEGORY_CLEAR_ERROR";
