import {
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_BY_ID_SUCCESS,
  ADD_NEW_PRODUCT,
  ADD_NEW_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  PRODUCT_CLEAR_NOTIFY,
} from "./actionTypes";
// Product
export const getProduct = id => ({
  type: GET_PRODUCT,
  payload: id,
});

export const getProductSuccess = Products => ({
  type: GET_PRODUCT_SUCCESS,
  payload: Products,
});

export const getProductFail = error => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
});

export const getProductById = payload => ({
  type: GET_PRODUCT_BY_ID,
  payload,
});

export const getProductByIdSuccess = Product => ({
  type: GET_PRODUCT_BY_ID_SUCCESS,
  payload: Product,
});

export const getProductByIdFail = error => ({
  type: GET_PRODUCT_BY_ID_FAIL,
  payload: error,
});

export const addNewProduct = payload => ({
  type: ADD_NEW_PRODUCT,
  payload,
});

export const addNewProductSuccess = payload => ({
  type: ADD_NEW_PRODUCT_SUCCESS,
  payload,
});

export const updateProduct = payload => ({
  type: UPDATE_PRODUCT,
  payload,
});

export const updateProductSuccess = payload => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProduct = payload => ({
  type: DELETE_PRODUCT,
  payload,
});
export const deleteProductSuccess = payload => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload,
});

export const clearProductNotify = () => ({
  type: PRODUCT_CLEAR_NOTIFY,
});
