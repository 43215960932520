import {
  GET_PAGING_LOCATION,
  GET_PAGING_LOCATION_SUCCESS,
  GET_PAGING_LOCATION_ERROR,
  GET_LOCATION_BY_ID,
  GET_LOCATION_BY_ID_SUCCESS,
  ADD_NEW_LOCATION,
  ADD_NEW_LOCATION_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  ADD_NEW_LOCATION_ERROR,
} from "./actionTypes";
// Location
export const getPagingLocation = query => ({
  type: GET_PAGING_LOCATION,
  payload: query,
});

export const getPagingLocationSuccess = payload => ({
  type: GET_PAGING_LOCATION_SUCCESS,
  payload,
});

export const getPagingLocationError = error => ({
  type: GET_PAGING_LOCATION_ERROR,
  payload: error,
});

export const getLocationById = id => ({
  type: GET_LOCATION_BY_ID,
  payload: id,
});

export const getLocationByIdSuccess = payload => ({
  type: GET_LOCATION_BY_ID_SUCCESS,
  payload,
});

export const addNewLocation = item => ({
  type: ADD_NEW_LOCATION,
  payload: item,
});

export const addNewLocationSuccess = item => ({
  type: ADD_NEW_LOCATION_SUCCESS,
  payload: item,
});

export const addNewLocationError = error => ({
  type: ADD_NEW_LOCATION_ERROR,
  payload: error,
});

export const updateLocation = item => ({
  type: UPDATE_LOCATION,
  payload: item,
});

export const updateLocationSuccess = item => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload: item,
});

export const deleteLocation = item => ({
  type: DELETE_LOCATION,
  payload: item,
});

export const deleteLocationSuccess = item => ({
  type: DELETE_LOCATION_SUCCESS,
  payload: item,
});