// PRODUCT_IMAGE
export const GET_PRODUCT_IMAGES = "GET_PRODUCT_IMAGES";
export const GET_PRODUCT_IMAGES_SUCCESS = "GET_PRODUCT_IMAGES_SUCCESS";
export const GET_PRODUCT_IMAGES_FAIL = "GET_PRODUCT_IMAGES_FAIL";

export const GET_PRODUCT_IMAGE_BY_ID = "GET_PRODUCT_IMAGE_BY_ID";
export const GET_PRODUCT_IMAGE_BY_ID_SUCCESS =
  "GET_PRODUCT_IMAGE_BY_ID_SUCCESS";

export const ADD_NEW_PRODUCT_IMAGE = "ADD_NEW_PRODUCT_IMAGE";

export const UPDATE_PRODUCT_IMAGE = "UPDATE_PRODUCT_IMAGE";
export const UPDATE_PRODUCT_IMAGES = "UPDATE_PRODUCT_IMAGES";

export const DELETE_PRODUCT_IMAGE = "DELETE_PRODUCT_IMAGE";

export const PRODUCT_IMAGE_CLEAR_ERROR = "PRODUCT_IMAGE_CLEAR_ERROR";
