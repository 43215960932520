import {
  GET_PACKAGINGS,
  GET_PACKAGINGS_SUCCESS,
  GET_PACKAGINGS_ERROR,
  GET_PACKAGING_BY_ID,
  GET_PACKAGING_BY_ID_SUCCESS,
  ADD_NEW_PACKAGING,
  ADD_NEW_PACKAGING_SUCCESS,
  UPDATE_PACKAGING,
  UPDATE_PACKAGING_SUCCESS,
  DELETE_PACKAGING,
  DELETE_PACKAGING_SUCCESS,
  PACKAGING_CLEAR_ERROR,
  ADD_NEW_PACKAGING_ERROR,
  GET_PACKAGING_ENTRY_FORM,
  GET_PACKAGING_ENTRY_FORM_SUCCESS,
  SAVE_PACKAGING_ENTRY_FORM,
  SAVE_PACKAGING_ENTRY_FORM_SUCCESS,
} from "./actionTypes"
import {
  GET_SEWING_ENTRY_FORM,
  GET_SEWING_ENTRY_FORM_SUCCESS,
  SAVE_SEWING_ENTRY_FORM,
  SAVE_SEWING_ENTRY_FORM_SUCCESS
} from "../sewing/actionTypes"

// Packaging
export const getPackagings = query => ({
  type: GET_PACKAGINGS,
  payload: query,
});

export const getPackagingsSuccess = payload => ({
  type: GET_PACKAGINGS_SUCCESS,
  payload,
});

export const getPackagingsError = error => ({
  type: GET_PACKAGINGS_ERROR,
  payload: error,
});

export const getPackagingById = id => ({
  type: GET_PACKAGING_BY_ID,
  payload: id,
});

export const getPackagingByIdSuccess = payload => ({
  type: GET_PACKAGING_BY_ID_SUCCESS,
  payload,
});

export const addNewPackaging = item => ({
  type: ADD_NEW_PACKAGING,
  payload: item,
});

export const addNewPackagingSuccess = item => ({
  type: ADD_NEW_PACKAGING_SUCCESS,
  payload: item,
});

export const addNewPackagingError = error => ({
  type: ADD_NEW_PACKAGING_ERROR,
  payload: error,
});

export const updatePackaging = item => ({
  type: UPDATE_PACKAGING,
  payload: item,
});

export const updatePackagingSuccess = item => ({
  type: UPDATE_PACKAGING_SUCCESS,
  payload: item,
});

export const deletePackaging = item => ({
  type: DELETE_PACKAGING,
  payload: item,
});

export const deletePackagingSuccess = item => ({
  type: DELETE_PACKAGING_SUCCESS,
  payload: item,
});

export const clearPackagingError = () => ({
  type: PACKAGING_CLEAR_ERROR,
});

export const getPackagingEntryForm = query => ({
  type: GET_PACKAGING_ENTRY_FORM,
  payload: query,
});

export const getPackagingEntryFormSuccess = payload => ({
  type: GET_PACKAGING_ENTRY_FORM_SUCCESS,
  payload,
});

export const savePackagingEntryForm = query => ({
  type: SAVE_PACKAGING_ENTRY_FORM,
  payload: query,
});

export const savePackagingEntryFormSuccess = payload => ({
  type: SAVE_PACKAGING_ENTRY_FORM_SUCCESS,
  payload,
});

