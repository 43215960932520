import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_ITEM_UNIT_OF_MEASUREMENT,
  GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID,
  UPDATE_ITEM_UNIT_OF_MEASUREMENT,
  DELETE_ITEM_UNIT_OF_MEASUREMENT,
  GET_ITEM_UNIT_OF_MEASUREMENTS,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getItemUnitOfMeasurementByIdSuccess,
  getItemUnitOfMeasurementsError,
  getItemUnitOfMeasurementsSuccess,
} from "./actions";

const getItemUnitOfMeasurementsRequest = query =>
  get("/api/ItemUnitOfMeasurement/paging", { params: query });
const addNewItemUnitOfMeasurementRequest = item =>
  post("/api/ItemUnitOfMeasurement", item);
const getItemUnitOfMeasurementByIdRequest = id =>
  get(`/api/ItemUnitOfMeasurement/${id}`);
const updateItemUnitOfMeasurementRequest = item =>
  update("/api/ItemUnitOfMeasurement", item);
const deleteItemUnitOfMeasurementRequest = id =>
  del(`/api/ItemUnitOfMeasurement/${id}`);

function* getItemUnitOfMeasurements({ payload }) {
  try {
    const response = yield call(getItemUnitOfMeasurementsRequest, payload);
    yield put(getItemUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUnitOfMeasurementsError(error));
  }
}

function* addNewItemUnitOfMeasurement({ payload }) {
  try {
    const { itemId } = payload;
    yield call(addNewItemUnitOfMeasurementRequest, payload);
    const response = yield call(getItemUnitOfMeasurementsRequest, { itemId });
    yield put(getItemUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUnitOfMeasurementsError(error));
  }
}

function* updateItemUnitOfMeasurement({ payload }) {
  try {
    const { itemId } = payload;
    yield call(updateItemUnitOfMeasurementRequest, payload);
    const response = yield call(getItemUnitOfMeasurementsRequest, { itemId });
    yield put(getItemUnitOfMeasurementsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUnitOfMeasurementsError(error));
  }
}

function* getItemUnitOfMeasurementById({ payload }) {
  try {
    var response = yield call(getItemUnitOfMeasurementByIdRequest, payload);
    yield put(getItemUnitOfMeasurementByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUnitOfMeasurementsError(error));
  }
}

function* deleteItemUnitOfMeasurement({ payload }) {
  try {
    const { itemId, id } = payload;
    yield call(deleteItemUnitOfMeasurementRequest, id);
    const response = yield call(getItemUnitOfMeasurementsRequest, { itemId });
    yield put(getItemUnitOfMeasurementsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemUnitOfMeasurementsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_ITEM_UNIT_OF_MEASUREMENTS, getItemUnitOfMeasurements);
  yield takeEvery(
    ADD_NEW_ITEM_UNIT_OF_MEASUREMENT,
    addNewItemUnitOfMeasurement
  );
  yield takeEvery(
    GET_ITEM_UNIT_OF_MEASUREMENT_BY_ID,
    getItemUnitOfMeasurementById
  );
  yield takeEvery(UPDATE_ITEM_UNIT_OF_MEASUREMENT, updateItemUnitOfMeasurement);
  yield takeEvery(DELETE_ITEM_UNIT_OF_MEASUREMENT, deleteItemUnitOfMeasurement);
}

export default itemSaga;

export { 
  addNewItemUnitOfMeasurementRequest, 
  getItemUnitOfMeasurementByIdRequest, 
  getItemUnitOfMeasurementsRequest
};
