import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Table
} from "reactstrap"
import Select from "react-select"
import { customSingleValue, formatItemOptionLabel, getMaxValue, getStockRequestTypeValue } from "../../helpers/utils"
import ItemRow from "./ItemRow"
import { debounce, sumBy } from "lodash"
import { getItemUnitOfMeasurementsRequest } from "../../store/item-unit-of-measurement/saga"
import { NIL } from "uuid"
import { getItemsRequest } from "../../store/item/saga"

const ItemUsageLimitationForm = props => {
  const { purchaseOrderId, onSubmit, onSubmitWithStatus, onCancel } = props

  const [ term, setTerm ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ itemOptions, setItemOptions ] = useState([])
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ isSubmitted, setIsSubmitted ] = useState(false)
  const [ confirmationType, setConfirmationType ] = useState('')
  const [ modalPrintPreview, setModalPrintPreview ] = useState()
  const [ modalValidation, setModalValidation ] = useState(false)
  const [ items, setItems ] = useState([])

  const SUBMIT = "SUBMIT"
  const APPROVE = "APPROVE"
  const CONFIRMED = "CONFIRMED"
  const REJECT = "REJECT"
  const CANCEL = "CANCEL"
  

  useEffect(() => {
    if (props.items && props.items.length > 0) {

      const { items } = props
      setItems(items.map((item, index) => {
        return {
          ...item,
          index,
          note: item.note ?? '',
          itemId: {
            key: item.itemId,
            value: item.itemId,
            label: `${ item.itemCode } - ${ item.itemName }`,
          },

          isSelected: false,
          label: `${ item.itemCode } - ${ item.itemName }`,
          
          unitOfMeasurementId: {
            key: item.itemUnitOfMeasurementId,
            uomId: item.unitOfMeasurementId,
            value: item.itemUnitOfMeasurementId,
            label: item.itemUnitOfMeasurementTitle
          }
        }
      }))
    } else {
      setItems([])
    }
  }, [ props.items ])

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  useEffect(() => {
    setLoading(true)
    getItemsRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        setLoading(false)
        setItemOptions(res.data.map((a, index) => {
          return {
            key: a.id,
            value: a.id,
            type: a.type,
            itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
            quantity: a.onHandQuantity,
            label: `${ a.code } - ${ a.name }`
          }
        }))
      }
    })
  }, [ term ])

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    switch (name) {
      case 'itemId':
        if (valueType) {
          const { value, label } = valueType
          getItemUnitOfMeasurementsRequest({ itemId: value }).then(res => {
            const { data } = res
            let itemUomOptions = data?.map(a => {
              return {
                id: a.id,
                value: a.id,
                uomId: a.unitOfMeasurementId,
                label: a.title
              }
            }) ?? []

            let max = 0
            if (items.length > 0) {
              max = getMaxValue(
                  items.map(a => {
                    return a.index
                  })
              )
              max += 1
            } else {
              max = max + 1
            }

            let newItem = {
              index: max,
              id: NIL,
              purchaseOrderId,
              itemId: valueType,
              label: label,
              quantity: 0,
              itemUnitOfMeasurementId: null,
              unitOfMeasurementId: itemUomOptions.length > 0 ? itemUomOptions[0] : null,
              note: '',
              itemUomOptions,
              isSelected: false
            }

            setItems([ ...items, newItem ])
          })
        }
        break
    }
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setItems(items.map(item => {
      return {
        ...item,
        isSelected: checked
      }
    }))
  }

  const handleDeleteSelected = () => {
    setItems(items.filter(e => !e.isSelected))
    setIsHeaderSelected(false)
  }

  const handleItemChange = item => {
    setItems(items.map(a => {
      return a.index === item.index ? item : a
    }))
  }

  const handleDeleteItem = (item) => {
    setItems(items.filter(e => e.index !== item.index))
  }

  const handleSubmit = () => {
    let valid = isValidateSubmit()

    if (valid) {
      let data = items.map(item => {
        return {
          ...item,
          itemId: item.itemId?.value,
          itemUnitOfMeasurementId: item.unitOfMeasurementId?.value,
          unitOfMeasurementId: item.unitOfMeasurementId?.uomId
        }
      })
      
      onSubmit(data)
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const isValidateSubmit = () => {
    setIsSubmitted(true)
    let hasNull = items.some(e => !e.unitOfMeasurementId || e.quantity === 0 || e.quantity === '')
    return !!(!hasNull)
  }

  return (
      <>
        <Row>
          <Col>
            <Card className={ "mb-2" }>
              <CardHeader className={ "bg-transparent border-bottom" }>
                <Row>
                  <Col md={ 2 }>
                    <CardTitle className={ "pt-2" }>Items</CardTitle>
                  </Col>
                  <Col>
                  </Col>
                </Row>
              </CardHeader>
              <CardHeader className={ "bg-transparent border-bottom" }>
                <Row>
                  <Col>
                    <Label className={ "mt-2" }>Find Items:</Label>
                  </Col>
                  <Col md={ 8 }>
                    <Select
                        name="itemId"
                        value={ null }
                        placeholder={ "Find by Code, Name, ..." }
                        onChange={ handleSelectChange }
                        options={ itemOptions }
                        components={ {
                          SingleValue: customSingleValue
                        } }
                        onInputChange={ handleInputChange }
                        formatOptionLabel={ formatItemOptionLabel }
                        classNamePrefix="select2-selection"
                        isLoading={ loading }
                        isClearable
                    />
                  </Col>
                  <Col>
                    <div className="text-sm-end">
                      <Button color={ "danger" }
                              onClick={ handleDeleteSelected }
                              outline disabled={ !items.some(e => e.isSelected) }>
                        <i className="fas fa-trash me-1"/> Delete Selected
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                    id="tech-companies-1"
                    className="table-editable table table-striped table-bordered table-nowrap"
                >
                  <thead className={ "bg-primary text-white" }>
                  <tr>
                    <th className={ "text-center" } style={ { width: "80px" } }>
                      <input
                          type="checkbox"
                          className="form-check-input"
                          id="headerSelected"
                          checked={ isHeaderSelected }
                          onChange={ handleHeaderSelect }
                      />
                    </th>
                    <th className="text-center">Item</th>
                    <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                    <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                    <th className={ "text-center" } style={ { width: "250px" } }>Note</th>
                    <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    items.sort((a, b) => a.index > b.index? -1: 1)
                        .map((item, index) => {
                      return <ItemRow
                          key={ index }
                          item={ item }
                          isSubmitted={ isSubmitted }
                          onChange={ handleItemChange }
                          onDelete={ handleDeleteItem }
                      />
                    })
                  }
                  </tbody>
                  <tfoot>
                  <tr>
                    <td className={ "text-end" } colSpan={ 2 }>
                      <strong>Total</strong>
                    </td>
                    <td className={ "text-end" }>
                      <strong>
                        { Number(sumBy(items, a => {
                          return Number(a.quantity)
                        })).toFixed(2) }
                      </strong>
                    </td>
                    <td></td>
                  </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
            <Card className={ "mb-2" }>
              <CardBody>
                <Button
                    type="button"
                    color="primary"
                    className="me-1"
                    onClick={ handleSubmit }
                >
                  Submit
                </Button>
                <Button color="secondary"
                        onClick={ handleCancel }
                        type="button">
                  Cancel
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
  )
}

ItemUsageLimitationForm.propTypes = {
  items: PropTypes.array,
  onSubmit: PropTypes.func,
  onSubmitWithStatus: PropTypes.func,
  onCancel: PropTypes.func,
  stockRequestType: PropTypes.string,
  purchaseOrderId: PropTypes.string.isRequired,
}

export default ItemUsageLimitationForm