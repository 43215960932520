import React from 'react'
import PropTypes from 'prop-types'
import { MetaTags } from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { addNewProductStockOut } from "../../store/product-stock-out/actions"
import ProductStockOutForm from "../../containers/product-stock-out/ProductStockOutForm"

const AddNewProductStockIn = props => {
  const { addNewProductStockOut, history } = props

  const handleOnSubmit = (data) => {
    addNewProductStockOut(data)
    history.push(`/product-stock-out`)
  }

  const handleOnCancel = () => {
    history.push(`/product-stock-out`)
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product Stock Out | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Home"
                backLink={ `/product-stock-out` }
                breadcrumbItem={ `Add New Product Stock out` }
            />
            <Row>
              <Col md={ 12 }>
                <ProductStockOutForm onSubmit={ handleOnSubmit } onCancel={ handleOnCancel }/>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

AddNewProductStockIn.propTypes = {
  addNewProductStockOut: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = ({ productStockIn }) => {
  return productStockIn
}

export default withRouter(
    connect(mapStateToProps, {
      addNewProductStockOut,
    })(AddNewProductStockIn)
)
