import {
  GET_ORDER_TRACKING,
  GET_ORDER_TRACKING_SUCCESS,
  GET_ORDER_TRACKING_FAIL,

  GET_ORDER_TRACKING_BY_ID,
  GET_ORDER_TRACKING_BY_ID_SUCCESS,

  ADD_NEW_ORDER_TRACKING,
  ADD_NEW_ORDER_TRACKING_SUCCESS,

  UPDATE_ORDER_TRACKING,
  UPDATE_ORDER_TRACKING_SUCCESS,

  DELETE_ORDER_TRACKING,
  DELETE_ORDER_TRACKING_SUCCESS,

  ORDER_TRACKING_CLEAR_NOTIFY,
} from "./actionTypes";


export const getOrderTracking = id => ({
  type: GET_ORDER_TRACKING,
  payload: id,
});

export const getOrderTrackingSuccess = ordertracking => ({
  type: GET_ORDER_TRACKING_SUCCESS,
  payload: ordertracking,
});

export const getOrderTrackingFail = error => ({
  type: GET_ORDER_TRACKING_FAIL,
  payload: error,
});

export const getOrderTrackingById = data => ({
  type: GET_ORDER_TRACKING_BY_ID,
  payload: data,
});

export const getOrderTrackingByIdSuccess = ordertracking => ({
  type: GET_ORDER_TRACKING_BY_ID_SUCCESS,
  payload: ordertracking,
});

export const getOrderTrackingByIdFail = error => ({
  type: GET_ORDER_TRACKING_BY_ID_FAIL,
  payload: error,
});

export const addNewOrderTracking= payload => ({
  type: ADD_NEW_ORDER_TRACKING,
  payload,
});

export const addNewOrderTrackingSuccess = payload => ({
  type: ADD_NEW_ORDER_TRACKING_SUCCESS,
  payload,
});

export const updateOrderTracking = payload => ({
  type: UPDATE_ORDER_TRACKING,
  payload,
});

export const updateOrderTrackingSuccess = payload => ({
  type: UPDATE_ORDER_TRACKING_SUCCESS,
  payload,
});

export const deleteOrderTracking = payload => ({
  type: DELETE_ORDER_TRACKING,
  payload,
});

export const deleteOrderTrackingSuccess = payload => ({
  type: DELETE_ORDER_TRACKING_SUCCESS,
  payload,
});

export const clearOrderTrackingNotify = () => ({
  type: ORDER_TRACKING_CLEAR_NOTIFY,
});
