import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import {
  getShippingContact,
  addNewShippingContact,
  getShippingContactById,
  updateShippingContact,
  deleteShippingContact,
} from "../../../store/shipping-contact/actions";
import ShippingContactTable from "../../../containers/shipping-contact/ShippingContactTable";
import ModalFormShippingContact from "../../../containers/shipping-contact/ModalFormShippingContact";

class ShippingContactTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      userId: props.userId,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    const { userId } = this.props;

    if (userId) {
      this.setState({
        userId,
      });
      this.props.getShippingContact({
        page: 1,
        pageSize: 10,
        userId,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { userId } = props;
    if (userId !== state.userId) {
      return userId;
    }

    return null;
  }

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = item => {
    const { userId } = this.state;
    this.props.addNewShippingContact({ userId, data: item });
    this.setState({
      modalAddIsOpen: false,
    });
  };

  // componentDidUpdate() {
  //   const { error } = this.props;

  //   if (error) {
  //     toastr.options = {
  //       timeOut: 5000,
  //     };
  //     toastr.error(error, "Error");

  //     // this.props.clearRoleError();
  //   }
  // }

  handleOnEdit = id => {
    this.props.getShippingContactById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleUpdate = item => {
    const { userId } = this.state;
    this.props.updateShippingContact({ userId, data: item });
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    const { id, userId } = this.state;
    this.props.deleteShippingContact({ id, userId });
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  handleOnPageChange = page => {
    const { pageSize, userId } = this.state;
    this.props.getShippingContact({
      page,
      pageSize,
      userId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { userId } = this.state;
    this.props.getShippingContact({
      page: 1,
      pageSize,
      userId,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnSearch = e => {
    const { userId } = this.state;

    const { value } = e.target;
    this.props.getShippingContact({
      term: value ?? "",
      userId,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pb-1 pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={6}></Col>
              <Col md={4}>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    onClick={this.handleOnAddNew}
                  >
                    <i className="fas fa-plus" /> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {items && (
          <ShippingContactTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
        <ModalFormShippingContact
          title={"Add New Shipping Contact"}
          userId={this.state.userId}
          isOpen={this.state.modalAddIsOpen}
          toggle={this.toggleModalAdd}
          onSubmit={this.handleSubmit}
        />

        {item && (
          <ModalFormShippingContact
            title={"Edit Shipping Contact"}
            item={item}
            userId={this.state.userId}
            isOpen={this.state.modalEditIsOpen}
            toggle={this.toggleModalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

ShippingContactTab.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getShippingContact: PropTypes.func,
  getShippingContactById: PropTypes.func,
  addNewShippingContact: PropTypes.func,
  updateShippingContact: PropTypes.func,
  deleteShippingContact: PropTypes.func,
  userId: PropTypes.string,
};

const mapStateToProps = ({ shippingContact }) => {
  return shippingContact;
};

export default withRouter(
  connect(mapStateToProps, {
    getShippingContact,
    addNewShippingContact,
    getShippingContactById,
    updateShippingContact,
    deleteShippingContact,
    // updateRole,
    // getRoleById,
    // deleteRole,
    // clearRoleError,
  })(ShippingContactTab)
);
