import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { NIL } from "uuid"
import Select from "react-select"
import { getItemTypeOptions } from "../../helpers/utils"
import { getAllClothingSizesRequest } from "../../store/clothing-size/saga"

const ModalForm = ({ isOpen, toggle, title, item, onSubmit }) => {
  const [ id, setId ] = useState(NIL)
  const [ code, setCode ] = useState("")
  const [ referenceNumber, setReferenceNumber ] = useState("")
  const [ name, setName ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ status, setStatus ] = useState(0)
  const [ clothingSizes, setClothingSizes ] = useState([]);

  const [ submitted, setSubmitted ] = useState(false);
  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])

  useEffect(() => {
    getAllClothingSizesRequest().then(data=>{
      setClothingSizeOptions(data.map(a =>{
        return {
          key: a.id,
          value: a.id,
          label: a.name
        }
      }))
    })
  }, [])

  const onOpened = () => {
    setSubmitted(false);
    if (!isEmpty(item)) {
      const { id, code, name, referenceNumber, description, status, clothingSizes } = item
      setId(id)
      setCode(code)
      setReferenceNumber(referenceNumber ?? '');
      setName(name)
      setDescription(description)
      setStatus(status);
      setClothingSizes(clothingSizes.map(clothingSize=>{
        return {
          key: clothingSize.id,
          value: clothingSize.id,
          label: clothingSize.name
        }
      }))
    } else {
      setId(NIL)
      setCode("");
      setReferenceNumber('');
      setName("");
      setDescription("")
      setStatus(0);
      setClothingSizes([]);
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    let isValid = code && name
    if (isValid) {
      const data = {
        id,
        code,
        referenceNumber,
        name,
        description,
        status,
        clothingSizes: clothingSizes.map(a =>{
          return {
            id: a.value,
            name: a.label
          }
        })
      }
      onSubmit(data)
    }
  }

  return (
      <Modal
          size="md"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={ 12 }>
              <FormGroup className="mb-3">
                <Label htmlFor="">Code</Label>
                <Input
                    type="text"
                    name="code"
                    placeholder="Purchase Code"
                    value={ code }
                    onChange={ e => {
                      setCode(e.target.value)
                    } }
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="">Reference Number</Label>
                <Input
                    type="text"
                    name="referenceNumber"
                    placeholder="Reference Number"
                    value={ referenceNumber }
                    onChange={ e => {
                      setReferenceNumber(e.target.value)
                    } }
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="">Name</Label>
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={ submitted && !name ? "is-invalid" : "" }
                    value={ name }
                    onChange={ e => {
                      setName(e.target.value)
                    } }
                />
                { submitted && !name && (
                    <div className="invalid-feedback-custom">Name is required.</div>
                ) }
              </FormGroup>

              <FormGroup className="mb-3">
                <Label htmlFor="">Description</Label>
                <Input
                    type="textarea"
                    rows={ 3 }
                    name="description"
                    placeholder="Description"
                    className={ submitted && !description ? "is-invalid" : "" }
                    value={ description }
                    onChange={ e => {
                      setDescription(e.target.value)
                    } }
                />
              </FormGroup>

              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (submitted && !clothingSizes ? " is-invalid" : "")
                    }
                >
                  <Label>Clothing Size</Label>
                  <Select
                      name="clothingSizes"
                      value={ clothingSizes }
                      onChange={ option => {
                        setClothingSizes(option)
                      } }
                      options={ clothingSizeOptions }
                      classNamePrefix="select2-selection"
                      isMulti
                      isClearable
                  />
                </div>
                { submitted && !clothingSizes && (
                    <div className="invalid-feedback-custom">Clothing Sizes is required.</div>
                ) }
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ModalForm
