// USER_ITEM_SHIPPING_FEE
export const GET_USER_ITEM_SHIPPING_FEES = "GET_USER_ITEM_SHIPPING_FEES";
export const GET_USER_ITEM_SHIPPING_FEES_SUCCESS =
  "GET_USER_ITEM_SHIPPING_FEES_SUCCESS";
export const GET_USER_ITEM_SHIPPING_FEES_FAIL =
  "GET_USER_ITEM_SHIPPING_FEES_FAIL";

export const GET_USER_ITEM_SHIPPING_FEE_BY_ID =
  "GET_USER_ITEM_SHIPPING_FEE_BY_ID";
export const GET_USER_ITEM_SHIPPING_FEE_BY_ID_SUCCESS =
  "GET_USER_ITEM_SHIPPING_FEE_BY_ID_SUCCESS";

export const ADD_NEW_USER_ITEM_SHIPPING_FEE = "ADD_NEW_USER_ITEM_SHIPPING_FEE";

export const UPDATE_USER_ITEM_SHIPPING_FEE = "UPDATE_USER_ITEM_SHIPPING_FEE";

export const DELETE_USER_ITEM_SHIPPING_FEE = "DELETE_USER_ITEM_SHIPPING_FEE";

export const USER_ITEM_SHIPPING_FEE_CLEAR_ERROR =
  "USER_ITEM_SHIPPING_FEE_CLEAR_ERROR";
