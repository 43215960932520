export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL";

export const GET_BANNER_BY_ID = "GET_BANNER_BY_ID";
export const GET_BANNER_BY_ID_SUCCESS = "GET_BANNER_BY_ID_SUCCESS";

export const ADD_NEW_BANNER = "ADD_NEW_BANNER";

export const UPDATE_BANNER = "UPDATE_BANNER";

export const DELETE_BANNER = "DELETE_BANNER";

export const BANNER_CLEAR_ERROR = "BANNER_CLEAR_ERROR";
