import { SEVERITY } from "helpers/utils.js";
import {
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDERS_SUCCESS,
  GET_PURCHASE_ORDER_BY_ID_SUCCESS,
  GET_PURCHASE_ORDERS_ERROR,
  PURCHASE_ORDER_CLEAR_ERROR,
  GET_CONFIRM_PURCHASE_ORDER,
  GET_CONFIRM_PURCHASE_ORDER_SUCCESS,
  PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN,
  PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN_SUCCESS,
  GET_PURCHASE_ORDER_MATERIAL,
  GET_PURCHASE_ORDER_MATERIAL_SUCCESS,
  GET_PURCHASE_ORDER_SUMMARY, GET_PURCHASE_ORDER_SUMMARY_SUCCESS,
} from "./actionTypes.js"
const initState = {
  items: [],
  item: null,
  material: null,
  summary: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  generating: false,
  message: null,
};

const StockIn = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PURCHASE_ORDER_MATERIAL:
    case GET_CONFIRM_PURCHASE_ORDER:
    case GET_PURCHASE_ORDERS:
    case GET_PURCHASE_ORDER_SUMMARY:
      return {
        ...state,
        loading: true,
      };
    case GET_PURCHASE_ORDER_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        material: payload
      }
      
    case GET_CONFIRM_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Stock In",
          text: "Stock In has been generated successfully.",
          severity: SEVERITY.SUCCESS,
        },
      };

    case GET_PURCHASE_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        summary: payload
      }
    case PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN:
      return {
        ...state,
        generating: true
      }
    case PURCHASE_ORDER_GENERATE_PRODUCT_STOCK_IN_SUCCESS:
      return {
        ...state,
        generating: false,
          message: {
          title: "Purchase Order",
          text: 'Purchase Order has been generate Product Stock-in successfully',
          severity: SEVERITY.SUCCESS,
        },
      }
    case GET_PURCHASE_ORDERS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_PURCHASE_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_PURCHASE_ORDERS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Code");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Item is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case PURCHASE_ORDER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default StockIn;
