import {
  GET_DELIVERY_AGENCY,
  GET_DELIVERY_AGENCY_SUCCESS,
  GET_DELIVERY_AGENCY_FAIL,
  GET_DELIVERY_AGENCY_BY_ID,
  GET_DELIVERY_AGENCY_BY_ID_SUCCESS,
  ADD_NEW_DELIVERY_AGENCY,
  ADD_NEW_DELIVERY_AGENCY_SUCCESS,
  UPDATE_DELIVERY_AGENCY,
  UPDATE_DELIVERY_AGENCY_SUCCESS,
  DELETE_DELIVERY_AGENCY,
  DELETE_DELIVERY_AGENCY_SUCCESS,
  DELIVERY_AGENCY_CLEAR_NOTIFY,
} from "./actionTypes";

// DeliveryAgency
export const getDeliveryAgency = id => ({
  type: GET_DELIVERY_AGENCY,
  payload: id,
});

export const getDeliveryAgencySuccess = items => ({
  type: GET_DELIVERY_AGENCY_SUCCESS,
  payload: items,
});

export const getDeliveryAgencyFail = error => ({
  type: GET_DELIVERY_AGENCY_FAIL,
  payload: error,
});

export const getDeliveryAgencyById = data => ({
  type: GET_DELIVERY_AGENCY_BY_ID,
  payload: data,
});

export const getDeliveryAgencyByIdSuccess = item => ({
  type: GET_DELIVERY_AGENCY_BY_ID_SUCCESS,
  payload: item,
});

export const getDeliveryAgencyByIdFail = error => ({
  type: GET_DELIVERY_AGENCY_BY_ID_FAIL,
  payload: error,
});

export const addNewDeliveryAgency = payload => ({
  type: ADD_NEW_DELIVERY_AGENCY,
  payload,
});

export const addNewDeliveryAgencySuccess = payload => ({
  type: ADD_NEW_DELIVERY_AGENCY_SUCCESS,
  payload,
});

export const updateDeliveryAgency = payload => ({
  type: UPDATE_DELIVERY_AGENCY,
  payload,
});

export const updateDeliveryAgencySuccess = payload => ({
  type: UPDATE_DELIVERY_AGENCY_SUCCESS,
  payload,
});

export const deleteDeliveryAgency = payload => ({
  type: DELETE_DELIVERY_AGENCY,
  payload,
});

export const deleteDeliveryAgencySuccess = payload => ({
  type: DELETE_DELIVERY_AGENCY_SUCCESS,
  payload,
});

export const clearDeliveryAgencyNotify = () => ({
  type: DELIVERY_AGENCY_CLEAR_NOTIFY,
});
