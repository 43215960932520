import {
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_FAIL,
  GET_STORE_BY_ID,
  GET_STORE_BY_ID_SUCCESS,
  ADD_NEW_STORE,
  ADD_NEW_STORE_SUCCESS,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  STORE_CLEAR_NOTIFY,
} from "./actionTypes";

// Store
export const getStore = id => ({
  type: GET_STORE,
  payload: id,
});

export const getStoreSuccess = items => ({
  type: GET_STORE_SUCCESS,
  payload: items,
});

export const getStoreFail = error => ({
  type: GET_STORE_FAIL,
  payload: error,
});

export const getStoreById = data => ({
  type: GET_STORE_BY_ID,
  payload: data,
});

export const getStoreByIdSuccess = item => ({
  type: GET_STORE_BY_ID_SUCCESS,
  payload: item,
});

export const getStoreByIdFail = error => ({
  type: GET_STORE_BY_ID_FAIL,
  payload: error,
});

export const addNewStore = payload => ({
  type: ADD_NEW_STORE,
  payload,
});

export const addNewStoreSuccess = payload => ({
  type: ADD_NEW_STORE_SUCCESS,
  payload,
});

export const updateStore = payload => ({
  type: UPDATE_STORE,
  payload,
});

export const updateStoreSuccess = payload => ({
  type: UPDATE_STORE_SUCCESS,
  payload,
});

export const deleteStore = payload => ({
  type: DELETE_STORE,
  payload,
});

export const deleteStoreSuccess = payload => ({
  type: DELETE_STORE_SUCCESS,
  payload,
});

export const clearStoreNotify = () => ({
  type: STORE_CLEAR_NOTIFY,
});
