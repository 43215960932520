import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
  // PurchaseOrder
  getPurchaseOrders,
  addNewPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrderById,
  deletePurchaseOrder,
  clearPurchaseOrderError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";

import { debounce } from "lodash";

import PurchaseOrderTable from "containers/purchase-order/PurchaseOrderTable";
import ModalForm from "containers/purchase-order/ModalForm";
import PurchaseOrderPrintPreviewModal from "./PurchaseOrderPrintPreviewModal"

const PurchaseOrderList = props => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [ modalExportToPdf, setModalExportToPdf ] = useState(false)
  
  const {
    getPurchaseOrders,
    addNewPurchaseOrder,
    deletePurchaseOrder,
    updatePurchaseOrder,
    clearPurchaseOrderError,
    getPurchaseOrderById,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
    history,
  } = props;

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );

  useEffect(() => {
    getPurchaseOrders({ page, pageSize, term });
  }, [page, pageSize, term]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearPurchaseOrderError();
    }
  }, [message]);

  const handleOnEdit = id => {
    getPurchaseOrderById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = supplier => {
    updatePurchaseOrder(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    // setModalAddIsOpen(true);
    history.push(`/purchase-order/add-new`);
  };

  const handleSubmit = item => {
    addNewPurchaseOrder(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deletePurchaseOrder(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnDetail = id => {
    history.push(`/purchase-order/${id}`);
  };

  const handleOnExportToPdf = id => {
    setId(id)
    setModalExportToPdf(true)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Purchase Order | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Purchase Order"
            breadcrumbItem="Purchase Order List"
          />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e => {
                              debouncedFetchData(e.target.value);
                            }}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <PurchaseOrderTable
                  items={items}
                  itemCount={itemCount}
                  page={page}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onDetails={handleOnDetail}
                  onExportToPDF={handleOnExportToPdf}
                  onEdit={handleOnEdit}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={page => setPage(page)}
                  onPageSizeChange={pageSize => {
                    setPage(1);
                    setPageSize(pageSize);
                  }}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Purchase Order"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Purchase Order"}
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() =>
                  setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                }
                onSubmit={handleDelete}
              />

              {
                id &&
              <PurchaseOrderPrintPreviewModal
                  purchaseOrderId={id}
                  isOpen={modalExportToPdf}
                  toggle={()=> setModalExportToPdf(false)}
              />
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PurchaseOrderList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,

  getPurchaseOrders: PropTypes.func,
  addNewPurchaseOrder: PropTypes.func,
  updatePurchaseOrder: PropTypes.func,
  getPurchaseOrderById: PropTypes.func,
  deletePurchaseOrder: PropTypes.func,
  clearPurchaseOrderError: PropTypes.func,
  getConfirmPurchaseOrder: PropTypes.func,
};

const mapStateToProps = ({ purchaseOrder }) => {
  return purchaseOrder;
};

export default withRouter(
  connect(mapStateToProps, {
    getPurchaseOrders,
    addNewPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrderById,
    deletePurchaseOrder,
    clearPurchaseOrderError,
  })(PurchaseOrderList)
);
