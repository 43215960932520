import { SEVERITY } from "helpers/utils.js";
import {
  GET_DELIVERY_AGENCY,
  GET_DELIVERY_AGENCY_SUCCESS,
  GET_DELIVERY_AGENCY_FAIL,
  GET_DELIVERY_AGENCY_BY_ID_SUCCESS,
  ADD_NEW_DELIVERY_AGENCY,
  ADD_NEW_DELIVERY_AGENCY_SUCCESS,
  UPDATE_DELIVERY_AGENCY,
  UPDATE_DELIVERY_AGENCY_SUCCESS,
  DELETE_DELIVERY_AGENCY,
  DELETE_DELIVERY_AGENCY_SUCCESS,
  DELIVERY_AGENCY_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const deliveryAgency = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_DELIVERY_AGENCY:
    case UPDATE_DELIVERY_AGENCY:
    case DELETE_DELIVERY_AGENCY:
    case GET_DELIVERY_AGENCY:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_AGENCY_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_DELIVERY_AGENCY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case ADD_NEW_DELIVERY_AGENCY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Delivery Order",
          text: "Delivery Order has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_DELIVERY_AGENCY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Delivery Order",
          text: "Delivery Order has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELETE_DELIVERY_AGENCY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Delivery Order",
          text: "Delivery Order has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_DELIVERY_AGENCY_FAIL:
      return {
        ...state,
        error: payload,
      };
    case DELIVERY_AGENCY_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default deliveryAgency;
