// FEEDBACK
export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAIL = "GET_FEEDBACK_FAIL";

export const GET_FEEDBACK_BY_ID = "GET_FEEDBACK_BY_ID";
export const GET_FEEDBACK_BY_ID_SUCCESS = "GET_FEEDBACK_BY_ID_SUCCESS";

export const ADD_NEW_FEEDBACK = "ADD_NEW_FEEDBACK";
export const ADD_NEW_FEEDBACK_SUCCESS = "ADD_NEW_FEEDBACK_SUCCESS";

export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";

export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";

export const FEEDBACK_CLEAR_NOTIFY = "FEEDBACK_CLEAR_NOTIFY";
