import {
  GET_DEVICE_INFO,
  GET_DEVICE_INFO_SUCCESS,
  GET_DEVICE_INFO_FAIL,
  GET_DEVICE_INFO_BY_ID,
  GET_DEVICE_INFO_BY_ID_SUCCESS,
  ADD_NEW_DEVICE_INFO,
  ADD_NEW_DEVICE_INFO_SUCCESS,
  UPDATE_DEVICE_INFO,
  UPDATE_DEVICE_INFO_SUCCESS,
  DELETE_DEVICE_INFO,
  DELETE_DEVICE_INFO_SUCCESS,
  DEVICE_INFO_CLEAR_NOTIFY,
} from "./actionTypes";

// DeviceInfo
export const getDeviceInfo = id => ({
  type: GET_DEVICE_INFO,
  payload: id,
});

export const getDeviceInfoSuccess = items => ({
  type: GET_DEVICE_INFO_SUCCESS,
  payload: items,
});

export const getDeviceInfoFail = error => ({
  type: GET_DEVICE_INFO_FAIL,
  payload: error,
});

export const getDeviceInfoById = data => ({
  type: GET_DEVICE_INFO_BY_ID,
  payload: data,
});

export const getDeviceInfoByIdSuccess = item => ({
  type: GET_DEVICE_INFO_BY_ID_SUCCESS,
  payload: item,
});

export const getDeviceInfoByIdFail = error => ({
  type: GET_DEVICE_INFO_BY_ID_FAIL,
  payload: error,
});

export const addNewDeviceInfo = payload => ({
  type: ADD_NEW_DEVICE_INFO,
  payload,
});

export const addNewDeviceInfoSuccess = payload => ({
  type: ADD_NEW_DEVICE_INFO_SUCCESS,
  payload,
});

export const updateDeviceInfo = payload => ({
  type: UPDATE_DEVICE_INFO,
  payload,
});

export const updateDeviceInfoSuccess = payload => ({
  type: UPDATE_DEVICE_INFO_SUCCESS,
  payload,
});

export const deleteDeviceInfo = payload => ({
  type: DELETE_DEVICE_INFO,
  payload,
});

export const deleteDeviceInfoSuccess = payload => ({
  type: DELETE_DEVICE_INFO_SUCCESS,
  payload,
});

export const clearDeviceInfoNotify = () => ({
  type: DEVICE_INFO_CLEAR_NOTIFY,
});
