import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ITEM_ATTACHMENTS,
  ADD_NEW_ITEM_ATTACHMENT,
  GET_ITEM_ATTACHMENT_BY_ID,
  UPDATE_ITEM_ATTACHMENT,
  UPDATE_ITEM_ATTACHMENTS,
  DELETE_ITEM_ATTACHMENT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getItemAttachmentsSuccess,
  getItemAttachmentByIdSuccess,
  getItemAttachmentsFail,
} from "./actions";
// ItemAttachment
const getItemAttachmentsRequest = query =>
  get("/api/ItemAttachment/paging", { params: query });
const addNewItemAttachmentRequest = role => post("/api/ItemAttachment", role);
const getItemAttachmentByIdRequest = id => get(`/api/ItemAttachment/${id}`);
const updateItemAttachmentRequest = role => update("/api/ItemAttachment", role);
const deleteItemAttachmentRequest = id => del(`/api/ItemAttachment/${id}`);

const updateItemAttachmentsRequest = ({ itemId, attachments }) => {
  return update(`/api/Item/${itemId}/attachments`, attachments);
};

function* fetchItemAttachments({ payload }) {
  try {
    const response = yield call(getItemAttachmentsRequest, payload);
    yield put(getItemAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemAttachmentsFail(error));
  }
}

function* addNewItemAttachment({ payload }) {
  try {
    yield call(addNewItemAttachmentRequest, payload);
    const { itemId } = payload;
    const response = yield call(getItemAttachmentsRequest, { itemId });
    yield put(getItemAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemAttachmentsFail(error));
  }
}

function* updateItemAttachment({ payload }) {
  try {
    yield call(updateItemAttachmentRequest, payload);
    const { itemId } = payload;
    const response = yield call(getItemAttachmentsRequest, { itemId });
    yield put(getItemAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemAttachmentsFail(error));
  }
}

function* updateItemAttachments({ payload }) {
  try {
    console.log(payload);
    let response = yield call(updateItemAttachmentsRequest, payload);

    response = yield call(getItemAttachmentsRequest, {
      itemId: response,
    });

    yield put(getItemAttachmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemAttachmentsFail(error));
  }
}

function* getItemAttachmentById({ payload }) {
  try {
    var response = yield call(getItemAttachmentByIdRequest, payload);
    yield put(getItemAttachmentByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemAttachmentsFail(error));
  }
}

function* deleteItemAttachment({ payload }) {
  try {
    const { id, itemId } = payload;
    let response = yield call(deleteItemAttachmentRequest, id);
    response = yield call(getItemAttachmentsRequest, { itemId });
    yield put(getItemAttachmentsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getItemAttachmentsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ITEM_ATTACHMENTS, fetchItemAttachments);
  yield takeEvery(ADD_NEW_ITEM_ATTACHMENT, addNewItemAttachment);
  yield takeEvery(GET_ITEM_ATTACHMENT_BY_ID, getItemAttachmentById);
  yield takeEvery(UPDATE_ITEM_ATTACHMENT, updateItemAttachment);
  yield takeEvery(UPDATE_ITEM_ATTACHMENTS, updateItemAttachments);
  yield takeEvery(DELETE_ITEM_ATTACHMENT, deleteItemAttachment);
}

export default roleSaga;
