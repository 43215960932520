import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_DEVICE_INFO_NOTIFICATION_HISTORY,
  ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY,
  GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID,
  UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY,
  DELETE_DEVICE_INFO_NOTIFICATION_HISTORY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getDeviceInfoNotificationHistorySuccess,
  getDeviceInfoNotificationHistoryByIdSuccess,
  getDeviceInfoNotificationHistoryFail,
  addNewDeviceInfoNotificationHistorySuccess,
  updateDeviceInfoNotificationHistorySuccess,
  deleteDeviceInfoNotificationHistorySuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () =>
  get(`/api/DeviceInfoNotificationHistory`);
const getDeviceInfoNotificationHistoryRequest = query =>
  get(`/api/DeviceInfoNotificationHistory/paging`, { params: query });

const addNewDeviceInfoNotificationHistoryRequest = item =>
  post(`/api/DeviceInfoNotificationHistory`, item);
const getDeviceInfoNotificationHistoryByIdRequest = id =>
  get(`/api/DeviceInfoNotificationHistory/${id}`);
const updateDeviceInfoNotificationHistoryRequest = item =>
  update(`/api/DeviceInfoNotificationHistory`, item);
const deleteDeviceInfoNotificationHistoryRequest = id =>
  del(`/api/DeviceInfoNotificationHistory/${id}`);

function* fetchDeviceInfoNotificationHistorys({ payload }) {
  try {
    const response = yield call(
      getDeviceInfoNotificationHistoryRequest,
      payload
    );

    yield put(getDeviceInfoNotificationHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoNotificationHistoryFail(error));
  }
}

function* addNewDeviceInfoNotificationHistory({ payload }) {
  try {
    yield call(addNewDeviceInfoNotificationHistoryRequest, payload);
    const response = yield call(getDeviceInfoNotificationHistoryRequest);
    yield put(getDeviceInfoNotificationHistorySuccess(response));
    yield put(addNewDeviceInfoNotificationHistorySuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoNotificationHistoryFail(error));
  }
}

function* updateDeviceInfoNotificationHistory({ payload }) {
  try {
    yield call(updateDeviceInfoNotificationHistoryRequest, payload);
    const response = yield call(getDeviceInfoNotificationHistoryRequest);
    yield put(getDeviceInfoNotificationHistorySuccess(response));
    yield put(updateDeviceInfoNotificationHistorySuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoNotificationHistoryFail(error));
  }
}

function* getDeviceInfoNotificationHistoryById({ payload }) {
  try {
    var response = yield call(
      getDeviceInfoNotificationHistoryByIdRequest,
      payload
    );
    yield put(getDeviceInfoNotificationHistoryByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoNotificationHistoryFail(error));
  }
}

function* deleteDeviceInfoNotificationHistory({ payload }) {
  try {
    yield call(deleteDeviceInfoNotificationHistoryRequest, payload);
    const response = yield call(getDeviceInfoNotificationHistoryRequest);
    yield put(getDeviceInfoNotificationHistorySuccess(response));
    yield put(deleteDeviceInfoNotificationHistorySuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getDeviceInfoNotificationHistoryFail(error));
  }
}

function* DeviceInfoNotificationHistorySaga() {
  yield takeEvery(
    GET_DEVICE_INFO_NOTIFICATION_HISTORY,
    fetchDeviceInfoNotificationHistorys
  );
  yield takeEvery(
    ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY,
    addNewDeviceInfoNotificationHistory
  );
  yield takeEvery(
    GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID,
    getDeviceInfoNotificationHistoryById
  );
  yield takeEvery(
    UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY,
    updateDeviceInfoNotificationHistory
  );
  yield takeEvery(
    DELETE_DEVICE_INFO_NOTIFICATION_HISTORY,
    deleteDeviceInfoNotificationHistory
  );
}

export default DeviceInfoNotificationHistorySaga;

export { getDeliveryAgenciesRequest };
