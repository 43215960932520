import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_NEW_FINISHED_GOODS,
  GET_FINISHED_GOODS_BY_ID,
  UPDATE_FINISHED_GOODS,
  DELETE_FINISHED_GOODS,
  GET_FINISHED_GOODSS, GET_FINISHED_GOODS_ENTRY_FORM, SAVE_FINISHED_GOODS_ENTRY_FORM, CHANGE_STATUS_FINISHED_GOODS,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper"
import {
  getFinishedGoodsByIdSuccess,
  getFinishedGoodsEntryFormSuccess,
  getFinishedGoodssError,
  getFinishedGoodssSuccess,
  saveFinishedGoodsEntryFormSuccess,
} from "./actions"

const getFinishedGoodssRequest = query => get("/api/FinishedGoods/paging", { params: query })
const addNewFinishedGoodsRequest = item => post("/api/FinishedGoods", item)
const getFinishedGoodsByIdRequest = id => get(`/api/FinishedGoods/${ id }`)
const updateFinishedGoodsRequest = item => update("/api/FinishedGoods", item)
const deleteFinishedGoodsRequest = id => del(`/api/FinishedGoods/${ id }`)

const getFinishedGoodsEntryFormRequest = id => get(`/api/FinishedGoods/entryForm/${ id }`)
const saveFinishedGoodsEntryFormRequest = item => post("/api/FinishedGoods/entryForm", item)
const changeStatusFinishedGoodRequest = id => get(`/api/FinishedGoods/${ id }/changeStatus`)
const validateFinishedGoodsEntryFormRequest = item => post("/api/FinishedGoods/validateEntryForm", item);
const validateFinishedGoodsRequest = item => post("/api/FinishedGoods/validateEntry", item);

function* getFinishedGoodss({ payload }) {
  try {
    const response = yield call(getFinishedGoodssRequest, payload)
    yield put(getFinishedGoodssSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* addNewFinishedGoods({ payload }) {
  try {
    const { data, queryParam } = payload
    yield call(addNewFinishedGoodsRequest, data)
    const response = yield call(getFinishedGoodssRequest, queryParam)
    yield put(getFinishedGoodssSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* updateFinishedGoods({ payload }) {
  try {
    const { data, queryParam } = payload
    yield call(updateFinishedGoodsRequest, data)
    const response = yield call(getFinishedGoodssRequest, queryParam)
    yield put(getFinishedGoodssSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* getFinishedGoodsById({ payload }) {
  try {
    let response = yield call(getFinishedGoodsByIdRequest, payload)
    yield put(getFinishedGoodsByIdSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* deleteFinishedGoods({ payload }) {
  try {
    const { data, queryParam } = payload
    yield call(deleteFinishedGoodsRequest, data)
    const response = yield call(getFinishedGoodssRequest, queryParam)
    yield put(getFinishedGoodssSuccess(response))
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* getFinishedGoodsEntryForm({ payload }) {
  try {
    const response = yield call(getFinishedGoodsEntryFormRequest, payload)
    yield put(getFinishedGoodsEntryFormSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* saveFinishedGoodsEntryForm({ payload }) {
  try {
    const { data, queryParam } = payload
    yield call(saveFinishedGoodsEntryFormRequest, data)
    const response = yield call(getFinishedGoodssRequest, queryParam)
    yield put(getFinishedGoodssSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* changeStatusFinishedGood({ payload }) {
  try {
    const { data, queryParam } = payload
    yield call(changeStatusFinishedGoodRequest, data)
    const response = yield call(getFinishedGoodssRequest, queryParam)
    yield put(getFinishedGoodssSuccess(response))
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong."
    yield put(getFinishedGoodssError(error))
  }
}

function* itemSaga() {
  yield takeEvery(GET_FINISHED_GOODSS, getFinishedGoodss)
  yield takeEvery(ADD_NEW_FINISHED_GOODS, addNewFinishedGoods)
  yield takeEvery(GET_FINISHED_GOODS_BY_ID, getFinishedGoodsById)
  yield takeEvery(UPDATE_FINISHED_GOODS, updateFinishedGoods)
  yield takeEvery(DELETE_FINISHED_GOODS, deleteFinishedGoods)
  yield takeEvery(GET_FINISHED_GOODS_ENTRY_FORM, getFinishedGoodsEntryForm)
  yield takeEvery(SAVE_FINISHED_GOODS_ENTRY_FORM, saveFinishedGoodsEntryForm)
  yield takeEvery(CHANGE_STATUS_FINISHED_GOODS, changeStatusFinishedGood)
}

export default itemSaga

export {
  getFinishedGoodssRequest,
  validateFinishedGoodsEntryFormRequest,
  validateFinishedGoodsRequest
}
