import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Input, Table } from "reactstrap";

export class OrderNotificationHistoryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null,
    };
  }

  componentDidMount() {
    const { items } = this.props;
    if (items && items.length > 0) {
      this.setState({ items });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { items } = props;

    if (items && items.length > 0 && state.items && state.items.length > 0) {
      if (items[0].id !== state.items[0].id) {
        return items;
      }
    }
    return null;
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      onChangePage,
      onPageSizeChange,
      showCheckbox,
    } = this.props;
    const { items } = this.state;

    return (
      <>
        <div>
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered table-nowrap align-middle"
          >
            <thead>
              <tr>
                <th>Title</th>
                <th>Body</th>
                <th>Date Sent</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((item, index) => {
                  const { dateSent } = item;
                  return (
                    <tr key={index}>
                      <td>{item.title}</td>
                      <td>{item.body}</td>
                      <td>
                        {dateSent &&
                          moment
                            .utc(dateSent)
                            .local()
                            .format("DD-MMM-yyyy h:mm:ss a")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 15, 20, 25, 30]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </>
    );
  }
}

OrderNotificationHistoryTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onChange: PropTypes.func,
  showCheckbox: PropTypes.bool,
};

export default OrderNotificationHistoryTable;
