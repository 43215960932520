import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import {
  getTransportNote,
  addNewTransportNote,
  getTransportNoteById,
  updateTransportNote,
  deleteTransportNote,
} from "../../store/transport-note/actions";
import TransportNoteTable from "../../containers/transport/TransportNoteTable";
import TransportNoteForm from "../../containers/transport/TransportNoteForm";

class TransportNoteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      transportId: props.transportId,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getTransportNote({
      page: 1,
      pageSize: 10,
      term: "",
      transportId: this.state.transportId,
    });
  }

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = item => {
    item.transportId = this.state.transportId;
    this.props.addNewTransportNote(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  componentDidUpdate() {
    const { error } = this.props;

    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");
    }
  }
  handleOnEdit = id => {
    var data = {};
    data.transportId = this.state.transportId;
    data.id = id;
    this.props.getTransportNoteById(data);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleUpdate = item => {
    item.transportId = this.state.transportId;
    this.props.updateTransportNote(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };
  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    var data = {};
    data.transportId = this.state.transportId;
    data.id = this.state.id;
    this.props.deleteTransportNote(data);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  handleOnSearch = e => {
    const { transportId, getTransportNote } = this.props;
    const { value } = e.target;
    getTransportNote({
      term: value ?? "",
      transportId,
    });
  };

  handleOnPageChange = page => {
    const { transportId, getTransportNote } = this.props;
    const { pageSize } = this.state;
    getTransportNote({
      transportId,
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { transportId, getTransportNote } = this.props;
    getTransportNote({
      transportId,
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        {items && (
          <TransportNoteTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onAddNew={this.handleOnAddNew}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onSearch={this.handleOnSearch}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
        <TransportNoteForm
          title={"Add New Note"}
          isOpen={this.state.modalAddIsOpen}
          toggle={this.toggleModalAdd}
          onSubmit={this.handleSubmit}
        />

        {item && (
          <TransportNoteForm
            title={"Edit Note"}
            item={item}
            isOpen={this.state.modalEditIsOpen}
            toggle={this.toggleModalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalConfirmDeleteIsOpen}
          toggle={this.toggleModalConfirmDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

TransportNoteList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransportNote: PropTypes.func,
  getTransportNoteById: PropTypes.func,
  addNewTransportNote: PropTypes.func,
  updateTransportNote: PropTypes.func,
  deleteTransportNote: PropTypes.func,
  transportId: PropTypes.string,
};

const mapStateToProps = ({ transportNote }) => {
  return transportNote;
};

export default withRouter(
  connect(mapStateToProps, {
    getTransportNote,
    addNewTransportNote,
    getTransportNoteById,
    updateTransportNote,
    deleteTransportNote,
  })(TransportNoteList)
);
