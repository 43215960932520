// DELIVERY_AGENCY
export const GET_DELIVERY_AGENCY = "GET_DELIVERY_AGENCY";
export const GET_DELIVERY_AGENCY_SUCCESS = "GET_DELIVERY_AGENCY_SUCCESS";
export const GET_DELIVERY_AGENCY_FAIL = "GET_DELIVERY_AGENCY_FAIL";

export const GET_DELIVERY_AGENCY_BY_ID = "GET_DELIVERY_AGENCY_BY_ID";
export const GET_DELIVERY_AGENCY_BY_ID_SUCCESS =
  "GET_DELIVERY_AGENCY_BY_ID_SUCCESS";

export const ADD_NEW_DELIVERY_AGENCY = "ADD_NEW_DELIVERY_AGENCY";
export const ADD_NEW_DELIVERY_AGENCY_SUCCESS =
  "ADD_NEW_DELIVERY_AGENCY_SUCCESS";

export const UPDATE_DELIVERY_AGENCY = "UPDATE_DELIVERY_AGENCY";
export const UPDATE_DELIVERY_AGENCY_SUCCESS = "UPDATE_DELIVERY_AGENCY_SUCCESS";

export const DELETE_DELIVERY_AGENCY = "DELETE_DELIVERY_AGENCY";
export const DELETE_DELIVERY_AGENCY_SUCCESS = "DELETE_DELIVERY_AGENCY_SUCCESS";

export const DELIVERY_AGENCY_CLEAR_NOTIFY = "DELIVERY_AGENCY_CLEAR_NOTIFY";
