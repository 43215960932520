import { SEVERITY } from "helpers/utils.js";
import {
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_COMPANY_BY_ID_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_NEW_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  COMPANY_CLEAR_NOTIFY,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const company = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_COMPANY:
    case UPDATE_COMPANY:
    case DELETE_COMPANY:
    case GET_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case ADD_NEW_COMPANY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Company",
          text: "Company has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Company",
          text: "Company has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        message: {
          title: "Company",
          text: "Company has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };
    case GET_COMPANY_FAIL:
      return {
        ...state,
        error: payload,
      };
    case COMPANY_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default company;
