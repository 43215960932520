import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardHeader, Col, Input, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getItemShippingFeeById,
  getItemShippingFeeEntrys,
  addNewItemShippingFeeEntry,
  updateItemShippingFeeEntry,
  getItemShippingFeeEntryById,
  deleteItemShippingFeeEntry,
  clearItemShippingFeeEntryError,
} from "store/actions";
import ShippingFeeEntryTable from "containers/shipping-fee-entry/ShippingFeeEntryTable";
import ModalForm from "containers/shipping-fee-entry/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

export class ShippingEntryTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      itemShippingFeeId: null,
      currentPage: 1,
      pageSize: 10,

      modalAdd: false,
      modalEdit: false,
      modalDelete: false,
    };
  }

  componentDidMount() {
    const { itemShippingFeeId } = this.props;

    if (itemShippingFeeId) {
      this.setState({
        itemShippingFeeId,
      });

      this.props.getItemShippingFeeEntrys({
        itemShippingFeeId,
        term: "",
      });
    }
  }

  handleOnPageChange = page => {
    const { pageSize, itemShippingFeeId } = this.state;
    const { getItemShippingFeeEntrys } = this.props;
    getItemShippingFeeEntrys({ itemShippingFeeId, term: "", page, pageSize });

    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { itemShippingFeeId } = this.state;
    const { getItemShippingFeeEntrys } = this.props;
    getItemShippingFeeEntrys({
      itemShippingFeeId,
      page: 1,
      pageSize,
      term: "",
    });

    this.setState({
      pageSize,
    });
  };

  handleOnSearch = e => {
    const { itemShippingFeeId } = this.state;
    const { getItemShippingFeeEntrys } = this.props;
    const { value } = e.target;
    getItemShippingFeeEntrys({ itemShippingFeeId, term: value ?? "" });
  };

  toggleAddModal = () => {
    this.setState(prevState => ({
      modalAdd: !prevState.modalAdd,
    }));
  };

  toggleEditModal = () => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
    }));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
    }));
  };

  handleOnAddNew = () => {
    this.setState({
      modalAdd: true,
    });
  };

  handleSubmit = data => {
    const { addNewItemShippingFeeEntry } = this.props;
    this.setState({
      modalAdd: false,
    });

    addNewItemShippingFeeEntry(data);
  };

  handleUpdate = item => {
    this.props.updateItemShippingFeeEntry(item);
    this.setState({
      modalEdit: false,
    });
  };

  handleOnEdit = id => {
    this.props.getItemShippingFeeEntryById(id);
    this.setState({
      id,
      modalEdit: true,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalDelete: true,
    });
  };

  handleDelete = () => {
    const { id, itemShippingFeeId } = this.state;
    this.props.deleteItemShippingFeeEntry({ id, itemShippingFeeId });
    this.setState({ modalDelete: false });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <>
        <Card className="mb-1 rounded-3">
          <CardHeader className="bg-transparent border pb-0 pt-3">
            <Row className="g-1">
              <Col md={8}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="me-1"
                    onClick={this.handleOnAddNew}
                  >
                    <i className="fas fa-plus" /> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>

        {items && (
          <ShippingFeeEntryTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onEdit={this.handleOnEdit}
            onConfirmDelete={this.handleConfirmDelete}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}

        <ModalForm
          title="Add Shipping Fee Entry"
          itemShippingFeeId={this.state.itemShippingFeeId}
          toggle={this.toggleAddModal}
          isOpen={this.state.modalAdd}
          onSubmit={this.handleSubmit}
        />
        {item && (
          <ModalForm
            title="Edit Shipping Fee Entry"
            item={item}
            itemShippingFeeId={this.state.itemShippingFeeId}
            toggle={this.toggleEditModal}
            isOpen={this.state.modalEdit}
            onSubmit={this.handleUpdate}
          />
        )}

        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalDelete}
          toggle={this.toggleDeleteModal}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

ShippingEntryTab.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  loading: PropTypes.bool,
  itemShippingFeeId: PropTypes.string,
  getItemShippingFeeById: PropTypes.func,
  itemShippingFee: PropTypes.object,
  itemShippingFeeEntry: PropTypes.object,
  getItemShippingFeeEntrys: PropTypes.func,
  addNewItemShippingFeeEntry: PropTypes.func,
  getItemShippingFeeEntryById: PropTypes.func,
  updateItemShippingFeeEntry: PropTypes.func,
  deleteItemShippingFeeEntry: PropTypes.func,
};

const mapStateToProps = ({ itemShippingFeeEntry }) => {
  return itemShippingFeeEntry;
};

export default withRouter(
  connect(mapStateToProps, {
    getItemShippingFeeById,
    getItemShippingFeeEntrys,
    addNewItemShippingFeeEntry,
    updateItemShippingFeeEntry,
    getItemShippingFeeEntryById,
    deleteItemShippingFeeEntry,
    clearItemShippingFeeEntryError,
  })(ShippingEntryTab)
);
