export const GET_WAREHOUSES = "GET_WAREHOUSES";
export const GET_WAREHOUSES_SUCCESS = "GET_WAREHOUSES_SUCCESS";
export const GET_WAREHOUSES_FAIL = "GET_WAREHOUSES_FAIL";

export const GET_WAREHOUSE_BY_ID = "GET_WAREHOUSE_BY_ID";
export const GET_WAREHOUSE_BY_ID_SUCCESS = "GET_WAREHOUSE_BY_ID_SUCCESS";

export const ADD_NEW_WAREHOUSE = "ADD_NEW_WAREHOUSE";

export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";

export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";

export const WAREHOUSE_CLEAR_ERROR = "WAREHOUSE_CLEAR_ERROR";
