import {
  GET_ITEM_SHIPPING_FEE_ENTRYS,
  GET_ITEM_SHIPPING_FEE_ENTRYS_SUCCESS,
  GET_ITEM_SHIPPING_FEE_ENTRYS_FAIL,
  GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID,
  GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_SUCCESS,
  ADD_NEW_ITEM_SHIPPING_FEE_ENTRY,
  UPDATE_ITEM_SHIPPING_FEE_ENTRY,
  DELETE_ITEM_SHIPPING_FEE_ENTRY,
  ITEM_SHIPPING_FEE_ENTRY_CLEAR_ERROR,
} from "./actionTypes";

export const getItemShippingFeeEntrys = query => ({
  type: GET_ITEM_SHIPPING_FEE_ENTRYS,
  payload: query,
});

export const getItemShippingFeeEntrysSuccess = items => ({
  type: GET_ITEM_SHIPPING_FEE_ENTRYS_SUCCESS,
  payload: items,
});

export const getItemShippingFeeEntrysFail = error => ({
  type: GET_ITEM_SHIPPING_FEE_ENTRYS_FAIL,
  payload: error,
});

export const getItemShippingFeeEntryById = id => ({
  type: GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID,
  payload: id,
});

export const getItemShippingFeeEntryByIdSuccess = item => ({
  type: GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_SUCCESS,
  payload: item,
});

export const getItemShippingFeeEntryByIdFail = error => ({
  type: GET_ITEM_SHIPPING_FEE_ENTRY_BY_ID_FAIL,
  payload: error,
});

export const addNewItemShippingFeeEntry = item => ({
  type: ADD_NEW_ITEM_SHIPPING_FEE_ENTRY,
  payload: item,
});

export const updateItemShippingFeeEntry = item => ({
  type: UPDATE_ITEM_SHIPPING_FEE_ENTRY,
  payload: item,
});

export const deleteItemShippingFeeEntry = item => ({
  type: DELETE_ITEM_SHIPPING_FEE_ENTRY,
  payload: item,
});

export const clearItemShippingFeeEntryError = () => ({
  type: ITEM_SHIPPING_FEE_ENTRY_CLEAR_ERROR,
});
