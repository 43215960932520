import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";
import role from "./role/reducer";
import unit from "./unit/reducer";
import warehouse from "./warehouse/reducer";
import order from "./order/reducer";
import category from "./category/reducer";
import attribute from "./attribute/reducer";
import user from "./user/reducer";
import orderItem from "./order-item/reducer";
import currency from "./currency/reducer";
import banner from "./banner/reducer";
import itemShippingFee from "./item-shipping-fee/reducer";
import itemShippingFeeEntry from "./item-shipping-fee-entry/reducer";
import userItemShippingFee from "./user-item-shipping-fee/reducer";
import shippingContact from "./shipping-contact/reducer";
import permission from "./permission/reducer";
import orderAttachment from "./order-attachment/reducer";
import orderNote from "./order-note/reducer";
import transport from "./transport/reducer";
import transportOrder from "./transport-order/reducer";
import transportNote from "./transport-note/reducer";
import orderTracking from "./order-tracking/reducer";
import invoice from "./invoice/reducer";
import invoicePaymentHistotry from "./invoice-payment-history/reducer";
import invoicePayment from "./invoice-payment/reducer";
import invoiceNote from "./invoice-note/reducer";
import invoiceReport from "./invoice-report/reducer";
import deliveryAgency from "./delivery-agency/reducer";
import deliverer from "./deliverer/reducer";
import deliveryOrder from "./delivery-order/reducer";
import deliveryOrderItem from "./delivery-order-item/reducer";
import setting from "./settings/reducer";
import orderVerifyTracking from "./order-verify-tracking/reducer";
import orderNotificationHistory from "./order-notification-history/reducer";
import orderMessageHistory from "./order-message-history/reducer";
import deviceInfo from "./device-info/reducer";
import deviceInfoNotificationHistory from "./device-info-notification-history/reducer";
import feedback from "./feedback/reducer";
import supplier from "./supplier/reducer";
import company from "./company/reducer";
import store from "./store/reducer";
import product from "./product/reducer";
import productImage from "./product-image/reducer";
import item from "./item/reducer";
import itemAttachment from "./item-attachment/reducer";
import unitOfMeasurement from "./unit-of-measurement/reducer";
import itemUnitOfMeasurement from "./item-unit-of-measurement/reducer";
import stockIn from "./stock-in/reducer";
import stockInItem from "./stock-in-item/reducer";
import stockAdjustment from "./stock-adjustment/reducer";
import stockAdjustmentItem from "./stock-adjustment-item/reducer";
import purchaseOrder from "./purchase-order/reducer";
import purchaseOrderItem from "./purchase-order-item/reducer";
import stockOut from "./stock-out/reducer";
import stockOutItem from "./stock-out-item/reducer";
import productStockIn from "./product-stock-in/reducer";
import productStockInProduct from "./product-stock-in-product/reducer";
import productUnitOfMeasurement from "./product-unit-of-measurement/reducer";
import productStockOut from "./product-stock-out/reducer";
import productStockOutProduct from "./product-stock-out-product/reducer";
import clothingSize from "./clothing-size/reducer";
import cutting from "./cutting/reducer";
import stockRequest from "./stock-request/reducer";
import stockRequestItem from "./stock-request-item/reducer";
import sewing from "./sewing/reducer";
import packaging from "./packaging/reducer";
import finishedGoods from "./finished-goods/reducer";
import purchaseOrderAttachment from "./purchase-order-attachment/reducer";
import itemUsageLimitation from "./item-usage-limitation/reducer";
import stockLocation from "./stock-location/reducer";
import transferLocation from "./transfer-location/reducer";
import codeColor from "./code-color/reducer";
import location from "./location/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  mails,
  crypto,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  role,
  unit,
  warehouse,
  order,
  category,
  attribute,
  user,
  orderItem,
  currency,
  banner,
  itemShippingFee,
  itemShippingFeeEntry,
  userItemShippingFee,
  shippingContact,
  permission,
  orderAttachment,
  orderNote,
  transport,
  transportOrder,
  transportNote,
  orderTracking,
  orderVerifyTracking,
  invoice,
  invoicePaymentHistotry,
  invoicePayment,
  invoiceNote,
  invoiceReport,
  setting,
  deliveryAgency,
  deliverer,
  deliveryOrder,
  deliveryOrderItem,
  orderNotificationHistory,
  orderMessageHistory,
  deviceInfo,
  deviceInfoNotificationHistory,
  feedback,
  supplier,
  company,
  store,
  product,
  productImage,
  item,
  itemAttachment,
  unitOfMeasurement,
  itemUnitOfMeasurement,
  stockIn,
  stockInItem,
  stockAdjustment,
  stockAdjustmentItem,
  purchaseOrder,
  purchaseOrderItem,
  stockOut,
  stockOutItem,
  productStockIn,
  productStockInProduct,
  productUnitOfMeasurement,
  productStockOut,
  productStockOutProduct,
  clothingSize,
  cutting,
  stockRequest,
  stockRequestItem,
  sewing,
  packaging,
  finishedGoods,
  purchaseOrderAttachment,
  itemUsageLimitation,
  stockLocation,
  transferLocation,
  codeColor,
  location
});

export default rootReducer;
