// PURCHASE_ORDER_ATTACHMENT
export const GET_PURCHASE_ORDER_ATTACHMENTS = "GET_PURCHASE_ORDER_ATTACHMENTS";
export const GET_PURCHASE_ORDER_ATTACHMENTS_SUCCESS =
  "GET_PURCHASE_ORDER_ATTACHMENTS_SUCCESS";
export const GET_PURCHASE_ORDER_ATTACHMENTS_ERROR = "GET_PURCHASE_ORDER_ATTACHMENTS_ERROR";

export const GET_PURCHASE_ORDER_ATTACHMENT_BY_ID = "GET_PURCHASE_ORDER_ATTACHMENT_BY_ID";
export const GET_PURCHASE_ORDER_ATTACHMENT_BY_ID_SUCCESS =
  "GET_PURCHASE_ORDER_ATTACHMENT_BY_ID_SUCCESS";

export const ADD_NEW_PURCHASE_ORDER_ATTACHMENT = "ADD_NEW_PURCHASE_ORDER_ATTACHMENT";
export const ADD_NEW_PURCHASE_ORDER_ATTACHMENT_SUCCESS =
  "ADD_NEW_PURCHASE_ORDER_ATTACHMENT_SUCCESS";
export const ADD_NEW_PURCHASE_ORDER_ATTACHMENT_ERROR =
  "ADD_NEW_PURCHASE_ORDER_ATTACHMENT_ERROR";

export const UPDATE_PURCHASE_ORDER_ATTACHMENT = "UPDATE_PURCHASE_ORDER_ATTACHMENT";
export const UPDATE_PURCHASE_ORDER_ATTACHMENT_SUCCESS =
  "UPDATE_PURCHASE_ORDER_ATTACHMENT_SUCCESS";
export const UPDATE_PURCHASE_ORDER_ATTACHMENT_ERROR =
  "UPDATE_PURCHASE_ORDER_ATTACHMENT_ERROR";

export const DELETE_PURCHASE_ORDER_ATTACHMENT = "DELETE_PURCHASE_ORDER_ATTACHMENT";
export const DELETE_PURCHASE_ORDER_ATTACHMENT_SUCCESS =
  "DELETE_PURCHASE_ORDER_ATTACHMENT_SUCCESS";

export const PURCHASE_ORDER_ATTACHMENT_CLEAR_ERROR =
  "PURCHASE_ORDER_ATTACHMENT_CLEAR_ERROR";
