// DEVICE_INFO_NOTIFICATION_HISTORY_NOTIFICATION_HISTORY
export const GET_DEVICE_INFO_NOTIFICATION_HISTORY =
  "GET_DEVICE_INFO_NOTIFICATION_HISTORY";
export const GET_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS =
  "GET_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS";
export const GET_DEVICE_INFO_NOTIFICATION_HISTORY_FAIL =
  "GET_DEVICE_INFO_NOTIFICATION_HISTORY_FAIL";

export const GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID =
  "GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID";
export const GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID_SUCCESS =
  "GET_DEVICE_INFO_NOTIFICATION_HISTORY_BY_ID_SUCCESS";

export const ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY =
  "ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY";
export const ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS =
  "ADD_NEW_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS";

export const UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY =
  "UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY";
export const UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS =
  "UPDATE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS";

export const DELETE_DEVICE_INFO_NOTIFICATION_HISTORY =
  "DELETE_DEVICE_INFO_NOTIFICATION_HISTORY";
export const DELETE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS =
  "DELETE_DEVICE_INFO_NOTIFICATION_HISTORY_SUCCESS";

export const DEVICE_INFO_NOTIFICATION_HISTORY_CLEAR_NOTIFY =
  "DEVICE_INFO_NOTIFICATION_HISTORY_CLEAR_NOTIFY";
