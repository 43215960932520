import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_STOCK_REQUEST_ITEM,
  GET_STOCK_REQUEST_ITEM_BY_ID,
  UPDATE_STOCK_REQUEST_ITEM,
  DELETE_STOCK_REQUEST_ITEM,
  GET_STOCK_REQUEST_ITEMS, ADD_NEW_BULK_STOCK_REQUEST_ITEM,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getStockRequestItemByIdSuccess,
  getStockRequestItemsError,
  getStockRequestItemsSuccess,
} from "./actions";

const getStockRequestItemsRequest = query =>
  get("/api/StockRequestItem/paging", { params: query });
const addNewStockRequestItemRequest = item =>
  post("/api/StockRequestItem", item);

const addNewBulkStockRequestItemRequest = item =>
    post("/api/StockRequestItem/bulk", item);

const getStockRequestItemByIdRequest = id =>
  get(`/api/StockRequestItem/${id}`);
const updateStockRequestItemRequest = item =>
  update("/api/StockRequestItem", item);
const deleteStockRequestItemRequest = id =>
  del(`/api/StockRequestItem/${id}`);

function* getStockRequestItems({ payload }) {
  try {
    const response = yield call(getStockRequestItemsRequest, payload);
    yield put(getStockRequestItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockRequestItemsError(error));
  }
}

function* addNewStockRequestItem({ payload }) {
  try {
    const { stockRequestId } = payload;
    yield call(addNewStockRequestItemRequest, payload);
    const response = yield call(getStockRequestItemsRequest, {
      stockRequestId,
    });
    yield put(getStockRequestItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockRequestItemsError(error));
  }
}

function* addNewBulkStockRequestItem({ payload }) {
  try {
    const { data, stockRequestId } = payload;
    yield call(addNewBulkStockRequestItemRequest, data);
    const response = yield call(getStockRequestItemsRequest, {
      stockRequestId,
    });
    yield put(getStockRequestItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockRequestItemsError(error));
  }
}

function* updateStockRequestItem({ payload }) {
  try {
    const { stockRequestId } = payload;
    yield call(updateStockRequestItemRequest, payload);
    const response = yield call(getStockRequestItemsRequest, {
      stockRequestId,
    });
    yield put(getStockRequestItemsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockRequestItemsError(error));
  }
}

function* getStockRequestItemById({ payload }) {
  try {
    var response = yield call(getStockRequestItemByIdRequest, payload);
    yield put(getStockRequestItemByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockRequestItemsError(error));
  }
}

function* deleteStockRequestItem({ payload }) {
  try {
    const { stockRequestId, id } = payload;
    yield call(deleteStockRequestItemRequest, id);
    const response = yield call(getStockRequestItemsRequest, {
      stockRequestId,
    });
    yield put(getStockRequestItemsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockRequestItemsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_REQUEST_ITEMS, getStockRequestItems);
  yield takeEvery(ADD_NEW_STOCK_REQUEST_ITEM, addNewStockRequestItem);
  yield takeEvery(ADD_NEW_BULK_STOCK_REQUEST_ITEM, addNewBulkStockRequestItem);
  yield takeEvery(ADD_NEW_STOCK_REQUEST_ITEM, addNewStockRequestItem);
  yield takeEvery(GET_STOCK_REQUEST_ITEM_BY_ID, getStockRequestItemById);
  yield takeEvery(UPDATE_STOCK_REQUEST_ITEM, updateStockRequestItem);
  yield takeEvery(DELETE_STOCK_REQUEST_ITEM, deleteStockRequestItem);
}

export default itemSaga;
