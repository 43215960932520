// ITEM_ATTACHMENT
export const GET_ITEM_ATTACHMENTS = "GET_ITEM_ATTACHMENTS";
export const GET_ITEM_ATTACHMENTS_SUCCESS = "GET_ITEM_ATTACHMENTS_SUCCESS";
export const GET_ITEM_ATTACHMENTS_FAIL = "GET_ITEM_ATTACHMENTS_FAIL";

export const GET_ITEM_ATTACHMENT_BY_ID = "GET_ITEM_ATTACHMENT_BY_ID";
export const GET_ITEM_ATTACHMENT_BY_ID_SUCCESS =
  "GET_ITEM_ATTACHMENT_BY_ID_SUCCESS";

export const ADD_NEW_ITEM_ATTACHMENT = "ADD_NEW_ITEM_ATTACHMENT";

export const UPDATE_ITEM_ATTACHMENT = "UPDATE_ITEM_ATTACHMENT";
export const UPDATE_ITEM_ATTACHMENTS = "UPDATE_ITEM_ATTACHMENTS";

export const DELETE_ITEM_ATTACHMENT = "DELETE_ITEM_ATTACHMENT";

export const ITEM_ATTACHMENT_CLEAR_ERROR = "ITEM_ATTACHMENT_CLEAR_ERROR";
