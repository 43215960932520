import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardHeader, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import ShippingFeeUserTable from "containers/shipping-fee-customer/ShippingFeeUserTable";
import UserSelectionModalList from "pages/Order/UserSelectionModalList";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { getItemShippingFeeById } from "../../store/item-shipping-fee/actions";
import {
  getUserItemShippingFees,
  addNewUserItemShippingFee,
  deleteUserItemShippingFee,
  clearUserItemShippingFeeError,
} from "../../store/user-item-shipping-fee/actions";
import { getUserIdsItemShippingFeesRequest } from "store/user-item-shipping-fee/saga";

export class ShippingCustomerTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      itemShippingFeeId: null,
      type: null,
      userIds: null,
      currentPage: 1,
      pageSize: 10,
      modalAdd: false,
      modalEdit: false,
      modalDelete: false,
    };
  }

  toggleAddModal = () => {
    this.setState(prevState => ({
      modalAdd: !prevState.modalAdd,
    }));
  };

  toggleEditModal = () => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
    }));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
    }));
  };

  handleOnAddNew = () => {
    this.setState({
      modalAdd: true,
    });
  };

  componentDidMount() {
    const { itemShippingFeeId, type } = this.props;
    if (itemShippingFeeId) {
      this.setState({
        itemShippingFeeId,
        type,
      });

      this.props.getUserItemShippingFees({
        itemShippingFeeId,
        term: "",
      });

      getUserIdsItemShippingFeesRequest({ type }).then(data => {
        this.setState({
          userIds: data,
        });
      });
    }
  }

  componentDidUpdate() {
    const { error } = this.props;

    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");

      this.props.clearUserItemShippingFeeError();
    }
  }

  handleOnEdit = id => {
    this.props.getItemShippingFeeEntryById(id);
    this.setState({
      id,
      modalEdit: true,
    });
  };

  handleUpdate = item => {
    this.props.updateItemShippingFeeEntry(item);
    this.setState({
      modalEdit: false,
    });
  };

  handleDelete = () => {
    const { id, itemShippingFeeId } = this.state;
    this.props.deleteUserItemShippingFee({ userId: id, itemShippingFeeId });
    this.setState({ modalDelete: false });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalDelete: true,
    });
  };

  handleOnPageChange = page => {
    const { pageSize, itemShippingFeeId } = this.state;
    const { getUserItemShippingFees } = this.props;
    getUserItemShippingFees({ itemShippingFeeId, term: "", page, pageSize });

    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { itemShippingFeeId } = this.state;
    const { getUserItemShippingFees } = this.props;
    getUserItemShippingFees({
      itemShippingFeeId,
      page: 1,
      pageSize,
      term: "",
    });

    this.setState({
      pageSize,
    });
  };

  handleOnSearch = e => {
    const { itemShippingFeeId } = this.state;
    const { getUserItemShippingFees } = this.props;
    const { value } = e.target;
    getUserItemShippingFees({ itemShippingFeeId, term: value ?? "" });
  };

  handleSelectUser = user => {
    const { itemShippingFeeId } = this.state;
    const { addNewUserItemShippingFee } = this.props;
    const data = {
      itemShippingFeeId,
      userId: user.id,
    };

    addNewUserItemShippingFee(data);

    this.setState({
      modalAdd: false,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, itemCount, pageCount, loading } = this.props;

    return (
      <>
        <Card className="mb-1 rounded-3">
          <CardHeader className="bg-transparent border pb-0 pt-3">
            <Row className="g-1">
              <Col md={8}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="me-1"
                    onClick={this.handleOnAddNew}
                  >
                    <i className="fas fa-plus" /> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {items && (
          <ShippingFeeUserTable
            items={items}
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={pageCount}
            loading={loading}
            defaultPageSize={pageSize}
            onConfirmDelete={this.handleConfirmDelete}
            onChangePage={this.handleOnPageChange}
            onPageSizeChange={this.handleOnPageSizeChange}
          />
        )}
        <UserSelectionModalList
          title={"Find Customer"}
          isOpen={this.state.modalAdd}
          toggle={this.toggleAddModal}
          onSubmit={this.handleSelectUser}
          notUserIds={this.state.userIds}
        />
        <ConfirmDeleteModal
          title="Confirm Delete"
          isOpen={this.state.modalDelete}
          toggle={this.toggleDeleteModal}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

ShippingCustomerTab.propTypes = {
  items: PropTypes.array,
  type: PropTypes.number,
  item: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  loading: PropTypes.bool,
  itemShippingFeeId: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.string,
  getItemShippingFeeById: PropTypes.func,
  itemShippingFee: PropTypes.object,
  userItemShippingFee: PropTypes.object,
  getUserItemShippingFees: PropTypes.func,
  addNewUserItemShippingFee: PropTypes.func,
  getItemShippingFeeEntryById: PropTypes.func,
  updateItemShippingFeeEntry: PropTypes.func,
  deleteUserItemShippingFee: PropTypes.func,
  clearUserItemShippingFeeError: PropTypes.func,
};

const mapStateToProps = ({ userItemShippingFee }) => {
  return userItemShippingFee;
};

export default withRouter(
  connect(mapStateToProps, {
    getItemShippingFeeById,
    getUserItemShippingFees,
    addNewUserItemShippingFee,
    deleteUserItemShippingFee,
    clearUserItemShippingFeeError,
  })(ShippingCustomerTab)
);
