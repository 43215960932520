import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_FEEDBACK,
  ADD_NEW_FEEDBACK,
  GET_FEEDBACK_BY_ID,
  UPDATE_FEEDBACK,
  DELETE_FEEDBACK,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getFeedbackSuccess,
  getFeedbackByIdSuccess,
  getFeedbackFail,
  addNewFeedbackSuccess,
  updateFeedbackSuccess,
  deleteFeedbackSuccess,
} from "./actions";

const getFeedbackRequest = query =>
  get(`/api/Feedback/paging`, { params: query });
const addNewFeedbackRequest = item => post(`/api/Feedback`, item);
const getFeedbackByIdRequest = id => get(`/api/Feedback/${id}`);
const updateFeedbackRequest = item => update(`/api/Feedback`, item);
const deleteFeedbackRequest = id => del(`/api/Feedback/${id}`);

function* fetchFeedbacks({ payload }) {
  try {
    const response = yield call(getFeedbackRequest, payload);

    yield put(getFeedbackSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getFeedbackFail(error));
  }
}

function* addNewFeedback({ payload }) {
  try {
    yield call(addNewFeedbackRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getFeedbackRequest, { invoiceId });
    yield put(getFeedbackSuccess(response));
    yield put(addNewFeedbackSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getFeedbackFail(error));
  }
}

function* updateFeedback({ payload }) {
  try {
    yield call(updateFeedbackRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getFeedbackRequest, { invoiceId });
    yield put(getFeedbackSuccess(response));
    yield put(updateFeedbackSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getFeedbackFail(error));
  }
}

function* getFeedbackById({ payload }) {
  try {
    var response = yield call(getFeedbackByIdRequest, payload);
    yield put(getFeedbackByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getFeedbackFail(error));
  }
}

function* deleteFeedback({ payload }) {
  try {
    const { id, invoiceId } = payload;
    yield call(deleteFeedbackRequest, id);

    const response = yield call(getFeedbackRequest, { invoiceId });
    yield put(getFeedbackSuccess(response));
    yield put(deleteFeedbackSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getFeedbackFail(error));
  }
}

function* FeedbackSaga() {
  yield takeEvery(GET_FEEDBACK, fetchFeedbacks);
  yield takeEvery(ADD_NEW_FEEDBACK, addNewFeedback);
  yield takeEvery(GET_FEEDBACK_BY_ID, getFeedbackById);
  yield takeEvery(UPDATE_FEEDBACK, updateFeedback);
  yield takeEvery(DELETE_FEEDBACK, deleteFeedback);
}

export default FeedbackSaga;
