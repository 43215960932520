import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useCallback, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Button, ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup,
  Input,
  Row, Table, UncontrolledDropdown,
} from "reactstrap"
import {
  clearPurchaseOrderError,
  getPurchaseOrderSummary
} from "store/actions"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { timestampFileName, toastMessage } from "helpers/utils"

import { debounce, sum } from "lodash"
import ModalForm from "containers/purchase-order/ModalForm"
import DatePicker from "react-datepicker"
import moment from "moment"
import { getItemToExcel, getItemUploadTemplate } from "../../store/item/saga"
import { getPurchaseOrderSummaryToExcel } from "../../store/purchase-order/saga"

const PurchaseOrderSummaryReport = props => {
  const {
    getPurchaseOrderSummary,
    clearPurchaseOrderError,
    message,
    loading,
    history,
    summary
  } = props

  const [ id, setId ] = useState(null)
  const [ term, setTerm ] = useState("")
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false)
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ startDate, setStartDate ] = useState(null)
  const [ endDate, setEndDate ] = useState(null)
  
  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term)
      }, 1000),
      []
  )

  useEffect(() => {
    getPurchaseOrderSummary({term, startDate, endDate})
  }, [ term, startDate, endDate ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearPurchaseOrderError()
    }
  }, [ message ])
  

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Purchase Order Summary Report | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Purchase Order"
                breadcrumbItem="Purchase Order Summary Report"
            />
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <FormGroup>
                          <DatePicker
                              className="form-control"
                              selectsStart
                              name="startDate"
                              selected={ startDate }
                              startDate={ startDate }
                              endDate={ endDate }
                              maxDate={ endDate }
                              onChange={ date =>
                                  setStartDate(date)
                              }
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="Start Date"
                              isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <DatePicker
                              className="form-control"
                              name="endDate"
                              selectsEnd
                              selected={ endDate }
                              startDate={ startDate }
                              endDate={ endDate }
                              minDate={ startDate }
                              onChange={ date =>
                                  setEndDate(date)
                              }
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="End Date"
                              isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <div className="text-sm-end">
                          <UncontrolledDropdown>
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              
                              <DropdownItem
                                  onClick={()=>{
                                    getPurchaseOrderSummaryToExcel(
                                        {
                                          term,
                                          startDate,
                                          endDate
                                        },
                                        `${timestampFileName()}_Accessories.xlsx`
                                    );
                                  }}
                                  className="text-primary"
                              >
                                <i className="fas fa-file-export me-1" /> Export
                                to Excel
                              </DropdownItem>

                              {/*<DropdownItem*/}
                              {/*    onClick={() => setModalUpload(true)}*/}
                              {/*    className="text-primary"*/}
                              {/*>*/}
                              {/*  <i className="fas fa-file-import me-1" /> Import*/}
                              {/*  From Excel*/}
                              {/*</DropdownItem>*/}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>

                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ?
                      <div className={"loading"}>
                        
                      </div>:
                      <>
                        { summary && (
                            <Card>
                              <CardBody>
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                  <Table
                                      id="tech-companies-1"
                                      className="table table-striped table-bordered table-nowrap"
                                  >
                                    <thead>
                                    <tr>
                                      <th style={ { width: "80px" } }>#</th>
                                      <th className="text-center">Purchase Order</th>
                                      <th className="text-center">Date</th>
                                      <th className="text-center">Design</th>
                                      <th className="text-center">Cutting</th>
                                      <th className="text-center">Sewing</th>
                                      <th className="text-center">Packaging</th>
                                      <th className="text-center">Finished Goods</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { summary.entries.map((item, index) => {

                                      return (
                                          <tr key={ index }>
                                            <td>{ index+1 }</td>
                                            <td>{ item.purchaseOrderCode }</td>
                                            <td>{moment(item.purchaseOrderDate).format("DD-MMM-YYYY")}</td>
                                            <td className={"text-end"}>{item.totalDesign}</td>
                                            <td className={"text-end"}>{item.totalCutting}</td>
                                            <td className={"text-end"}>{item.totalSewing}</td>
                                            <td className={"text-end"}>{item.totalPackaging}</td>
                                            <td className={"text-end"}>{item.totalFinishedGood}</td>
                                          </tr>
                                      )
                                    })
                                    }

                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>

                        ) }
                      </>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

PurchaseOrderSummaryReport.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,
  summary: PropTypes.object,
  
  clearPurchaseOrderError: PropTypes.func,
  getPurchaseOrderSummary: PropTypes.func,
}

const mapStateToProps = ({ purchaseOrder }) => {
  return purchaseOrder
}

export default withRouter(
    connect(mapStateToProps, {
      clearPurchaseOrderError,
      getPurchaseOrderSummary
    })(PurchaseOrderSummaryReport)
)
