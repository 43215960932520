import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Card, CardBody, Col, Row } from "reactstrap";

export class InvoicePaymentSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { item, onStatusFilter } = this.props;
    const { draftIds, pendingIds, confirmedIds, rejectedIds } = item;
    let all = [];
    all = all
      .concat(draftIds)
      .concat(pendingIds)
      .concat(confirmedIds)
      .concat(rejectedIds);
    return (
      <Row>
        <Col md={12}>
          <div className="d-flex flex-row">
            <Card
              color="info"
              className="mini-stats-wid mt-1 mb-2 border me-1 w-100 clickable"
              onClick={() => onStatusFilter(all)}
            >
              <CardBody className="p-2">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-white font-size-12 fw-bold">All</p>
                    <h4 className="mb-0 text-white text-center">
                      <NumberFormat
                        value={all.length}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </h4>
                  </div>
                  <div
                    className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                    style={{
                      borderColor: "white",
                      borderStyle: "solid",
                    }}
                  >
                    <span className="avatar-title bg-transparent">
                      <i className={"fas fa-cubes font-size-24"} />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card
              color="primary"
              onClick={() => onStatusFilter(draftIds)}
              className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
            >
              <CardBody className="p-2">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-white font-size-12 fw-bold">Draft</p>
                    <h4 className="mb-0 text-white text-center">
                      <NumberFormat
                        value={draftIds.length}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </h4>
                  </div>
                  <div
                    className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                    style={{
                      borderColor: "white",
                      borderStyle: "solid",
                    }}
                  >
                    <span className="avatar-title bg-transparent">
                      <i className={"fab fa-firstdraft font-size-24"} />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card
              color="warning"
              onClick={() => onStatusFilter(pendingIds)}
              className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
            >
              <CardBody className="p-2">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-white font-size-12 fw-bold">Pending</p>
                    <h4 className="mb-0 text-white text-center">
                      <NumberFormat
                        value={pendingIds.length}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </h4>
                  </div>
                  <div
                    className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                    style={{
                      borderColor: "white",
                      borderStyle: "solid",
                    }}
                  >
                    <span className="avatar-title bg-transparent">
                      <i className={"fas fa-list font-size-24"} />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card
              color="success"
              onClick={() => onStatusFilter(confirmedIds)}
              className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
            >
              <CardBody className="p-2">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-white font-size-12 fw-bold">Confirmed</p>
                    <h4 className="mb-0 text-white text-center">
                      <NumberFormat
                        value={confirmedIds.length}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </h4>
                  </div>
                  <div
                    className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                    style={{
                      borderColor: "white",
                      borderStyle: "solid",
                    }}
                  >
                    <span className="avatar-title bg-transparent">
                      <i className={"fas fa-check font-size-24"} />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card
              color="danger"
              onClick={() => onStatusFilter(rejectedIds)}
              className="mini-stats-wid mt-1 mb-2 border ms-1 w-100 clickable"
            >
              <CardBody className="p-2">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-white font-size-12 fw-bold">Rejected</p>
                    <h4 className="mb-0 text-white text-center">
                      <NumberFormat
                        value={rejectedIds.length}
                        displayType="text"
                        thousandSeparator={true}
                        fixedDecimalScale={false}
                      />
                    </h4>
                  </div>
                  <div
                    className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                    style={{
                      borderColor: "white",
                      borderStyle: "solid",
                    }}
                  >
                    <span className="avatar-title bg-transparent">
                      <i className={"mdi mdi-close-thick font-size-24"} />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    );
  }
}

InvoicePaymentSummary.propTypes = {
  item: PropTypes.object,
  onStatusFilter: PropTypes.func,
};

export default InvoicePaymentSummary;
