import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_INVOICE_PAYMENT,
  ADD_NEW_INVOICE_PAYMENT,
  GET_INVOICE_PAYMENT_BY_ID,
  UPDATE_INVOICE_PAYMENT,
  DELETE_INVOICE_PAYMENT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getInvoicePaymentSuccess,
  getInvoicePaymentByIdSuccess,
  getInvoicePaymentFail,
  addNewInvoicePaymentSuccess,
  updateInvoicePaymentSuccess,
  deleteInvoicePaymentSuccess,
} from "./actions";

const getInvoicePaymentRequest = query =>
  post(`/api/InvoicePayment/paging`, query);
const addNewInvoicePaymentRequest = item => post(`/api/InvoicePayment`, item);
const getInvoicePaymentByIdRequest = id => get(`/api/InvoicePayment/${id}`);
const updateInvoicePaymentRequest = item => update(`/api/InvoicePayment`, item);
const deleteInvoicePaymentRequest = id => del(`/api/InvoicePayment/${id}`);

function* getInvoicePayment({ payload }) {
  try {
    const response = yield call(getInvoicePaymentRequest, payload);

    yield put(getInvoicePaymentSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicePaymentFail(error));
  }
}

function* addNewInvoicePayment({ payload }) {
  try {
    yield call(addNewInvoicePaymentRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getInvoicePaymentRequest, { invoiceId });
    yield put(getInvoicePaymentSuccess(response));
    yield put(addNewInvoicePaymentSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicePaymentFail(error));
  }
}

function* updateInvoicePayment({ payload }) {
  try {
    yield call(updateInvoicePaymentRequest, payload);
    const { invoiceId } = payload;
    const response = yield call(getInvoicePaymentRequest, { invoiceId });
    yield put(getInvoicePaymentSuccess(response));
    yield put(updateInvoicePaymentSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicePaymentFail(error));
  }
}

function* getInvoicePaymentById({ payload }) {
  try {
    var response = yield call(getInvoicePaymentByIdRequest, payload);
    yield put(getInvoicePaymentByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicePaymentFail(error));
  }
}

function* deleteInvoicePayment({ payload }) {
  try {
    const { id, invoiceId } = payload;
    yield call(deleteInvoicePaymentRequest, id);

    const response = yield call(getInvoicePaymentRequest, { invoiceId });
    yield put(getInvoicePaymentSuccess(response));
    yield put(deleteInvoicePaymentSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getInvoicePaymentFail(error));
  }
}

function* InvoicePaymentSaga() {
  yield takeEvery(GET_INVOICE_PAYMENT, getInvoicePayment);
  yield takeEvery(ADD_NEW_INVOICE_PAYMENT, addNewInvoicePayment);
  yield takeEvery(GET_INVOICE_PAYMENT_BY_ID, getInvoicePaymentById);
  yield takeEvery(UPDATE_INVOICE_PAYMENT, updateInvoicePayment);
  yield takeEvery(DELETE_INVOICE_PAYMENT, deleteInvoicePayment);
}

export default InvoicePaymentSaga;
