import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_STORE,
  ADD_NEW_STORE,
  GET_STORE_BY_ID,
  UPDATE_STORE,
  DELETE_STORE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getStoreSuccess,
  getStoreByIdSuccess,
  getStoreFail,
  addNewStoreSuccess,
  updateStoreSuccess,
  deleteStoreSuccess,
} from "./actions";

const getDeliveryAgenciesRequest = () => get(`/api/Store`);
const getStoreRequest = query => get(`/api/Store/paging`, { params: query });

const addNewStoreRequest = item => post(`/api/Store`, item);
const getStoreByIdRequest = id => get(`/api/Store/${id}`);
const updateStoreRequest = item => update(`/api/Store`, item);
const deleteStoreRequest = id => del(`/api/Store/${id}`);

function* fetchStores({ payload }) {
  try {
    const response = yield call(getStoreRequest, payload);

    yield put(getStoreSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStoreFail(error));
  }
}

function* addNewStore({ payload }) {
  try {
    yield call(addNewStoreRequest, payload);
    const response = yield call(getStoreRequest);
    yield put(getStoreSuccess(response));
    yield put(addNewStoreSuccess(response));
  } catch (err) {
    console.log(err).response;
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStoreFail(error));
  }
}

function* updateStore({ payload }) {
  try {
    yield call(updateStoreRequest, payload);
    const response = yield call(getStoreRequest);
    yield put(getStoreSuccess(response));
    yield put(updateStoreSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStoreFail(error));
  }
}

function* getStoreById({ payload }) {
  try {
    var response = yield call(getStoreByIdRequest, payload);
    yield put(getStoreByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStoreFail(error));
  }
}

function* deleteStore({ payload }) {
  try {
    yield call(deleteStoreRequest, payload);
    const response = yield call(getStoreRequest);
    yield put(getStoreSuccess(response));
    yield put(deleteStoreSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStoreFail(error));
  }
}

function* StoreSaga() {
  yield takeEvery(GET_STORE, fetchStores);
  yield takeEvery(ADD_NEW_STORE, addNewStore);
  yield takeEvery(GET_STORE_BY_ID, getStoreById);
  yield takeEvery(UPDATE_STORE, updateStore);
  yield takeEvery(DELETE_STORE, deleteStore);
}

export default StoreSaga;

export { getDeliveryAgenciesRequest };
