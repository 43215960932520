export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";

export const ADD_NEW_ROLE = "ADD_NEW_ROLE";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";

export const DELETE_ROLE = "DELETE_ROLE";

export const ROLE_CLEAR_ERROR = "ROLE_CLEAR_ERROR";
export const ROLE_CLEAR_NOTIFY = "ROLE_CLEAR_NOTIFY";
