import {
  GET_ALLCATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORY_BY_ID,
  GET_CATEGORY_BY_ID_SUCCESS,
  ADD_NEW_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CATEGORY_CLEAR_ERROR,
} from "./actionTypes";


export const getCategories = query => ({
  type: GET_ALLCATEGORIES,
  payload: query,
});

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});

export const getCategoryById = id => ({
  type: GET_CATEGORY_BY_ID,
  payload: id,
});

export const getCategoryByIdSuccess = category => ({
  type: GET_CATEGORY_BY_ID_SUCCESS,
  payload: category,
});

export const getCategoryByIdFail = error => ({
  type: GET_CATEGORY_BY_ID_FAIL,
  payload: error,
});

export const addNewCategory = category => ({
  type: ADD_NEW_CATEGORY,
  payload: category,
});

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: category,
});

export const deleteCategory = category => ({
  type: DELETE_CATEGORY,
  payload: category,
});

export const clearCategoryError = () => ({
  type: CATEGORY_CLEAR_ERROR,
});
