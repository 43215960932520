import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import SendMessageForm from "./SendMessageForm";

export class SettingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      name: "",
      value: "",
      description: "",
      cbmRate: 0,

      phoneNumber: "",
      body: "",
    };
  }

  componentDidMount() {
    const { item } = this.props;
    if (item) {
      this.setState({
        ...item,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { item } = props;
    if (item) {
      if (item.id !== state.id) {
        return {
          ...item,
        };
      }
    }

    return null;
  }

  handleSubmit = () => {
    const { id, name, value, description, cbmRate } = this.state;

    const data = {
      id,
      name,
      value,
      description,
      cbmRate,
    };

    this.props.onSubmit(data);
  };

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader className="bg-transparent border pt-3">
              <CardTitle>System Info</CardTitle>
            </CardHeader>
            <CardBody className="p-0">
              <table className="table align-middle mb-0 table">
                <tbody>
                  <tr>
                    <td>
                      <h5 className="font-size-14 mb-0">Environemnt</h5>
                    </td>
                    <td>
                      <p className="mb-0">{process.env.NODE_ENV}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-14 mb-0">API Endpoint</h5>
                    </td>
                    <td>
                      <p className="mb-0">{process.env.REACT_APP_API_URL}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <SendMessageForm />
        </Col>
        {/* <Col md={12}>
          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" onClick={this.handleSubmit}>
                  Save
                </Button>{" "}
                <Button color="primary" onClick={this.handleCancel} outline>
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    );
  }
}

SettingForm.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SettingForm;
